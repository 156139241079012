import React, { useState } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { Button, Modal, Space, Typography } from 'antd';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import {
  ExclamationCircleOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
// Api
import { REMOVE_INTERVIEWER_CAPABILITY } from 'api/interviewer/mutations';
import {
  ACTIVATE_STORE_ACCOUNT,
  DEACTIVATE_STORE_ACCOUNT,
} from 'api/store/mutations';
import {
  // DELETE_USER,
  REJECT_STORE_USER,
  REINSTATE_REJECTED_STORE_USER,
} from 'api/users/mutations';
// Types
import { ManageOrganizationStoreStatus } from '../ManageStores';
import {
  RemoveInterviewerCapability,
  RemoveInterviewerCapabilityVariables,
} from 'api/interviewer/types/RemoveInterviewerCapability';
import {
  GetAdminStores,
  GetAdminStores_adminStores_entities,
  GetAdminStores_adminStores_entities_sports,
  GetAdminStoresVariables,
} from 'api/store/types/GetAdminStores';
import {
  DeactivateStoreAccount,
  DeactivateStoreAccountVariables,
} from 'api/store/types/DeactivateStoreAccount';
import {
  ActivateStoreAccount,
  ActivateStoreAccountVariables,
} from 'api/store/types/ActivateStoreAccount';
import {
  ReinstateRejectedStore,
  ReinstateRejectedStoreVariables,
} from 'api/users/types/ReinstateRejectedStore';
import {
  RejectAwaitingApprovalStore,
  RejectAwaitingApprovalStoreVariables,
} from 'api/users/types/RejectAwaitingApprovalStore';
// UI
import Table, { SortedInfo, TableFilter } from 'ui/Table';
// import { DeleteUser } from 'api/users/types/DeleteUser';
// import { DeleteAddressVariables } from 'api/users/types/DeleteAddress';
import { AdminStoresOrderBy, StoreStatus } from 'api/graphql-global-types';
// Constants
import { commonTimeFormat } from 'constants/global';
import { ORGANIZATION_DASHBOARD_GEN } from 'constants/routes';
// Hooks
import { useGetSports } from 'hooks';
// Helpers
import { errorNotification, successNotification } from 'ui/Notification';
import VideoPreview from 'ui/VideoPreview/VideoPreview';
// Columns
import {
  AMAColumn,
  ExperiencesColumn,
  FacebookColumn,
  MillionsFollowersCountColumn,
  SocialFollowersCountColumn,
  InitialAssessmentColumn,
  InstagramColumn,
  LatestAssessmentColumn,
  ManagedByColumn,
  MerchColumn,
  NumberColumn,
  ProductsColumn,
  RevenueColumn,
  StoreStatusColumn,
  StreamColumn,
  TiktokColumn,
  TwitterColumn,
  WebsiteColumn,
  YoutubeColumn,
  OpenDesignRequestColumn,
  CountryColumn,
  StateColumn,
  CityColumn,
} from 'components/common/ManageStoreCommonColumns/ManageStoreCommonColumns';
import InterviewerCapability from 'components/common/InterviewerCapability/InterviewerCapability';
// Styles
import './styles.scss';

const { confirm } = Modal;
const { Link } = Typography;

export type RejectedStoresTableDataItem = {
  id: number;
  storeId: string;
  storeName: string;
  contactsEmail: string;
  phoneNumber: string;
  sports: GetAdminStores_adminStores_entities_sports[];
};

export type UnApprovedStoresTableDataItem = {
  id: number;
  storeId: string;
  storeName: string;
  contactsName: string;
  contactsRole: string;
  contactsEmail: string;
  phoneNumber: string;
  gymSocialLink: string;
  tiktokLink: string;
  facebookLink: string;
  instagramLink: string;
  website: string;
  twitterLink: string;
  hasMerch: string;
  hasAma: string;
  hasStreams: string;
  sports: GetAdminStores_adminStores_entities_sports[];
  verificationVideo: string | null;
  country: string | null;
  state: string | null;
  city: string | null;
  isOnboarded: boolean;
};

export type ApprovedStoresTableDataItem = Omit<
  UnApprovedStoresTableDataItem,
  'phoneNumber' | 'verificationVideo' | 'isOnboarded'
> & {
  followerCount: number | null;
  revenue: number;
  status: StoreStatus | null;
};

type StoresTableDataItem =
  | ApprovedStoresTableDataItem
  | UnApprovedStoresTableDataItem
  | RejectedStoresTableDataItem;

interface Props {
  data: StoresTableDataItem[];
  loading?: boolean;
  refetchStores: (
    variables?: Partial<GetAdminStoresVariables> | undefined
  ) => Promise<ApolloQueryResult<GetAdminStores>>;
  total?: number;
  tabKey?: string;
  sort: SortedInfo<AdminStoresOrderBy>;
  currentPage?: number;
  pageSize?: number;
  setSortInfo: React.Dispatch<
    React.SetStateAction<SortedInfo<AdminStoresOrderBy>>
  >;
  searchFilters: TableFilter[];
  setSearchFilters: React.Dispatch<React.SetStateAction<TableFilter[]>>;
  setPageAndSize?: (page: number, size: number) => void;
}

const StoresTable: React.FC<Props> = ({
  data,
  loading,
  refetchStores,
  total,
  tabKey,
  sort,
  setSortInfo,
  currentPage,
  pageSize,
  searchFilters,
  setSearchFilters,
  setPageAndSize,
}) => {
  const history = useHistory();

  const { data: sportsData } = useGetSports();

  const sports = sportsData?.sports?.map((sport) => {
    return { text: sport.name, value: Number(sport.id) };
  });

  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [videoPreview, setVideoPreview] = useState<string | null>(null);

  const [interviewerCapabilityModalData, setInterviewerCapabilityModalData] =
    useState<GetAdminStores_adminStores_entities | null>(null);

  const showSetInterviewerCapabilityModal = (
    record: GetAdminStores_adminStores_entities
  ) => {
    setInterviewerCapabilityModalData(record);
  };

  const hideInterviewerCapabilityModal = () =>
    setInterviewerCapabilityModalData(null);

  // const [deleteUser] = useMutation<DeleteUser, DeleteAddressVariables>(
  //   DELETE_USER,
  //   {
  //     onCompleted: () => {
  //       refetchStores();
  //     },
  //   }
  // );

  const [rejectStoreUser] = useMutation<
    RejectAwaitingApprovalStore,
    RejectAwaitingApprovalStoreVariables
  >(REJECT_STORE_USER, {
    onCompleted: () => {
      refetchStores();
    },
  });

  const [removeInterviewerCapability] = useMutation<
    RemoveInterviewerCapability,
    RemoveInterviewerCapabilityVariables
  >(REMOVE_INTERVIEWER_CAPABILITY, {
    onCompleted: () => {
      refetchStores();
    },
  });

  const [reinstateRejectedStoreUser] = useMutation<
    ReinstateRejectedStore,
    ReinstateRejectedStoreVariables
  >(REINSTATE_REJECTED_STORE_USER, {
    onCompleted: () => {
      refetchStores();
    },
  });

  const [deactivateStoreAccount] = useMutation<
    DeactivateStoreAccount,
    DeactivateStoreAccountVariables
  >(DEACTIVATE_STORE_ACCOUNT);

  const [activateStoreAccount] = useMutation<
    ActivateStoreAccount,
    ActivateStoreAccountVariables
  >(ACTIVATE_STORE_ACCOUNT);

  const handleRejectStore = async (storeId: string) => {
    try {
      await rejectStoreUser({
        variables: { input: { storeId } },
      });
      refetchStores();
      successNotification('Store has been rejected');
    } catch (err) {
      errorNotification((err as Error)?.message);
      console.log('handleRejectStore error:', { ...(err as Error) });
    }
  };

  const handleReinstateStore = async (storeId: string) => {
    try {
      await reinstateRejectedStoreUser({
        variables: { input: { storeId } },
      });
      refetchStores();
      successNotification('Store has been reinstated');
    } catch (err) {
      errorNotification((err as Error)?.message);
      console.log('handleReinstateStore error:', { ...(err as Error) });
    }
  };

  const handleRemoveInterviewerCapability = async (storeId: string) => {
    try {
      await removeInterviewerCapability({
        variables: { input: { storeId } },
      });
      refetchStores();
      successNotification('Interviewer capability has been removed');
    } catch (err) {
      errorNotification();
    }
  };

  // COMMENTED: requested to remove delete function for now, possible re-activation in the future
  // const handleDeleteUser = async (id: string) => {
  //   confirm({
  //     title: `Are you sure you want to delete this user?`,
  //     icon: <ExclamationCircleOutlined />,
  //     okText: 'Yes',
  //     okType: 'danger',
  //     cancelText: 'No',
  //     onOk: async () => {
  //       try {
  //         await deleteUser({
  //           variables: { id },
  //         });
  //         successNotification('User is deleted');
  //       } catch (err) {
  //         errorNotification();
  //         console.log('deleteUser error:', err);
  //       }
  //     },
  //   });
  // };

  const handleDeactivateAccount = async (storeId: string) => {
    try {
      await deactivateStoreAccount({
        variables: { input: { storeId } },
      });
      refetchStores();
      successNotification('Store has been deactivated');
    } catch (err) {
      errorNotification((err as Error)?.message);
      console.log('handleDeactivateAccount error:', { ...(err as Error) });
    }
  };

  const handleActivateAccount = async (storeId: string) => {
    try {
      await activateStoreAccount({
        variables: { input: { storeId } },
      });
      refetchStores();
      successNotification('Store has been activated');
    } catch (err) {
      errorNotification();
    }
  };

  const handleRemoveInterviewerCapabilityConfirmation = (
    id: string,
    name: string
  ) => {
    confirm({
      title: `Are you sure you want to remove interviewer capabilities for the "${name}"?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleRemoveInterviewerCapability(id);
      },
    });
  };

  const handleDeactivateStoreConfirmation = (id: string, name: string) => {
    confirm({
      title: `Are you sure to deactivate the store "${name}"?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeactivateAccount(id);
      },
    });
  };

  const handleActivateStoreConfirmation = (id: string, name: string) => {
    confirm({
      title: `Are you sure to activate the store "${name}"?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleActivateAccount(id);
      },
    });
  };

  const handleManageStore = async (storeId: string) => {
    await history.push(`${ORGANIZATION_DASHBOARD_GEN}/${storeId}`);
  };

  const handleShowVideo = (videoUrl: string) => {
    setModalVisible(true);
    setVideoPreview(videoUrl);
  };

  const handleHideModal = () => {
    setModalVisible(false);
  };

  const SignUpDateColumn = {
    title: 'Sign up date',
    dataIndex: 'createdAt',
    key: AdminStoresOrderBy.createdAt,
    align: 'center' as const,
    width: 180,
    sorterType: 'date',
    render: (createdAt: string) => {
      return moment(createdAt).format(commonTimeFormat);
    },
    withDateRangeFilter: true,
  };

  const ActivatedAtDateColumn = {
    title: 'Activated at date',
    dataIndex: 'activatedAt',
    key: AdminStoresOrderBy.activatedAt,
    align: 'center' as const,
    width: 180,
    sorterType: 'date',
    render: (activatedAt: string) => {
      if (activatedAt) {
        return moment(activatedAt).format(commonTimeFormat);
      }
    },
    withDateRangeFilter: true,
  };

  const AmaVideoPreviewColumn = {
    title: 'AMA Video',
    dataIndex: 'verificationVideo',
    align: 'center' as const,
    width: 130,
    key: AdminStoresOrderBy.amaVideo,
    render: function VerificationVideo(video: string | null) {
      if (video) {
        return (
          <button
            className="storesTablePlayButton"
            onClick={() => handleShowVideo(video)}
          >
            <PlayCircleOutlined style={{ fontSize: '24px' }} />
          </button>
        );
      }
    },
  };

  const handleAcceptUnapproved = (record: UnApprovedStoresTableDataItem) => {
    if (record.isOnboarded) {
      handleActivateAccount(record.storeId);
    } else {
      errorNotification('Store is not onboarded.');
    }
  };

  const OrganizationNameColumn = {
    title: 'Organization name',
    dataIndex: 'storeName',
    fixed: 'left' as const,
    key: AdminStoresOrderBy.storeName,
    width: 200,
    sorterType: 'text',
    withSearch: true,
  };

  const ContactNameColumn = {
    title: 'Contacts name',
    dataIndex: 'contactsName',
    key: AdminStoresOrderBy.firstName,
    width: 150,
    withSearch: true,
    sorterType: 'text',
  };

  const ContactsRoleColumn = {
    title: "Contact's role",
    dataIndex: 'contactsRole',
    key: AdminStoresOrderBy.contactRole,
    width: 130,
    sorterType: 'text',
  };

  const ContactEmailColumn = {
    title: "Contact's email",
    dataIndex: 'contactsEmail',
    key: AdminStoresOrderBy.email,
    fixed: 'left' as const,
    width: 200,
    sorterType: 'text',
    withSearch: true,
  };

  const SportColumn = {
    key: 'sportId',
    filters: sports,
    filterMultiple: true,
    withRadioFilters: true,
    title: 'Type of sport',
    dataIndex: 'sports',
    align: 'center' as const,
    width: 150,
    render: function Sports(sports: StoresTableDataItem['sports']) {
      const sportList = sports?.map((item) => `${item.name}`).join(', ') || '';
      return sportList;
    },
  };

  const SocialMediaColumn = {
    title: 'Organization social link',
    dataIndex: 'gymSocialLink',
    align: 'left' as const,
    width: 180,
    render: function SocialMediaLink(socialMediaLink: string) {
      return (
        <Link href={socialMediaLink} target="_blank">
          {socialMediaLink}
        </Link>
      );
    },
  };

  const PhoneColumn = {
    title: "Contact's phone number",
    dataIndex: 'phoneNumber',
    key: AdminStoresOrderBy.phoneNumber,
    align: 'center' as const,
    width: 180,
    withSearch: true,
    sorterType: 'text',
  };

  const rejectedStoresColumns = [
    NumberColumn,
    OrganizationNameColumn,
    ContactEmailColumn,
    SignUpDateColumn,
    PhoneColumn,
    AmaVideoPreviewColumn,
    SportColumn,
    ManagedByColumn,
    SocialFollowersCountColumn,
    FacebookColumn,
    YoutubeColumn,
    TiktokColumn,
    InstagramColumn,
    TwitterColumn,
    InitialAssessmentColumn,
    LatestAssessmentColumn,
    CountryColumn,
    StateColumn,
    CityColumn,
    {
      title: 'Actions',
      dataIndex: 'actions',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 250,
      render: function Actions(text: any, record: any) {
        return (
          <Space size="middle" direction="vertical">
            <Button
              type="primary"
              onClick={() => handleReinstateStore(record.storeId)}
            >
              Reinstate
            </Button>
          </Space>
        );
      },
    },
  ];

  const approvedStoresColumns = [
    NumberColumn,
    OrganizationNameColumn,
    ContactEmailColumn,
    ContactNameColumn,
    ContactsRoleColumn,
    PhoneColumn,
    SignUpDateColumn,
    ActivatedAtDateColumn,
    RevenueColumn,
    StoreStatusColumn,
    MerchColumn,
    AMAColumn,
    StreamColumn,
    ProductsColumn,
    ExperiencesColumn,
    AmaVideoPreviewColumn,
    SportColumn,
    SocialMediaColumn,
    MillionsFollowersCountColumn,
    SocialFollowersCountColumn,
    FacebookColumn,
    YoutubeColumn,
    TiktokColumn,
    InstagramColumn,
    TwitterColumn,
    WebsiteColumn,
    OpenDesignRequestColumn,
    ManagedByColumn,
    InitialAssessmentColumn,
    LatestAssessmentColumn,
    CountryColumn,
    StateColumn,
    CityColumn,
    {
      title: 'Actions',
      dataIndex: 'actions',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 250,
      render: function Actions(text: any, record: any) {
        const storeIsActive = record?.status === StoreStatus.Active;
        return (
          <Space size="middle" direction="vertical">
            <Button
              type="primary"
              onClick={() => handleManageStore(record.storeId)}
            >
              Manage
            </Button>
            <Button
              disabled={record.interviewerDetails}
              type="primary"
              onClick={() => {
                showSetInterviewerCapabilityModal(record);
              }}
            >
              Set interviewer capability
            </Button>

            <Button
              disabled={!record.interviewerDetails}
              type="primary"
              onClick={() => {
                handleRemoveInterviewerCapabilityConfirmation(
                  record.storeId,
                  record.name
                );
              }}
            >
              Remove interviewer capability
            </Button>
            <Button
              type="primary"
              danger={storeIsActive}
              className={cn({
                'basic-table__button_success': !storeIsActive,
              })}
              onClick={() =>
                storeIsActive
                  ? handleDeactivateStoreConfirmation(
                      record.storeId,
                      record.name
                    )
                  : handleActivateStoreConfirmation(record.storeId, record.name)
              }
            >
              {storeIsActive ? 'Deactivate' : 'Activate'}
            </Button>
          </Space>
        );
      },
    },
  ];

  const unApprovedStoresColumns = [
    NumberColumn,
    OrganizationNameColumn,
    ContactEmailColumn,
    ContactNameColumn,
    ContactsRoleColumn,
    PhoneColumn,
    SignUpDateColumn,
    MerchColumn,
    AMAColumn,
    StreamColumn,
    ProductsColumn,
    ExperiencesColumn,
    AmaVideoPreviewColumn,
    SportColumn,
    SocialMediaColumn,
    SocialFollowersCountColumn,
    FacebookColumn,
    YoutubeColumn,
    TiktokColumn,
    InstagramColumn,
    TwitterColumn,
    WebsiteColumn,
    OpenDesignRequestColumn,
    ManagedByColumn,
    InitialAssessmentColumn,
    LatestAssessmentColumn,
    CountryColumn,
    StateColumn,
    CityColumn,
    {
      title: 'Actions',
      dataIndex: 'actions',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 250,
      render: function Actions(text: any, record: any) {
        return (
          <Space size="middle" direction="vertical">
            <Button
              type="primary"
              onClick={() => handleManageStore(record.storeId)}
            >
              Manage
            </Button>
            <Button
              type="primary"
              className="basic-table__button_success"
              onClick={() => handleAcceptUnapproved(record)}
            >
              Accept
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => handleRejectStore(record.storeId)}
            >
              Reject
            </Button>
            {/* <Button
              type="primary"
              danger
              onClick={() => handleDeleteUser(record.storeId)}
            >
              Decline
            </Button> */}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table<StoresTableDataItem, AdminStoresOrderBy>
        columns={
          tabKey === ManageOrganizationStoreStatus.Pending
            ? unApprovedStoresColumns
            : tabKey === ManageOrganizationStoreStatus.Rejected
            ? rejectedStoresColumns
            : approvedStoresColumns
        }
        data={data}
        scroll={{ x: 1800 }}
        loading={loading}
        total={total}
        defaultPageSize={pageSize}
        defaultCurrent={currentPage}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        setPageAndSize={setPageAndSize}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />

      <Modal
        title="Interviewer Capability"
        visible={interviewerCapabilityModalData ? true : false}
        footer={null}
        onCancel={hideInterviewerCapabilityModal}
      >
        {interviewerCapabilityModalData && (
          <InterviewerCapability
            record={interviewerCapabilityModalData}
            refetchStores={refetchStores}
            hideInterviewerCapabilityModal={hideInterviewerCapabilityModal}
          />
        )}
      </Modal>

      <Modal
        title="Video Preview"
        visible={isModalVisible}
        footer={null}
        onCancel={handleHideModal}
      >
        <div className="videoWrapper">
          <VideoPreview video={videoPreview} show={isModalVisible} />
        </div>
      </Modal>
    </>
  );
};

export default StoresTable;
