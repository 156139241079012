import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import moment from 'moment';
// API
import { GET_AMA_SALES_BY_STORE_V2 } from 'api/ama/queries';
// Types
import {
  GetAmaSalesV2,
  GetAmaSalesV2Variables,
  GetAmaSalesV2_getAmaSalesV2_entities_thankYouVideo,
} from 'api/ama/types/GetAmaSalesV2';
import {
  ProductTypesType,
  AmaSalesOrderBy,
  SortDirection,
} from 'api/graphql-global-types';
// Constants
import { onlyDateTimeFormat } from 'constants/global';
// Helpers
import { formatCurrencyString, getCustomerName } from 'helpers/utils';
// Ui
import Table, {
  DEFAULT_NUMBER_ITEMS_PER_PAGE,
  DEFAULT_CURRENT_PAGE,
  SortedInfo,
  TableFilter,
} from 'ui/Table';
// Components
import StoreSalesActions from '../components/StoreSalesActions';

type AmaSalesItems = {
  customerName: string;
  customerEmail: string | null;
  createdAt: string;
  type: string;
  discount: string;
  earnings: string;
  id: string;
  thankYouVideo: GetAmaSalesV2_getAmaSalesV2_entities_thankYouVideo | null;
};

type AmaSalesProps = {
  onVideoView: (videoUrl: string) => void;
};

const AmaSales: React.FC<AmaSalesProps> = ({ onVideoView }) => {
  const { storeId } = useParams<{ storeId: string | undefined }>();

  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [pageSize, setPageSize] = useState<number>(
    DEFAULT_NUMBER_ITEMS_PER_PAGE
  );

  const defaultSortInfo: SortedInfo<AmaSalesOrderBy> = {
    order: SortDirection.DESC,
    key: AmaSalesOrderBy.createdAt,
  };

  const [sort, setSortInfo] =
    useState<SortedInfo<AmaSalesOrderBy>>(defaultSortInfo);
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const getQueryVariables: any = () => {
    const input: any = {
      storeId,
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      if (key === 'createdAt') {
        return (input['createdAtDateRange'] = {
          from: Array.isArray(value) ? value?.[0] : value,
          to: Array.isArray(value) ? value?.[1] : value,
        });
      }
      input[key] = value;
    });

    return { input };
  };

  const { data, loading } = useQuery<GetAmaSalesV2, GetAmaSalesV2Variables>(
    GET_AMA_SALES_BY_STORE_V2,
    {
      variables: { ...getQueryVariables() },
      fetchPolicy: 'cache-and-network',
    }
  );
  const tableData: AmaSalesItems[] =
    data?.getAmaSalesV2?.entities?.map((sale) => {
      const {
        id,
        customer,
        ama,
        paidAt,
        discountAmount,
        requestedProfit,
        thankYouVideo,
      } = sale;
      return {
        customerName: getCustomerName(customer.firstName, customer.lastName),
        customerEmail: customer.email,
        type: ama.type,
        createdAt: moment(paidAt).format(onlyDateTimeFormat),
        discount: formatCurrencyString(discountAmount),
        earnings: formatCurrencyString(requestedProfit || ''),
        id,
        thankYouVideo,
      };
    }) || [];

  const columns = [
    {
      title: 'Customer name',
      dataIndex: 'customerName',
      fixed: 'left' as const,
      key: AmaSalesOrderBy.customerName,
      width: 90,
      sorterType: 'text',
      withSearch: true,
    },
    {
      title: 'Customer Email',
      dataIndex: 'customerEmail',
      fixed: 'left' as const,
      key: AmaSalesOrderBy.customerEmail,
      width: 160,
      sorterType: 'text',
      withSearch: true,
    },
    {
      title: 'Purchase date',
      dataIndex: 'createdAt',
      key: AmaSalesOrderBy.createdAt,
      width: 110,
      sorterType: 'date',
      withDateRangeFilter: true,
    },
    {
      title: 'AMA Type',
      dataIndex: 'type',
      width: 80,
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      align: 'center' as const,
      width: 70,
      key: AmaSalesOrderBy.discount,
      sorterType: 'number',
    },
    {
      title: 'Earnings',
      dataIndex: 'earnings',
      align: 'right' as const,
      width: 70,
      key: AmaSalesOrderBy.earnings,
      sorterType: 'number',
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      align: 'center' as const,
      fixed: 'right' as const,
      width: 70,
      render: function Actions(orderId: string, rowData: AmaSalesItems) {
        return (
          <StoreSalesActions
            orderId={orderId}
            thankYouVideo={rowData.thankYouVideo}
            onVideoView={onVideoView}
            productType={ProductTypesType.Ama}
          />
        );
      },
    },
  ];

  return (
    <Table<AmaSalesItems, AmaSalesOrderBy>
      columns={columns}
      data={tableData}
      scroll={{ x: 1200 }}
      loading={loading}
      total={data?.getAmaSalesV2?.total}
      defaultPageSize={pageSize}
      defaultCurrent={currentPage}
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      setPageSize={setPageSize}
      setCurrentPage={setCurrentPage}
      sortInfo={sort}
      setSortInfo={setSortInfo}
    />
  );
};

export default AmaSales;
