import React, { useEffect, useState } from 'react';
import { Form, Typography, InputNumber, Button, Input, Divider } from 'antd';
import { ScissorOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { useMutation, useQuery } from '@apollo/client';
// Api
import { CREATE_STREAM_CLIP } from 'api/streams/mutations';
import { GET_STREAM_CLIP } from 'api/streams/queries';
import { StreamClipStatus } from 'api/graphql-global-types';
// Types
import { GetAdminWatchStreams_adminStreams_entities_streamRecords } from 'api/streams/types/GetAdminWatchStreams';
import {
  GetStreamClip,
  GetStreamClipVariables,
  GetStreamClip_getStreamClip,
} from 'api/streams/types/GetStreamClip';
import {
  CreateStreamClip,
  CreateStreamClipVariables,
} from 'api/streams/types/CreateStreamClip';
// Helpers
import {
  calculateNoteEndTs,
  calculateNoteStartTs,
  formatTime,
  parseTime,
} from 'pages/WatchStream/helpers';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Styles
import styles from './CreateStreamRecordClip.module.scss';

type FormValues = {
  startTs: number;
  endTs: number;
  title: string;
};

const { Title } = Typography;

type CreateStreamRecordClipProps = {
  streamRecord: GetAdminWatchStreams_adminStreams_entities_streamRecords | null;
  onNewRecordCreate: () => void;
  hideClipForm: () => void;
  clearNoteIndex: () => void;
  showClipForm?: boolean;
  noteTime?: string | null;
  createClipRef: any;
  setCreateClipLoading: (loading: boolean) => void;
};

const CreateStreamRecordClip: React.FC<CreateStreamRecordClipProps> = ({
  streamRecord,
  onNewRecordCreate,
  hideClipForm,
  clearNoteIndex,
  showClipForm,
  noteTime,
  createClipRef,
  setCreateClipLoading,
}) => {
  const [newClipId, setNewClipId] = useState<string | null>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (noteTime) {
      const startTs = calculateNoteStartTs(parseTime(noteTime));
      const endTs = calculateNoteEndTs(
        parseTime(noteTime),
        streamRecord?.duration
      );
      form.setFieldsValue({ startTs, endTs, title: '' });
    }
  }, [noteTime, streamRecord, form]);

  const [createStreamClip, { loading: createStreamClipLoading }] = useMutation<
    CreateStreamClip,
    CreateStreamClipVariables
  >(CREATE_STREAM_CLIP);

  const { data, startPolling, stopPolling } = useQuery<
    GetStreamClip,
    GetStreamClipVariables
  >(GET_STREAM_CLIP, {
    variables: { input: { id: newClipId || '' } },
    pollInterval: 5000,
    skip: !newClipId,
    fetchPolicy: 'no-cache',
  });

  const newClip = data?.getStreamClip;

  const checkStatus = (newClip: GetStreamClip_getStreamClip) => {
    if (newClip?.status === StreamClipStatus.Complete) {
      stopPolling();
      setCreateClipLoading(false);
    }
    if (newClip?.status === StreamClipStatus.Error) {
      stopPolling();
      setCreateClipLoading(false);
      errorNotification(
        'Looks like something went wrong. Please try again later.'
      );
    }
    if (newClip?.status === StreamClipStatus.Processing) {
      startPolling(5000);
    }
  };

  useEffect(() => {
    if (newClip) checkStatus(newClip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newClip]);

  const handleClipCreate = async () => {
    const { title, startTs, endTs }: FormValues = form.getFieldsValue();
    const startTimestamp = Math.floor(startTs);
    const endTimestamp = Math.floor(endTs);
    if (streamRecord) {
      setCreateClipLoading(true);

      try {
        const response = await createStreamClip({
          variables: {
            input: {
              streamRecordId: streamRecord?.id,
              startTs: startTimestamp,
              endTs: endTimestamp,
              title,
            },
          },
        });

        setNewClipId(response?.data?.createStreamClip.id || '');

        form.resetFields();
        hideClipForm();
        startPolling(5000);

        successNotification('You have successfully created a new clip');
      } catch (error) {
        errorNotification(
          (error as Error).message || 'Something went wrong while note save'
        );
      }
      onNewRecordCreate();
      clearNoteIndex();
    }
  };

  const handleClipCancel = () => {
    form.resetFields();
    hideClipForm();
    clearNoteIndex();
    setCreateClipLoading(false);
  };

  return (
    <div
      ref={createClipRef}
      className={cn({
        [styles.showClipForm]: showClipForm,
        [styles.hideClipForm]: !showClipForm,
      })}
    >
      <Divider />

      <Title level={3}>Add a clip</Title>

      <Form
        form={form}
        layout="horizontal"
        name="clipForm"
        autoComplete="off"
        initialValues={{ startTs: 0, endTs: 0, title: '' }}
        onFinish={handleClipCreate}
        style={{
          display: 'flex',
          width: '650px',
          justifyContent: 'space-around',
          alignItems: 'baseline',
        }}
      >
        <Form.Item
          name="title"
          rules={[
            {
              required: true,
              message: 'Please enter the title',
            },
          ]}
        >
          <Input style={{ width: 180 }} placeholder="Clip title" />
        </Form.Item>
        <Form.Item
          name="startTs"
          rules={[
            {
              required: true,
              message: 'Please enter start time ',
            },
          ]}
        >
          <InputNumber
            min={0}
            step={1}
            style={{ width: 100 }}
            max={streamRecord?.duration || 10000}
            disabled={createStreamClipLoading}
            formatter={formatTime}
            parser={parseTime}
            data-testid="startClip"
          />
        </Form.Item>
        -
        <Form.Item
          name="endTs"
          rules={[
            {
              required: true,
              message: 'Please enter end time ',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('startTs') <= value - 1) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('End time must be after start time')
                );
              },
            }),
          ]}
        >
          <InputNumber
            min={0}
            step={1}
            style={{ width: 100 }}
            max={streamRecord?.duration || 10000}
            disabled={createStreamClipLoading}
            formatter={formatTime}
            parser={parseTime}
            data-testid="endClip"
          />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          <ScissorOutlined />
          Clip
        </Button>
        <Button type="primary" danger onClick={handleClipCancel}>
          Cancel
        </Button>
      </Form>
    </div>
  );
};

export default CreateStreamRecordClip;
