import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import cn from 'classnames';
import { useDropzone } from 'react-dropzone';
import { Button, Modal } from 'antd';
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
// Api
import { GET_PRINT_IMAGES, GET_PRINT_IMAGE_BY_ID } from 'api/merch/queries';
import { UPLOAD_PRINT_IMAGE, DELETE_PRINT_IMAGE } from 'api/merch/mutations';
// Types
import {
  GetPrintImages,
  GetPrintImagesVariables,
  GetPrintImages_getPrintImages,
} from 'api/merch/types/GetPrintImages';
import {
  GetPrintImageById,
  GetPrintImageByIdVariables,
} from 'api/merch/types/GetPrintImageById';
import {
  UploadPrintImage,
  UploadPrintImageVariables,
} from 'api/merch/types/UploadPrintImage';
import {
  DeletePrintImage,
  DeletePrintImageVariables,
} from 'api/merch/types/DeletePrintImage';
import { PrintImage } from '../MerchConstructor';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
// Styles
import styles from './PrintImages.module.scss';

export type PrintImagesProps = {
  allowSelection: boolean;
  onSelectPrintImage: (printImage: PrintImage) => void;
  printImage: PrintImage;
  initialPrintImageId?: string;
  extraColorError: string;
  designRequestId?: string | null;
};

const { confirm } = Modal;

const MIN_IMAGE_WIDTH = 1024;
const MIN_IMAGE_HEIGHT = 768;
// const MAX_IMAGE_WIDTH = 1920;
// const MAX_IMAGE_HEIGHT = 1080;

const PrintImages: React.FC<PrintImagesProps> = ({
  allowSelection,
  onSelectPrintImage,
  printImage,
  initialPrintImageId,
  extraColorError,
  designRequestId,
}) => {
  const { storeId } = useParams<{ storeId: string | undefined }>();
  const [imageId, setImageId] = useState<string | null>(null);
  const { data: printImagesData, refetch } = useQuery<
    GetPrintImages,
    GetPrintImagesVariables
  >(GET_PRINT_IMAGES, {
    variables: {
      input: {
        storeId: storeId || '',
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !storeId,
  });

  const { data: printImagesByIdData } = useQuery<
    GetPrintImageById,
    GetPrintImageByIdVariables
  >(GET_PRINT_IMAGE_BY_ID, {
    variables: {
      input: {
        printImageId: `${initialPrintImageId}`,
        userId: storeId,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !storeId || !initialPrintImageId,
  });

  const printImageById = printImagesByIdData?.getPrintImageById;

  const [uploadImage, { loading: uploadImageLoading }] = useMutation<
    UploadPrintImage,
    UploadPrintImageVariables
  >(UPLOAD_PRINT_IMAGE, {
    onCompleted: () => {
      refetch();
    },
  });

  const [deleteImage, { loading: deleteImageLoading }] = useMutation<
    DeletePrintImage,
    DeletePrintImageVariables
  >(DELETE_PRINT_IMAGE, {
    variables: {
      input: {
        printImageId: `${imageId}`,
        userId: storeId,
      },
    },
    onCompleted: () => {
      refetch();
    },
    update(cache, { data }) {
      cache.modify({
        fields: {
          getMyPrintImages(existingImagesRefs = [], { readField }) {
            return existingImagesRefs.filter(
              (imageRef: any) =>
                readField('id', imageRef) !== data?.deletePrintImage.id
            );
          },
        },
      });
    },
  });

  useEffect(() => {
    if ((!printImage || !printImage.imageURL) && printImageById) {
      onSelectPrintImage(printImageById);
    }
  }, [onSelectPrintImage, printImage, printImageById]);

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (file) {
      try {
        const img = document.createElement('img');
        const objectUrl = URL.createObjectURL(file);

        img.onload = async function () {
          const width = img.width;
          const height = img.height;

          // if (width > MAX_IMAGE_WIDTH || height > MAX_IMAGE_HEIGHT) {
          //   errorNotification(
          //     `The current image resolution is ${width}x${height}. The image should not have a resolution more than ${MAX_IMAGE_WIDTH}x${MAX_IMAGE_HEIGHT}.`
          //   );
          // }

          if (width < MIN_IMAGE_WIDTH || height < MIN_IMAGE_HEIGHT) {
            errorNotification(`
            This image is too low resolution for merch. Please upload a design that’s at least 300 DPI and ${MIN_IMAGE_WIDTH}x${MIN_IMAGE_HEIGHT} dimensions.`);
          } else {
            const input = designRequestId
              ? {
                  designRequestId,
                  userId: storeId,
                  image: file,
                }
              : {
                  userId: storeId,
                  image: file,
                };

            await uploadImage({
              variables: {
                input,
              },
            });

            successNotification('Image successfully added');
          }

          URL.revokeObjectURL(objectUrl);
        };

        img.src = objectUrl;
      } catch (error) {
        errorNotification('Something went wrong');
        console.log('add image', error);
      }
    }
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: 'image/jpg, image/jpeg, image/png',
  });

  const handleRemoveModalClose = () => {
    setImageId(null);
  };

  const handleImageRemove = async () => {
    try {
      await deleteImage();

      successNotification('Image successfully removed');
    } catch (error) {
      successNotification('Something went wrong');
      console.log('remove image', error);
    }

    setImageId(null);
  };

  const handleRemoveButtonClick = (
    imageId: string,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();

    setImageId(imageId);

    confirm({
      title: 'Are you sure you want to remove this image?',
      content: "If you remove the image, you won't be able to use it.",
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: handleImageRemove,
      onCancel: handleRemoveModalClose,
    });
  };

  const handleImageItemClick = (imageItem: GetPrintImages_getPrintImages) => {
    if (!allowSelection) {
      errorNotification('Please select a color first');
    }
    if (extraColorError !== '') {
      errorNotification(extraColorError);
    }
    if (allowSelection && !extraColorError && imageItem.imageURL) {
      onSelectPrintImage(imageItem);
    }
  };

  return (
    <>
      <div className={styles.root}>
        <h2 className={styles.designTitle}>Select a logo</h2>

        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Button
            className={styles.uploadDesignBtn}
            color="harvest-gold"
            loading={uploadImageLoading || deleteImageLoading}
          >
            upload design
          </Button>
        </div>

        <div className={styles.uploadImages}>
          {printImagesData?.getPrintImages.map((item) => {
            return (
              <div
                tabIndex={0}
                role="button"
                className={cn(styles.uploadImageItem, {
                  [styles.active]: printImage?.id === item.id,
                })}
                key={item.id}
                onClick={() => handleImageItemClick(item)}
                onKeyPress={() => handleImageItemClick(item)}
              >
                <div className={styles.imageInfo}>
                  <img
                    className={styles.image}
                    src={item.imageURL || ''}
                    height={60}
                    width={60}
                    alt="Uploaded file"
                  />
                </div>

                <button
                  className={styles.removeIcon}
                  onClick={(e) => handleRemoveButtonClick(item.id, e)}
                >
                  <CloseCircleOutlined />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default PrintImages;
