import { useQuery, QueryResult } from '@apollo/client';
import { useParams } from 'react-router-dom';
// Api
import { GET_THANK_YOU_VIDEO_TEMPLATE } from 'api/thankYouVideo/queries';
// Types
import {
  GetThankYouVideoTemplate,
  GetThankYouVideoTemplateVariables,
} from 'api/thankYouVideo/types/GetThankYouVideoTemplate';
import { ProductTypesType } from 'api/graphql-global-types';

export const useGetThankYouVideoTemplate = (
  type: ProductTypesType
): QueryResult<GetThankYouVideoTemplate> => {
  const { storeId } = useParams<{ storeId: string | undefined }>();

  const data = useQuery<
    GetThankYouVideoTemplate,
    GetThankYouVideoTemplateVariables
  >(GET_THANK_YOU_VIDEO_TEMPLATE, {
    variables: {
      input: {
        storeId,
        productType: type,
      },
    },
    skip: !storeId,
    fetchPolicy: 'cache-and-network',
  });

  return data;
};
