import React, { useState } from 'react';
import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button';
// Components
import AssignVideoEditorModal from './AssignVideoEditorModal';

type AssignVideoEditorProps = {
  videoEditRequestId?: string;
  buttonTitle?: string;
  buttonType?: ButtonType;
};

const AssignVideoEditor: React.FC<AssignVideoEditorProps> = ({
  videoEditRequestId,
  buttonTitle = 'Assign',
  buttonType = 'primary',
}) => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const handleShowModal = () => {
    setModalVisible(true);
  };

  const handleSetModalVisibility = (isVisible: boolean) => {
    setModalVisible(isVisible);
  };

  return (
    <>
      <Button type={buttonType} onClick={handleShowModal}>
        {buttonTitle}
      </Button>

      <AssignVideoEditorModal
        videoEditRequestId={videoEditRequestId}
        isModalVisible={isModalVisible}
        setModalVisible={handleSetModalVisibility}
      />
    </>
  );
};

export default AssignVideoEditor;
