import { gql } from '@apollo/client';
import { DesignRequestsFields, DesignersFields } from './fragments';

export const ARCHIVE_DESIGN = gql`
  mutation ArchiveMerchDesign($input: AdminArchiveMerchDesignInput!) {
    archiveMerchDesign(input: $input) {
      ...DesignRequestsFields
    }
  }
  ${DesignRequestsFields}
`;

export const ASSIGN_DESIGN_TO_DESIGNER = gql`
  mutation AssignMerchDesignToDesigner($input: AdminAssignToDesignerInput!) {
    assignMerchDesignToDesigner(input: $input) {
      ...DesignRequestsFields
    }
  }
  ${DesignRequestsFields}
`;

export const CREATE_DESIGNER = gql`
  mutation CreateDesigner($input: DesignerCreateInput!) {
    createDesigner(input: $input) {
      ...DesignersFields
    }
  }
  ${DesignersFields}
`;

export const EDIT_DESIGNER = gql`
  mutation EditDesigner($input: DesignerEditInput!) {
    editDesigner(input: $input) {
      ...DesignersFields
    }
  }
  ${DesignersFields}
`;

export const DEACTIVATE_DESIGNER = gql`
  mutation DeactivateDesigner($id: String!) {
    deactivateDesigner(id: $id) {
      ...DesignersFields
    }
  }
  ${DesignersFields}
`;

export const REJECT_AND_SEND_BACK_MERCH_DESIGN = gql`
  mutation RejectAndSendBackMerchDesign(
    $input: ManagerRejectMerchDesignInput!
  ) {
    rejectAndSendBackMerchDesign(input: $input) {
      ...DesignRequestsFields
    }
  }
  ${DesignRequestsFields}
`;

export const REJECT_AND_REASSIGN_DESIGN = gql`
  mutation RejectAndReassignDesign(
    $input: ManagerRejectAndReassignMerchDesignInput!
  ) {
    rejectAndReassignDesign(input: $input) {
      ...DesignRequestsFields
    }
  }
  ${DesignRequestsFields}
`;

export const CREATE_PRESIGNED_URL_FOR_SOCIAL_PACK_REQUEST = gql`
  mutation CreatePresignedUrlForSocialPack(
    $input: CreateDesignImagePresignedUrl!
  ) {
    createPresignedUrlForSocialPack(input: $input) {
      key
      url
      fields
    }
  }
`;

export const SEND_MERCH_DESIGN_TO_REVIEW = gql`
  mutation SendMerchDesignToReview($input: SendMerchDesignToReviewInput!) {
    sendMerchDesignToReview(input: $input) {
      ...DesignRequestsFields
    }
  }
  ${DesignRequestsFields}
`;

export const ACCEPT_MERCH_DESIGN = gql`
  mutation AcceptMerchDesign($input: AcceptMerchDesignInput!) {
    acceptMerchDesign(input: $input) {
      ...DesignRequestsFields
    }
  }
  ${DesignRequestsFields}
`;

export const APPROVE_BY_MANAGER = gql`
  mutation ApproveByManager($input: ApproveMerchDesignByManagerInput!) {
    approveByManager(input: $input) {
      ...DesignRequestsFields
    }
  }
  ${DesignRequestsFields}
`;

export const ADMIN_CREATE_MERCH_DESIGN = gql`
  mutation AdminCreateMerchDesign($input: AdminCreateMerchDesignInput!) {
    adminCreateMerchDesign(input: $input) {
      id
      status
      type
    }
  }
`;

export const ADMIN_EDIT_MERCH_DESIGN = gql`
  mutation AdminEditMerchDesign($input: AdminEditMerchDesignInput!) {
    adminEditMerchDesign(input: $input) {
      id
      status
      type
    }
  }
`;

export const ADMIN_EDIT_MERCH_DESIGN_WITH_LOGO = gql`
  mutation AdminEditMerchDesignWithLogo(
    $input: AdminEditMerchDesignWithLogoInput!
  ) {
    adminEditMerchDesignWithLogo(input: $input) {
      id
      status
      type
    }
  }
`;

export const CHANGE_DESIGN_PRIORITY = gql`
  mutation ChangeMerchDesignPriority($input: DesignMerchChangePriorityInput!) {
    changeMerchDesignPriority(input: $input) {
      ...DesignRequestsFields
    }
  }
  ${DesignRequestsFields}
`;

export const CREATE_PRESIGNED_URL_FOR_EXAMPLE_IMAGES = gql`
  mutation CreatePresignedUrlForExampleImages(
    $input: CreateDesignImageExamplePresignedUrlInput!
  ) {
    createPresignedUrlForExampleImages(input: $input) {
      fields
      key
      url
    }
  }
`;
