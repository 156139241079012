// Types
import { Rule } from 'antd/lib/form';

export const validationRules: Record<string, Rule[]> = {
  nameOrSurname: [
    {
      required: true,
      message: 'This field should not be empty',
    },
    {
      required: true,
      max: 20,
      message: 'The allowed lenght is 20 characters',
    },
    {
      required: true,
      pattern: /^[a-z ,.'-]+$/i,
      message: 'Please enter a valid string',
    },
  ],
  city: [
    {
      required: true,
      message: 'This field should not be empty',
    },
    {
      required: true,
      min: 1,
      message: 'The min lenght is 1 character',
    },
    {
      required: true,
      max: 20,
      message: 'The allowed lenght is 20 characters',
    },
    {
      required: true,
      pattern: /^[a-z ,.'-]+$/i,
      message: 'Please enter a valid city',
    },
  ],
  addressLine: [
    {
      required: true,
      message: 'This field should not be empty',
    },
    {
      required: true,
      min: 1,
      message: 'The min lenght is 1 character',
    },
    {
      required: true,
      max: 30,
      message: 'The allowed lenght is 30 characters',
    },
  ],
  email: [
    {
      required: true,
      pattern: /\S+@\S+\.\S+/,
      message: 'Please enter a valid e-mail',
    },
  ],
  phoneNumber: [
    {
      required: true,
      message: 'Please enter a phone number',
    },
  ],
};
