import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
// Api
import { GET_ADMIN_PROMO_CODE } from 'api/promocodes/queries';
// Types
import {
  GetAdminPromocode,
  GetAdminPromocodeVariables,
} from 'api/promocodes/types/GetAdminPromocode';
// Helpers
import {
  preparePromoCodeData,
  renderPromoCodeList,
} from './products-list-helpers';
// UI
import { Button, Modal, Table } from 'antd';

type ProductsListProps = {
  promoCodeId: string;
};

const ProductsList: React.FC<ProductsListProps> = ({ promoCodeId }) => {
  const [productsModalIsVisible, setProductsModalIsVisible] =
    useState<boolean>(false);

  const [getAdminPromocode, { data, loading }] = useLazyQuery<
    GetAdminPromocode,
    GetAdminPromocodeVariables
  >(GET_ADMIN_PROMO_CODE, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (productsModalIsVisible) {
      getAdminPromocode({ variables: { input: { id: promoCodeId } } });
    }
  }, [promoCodeId, productsModalIsVisible, getAdminPromocode]);

  const otToggleModal = () => {
    setProductsModalIsVisible(!productsModalIsVisible);
  };

  const prepared = preparePromoCodeData(data?.getAdminPromocode);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'storeFullName',
      key: 'id',
    },
    {
      title: 'Products',
      dataIndex: 'products',
      key: 'products',
      width: 350,
      render: renderPromoCodeList,
    },
    {
      title: 'AMAs',
      dataIndex: 'amas',
      key: 'amas',
      render: renderPromoCodeList,
    },
    {
      title: 'Streams',
      dataIndex: 'streams',
      key: 'streams',
      render: renderPromoCodeList,
    },
  ];

  return (
    <>
      <Button type="link" onClick={otToggleModal}>
        See all products
      </Button>

      <Modal
        destroyOnClose
        width={920}
        title={`"${
          data?.getAdminPromocode?.name || ''
        }" promo code products list`}
        visible={productsModalIsVisible}
        onOk={otToggleModal}
        onCancel={otToggleModal}
        footer={null}
      >
        <Table
          pagination={false}
          dataSource={prepared}
          columns={columns}
          rowKey="storeId"
          loading={loading}
        />
      </Modal>
    </>
  );
};

export default ProductsList;
