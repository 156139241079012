import { gql } from '@apollo/client';

export const HashtagFields = gql`
  fragment HashtagFields on UserHashtag {
    id
    name
    storeCount
    sports {
      id
      name
    }
  }
`;
