import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Space, Button } from 'antd';
// Hooks
import {
  useGetUserTypeFromRoute,
  useDefaultStoreRedirectOnEmptyId,
} from 'hooks';
// Api
import { GET_AMA_REQUESTS_PAGINATED } from 'api/ama/queries';
// Types
import { SortedInfo } from 'ui/Table';
import {
  AmaRequestsPaginated,
  AmaRequestsPaginatedVariables,
  AmaRequestsPaginated_amaRequestsPaginated_entities,
} from 'api/ama/types/AmaRequestsPaginated';
import { SortDirection, AmaRequestOrderBy } from 'api/graphql-global-types';
// Constants
import {
  SETUP_ATHLETE_AMA_GEN,
  SETUP_CONTENT_CREATOR_AMA_GEN,
  SETUP_ORGANIZATION_AMA_GEN,
} from 'constants/routes';
// Components
import Actions from './components/Actions';
// Helpers
import { calculateRemainingDays } from 'helpers/utils';
// UI
import Table from 'ui/Table';
// Styles
import './styles.scss';

const ManageAmaRequests: React.FC = () => {
  useDefaultStoreRedirectOnEmptyId();

  const { isAthlete, isContentCreator, isOrganization } =
    useGetUserTypeFromRoute();
  const history = useHistory();
  const { storeId } = useParams<{ storeId: string | undefined }>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [
    sort,
    // setSortInfo
  ] = useState<SortedInfo<any>>({
    order: SortDirection.DESC,
    key: AmaRequestOrderBy.CreatedAt,
  });

  const { data, loading } = useQuery<
    AmaRequestsPaginated,
    AmaRequestsPaginatedVariables
  >(GET_AMA_REQUESTS_PAGINATED, {
    variables: {
      input: {
        storeId,
        direction: sort.order,
        orderBy: sort.key,
        limit: pageSize,
        offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !storeId,
  });

  const handleSetupAma = () => {
    let path = '/';

    if (isAthlete) {
      path = `${SETUP_ATHLETE_AMA_GEN}/${storeId}`;
    }

    if (isOrganization) {
      path = `${SETUP_ORGANIZATION_AMA_GEN}/${storeId}`;
    }

    if (isContentCreator) {
      path = `${SETUP_CONTENT_CREATOR_AMA_GEN}/${storeId}`;
    }

    history.push(path);
  };

  // TODO: Add 'key' from type, that will receive BE when filters will be ready
  const columns = [
    {
      title: 'Type',
      dataIndex: ['ama', 'type'],
      fixed: 'left' as const,
      align: 'left' as const,
    },
    {
      title: 'From',
      dataIndex: 'from',
      fixed: 'left' as const,
      align: 'center' as const,
    },
    {
      title: 'To',
      dataIndex: 'to',
      fixed: 'left' as const,
      align: 'center' as const,
    },
    {
      title: 'Text instructions',
      dataIndex: 'instructions',
      fixed: 'left' as const,
    },
    {
      title: 'Days remaining',
      dataIndex: 'dateToResponse',
      align: 'center' as const,
      render: (dateToResponse: string) =>
        `${calculateRemainingDays(dateToResponse)} days`,
    },
    {
      title: 'Status',
      dataIndex: 'orderStatus',
      align: 'center' as const,
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      align: 'center' as const,
      width: 120,
      render: function ActionsWrap(text: any, record: any) {
        return <Actions amaId={record.id} orderStatus={record.orderStatus} />;
      },
    },
  ];

  return (
    <div className="manage-ama-requests">
      <Space
        direction="vertical"
        size="middle"
        align="end"
        className="manage-ama-requests__cta-wrapper"
      >
        <Button
          type="primary"
          onClick={handleSetupAma}
          className="manage-ama-requests__headeer__cta"
        >
          Edit my AMA
        </Button>
      </Space>

      <Table<AmaRequestsPaginated_amaRequestsPaginated_entities>
        columns={columns}
        data={data?.amaRequestsPaginated?.entities}
        scroll={{ x: 800 }}
        loading={loading}
        total={data?.amaRequestsPaginated?.total}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default ManageAmaRequests;
