import React, { useState } from 'react';
import { Button, Badge, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
// Types
import { GetUpcomingStreams_adminStreams_entities } from 'api/streams/types/GetUpcomingStreams';
// Constants
import { WATCH_STREAM_GEN } from 'constants/routes';
// Helpers
import { getStoreName, getPublicStreamDate } from 'helpers/utils';
// Styles
import styles from './StreamsListItem.module.scss';
// Components
import StreamPurchasesModal from 'components/common/Stream/StreamPurchasesModal/StreamPurchasesModal';

export type LocationStateType = {
  fromCalendar?: boolean;
};

type StreamsListItemProps = {
  stream: GetUpcomingStreams_adminStreams_entities;
};

const StreamsListItem = ({ stream }: StreamsListItemProps): JSX.Element => {
  const history: History<LocationStateType> = useHistory();
  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const handleModalToggle = () => {
    setModalVisible((prev) => !prev);
  };

  const handleStreamDetailsButtonClick = () => {
    history.push({
      pathname: `${WATCH_STREAM_GEN}/${stream.id}`,
      state: { fromCalendar: true },
    });
  };

  return (
    <>
      <Badge
        status="success"
        text={
          <Space direction="horizontal" size="middle">
            <Button
              type="link"
              className={styles.cta}
              onClick={handleStreamDetailsButtonClick}
            >
              {`
              ${stream.name}, 
              ${getStoreName(stream?.store)},
              ${getPublicStreamDate(stream?.scheduleDate, stream?.timeZone)}
            `}
            </Button>

            {Number(stream.numberOfOrders) > 0 && (
              <Button
                type="link"
                className={styles.ctaSecond}
                onClick={handleModalToggle}
              >
                ({stream.numberOfOrders})
              </Button>
            )}
          </Space>
        }
      />

      <StreamPurchasesModal
        streamId={stream.id}
        streamName={stream.name}
        isFree={stream.isFree}
        isVisible={isModalVisible}
        onCancel={handleModalToggle}
      />
    </>
  );
};

export default StreamsListItem;
