import React, { createContext, useState, useContext } from 'react';
// Types
import { TreeDataEntity } from 'pages/PromoCodes/data/tree-data.entity';

type StoresSelectedProductsContext = {
  selectedStoreItems: Record<string, TreeDataEntity[]>;
  removeStoreSelectedItems: (storeId: string) => void;
  setSelected: (storeId: string, items: TreeDataEntity[]) => void;
  getSelectedProducts: () => TreeDataEntity[];
  flushSelected: () => void;
};

const Context = createContext<StoresSelectedProductsContext>(
  {} as StoresSelectedProductsContext
);

const StoresSelectedProductsProvider: React.FC = ({ children }) => {
  const [selectedStoreItems, setSelectedItems] = useState({});

  const setSelected = (storeId: string, items: TreeDataEntity[]): void => {
    setSelectedItems((prev) => ({ ...prev, [storeId]: items }));
  };

  const removeStoreSelectedItems = (storeId: string): void => {
    setSelectedItems((prev) => {
      const newSelected = Object.entries(prev).filter(
        ([key]) => key !== storeId
      );

      if (!newSelected.length) {
        return {};
      } else {
        return Object.fromEntries(newSelected);
      }
    });
  };

  const getSelectedProducts = (): TreeDataEntity[] => {
    return Object.values(selectedStoreItems).reduce<TreeDataEntity[]>(
      (acc, next) => acc.concat(next as TreeDataEntity[]),
      []
    );
  };

  const flushSelected = (): void => {
    setSelectedItems({});
  };

  const value = {
    removeStoreSelectedItems: removeStoreSelectedItems,
    setSelected,
    selectedStoreItems: selectedStoreItems,
    getSelectedProducts,
    flushSelected,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useStoresSelectProducts = (): StoresSelectedProductsContext => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      'useStoresSelectProducts must be used within a StoresSelectedProductsProvider'
    );
  }

  return context;
};

export { StoresSelectedProductsProvider, useStoresSelectProducts };
