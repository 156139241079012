import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { Typography, AutoComplete } from 'antd';
// Api
import { SEARCH_MEMORABILIA } from 'api/interview/queries';
// Types
import {
  SearchMemorabilia,
  SearchMemorabiliaVariables,
} from 'api/interview/types/SearchMemorabilia';
// Components
import { AutoCompleteOption } from '../SendInviteModal/SendInviteModal';
// Styles
import styles from './SearchProducts.module.scss';

const { Text } = Typography;

type SearchProductProps = {
  storeIds: string[];
  selectedProductOption: AutoCompleteOption | null;
  setSelectedProductOption: React.Dispatch<
    React.SetStateAction<AutoCompleteOption | null>
  >;
};

export const SearchProductsComponent = ({
  storeIds,
  selectedProductOption,
  setSelectedProductOption,
}: SearchProductProps): JSX.Element => {
  const [searchMemorabilia, { data }] = useLazyQuery<
    SearchMemorabilia,
    SearchMemorabiliaVariables
  >(SEARCH_MEMORABILIA);

  const [merchOptions, setMerchOptions] = useState<AutoCompleteOption[]>([]);

  const handleSelect = (value: string, option: AutoCompleteOption): void => {
    setSelectedProductOption(option);
  };

  const handleOnClear = () => {
    setSelectedProductOption(null);
  };

  const handleOnSearch = useMemo(() => {
    const loadOptions = (searchTerm: string) => {
      searchMemorabilia({
        variables: {
          input: {
            searchTerm: searchTerm,
            storeIds,
          },
        },
      });
    };

    return debounce(loadOptions, 400);
  }, [searchMemorabilia, storeIds]);

  useEffect(() => {
    if (data?.getMemorabilia?.entities) {
      setMerchOptions(
        data?.getMemorabilia?.entities.map((product) => ({
          value: product.id,
          label: product.title || '',
        }))
      );
    }
  }, [data]);

  return (
    <div className={styles.streamSection}>
      <Text>Product/Memorabilia:</Text>
      <AutoComplete
        id="product-search"
        allowClear
        className={styles.autoComplete}
        placeholder="Search Product Name"
        options={merchOptions}
        value={selectedProductOption?.label}
        onSearch={handleOnSearch}
        onSelect={handleSelect}
        onClear={handleOnClear}
      />

      {selectedProductOption && (
        <Text className={styles.text}>
          Selected product: {selectedProductOption?.label}
        </Text>
      )}
    </div>
  );
};

export default SearchProductsComponent;
