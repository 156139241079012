// Types
import { CampaignTaskType } from 'api/graphql-global-types';

export const getContentTypeText = (
  contentTypes: CampaignTaskType[]
): string[] => {
  if (!contentTypes) return [];

  return contentTypes.map((type) => {
    switch (type) {
      case CampaignTaskType.IG_LIVE:
        return 'Instagram Live';
      case CampaignTaskType.IG_POST:
        return 'Instagram Post';
      case CampaignTaskType.IG_REEL:
        return 'Instagram Reel';
      case CampaignTaskType.IG_STORY:
        return 'Instagram Story';
      case CampaignTaskType.TT_VIDEO:
        return 'TikTok Video';
      default:
        return 'Unknown';
    }
  });
};
