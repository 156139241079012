import React from 'react';
import { Modal } from 'antd';
import moment from 'moment';
// Types
import { GetVideoEditRequestsForEditors_getVideoEditRequests_entities_comments } from 'api/videoLab/types/GetVideoEditRequestsForEditors';
// UI
import Table from 'ui/Table';

type CommentsModalProps = {
  show: boolean;
  onClose: () => void;
  data: GetVideoEditRequestsForEditors_getVideoEditRequests_entities_comments[];
};

const CommentsModal: React.FC<CommentsModalProps> = ({
  show,
  data,
  onClose,
}) => {
  const columns = [
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      align: 'center' as const,
      width: 100,
      render: (createdAt: string) => moment(createdAt).format('ll'),
    },
    {
      title: 'Comment',
      dataIndex: 'feedback',
      align: 'left' as const,
      width: 280,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      align: 'left' as const,
      width: 280,
    },
  ];

  return (
    <Modal
      title="Editing request comments"
      visible={show}
      footer={null}
      onCancel={onClose}
      width={570}
    >
      <Table<GetVideoEditRequestsForEditors_getVideoEditRequests_entities_comments>
        columns={columns}
        data={data}
        scroll={{ x: 430 }}
        total={data.length}
        defaultPageSize={5}
      />
    </Modal>
  );
};

export default CommentsModal;
