import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Upload, Space, Modal, Pagination } from 'antd';
import {
  UploadOutlined,
  CheckOutlined,
  DeleteOutlined,
  EyeOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import cn from 'classnames';
//Api
import {
  ADMIN_CREATE_GALLERY_VIDEO,
  ADMIN_DELETE_GALLERY_VIDEO,
} from 'api/homePageSetup/mutations';
import { GET_ADMIN_GALLERY_VIDEOS } from 'api/homePageSetup/querries';
// Types
import { UploadRequestOption } from 'rc-upload/lib/interface';
import {
  AdminGalleryVideos,
  AdminGalleryVideosVariables,
} from 'api/homePageSetup/types/AdminGalleryVideos';
import { AdminGetGalleryVideosInput } from 'api/graphql-global-types';
// Ui
import { errorNotification } from 'ui/Notification';
import VideoPreview from 'ui/VideoPreview/VideoPreview';

import './styles.scss';

const DEFAULT_NUMBER_ITEMS_PER_PAGE = 10;
const DEFAULT_CURRENT_PAGE = 1;

type UploadVideoModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  title: string;
  onVideoChange: (url: string, id: string) => void;
  acceptFileType?: string;
};

function beforeUpload(file: File): boolean {
  const isMp4 = file.type === 'video/mp4';
  if (!isMp4) {
    errorNotification('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 10240 / 10240 < 2;
  if (!isLt2M) {
    errorNotification('Video should be smaller than 20MB!');
  }
  return isMp4 && isLt2M;
}

const UploadVideoModal = ({
  isVisible,
  onCancel,
  title,
  onVideoChange,
  acceptFileType = '.mp4',
}: UploadVideoModalProps): JSX.Element => {
  const [rowIndex, setRowIndex] = useState<number>(-1);
  const [activeVideoUrl, setActiveVideoUrl] = useState<string>('');
  const [activeVideoId, setActiveVideoId] = useState<string>('');
  const [isPreviewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewVideo, setPreviewVideo] = useState<string | undefined>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const getAdminGetGalleryVideosInput = (): AdminGalleryVideosVariables => {
    const input: AdminGetGalleryVideosInput = {
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    return { input };
  };

  const { data } = useQuery<AdminGalleryVideos, AdminGalleryVideosVariables>(
    GET_ADMIN_GALLERY_VIDEOS,
    {
      variables: {
        ...getAdminGetGalleryVideosInput(),
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const [adminCreateGalleryVideo, { loading }] =
    useMutation<AdminGalleryVideos>(ADMIN_CREATE_GALLERY_VIDEO);

  const [adminDeleteGalleryVideo] = useMutation<AdminGalleryVideos>(
    ADMIN_DELETE_GALLERY_VIDEO
  );

  const customRequest = async ({
    data,
    file,
    onSuccess,
  }: UploadRequestOption) => {
    try {
      await adminCreateGalleryVideo({
        variables: {
          input: {
            file,
          },
        },
        refetchQueries: [
          {
            query: GET_ADMIN_GALLERY_VIDEOS,
            variables: {
              ...getAdminGetGalleryVideosInput(),
            },
          },
        ],
      });
    } catch (error) {
      errorNotification((error as Error)?.message);
    }

    if (onSuccess) {
      onSuccess(data, file as any);
    }
  };

  const handleOk = () => {
    onVideoChange(activeVideoUrl, activeVideoId);
    onCancel();
  };

  const handleDeleteVideo = async (id: string) => {
    try {
      await adminDeleteGalleryVideo({
        variables: {
          input: {
            id,
          },
        },
        refetchQueries: [
          {
            query: GET_ADMIN_GALLERY_VIDEOS,
            variables: {
              ...getAdminGetGalleryVideosInput(),
            },
          },
        ],
      });
    } catch (error) {
      errorNotification((error as Error)?.message);
    }
  };

  const mouseIn = (id: number) => {
    setRowIndex(id);
  };

  const mouseOut = () => {
    setRowIndex(-1);
  };

  const handleCancelPreview = (): void => {
    setPreviewVisible(false);
    setPreviewVideo('');
  };

  const handleShowVideoPreview = async (url: string) => {
    setPreviewVisible(true);
    setPreviewVideo(url);
  };

  const handleChangePage = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    setPageSize(pageSize || 0);
  };

  const handleSetActiveVideo = (url: string, id: string) => {
    setActiveVideoUrl(url);
    setActiveVideoId(id);
  };

  return (
    <Modal
      visible={isVisible}
      width={610}
      onOk={handleOk}
      onCancel={onCancel}
      title={title}
      okButtonProps={{ disabled: !activeVideoUrl }}
    >
      <Space className="video-modal-container" direction="vertical">
        <Upload
          showUploadList={false}
          beforeUpload={beforeUpload}
          customRequest={customRequest}
          accept={acceptFileType}
          multiple
        >
          <Button
            icon={loading ? <LoadingOutlined /> : <UploadOutlined />}
            disabled={loading}
          >
            Upload
          </Button>
        </Upload>
      </Space>
      <div className="upload-video-modal-video-wrapper">
        {data?.adminGalleryVideos.entities.map((item, index) => {
          const onSelect = () => {
            handleSetActiveVideo(item.url, item.id);
          };

          const onShow = async () => {
            await handleShowVideoPreview(item.url);
          };

          const onDelete = async () => {
            await handleDeleteVideo(item.id);
          };

          return (
            <div
              className={cn('upload-video-modal-video-block', {
                'upload-video-modal-video-block-active':
                  activeVideoUrl === item.url,
              })}
              key={item.id}
              onMouseEnter={() => mouseIn(index)}
              onMouseLeave={mouseOut}
            >
              <VideoPreview
                video={item.url}
                show={Boolean(item)}
                videoClassName="gallery-video"
                noControl
              />

              {rowIndex === index && (
                <div className="upload-video-modal-action-block-wrapper">
                  <div className="upload-video-modal-action-block">
                    <CheckOutlined
                      className="video-modal-button"
                      onClick={onSelect}
                    />
                    <EyeOutlined
                      className="video-modal-button"
                      onClick={onShow}
                    />
                    <DeleteOutlined
                      className="video-modal-button"
                      onClick={onDelete}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {data &&
        data?.adminGalleryVideos.total > DEFAULT_NUMBER_ITEMS_PER_PAGE && (
          <Pagination
            onChange={handleChangePage}
            defaultCurrent={DEFAULT_CURRENT_PAGE}
            total={data?.adminGalleryVideos.total}
            current={currentPage}
          />
        )}
      <Modal
        visible={isPreviewVisible}
        footer={null}
        onCancel={handleCancelPreview}
        title="Video Preview"
        width="90vw"
      >
        <VideoPreview
          video={previewVideo}
          show={Boolean(previewVideo)}
          videoClassName="video-preview"
          wrapperClassName="video-preview-wrapper"
        />
      </Modal>
    </Modal>
  );
};

export default UploadVideoModal;
