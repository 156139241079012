import React from 'react';
import { Card } from 'antd';
// Types
import { CtaOption } from '../../index';
// Components
import SetupCtaItem, { CtaItemType } from './components/SetupCtaItem';

export type SetupCtaProps = {
  selectedItems: CtaOption[];
  onInputChange: React.Dispatch<React.SetStateAction<CtaOption[]>>;
};

const SetupCta: React.FC<SetupCtaProps> = ({
  selectedItems,
  onInputChange,
}) => {
  const handleCtaChange = (
    index: number,
    data: string | boolean,
    field: string,
    mediaId?: string
  ) => {
    const newCta = [...selectedItems];
    if (mediaId && field === 'image' && typeof data !== 'boolean') {
      newCta[index] = {
        ...newCta[index],
        image: data,
        mediaId,
      };
    } else {
      newCta[index] = { ...newCta[index], [field]: data };
    }
    onInputChange(newCta);
  };

  return (
    <>
      <Card title="Setup Merch Cta" style={{ width: '100%' }}>
        <SetupCtaItem
          index={0}
          onCtaChange={handleCtaChange}
          item={selectedItems[0]}
          type={CtaItemType.Merch}
        />
      </Card>
      <Card title="Setup Athlete Cta" style={{ width: '100%' }}>
        <SetupCtaItem
          index={1}
          onCtaChange={handleCtaChange}
          item={selectedItems[1]}
          type={CtaItemType.Enroll}
        />
      </Card>
    </>
  );
};

export default SetupCta;
