import React, { useCallback, useEffect, useState, useMemo } from 'react';
import cn from 'classnames';
import { isFirefox, isMobileSafari, isChrome } from 'react-device-detect';
// Types
import { VideoDimensions } from 'helpers/media';
// Helpers
import { getVideoDimensionsOf, getCropArea } from 'helpers/media';
// Ui
import Loader from 'uiShared/Loader/Loader';
import Text from 'uiShared/Text/Text';
// Ui2
import Button from 'uiShared/Button/Button';
// Components
import CropVideo, {
  CropArea,
  CropVideoNaturalMediaSize,
} from 'components/common/CropVideo/CropVideo';

import styles from './VideoPreview.module.scss';

export type VideoDeviceSize = 'regular' | 'small' | 'onboarding';

type Props = {
  video: string | null;
  withToggle?: boolean;
  show?: boolean;
  onError?: () => void;
  videoPreviewNotSupported?: boolean;
  isCropVisible?: boolean;
  onCropVideoComplete?: (area: CropArea) => void;
  cropAspect?: number;
  cropNotSupported?: boolean;
  setCropNotSupported?: React.Dispatch<React.SetStateAction<boolean>>;
  wrapperClassName?: string;
  videoClassName?: string;
  deviceSize?: VideoDeviceSize;
  videoInDeviceIsLoading?: boolean;
  successUploadMessage?: string | JSX.Element | null;
  errorMessage?: string | JSX.Element | null;
};

const VideoPreview: React.FC<Props> = ({
  video,
  withToggle,
  show,
  onError,
  videoPreviewNotSupported,
  isCropVisible,
  cropAspect = 535 / 1182,
  cropNotSupported,
  setCropNotSupported,
  onCropVideoComplete,
  wrapperClassName,
  videoClassName,
  deviceSize,
  successUploadMessage = 'Your video has been successfully uploaded.',
  errorMessage,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);

  const setCropAreaFromDimensions = useCallback(() => {
    setLoading(true);
    getVideoDimensionsOf(video || '').then((videoDimensions) => {
      if (!videoDimensions && setCropNotSupported) {
        setCropNotSupported(true);
      }

      let cropArea: CropArea;

      if (videoPreviewNotSupported) {
        const originalWidth = videoDimensions?.width || 0;
        const originalHeight = videoDimensions?.height || 0;
        cropArea = getCropArea(originalWidth, originalHeight, cropAspect);

        onCropVideoComplete && onCropVideoComplete(cropArea);
      }
      setLoading(false);
    });
  }, [
    video,
    cropAspect,
    onCropVideoComplete,
    setCropNotSupported,
    videoPreviewNotSupported,
  ]);

  useEffect(() => {
    setIsVisible(!!show);
  }, [show]);

  useEffect(() => {
    setCropAreaFromDimensions();
  }, [setCropAreaFromDimensions, video]);

  const src: string | null = useMemo(
    () => (isFirefox || isChrome ? video : video ? `${video}#t=0.001` : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [video, isFirefox, isMobileSafari]
  );

  const handleVideoError = () => {
    if (onError) {
      setLoading(true);
      onError();
    }
  };

  const handleLoadedMetadata = () => {
    setLoading(false);
  };

  const onCropVideo = async (
    area: CropArea,
    naturalMediaSize: CropVideoNaturalMediaSize
  ) => {
    await getVideoDimensionsOf(video || '').then((dimension) => {
      const videoDimensions: VideoDimensions | null = dimension;

      if (!videoDimensions && videoPreviewNotSupported && setCropNotSupported) {
        setCropNotSupported(true);
      }

      let cropArea: CropArea = area;

      if (videoPreviewNotSupported) {
        const { naturalWidth, naturalHeight } = naturalMediaSize;
        const originalWidth =
          naturalWidth > 0 ? naturalWidth : videoDimensions?.width || 0;
        const originalHeight =
          naturalHeight > 0 ? naturalHeight : videoDimensions?.height || 0;
        cropArea = getCropArea(originalWidth, originalHeight, cropAspect);
      }

      onCropVideoComplete && onCropVideoComplete(cropArea);
    });
    setLoading(false);
  };

  const videoBasicProps = {
    onError: handleVideoError,
    onLoadedMetadata: handleLoadedMetadata,
    preload: 'metadata',
    [deviceSize ? 'deviceVideoClassName' : 'className']: cn(
      styles.videoPreview,
      {
        [styles.hidden]: isLoading || isCropVisible,
      },
      videoClassName
    ),
  };

  const player = video ? (
    <video
      controls={!!src}
      muted
      playsInline
      src={src ? `${src}#t=0.001` : ''}
      {...videoBasicProps}
    >
      <source src={video} />
      Your browser does not support HTML5 video.
    </video>
  ) : null;

  const showSuccessUploadMessage =
    !errorMessage &&
    !cropNotSupported &&
    videoPreviewNotSupported &&
    !(isCropVisible && cropNotSupported);

  return (
    <>
      <div
        className={cn(
          styles.previewWrapper,
          {
            [styles.previewWrapperWithCrop]: show && isCropVisible,
          },
          wrapperClassName
        )}
      >
        {withToggle ? (
          <Button
            type="button"
            disabled={!video}
            onClick={() => setIsVisible(!isVisible)}
          >
            {isVisible ? 'Close preview' : 'Preview'}
          </Button>
        ) : null}

        {(isVisible || showSuccessUploadMessage || errorMessage) && (
          <Loader loading={isLoading} className={styles.loader} />
        )}

        {isVisible && !isCropVisible ? player : null}

        {isCropVisible && onCropVideoComplete && !cropNotSupported ? (
          <CropVideo
            videoLink={src || ''}
            aspect={cropAspect}
            onCropComplete={onCropVideo}
            containerClassName={cn(styles.cropVideoContainerWrapper, {
              [styles.hidden]: videoPreviewNotSupported,
            })}
          />
        ) : null}
      </div>

      <div className={styles.previewMessageWrapper}>
        {!isLoading && showSuccessUploadMessage && (
          <Text color="black">{successUploadMessage}</Text>
        )}

        {!isLoading && errorMessage && (
          <Text color="maximum-red" textAlign="center">
            {errorMessage}
          </Text>
        )}

        {isCropVisible && cropNotSupported ? (
          <Text color="maximum-red" textAlign="center">
            The <strong>video format</strong> is not supported by this browser.
            Please, select another video file or try another browser.
          </Text>
        ) : null}
      </div>
    </>
  );
};

export default VideoPreview;
