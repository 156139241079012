import { gql } from '@apollo/client';
import { ThankYouVideoFields } from './fragments';

export const CREATE_THANK_YOU_VIDEO_TEMPLATE = gql`
  mutation CreateThankYouVideoTemplate(
    $input: CreateThankYouVideoTemplateInput!
  ) {
    createThankYouVideoTemplate(input: $input) {
      ...ThankYouVideoFields
    }
  }
  ${ThankYouVideoFields}
`;

export const DELETE_THANK_YOU_VIDEO_TEMPLATE = gql`
  mutation DeleteThankYouVideoTemplate(
    $input: DeleteThankYouVideoTemplateInput!
  ) {
    deleteThankYouVideoTemplate(input: $input) {
      id
    }
  }
`;

export const ATTACH_THANK_YOU_VIDEO_TEMPLATE = gql`
  mutation AttachThankYouVideoTemplate(
    $input: AttachThankYouVideoTemplateInput!
  ) {
    attachThankYouVideoTemplate(input: $input) {
      ...ThankYouVideoFields
    }
  }
  ${ThankYouVideoFields}
`;

export const ATTACH_THANK_YOU_VIDEO = gql`
  mutation AttachThankYouVideo($input: AttachThankYouVideoInput!) {
    attachThankYouVideo(input: $input) {
      ...ThankYouVideoFields
    }
  }
  ${ThankYouVideoFields}
`;

export const ATTACH_THANK_YOU_VIDEO_TEMPLATE_STREAM = gql`
  mutation AttachThankYouVideoTemplateStream(
    $input: AttachThankYouVideoTemplateStreamInput!
  ) {
    attachThankYouVideoTemplateStream(input: $input) {
      ...ThankYouVideoFields
    }
  }
  ${ThankYouVideoFields}
`;

export const ATTACH_THANK_YOU_VIDEO_STREAM = gql`
  mutation AttachThankYouVideoStream($input: AttachThankYouVideoStreamInput!) {
    attachThankYouVideoStream(input: $input) {
      ...ThankYouVideoFields
    }
  }
  ${ThankYouVideoFields}
`;
