import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Space } from 'antd';
// Api
import { GET_USERS } from 'api/users/queries';
import { DELETE_ADDRESS } from 'api/users/mutations';
// Types
import { StoreStatus } from 'api/graphql-global-types';
import {
  Users,
  UsersVariables,
  Users_users_entities_addresses,
} from 'api/users/types/Users';
import { UserAddressRequestFields } from 'api/users/types/UserAddressRequestFields';
import {
  DeleteAddress,
  DeleteAddressVariables,
} from 'api/users/types/DeleteAddress';
// Hooks
import { useDefaultCustomerRedirectOnEmptyId } from 'hooks';
// Components
import CreateAddressModal from './Components/CreateAddressModal';
import EditAddressModal from './Components/EditAddressModal';
// UI
import Table from 'ui/Table';
import { errorNotification, successNotification } from 'ui/Notification';
// Helpers
import { renderedCheckForBooleans } from 'helpers/utils';

type AddressListItem = Users_users_entities_addresses & {
  idNum: number;
};

const CustomerAddresses = (): JSX.Element => {
  useDefaultCustomerRedirectOnEmptyId();

  const { customerId } = useParams<{ customerId: string | undefined }>();

  const {
    data,
    loading,
    refetch: refetchUser,
  } = useQuery<Users, UsersVariables>(GET_USERS, {
    variables: {
      input: { id: customerId, status: [StoreStatus.Active] },
    },
    fetchPolicy: 'cache-and-network',
  });

  const [deleteAddress] = useMutation<DeleteAddress, DeleteAddressVariables>(
    DELETE_ADDRESS,
    {
      onCompleted: () => {
        refetchUser();
      },
    }
  );

  const [isCreateAddressModalOpen, setIsCreateAddressModalOpen] =
    useState<boolean>(false);
  const [isAddressEditing, toggleAddressEditing] = useState<boolean>(false);
  const [addressToEdit, setAddressToEdit] = useState<
    UserAddressRequestFields | undefined
  >(undefined);

  const handleDeleteAddress = async (id: string) => {
    try {
      await deleteAddress({ variables: { id } });
      successNotification('The address is deleted');
    } catch {
      errorNotification();
    }
  };

  const handleCreateAddressModalOpen = () => {
    setIsCreateAddressModalOpen(true);
  };

  const handleCreateAddressModalClose = () => {
    setIsCreateAddressModalOpen(false);
  };

  const customerData: AddressListItem[] =
    data?.users?.entities?.[0]?.addresses?.map((addresses, i) => ({
      ...addresses,
      idNum: i + 1,
    })) || [];

  const columns = [
    {
      title: 'ID',
      dataIndex: 'idNum',
      key: 'id',
      align: 'center' as const,
      sorterType: 'text',
      fixed: 'left' as const,
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'name',
      align: 'center' as const,
      fixed: 'left' as const,
      sorterType: 'text',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'surname',
      align: 'center' as const,
      fixed: 'left' as const,
      sorterType: 'text',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      fixed: 'left' as const,
      sorterType: 'text',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      fixed: 'left' as const,
      sorterType: 'text',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      align: 'center' as const,
      sorterType: 'text',
    },
    {
      title: 'ZIP',
      dataIndex: 'zipCode',
      key: 'zip',
      align: 'center' as const,
      sorterType: 'number',
    },
    {
      title: 'Default',
      dataIndex: 'isDefault',
      key: 'default',
      align: 'center' as const,
      sorterType: 'boolean',
      render: renderedCheckForBooleans,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center' as const,
      width: 120,
      render: function Actions(text: any, record: any) {
        return (
          <Space size="middle" direction="vertical">
            <Button
              type="primary"
              onClick={() => {
                toggleAddressEditing(true);
                setAddressToEdit(record);
              }}
            >
              Edit
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => handleDeleteAddress(record.id)}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <div style={{ margin: 10, textAlign: 'end' }}>
        <Button type="primary" onClick={handleCreateAddressModalOpen}>
          Add new address
        </Button>
      </div>

      <Table<AddressListItem>
        columns={columns}
        data={customerData}
        scroll={{ x: 800 }}
        loading={loading}
      />

      <CreateAddressModal
        userId={customerId}
        open={isCreateAddressModalOpen}
        onClose={handleCreateAddressModalClose}
        onComplete={refetchUser}
      />

      <EditAddressModal
        addressToEdit={addressToEdit}
        isAddressEditing={isAddressEditing}
        toggleAddressEditing={toggleAddressEditing}
        refetchUser={refetchUser}
      />
    </div>
  );
};

export default CustomerAddresses;
