import {
  useQuery,
  QueryResult,
  QueryOptions,
  WatchQueryFetchPolicy,
  QueryFunctionOptions,
} from '@apollo/client';
// Api
import { GET_SCHEDULE_TIMEZONES } from 'api/schedule/queries';
// Types
import { GetScheduleTimezones } from 'api/schedule/types/GetScheduleTimezones';

type BasicQueryOptions = Omit<
  QueryOptions,
  'query' | 'variables' | 'fetchPolicy'
> & {
  fetchPolicy?: WatchQueryFetchPolicy;
} & QueryFunctionOptions;

export const useGetScheduleTimeZones = (
  options?: BasicQueryOptions
): QueryResult<GetScheduleTimezones> => {
  const timeZones = useQuery<GetScheduleTimezones>(GET_SCHEDULE_TIMEZONES, {
    ...options,
  });

  return timeZones;
};
