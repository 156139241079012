import { GetAdminPromocode } from 'api/promocodes/types/GetAdminPromocode';

export type PromoCodeDataListItem = {
  id: string | number;
  title: string;
};

export type PromoCodeData = {
  storeId: string;
  storeFullName: string;
  products: PromoCodeDataListItem[];
  streams: PromoCodeDataListItem[];
  amas: PromoCodeDataListItem[];
};

export const renderPromoCodeList = (data: PromoCodeDataListItem[]): string => {
  if (!data.length) {
    return '-';
  } else {
    return data.map(({ title }) => title).join(', ');
  }
};

export const preparePromoCodeData = (
  data?: GetAdminPromocode['getAdminPromocode']
): PromoCodeData[] => {
  if (!data) {
    return [];
  }

  const { merchProducts, amas, streams } = data;

  const allItems = [
    ...(merchProducts || []),
    ...(amas || []),
    ...(streams || []),
  ];

  const promoCodeDataMap = allItems.reduce(
    (acc: Record<string, PromoCodeData>, item) => {
      const store = acc[item.storeId] || {
        storeId: item.storeId,
        storeFullName: `${item.store?.firstName} ${item.store?.lastName}`,
        products: [],
        amas: [],
        streams: [],
      };

      switch (item.__typename) {
        case 'Ama': {
          const { id, type, title } = item;

          const descrItemData = {
            id,
            title: title || `AMA (${type})`,
          };

          if (store.amas) {
            store.amas = [...store.amas, descrItemData];
          } else {
            store.amas = [descrItemData];
          }

          break;
        }

        case 'MerchProduct': {
          const { id, title } = item;

          const descrItemData = {
            id,
            title: title || `Product: ${id}`,
          };

          if (store.products) {
            store.products = [...store.products, descrItemData];
          } else {
            store.products = [descrItemData];
          }

          break;
        }

        case 'Stream': {
          const { id, name } = item;

          const descrItemData = {
            id,
            title: name || `Stream: ${id}`,
          };

          if (store.streams) {
            store.streams = [...store.streams, descrItemData];
          } else {
            store.streams = [descrItemData];
          }

          break;
        }
      }

      acc[item.storeId] = store;

      return acc;
    },
    {}
  );

  return Object.values(promoCodeDataMap);
};
