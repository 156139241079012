import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Rate, Typography, Button } from 'antd';
// Api
import { DESIGNERS } from 'api/designLab/queries';
// Types
import {
  Designers,
  DesignersVariables,
  Designers_designers_entities,
} from 'api/designLab/types/Designers';
import {
  SortDirection,
  DesignerStatus,
  DesignersOrderBy,
} from 'api/graphql-global-types';
import { SortedInfo, TableFilter } from 'ui/Table';
// UI
import Table from 'ui/Table';
// Components
import Actions from './components/Actions';
import Requests from './components/Requests';
import AddNewDesigner from './components/AddNewDesigner';

type DesignersFormattedData = Designers_designers_entities & {
  designerName: string;
};

const { Text } = Typography;

const DesignerStatusTitle = {
  [DesignerStatus.Pending]: 'Inactive',
  [DesignerStatus.Active]: 'Active',
  [DesignerStatus.Inactive]: 'Deactivated',
};

const DesignersPage: React.FC = () => {
  const [selectedDesigner, setSelectedDesigner] =
    useState<Designers_designers_entities | null>(null);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<DesignersOrderBy>>({
    order: SortDirection.DESC,
    key: DesignersOrderBy.designerName,
  });
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [showRequestsModal, setShowRequestsModal] = useState<boolean>(false);

  const getAllDesignersInput: any = () => {
    const input: any = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;
    });

    return { input };
  };

  const { data, loading } = useQuery<Designers, DesignersVariables>(DESIGNERS, {
    variables: { ...getAllDesignersInput() },
    fetchPolicy: 'cache-and-network',
  });

  const getName = (
    firstName: string | undefined | null,
    lastName: string | undefined | null,
    email: string | undefined | null
  ): string => {
    return firstName || lastName ? `${firstName} ${lastName}` : email || '';
  };

  const handleShowModal = (designer: Designers_designers_entities) => {
    setSelectedDesigner(designer);
    setShowRequestsModal(true);
  };

  const handleHideModal = () => {
    setShowRequestsModal(false);
    setSelectedDesigner(null);
  };

  const getFormattedData = (): DesignersFormattedData[] => {
    return (
      data?.designers?.entities?.map((i) => ({
        ...i,
        designerName: getName(i?.firstName, i?.lastName, i?.email),
        isIncludedInAutoAssignment:
          i?.designerDetails?.isIncludedInAutoAssignment ?? false,
      })) || []
    );
  };

  const columns = [
    {
      title: 'Designer name',
      dataIndex: DesignersOrderBy.designerName,
      key: DesignersOrderBy.designerName,
      fixed: 'left' as const,
      align: 'left' as const,
      width: 100,
      withSearch: true,
      sorterType: 'text',
    },
    {
      title: 'Requests',
      dataIndex: 'designRequestCnt',
      key: DesignersOrderBy.designerRequestCnt,
      align: 'center' as const,
      width: 80,
      sorterType: 'number',
      render: function RequestsWrap(
        designRequestCnt: number,
        record: Designers_designers_entities
      ) {
        return (
          <Button
            type="link"
            onClick={() => handleShowModal(record)}
            title={`${record.firstName} ${record.lastName} designer requests`}
            disabled={!designRequestCnt}
          >
            {designRequestCnt > 0 ? designRequestCnt : 'No requests yet'}
          </Button>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: DesignersOrderBy.designerStatus,
      key: DesignersOrderBy.designerStatus,
      align: 'center' as const,
      width: 80,
      sorterType: 'text',
      withCheckboxFilters: true,
      filters: [
        {
          text: DesignerStatusTitle[DesignerStatus.Active],
          value: DesignerStatus.Active,
        },
        {
          text: DesignerStatusTitle[DesignerStatus.Pending],
          value: DesignerStatus.Pending,
        },
        {
          text: DesignerStatusTitle[DesignerStatus.Inactive],
          value: DesignerStatus.Inactive,
        },
      ],
      filterMultiple: true,
      render: function DesignerStatusWrap(
        designerStatus: DesignerStatus | null
      ) {
        return (
          <Text>
            {designerStatus
              ? DesignerStatusTitle[designerStatus] || designerStatus
              : '-'}
          </Text>
        );
      },
    },
    {
      title: 'Auto Assign',
      dataIndex: 'isIncludedInAutoAssignment',
      key: 'autoAssign',
      align: 'center' as const,
      width: 120,
      render: function AutoAssignWrap(
        isIncludedInAutoAssignment: boolean | undefined
      ) {
        const displayValue = isIncludedInAutoAssignment ? 'Yes' : 'No';
        return <span>{displayValue}</span>;
      },
    },
    {
      title: 'Rate',
      dataIndex: DesignersOrderBy.designerRate,
      key: DesignersOrderBy.designerRate,
      align: 'center' as const,
      width: 150,
      sorterType: 'number',
      render: function RateWrap(designerRate: number | null) {
        return <Rate value={designerRate || 0} disabled allowHalf count={5} />;
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 70,
      render: function ActionsWrap(_: any, record: any) {
        return (
          <Actions
            designer={record}
            getAllDesignersInput={getAllDesignersInput()}
          />
        );
      },
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <AddNewDesigner getAllDesignersInput={getAllDesignersInput()} />

      <Table<DesignersFormattedData, DesignersOrderBy>
        columns={columns}
        data={getFormattedData()}
        scroll={{ x: 1024 }}
        loading={loading}
        total={data?.designers?.total}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />

      {selectedDesigner && (
        <Requests
          show={showRequestsModal}
          onClose={handleHideModal}
          designerId={selectedDesigner.id}
          designerName={`${getName(
            selectedDesigner.firstName,
            selectedDesigner.lastName,
            selectedDesigner.email
          )}`}
        />
      )}
    </div>
  );
};

export default DesignersPage;
