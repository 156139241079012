import ENV from 'api/env';
// Helpers
import { getEnvLink } from 'helpers/getEnvLink';

export type FacebookParams = {
  display?: 'page' | 'popup';
};

type CreateLink<Params = Record<string, unknown>> = (
  path: string,
  params?: Params
) => string;

export const facebookLink: CreateLink<FacebookParams> = (
  path,
  { display = 'page' } = {}
) =>
  `https://www.facebook.com/sharer.php?display=${display}&u=${getEnvLink(
    ENV.REACT_APP_ENV
  )}${path}`;

export const twitterLink = (path: string, text: string | undefined): string =>
  `https://twitter.com/intent/tweet?url=${getEnvLink(
    ENV.REACT_APP_ENV
  )}${path}${text ? `&text=${encodeURIComponent(text)}` : ''}`;
