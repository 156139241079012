import { useLocation } from 'react-router-dom';
// Helpers
import { facebookLink, twitterLink } from 'helpers/social-share';

type SocialLinks = {
  facebookShareLink: string;
  twitterShareLink: string;
};

type OptionProps = {
  url?: string;
  twitterText?: string;
};

export const useGetSocialLinks = (options?: OptionProps): SocialLinks => {
  const location = useLocation();
  const fullPath = location.pathname + location.search + location.hash;
  const { url, twitterText } = options || {};

  const facebookShareLink = facebookLink(url || fullPath);
  const twitterShareLink = twitterLink(url || fullPath, twitterText);

  return {
    facebookShareLink,
    twitterShareLink,
  };
};
