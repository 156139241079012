import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useMutation, useLazyQuery } from '@apollo/client';
import moment, { Moment } from 'moment';
import momentTimezone from 'moment-timezone';
import debounce from 'lodash/debounce';
import {
  Form,
  Input,
  Select,
  Switch,
  Button,
  Tooltip,
  Typography,
  DatePicker,
  InputNumber,
  Spin,
  AutoComplete,
} from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { UploadFile } from 'antd/es/upload/interface';
import { QuestionCircleTwoTone } from '@ant-design/icons';
// Api
import { GET_OR_CREATE_STREAM_PARTICIPANT_INVITE_ADMIN } from 'api/streams/queries';
import { CREATE_PRESIGNED_AFFILIATE_PRODUCT_IMAGE_URL } from 'api/affiliateProducts/mutations';
import { GET_STORES } from 'api/store/queries';
// Types
import { GetStores, GetStoresVariables } from 'api/store/types/GetStores';
import {
  GetOrCreateStreamParticipantInviteAdmin,
  GetOrCreateStreamParticipantInviteAdminVariables,
} from 'api/streams/types/GetOrCreateStreamParticipantInviteAdmin';
import {
  Streams_adminStreams_entities,
  Streams_adminStreams_entities_affiliateProducts,
} from 'api/streams/types/Streams';
import { GetModerators_moderators_entities } from 'api/streams/types/GetModerators';
import { ToolbarItem } from 'components/common/RichText/components/Toolbar/Toolbar';
import {
  CreateAffiliateProductInput,
  EditAffiliateProductInput,
  StreamStatus,
  UserRole,
} from 'api/graphql-global-types';
import AffiliateProductItem, {
  AffiliateProductItemType,
  UploadImage,
} from 'ui/AffiliateProductItem/AffiliateProductItem';
import {
  CreatePresignedAffiliateProductImageUrls,
  CreatePresignedAffiliateProductImageUrlsVariables,
} from 'api/affiliateProducts/types/CreatePresignedAffiliateProductImageUrls';
// Constants
import { commonTimeFormat } from 'constants/global';
import { TIME_ZONE_OPTIONS } from 'constants/timeZone';
// Helpers
import { getUnique } from 'helpers/utils';
import { getEnvLink } from 'helpers/getEnvLink';
import {
  getTimeZoneName,
  getDefaultTimeZone,
  getDateWithoutCurrentTimeZone,
} from 'helpers/timeZone';
import {
  getCreateAffiliateFields,
  getEditAffiliateFields,
  rearrangeArrayIndexes,
  REPEAT_STREAM_OPTIONS,
  uploadImages,
} from './helpers';
// Config
import ENV from 'api/env';
// Components
import RichText from 'components/common/RichText/RichText';
import CroppedPictureInput from 'components/common/CroppedPictureInput/CroppedPictureInput';
import Participants from './components/Participants';
// UI
import { errorNotification } from 'ui/Notification';
import CopyLink from 'ui/CopyLink/CopyLink';
import TagsInput from 'uiShared/TagsInput/TagsInput';
import Mentions, { MentionValues } from 'uiShared/Mentions/Mentions';
// Styles
import styles from './CreateEditStreamForm.module.scss';

const { Text } = Typography;
const { Option } = Select;

export type CreateEditFormValues = {
  date: Moment | null;
  tzCode: string | null | undefined;
  hashtags: string[];
  picture: UploadFile[] | string | null;
  name: string | null;
  requestedPrice: number | null;
  isFree: boolean;
  isHidden: boolean;
  repeatsEveryEnum: string | null;
  description: string | null;
  sponsorImage?: UploadFile[] | string | null;
  sponsorName?: string | null;
  sponsorPromoMessage?: string | null;
  moderatorId?: string | null;
  hasAffiliate: boolean;
  affiliateProducts?:
    | Streams_adminStreams_entities_affiliateProducts[]
    | CreateAffiliateProductInput[]
    | null;
  mentions: MentionValues[];
};

type CreateEditStreamFormProps = {
  stream?: Streams_adminStreams_entities | null;
  onFinish?: (value: CreateEditFormValues) => void;
  loading?: boolean;
  submitButtonTitle?: string;
  setFormValues?: React.Dispatch<React.SetStateAction<any>>;
  moderators?: GetModerators_moderators_entities[];
  isEdit?: boolean;
  selectedStoreIds: string[] | never[];
  setSelectedStoreIds: React.Dispatch<React.SetStateAction<string[]>>;
};

const formItemLayout = {
  labelCol: {
    sm: {
      span: 10,
    },
    md: {
      span: 9,
    },
    lg: {
      span: 9,
    },
  },
  wrapperCol: {
    sm: {
      span: 14,
    },
    md: {
      span: 13,
    },
    lg: {
      span: 14,
    },
  },
};

export const CreateEditStreamForm = ({
  stream,
  onFinish,
  loading,
  submitButtonTitle,
  setFormValues,
  moderators,
  isEdit = false,
  selectedStoreIds,
  setSelectedStoreIds,
}: CreateEditStreamFormProps): JSX.Element => {
  const [getStores, { loading: getStoresLoading, data }] = useLazyQuery<
    GetStores,
    GetStoresVariables
  >(GET_STORES);

  const [getStreamParticipantInviteAdmin, { data: streamInviteData }] =
    useLazyQuery<
      GetOrCreateStreamParticipantInviteAdmin,
      GetOrCreateStreamParticipantInviteAdminVariables
    >(GET_OR_CREATE_STREAM_PARTICIPANT_INVITE_ADMIN);

  const [
    createPresignedAffiliateProductImageUrls,
    { loading: loadingCreatePresignedAffiliateProductsImageUrls },
  ] = useMutation<
    CreatePresignedAffiliateProductImageUrls,
    CreatePresignedAffiliateProductImageUrlsVariables
  >(CREATE_PRESIGNED_AFFILIATE_PRODUCT_IMAGE_URL);

  const streamDate = getDateWithoutCurrentTimeZone(stream?.scheduleDate);

  const initialFormValues: CreateEditFormValues = {
    date: stream ? moment(streamDate) : null,
    tzCode: getDefaultTimeZone(stream),
    hashtags: stream?.hashtags?.map((tag) => tag.name) || [],
    picture: stream?.imageURL || null,
    name: stream?.repeatsEveryEnum
      ? stream.repeatingTitle
      : stream?.name || null,
    requestedPrice: stream?.requestedPrice || null,
    isFree: Boolean(stream?.isFree),
    isHidden: Boolean(stream?.isHidden),
    repeatsEveryEnum:
      stream?.repeatsEveryEnum || REPEAT_STREAM_OPTIONS[0].value,
    description: stream?.description || null,
    sponsorName: stream?.sponsors?.[0]?.name,
    sponsorImage: stream?.sponsors?.[0]?.logoUrl,
    sponsorPromoMessage: stream?.sponsors?.[0]?.promoMessage,
    moderatorId: stream?.moderator?.id || null,
    hasAffiliate: !!stream?.affiliateProducts?.length,
    affiliateProducts: stream?.affiliateProducts || [],
    mentions: stream?.mentions || [],
  };

  const [formFields, setFormFields] =
    useState<CreateEditFormValues>(initialFormValues);
  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
  const [pictureValidation, setPictureValidation] = useState<string>('');
  const [sponsorImageValidation, setSponsorImageValidation] =
    useState<string>('');
  const [s3Loader, setS3Loader] = useState<boolean>(false);

  const [autoCompleteValue, setAutoCompleteValue] = useState<string>('');
  const [asyncOptions, setAsyncOptions] = useState<Array<any>>([]);
  const [mentionsValues, setMentionsValues] = useState<MentionValues[]>(
    stream?.mentions || []
  );

  const initialAffiliateProducts: AffiliateProductItemType[] =
    stream?.affiliateProducts && stream?.affiliateProducts.length
      ? stream.affiliateProducts.map((product, index) => {
          return {
            id: product.id || '',
            index: index,
            isValid: true,
            price: product.price,
            title: product.title,
            url: product.url,
            image: { data_url: product.imageKey },
            imageUrl: product.imageUrl,
          };
        })
      : [];

  const [affiliateProductsData, setAffiliateProductsData] = useState<
    AffiliateProductItemType[]
  >(initialAffiliateProducts);

  const handleRemoveStore = (storeId: string): void => {
    setSelectedStoreIds((prev) => prev.filter((id) => id !== storeId));
    setIsFormTouched(true);
  };

  useEffect(() => {
    const options =
      data?.adminStores.entities.map(({ id, storeDetails }) => ({
        value: id,
        label: storeDetails?.storeName || '',
      })) || [];

    setAsyncOptions(options);
  }, [data]);

  useEffect(() => {
    if (stream?.id && stream?.storeId) {
      getStreamParticipantInviteAdmin({
        variables: {
          input: {
            streamId: stream.id,
            storeId: stream.storeId,
          },
        },
      });
    }
  }, [getStreamParticipantInviteAdmin, stream]);

  const streamLink = isEdit
    ? `${getEnvLink(ENV.REACT_APP_ENV)}/${stream?.store?.slug}/streams/${
        stream?.slug
      }`
    : '';

  const inviteLink = isEdit
    ? `${getEnvLink(ENV.REACT_APP_ENV)}/stream-invite/${stream?.id}/${
        streamInviteData?.getOrCreateStreamParticipantInviteAdmin.invite
      }`
    : '';

  const handleSelectStoreId = (value: string): void => {
    setAutoCompleteValue('');
    setIsFormTouched(true);

    setSelectedStoreIds((prevState) => getUnique([...prevState, value]));
  };

  const handleAutoCompleteChange = (value: string): void => {
    setAutoCompleteValue(value);
  };

  const handleOnSearch = useMemo(() => {
    const loadOptions = (storeName: string) => {
      setAsyncOptions([]);

      getStores({
        variables: {
          storeRoles: [
            UserRole.Athlete,
            UserRole.Organization,
            UserRole.ContentCreator,
          ],
          input: { storeName },
        },
      });
    };

    return debounce(loadOptions, 400);
  }, [getStores]);

  const [form] = Form.useForm();
  const methods = useForm();
  const { control } = methods;

  const {
    isFree,
    tzCode,
    picture,
    sponsorName,
    sponsorImage,
    sponsorPromoMessage,
    description,
    hasAffiliate,
  } = formFields;

  const handleAddAffiliateProduct = () => {
    const newAffiliateProduct: AffiliateProductItemType = {
      index: affiliateProductsData?.length || 0,
      isValid: false,
      image: { data_url: '', file: undefined },
      price: 0,
      title: '',
      url: '',
    };

    setIsFormTouched(true);
    setAffiliateProductsData((prevData) => [...prevData, newAffiliateProduct]);
  };

  const setAffiliateProductsValue = (value: AffiliateProductItemType) => {
    setIsFormTouched(true);
    setAffiliateProductsData((prevData) =>
      prevData.map((currentItem, index) => {
        if (index === value.index) {
          return value;
        }
        return currentItem;
      })
    );
  };

  const deleteAffiliateProduct = (index: number) => {
    setIsFormTouched(true);
    setAffiliateProductsData((prevData) => {
      return rearrangeArrayIndexes(
        prevData.filter((item) => item.index !== index)
      );
    });
  };

  const isFieldsEditingDisabled = !!(
    stream && stream?.streamStatus !== StreamStatus.Scheduled
  );

  const onCheckboxChange = (checked: boolean) => {
    if (checked) {
      form.validateFields(['requestedPrice']);
    }
  };

  const normFile = (e: any) => {
    if (e && Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getIfSubmitIsDisabled = (): boolean => {
    const sponsorFieldsNotCompleted = Boolean(
      (sponsorName && sponsorName?.length > 0 && !sponsorImage?.length) ||
        (sponsorImage && sponsorImage?.length > 0 && !sponsorName?.length)
    );

    const hasErrors = Boolean(
      pictureValidation.length > 0 ||
        sponsorImageValidation.length > 0 ||
        form.getFieldsError()?.filter(({ errors }) => errors?.length)?.length
    );

    const formHasNoRequiredValues = Object.entries(formFields).some(
      ([field, value]) => {
        const ifHasNoValues = value === null || value === '';

        if (field === 'requestedPrice' && isFree) {
          return false;
        }

        if (field === 'sponsorName') {
          return sponsorImage?.length && !sponsorName?.length;
        }

        if (field === 'sponsorImage') {
          return sponsorName?.length && !sponsorImage?.length;
        }

        if (field === 'sponsorPromoMessage') {
          return false;
        }

        if (field === 'moderatorId') {
          return false;
        }

        if (field === 'repeatsEveryEnum') {
          return false;
        }

        return ifHasNoValues;
      }
    );

    // if there are any affiliate products with property "isValid" equal to false,
    // block the stream completion
    const isAffiliateInvalid =
      hasAffiliate &&
      !!affiliateProductsData.filter((item) => item.isValid === false).length;

    return Boolean(
      hasErrors ||
        !isFormTouched ||
        sponsorFieldsNotCompleted ||
        formHasNoRequiredValues ||
        isAffiliateInvalid ||
        loading
    );
  };

  const submitIsDisabled = getIfSubmitIsDisabled();

  const setFields = (newValues: CreateEditFormValues) => {
    setIsFormTouched(true);

    setFormFields(newValues);

    if (setFormValues) {
      setFormValues(newValues);
    }
  };

  const onFieldsChange = () => {
    const newValues: CreateEditFormValues = form.getFieldsValue();

    if (!sponsorName?.length && !sponsorImage?.length) {
      form.setFields([
        {
          name: 'sponsorName',
          errors: [],
        },
        {
          name: 'sponsorImage',
          errors: [],
        },
      ]);
    }

    setFields(newValues);
  };

  const onSponsorPromoMessageChange = (newSponsorPromoMessage: string) => {
    setFields({
      ...formFields,
      sponsorPromoMessage: newSponsorPromoMessage,
    });

    form.setFieldsValue({ sponsorPromoMessage: newSponsorPromoMessage });
  };

  const handleHashtagsChange = (hashtags: any) => {
    setFields({
      ...formFields,
      hashtags: hashtags,
    });
    form.setFieldsValue({ hashtags: hashtags });
  };

  const handleMentionChange = useCallback(
    (mentions) => {
      if (JSON.stringify(mentions) !== JSON.stringify(mentionsValues)) {
        setIsFormTouched(true);
        form.setFieldsValue({ mentions: mentions });
        setMentionsValues(mentions);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mentionsValues]
  );

  const onDescriptionChange = (newDescription: string) => {
    setFields({
      ...formFields,
      description: newDescription,
    });

    form.setFieldsValue({ description: newDescription });
  };

  const getDisabledDates = (current: Moment) => {
    const selectedTimeZoneOffset = momentTimezone(
      current ? current.toDate() : ''
    )
      .tz(getTimeZoneName(tzCode))
      .utcOffset();

    const currentTimeInSelectedTimeZone = moment()
      .add(selectedTimeZoneOffset, 'm')
      .add(new Date().getTimezoneOffset(), 'm')
      .toDate();

    return moment(currentTimeInSelectedTimeZone).diff(current, 'minutes') > 0;
  };

  const handleOnIsAffiliateChange = (checked: boolean) => {
    if (!checked) {
      form.setFieldsValue({
        affiliateProducts: [],
      });
      setAffiliateProductsData([]);
    } else {
      setAffiliateProductsData(initialAffiliateProducts);
      handleAddAffiliateProduct();
    }
  };

  const handleUpdateFormAndFinish = (
    affiliateProductsField:
      | EditAffiliateProductInput[]
      | CreateAffiliateProductInput[]
  ) => {
    const newFormFields = {
      ...formFields,
      affiliateProducts: affiliateProductsField,
    };
    setFields(newFormFields);

    onFinish && onFinish(newFormFields);
  };

  const handleFormFinish = async () => {
    // imageUrls is the final array of all image urls-those that we need to upload will be placed here as empty string and
    // be added once they are uploaded and returned in string format, using their index to place them in the right sequence
    const imageUrls: string[] = [];
    const imagesForUpload: UploadImage[] = [];
    const uploadImagesIndex: number[] = [];

    for (let counter = 0; counter < affiliateProductsData.length; counter++) {
      imageUrls.push(affiliateProductsData[counter].image?.data_url || '');
      if (affiliateProductsData[counter].image?.file) {
        imagesForUpload.push(affiliateProductsData[counter].image);
        uploadImagesIndex.push(counter);
      }
    }

    if (hasAffiliate) {
      if (uploadImagesIndex.length) {
        setS3Loader(true);
        // in case of affiliate products where we first need to upload images
        try {
          const { data: presignedImageUrls } =
            await createPresignedAffiliateProductImageUrls({
              variables: {
                input: {
                  numberOfImages: imagesForUpload.length,
                },
              },
            });
          if (presignedImageUrls) {
            try {
              const uploadedImagesUrls = await uploadImages(
                presignedImageUrls.createPresignedAffiliateProductImageUrls,
                imagesForUpload
              );
              // once uploaded, adding image urls back to their index one by one
              for (let counter = 0; counter < imageUrls.length; counter++) {
                if (uploadImagesIndex.includes(counter)) {
                  imageUrls[counter] = uploadedImagesUrls[0];
                  uploadedImagesUrls.shift();
                }
              }
              // create affiliate products data, based on the action type-create or edit
              const affiliateProductsField:
                | EditAffiliateProductInput[]
                | CreateAffiliateProductInput[] = isEdit
                ? getEditAffiliateFields(affiliateProductsData, imageUrls)
                : getCreateAffiliateFields(affiliateProductsData, imageUrls);
              setS3Loader(false);
              handleUpdateFormAndFinish(affiliateProductsField);
            } catch (error) {
              setS3Loader(false);
              errorNotification((error as Error)?.message);
            }
          }
        } catch (error) {
          errorNotification((error as Error)?.message);
        }
      } else {
        // in case of affiliate products where we already have all the images(when editing)
        const affiliateProductsField: EditAffiliateProductInput[] =
          getEditAffiliateFields(affiliateProductsData, imageUrls);

        handleUpdateFormAndFinish(affiliateProductsField);
      }
    } else {
      // if we don't have affiliate products, just create or edit normally
      onFinish && onFinish(formFields);
    }
  };

  const isLoading =
    loadingCreatePresignedAffiliateProductsImageUrls || loading || s3Loader;

  return (
    <Form
      className={styles.setupStreamForm}
      form={form}
      layout="horizontal"
      name="setupStream"
      initialValues={initialFormValues}
      autoComplete="off"
      onFieldsChange={onFieldsChange}
      onFinish={handleFormFinish}
      {...formItemLayout}
    >
      <div
        className={cn(styles.formContainer, {
          [styles.formContainerInsideModal]: isEdit,
        })}
      >
        {isEdit && (
          <div className={styles.copyLinks}>
            <CopyLink
              name="event"
              label="Event promotion link"
              link={streamLink}
            />
            <CopyLink
              name="invite"
              label="Athlete invitation link"
              link={inviteLink}
            />
          </div>
        )}

        <div
          className={cn(styles.formFieldsContainer, {
            [styles.formFieldsWithAffiliate]: hasAffiliate && !isEdit,
          })}
        >
          {!isEdit && (
            <Form.Item
              name="hasAffiliate"
              valuePropName="checked"
              label={<Text>Has Affiliate products</Text>}
            >
              <Switch
                checked={hasAffiliate}
                onChange={handleOnIsAffiliateChange}
              />
            </Form.Item>
          )}
          <Form.Item
            name="name"
            label={<Text>Title</Text>}
            rules={[
              {
                required: true,
                message: 'Please enter the title',
              },
            ]}
          >
            <Input style={{ width: 180 }} />
          </Form.Item>

          <Form.Item
            name="description"
            label={<Text>Description</Text>}
            rules={[
              {
                required: true,
                message: 'Please enter the description',
              },
            ]}
          >
            <div className={styles.sponsorPromoMessageWrapper}>
              <RichText
                initialValue={description || ''}
                toolbarItems={[ToolbarItem.Link]}
                onChange={onDescriptionChange}
                placeholder="Type the stream description"
              />
            </div>
          </Form.Item>

          <Form.Item
            name="date"
            label={<Text>Set up the date</Text>}
            rules={[
              {
                required: true,
                message: 'Please enter the date',
              },
            ]}
          >
            <DatePicker
              data-testid="streamDate"
              disabledDate={getDisabledDates}
              showTime
              format={commonTimeFormat}
              style={{ width: 250 }}
              minuteStep={15}
              showNow={false}
              disabled={isFieldsEditingDisabled}
            />
          </Form.Item>

          <Form.Item
            name="tzCode"
            label={<Text>Time Zone</Text>}
            rules={[
              {
                required: true,
                message: 'Please select time zone',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select time zone"
              optionFilterProp="children"
              disabled={isFieldsEditingDisabled}
            >
              {TIME_ZONE_OPTIONS.map(({ tzCode, label }) => {
                return (
                  <Option key={tzCode} value={tzCode}>
                    {label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="hashtags" label="Tagging">
            <Controller
              name="hashtags"
              defaultValue={formFields.hashtags}
              control={control}
              render={() => (
                <TagsInput
                  hashtags={formFields.hashtags}
                  onChange={(val: any) => handleHashtagsChange(val)}
                  name="hashtags"
                />
              )}
            />
          </Form.Item>

          <Form.Item
            name="repeatsEveryEnum"
            label={<Text>Does this event repeat</Text>}
          >
            <Select showSearch placeholder="Select">
              {REPEAT_STREAM_OPTIONS.map(({ label, value }) => {
                return (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="requestedPrice"
            label={
              <Text>
                {'Requested price ($) '}
                <Tooltip title="The final price will be increased due to the platform's fee">
                  <QuestionCircleTwoTone />
                </Tooltip>
              </Text>
            }
            rules={[
              {
                required: !isFree,
                message: 'Price is required for non free watchstreams',
              },
            ]}
          >
            <InputNumber
              min={0}
              step={1}
              max={799999}
              disabled={Boolean(stream) || isFree || isFieldsEditingDisabled}
              readOnly={Boolean(stream)}
            />
          </Form.Item>

          <Form.Item
            name="isFree"
            valuePropName="checked"
            label={<Text>Free watchstream</Text>}
          >
            <Switch
              disabled={Boolean(stream) || isFieldsEditingDisabled}
              onChange={onCheckboxChange}
            />
          </Form.Item>

          <Form.Item
            name="isHidden"
            valuePropName="checked"
            label={<Text>Test</Text>}
            help={<Text>Test streams are not visible on the platform</Text>}
            style={{ marginBottom: 14 }}
          >
            <Switch aria-label="test stream" />
          </Form.Item>

          <Form.Item
            name="picture"
            label={<Text>Upload picture</Text>}
            validateStatus={
              pictureValidation ||
              form.getFieldsError(['picture'])[0].errors?.length ||
              !picture
                ? 'error'
                : ''
            }
            help={pictureValidation || null}
            rules={[
              {
                required: true,
                message: 'Please upload the picture',
              },
            ]}
          >
            <CroppedPictureInput
              setPictureValidation={setPictureValidation}
              defaultImage={stream?.imageURL}
              requiredMessage="Please upload the picture"
              buttonTitle="Upload Stream picture"
            />
          </Form.Item>

          <Form.Item
            name="sponsorName"
            label={<Text>Sponsor name</Text>}
            rules={[
              {
                required: !!(sponsorImage && sponsorImage?.length > 0),
                message:
                  "Please enter sponsor name (required if You've uploaded the sponsor logo)",
              },
            ]}
          >
            <Input
              style={{ width: 180 }}
              allowClear
              disabled={isFieldsEditingDisabled}
            />
          </Form.Item>

          <Form.Item
            name="sponsorImage"
            label={<Text>Upload sponsor image</Text>}
            getValueFromEvent={normFile}
            validateStatus={
              sponsorImageValidation ||
              form.getFieldsError(['sponsorImage'])[0].errors?.length ||
              (sponsorName && sponsorName?.length > 0 && !sponsorImage?.length)
                ? 'error'
                : ''
            }
            help={sponsorImageValidation || null}
            rules={[
              {
                required: !!(sponsorName && sponsorName?.length > 0),
                message:
                  "Please enter sponsor image (required if You've provided the sponsor name)",
              },
            ]}
          >
            <CroppedPictureInput
              setPictureValidation={setSponsorImageValidation}
              defaultImage={stream?.sponsors?.[0]?.logoUrl}
              buttonTitle="Upload sponsor image"
              disabled={isFieldsEditingDisabled}
            />
          </Form.Item>

          <Form.Item
            name="sponsorPromoMessage"
            label={
              <Text>
                {'Sponsor promotion message '}
                <Tooltip title="This message will be used by the stream Moderator to promote the Sponsor in the stream’s chat.">
                  <QuestionCircleTwoTone />
                </Tooltip>
              </Text>
            }
          >
            <div
              className={cn(styles.sponsorPromoMessageWrapper, {
                [styles.disabledRichText]: isFieldsEditingDisabled,
              })}
            >
              <RichText
                initialValue={sponsorPromoMessage || ''}
                toolbarItems={[ToolbarItem.Link]}
                onChange={onSponsorPromoMessageChange}
                placeholder="Type the sponsor promotion description"
                readOnly={isFieldsEditingDisabled}
              />
            </div>
          </Form.Item>

          <Form.Item name="moderatorId" label={<Text>Moderator</Text>}>
            <Select
              showSearch
              allowClear
              disabled={isFieldsEditingDisabled}
              placeholder="Select moderator"
              filterOption={(input, option) =>
                option?.name
                  ?.toLowerCase()
                  .indexOf(input.replace(/\s\s+/g, ' ').trim().toLowerCase()) >=
                0
              }
            >
              {moderators?.map(({ firstName, lastName, id }) => {
                const name = [firstName, lastName].join(' ').trim();
                return (
                  <Option key={id} value={id} name={name}>
                    {name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>

        <div className={styles.participantsWrapper}>
          <p className={styles.streamParticipantsTitle}>Stream participants</p>
          <Form.Item label="Search and select store">
            <AutoComplete
              placeholder="Search by store name"
              notFoundContent={getStoresLoading ? <Spin size="small" /> : null}
              value={autoCompleteValue}
              options={asyncOptions}
              onSearch={handleOnSearch}
              onChange={handleAutoCompleteChange}
              onSelect={handleSelectStoreId}
            />
          </Form.Item>
        </div>

        {selectedStoreIds.length > 0 && (
          <Participants
            storeIds={selectedStoreIds}
            onRemove={handleRemoveStore}
          />
        )}

        {isEdit && (
          <Form.Item
            name="hasAffiliate"
            valuePropName="checked"
            label={<Text>Has Affiliate products</Text>}
          >
            <Switch
              checked={hasAffiliate}
              onChange={handleOnIsAffiliateChange}
            />
          </Form.Item>
        )}
        {hasAffiliate && (
          <div
            className={cn(styles.formAffiliateProducts, {
              [styles.formAffiliateProductsInsideModal]: isEdit,
            })}
          >
            {affiliateProductsData.map(
              (item: AffiliateProductItemType, index: number) => {
                const { id, isValid, price, title, url, image, imageUrl } =
                  item;
                return (
                  <AffiliateProductItem
                    key={title + '_' + index}
                    id={id || undefined}
                    index={index}
                    isValid={isValid}
                    price={price}
                    title={title}
                    url={url}
                    image={image}
                    imageUrl={imageUrl}
                    setValue={setAffiliateProductsValue}
                    deleteValue={deleteAffiliateProduct}
                  />
                );
              }
            )}
            <div className={styles.buttonBottomContainer}>
              <Button
                type="primary"
                loading={isLoading}
                onClick={handleAddAffiliateProduct}
              >
                Add new affiliate product
              </Button>
            </div>
          </div>
        )}

        <Form.Item name="mentions" className={styles.mentionsRoot}>
          <Text className={styles.mentionSectionTitleWrapper}>
            <p className={styles.mentionsSectionTitle}>Mentioning options</p>
            <Tooltip
              title="Mention athletes, brands, and organizations by using their names and
          links to directly engage them and increase interaction on your
          content. Write down their names and url links."
            >
              <QuestionCircleTwoTone />
            </Tooltip>
          </Text>
        </Form.Item>

        <div className={styles.mentionsContainer}>
          <Mentions mentions={mentionsValues} onChange={handleMentionChange} />
        </div>
      </div>

      <Form.Item className={styles.finishButtonContainer}>
        <Button
          className={styles.finishButton}
          type="primary"
          htmlType="submit"
          loading={isLoading}
          disabled={submitIsDisabled}
        >
          {submitButtonTitle}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateEditStreamForm;
