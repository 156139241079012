import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Input, Form, Switch, Typography } from 'antd';
// Api
import { EDIT_DESIGNER } from 'api/designLab/mutations';
// Types
import {
  EditDesigner,
  EditDesignerVariables,
} from 'api/designLab/types/EditDesigner';
// UI
import { successNotification, errorNotification } from 'ui/Notification';
import Tip from 'ui/Tip';
// helpers
import { Designers_designers_entities } from 'api/designLab/types/Designers';

const { Text } = Typography;

const formItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 12 },
};

type EditDesignerModalProps = {
  designer: Designers_designers_entities;
  isDesignerEditing: boolean;
  toggleDesignerEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditDesignerModal: React.FC<EditDesignerModalProps> = ({
  designer,
  isDesignerEditing,
  toggleDesignerEditing,
}) => {
  const [editDesigner, { loading: isUpdatingInProgress }] = useMutation<
    EditDesigner,
    EditDesignerVariables
  >(EDIT_DESIGNER, {
    onCompleted: () => {
      toggleDesignerEditing(false);
    },
  });

  const [isAutoAssigned, setIsAutoAssigned] = useState<boolean>(
    designer.designerDetails.isIncludedInAutoAssignment
  );

  const [form] = Form.useForm();

  const handleDesigner = async () => {
    try {
      const { firstName, lastName, email, autoAssign } = form.getFieldsValue();
      const inputValues = {
        designerDetails: { isIncludedInAutoAssignment: autoAssign },
        designerId: designer.id,
        email,
        firstName,
        lastName,
      };

      await editDesigner({ variables: { input: inputValues } });
      successNotification('The designer is updated');
    } catch (e) {
      errorNotification();
    }
  };
  // eslint-disable-next-line
    const [formFields, setFormFields] = useState<boolean>(false);

  const getIfSubmitIsDisabled = (): boolean => {
    return Boolean(
      Object.values(form.getFieldsValue()).some(
        (field) => field === null || field === ''
      ) || form.getFieldsError()?.filter(({ errors }) => errors?.length)?.length
    );
  };

  const handleAutoAssignChange = (checked: boolean) =>
    setIsAutoAssigned(checked);

  const hideDesignEditModal = () => {
    toggleDesignerEditing(false);
    form.resetFields();
    setIsAutoAssigned(designer.designerDetails.isIncludedInAutoAssignment);
  };

  return (
    <Modal
      title="Designer editing"
      visible={isDesignerEditing}
      onCancel={hideDesignEditModal}
      onOk={handleDesigner}
      okButtonProps={{ disabled: getIfSubmitIsDisabled() }}
      confirmLoading={isUpdatingInProgress}
    >
      <Form
        form={form}
        layout="horizontal"
        name="setupDesigner"
        autoComplete="off"
        initialValues={designer}
        onValuesChange={() => {
          const fields = form.getFieldsValue();
          setFormFields({
            ...fields,
          });
        }}
        {...formItemLayout}
      >
        <Form.Item name="designerId" hidden>
          <Input type="text" />
        </Form.Item>
        <Form.Item name="firstName" label={<Text>First Name</Text>}>
          <Input />
        </Form.Item>
        <Form.Item name="lastName" label={<Text>Last Name</Text>}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label={<Text>Email</Text>}>
          <Input />
        </Form.Item>
        <Form.Item
          name="autoAssign"
          label={
            <Text>
              Auto Assign
              <Tip title="Enable auto assignment for designer" />
            </Text>
          }
        >
          <Switch onChange={handleAutoAssignChange} checked={isAutoAssigned} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditDesignerModal;
