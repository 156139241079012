import React from 'react';
import { useQuery } from '@apollo/client';
import { Button, Spin, Typography } from 'antd';
// Api
import { GET_MERCH_PRODUCT_V2 } from 'api/merch/queries';
// Types
import {
  GetMerchProductV2,
  GetMerchProductV2Variables,
} from 'api/merch/types/GetMerchProductV2';
import { DesignRequests_designRequests_entities_designCreationDetails_merchItems_merch } from 'api/designLab/types/DesignRequests';
import { GetAdminMerchProducts_getAdminMerchProducts_entities } from 'api/merch/types/GetAdminMerchProducts';
import { AutoSocialMediaPackStatus } from 'api/graphql-global-types';
// Styles
import styles from './SocialMediaPack.module.scss';

type SocialMediaPackProps = {
  merchItem:
    | GetAdminMerchProducts_getAdminMerchProducts_entities
    | DesignRequests_designRequests_entities_designCreationDetails_merchItems_merch;
};

const { Text } = Typography;

const SocialMediaPack = ({ merchItem }: SocialMediaPackProps): JSX.Element => {
  const areImagesReady =
    merchItem.autoSocialMediaPackStatus === AutoSocialMediaPackStatus.ready;
  const areImagesMissing =
    merchItem.autoSocialMediaPackStatus === AutoSocialMediaPackStatus.missing;

  const { data, loading } = useQuery<
    GetMerchProductV2,
    GetMerchProductV2Variables
  >(GET_MERCH_PRODUCT_V2, {
    variables: {
      input: {
        id: merchItem.id,
      },
    },
    skip: areImagesReady || areImagesMissing,
    pollInterval: 2000,
  });

  const handleDownloadImage = (imageUrl: string) => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.setAttribute('download', 'social-media-pack');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadAllImagesClick = () => {
    merchItem.autoSocialPackImages?.forEach((image) => {
      handleDownloadImage(image.url ?? '');
    });
  };

  const images = areImagesReady
    ? merchItem.autoSocialPackImages
    : data?.getMerchProduct.autoSocialPackImages ?? [];

  const isLoadingImages = !areImagesReady || loading;

  return (
    <div className={styles.root}>
      {merchItem.autoSocialPackImages.length > 1 && !isLoadingImages && (
        <Button type="primary" onClick={handleDownloadAllImagesClick}>
          Download All Images
        </Button>
      )}

      {isLoadingImages && (
        <>
          <Spin size="default" />

          <Text>
            Your Social Media Pack is being generated. This may take a few
            minutes.
          </Text>
        </>
      )}

      {!isLoadingImages && (
        <div className={styles.imagesWrapper}>
          {images.map((image) => (
            <div key={image.id} className={styles.imageWrapper}>
              <img className={styles.image} src={image.url ?? ''} alt="" />

              <Button
                type="primary"
                onClick={() => handleDownloadImage(image.url ?? '')}
              >
                Download
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SocialMediaPack;
