import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Form, Input, Space, Spin, Typography } from 'antd';
import Checkbox from 'antd/lib/checkbox';
// Api
import { SET_INTERVIEW_SETTINGS } from 'api/interview/mutations';
import { GET_INTERVIEW_SETTINGS } from 'api/interview/queries';
// Types
import { InterviewSettingType } from 'api/graphql-global-types';
import {
  GetInterviewSettings,
  GetInterviewSettings_getInterviewSettings_InterviewHighValueMemorabiliaTrigger,
  GetInterviewSettings_getInterviewSettings_InterviewHighValueMerchTrigger,
  GetInterviewSettings_getInterviewSettings_InterviewPPVParticipantTrigger,
} from 'api/interview/types/GetInterviewSettings';
import {
  SetInterviewSettings,
  SetInterviewSettingsVariables,
} from 'api/interview/types/SetInterviewSettings';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';

const { Title } = Typography;

const InterviewSettings = (): JSX.Element => {
  const { data } = useQuery<GetInterviewSettings>(GET_INTERVIEW_SETTINGS, {
    fetchPolicy: 'cache-and-network',
  });

  const [setInterviewSettings, { loading }] = useMutation<
    SetInterviewSettings,
    SetInterviewSettingsVariables
  >(SET_INTERVIEW_SETTINGS);

  const merchTriger = data?.getInterviewSettings.find(
    (item) => item.id === InterviewSettingType.HighValueMerchTrigger
  ) as GetInterviewSettings_getInterviewSettings_InterviewHighValueMerchTrigger;

  const memorabiliaTriger = data?.getInterviewSettings.find(
    (item) => item.id === InterviewSettingType.HighValueMemorabiliaTrigger
  ) as GetInterviewSettings_getInterviewSettings_InterviewHighValueMemorabiliaTrigger;

  const ppvParticipantTriger = data?.getInterviewSettings.find(
    (item) => item.id === InterviewSettingType.PPVParticipantTrigger
  ) as GetInterviewSettings_getInterviewSettings_InterviewPPVParticipantTrigger;

  const [form] = Form.useForm();

  const handleUpdateSettings = async () => {
    const values = form.getFieldsValue();
    try {
      await setInterviewSettings({
        variables: {
          input: {
            HighValueMemorabiliaTrigger: {
              isEnabled: values.interviewHighValueMemorabiliaTrigger,
              config: {
                minPrice: Number(values.interviewHighValueMemorabiliaMinPrice),
              },
            },
            HighValueMerchTrigger: {
              isEnabled: values.interviewHighValueMerchTrigger,
              config: {
                minPrice: Number(values.interviewHighValueMerchMinPrice),
              },
            },
            PPVParticipantTrigger: {
              isEnabled: values.interviewPPVParticipantTrigger,
            },
          },
        },
      });
      successNotification('Settings updated');
    } catch (error) {
      errorNotification((error as Error)?.message);
    }
  };

  const handleFinish = async () => {
    handleUpdateSettings();
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        interviewHighValueMerchTrigger: merchTriger?.isEnabled || false,
        interviewHighValueMerchMinPrice: merchTriger?.config?.minPrice || 1,
        interviewHighValueMemorabiliaTrigger:
          memorabiliaTriger?.isEnabled || false,
        interviewHighValueMemorabiliaMinPrice:
          memorabiliaTriger?.config?.minPrice || 1,
        interviewPPVParticipantTrigger:
          ppvParticipantTriger?.isEnabled || false,
      });
    }
  }, [data, form, merchTriger, memorabiliaTriger, ppvParticipantTriger]);

  return (
    <>
      <div>
        <Space direction="vertical" size="middle" align="end">
          <Form
            form={form}
            layout="horizontal"
            name="interviewSettings"
            autoComplete="off"
            onFinish={handleFinish}
          >
            <div>
              <div>
                <Title>Interview settings</Title>

                <Form.Item
                  name="interviewHighValueMerchTrigger"
                  label="Interview High Value Merch Trigger"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>

                <Form.Item
                  name="interviewHighValueMerchMinPrice"
                  label="Min price"
                >
                  <Input type="number" placeholder="Type here" />
                </Form.Item>

                <Form.Item
                  name="interviewHighValueMemorabiliaTrigger"
                  label="Interview High Value Memorabilia Trigger"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>

                <Form.Item
                  name="interviewHighValueMemorabiliaMinPrice"
                  label="Min price"
                >
                  <Input type="number" placeholder="Type here" />
                </Form.Item>

                <Form.Item
                  name="interviewPPVParticipantTrigger"
                  label="Interview PPV Participant Trigger"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </div>
            </div>
            <div>
              {loading ? (
                <Spin size="large" />
              ) : (
                <Button type="primary" htmlType="submit">
                  Save Settings
                </Button>
              )}
            </div>
          </Form>
        </Space>
      </div>
    </>
  );
};

export default InterviewSettings;
