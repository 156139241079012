import React, { useState } from 'react';
import { Row, Col } from 'antd';
// Types
import { MerchOption } from 'pages/HomePageManagement';
import { OptionsItem } from 'pages/HomePageManagement/components/SectionItem';
// Components
import SetupMerchStore from './SetupMerchStore';
import SetupMerchItem from './SetupMerchItem';

type SetupMerchSectionProps = {
  selectedMerch: MerchOption;
  onChange: ({
    store,
    merch,
  }: {
    store?: OptionsItem;
    merch?: OptionsItem;
  }) => void;
};

const SetupMerchSection: React.FC<SetupMerchSectionProps> = ({
  selectedMerch,
  onChange,
}) => {
  const [clearMerchCachedOptions, setClearMerchCachedOptions] =
    useState<boolean>(false);

  const onStoreChange = (store: OptionsItem) => {
    if (store.value !== selectedMerch.store.value) {
      setClearMerchCachedOptions(true);
      onChange({ store });
    }
  };

  const onMerchProductChange = (merch: OptionsItem) => {
    onChange({ merch });
  };

  return (
    <Row gutter={[16, 16]} align="middle">
      <Col className="gutter-row" span={12}>
        <SetupMerchStore
          selectedStore={selectedMerch.store}
          onStoreChange={onStoreChange}
        />
      </Col>
      <Col className="gutter-row" span={12}>
        <SetupMerchItem
          storeId={selectedMerch.store.value}
          selectedMerch={selectedMerch.merch}
          clearCachedOptions={clearMerchCachedOptions}
          onMerchChange={onMerchProductChange}
          setClearCachedOptions={setClearMerchCachedOptions}
        />
      </Col>
    </Row>
  );
};

export default SetupMerchSection;
