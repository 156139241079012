import { gql } from '@apollo/client';

export const GET_PRICE_MARGINS = gql`
  query GetPriceMargins {
    getPriceMargins {
      merchMargin
      streamMargin
      amaMargin
    }
  }
`;
