import React from 'react';
import { Modal } from 'antd';
// Components
import DLConstructor from '../DLConstructor/DLConstructor';

type MerchCreationModalProps = {
  onHide: () => void;
  isVisible?: boolean;
};

const MerchCreationModal = ({
  onHide,
  isVisible,
}: MerchCreationModalProps): JSX.Element | null => {
  return (
    <Modal
      title="Request merch design for Store"
      visible={isVisible}
      onCancel={onHide}
      destroyOnClose
      footer={null}
      width="100%"
      centered
    >
      <DLConstructor onComplete={onHide} isMerchDesign />
    </Modal>
  );
};

export default MerchCreationModal;
