import React from 'react';

import styles from './DashboardSection.module.scss';

type DashboardSectionProps = {
  title: string;
  value?: string | number;
};

const DashboardSection: React.FC<DashboardSectionProps> = ({
  title,
  value,
  children,
}) => {
  return (
    <div className={styles.dashboardSection}>
      <div className={styles.dashboardSectionHeader}>
        <h2 className={styles.dashboardSectionHeaderTitle}>{title}</h2>

        {value && (
          <div className={styles.dashboardSectionHeaderSide}>
            <h3 className={styles.dashboardSectionHeaderSideTitle}>
              Total Amount
            </h3>

            <p className={styles.dashboardSectionHeaderSideValue}>{value}</p>
          </div>
        )}
      </div>

      <div>{children}</div>
    </div>
  );
};

export default DashboardSection;
