import { gql } from '@apollo/client';

export const SET_INTERVIEWER_CAPABILITY = gql`
  mutation SetInterviewerCapability($input: SetInterviewerCapabilityInput!) {
    setInterviewerCapability(input: $input) {
      id
    }
  }
`;

export const REMOVE_INTERVIEWER_CAPABILITY = gql`
  mutation RemoveInterviewerCapability(
    $input: RemoveInterviewerCapabilityInput!
  ) {
    removeInterviewerCapability(input: $input) {
      id
    }
  }
`;

export const EDIT_INTERVIEWER_DETAILS = gql`
  mutation EditInterviewerDetails($input: EditInterviewerDetailsInput!) {
    editInterviewerDetails(input: $input) {
      id
    }
  }
`;
