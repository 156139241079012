import React from 'react';
import { Space, Typography } from 'antd';
// Api
import { ExperienceSalesItems } from 'components/common/Sales/ExperienceSales/index';
// Helpers
import { formatPrice } from 'components/common/ManageMerch/helpers';

import styles from './ExperienceSalesDetails.module.scss';

const { Text, Title } = Typography;

type ExperienceSalesDetailsProps = {
  experienceRequest: ExperienceSalesItems;
};

const ExperienceSalesDetails = ({
  experienceRequest,
}: ExperienceSalesDetailsProps): JSX.Element => {
  const experienceItem = experienceRequest?.experienceItem;
  const { experience, customer, store } = experienceItem;

  const mainImage = experience?.images?.find(({ isMainImage }) => isMainImage);

  return (
    <div className={styles.root}>
      <div className={styles.leftSide}>
        <img
          className={styles.image}
          src={mainImage?.experienceImageFileKey || ''}
          alt={experience.title || ''}
        />
        <Space direction="vertical">
          <Title>General</Title>

          <Space direction="vertical">
            <Text>{'Title: ' + experience.title || ''}</Text>
            <Text>{`Price: ${formatPrice(experience.requestedPrice)}`}</Text>
            <Text>{`Quantity: ${experienceRequest?.quantity}`}</Text>
            <Text>{`Status: ${experienceItem.requestStatus || ''}`}</Text>
          </Space>

          <Title>Payment</Title>

          <Space direction="vertical">
            <Text>{`Payment Method Type: ${
              experienceItem?.paymentMethod?.type || ''
            }`}</Text>
            <Text>{`Last 4: ${
              experienceItem?.paymentMethod?.last4 || ''
            }`}</Text>
          </Space>

          <Title>Summary</Title>

          <Space direction="vertical">
            <Text>{`Total: ${formatPrice(experienceItem?.price)}`}</Text>
          </Space>
        </Space>
      </div>

      <div>
        <Space direction="vertical">
          <Title>Customer</Title>

          <Space direction="vertical">
            <Text>{`Name: ${experienceRequest.customerName}`}</Text>
            <Text>{`Email: ${experienceRequest.email}`}</Text>
            <Text>{`Phone: ${customer?.phoneNumber}` || ''}</Text>
          </Space>

          <Title>Store</Title>

          <Space direction="vertical">
            <Text>{`Name: ${experienceRequest.storeName}`}</Text>
            <Text>{`Email: ${store?.email}` || ''}</Text>
            <Text>{`Phone: ${store?.phoneNumber}` || ''}</Text>
          </Space>
        </Space>
      </div>
    </div>
  );
};

export default ExperienceSalesDetails;
