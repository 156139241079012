import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
// Api
import { GET_USERS } from 'api/users/queries';
// Types
import { StoreStatus } from 'api/graphql-global-types';
import {
  Users,
  UsersVariables,
  Users_users_entities_paymentMethods,
} from 'api/users/types/Users';
// UI
import Table from 'ui/Table';
// Helpers
import { renderedCheckForBooleans } from 'helpers/utils';
// Hooks
import { useDefaultCustomerRedirectOnEmptyId } from 'hooks';

type PaymentMethodItem = Users_users_entities_paymentMethods & {
  mmyy: string;
};

const CustomerPayment: React.FC = () => {
  useDefaultCustomerRedirectOnEmptyId();
  const { customerId } = useParams<{ customerId: string | undefined }>();
  const { data, loading } = useQuery<Users, UsersVariables>(GET_USERS, {
    variables: {
      input: {
        id: customerId,
        status: [StoreStatus.Active],
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const paymentMethods: PaymentMethodItem[] =
    data?.users?.entities?.[0]?.paymentMethods?.map((record: any) => ({
      ...record,
      mmyy: `${record.expMonth}/${record.expYear}`,
    })) || [];

  const columns = [
    {
      title: 'Card Number',
      dataIndex: 'last4',
      key: 'cardNumber',
      align: 'center' as const,
      sorterType: 'number',
      fixed: 'left' as const,
      render: (last4: number) => `**** **** **** ${last4}`,
    },
    {
      title: 'MM/YY',
      dataIndex: 'mmyy',
      key: 'date',
      align: 'center' as const,
      fixed: 'left' as const,
      sorterType: 'text',
    },
    {
      title: 'Cardholder Name',
      dataIndex: 'cardholderName',
      key: 'name',
      fixed: 'left' as const,
      sorterType: 'text',
    },
    {
      title: 'Default',
      dataIndex: 'isDefault',
      key: 'default',
      align: 'center' as const,
      sorterType: 'boolean',
      render: renderedCheckForBooleans,
    },
  ];

  return (
    <Table<PaymentMethodItem>
      columns={columns}
      data={paymentMethods}
      scroll={{ x: 800 }}
      loading={loading}
    />
  );
};

export default CustomerPayment;
