import React, { ChangeEvent, useState } from 'react';
import moment from 'moment';
import { Button, Image, Input } from 'antd';
import { useMutation } from '@apollo/client';
// Api
import {
  DOWNLOAD_CAMPAIGN_OFFERS,
  ADD_OFFLINE_PAYMENT,
} from 'api/campaignV2/mutations';
// Helpers
import { isImage } from 'helpers/isImage';
import { getContentTypeText } from 'helpers/socialMediaContentTypes';
// Constants
import { FALLBACK_IMAGE } from 'constants/global';
// Types
import {
  GetCampaignsV2_getCampaigns_entities,
  GetCampaignsV2_getCampaigns_entities_activities_CustomActivity,
  GetCampaignsV2_getCampaigns_entities_activities_InPersonAppearanceActivity,
  GetCampaignsV2_getCampaigns_entities_activities_SocialActivity,
  GetCampaignsV2_getCampaigns_entities_activities_SocialActivity_files,
  GetCampaignsV2_getCampaigns_entities_activities_SponsoredAppearanceActivity,
  GetCampaignsV2_getCampaigns_entities_activities_VirtualAppearanceActivity,
  GetCampaignsV2_getCampaigns_entities_agency,
  GetCampaignsV2_getCampaigns_entities_search,
} from 'api/campaignV2/types/GetCampaignsV2';
import {
  DownloadCampaignOffersFull,
  DownloadCampaignOffersFullVariables,
} from 'api/campaignV2/types/DownloadCampaignOffersFull';
import {
  AddOfflineCampaignPayment,
  AddOfflineCampaignPaymentVariables,
} from 'api/campaignV2/types/AddOfflineCampaignPayment';
import { CampaignStatus } from 'api/graphql-global-types';
// Ui
import { successNotification, errorNotification } from 'ui/Notification';
// Icons
import { DownloadOutlined } from '@ant-design/icons';
// Style
import styles from './CampaignDetailsModal.module.scss';

type CampaignDetailsModalProps = {
  record: GetCampaignsV2_getCampaigns_entities | null;
  onBudgetUpdate: () => void;
};

const CampaignDetailsModal = ({
  record,
  onBudgetUpdate,
}: CampaignDetailsModalProps): JSX.Element | null => {
  const [newBudget, setNewBudget] = useState<string>('');
  const [comment, setComment] = useState<string | null>(null);

  const [downloadCSV, { loading: downloadingCSV }] = useMutation<
    DownloadCampaignOffersFull,
    DownloadCampaignOffersFullVariables
  >(DOWNLOAD_CAMPAIGN_OFFERS);

  const [addOfflineCampaign] = useMutation<
    AddOfflineCampaignPayment,
    AddOfflineCampaignPaymentVariables
  >(ADD_OFFLINE_PAYMENT);

  const socialActivity = record?.activities?.find(
    (item) => item.__typename === 'SocialActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_SocialActivity;

  const sponsoredAppearanceActivity = record?.activities?.find(
    (item) => item.__typename === 'SponsoredAppearanceActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_SponsoredAppearanceActivity;

  const virtualAppearanceActivity = record?.activities?.find(
    (item) => item.__typename === 'VirtualAppearanceActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_VirtualAppearanceActivity;

  const inPersonAppearanceActivity = record?.activities?.find(
    (item) => item.__typename === 'InPersonAppearanceActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_InPersonAppearanceActivity;

  const customActivity = record?.activities?.find(
    (item) => item.__typename === 'CustomActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_CustomActivity;

  const brand = record?.agency as GetCampaignsV2_getCampaigns_entities_agency;
  const filters = record?.search as GetCampaignsV2_getCampaigns_entities_search;
  const locations = filters?.locations;

  const instructions =
    socialActivity?.instructions ||
    virtualAppearanceActivity?.instructions ||
    inPersonAppearanceActivity?.instructions ||
    customActivity?.instructions;

  const files =
    socialActivity?.files?.length > 0
      ? socialActivity.files
      : virtualAppearanceActivity?.files?.length > 0
      ? virtualAppearanceActivity.files
      : inPersonAppearanceActivity?.files?.length > 0
      ? inPersonAppearanceActivity.files
      : customActivity?.files?.length > 0
      ? customActivity.files
      : [];

  const possibleDates =
    inPersonAppearanceActivity || virtualAppearanceActivity
      ? inPersonAppearanceActivity?.possibleDates ||
        virtualAppearanceActivity?.possibleDates
      : null;

  const description =
    inPersonAppearanceActivity || virtualAppearanceActivity
      ? inPersonAppearanceActivity?.description ||
        virtualAppearanceActivity?.description
      : null;

  const duration =
    inPersonAppearanceActivity || virtualAppearanceActivity
      ? inPersonAppearanceActivity?.duration ||
        virtualAppearanceActivity?.duration
      : null;

  const onCSVButtonClick = async () => {
    try {
      const { data } = await downloadCSV({
        variables: {
          input: {
            campaignId: record?.id || '',
          },
        },
      });

      if (data?.downloadCampaignOffersFull.csvUrl) {
        window.open(data.downloadCampaignOffersFull.csvUrl, '_blank');
        successNotification('CSV has been downloaded');
      }
    } catch (error) {
      console.error('Error downloading CSV', error);
      errorNotification('Error downloading CSV');
    }
  };

  const handleBudgetChange = (newValue: ChangeEvent<HTMLInputElement>) => {
    const newBudgetValue = newValue.target.value;

    const positiveOrNegativeNumber = /^[-+]?\d+$/;

    const isValid = positiveOrNegativeNumber.test(newBudgetValue);
    if (isValid) {
      setNewBudget(newBudgetValue);
    }
  };

  const handleCommentChange = (newValue: ChangeEvent<HTMLInputElement>) => {
    const newCommentValue = newValue.target.value;
    if (newCommentValue !== '') {
      setComment(newCommentValue);
    } else {
      setComment(null);
    }
  };

  const handleUpdateBudget = async () => {
    if (record?.id && Number(newBudget)) {
      try {
        const { data } = await addOfflineCampaign({
          variables: {
            input: {
              amount: Number(newBudget),
              campaignId: record?.id,
              ...(comment && { comment }),
            },
          },
        });

        if (data?.addOfflineCampaignPayment.id) {
          successNotification('Campaign budget has been updated');
          onBudgetUpdate();
        }
      } catch (error) {
        console.error('Error updating budget', error);
        errorNotification('Error updating budget');
      }
    } else {
      errorNotification('Impossible budget value');
    }
  };

  const showBudgetField =
    record?.status !== CampaignStatus.COMPLETED &&
    record?.status !== CampaignStatus.DECLINED;

  if (!record) return null;

  return (
    <div className={styles.root}>
      <div className={styles.recordData}>
        <div>
          <p className={styles.label}>ID:</p>
          <p className={styles.value}>{record.id}</p>
        </div>

        <div>
          <p className={styles.label}>Campaign Name:</p>
          <p className={styles.value}>{record.name}</p>
        </div>

        <div>
          <p className={styles.label}>Brand email:</p>
          <p className={styles.value}>{brand.email}</p>
        </div>

        <div>
          <p className={styles.label}>Brand phone number:</p>
          <p className={styles.value}>{brand.phoneNumber}</p>
        </div>

        <div>
          <p className={styles.label}>Submit date:</p>
          <p className={styles.value}>
            {moment(record.createdAt).format('MM/DD/YYYY')}
          </p>
        </div>

        <div>
          <p className={styles.label}>Due date:</p>
          <p className={styles.value}>
            {moment(record.dueDate).format('MM/DD/YYYY')}
          </p>
        </div>

        <div>
          <p className={styles.label}>Total budget:</p>
          <p className={styles.value}> {record.budget}</p>
        </div>

        <div>
          <p className={styles.label}>Budget remaining:</p>
          <p className={styles.value}> {record.budgetLeft}</p>
        </div>

        {showBudgetField && (
          <div className={styles.budgetWrapper}>
            <p className={styles.label}>Budget Change:</p>
            <div className={styles.budgetInputAndButton}>
              <Input
                onChange={handleBudgetChange}
                style={{ width: 250 }}
                placeholder="$"
              />
              <Input
                onChange={handleCommentChange}
                style={{ width: 250 }}
                placeholder="Reason"
              />
              <Button type="primary" onClick={handleUpdateBudget}>
                Add budget
              </Button>
            </div>
            <p className={styles.budgetExplanation}>
              Positive number to adds budget
            </p>
            <p className={styles.budgetExplanation}>
              Negative number to subtract budget
            </p>
          </div>
        )}

        <div>
          <p className={styles.label}>Cost per athlete:</p>
          <p className={styles.value}> {record.flatFeePerStore}</p>
        </div>

        {record.search?.smPlatforms?.[0] && (
          <div>
            <p className={styles.label}>Platform:</p>
            <p className={styles.value}>{record.search?.smPlatforms?.[0]}</p>
          </div>
        )}

        {!!filters?.sports?.length && (
          <div>
            <p className={styles.label}>Sports:</p>
            <p className={styles.value}>
              {filters?.sports?.map((sport) => {
                return (
                  <span key={sport} className={styles.value}>
                    {sport}
                  </span>
                );
              })}
            </p>
          </div>
        )}

        {!!filters?.genders && (
          <div>
            <p className={styles.label}>Gender:</p>
            <p className={styles.value}> {filters.genders}</p>
          </div>
        )}

        {!!filters?.athleteMinAge && (
          <div>
            <p className={styles.label}>Athlete minimum age:</p>
            <p className={styles.value}> {filters.athleteMinAge}</p>
          </div>
        )}

        {!!filters?.athleteMaxAge && (
          <div>
            <p className={styles.label}>Athlete maximum age:</p>
            <p className={styles.value}> {filters.athleteMaxAge}</p>
          </div>
        )}

        {!!(filters?.minFollowers || filters?.maxFollowers) && (
          <div>
            <p className={styles.label}>Social media following:</p>
            <p className={styles.value}>
              {filters.minFollowers || filters.maxFollowers}
            </p>
          </div>
        )}

        {!!locations?.length && (
          <div>
            <p className={styles.label}>Locations:</p>
            <p className={styles.value}>
              {locations?.map((location) => {
                return (
                  <span key={location.country} className={styles.value}>
                    {location.country
                      ? 'Country: ' + location.country
                      : '' + location.country && location.state
                      ? ', '
                      : '' + location.state
                      ? 'State: ' + location.state
                      : ''}
                  </span>
                );
              })}
            </p>
          </div>
        )}

        <div>
          <p className={styles.label}>Examples:</p>
          {files?.length > 0 ? (
            files.map(
              (
                item: GetCampaignsV2_getCampaigns_entities_activities_SocialActivity_files
              ) => {
                const fileExtension = item?.key?.split('.')[1] || '';

                const isImageKey = isImage(fileExtension);
                return (
                  <div key={item.key}>
                    {isImageKey ? (
                      <Image
                        fallback={FALLBACK_IMAGE}
                        key={item.key}
                        width={200}
                        height={200}
                        src={item.url || ''}
                        style={{ objectFit: 'cover' }}
                        alt={item.key || 'uploads'}
                      />
                    ) : (
                      <>
                        <a
                          key={item.key}
                          href={item.url}
                          download={item.key}
                          title={item.key}
                        >
                          <DownloadOutlined /> Download file
                        </a>
                      </>
                    )}
                  </div>
                );
              }
            )
          ) : (
            <p>no example</p>
          )}
        </div>
      </div>

      <div className={styles.activityData}>
        <div className={styles.storesData}>
          <h1>Stores: </h1>

          <Button
            type="primary"
            loading={downloadingCSV}
            onClick={onCSVButtonClick}
          >
            Export to CSV
          </Button>
        </div>

        {!!socialActivity && socialActivity.brandName && (
          <div>
            <p className={styles.label}>Brand Name:</p>
            <p className={styles.value}>{socialActivity.brandName}</p>
          </div>
        )}

        {!!socialActivity && socialActivity.website && (
          <div>
            <p className={styles.label}>Website:</p>
            <p className={styles.value}>{socialActivity.website}</p>
          </div>
        )}

        {!!instructions && (
          <div>
            <p className={styles.label}>Campaign instructions:</p>
            <p className={styles.value}>{instructions}</p>
          </div>
        )}

        {!!socialActivity && (
          <div>
            <p className={styles.label}>Encouraged:</p>
            <p className={styles.value}>{socialActivity.encouraged ?? 'n/a'}</p>
          </div>
        )}

        {!!socialActivity && (
          <div>
            <p className={styles.label}>Please avoid:</p>
            <p className={styles.value}>{socialActivity.avoid ?? 'n/a'}</p>
          </div>
        )}

        {!!socialActivity && socialActivity.mediaType?.length && (
          <div>
            <p className={styles.label}>Type of media:</p>
            <p className={styles.value}>
              {socialActivity.mediaType
                ?.map((item) => item?.toLowerCase())
                .join(', ')}
            </p>
          </div>
        )}

        {!!socialActivity && socialActivity?.tasks?.length && (
          <div>
            <p className={styles.label}>Post type:</p>
            <p className={styles.value}>
              {getContentTypeText(
                socialActivity?.tasks?.map((task) => task.type)
              ).join(', ')}
            </p>
          </div>
        )}

        {!!socialActivity && (
          <div>
            <p className={styles.label}>Suggested caption:</p>
            <p className={styles.value}>
              {socialActivity.suggestedCaption ?? 'n/a'}
            </p>
          </div>
        )}

        {!!socialActivity && (
          <div>
            <p className={styles.label}>Hashtags:</p>
            <p className={styles.value}>
              {socialActivity.hashtags?.join(', ') || 'n/a'}
            </p>
          </div>
        )}

        {!!socialActivity && (
          <div>
            <p className={styles.label}>Profile mentions:</p>
            <p className={styles.value}>
              {socialActivity.profileMentions ?? 'n/a'}
            </p>
          </div>
        )}

        {!!socialActivity && (
          <div>
            <p className={styles.label}>Promotion codes:</p>
            {(socialActivity.promoCodes?.length ?? 0) > 0
              ? socialActivity.promoCodes?.map((item) => {
                  return (
                    <span key={item} className={styles.value}>
                      {item}
                    </span>
                  );
                })
              : 'n/a'}
          </div>
        )}

        {!!socialActivity && (
          <div>
            <p className={styles.label}>Promotion urls:</p>
            {(socialActivity.promoUrls?.length ?? 0) > 0
              ? socialActivity.promoUrls?.map((item) => {
                  return (
                    <span key={item} className={styles.value}>
                      {item}
                    </span>
                  );
                })
              : 'n/a'}
          </div>
        )}

        {!!socialActivity && (
          <div>
            <p className={styles.label}>Quantity instructions:</p>
            <p className={styles.value}>
              {socialActivity.quantityInstructions}
            </p>
          </div>
        )}

        {!!socialActivity && (
          <div>
            <p className={styles.label}>Script:</p>
            <p className={styles.value}> {socialActivity.script ?? 'n/a'}</p>
          </div>
        )}

        {!!inPersonAppearanceActivity && inPersonAppearanceActivity.country && (
          <div>
            <p className={styles.label}>Country:</p>
            <p className={styles.value}>{inPersonAppearanceActivity.country}</p>
          </div>
        )}

        {!!inPersonAppearanceActivity && (
          <div>
            <p className={styles.label}>State:</p>
            <p className={styles.value}> {inPersonAppearanceActivity.state}</p>
          </div>
        )}

        {!!inPersonAppearanceActivity && (
          <div>
            <p className={styles.label}>Zip:</p>
            <p className={styles.value}> {inPersonAppearanceActivity.zip}</p>
          </div>
        )}
        {!!inPersonAppearanceActivity && (
          <div>
            <p className={styles.label}>City:</p>
            <p className={styles.value}> {inPersonAppearanceActivity.city}</p>
          </div>
        )}
        {!!inPersonAppearanceActivity && (
          <div>
            <p className={styles.label}>Address:</p>
            <p className={styles.value}>{inPersonAppearanceActivity.address}</p>
          </div>
        )}

        {!!inPersonAppearanceActivity && (
          <div>
            <p className={styles.label}>Venue name:</p>
            <p className={styles.value}>
              {inPersonAppearanceActivity.venueName}
            </p>
          </div>
        )}

        {!!inPersonAppearanceActivity && (
          <div>
            <p className={styles.label}>Covered expenses:</p>
            <p className={styles.value}>
              {inPersonAppearanceActivity.coveredExpenses}
            </p>
          </div>
        )}

        {!!possibleDates && (
          <div>
            <p className={styles.label}>Possible Dates:</p>
            <p className={styles.value}>{possibleDates}</p>
          </div>
        )}

        {!!description && (
          <div>
            <p className={styles.label}>Description:</p>
            <p className={styles.value}>{description}</p>
          </div>
        )}

        {!!duration && (
          <div>
            <p className={styles.label}>Duration:</p>
            <p className={styles.value}>{duration}</p>
          </div>
        )}

        {!!sponsoredAppearanceActivity && (
          <div>
            <p className={styles.label}>Video Type:</p>
            <p className={styles.value}>
              {sponsoredAppearanceActivity.videoType}
            </p>
          </div>
        )}

        {!!sponsoredAppearanceActivity && (
          <div>
            <p className={styles.label}>With Amplify:</p>
            <p className={styles.value}>
              {`${sponsoredAppearanceActivity.withAmplify}`}
            </p>
          </div>
        )}

        {!!sponsoredAppearanceActivity && sponsoredAppearanceActivity?.target && (
          <div>
            <p className={styles.label}>Target:</p>
            <p className={styles.value}>
              {sponsoredAppearanceActivity.target.type +
                ', id: ' +
                sponsoredAppearanceActivity.target.id}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CampaignDetailsModal;
