// Types
import {
  MemorabiliaProductType,
  MerchOrderStatus,
} from 'api/graphql-global-types';
import { GetAnyProductSales_getAnyProductSales_entities_order_subOrders } from 'api/memorabilia/types/GetAnyProductSales';

export const getMerchOrderStatus = (
  subOrders: GetAnyProductSales_getAnyProductSales_entities_order_subOrders[]
): MerchOrderStatus => {
  const existingStatuses = subOrders.reduce(
    (prev, acc) => {
      if (acc.status === MerchOrderStatus.Canceled) {
        prev.isCanceled = true;
      }

      if (acc.status === MerchOrderStatus.Pending) {
        prev.isPending = true;
      }

      if (acc.status === MerchOrderStatus.Completed) {
        prev.isCompleted = true;
      }

      if (acc.status === MerchOrderStatus.Processing) {
        prev.isProcessing = true;
      }

      return prev;
    },
    {
      isCanceled: false,
      isPending: false,
      isCompleted: false,
      isProcessing: false,
    }
  );

  const status = existingStatuses.isPending
    ? MerchOrderStatus.Pending
    : existingStatuses.isProcessing
    ? MerchOrderStatus.Processing
    : existingStatuses.isCompleted
    ? MerchOrderStatus.Completed
    : existingStatuses.isCanceled
    ? MerchOrderStatus.Canceled
    : MerchOrderStatus.Pending;

  return status;
};

export const getNumberOfSuborderItems = (
  subOrders: GetAnyProductSales_getAnyProductSales_entities_order_subOrders[]
): number => {
  const numberOfItems = subOrders.reduce((total, order) => {
    const orderItemsTotal = order.merchOrderItems.reduce(
      (itemTotal, item) => itemTotal + item.amount,
      0
    );
    return total + orderItemsTotal;
  }, 0);

  return numberOfItems;
};

export const orderStatus = [
  { value: MerchOrderStatus.Completed, text: 'Completed' },
  { value: MerchOrderStatus.Canceled, text: 'Canceled' },
  { value: MerchOrderStatus.Pending, text: 'Pending' },
  { value: MerchOrderStatus.Processing, text: 'Processing' },
];

export const getDropDownItemName = (category: string): string => {
  switch (category) {
    case 'label':
      return 'Shipping label';
    case 'commercial_invoice':
      return 'Commercial invoice';
    case 'packing_slip':
      return 'Packing slip';
    case 'battery_form':
      return 'Battery form';
    default:
      return category;
  }
};

export const joinStrings = (
  params: (string | number | null | undefined)[]
): string => {
  return params
    .filter((item) => {
      if (typeof item === 'string') {
        return item.trim();
      }

      return !!item;
    })
    .join(', ');
};

export const getCommonAddressString = (
  address:
    | GetAnyProductSales_getAnyProductSales_entities_order_subOrders
    | undefined
): string => {
  return joinStrings([
    address?.addressLine1,
    address?.city,
    address?.stateCode,
    address?.zipCode,
    address?.country,
  ]);
};

export const getMerchType = (
  suborders: GetAnyProductSales_getAnyProductSales_entities_order_subOrders[]
): string => {
  const merchSold = suborders.some(({ merchOrderItems }) =>
    merchOrderItems.some((item) => item.memorabilia === null)
  );
  const memorabiliaSold = suborders.some(({ merchOrderItems }) =>
    merchOrderItems.some(
      (item) =>
        item.memorabilia !== null &&
        item.memorabilia.memorabiliaProductType ===
          MemorabiliaProductType.Memorabilia
    )
  );
  const productSold = suborders.some(({ merchOrderItems }) =>
    merchOrderItems.some(
      (item) =>
        item.memorabilia !== null &&
        item.memorabilia.memorabiliaProductType ===
          MemorabiliaProductType.Product
    )
  );

  if (merchSold) {
    if (memorabiliaSold) {
      if (productSold) {
        return 'Merch / Memorabilia / Product';
      } else {
        return 'Merch / Memorabilia';
      }
    } else {
      return 'Merch';
    }
  } else if (memorabiliaSold) {
    if (productSold) {
      return 'Memorabilia / Product';
    } else {
      return 'Memorabilia';
    }
  } else {
    return 'Product';
  }
};
