import React, { useEffect } from 'react';
import { Form, Button, InputNumber, Input, Typography, Checkbox } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
// Helpers
import { getDisabledCategories } from 'helpers/campaign';
// Hooks
import { useDefaultStoreRedirectOnEmptyId } from 'hooks';
// Api
import { SET_BRAND_DEAL_SETTINGS } from 'api/deals/mutations';
import { GET_STORE_BRAND_DEAL_INFO } from 'api/store/queries';
// Types
import {
  SetBrandDealSettings,
  SetBrandDealSettingsVariables,
} from 'api/deals/types/SetBrandDealSettings';
import {
  GetStoreBrandDeal,
  GetStoreBrandDealVariables,
} from 'api/store/types/GetStoreBrandDeal';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Styles
import styles from './SetupDeals.module.scss';

export type SettingsFormInputs = {
  contactEmail?: string | null;
  minFlatPrice?: number | null;
  contactPhoneNumberE164: string | null;
  minBookMePrice?: number | null;
  minSocialPrice?: number | null;
  minSponsoredLivePrice?: number | null;
  disabledCategories?: string[] | null;
  isAcceptingFreeProducts?: boolean;
  isArticlesPrEnabled?: boolean;
};

const { Text } = Typography;

const SetupDeals = (): JSX.Element => {
  useDefaultStoreRedirectOnEmptyId();
  const { storeId } = useParams<{ storeId: string | undefined }>();

  const [form] = Form.useForm();

  const { data, refetch } = useQuery<
    GetStoreBrandDeal,
    GetStoreBrandDealVariables
  >(GET_STORE_BRAND_DEAL_INFO, {
    variables: {
      input: {
        id: storeId,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !storeId,
  });

  const [setBrandDealSettings, { loading }] = useMutation<
    SetBrandDealSettings,
    SetBrandDealSettingsVariables
  >(SET_BRAND_DEAL_SETTINGS);

  const storeData = data?.store;

  const industryCategories = storeData?.brandDealSettings?.industryCategories;

  const onSubmit = async () => {
    const {
      contactEmail,
      minFlatPrice,
      phoneNumber,
      minSocialPrice,
      minBookMePrice,
      isArticlesPrEnabled,
      isAcceptingFreeProducts,
      minSponsoredLivePrice,
    } = form.getFieldsValue();

    const disabledCategories = getDisabledCategories(form.getFieldsValue());

    try {
      await setBrandDealSettings({
        variables: {
          input: {
            contactEmail: contactEmail || null,
            minFlatPrice: minFlatPrice || null,
            ownerId: storeId,
            contactPhone: phoneNumber || null,
            minSocialPrice: minSocialPrice || null,
            minBookMePrice: minBookMePrice || null,
            minSponsoredLivePrice: minSponsoredLivePrice || null,
            isArticlesPrEnabled: isArticlesPrEnabled || false,
            isAcceptingFreeProducts: isAcceptingFreeProducts || false,
            disabledCategories,
          },
        },
      });
      successNotification(`Brand Deal Settings updated successfully`);
      refetch({
        input: {
          id: storeId,
        },
      });
    } catch (error) {
      errorNotification((error as Error)?.message);
      console.error(error);
    }
  };

  useEffect(() => {
    if (storeData) {
      form.setFieldsValue({
        contactEmail: storeData.brandDealSettings?.contactEmail || '',
        minFlatPrice: storeData.brandDealSettings?.minFlatPrice || 0,
        phoneNumber: storeData.brandDealSettings?.contactPhone || '',
        minSocialPrice: storeData.brandDealSettings?.minSocialPrice || 0,
        minBookMePrice: storeData.brandDealSettings?.minBookMePrice || 0,
        minSponsoredLivePrice:
          storeData.brandDealSettings?.minSponsoredLivePrice || 0,
        isArticlesPrEnabled:
          storeData.brandDealSettings?.isArticlesPrEnabled || false,
        isAcceptingFreeProducts:
          storeData.brandDealSettings?.isAcceptingFreeProducts || false,
      });
    }
  }, [storeData, form, data]);

  useEffect(() => {
    if (industryCategories) {
      industryCategories.forEach((category) => {
        form.setFieldsValue({
          [category.value]: category.isEnabled,
        });
      });
    }
  }, [industryCategories, form]);

  return (
    <div className={styles.root}>
      <Form
        name="SetupDealsForm"
        aria-label="Setup Deals Form"
        onFinish={onSubmit}
        form={form}
      >
        <Form.Item
          name="contactEmail"
          label={<Text>Preferred Email</Text>}
          rules={[
            {
              required: false,
            },
            {
              type: 'email',
              message: 'Please input a valid email address',
            },
          ]}
        >
          <Input
            type="email"
            placeholder="Preferred Email"
            aria-label="Preferred email"
          />
        </Form.Item>

        <Form.Item
          name="phoneNumber"
          rules={[
            {
              pattern:
                /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/gm,
              message: 'Please enter a valid phone number',
            },
          ]}
          label={<Text>Contact Phone Number</Text>}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="minFlatPrice"
          label={<Text>Minimum Deal Price</Text>}
          rules={[
            {
              required: false,
            },
            {
              type: 'number',
              message: 'Please enter the minimum deal price',
            },
          ]}
        >
          <InputNumber
            type="number"
            placeholder="Minimum Deal Price"
            aria-label="Minimum deal price"
          />
        </Form.Item>

        <Form.Item
          name="minSocialPrice"
          label={<Text>Minimum Social Price</Text>}
        >
          <InputNumber
            type="number"
            placeholder="Minimum Social Price"
            aria-label="Minimum social price"
          />
        </Form.Item>

        <Form.Item
          name="minBookMePrice"
          label={<Text>Minimum Book Me Price</Text>}
        >
          <InputNumber
            type="number"
            placeholder="Minimum Book Me Price"
            aria-label="Minimum book me price"
          />
        </Form.Item>

        <Form.Item
          name="minSponsoredLivePrice"
          label={<Text>Minimum Sponsored Live Price</Text>}
        >
          <InputNumber
            type="number"
            placeholder="Minimum Sponsored Live Price"
            aria-label="Minimum sponsored live price"
          />
        </Form.Item>

        <Form.Item
          name="isArticlesPrEnabled"
          label="Sponsored Articles & PR"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <Form.Item
          name="isAcceptingFreeProducts"
          label="Accept Free Product Campaigns"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <p>
          If you do not wish to see brand deals, or cannot promote brands in
          these categories, please opt out by toggling off.
        </p>

        {industryCategories?.map((category) => {
          return (
            <Form.Item
              valuePropName="checked"
              key={category.value}
              name={category.value}
              label={category.label}
            >
              <Checkbox />
            </Form.Item>
          );
        })}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SetupDeals;
