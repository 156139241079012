import { gql } from '@apollo/client';

export const ADD_PSYCHOSOCIAL_KEYWORD = gql`
  mutation AddPsychosocialKeyword($input: AddKeywordInput!) {
    addPsychosocialKeyword(input: $input) {
      id
    }
  }
`;

export const EDIT_PSYCHOSOCIAL_KEYWORD = gql`
  mutation EditPsychosocialKeyWord($input: EditKeywordInput!) {
    editPsychosocialKeyWord(input: $input) {
      id
    }
  }
`;

export const DELETE_PSYCHOSOCIAL_KEYWORD = gql`
  mutation DeletePsychosocialKeyword($id: String!) {
    deletePsychosocialKeyword(id: $id) {
      id
    }
  }
`;

export const DELETE_PSYCHOSOCIAL_KEYWORD_FROM_STORE = gql`
  mutation DeletePsychosocialKeywordsFromStore(
    $psychosocialKeywordId: String!
    $storeId: String!
  ) {
    deletePsychosocialKeywordsFromStore(
      psychosocialKeywordId: $psychosocialKeywordId
      storeId: $storeId
    ) {
      id
    }
  }
`;
