import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Form, InputNumber, Input, Typography, Button, Spin } from 'antd';
import { uniqBy } from 'lodash';
// Api
import { MANUAL_MERCH_MERGE } from 'api/merch/mutations';
import { GET_MERCH_PRODUCT_V2 } from 'api/merch/queries';
// Types
import { GetAdminMerchProducts_getAdminMerchProducts_entities } from 'api/merch/types/GetAdminMerchProducts';
import { GetUnmergedMerch_getUnmergedMerch_entities } from 'api/merch/types/GetUnmergedMerch';
import {
  ManualMerchMerge,
  ManualMerchMergeVariables,
} from 'api/merch/types/ManualMerchMerge';
import {
  GetMerchProductV2,
  GetMerchProductV2Variables,
  GetMerchProductV2_getMerchProduct,
} from 'api/merch/types/GetMerchProductV2';
import { MerchType } from 'api/graphql-global-types';
// Helpers
import {
  GetProductColors,
  computeMerchBackgroundColor,
  getProductColors,
} from 'components/common/ManageMerch/helpers';
import { listGenders } from 'helpers/mergeMerch';
// Ui
import { errorNotification } from 'ui/Notification';
// Styles
import styles from './MergeMerchModal.module.scss';

type MergeMerchModalProps = {
  parentMerch: GetAdminMerchProducts_getAdminMerchProducts_entities;
  childMerch: GetUnmergedMerch_getUnmergedMerch_entities;
  childMerchColors: GetProductColors;
  childMerchImages: any;
  onClose: () => void;
};

type FormValues = {
  title: string;
  finalProfit: number;
};

const { Text } = Typography;

const MergeMerchModal = ({
  parentMerch,
  childMerch,
  childMerchColors,
  childMerchImages,
  onClose,
}: MergeMerchModalProps): JSX.Element => {
  const history = useHistory();
  const initialFormValues: FormValues = {
    title: parentMerch?.title || '',
    finalProfit: parentMerch?.requestedProfit || 0,
  };
  const [formFields, setFormFields] = useState<FormValues>(initialFormValues);

  const [manualMerchMerge, loading] = useMutation<
    ManualMerchMerge,
    ManualMerchMergeVariables
  >(MANUAL_MERCH_MERGE);

  const { data: myProductData } = useQuery<
    GetMerchProductV2,
    GetMerchProductV2Variables
  >(GET_MERCH_PRODUCT_V2, {
    variables: {
      input: {
        id: parentMerch?.id,
      },
    },
  });

  const [form] = Form.useForm();
  const setFields = () => {
    const fields: FormValues = form.getFieldsValue();

    setFormFields({
      ...fields,
    });
  };

  const handleFinish = async () => {
    try {
      await manualMerchMerge({
        variables: {
          input: {
            childMerchId: [childMerch.id],
            parentMerchId: parentMerch.id,
            requestedProfit: formFields.finalProfit,
            title: formFields.title,
          },
        },
      });
      handleClose();
    } catch (error) {
      errorNotification((error as Error)?.message);
    }
  };

  const handleClose = () => {
    history.go(0);
    onClose();
  };

  const parentMerchItem = myProductData?.getMerchProduct;
  const parentMerchColors =
    (parentMerchItem &&
      getProductColors(parentMerchItem as GetMerchProductV2_getMerchProduct)) ||
    [];
  const parentMerchImages = uniqBy(
    parentMerchItem?.merchProductVariants,
    'mockupImageURL'
  ).map((variant) => variant.mockupImageURL);

  const isShirt = childMerch.type === MerchType.TShirt;

  const childGenders = childMerch.genders?.length
    ? listGenders(childMerch.genders, isShirt)
    : '';
  const parentGenders = parentMerch.genders?.length
    ? listGenders(parentMerch.genders, isShirt)
    : '';

  return (
    <div className={styles.merchModalRoot}>
      <div className={styles.merchCompareContainer}>
        <div className={styles.merchChildRoot}>
          <div className={styles.title}>
            Merge Child <ArrowRightOutlined />
          </div>
          <div className={styles.merchImages}>
            {(childMerchImages as string[])?.map((item, index) => (
              <div key={index} className={styles.imageWrapper}>
                <img className={styles.photo} src={item || ''} alt="" />
              </div>
            ))}
          </div>
          <div className={styles.merchInformation}>
            <p>Merch title: {childMerch?.title || ''}</p>
            <p>Gender: {childGenders}</p>
            <div className={styles.colors}>
              Colors:
              {childMerchColors.map((item) => {
                return (
                  <span
                    key={item.color}
                    className={styles.colorItem}
                    style={computeMerchBackgroundColor(
                      item.colorCode,
                      item.colorCode2
                    )}
                  />
                );
              })}
            </div>
            <p>Request profit: {childMerch.requestedProfit} $</p>
          </div>
        </div>

        <div className={styles.merchParentRoot}>
          <div className={styles.title}>Into Parent</div>
          <div className={styles.merchImages}>
            {(parentMerchImages as string[])?.map((item, index) => (
              <div key={index} className={styles.imageWrapper}>
                <img className={styles.photo} src={item || ''} alt="" />
              </div>
            ))}
          </div>
          <div className={styles.merchInformation}>
            <p>Merch title: {parentMerch?.title || ''}</p>
            <p>Gender: {parentGenders}</p>
            <div className={styles.colors}>
              Colors:
              {parentMerchColors.map((item) => {
                return (
                  <span
                    key={item.color}
                    className={styles.colorItem}
                    style={computeMerchBackgroundColor(
                      item.colorCode,
                      item.colorCode2
                    )}
                  />
                );
              })}
            </div>
            <p>Request profit: {parentMerch.requestedProfit} $</p>
          </div>
        </div>
      </div>

      <Form
        form={form}
        layout="horizontal"
        name="setupStream"
        initialValues={initialFormValues}
        autoComplete="off"
        onValuesChange={setFields}
        onLoad={setFields}
        onFinish={handleFinish}
      >
        <Form.Item
          className={styles.customInput}
          name="title"
          label={<Text>Title</Text>}
          rules={[
            {
              required: true,
              message: 'Please enter the title',
            },
          ]}
        >
          <Input placeholder="Example of title" />
        </Form.Item>

        <Form.Item
          className={styles.customInput}
          name="finalProfit"
          label={<Text>Final profit</Text>}
          rules={[
            {
              required: true,
              type: 'number',
              message: 'Please enter the final profit value',
            },
          ]}
        >
          <InputNumber
            placeholder={`${parentMerch?.requestedProfit}$` ?? '0.00$'}
            className={styles.profit}
          />
        </Form.Item>

        <div className={styles.modalFooter}>
          <Button onClick={handleClose}>Cancel</Button>
          {!loading ? (
            <Spin size="large" />
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              className="createEditFormSubmit"
            >
              Yes, I want to merge
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default MergeMerchModal;
