import * as yup from 'yup';
// Types
import {
  GetMediaPosts_getMediaPosts_entities_ImagePost,
  GetMediaPosts_getMediaPosts_entities_VideoPost,
} from 'api/mediaPost/types/GetMediaPosts';
import { AdminCreatePostImagePresignedUrlV2_adminCreatePostImagePresignedUrlV2 } from 'api/mediaPost/types/AdminCreatePostImagePresignedUrlV2';

// function copied from fs-web/helpers/mediaPost
export const getDateDifference = (created: Date): string | undefined => {
  const today = new Date();

  // difference between dates divided by a day which is 24 hours, 60 min,  60 sek and 1000 milliseconds
  const day = Math.floor(
    (today.getTime() - created.getTime()) / (1000 * 60 * 60 * 24)
  );
  const month = Math.abs(today.getMonth() - created.getMonth());
  const year = Math.abs(today.getFullYear() - created.getFullYear());

  if (year && month > 12) {
    switch (year) {
      case 1:
        return 'Last Year';
      default:
        return year + 'Years Ago';
    }
  }

  if (month && day > 31) {
    switch (month) {
      case 1:
        return 'Last Month';
      default:
        return month + ' Months Ago';
    }
  }

  if (day <= 31) {
    switch (day) {
      case 0:
        return 'Today'; // if less than 24h passed
      case 1:
        return 'Yesterday'; // if less than 48h had passed
      default:
        return day + ' Days Ago';
    }
  }
};

export const isVideoPost = (
  post:
    | GetMediaPosts_getMediaPosts_entities_ImagePost
    | GetMediaPosts_getMediaPosts_entities_VideoPost
): post is GetMediaPosts_getMediaPosts_entities_VideoPost => {
  return (
    (post as GetMediaPosts_getMediaPosts_entities_VideoPost).video !== undefined
  );
};

/**
 * strategy for chunk size is following:
 * - 10MB for files under 100MB
 * - 100MB for files under 1GB
 * - 500MB for files larger than 1GB
 * @param fileSize
 * @returns chunkSize
 */
export const calculateChunkSize = (fileSize: number): number => {
  // <= 100MB ==> chunk 10MB
  if (fileSize <= 1024 * 1024 * 100) {
    return 1024 * 1024 * 10;
  }

  // <= 1GB ==> chunk 100MB
  if (fileSize <= 1024 * 1024 * 1024) {
    return 1024 * 1024 * 100;
  }

  // > 1GB ==> chunk 500MB
  return 1024 * 1024 * 500;
};

export const getValidationSchema = ({ isVideo }: { isVideo: boolean }): any => {
  const videoValidation = {
    title: yup
      .string()
      .max(200, 'Max 200 characters')
      .required('Field is required'),
    body: yup
      .string()
      .max(2200, 'Max 2200 characters')
      .required('Field is required'),
  };
  const imageValidation = {
    title: yup
      .string()
      .max(2200, 'Max 2200 characters')
      .required('Field is required'),
  };

  if (isVideo) {
    return yup.object().shape({
      ...videoValidation,
    });
  } else {
    return yup.object().shape({
      ...imageValidation,
    });
  }
};

export const VALID_VIDEO_EXTENSIONS = [
  '3gp',
  '3g2',
  'wmv',
  'avi',
  'flv',
  'f4v',
  'f4p',
  'f4a',
  'f4b',
  'ts',
  'imf',
  'mkv',
  'mpg',
  'mpeg',
  'm2v',
  'mp2',
  'mp4',
  'm4p',
  'm4v',
  'mpe',
  'mpv',
  'mxf',
  'mov',
  'qt',
  'webm',
];

export type ImageToUpload = {
  fields: string;
  url: string;
  name: string;
  type: string;
  file: File | '';
};

export const getImageToUpload = (
  preSignedUrl: AdminCreatePostImagePresignedUrlV2_adminCreatePostImagePresignedUrlV2,
  image: UploadImage
): ImageToUpload => {
  const { file } = image;

  const imageExtension = file
    ? file.name.split('.')[file.name.split('.').length - 1]
    : '';

  const imageName = `${preSignedUrl?.key || ''}.${imageExtension}`;

  const res: ImageToUpload = {
    fields: preSignedUrl?.fields || '',
    url: preSignedUrl?.url || '',
    name: imageName,
    type: file?.type || '',
    file: file || '',
  };

  return res;
};

export type UploadImage = {
  data_url?: string;
  file?: File;
};

export const uploadImageToS3 = async (
  preSignedUrl: AdminCreatePostImagePresignedUrlV2_adminCreatePostImagePresignedUrlV2,
  imageToUpload: UploadImage
): Promise<string> => {
  const {
    fields,
    url,
    name: imageName,
    type,
    file,
  } = getImageToUpload(preSignedUrl, imageToUpload);

  const formData = new FormData();

  Object.entries(JSON.parse(fields)).forEach(([key, value]) => {
    formData.append(key, value as string);
  });

  const parts = imageName.split('.');
  const newImageName = parts.slice(0, -1).join('.');

  formData.append('key', newImageName);
  formData.append('Content-Type', type);
  formData.append('file', file);

  await fetch(url, {
    method: 'POST',
    body: formData,
  });

  return newImageName;
};

export const MEDIA_TYPE = [
  {
    label: 'Image',
    value: 'image',
    disabled: false,
  },
  {
    label: 'Video',
    value: 'video',
    disabled: false,
  },
];
