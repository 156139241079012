import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Moment } from 'moment';
// Api
import { GET_PROMO_CODE_STATS } from 'api/promocodes/queries';
// Types
import {
  GetPromocodeStats,
  GetPromocodeStatsVariables,
  GetPromocodeStats_getPromocodeStats_entities,
} from 'api/promocodes/types/GetPromocodeStats';
import { SortedInfo, TableFilter } from 'ui/Table';
import { SortDirection, PromocodesOrderBy } from 'api/graphql-global-types';
// Helpers
import { formatCurrencyString } from 'helpers/utils';
// UI
import Table from 'ui/Table';

type DashboardPromoCodesProps = {
  startDate: Moment;
  endDate: Moment;
  storeId?: string | null | undefined;
};

const DashboardPromoCodes: React.FC<DashboardPromoCodesProps> = ({
  startDate,
  endDate,
  storeId,
}) => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<PromocodesOrderBy>>({
    order: SortDirection.DESC,
    key: PromocodesOrderBy.createdAt,
  });
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const getPromoCodeStatsInput: any = () => {
    const input: any = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      dateRange: { from: startDate, to: endDate },
      storeId,
    };

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;
    });

    return { input };
  };

  const { data, loading } = useQuery<
    GetPromocodeStats,
    GetPromocodeStatsVariables
  >(GET_PROMO_CODE_STATS, {
    variables: { ...getPromoCodeStatsInput() },
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: PromocodesOrderBy.name,
      fixed: 'left' as const,
      align: 'left' as const,
      width: 70,
      withSearch: true,
      sorterType: 'text',
    },
    {
      title: 'Times used',
      dataIndex: 'timesUsed',
      align: 'right' as const,
      width: 70,
    },
    {
      title: 'Revenue generated',
      dataIndex: 'totalRevenue',
      align: 'right' as const,
      width: 70,
      render(_: any, record: any) {
        return formatCurrencyString(record?.totalRevenue || 0);
      },
    },
    {
      title: 'Total discounted amount',
      dataIndex: 'totalDiscountAmount',
      align: 'right' as const,
      width: 100,
      render(_: any, record: any) {
        return formatCurrencyString(record?.totalDiscountAmount || 0);
      },
    },
  ];

  return (
    <Table<GetPromocodeStats_getPromocodeStats_entities, PromocodesOrderBy>
      columns={columns}
      data={data?.getPromocodeStats?.entities}
      scroll={{ x: 400 }}
      scrollToTopOnPagination={false}
      loading={loading}
      total={data?.getPromocodeStats?.total}
      defaultPageSize={pageSize}
      defaultCurrent={currentPage}
      setPageSize={setPageSize}
      setCurrentPage={setCurrentPage}
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      sortInfo={sort}
      setSortInfo={setSortInfo}
    />
  );
};

export default DashboardPromoCodes;
