import momentTimezone from 'moment-timezone';
// Types
import {
  PromoCodesChargeSubject,
  TimeZoneInput,
  ProductTypesType,
} from 'api/graphql-global-types';
import { TimeZoneOption } from 'constants/timeZone';
import { TreeDataEntity } from './tree-data.entity';
import { TreeDataConverter } from '../converter/tree-data.converter';
import { PromocodeFormValuesInterface } from './promocode-form-values.interface';
// Constants
import { TIME_ZONE_OPTIONS } from 'constants/timeZone';

export class PromocodeInputData {
  name: string;
  startDate: string;
  endDate: string | null;
  amount: number;
  usageLimit: number;
  perUserLimit: number;
  chargeSubject: PromoCodesChargeSubject;
  isSiteWide: boolean;
  timeZone: TimeZoneInput;
  merchProductIds: string[] | null;
  amaIds: string[] | null;
  streamIds: string[] | null;
  siteWideOptions?: ProductTypesType[] | null;

  public static fromObject({
    name,
    startDate,
    validityPeriod,
    amount,
    usageLimit,
    perUserLimit,
    chargeSubject,
    isSiteWide,
    storeProducts,
    timeZone,
    siteWideOptions,
  }: PromocodeFormValuesInterface): PromocodeInputData {
    const data = new PromocodeInputData();

    data.name = name.trim();
    data.amount = amount;
    data.usageLimit = usageLimit ? parseInt(usageLimit, 10) : -1;
    data.perUserLimit = perUserLimit ? parseInt(perUserLimit, 10) : -1;
    data.chargeSubject = chargeSubject;
    data.isSiteWide = isSiteWide;
    data.siteWideOptions = siteWideOptions;

    if (validityPeriod?.length) {
      const [periodStart, periodEnd] = validityPeriod;

      data.startDate = periodStart.toISOString();
      data.endDate = periodEnd.toISOString();
    } else {
      data.startDate = startDate.toISOString();
      data.endDate = null;
    }

    if (storeProducts) {
      const { amaIds, merchProductIds, streamIds } =
        this.getProductIdsSorted(storeProducts);

      data.amaIds = amaIds.length ? amaIds : null;
      data.merchProductIds = merchProductIds.length ? merchProductIds : null;
      data.streamIds = streamIds.length ? streamIds : null;
    } else {
      data.amaIds = null;
      data.merchProductIds = null;
      data.streamIds = null;
    }

    const timeZoneOption = this.findTimeZoneOption(timeZone);

    if (!timeZoneOption) {
      throw new Error(`TimeZoneOption not found. Unknown ${timeZone}.`);
    } else {
      const timeZoneOffset = momentTimezone(data.startDate)
        .tz(timeZoneOption?.tzName || TIME_ZONE_OPTIONS[0].tzName)
        .utcOffset();

      data.timeZone = {
        tzCode: timeZoneOption.tzCode,
        offset: timeZoneOffset,
      };
    }
    return data;
  }

  private static getProductIdsSorted(storeProducts: TreeDataEntity[]) {
    const products: {
      merchProductIds: string[];
      amaIds: string[];
      streamIds: string[];
    } = { merchProductIds: [], amaIds: [], streamIds: [] };

    storeProducts?.forEach((product) => {
      const { type, id } = TreeDataConverter.to(product);

      switch (type) {
        case 'MerchProduct': {
          products.merchProductIds.push(id);

          break;
        }

        case 'Ama': {
          products.amaIds.push(id);

          break;
        }

        case 'Stream': {
          products.streamIds.push(id);

          break;
        }
      }
    });

    return products;
  }

  private static findTimeZoneOption(
    tzCode: string
  ): TimeZoneOption | undefined {
    return TIME_ZONE_OPTIONS.find((option) => option.tzCode === tzCode);
  }
}
