import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { QuestionCircleTwoTone } from '@ant-design/icons';
// Helpers
import { createPresignedUrlsAndUploadToS3 } from 'helpers/single-uploader';
import { parseForInnerHTMLWithBreaks } from 'helpers/textEditor';
import { formatMentionsInput } from 'helpers/mentions';
// Constants
import { ARTICLE_TEMPLATES } from 'constants/articleTemplates';
// API
import { GET_ADMIN_STORES } from 'api/store/queries';
import { GET_HASHTAGS } from 'api/hashtags/queries';
import { GET_ARTICLES } from 'api/articles/queries';
import { CREATE_UNREGISTERED_CONTACT } from 'api/mentions/mutations';
import {
  CREATE_ARTICLE,
  CREATE_IMAGES_PRESIGNED_URL,
  UPDATE_ARTICLE,
} from 'api/articles/mutations';
// Types
import {
  AdminStoresOrderBy,
  ArticleTypes,
  CreateArticleInput,
  UpdateArticleInput,
  StoreStatus,
  UserRole,
  CreateMentionInput,
  CreateUnregisteredContactInput,
} from 'api/graphql-global-types';
import {
  GetAdminStores,
  GetAdminStoresVariables,
} from 'api/store/types/GetAdminStores';
import {
  GetHashtags,
  GetHashtagsVariables,
} from 'api/hashtags/types/GetHashtags';
import {
  CreateArticlePresignedUrl,
  CreateArticlePresignedUrlVariables,
} from 'api/articles/types/CreateArticlePresignedUrl';
import {
  CreateUnregisteredContact,
  CreateUnregisteredContactVariables,
} from 'api/mentions/types/CreateUnregisteredContact';
import {
  CreateArticle,
  CreateArticleVariables,
} from 'api/articles/types/CreateArticle';
import {
  UpdateArticle,
  UpdateArticleVariables,
} from 'api/articles/types/UpdateArticle';
import { GetArticles_getArticles_entities } from 'api/articles/types/GetArticles';
// UI
import { Modal, Form, Input, Button, Select, Tooltip, Typography } from 'antd';
import { errorNotification, successNotification } from 'ui/Notification';
import UploadImageFile from 'ui/UploadImageFile/UploadImageFile';
import Mentions, { MentionValues } from 'uiShared/Mentions/Mentions';
// Styles
import styles from './CreateEditSponsoredArticles.module.scss';

const { Option } = Select;
const { Text } = Typography;

type CreateSponsoredArticlesProps = {
  onClose: () => void;
  isVisible: boolean;
  pageSize: number;
  record?: GetArticles_getArticles_entities | null;
};

// TODO: saved for V2 where we should use initial form values instead of setting fields manually
// type FormValues = {
//   title: string;
//   athleteSelection: string[];
//   blogText: string;
//   tagging: string[];
//   imageLabel: string;
//   articleUrl: string;
// };

const CreateSponsoredArticles = ({
  onClose,
  isVisible,
  pageSize,
  record = null,
}: CreateSponsoredArticlesProps): JSX.Element => {
  const blogTextInputRef = useRef<HTMLInputElement>(null);
  const [form] = Form.useForm();
  const [mentionsValues, setMentionsValues] = useState<MentionValues[]>(
    record?.mentions || []
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [blogText, setBlogText] = useState<string>(record?.content || '');

  const [shouldUploadImage, setShouldUploadImage] = useState<boolean>(true);

  const trimmedBlogText = blogText.trim();
  const blogPreview = trimmedBlogText;

  // remove HTML tags
  const textWithoutTags = blogText.replace(/<[^>]*>/g, '');
  const words = textWithoutTags.trim().split(/\s+/);
  const wordCount: number = textWithoutTags.length ? words.length : 0;

  const isEdit = !!record?.id;

  const { data: hashtagsData } = useQuery<GetHashtags, GetHashtagsVariables>(
    GET_HASHTAGS,
    {
      variables: {
        keyword: '',
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const [createUnregisteredContact] = useMutation<
    CreateUnregisteredContact,
    CreateUnregisteredContactVariables
  >(CREATE_UNREGISTERED_CONTACT);

  const [createArticlePresignedUrls] = useMutation<
    CreateArticlePresignedUrl,
    CreateArticlePresignedUrlVariables
  >(CREATE_IMAGES_PRESIGNED_URL);

  const [createArticle] = useMutation<CreateArticle, CreateArticleVariables>(
    CREATE_ARTICLE,
    {
      refetchQueries: [
        {
          query: GET_ARTICLES,
          variables: {
            input: {
              limit: pageSize,
            },
          },
        },
      ],
    }
  );

  const [updateArticle] = useMutation<UpdateArticle, UpdateArticleVariables>(
    UPDATE_ARTICLE,
    {
      refetchQueries: [
        {
          query: GET_ARTICLES,
          variables: {
            input: {
              limit: pageSize,
            },
          },
        },
      ],
    }
  );

  const { data: storesData, refetch } = useQuery<
    GetAdminStores,
    GetAdminStoresVariables
  >(GET_ADMIN_STORES, {
    variables: {
      storeRoles: [
        UserRole.Athlete,
        UserRole.Organization,
        UserRole.ContentCreator,
      ],
      input: {
        orderBy: AdminStoresOrderBy.storeName,
        status: [StoreStatus.Active, StoreStatus.Inactive],
      },
    },
  });

  const handleOnSearchStores = useMemo(() => {
    const loadOptions = (storeName: string) => {
      refetch({
        storeRoles: [
          UserRole.Athlete,
          UserRole.Organization,
          UserRole.ContentCreator,
        ],
        input: { storeName, orderBy: AdminStoresOrderBy.storeName },
      });
    };

    return debounce(loadOptions, 400);
  }, [refetch]);

  const hashtags = hashtagsData?.getHashtags || [];

  const handleResetAndClose = () => {
    form.resetFields();
    setIsLoading(false);
    setBlogText('');
    onClose();
  };

  const handleBlogTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    setBlogText(e.target.value);
  };

  useEffect(() => {
    form.setFieldsValue({
      blogText: blogText,
    });
  }, [blogText, form]);

  useEffect(() => {
    if (isEdit && record?.content) {
      setBlogText(record?.content);
      form.setFieldsValue({
        blogText: record.content,
      });
      setShouldUploadImage(false);
    }
  }, [form, isEdit, record]);

  useEffect(() => {
    // manually setting form values for edit and create because the approach with initialFormValues created a bug
    // where we wouldn't have any values in any of the fields-TODO: should be solved in V2
    form.setFields([
      {
        name: 'title',
        errors: [],
        value: record?.title || '',
      },
      {
        name: 'athleteSelection',
        errors: [],
        value: record?.store ? [record.store.id] : [],
      },
      {
        name: 'blogText',
        errors: [],
        value: record?.content || '',
      },
      {
        name: 'tagging',
        errors: [],
        value: record?.hashtags ? record.hashtags.map((tag) => tag.name) : [],
      },
      {
        name: 'imageLabel',
        errors: [],
        value: record?.imageLabel || '',
      },
      {
        name: 'articleUrl',
        errors: [],
        value: record?.articleUrl || '',
      },
      {
        name: 'mentions',
        errors: [],
        value: record?.mentions || [],
      },
    ]);

    if (isEdit && record?.store.id) {
      // refetch to display the store with id from record
      refetch({
        storeRoles: [
          UserRole.Athlete,
          UserRole.Organization,
          UserRole.ContentCreator,
        ],
        input: { storeIds: [record.store.id] },
      });
    } else {
      // refetch fresh data for "create" modal after using edit
      refetch({
        storeRoles: [
          UserRole.Athlete,
          UserRole.Organization,
          UserRole.ContentCreator,
        ],
        input: { orderBy: AdminStoresOrderBy.storeName },
      });
    }
    setMentionsValues(record?.mentions || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  const normFile = (e: any) => {
    if (e && Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleOnSubmit = async () => {
    setIsLoading(true);
    const {
      title,
      athleteSelection,
      blogText,
      tagging,
      imageLabel,
      articleUrl,
    } = form.getFieldsValue();

    const createUnregisteredContactInput =
      (mentionsValues as MentionValues[])?.filter(
        (mention) => !mention?.targetType
      ) || [];

    const unregisterContactsData = await createUnregisteredContact({
      variables: {
        input:
          createUnregisteredContactInput as CreateUnregisteredContactInput[],
      },
    });

    const newUnregisteredContacts =
      (unregisterContactsData?.data?.createUnregisteredContact || [])?.map(
        (contact) => ({
          unregisteredContactId: contact?.id,
        })
      ) || [];

    const oldMentions = (mentionsValues?.filter(
      (mention) => mention?.targetType
    ) || []) as MentionValues[];
    const formatedOldMentions = formatMentionsInput(oldMentions);

    const hashtagInputs = tagging.map((tag: string) => ({ name: tag }));
    const mentionsInputs = [
      ...formatedOldMentions,
      ...newUnregisteredContacts,
    ] as CreateMentionInput[];

    // had to create both common inputs here due to a difference in create and update input:
    // create has image with "key" and "imageLabel", like {.... image:{key, imageLabel}}
    // update API doesn't have this object but has "key" and "imageLabel" as input properties
    const commonCreateInput: CreateArticleInput = {
      content: blogText,
      hashtagInputs,
      mentionsInputs,
      storeIds: athleteSelection,
      title,
      image: { imageLabel, key: '' },
      type: ArticleTypes.Sponsored,
    };

    const commonUpdateInput: UpdateArticleInput = {
      id: record?.id || '',
      content: blogText,
      hashtagInputs,
      mentionsInputs,
      storeIds: athleteSelection,
      title,
      imageLabel,
    };

    if (articleUrl !== '') {
      commonCreateInput.articleUrl = articleUrl;
      commonUpdateInput.articleUrl = articleUrl;
    }

    try {
      if (shouldUploadImage) {
        const imageForUpload = form.getFieldValue('image')[0].originFileObj;

        const presignedImage = await createPresignedUrlsAndUploadToS3({
          files: [imageForUpload],
          getPresignedUrls: async () => {
            const { data: presignedData } = await createArticlePresignedUrls({
              variables: {
                input: {
                  ext: imageForUpload.name.split('.').pop() || '',
                  contentType: imageForUpload.type || '',
                },
              },
            });
            if (!presignedData) {
              console.error('Error uploading image file');
              setIsLoading(false);
              throw new Error(
                'Something went wrong with the upload of the image file'
              );
            }

            return [presignedData.createArticlePresignedUrl];
          },
        });
        if (presignedImage) {
          if (isEdit) {
            commonUpdateInput.key = presignedImage[0].key;
          } else {
            commonCreateInput.image = {
              key: presignedImage[0].key,
              imageLabel,
            };
          }
        }
      }
      if (isEdit) {
        await updateArticle({
          variables: {
            input: {
              ...commonUpdateInput,
            },
          },
        });
        successNotification(`Sponsored Article updated successfully`);
      } else {
        await createArticle({
          variables: {
            input: {
              ...commonCreateInput,
            },
          },
        });
        successNotification(`Sponsored Article created successfully`);
      }

      setIsLoading(false);
      handleResetAndClose();
    } catch (err) {
      setIsLoading(false);
      errorNotification((err as Error)?.message || 'Something went wrong');
    }
  };

  const handleMentionChange = useCallback(
    (mentions) => {
      if (JSON.stringify(mentions) !== JSON.stringify(mentionsValues)) {
        form.setFieldsValue({ mentions: mentions });
        setMentionsValues(mentions);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mentionsValues]
  );

  return (
    <Modal
      title={isEdit ? 'Edit Article' : 'New Article'}
      destroyOnClose
      visible={isVisible}
      onCancel={handleResetAndClose}
      width="80vw"
      footer={[
        <Button
          key="create"
          type="primary"
          loading={isLoading}
          onClick={handleOnSubmit}
          disabled={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="CreateSponsoredArticles">
        <p className={styles.subtitle}>Basic Information</p>

        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: 'Please set the title',
            },
          ]}
        >
          <Input placeholder="Enter article title here" />
        </Form.Item>

        <Form.Item
          name="athleteSelection"
          label="Profile Selection"
          rules={[
            {
              required: true,
              message: 'Search and add profiles',
            },
          ]}
        >
          <Select
            id="athleteSelection"
            placeholder="Profile Selection"
            filterOption={(inputValue, option) =>
              option?.props.children && // adding a check while waiting for response
              option?.props.children
                .toString()
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
            onSearch={handleOnSearchStores}
            showSearch
          >
            {storesData?.adminStores.entities.map((item) => {
              return (
                <Option value={`${item.id}`} key={item.id}>
                  {item.storeDetails?.storeName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <p className={styles.subtitle}>Content</p>

        <div className={styles.blogText}>
          <Form.Item
            className={styles.inlineInput}
            name="blogText"
            label="Raw Blog Text"
            rules={[
              { required: true, message: "Please the article's content" },
            ]}
          >
            <Input.TextArea
              placeholder="Type something"
              rows={8}
              onChange={handleBlogTextChange}
              value={blogText}
              ref={blogTextInputRef}
            />
          </Form.Item>
          <div className={styles.templates}>
            {ARTICLE_TEMPLATES.map(({ label, value }) => (
              <Button
                className={styles.templateButton}
                key={value}
                onClick={() => {
                  const newText = blogText + value;
                  setBlogText(newText);
                  if (blogTextInputRef.current) {
                    blogTextInputRef.current.focus();
                  }
                }}
              >
                {label}
              </Button>
            ))}
          </div>
        </div>
        {blogText && (
          <div className={styles.previewSection}>
            <div>Live Blog Preview</div>
            <div
              className={styles.previewBlog}
              dangerouslySetInnerHTML={{
                __html: parseForInnerHTMLWithBreaks(blogPreview),
              }}
            ></div>
          </div>
        )}
        <div className={styles.wordCounter}>Word Count: {wordCount}</div>

        <p className={styles.subtitle}>Tags and SEO</p>

        <Form.Item
          name="tagging"
          label="Tagging"
          rules={[
            {
              required: true,
              message: 'Please select tags',
            },
          ]}
        >
          <Select
            id="tags"
            placeholder="Search and add SEO tags"
            mode="multiple"
            filterOption={(inputValue, option) =>
              option?.props.children
                .toString()
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
          >
            {hashtags.map((item) => {
              return (
                <Option value={`${item.name}`} key={item.id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <p className={styles.subtitle}>Cover image</p>

        <Form.Item
          name="image"
          label="Image"
          valuePropName="image"
          getValueFromEvent={normFile}
        >
          <UploadImageFile
            name="image"
            buttonTitle="Upload image"
            imageAlt="image"
            defaultFileUrl={record?.url || ''}
            onChange={() => setShouldUploadImage(true)}
          />
        </Form.Item>

        <Form.Item
          name="imageLabel"
          label="Image Label"
          rules={[
            {
              required: true,
              message: "Please set the image's label",
            },
          ]}
        >
          <Input placeholder="Enter the image label" />
        </Form.Item>

        <Form.Item name="articleUrl" label="Image URL">
          <Input placeholder="Enter the url of the image" />
        </Form.Item>

        <Form.Item name="mentions" className={styles.mentionsRoot}>
          <Text className={styles.mentionSectionTitleWrapper}>
            <p className={styles.mentionsSectionTitle}>Mentioning options</p>
            <Tooltip
              title="Mention athletes, brands, and organizations by using their names and
          links to directly engage them and increase interaction on your
          content. Write down their names and url links."
            >
              <QuestionCircleTwoTone />
            </Tooltip>
          </Text>
        </Form.Item>

        <div className={styles.mentionsContainer}>
          <Mentions mentions={mentionsValues} onChange={handleMentionChange} />
        </div>
      </Form>
    </Modal>
  );
};

export default CreateSponsoredArticles;
