import React from 'react';
import { Reference, useMutation } from '@apollo/client';
import { LockOutlined } from '@ant-design/icons';
import { Modal, Form, Typography, Input } from 'antd';
// Api
import { CREATE_ADMIN, EDIT_ADMIN } from 'api/admin/mutations';
import { AdminFields } from 'api/admin/fragments';
// Types
import { EditAdmin, EditAdminVariables } from 'api/admin/types/EditAdmin';
import { CreateAdmin, CreateAdminVariables } from 'api/admin/types/CreateAdmin';
import { GetAdmins_admins_entities } from 'api/admin/types/GetAdmins';
// UI
import { errorNotification, successNotification } from 'ui/Notification';

const { Text } = Typography;

type CreateEditAdminModalProps = {
  onClose: () => void;
  isVisible: boolean;
  admin?: GetAdmins_admins_entities;
};

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
  newPassword: string | null;
};

const CreateEditAdminModal = ({
  onClose,
  isVisible,
  admin,
}: CreateEditAdminModalProps): JSX.Element => {
  const [form] = Form.useForm();

  const initialFormValues: FormValues = {
    firstName: admin?.firstName || '',
    lastName: admin?.lastName || '',
    email: admin?.email || '',
    phoneNumber: admin?.phoneNumber || '',
    newPassword: null,
  };

  const [createAdmin, { loading: createAdminLoading }] = useMutation<
    CreateAdmin,
    CreateAdminVariables
  >(CREATE_ADMIN, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          admins(existingAdmins = []) {
            const newAdminRef = cache.writeFragment({
              data: data?.createAdmin,
              fragment: AdminFields,
            });

            return [...existingAdmins.entities, newAdminRef];
          },
        },
        broadcast: false,
      });
    },
  });

  const [editAdmin, { loading: editAdminLoading }] = useMutation<
    EditAdmin,
    EditAdminVariables
  >(EDIT_ADMIN, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          admins(existingAdmins = [], { readField }) {
            const newAdminRef = cache.writeFragment({
              data: data?.editAdmin,
              fragment: AdminFields,
            });

            if (
              existingAdmins.entities.some(
                (ref: Reference) => readField('id', ref) === data?.editAdmin.id
              )
            ) {
              return existingAdmins;
            }

            return [...existingAdmins.entities, newAdminRef];
          },
        },
        broadcast: false,
      });
    },
  });

  const handleCreateAdmin = async () => {
    const { firstName, lastName, email, phoneNumber } = form.getFieldsValue();

    try {
      await createAdmin({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            phoneNumber,
          },
        },
      });

      successNotification(`Admin created successfully`);

      onClose();
    } catch (err) {
      errorNotification((err as Error)?.message || 'Something went wrong');
    }
  };

  const handleEditAdmin = async () => {
    const { firstName, lastName, email, phoneNumber, newPassword } =
      form.getFieldsValue();

    try {
      await editAdmin({
        variables: {
          input: {
            adminId: admin?.id || '',
            firstName,
            lastName,
            email,
            phoneNumber,
            newPassword,
          },
        },
      });

      successNotification(
        `Admin ${admin?.firstName || ''} ${
          admin?.lastName || ''
        } updated successfully`
      );

      onClose();
    } catch (err) {
      errorNotification((err as Error)?.message || 'Something went wrong');
    }
  };

  const onFormSubmit = async () => {
    try {
      await form.validateFields();
      return admin ? handleEditAdmin() : handleCreateAdmin();
    } catch (err) {
      errorNotification((err as Error)?.message || 'Something went wrong');
    }
  };

  return (
    <Modal
      title={`${admin ? 'Edit' : 'Create new'} Admin`}
      destroyOnClose
      visible={isVisible}
      okText={admin ? 'Save' : 'Create'}
      cancelText="Cancel"
      onOk={onFormSubmit}
      onCancel={onClose}
      okButtonProps={{
        loading: createAdminLoading || editAdminLoading,
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="CreateEditAdmin"
        initialValues={initialFormValues}
      >
        <Form.Item
          name="firstName"
          label={<Text>First name</Text>}
          rules={[
            {
              required: true,
              message: 'Please enter first name',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastName"
          label={<Text>Last name</Text>}
          rules={[
            {
              required: true,
              message: 'Please enter last name',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label={<Text>Email</Text>}
          rules={[
            {
              required: true,
              message: 'Please input your email!',
            },
            {
              type: 'email',
              message: 'Please input a valid email address',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="phoneNumber" label="Phone number">
          <Input placeholder="Enter phone number" />
        </Form.Item>

        {admin && (
          <Form.Item name="newPassword">
            <Input
              prefix={<LockOutlined />}
              type="password"
              placeholder="New password"
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default CreateEditAdminModal;
