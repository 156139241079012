import React, { FC, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Spin, Pagination, Button, Modal } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { uniqBy } from 'lodash';
// Api
import {
  GET_ADMIN_MERCH_PRODUCTS,
  GET_MERCH_PRODUCT_V2,
} from 'api/merch/queries';
import { MerchProductStatus, MerchType } from 'api/graphql-global-types';
// Types
import {
  GetMerchProductV2,
  GetMerchProductV2Variables,
  GetMerchProductV2_getMerchProduct,
} from 'api/merch/types/GetMerchProductV2';
import {
  GetAdminMerchProducts,
  GetAdminMerchProductsVariables,
  GetAdminMerchProducts_getAdminMerchProducts_entities,
} from 'api/merch/types/GetAdminMerchProducts';
import { GetUnmergedMerch_getUnmergedMerch_entities } from 'api/merch/types/GetUnmergedMerch';
// Components
import { DEFAULT_CURRENT_PAGE, DEFAULT_NUMBER_ITEMS_PER_PAGE } from 'ui/Table';
import MergeMerchModal from '../MergeMerchModal/MergeMerchModal';
import MergeMerchChild from '../MergeMerchChild/MergeMerchChild';
import MergeMerchParent from '../MergeMerchParent/MergeMerchParent';
// Helpers
import { getProductColors } from 'components/common/ManageMerch/helpers';
// Styles
import styles from './MergeInto.module.scss';

interface MergeIntoProps {
  childMerch: GetUnmergedMerch_getUnmergedMerch_entities;
}

const MergeInto: FC<MergeIntoProps> = ({ childMerch }) => {
  const history = useHistory();
  const [parentMerch, setParentMerch] =
    useState<GetAdminMerchProducts_getAdminMerchProducts_entities>();
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [pageSize, setPageSize] = useState<number>(
    DEFAULT_NUMBER_ITEMS_PER_PAGE
  );
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const merchType = childMerch?.type as MerchType;

  const { data, loading, refetch } = useQuery<
    GetAdminMerchProducts,
    GetAdminMerchProductsVariables
  >(GET_ADMIN_MERCH_PRODUCTS, {
    variables: {
      input: {
        limit: pageSize,
        offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
        type: [merchType],
        merchStatus: MerchProductStatus.Active,
        storeId: childMerch.storeId,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: myProductData, loading: childLoading } = useQuery<
    GetMerchProductV2,
    GetMerchProductV2Variables
  >(GET_MERCH_PRODUCT_V2, {
    variables: {
      input: {
        slug: childMerch.slug,
      },
    },
  });

  const childMerchItem = myProductData?.getMerchProduct;
  const childMerchColors =
    (childMerchItem &&
      getProductColors(childMerchItem as GetMerchProductV2_getMerchProduct)) ||
    [];
  const childMerchImages = uniqBy(
    childMerchItem?.merchProductVariants,
    'mockupImageURL'
  ).map((variant) => variant.mockupImageURL);

  const handleChangePage = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize || 0);

    refetch({
      input: {
        limit: pageSize,
        offset: (page > 1 ? page - 1 : 0) * pageSize,
      },
    });

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleEditMerch = (
    record: GetUnmergedMerch_getUnmergedMerch_entities
  ) => {
    const url = `/manage-athlete-merch/${record.type}/${record.storeId}/${record.id}`;
    history.push(url);
  };

  const filteredData =
    data?.getAdminMerchProducts?.entities.filter(
      (item) => item.id !== childMerch.id
    ) || [];

  const onMergeHandle = (
    item: GetAdminMerchProducts_getAdminMerchProducts_entities
  ) => {
    setParentMerch(item);
    setModalVisible(true);
  };

  const handleHideModal = () => {
    setModalVisible(false);
  };

  return (
    <div className={styles.mergeIntoRoot}>
      {childMerchItem && (
        <MergeMerchChild
          childMerch={childMerch}
          childMerchColors={childMerchColors}
          childMerchImages={childMerchImages}
          loading={childLoading}
        />
      )}
      <div className={styles.mergeIntoInfo}>
        <div>
          MERGE INTO <ArrowRightOutlined />
        </div>
        <div>OR</div>
        <Button
          type="primary"
          onClick={() => {
            handleEditMerch(childMerch);
          }}
        >
          Edit this Merch
        </Button>
      </div>
      <div className={styles.mergeIntoList}>
        {loading ? (
          <Spin size="large" />
        ) : (
          <div>
            {data &&
              filteredData.map((item) => {
                return (
                  <div key={item.id}>
                    <MergeMerchParent
                      parentMerch={item}
                      handleMerge={() => onMergeHandle(item)}
                    />
                  </div>
                );
              })}
            {data &&
              data?.getAdminMerchProducts.total >
                DEFAULT_NUMBER_ITEMS_PER_PAGE && (
                <Pagination
                  onChange={handleChangePage}
                  defaultCurrent={DEFAULT_CURRENT_PAGE}
                  total={data?.getAdminMerchProducts.total}
                  current={currentPage}
                  pageSize={pageSize}
                />
              )}
          </div>
        )}
      </div>

      <Modal
        title="Merge Merch"
        visible={isModalVisible}
        footer={null}
        onCancel={handleHideModal}
        width="90vw"
      >
        {childMerch && parentMerch && (
          <MergeMerchModal
            parentMerch={parentMerch}
            childMerch={childMerch}
            childMerchImages={childMerchImages}
            childMerchColors={childMerchColors}
            onClose={handleHideModal}
          />
        )}
      </Modal>
    </div>
  );
};

export default MergeInto;
