import { gql } from '@apollo/client';

export const GET_ARTICLES = gql`
  query GetArticles($input: ArticleFilterInput!) {
    getArticles(input: $input) {
      limit
      offset
      total
      entities {
        mentions {
          id
        }
        articleUrl
        content
        createdAt
        hashtags {
          id
          name
        }
        mentions {
          id
          mentionType
          store {
            slug
            id
            storeDetails {
              storeName
              storeId
              avatarURL
            }
            firstName
            lastName
          }
          targetType
          unregisteredContact {
            email
            id
            name
            representativeName
            type
            url
          }
        }
        id
        imageLabel
        key
        slug
        status
        store {
          id
          slug
          storeDetails {
            storeName
          }
        }
        title
        url
      }
    }
  }
`;
