import React from 'react';
import cn from 'classnames';

import styles from './RadioGroup.module.scss';

export type RadioGroupOption = {
  value: string;
  label: string;
  disabled?: boolean;
};

type Props = {
  options: RadioGroupOption[];
  checkedValue: string;
  radioGroupName?: string;
  onChange: (value: string) => void;
  className?: string;
  small?: boolean;
  label?: string;
  helperText?: string;
};
// NOTE: copy from ui from fs-web
const RadioGroup: React.FC<Props> = ({
  options,
  checkedValue,
  radioGroupName = 'radioGroup',
  onChange,
  className,
  small,
  label,
  helperText,
}) => {
  return (
    <>
      {!!label && <label className={styles.label}>{label}</label>}
      <ul
        className={cn(
          styles.radioGroup,
          { [styles.small]: small },
          { [styles.long]: options?.length > 9 },
          className
        )}
        role="radiogroup"
        aria-label={radioGroupName}
      >
        {options.map(({ value, label, disabled }: RadioGroupOption) => {
          const handleRadioChange = () => (!disabled ? onChange(value) : null);
          return (
            <li key={value} className={styles.radioGroupItemWrapper}>
              <div
                className={cn(styles.radioGroupItem, {
                  [styles.small]: small,
                  [styles.disabled]: disabled,
                })}
                onKeyPress={handleRadioChange}
                onClick={handleRadioChange}
                role="radio"
                aria-checked={checkedValue === value}
                aria-disabled={disabled}
                tabIndex={0}
              >
                {label}
              </div>
            </li>
          );
        })}
      </ul>
      {helperText && <p className={styles.helperText}>{helperText}</p>}
    </>
  );
};

export default RadioGroup;
