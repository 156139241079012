import React from 'react';
import { Space, Image, Typography } from 'antd';
// Types
import { GetAdminWatchStreams_adminStreams_entities } from 'api/streams/types/GetAdminWatchStreams';
// Constants
import { FALLBACK_IMAGE } from 'constants/global';
// Helpers
import { getPublicStreamDate } from 'helpers/utils';
import { parseForInnerHTML } from 'helpers/textEditor';

// Styles
import styles from './StreamInfo.module.scss';

type StreamInfoProps = {
  stream: GetAdminWatchStreams_adminStreams_entities | null | undefined;
};

const { Text } = Typography;

const StreamInfo: React.FC<StreamInfoProps> = ({ stream }) => {
  const renderSection = (title: string, description: string) => (
    <Space size="small" direction="horizontal">
      <Text className={styles.streamTitle} strong>
        <strong>{title}</strong>
      </Text>
      <Text className={styles.streamDescription}>
        <div
          dangerouslySetInnerHTML={{ __html: parseForInnerHTML(description) }}
        />
      </Text>
    </Space>
  );

  return (
    <Space size="small" direction="horizontal">
      <Image
        width={180}
        src={stream?.imageURL || ''}
        fallback={FALLBACK_IMAGE}
      />

      <Space size="small" direction="vertical">
        {renderSection(
          'Streamed by:',
          `${stream?.store?.firstName || ''} ${stream?.store?.lastName || ''}`
        )}
        {renderSection('Name:', stream?.name || '')}
        {renderSection(
          'Date:',
          getPublicStreamDate(stream?.scheduleDate, stream?.timeZone)
        )}
        {renderSection('Description:', stream?.description || '')}
      </Space>
    </Space>
  );
};

export default StreamInfo;
