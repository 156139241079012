import React, { useState } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
// API
import { GET_STREAM_ORDER_SALES_BY_STORE } from 'api/streams/queries';
//Types
import {
  GetStreamOrderSales,
  GetStreamOrderSalesVariables,
} from 'api/streams/types/GetStreamOrderSales';
import {
  SortDirection,
  MyTipsAndOrdersSalesOrderBy,
} from 'api/graphql-global-types';
// Ui
import Table, {
  DEFAULT_NUMBER_ITEMS_PER_PAGE,
  DEFAULT_CURRENT_PAGE,
  SortedInfo,
  TableFilter,
} from 'ui/Table';
// Helpers
import { formatCurrencyString } from 'helpers/utils';

type StreamSalesDetailsProps = {
  streamId?: string;
  storeId?: string;
};

type StreamSalesDetailsItem = {
  id: string;
  type: string;
  firstName: string;
  lastName: string;
  email: string;
  paidAt: string;
  discountAmount: string;
  requestedProfit: string;
};

const StreamSalesDetails: React.FC<StreamSalesDetailsProps> = ({
  streamId = '',
  storeId,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [pageSize, setPageSize] = useState<number>(
    DEFAULT_NUMBER_ITEMS_PER_PAGE
  );

  const defaultSortInfo: SortedInfo<MyTipsAndOrdersSalesOrderBy> = {
    order: SortDirection.DESC,
    key: MyTipsAndOrdersSalesOrderBy.Type,
  };

  const [sort, setSortInfo] =
    useState<SortedInfo<MyTipsAndOrdersSalesOrderBy>>(defaultSortInfo);
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const getQueryVariables: any = () => {
    const input: any = {
      storeId,
      orderBy: sort.key,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      limit: pageSize,
      direction: sort.order,
    };

    searchFilters?.forEach(({ key, value }) => {
      if (key === 'PaidAt') {
        return (input['paidAtDateRange'] = {
          from: Array.isArray(value) ? value?.[0] : value,
          to: Array.isArray(value) ? value?.[1] : value,
        });
      }

      if (key === 'CustomerFirstName') {
        return (input['customerName'] = value);
      }

      if (key === 'CustomerEmail') {
        return (input['customerEmail'] = value);
      }
      input[key] = value;
    });

    return { input };
  };

  const { data, loading } = useQuery<
    GetStreamOrderSales,
    GetStreamOrderSalesVariables
  >(GET_STREAM_ORDER_SALES_BY_STORE, {
    variables: {
      ...getQueryVariables(),
      streamId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const tableData = data?.getStreamOrderSales.entities.map((streamItem) => {
    const { id, type, firstName, lastName, paidat, discount, earnings, email } =
      streamItem;

    return {
      id,
      type,
      firstName,
      lastName,
      email,
      paidAt: moment(paidat).format('ll') + ' (UTC)',
      discountAmount: formatCurrencyString(discount || 0),
      requestedProfit: formatCurrencyString(earnings),
    };
  });

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      fixed: 'left' as const,
      key: MyTipsAndOrdersSalesOrderBy.Type,
      width: 40,
      sorterType: 'text',
    },
    {
      title: 'Customer first name',
      dataIndex: 'firstName',
      fixed: 'left' as const,
      key: MyTipsAndOrdersSalesOrderBy.CustomerFirstName,
      width: 60,
      sorterType: 'text',
      withSearch: true,
    },
    {
      title: 'Customer last name',
      dataIndex: 'lastName',
      fixed: 'left' as const,
      width: 60,
      withSearch: true,
      key: 'customerName',
    },
    {
      title: 'Customer Email',
      dataIndex: 'email',
      key: MyTipsAndOrdersSalesOrderBy.CustomerEmail,
      width: 100,
      sorterType: 'text',
      withSearch: true,
    },
    {
      title: 'Purchase date',
      dataIndex: 'paidAt',
      key: MyTipsAndOrdersSalesOrderBy.PaidAt,
      width: 60,
      sorterType: 'date',
      withDateRangeFilter: true,
    },
    {
      title: 'Discount',
      dataIndex: 'discountAmount',
      align: 'right' as const,
      key: MyTipsAndOrdersSalesOrderBy.Discount,
      width: 40,
      sorterType: 'number',
    },
    {
      title: 'Earnings',
      dataIndex: 'requestedProfit',
      align: 'right' as const,
      key: MyTipsAndOrdersSalesOrderBy.Earnings,
      width: 40,
      sorterType: 'number',
    },
  ];

  return (
    <Table<StreamSalesDetailsItem, MyTipsAndOrdersSalesOrderBy>
      columns={columns}
      data={tableData}
      loading={loading}
      total={data?.getStreamOrderSales?.total}
      rowKey={(item) => item.id}
      defaultPageSize={pageSize}
      defaultCurrent={currentPage}
      setPageSize={setPageSize}
      setCurrentPage={setCurrentPage}
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      sortInfo={sort}
      setSortInfo={setSortInfo}
    />
  );
};

export default StreamSalesDetails;
