import React from 'react';
import cn from 'classnames';
// Api
import { GetPRProductByTypeV2_getPRProductByTypeV2_data_product_product_files } from 'api/merch/types/GetPRProductByTypeV2';
import { PlacementTypes } from 'api/graphql-global-types';
// Helpers
import { formatPrice } from 'components/common/ManageMerch/helpers';
// Styles
import styles from './PrintPlacements.module.scss';

type PrintPlacementsProps = {
  onChangePrintSide: (side: PlacementTypes) => void;
  onRemovePrintSide: (side: PlacementTypes) => void;
  currentPrintPlacement: PlacementTypes;
  selectedPrintPlacements: PlacementTypes[];
  availablePrintPlacements: (GetPRProductByTypeV2_getPRProductByTypeV2_data_product_product_files | null)[];
  platformMargin: number;
  isJoggersConstructor?: boolean;
};

const PrintPlacements: React.FC<PrintPlacementsProps> = ({
  onChangePrintSide,
  onRemovePrintSide,
  currentPrintPlacement,
  selectedPrintPlacements,
  availablePrintPlacements,
  platformMargin,
  isJoggersConstructor,
}) => {
  return (
    <div className={styles.root}>
      {isJoggersConstructor && (
        <span className={styles.placementItemsLimit}>(3 max)</span>
      )}
      {availablePrintPlacements.map((item) => {
        const isActivePrint = !!selectedPrintPlacements?.find(
          (print) => print === item?.type
        );
        const placement = (item?.type as PlacementTypes) || null;

        if (placement) {
          return (
            <div key={item?.id} className={styles.optionWrapper}>
              <button
                className={cn(styles.optionItem, {
                  [styles.active]: placement === currentPrintPlacement,
                  [styles.hasPrintImage]: isActivePrint,
                })}
                onClick={() => onChangePrintSide(placement)}
              >
                <span className={styles.radioIcon} />
                <span className={styles.optionLabel}>{item?.title}</span>
                {item?.additional_price && (
                  <span className={styles.price}>
                    +
                    {formatPrice(
                      Number(item.additional_price) * (1 + platformMargin / 100)
                    )}
                  </span>
                )}
              </button>
              {isActivePrint && (
                <button
                  className={styles.removeButton}
                  onClick={() => onRemovePrintSide(placement)}
                >
                  remove
                </button>
              )}
            </div>
          );
        }

        return null;
      })}
    </div>
  );
};

export default PrintPlacements;
