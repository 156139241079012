import React, { useState, ChangeEvent } from 'react';
import { useMutation } from '@apollo/client';
import { Space, Button, Select, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
// Api
import {
  ACCEPT_AMA_REQUEST,
  CREATE_AMA_REQUEST_VIDEO_PRESIGNED_URL,
  DECLINE_AMA_REQUEST,
  SUBMIT_AMA_REQUEST_VIDEO,
} from 'api/ama/mutations';
// Types
import {
  SubmitAmaRequestVideo,
  SubmitAmaRequestVideoVariables,
} from 'api/ama/types/SubmitAmaRequestVideo';
import {
  CreateAmaRequestVideoPresignedUrl,
  CreateAmaRequestVideoPresignedUrlVariables,
} from 'api/ama/types/CreateAmaRequestVideoPresignedUrl';
import {
  AcceptAmaRequest,
  AcceptAmaRequestVariables,
} from 'api/ama/types/AcceptAmaRequest';
import {
  DeclineAmaRequest,
  DeclineAmaRequestVariables,
} from 'api/ama/types/DeclineAmaRequest';
import { AmaOrderStatus } from 'api/graphql-global-types';
// Helpers
import {
  DECLINE_REASON,
  STATUS,
  VALID_VIDEO_EXTENSIONS,
  isValidVideoExtension,
} from 'helpers/ama';
// Components
import VideoPreview from 'ui/VideoPreview/VideoPreview';
// UI
import { errorNotification, successNotification } from 'ui/Notification';

const { Option } = Select;

type ActionsProps = {
  amaId: string;
  orderStatus: AmaOrderStatus;
};

const Actions = ({ amaId, orderStatus }: ActionsProps): JSX.Element | null => {
  const [acceptAmaRequest, { loading: acceptIsLoading }] = useMutation<
    AcceptAmaRequest,
    AcceptAmaRequestVariables
  >(ACCEPT_AMA_REQUEST);

  const [submitAmaRequestVideo, { loading: uploadIsLoading }] = useMutation<
    SubmitAmaRequestVideo,
    SubmitAmaRequestVideoVariables
  >(SUBMIT_AMA_REQUEST_VIDEO);

  const [createAmaRequestVideoPresignedUrl] = useMutation<
    CreateAmaRequestVideoPresignedUrl,
    CreateAmaRequestVideoPresignedUrlVariables
  >(CREATE_AMA_REQUEST_VIDEO_PRESIGNED_URL);

  const [declineAmaRequest, { loading: declineIsLoading }] = useMutation<
    DeclineAmaRequest,
    DeclineAmaRequestVariables
  >(DECLINE_AMA_REQUEST);

  const [declineReason, setDeclineReason] = useState<string>(
    DECLINE_REASON.UNABLE_ANSWER
  );
  const [declineModalIsVisible, setDeclineModalIsVisible] =
    useState<boolean>(false);
  const [uploadModalIsVisible, setUploadModalIsVisible] =
    useState<boolean>(false);
  const [amaVideo, setAmaVideo] = useState<Blob | File | null>(null);
  const [videoPreview, setVideoPreview] = useState<string | null>(null);

  const handleAcceptAmaRequest = async (): Promise<void> => {
    try {
      await acceptAmaRequest({ variables: { id: amaId } });
      successNotification('The AMA request is accepted');
    } catch (err) {
      errorNotification();
      console.log('acceptAmaRequest error:', { ...(err as Error) });
    }
  };

  const showDeclineAmaModal = () => setDeclineModalIsVisible(true);

  const hideDeclineAmaModal = () => {
    setDeclineModalIsVisible(false);
    setDeclineReason(DECLINE_REASON.UNABLE_ANSWER);
  };

  const showUploadModal = () => setUploadModalIsVisible(true);

  const hideUploadModal = () => {
    setAmaVideo(null);
    setUploadModalIsVisible(false);
  };

  const handleDeclineAmaRequest = async () => {
    try {
      await declineAmaRequest({
        variables: { input: { id: amaId, declineReason } },
      });
      successNotification('The AMA request is declined');
      hideDeclineAmaModal();
    } catch (err) {
      errorNotification();
      console.log('declineAmaRequest error:', err);
    }
  };

  const uploadToS3 = async (
    responses: CreateAmaRequestVideoPresignedUrl,
    record: File | Blob
  ) => {
    let videoKey = '';
    const { fields, url, key } =
      responses?.createAmaRequestVideoPresignedUrl ?? {};
    const parsedFields = JSON.parse(fields);
    const formData = new FormData();

    Object.entries(parsedFields).forEach(([k, v]) => {
      formData.append(k, v as string | Blob);
    });
    const fileExt =
      record instanceof File ? record.name?.split('.').pop() : 'mov';
    const keyWithExt: string = key + `.${fileExt}`;
    // for file that has mkv extension, file type is empty string
    // we need to add 'video/mkv' for file type
    const type =
      record instanceof File
        ? fileExt === 'mkv'
          ? 'video/mkv'
          : record.type
        : 'video/webm';

    videoKey = keyWithExt;
    formData.append('key', keyWithExt);
    formData.append('Content-Type', type);
    formData.append('file', record);

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload to S3');
      }

      await submitAmaRequestVideo({
        variables: {
          input: {
            id: amaId,
            videoKey,
          },
        },
      });
    } catch (error) {
      errorNotification((error as Error).message || 'Something went wrong');
    }
  };

  const handleSubmit = async () => {
    if (!amaVideo) {
      return;
    }

    try {
      await handleUpload(amaVideo as File);
      hideUploadModal();
    } catch (error) {
      errorNotification((error as Error).message || 'Something went wrong');
    }
  };

  const handleUpload = async (file: File) => {
    try {
      const getUrls = await createAmaRequestVideoPresignedUrl({
        variables: {
          input: {
            id: amaId,
          },
        },
      });

      if (getUrls?.data) {
        try {
          await uploadToS3(getUrls.data, file);
        } catch (error) {
          errorNotification((error as Error).message || 'Something went wrong');
        }
      }
    } catch (error) {
      errorNotification((error as Error).message || 'Something went wrong');
    }
  };

  const onFileUpload = (e: ChangeEvent<HTMLInputElement>): void => {
    const file = e?.target?.files?.[0];
    const fileExtension = file?.name?.split('.').pop() || '';

    const videoURL = file ? URL?.createObjectURL(file) : null;
    if (!file || !videoURL) {
      return;
    }
    setAmaVideo(file);
    setVideoPreview(videoURL);

    if (!isValidVideoExtension(fileExtension)) {
      errorNotification('Video has not valid extension');
    }
  };

  const handleClearVideo = () => setAmaVideo(null);

  const buttonsAcceptDecline = (
    <>
      <Space size="middle" direction="vertical">
        <Button
          type="primary"
          className="basic-table__button_success"
          onClick={handleAcceptAmaRequest}
          loading={acceptIsLoading}
        >
          Accept
        </Button>

        <Button type="primary" danger onClick={showDeclineAmaModal}>
          Decline
        </Button>
      </Space>

      <Modal
        title="Please specify the reason:"
        okText="Decline"
        visible={declineModalIsVisible}
        onOk={handleDeclineAmaRequest}
        confirmLoading={declineIsLoading}
        onCancel={hideDeclineAmaModal}
      >
        <Select value={declineReason} onChange={setDeclineReason}>
          {Object.keys(DECLINE_REASON).map((key: string) => (
            <Option key={key} value={DECLINE_REASON[key]}>
              {DECLINE_REASON[key]}
            </Option>
          ))}
        </Select>
      </Modal>
    </>
  );

  const buttonUpload = (
    <>
      <Space size="middle" direction="vertical">
        <Button
          type="primary"
          style={{ background: '#82ca9d', borderColor: '#82ca9d' }}
          onClick={showUploadModal}
        >
          Upload
        </Button>
      </Space>

      <Modal
        title="Please upload the video:"
        okText="Upload"
        visible={uploadModalIsVisible}
        confirmLoading={uploadIsLoading}
        onOk={handleSubmit}
        onCancel={hideUploadModal}
      >
        <VideoPreview video={videoPreview} show={Boolean(amaVideo)} />
        <div style={{ marginTop: 10 }} className="basic-table__upload-area">
          <div className="basic-table__upload-area__form-group">
            {!amaVideo ? (
              <>
                <input
                  name="video"
                  type="file"
                  accept={VALID_VIDEO_EXTENSIONS.map((ext) => `.${ext}`).join(
                    ','
                  )}
                  onChange={onFileUpload}
                />
              </>
            ) : (
              <Button onClick={handleClearVideo}>
                <DeleteOutlined />
                <span> Clear</span>
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );

  switch (orderStatus) {
    case STATUS.PROCESSING:
      return buttonsAcceptDecline;
    case STATUS.ACCEPTED:
      return buttonUpload;
    default:
      return null;
  }
};

export default Actions;
