import React from 'react';
import moment from 'moment';
// Types
import { VideoEditRequestsOrderBy } from 'api/graphql-global-types';
// Helpers
import {
  VideoEditingRequestsFormattedData,
  getVideoRequestDueDateCountdown,
} from 'helpers/videoEditingRequest';

const storeNameColumn = {
  title: 'Store Name',
  dataIndex: 'storeName',
  key: 'storeName',
  align: 'left' as const,
  width: 100,
  withSearch: true,
};

const requestDateColumn = {
  title: 'Creation date',
  dataIndex: VideoEditRequestsOrderBy.createdAt,
  key: VideoEditRequestsOrderBy.createdAt,
  align: 'center' as const,
  width: 100,
  sorterType: 'date',
  render: (createdAt: string): JSX.Element => {
    return <>{moment(createdAt).local().format('MM/DD/YYYY, h:mm a')}</>;
  },
};
const dueDateColumn = {
  title: 'Due date',
  dataIndex: VideoEditRequestsOrderBy.dueDate,
  key: VideoEditRequestsOrderBy.dueDate,
  align: 'center' as const,
  width: 100,
  render: (
    createdAt: string,
    record: VideoEditingRequestsFormattedData
  ): JSX.Element => {
    return <span>{getVideoRequestDueDateCountdown(record)}</span>;
  },
};

const assignedEditorColumn = {
  title: 'Assigned editor',
  dataIndex: VideoEditRequestsOrderBy.videoEditorName,
  key: VideoEditRequestsOrderBy.videoEditorName,
  align: 'left' as const,
  width: 100,
  withSearch: true,
};

const requestTypeColumn = {
  title: 'Video type',
  dataIndex: VideoEditRequestsOrderBy.videoEditRequestType,
  key: VideoEditRequestsOrderBy.videoEditRequestType,
  align: 'center' as const,
  width: 100,
};

export {
  storeNameColumn,
  requestDateColumn,
  dueDateColumn,
  assignedEditorColumn,
  requestTypeColumn,
};
