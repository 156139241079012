import {
  MemorabiliaFulfillment,
  MemorabiliaProductType,
} from 'api/graphql-global-types';

export const FULFILLMENT_OPTIONS = [
  {
    label: 'I`ll handle it myself',
    value: MemorabiliaFulfillment.Athlete,
  },
  {
    label: 'Let Millions do it for you*',
    value: MemorabiliaFulfillment.Millions,
  },
];

export const PRODUCT_TYPE_OPTIONS = [
  {
    label: 'Product',
    value: MemorabiliaProductType.Product,
  },
  {
    label: 'Memorabilia',
    value: MemorabiliaProductType.Memorabilia,
  },
];
