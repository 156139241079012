import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Modal, Button, Space } from 'antd';
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
// Api
import { DELETE_PSYCHOSOCIAL_KEYWORD_FROM_STORE } from 'api/psychosocialKeyword/mutations';
import { GET_ALL_PSYCHOSOCIAL_KEYWORDS } from 'api/psychosocialKeyword/queries';
import { GET_ADMIN_STORES } from 'api/store/queries';
// Types
import {
  GetAllUserPsychosocialKeywordsVariables,
  GetAllUserPsychosocialKeywords_getAllUserPsychosocialKeywords_entities,
} from 'api/psychosocialKeyword/types/GetAllUserPsychosocialKeywords';
import {
  AdminStoresFilterInput,
  AdminStoresOrderBy,
  SortDirection,
  UserRole,
} from 'api/graphql-global-types';
import {
  GetAdminStores,
  GetAdminStoresVariables,
  GetAdminStores_adminStores_entities,
} from 'api/store/types/GetAdminStores';
import {
  DeletePsychosocialKeywordsFromStore,
  DeletePsychosocialKeywordsFromStoreVariables,
} from 'api/psychosocialKeyword/types/DeletePsychosocialKeywordsFromStore';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
import Table, { SortedInfo } from 'ui/Table';

const { confirm } = Modal;

export type StoresProps = {
  record: GetAllUserPsychosocialKeywords_getAllUserPsychosocialKeywords_entities;
  getInput: () => GetAllUserPsychosocialKeywordsVariables;
  onFinish: () => void;
};

const Stores = ({ record, getInput, onFinish }: StoresProps): JSX.Element => {
  const defaultSortInfo: SortedInfo<AdminStoresOrderBy> = {
    order: SortDirection.DESC,
    key: AdminStoresOrderBy.createdAt,
  };

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] =
    useState<SortedInfo<AdminStoresOrderBy>>(defaultSortInfo);

  const getStoreVariables = (): GetAdminStoresVariables => {
    const input: AdminStoresFilterInput = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      psychosocialKeywordsIds: [record.id],
    };

    return { input };
  };

  const { data, loading } = useQuery<GetAdminStores, GetAdminStoresVariables>(
    GET_ADMIN_STORES,
    {
      variables: {
        storeRoles: [
          UserRole.Athlete,
          UserRole.ContentCreator,
          UserRole.Organization,
        ],
        ...getStoreVariables(),
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const [handleDeleteKeywordFromStore] = useMutation<
    DeletePsychosocialKeywordsFromStore,
    DeletePsychosocialKeywordsFromStoreVariables
  >(DELETE_PSYCHOSOCIAL_KEYWORD_FROM_STORE);

  const onDeleteFromStoreButtonClick = (storeName: string, storeId: string) => {
    confirm({
      title: `Are you sure you want to delete keyword ${record.name} from store ${storeName}?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => handleDeleteFromStore(storeName, storeId),
    });
  };

  const handleDeleteFromStore = async (storeName: string, storeId: string) => {
    try {
      await handleDeleteKeywordFromStore({
        variables: {
          storeId,
          psychosocialKeywordId: record.id,
        },
        refetchQueries: [
          {
            query: GET_ALL_PSYCHOSOCIAL_KEYWORDS,
            variables: { ...getInput() },
          },
        ],
      });
      successNotification(
        `${record.name} has been deleted from store ${storeName} successfully`
      );
      onFinish();
    } catch (err) {
      errorNotification((err as Error)?.message);
      console.error(err);
    }
  };

  const columns = [
    {
      title: 'Store',
      dataIndex: ['storeDetails', 'storeName'],
      width: 30,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: 30,
      render: function Actions(
        text: string,
        record: GetAdminStores_adminStores_entities
      ) {
        return (
          <Space size="middle" direction="vertical">
            <Button
              icon={<CloseCircleOutlined />}
              type="link"
              onClick={() => {
                if (record.storeDetails?.storeName) {
                  onDeleteFromStoreButtonClick(
                    record.storeDetails.storeName,
                    record.id
                  );
                }
              }}
              danger
            />
          </Space>
        );
      },
    },
  ];

  return (
    <Table<GetAdminStores_adminStores_entities, AdminStoresOrderBy>
      columns={columns}
      data={data?.adminStores.entities}
      loading={loading}
      total={data?.adminStores?.total}
      defaultPageSize={pageSize}
      defaultCurrent={currentPage}
      sortInfo={sort}
      setSortInfo={setSortInfo}
      setPageSize={setPageSize}
      setCurrentPage={setCurrentPage}
    />
  );
};

export default Stores;
