import { gql } from '@apollo/client';
import { PromocodeFields } from './fragments';

export const GET_ADMIN_PROMO_CODE = gql`
  query GetAdminPromocode($input: GetPromoCodeInput!) {
    getAdminPromocode(input: $input) {
      ...PromocodeFields
      merchProducts {
        id
        title
        storeId
        store {
          id
          firstName
          lastName
        }
      }
      amas {
        id
        type
        storeId
        store {
          id
          firstName
          lastName
        }
        title
      }
      streams {
        id
        name
        storeId
        store {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${PromocodeFields}
`;

export const GET_PROMO_CODES = gql`
  query GetPromocodes($input: PromoCodesFilterInput!) {
    getPromocodes(input: $input) {
      entities {
        ...PromocodeFields
      }
      total
      offset
      limit
    }
  }
  ${PromocodeFields}
`;

export const GET_STORES_PROMO_CODE_PAYLOAD = gql`
  query GetStoresPromoCodePayload($input: StorePromoCodePayloadInput!) {
    getStoresPromoCodePayload(input: $input) {
      store {
        id
        firstName
        lastName
        slug
      }
      products {
        id
        title
        storeId
      }
      amas {
        id
        type
        title
        storeId
      }
      streams {
        id
        name
        storeId
      }
    }
  }
`;

export const GET_PROMO_CODE_STATS = gql`
  query GetPromocodeStats($input: GetPromocodeStatsFilter!) {
    getPromocodeStats(input: $input) {
      entities {
        id
        name
        timesUsed
        totalRevenue
        totalDiscountAmount
      }
      total
      limit
      offset
    }
  }
`;
