import { gql } from '@apollo/client';

export const CREATE_UNREGISTERED_CONTACT = gql`
  mutation CreateUnregisteredContact(
    $input: [CreateUnregisteredContactInput!]!
  ) {
    createUnregisteredContact(input: $input) {
      id
    }
  }
`;
