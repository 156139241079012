import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Spin, Button, Modal, Pagination } from 'antd';
// API
import { GET_MEDIA_POSTS } from 'api/mediaPost/queries';
// Types
import {
  GetMediaPosts,
  GetMediaPostsVariables,
} from 'api/mediaPost/types/GetMediaPosts';
import { MediaPostStatus } from 'api/graphql-global-types';
// Hooks
import { useDefaultStoreRedirectOnEmptyId } from 'hooks';
// Components
import CreateEditMediaFeedStream from './CreateEditMediaFeed/CreateEditMediaFeed';
import MediaPostCard from './components/Card/Card';
// Styles
import styles from './MediaFeed.module.scss';

const DEFAULT_NUMBER_ITEMS_PER_PAGE = 12;

const ManageMediaFeed = (): JSX.Element => {
  useDefaultStoreRedirectOnEmptyId();

  const { storeId } = useParams<{ storeId: string | undefined }>();

  const [mediaPostModalVisibility, setMediaPostModalVisibility] =
    useState<boolean>(false);
  const [isUploadStarted, setIsUploadStarted] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(
    DEFAULT_NUMBER_ITEMS_PER_PAGE
  );

  const { data: mediaPostsData, loading } = useQuery<
    GetMediaPosts,
    GetMediaPostsVariables
  >(GET_MEDIA_POSTS, {
    variables: {
      input: {
        storeId,
        statuses: [
          MediaPostStatus.published,
          MediaPostStatus.scheduled,
          MediaPostStatus.scheduledInstant,
        ],
        limit: pageSize,
        offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      },
    },
    fetchPolicy: 'network-only',
  });

  const handleChangePage = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    setPageSize(pageSize || 0);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const handleMediaPostModalOpen = () => {
    setMediaPostModalVisibility(true);
  };

  const handleMediaPostModalClose = () => {
    if (!isUploadStarted) {
      setMediaPostModalVisibility(false);
    }
  };

  const onCompleteCallback = () => {
    setMediaPostModalVisibility(false);
    setIsUploadStarted(false);
    window.location.reload();
    // line above unblocks closing of the modal
  };

  const handleOnFailure = () => {
    setIsUploadStarted(false);
  };

  const mediaPosts = mediaPostsData?.getMediaPosts?.entities || [];
  const total = mediaPostsData?.getMediaPosts?.total ?? 0;

  const isNotFound = !loading && total === 0;

  if (loading) {
    return <Spin />;
  }

  return (
    <>
      <div className={styles.root}>
        <Button onClick={handleMediaPostModalOpen}>Create</Button>
        {!isNotFound ? (
          <div className={styles.mediaPosts}>
            {mediaPosts.map((item) => (
              <MediaPostCard mediaPost={item} key={item.id} />
            ))}
          </div>
        ) : (
          <p>No media feed</p>
        )}
      </div>

      {total > DEFAULT_NUMBER_ITEMS_PER_PAGE && (
        <Pagination
          onChange={handleChangePage}
          defaultCurrent={1}
          total={mediaPostsData?.getMediaPosts.total}
          current={currentPage}
        />
      )}

      <Modal
        title="Create a media post"
        visible={mediaPostModalVisibility}
        footer={null}
        onCancel={handleMediaPostModalClose}
      >
        <CreateEditMediaFeedStream
          onComplete={onCompleteCallback}
          onFailure={handleOnFailure}
        />
      </Modal>
    </>
  );
};

export default ManageMediaFeed;
