// Type
import {
  AffiliateProductItemType,
  UploadImage,
} from 'ui/AffiliateProductItem/AffiliateProductItem';
import {
  CreateAffiliateProductInput,
  EditAffiliateProductInput,
} from 'api/graphql-global-types';
import { CreatePresignedAffiliateProductImageUrls_createPresignedAffiliateProductImageUrls } from 'api/affiliateProducts/types/CreatePresignedAffiliateProductImageUrls';
import { StreamIntervalEnum } from 'api/graphql-global-types';

export const getCreateAffiliateFields = (
  affiliateProductsData: AffiliateProductItemType[],
  imageUrls: string[]
): CreateAffiliateProductInput[] => {
  return affiliateProductsData.map((product, index) => {
    const { price, title, url } = product;
    return {
      imageKey: imageUrls[index],
      price,
      title,
      url,
    };
  });
};

export const getEditAffiliateFields = (
  affiliateProductsData: AffiliateProductItemType[],
  imageUrls: string[]
): EditAffiliateProductInput[] => {
  return affiliateProductsData.map((product, index) => {
    const { id, price, title, url } = product;
    if (id) {
      // having ID means this affiliate product is being updated
      return {
        id,
        imageKey: imageUrls[index],
        price,
        title,
        url,
      };
    } else {
      // not having ID means this affiliate product is new
      return {
        imageKey: imageUrls[index],
        price,
        title,
        url,
      };
    }
  });
};

export const rearrangeArrayIndexes = (
  affiliateProducts: AffiliateProductItemType[]
): AffiliateProductItemType[] => {
  return affiliateProducts.map((item, index) => ({
    ...item,
    index,
  }));
};

type ImageToUpload = {
  fields: string;
  url: string;
  name: string;
  type: string;
  file: File | '';
};

export const getImagesToUpload = (
  preSignedUrls: CreatePresignedAffiliateProductImageUrls_createPresignedAffiliateProductImageUrls[],
  images: UploadImage[]
): ImageToUpload[] => {
  const res: ImageToUpload[] = [];

  images.forEach(({ file }, index) => {
    const preSignedUrl = preSignedUrls?.[index];

    const imageExtension = file
      ? file.name.split('.')[file.name.split('.').length - 1]
      : '';

    const imageName = `${preSignedUrl?.key || ''}.${imageExtension}`;

    res.push({
      fields: preSignedUrl?.fields || '',
      url: preSignedUrl?.url || '',
      name: imageName,
      type: file?.type || '',
      file: file || '',
    });
  });

  return res;
};

export const uploadImages = async (
  preSignedUrls: CreatePresignedAffiliateProductImageUrls_createPresignedAffiliateProductImageUrls[],
  images: UploadImage[]
): Promise<string[]> => {
  const imagesToUpload = getImagesToUpload(preSignedUrls, images);

  for (const image of imagesToUpload) {
    const { fields, url, name, type, file } = image;
    const formData = new FormData();

    Object.entries(JSON.parse(fields)).forEach(([key, value]) => {
      formData.append(key, value as string);
    });

    formData.append('key', name);
    formData.append('Content-Type', type);
    formData.append('file', file);

    await fetch(url, {
      method: 'POST',
      body: formData,
    });
  }

  return imagesToUpload.map(({ name }) => name);
};

export type RepeatStreamOption = {
  value: StreamIntervalEnum | null;
  label: string;
};

export const REPEAT_STREAM_OPTIONS: RepeatStreamOption[] = [
  {
    label: "No, it doesn't repeat",
    value: null,
  },
  {
    label: 'Every week',
    value: StreamIntervalEnum.weekly,
  },
  {
    label: 'Every other week',
    value: StreamIntervalEnum.biweekly,
  },
  {
    label: 'Every month',
    value: StreamIntervalEnum.monthly,
  },
];
