import Cookies from 'js-cookie';
// Types
import { AuthUser } from 'context/AppContext';
// Helpers
import { getCookiesDomain, getCookiesTokenEnvName } from 'helpers/env';
import { isJsonString } from 'helpers/textEditor';

export const AUTH_USER = 'token';

const setItem = (key: string, value: string): void => {
  Cookies.set(key, value, {
    domain: getCookiesDomain(),
    sameSite: 'Lax',
    expires: 90,
  });
};

const getItem = (key: string): string | null => {
  return Cookies.get(key) ?? null;
};

const removeItem = (key: string): void => {
  Cookies.remove(key, {
    domain: getCookiesDomain(),
    sameSite: 'Lax',
    expires: 90,
  });
};

// Auth User
export const setAuthUserToCookies = (authUser: AuthUser): void => {
  setItem(`${AUTH_USER}-${getCookiesTokenEnvName()}`, JSON.stringify(authUser));
};

export const getAuthUserFromCookies = (): AuthUser | null => {
  const authUser = getItem(`${AUTH_USER}-${getCookiesTokenEnvName()}`) || '';
  return authUser?.length && isJsonString(authUser)
    ? JSON.parse(authUser)
    : null;
};

export const clearAuthUserFromCookies = (): void => {
  removeItem(`${AUTH_USER}-${getCookiesTokenEnvName()}`);
};
