import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Form, Button, Input, InputNumber, Spin, Tooltip } from 'antd';
import cn from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { CheckCircleFilled } from '@ant-design/icons';
// Api
import { GET_STORE_NAME } from 'api/store/queries';
import {
  GET_PR_PRODUCT_BY_TYPE_V2,
  GET_MERCH_PRODUCT_V2,
  ESTIMATE_MERCH_PRICE,
} from 'api/merch/queries';
import {
  CREATE_MERCH_PRODUCT_V2,
  CREATE_VARIANT_PRINT_FILE_PRESIGNED_URLS,
  EDIT_MERCH_PRODUCT_V2,
} from 'api/merch/mutations';
import { CREATE_PRESIGNED_URL } from 'api/merch/mutations';
// Types
import {
  GetPRProductByTypeV2,
  GetPRProductByTypeV2Variables,
} from 'api/merch/types/GetPRProductByTypeV2';
import {
  CreateMerchProductV2,
  CreateMerchProductV2Variables,
} from 'api/merch/types/CreateMerchProductV2';
import {
  GetMerchProductV2,
  GetMerchProductV2Variables,
} from 'api/merch/types/GetMerchProductV2';
import {
  EditMerchProductV2,
  EditMerchProductV2Variables,
} from 'api/merch/types/EditMerchProductV2';
import {
  MerchType,
  PlacementTypes,
  Gender,
  UserRole,
  Age,
  MerchProductStatus,
  MerchProductVariantSet,
  MerchEstimatePrintFileSetInput,
} from 'api/graphql-global-types';
import { GetPrintImages_getPrintImages } from 'api/merch/types/GetPrintImages';
import {
  CreateVariantPrintFilePresignedUrls,
  CreateVariantPrintFilePresignedUrlsVariables,
} from 'api/merch/types/CreateVariantPrintFilePresignedUrls';
import {
  GetStoreName,
  GetStoreNameVariables,
} from 'api/store/types/GetStoreName';
import {
  CreatePresignedUrlMerch,
  CreatePresignedUrlMerchVariables,
} from 'api/merch/types/CreatePresignedUrlMerch';
import {
  EstimateMerchPrice,
  EstimateMerchPriceVariables,
} from 'api/merch/types/EstimateMerchPrice';
// Hooks
import { useAppContext } from 'hooks';
import { useGetPriceMargins } from 'hooks/useGetPriceMargins';
// Helpers
import { formatHashtagInput } from 'helpers/hashtags';
import {
  ComputePrintfulDimensions,
  PRProductExtraColors,
  computeMerchBackgroundColor,
  createMerchTitle,
  getCommonInput,
  getMerchGenderOptions,
  getPRProductStitches,
  getPRProductsColorsVariants,
  getPRProductsThreads,
  prepareBuildData,
  getTemplateImageUrl,
  getThreadColorsOptions,
  convertMerchDataIntoBuildData,
  formatPrice,
  formatProductType,
  getVariantsByColor,
} from 'components/common/ManageMerch/helpers';
import { base64ToBlob, convertBlobToFile } from 'helpers/file';
import {
  PresignedUrlResultItem,
  createPresignedUrlsAndUploadToS3,
} from 'helpers/single-uploader';
// Constants
import { MY_DESIGN_REQUESTS } from 'constants/routes';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
import UploadMultipleImages, {
  UploadImage,
} from 'ui/UploadMultipleImages/UploadMultipleImages';
// Components
import PrintImages from './PrintImages/PrintImages';
import PrintCanvas from './PrintCanvas/PrintCanvas';
import PrintPlacements from './PrintPlacements/PrintPlacements';
import TagsInput from 'uiShared/TagsInput/TagsInput';
// Styles
import styles from './MerchConstructor.module.scss';

type FormValues = {
  productName: string;
  hashtags: string[] | null;
  profit: number;
};

export type PrintCoordinates =
  | (ComputePrintfulDimensions & {
      pngFromCanvas?: string;
      uploadedAreaKey?: string;
      // pngPreviewFromCanvas: string;
    })
  | null;

export type PrintImage = GetPrintImages_getPrintImages | null;

export type PrintFiles = Partial<Record<PlacementTypes, PrintCoordinates>>;

export type SelectedPrintImages = Partial<Record<PlacementTypes, PrintImage>>;

export type CustomImage = {
  customMerchImageURL: string | null;
  imageFileKey: string;
  isMainImage: boolean;
  file: File | undefined;
};

export type BuildData = {
  gender: Gender;
  age: Age;
  colorTitle: string;
  // using one param for both stitches and thread color parameters
  extraColor?: string | undefined;
  printImages: SelectedPrintImages;
  printPlacements: PlacementTypes[];
  hasError: boolean;
  printFiles: PrintFiles;
  customImages: CustomImage[];
};

export type UploadCustomImage = {
  data_url?: string;
  file?: File;
  printfulVariantIds: number[];
  isMainImage: number;
};

const MerchConstructor = (): JSX.Element => {
  const { authUser } = useAppContext();

  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productType: MerchType = location.pathname.split('/')[2] as MerchType;

  const isDesigner = authUser?.role === UserRole.Designer;

  const { storeId, productId } = useParams<{
    storeId: string | undefined;
    productId: string | undefined;
  }>();

  const designRequestId = searchParams.get('designRequestId');
  const hasDesignRequestId = !!designRequestId;
  const merchTypesString = searchParams.get('merchTypes');
  const merchTypesArray = merchTypesString ? merchTypesString.split(',') : [];

  const [form] = Form.useForm();
  const methods = useForm();
  const { control } = methods;

  const [formValues, setFormValues] = useState<FormValues>({
    productName: '',
    hashtags: null,
    profit: 0,
  });
  const { productName, hashtags, profit } = formValues;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setValue = (field: any) => setFormValues({ ...formValues, ...field });

  const canvasBlockRef = useRef<HTMLDivElement | null>(null);
  const editingInitializationRef = useRef<boolean>(false);

  const isChangedRef = useRef<boolean>(false);

  const setIsChanged = useCallback(() => {
    if (!isChangedRef.current) {
      isChangedRef.current = true;
    }
  }, []);

  // get gender options for tabs
  const genderOptions = getMerchGenderOptions(productType);
  // set Male as default gender for all case except the hats, when its Unisex
  const [gender, setGender] = useState<Gender>(genderOptions[0]);
  const [genderErrors, setGenderErrors] = useState<Gender[]>([]);
  // display or hide the color picker after the plus icon
  const [showColorSelector, setShowColorSelector] = useState<boolean>(false);

  // centralized merch data
  const [buildData, setBuildData] = useState<BuildData[]>([]);

  // currently selected color option
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  // using previous only for the purpose of copying the coordinates when creating new variant
  // this behavior should persist even trough switching gender option and selecting a different placement
  const [previousIndex, setPreviousIndex] = useState<number>(0);

  const currentItem = buildData[currentIndex];
  const previousItem = buildData[previousIndex];

  const currentGenderBuildData = buildData.filter(
    (item) => item.gender === gender
  );

  const printFilesRef = useRef<PrintFiles>({});

  const [computeCoordinatesLoading, setComputeCoordinatesLoading] =
    useState<boolean>(false);

  const isHatConstructor = productType === MerchType.Hat;
  const isJoggersConstructor = productType === MerchType.Joggers;
  const isRashGuardConstructor = productType === MerchType.RashGuard;
  const isShirt = productType === MerchType.TShirt;

  const initialPlacement = isJoggersConstructor
    ? PlacementTypes.leg_back_left
    : isHatConstructor
    ? PlacementTypes.embroidery_front_large
    : PlacementTypes.front;

  const [selectedPlacement, setSelectedPlacement] =
    useState<PlacementTypes>(initialPlacement);

  const [refreshLoading, setRefreshLoading] = useState<boolean>(false);
  const [isCanvasMount, setIsCanvasMount] = useState<boolean>(false);

  const [imageProcessing, setImageProcessing] = useState<boolean>(false);

  const [isMainImage, setIsMainImage] = useState<number>(0);

  const [finishButtonLoading, setFinishButtonLoading] =
    useState<boolean>(false);

  const { data: storeData } = useQuery<GetStoreName, GetStoreNameVariables>(
    GET_STORE_NAME,
    {
      variables: {
        input: {
          id: storeId,
        },
      },
      fetchPolicy: 'cache-and-network',
      skip: !storeId,
    }
  );

  const storeName = storeData?.store.storeDetails?.storeName || '';

  const { data: prProductData } = useQuery<
    GetPRProductByTypeV2,
    GetPRProductByTypeV2Variables
  >(GET_PR_PRODUCT_BY_TYPE_V2, {
    variables: {
      type: { type: productType },
    },
    fetchPolicy: 'network-only',
    skip: !productType,
  });

  const { data: allMerchData } = useQuery<
    GetMerchProductV2,
    GetMerchProductV2Variables
  >(GET_MERCH_PRODUCT_V2, {
    variables: {
      input: {
        id: Number(productId || '') as number,
      },
    },
    fetchPolicy: 'network-only',
    skip: !productId,
  });

  const { data: priceMarginsData } = useGetPriceMargins();

  const merchData = allMerchData?.getMerchProduct;

  const age = gender === Gender.Youth ? Age.Youth : Age.Adult;
  const estimateMerchPriceGender =
    gender === Gender.Youth ? Gender.Unisex : gender;

  const selectedProduct = prProductData?.getPRProductByTypeV2?.filter(
    (product) => {
      return product.gender === estimateMerchPriceGender && product.age === age;
    }
  );

  const variants =
    getVariantsByColor(
      selectedProduct?.[0]?.data.product.variants,
      currentItem?.colorTitle
    ) || [];

  const variantIds = variants?.map((item) => item?.id as number);

  const newVariantSet: MerchProductVariantSet = {
    age,
    gender: estimateMerchPriceGender,
    variantIds,
  };

  if (isHatConstructor && currentItem?.extraColor) {
    newVariantSet.threads = [currentItem.extraColor];
  }

  if (isRashGuardConstructor && currentItem?.extraColor) {
    newVariantSet.stitches = currentItem.extraColor;
  }

  const variantSets: MerchProductVariantSet[] = [newVariantSet];

  const placementTypeSet = currentItem?.printPlacements.map((item) => {
    return { placementType: item };
  });

  const newPrintFileSet: MerchEstimatePrintFileSetInput = {
    printFiles: placementTypeSet,
    variantSetIndices: [0],
  };

  const printFileSets: MerchEstimatePrintFileSetInput[] = [newPrintFileSet];

  const estimateMerchPriceInput = {
    printFileSets,
    requestedProfit: Number(profit),
    type: productType,
    variantSets: variantSets,
  };

  // don't fetch estimate in case of:
  // - having a hat or rashguard merch without secondary color(thread or stitches)
  // - no profit is written in the requested profit input
  // - no variant sets exist
  // - no print area was selected
  const skipEstimate =
    ((isHatConstructor || isRashGuardConstructor) &&
      (!currentItem || !currentItem.extraColor)) ||
    estimateMerchPriceInput.requestedProfit === 0 ||
    !estimateMerchPriceInput.variantSets.length ||
    !estimateMerchPriceInput.printFileSets[0].printFiles?.length;

  const { data: estimateMerchPriceData, error: estimateMerchPriceError } =
    useQuery<EstimateMerchPrice, EstimateMerchPriceVariables>(
      ESTIMATE_MERCH_PRICE,
      {
        variables: {
          input: estimateMerchPriceInput,
        },
        fetchPolicy: 'network-only',
        skip: skipEstimate,
      }
    );

  // leaving log in case of an error
  console.error('Estimate Merch price API error:', estimateMerchPriceError);

  const estimate = estimateMerchPriceData?.estimateMerchPrice.default;

  const [createCustomImagesPresignedUrl] = useMutation<
    CreatePresignedUrlMerch,
    CreatePresignedUrlMerchVariables
  >(CREATE_PRESIGNED_URL);

  const [createPrintFilePresignedUrl] = useMutation<
    CreateVariantPrintFilePresignedUrls,
    CreateVariantPrintFilePresignedUrlsVariables
  >(CREATE_VARIANT_PRINT_FILE_PRESIGNED_URLS);

  const customImages = currentItem?.customImages;

  const [createMerchProduct, { loading: createMerchProductLoading }] =
    useMutation<CreateMerchProductV2, CreateMerchProductV2Variables>(
      CREATE_MERCH_PRODUCT_V2
    );

  const [updateMerchProduct, { loading: updateMerchProductLoading }] =
    useMutation<EditMerchProductV2, EditMerchProductV2Variables>(
      EDIT_MERCH_PRODUCT_V2
    );

  const platformMargin = priceMarginsData?.getPriceMargins.merchMargin || 0;

  // setting the product type based on gender
  const PRProductByType = prProductData?.getPRProductByTypeV2.find(
    (type) =>
      type.gender === gender ||
      (type.age === Age.Youth && gender === Gender.Youth)
  );

  const placementType = isHatConstructor
    ? PlacementTypes.embroidery_front_large
    : selectedPlacement;

  const printFile = currentItem?.printFiles?.[placementType] || null;
  const selectedPrintImage = currentItem?.printImages?.[placementType] || null;

  const isWaitingPrintFile = Boolean(
    productId && !currentItem?.printPlacements.length
  );

  // Printful added limit for joggers
  const limitForJoggers = isJoggersConstructor
    ? currentItem?.printPlacements && currentItem?.printPlacements.length > 3
    : false;

  const hasInvalidExtraColors =
    isRashGuardConstructor && !!buildData.find((item) => !item.extraColor);

  const hasInvalidPlacements = !!buildData.find(
    (item) => !item.printPlacements.length
  );

  const colorOptions = isShirt
    ? getPRProductsColorsVariants(PRProductByType).filter(
        (color) =>
          color.title.includes('Heather') || color.title.includes('White')
      )
    : isJoggersConstructor // color team royal was removed by the printful for joggers only
    ? getPRProductsColorsVariants(PRProductByType).filter(
        (color) => !color.title.includes('Team Royal')
      )
    : getPRProductsColorsVariants(PRProductByType);

  const threadOptions = getPRProductsThreads(PRProductByType);
  const threadColor = getThreadColorsOptions(
    threadOptions,
    currentItem?.extraColor
  );

  const stitchOptions = getPRProductStitches(PRProductByType);

  // filter to avoid unnecessary types
  const productPrintPlacements =
    PRProductByType?.data.product?.product?.files?.filter(
      (item) =>
        item?.type === PlacementTypes.front ||
        item?.type === PlacementTypes.back ||
        item?.type === PlacementTypes.sleeve_left ||
        item?.type === PlacementTypes.sleeve_right ||
        item?.type === PlacementTypes.leg_front_left ||
        item?.type === PlacementTypes.leg_back_right ||
        item?.type === PlacementTypes.leg_back_left ||
        item?.type === PlacementTypes.leg_front_right ||
        item?.type === PlacementTypes.pocket
    ) || [];

  const templateIdByPlacement =
    PRProductByType?.data.templates.variant_mapping?.[0]?.templates?.find(
      (item) => item?.placement === placementType
    )?.template_id;

  const defaultTemplate =
    PRProductByType?.data.templates?.templates?.find(
      (item) => item?.template_id === templateIdByPlacement
    ) || null;

  const mockupSize = PRProductByType?.data.printfiles.printfiles?.find(
    (item) => item.printfile_id === defaultTemplate?.printfile_id
  );

  const defaultMerchProductPrintfilePlacements =
    productId && buildData[currentIndex]?.printImages[selectedPlacement]
      ? buildData[currentIndex].printImages[selectedPlacement]
      : undefined;

  const initialPrintImageId = defaultMerchProductPrintfilePlacements?.id;

  const isEditMode = !!productId;
  const withLogoPlaceholder = !productId;

  const backgroundCanvasColor =
    colorOptions.find((item) => item.title === currentItem?.colorTitle)
      ?.color || '';

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedSetValue = useCallback(setValue, []);

  // Set initial product values (editing only)
  useEffect(() => {
    const shouldNotWaitForThreadOptions = isHatConstructor
      ? threadOptions.length
      : true;

    if (
      merchData &&
      shouldNotWaitForThreadOptions &&
      !editingInitializationRef.current
    ) {
      const values = {
        productName: merchData?.title,
        profit: merchData?.requestedProfit,
        hashtags: merchData?.hashtags.map((tag) => tag.name),
      };
      form.setFieldsValue(values);
      memoizedSetValue(values);

      const merchBuildData = convertMerchDataIntoBuildData(
        merchData.merchProductVariants
      );
      // block below will set the data and index so we automatically show(or switch to) the first available gender and it's color
      if (currentIndex < 0) {
        setBuildData(merchBuildData);
        const sameGenderItem = merchBuildData.find(
          (item) => gender === item.gender
        );

        if (isHatConstructor) {
          // in case of hats we don't have different genders so first option should always be selected
          setCurrentIndex(0);
        } else if (sameGenderItem) {
          // if first of the gender options(male) has data, display it
          const newIndex = merchBuildData.findIndex(
            (item) => gender === item.gender
          );
          setCurrentIndex(newIndex);
        } else {
          // if there is no data for male, go to gender of first item and display it.
          const newGender = merchBuildData[0].gender;
          setCurrentIndex(0);
          setGender(newGender);
        }
      }
      editingInitializationRef.current = true;
    }
  }, [
    currentIndex,
    form,
    gender,
    isHatConstructor,
    memoizedSetValue,
    merchData,
    threadOptions.length,
  ]);

  const requestedProfit = merchData?.requestedProfit;
  // populate productName when creating merch
  useEffect(() => {
    if (!editingInitializationRef.current) {
      const values = {
        productName: `${storeName} ${formatProductType(`${productType}`)}`,
        profit: requestedProfit,
      };
      form.setFieldsValue(values);
      memoizedSetValue(values);
    }
  }, [productType, memoizedSetValue, requestedProfit, form, storeName]);

  // Set custom image's main index whenever user switches to another variation by changing color or gender
  useEffect(() => {
    const newMainImageIndex = currentItem?.customImages?.findIndex(
      (image) => image.isMainImage
    );
    if (newMainImageIndex && newMainImageIndex !== isMainImage) {
      if (newMainImageIndex > 0) {
        setIsMainImage(newMainImageIndex);
      } else {
        setIsMainImage(0);
      }
    }
  }, [currentItem, isMainImage]);

  const handleFinishButtonClick = async () => {
    setFinishButtonLoading(true);

    const printAreaBase64: string[] = [];
    const customImageFiles: File[] = [];

    // save print files to build data as they are only saved when changing colors
    const newBuildData = [...buildData];
    newBuildData[currentIndex].printFiles = { ...printFilesRef.current };

    // if there are no variants of one gender, copy all female as male variants or all male as female variants
    const preparedBuildData: BuildData[] = prepareBuildData(
      productType,
      newBuildData
    );

    preparedBuildData.forEach((item) => {
      item.printPlacements.forEach((placement) => {
        // prepare only new placements for upload, for unchanged ones we will use uploadedAreaKey
        if (
          item.printFiles[placement] &&
          !item.printFiles[placement]?.uploadedAreaKey &&
          item.printFiles[placement]?.pngFromCanvas
        ) {
          printAreaBase64.push(item.printFiles[placement]?.pngFromCanvas || '');
        }
      });
      item.customImages.forEach((image) => {
        // add only custom images that have a file, as these are newly added
        if (image.file) {
          customImageFiles.push(image.file);
        }
      });
    });

    try {
      // don't send extra data if only price or profit changed.
      if (isEditMode && !isChangedRef.current) {
        await updateMerchProduct({
          variables: {
            input: {
              id: +(productId as string) || 0,
              storeId: storeId as string,
              type: productType,
              title: productName,
              hashtagInputs: hashtags?.length
                ? formatHashtagInput(hashtags)
                : null,
              requestedProfit: +profit.toFixed(2),
              finalMerchStatus: hasDesignRequestId
                ? MerchProductStatus.Inactive
                : null,
            },
          },
        });

        successNotification('The product successfully updated');
      } else {
        let customImageKeys: PresignedUrlResultItem[] = [];

        if (customImageFiles.length) {
          customImageKeys = await createPresignedUrlsAndUploadToS3({
            isStrict: false,
            files: customImageFiles,
            getPresignedUrls: async () => {
              const { data: customImagesPresignedUrls } =
                await createCustomImagesPresignedUrl({
                  variables: {
                    input: { numberOfImages: customImageFiles.length },
                  },
                });
              if (!customImagesPresignedUrls) {
                console.error('Error creating presigned images');
                throw new Error('Something went wrong');
              }

              return customImagesPresignedUrls.createPresignedUrlMerch;
            },
          });
        }

        const printAreaFiles = printAreaBase64
          .map(base64ToBlob)
          .map((blob, idx) => convertBlobToFile(blob, `file${idx}.png`));
        let printAreaKeys: PresignedUrlResultItem[] = [];

        if (printAreaBase64.length) {
          printAreaKeys = await createPresignedUrlsAndUploadToS3({
            files: printAreaFiles,
            getPresignedUrls: async (files) => {
              const { data: printFilesPresignedUrls } =
                await createPrintFilePresignedUrl({
                  variables: {
                    input: {
                      files: files.map(({ ext, contentType }) => ({
                        ext,
                        contentType,
                      })),
                    },
                  },
                });

              if (!printFilesPresignedUrls) {
                console.error('Error creating presigned images');
                throw new Error('Something went wrong');
              }

              return printFilesPresignedUrls.createVariantPrintFilePresignedUrls;
            },
          });
        }

        const commonInput = getCommonInput(
          isDesigner,
          preparedBuildData,
          prProductData,
          productType,
          productName,
          profit,
          printAreaKeys,
          customImageKeys,
          storeId as string
        );

        if (productId) {
          await updateMerchProduct({
            variables: {
              input: {
                id: +productId,
                ...commonInput,
                hashtagInputs: hashtags?.length
                  ? formatHashtagInput(hashtags)
                  : null,
                finalMerchStatus: hasDesignRequestId
                  ? MerchProductStatus.Inactive
                  : null,
              },
            },
          });
          successNotification('The product successfully updated');
        } else {
          await createMerchProduct({
            variables: {
              input: {
                ...commonInput,
                finalMerchStatus: hasDesignRequestId
                  ? MerchProductStatus.Inactive
                  : null,
                designRequestId,
                hashtagInputs: hashtags?.length
                  ? formatHashtagInput(hashtags)
                  : null,
              },
            },
          });
          successNotification('The product successfully created');
        }
      }
    } catch (error) {
      errorNotification((error as Error)?.message);
      console.error('product mutation', { error });
    }
    if (isDesigner) {
      // Update the URL and trigger a redirect when publish
      const newPathname = location.pathname.split('/');
      const currentIndex = merchTypesArray.indexOf(newPathname[2]);

      // if it's the last product type in the array, redirect to my design requests
      if (currentIndex === merchTypesArray.length - 1) {
        history.push(MY_DESIGN_REQUESTS);
      } else {
        // else redirect to the next product type
        newPathname[2] = merchTypesArray[currentIndex + 1];
        const pathname = newPathname.join('/');
        history.push({
          pathname: pathname,
          search: location.search,
        });
      }
    } else {
      history.goBack();
    }

    setFinishButtonLoading(false);
  };

  const handleComputeCoordinatesLoadingChange = useCallback(
    (isLoading: boolean) => {
      setComputeCoordinatesLoading(isLoading);
      setImageProcessing(false);
    },
    []
  );

  const refreshCanvas = () => {
    setRefreshLoading(true);
    setIsCanvasMount(false);

    // mount & un-mount PrintCanvas to fire re-initialization
    setTimeout(() => {
      setRefreshLoading(false);
    }, 1);
  };

  const handlePrintSideChange = (side: PlacementTypes) => {
    const updatedBuildData = [...buildData];
    updatedBuildData[currentIndex].printFiles = { ...printFilesRef.current };
    setBuildData(updatedBuildData);

    if (side !== selectedPlacement) {
      setSelectedPlacement(side);
      refreshCanvas();
    }
  };

  const handlePrintSideRemove = (side: PlacementTypes) => {
    const updatedSelectedPrintPlacements = currentItem?.printPlacements.filter(
      (item) => item !== side
    );
    printFilesRef.current[side] = null;

    const updatedBuildData = [...buildData];

    updatedBuildData[currentIndex].printPlacements =
      updatedSelectedPrintPlacements;

    updatedBuildData[currentIndex].printImages[side] = null;

    // add error if it has no placements
    updatedBuildData[currentIndex].hasError =
      updatedBuildData[currentIndex].hasError ||
      !updatedBuildData[currentIndex].printPlacements?.length;

    if (side === selectedPlacement) {
      refreshCanvas();
    }
    setBuildData(updatedBuildData);

    setIsChanged();
  };

  const backgroundImage = getTemplateImageUrl({
    PRProductByType: PRProductByType,
    selectedColorTitle: currentItem?.colorTitle,
    colorOptions,
    placement: placementType,
  });

  const handlePrintCoordinatesSet = useCallback(
    (printCoordinates: PrintCoordinates) => {
      printFilesRef.current[placementType] = {
        ...printCoordinates,
      } as PrintCoordinates;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [placementType, currentIndex]
  );

  const handleSelectedPrintImageChange = (printImage: PrintImage) => {
    const newPrintPlacements = currentItem?.printPlacements;

    const updatedBuildData = [...buildData];

    if (
      selectedPrintImage?.id === printImage?.id &&
      !selectedPrintImage?.imageURL
    ) {
      updatedBuildData[currentIndex].printImages[placementType] = printImage;
      setBuildData(updatedBuildData);
    }

    if (selectedPrintImage?.id !== printImage?.id) {
      const updatedSelectedPrintPlacements = Array.from(
        new Set(newPrintPlacements).add(placementType)
      );

      setImageProcessing(true);

      if (updatedBuildData[currentIndex]) {
        updatedBuildData[currentIndex].printPlacements =
          updatedSelectedPrintPlacements;
      }

      const previousPrintImages: SelectedPrintImages =
        currentItem?.printImages || {};

      updatedBuildData[currentIndex].printImages = {
        ...previousPrintImages,
        [placementType]: printImage,
      };

      // check if this variant has an extra color requirement (only for rashguard)
      updatedBuildData[currentIndex].hasError =
        isRashGuardConstructor && !updatedBuildData[currentIndex].extraColor;

      const isValidGender =
        (gender !== Gender.Youth && previousItem?.gender !== Gender.Youth) ||
        (gender === Gender.Youth && previousItem?.gender === Gender.Youth);
      // copy print image position(coordinates) when creating new color variant
      // we use previousIndex to check if there is anything to copy here-we also don't want to copy youth or to youth
      if (
        !selectedPrintImage &&
        buildData.length > 1 &&
        previousItem &&
        previousItem.printFiles[selectedPlacement] &&
        previousIndex !== currentIndex &&
        isValidGender
      ) {
        // this is where we map the previous coordinates and add the new image from canvas
        updatedBuildData[currentIndex].printFiles[selectedPlacement] = {
          angle: previousItem.printFiles[selectedPlacement]?.angle || 0,
          top: previousItem.printFiles[selectedPlacement]?.top || 0,
          left: previousItem.printFiles[selectedPlacement]?.left || 0,
          rotatedLeft:
            previousItem.printFiles[selectedPlacement]?.rotatedLeft || 0,
          rotatedTop:
            previousItem.printFiles[selectedPlacement]?.rotatedTop || 0,
          scaleX: previousItem.printFiles[selectedPlacement]?.scaleX || 0,
          scaleY: previousItem.printFiles[selectedPlacement]?.scaleY || 0,
          width: previousItem.printFiles[selectedPlacement]?.width || 0,
          height: previousItem.printFiles[selectedPlacement]?.height || 0,
          pngFromCanvas:
            printFilesRef.current[selectedPlacement]?.pngFromCanvas ||
            undefined,
        };
      } else {
        updatedBuildData[currentIndex].printFiles = printFilesRef.current;
      }

      setBuildData(updatedBuildData);
      setIsChanged();
    }
  };

  const toggleColorSelector = () => {
    setShowColorSelector(!showColorSelector);
  };

  const handleNewColor = (colorTitle: string) => {
    const updatedBuildData = [...buildData];
    if (buildData.length && updatedBuildData[currentIndex]) {
      updatedBuildData[currentIndex].printFiles = printFilesRef.current;
    }
    const age = gender === Gender.Youth ? Age.Youth : Age.Adult;

    updatedBuildData.push({
      colorTitle,
      gender,
      age,
      printPlacements: [],
      hasError: true,
      printImages: { [selectedPlacement]: null },
      printFiles: { [selectedPlacement]: null },
      customImages: [],
    });

    printFilesRef.current = {};

    toggleColorSelector();

    if (currentGenderBuildData.length === 8) {
      errorNotification(
        'You have reached the limit of 8 variations per product'
      );
    } else {
      if (currentIndex > -1) {
        setPreviousIndex(currentIndex);
      }
      // new index is same as length of the new array -1, or buildData's length
      setCurrentIndex(buildData.length);
      setBuildData(updatedBuildData);
      setIsChanged();
    }
  };

  const handleExtraColorClick = (extraColor: string) => {
    const newData: BuildData[] = [...buildData];

    if (extraColor === newData[currentIndex]?.extraColor) {
      newData[currentIndex].extraColor = undefined;
    } else {
      newData[currentIndex].extraColor = extraColor;
    }

    // check if the current item has errors
    newData[currentIndex].hasError =
      !newData[currentIndex].extraColor ||
      !newData[currentIndex].printPlacements.length;

    setBuildData(newData);
    setIsChanged();
  };

  const handleMerchColorClick = (index: number) => {
    // save print files to build data
    const updatedBuildData = [...buildData];
    updatedBuildData[currentIndex].printFiles = printFilesRef.current;
    setBuildData(updatedBuildData);

    setPreviousIndex(currentIndex);
    setCurrentIndex(index);

    printFilesRef.current = { ...buildData[index].printFiles };
  };

  // this useEffect will transfer all the current item's placements inside the print canvas
  useEffect(() => {
    if (currentItem?.printFiles) {
      printFilesRef.current = { ...currentItem?.printFiles };
    }
  }, [buildData, currentIndex, currentItem]);

  const removeColor = (index: number) => {
    const newBuildData = [...buildData];
    newBuildData.splice(index, 1);

    setBuildData(newBuildData);
    setIsChanged();

    if (index === currentIndex) {
      // when we lose the current item, our index should be the first item of same gender
      // if there is no item of current gender, setting it to null
      const newIndex = newBuildData.findIndex((item) => item.gender === gender);
      setPreviousIndex(0);
      setCurrentIndex(newIndex);
    } else if (currentIndex > index) {
      setPreviousIndex(previousIndex - 1);
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleCloseButtonClick = () => {
    if (isDesigner) {
      history.push(MY_DESIGN_REQUESTS);
    } else {
      history.goBack();
    }
  };

  const handleGenderClick = (newGender: Gender) => {
    setGender(newGender);

    const hasErrors = currentGenderBuildData.some(
      (item) => item.hasError === true
    );

    if (hasErrors) {
      const newGenderErrors: Gender[] = [...genderErrors, gender];
      setGenderErrors(newGenderErrors);
    } else {
      const newGenderErrors: Gender[] = [...genderErrors].filter(
        (item) => item !== gender
      );
      setGenderErrors(newGenderErrors);
    }
  };

  const handleSetImages = (incomingData: UploadImage[]) => {
    const updatedBuildData = [...buildData];

    const newCustomImages: CustomImage[] = [];
    incomingData.forEach((image, index) => {
      newCustomImages.push({
        imageFileKey: image.data_key || '',
        isMainImage: false,
        customMerchImageURL: image.data_url || null,
        file:
          image.file || currentItem?.customImages?.[index].file || undefined,
      });
    });
    const newImagesLength = newCustomImages.length;

    const isAnyDeleted = currentItem?.customImages.length > newImagesLength;

    if (newImagesLength) {
      // if there are custom images, determine which one is main and set the data
      if (isAnyDeleted) {
        if (newImagesLength - 1 < isMainImage) {
          // in case of main image being last, and some other image was deleted, make new last image main
          newCustomImages[newImagesLength - 1].isMainImage = true;
          setIsMainImage(newImagesLength - 1);
        } else {
          newCustomImages[isMainImage].isMainImage = true;
        }
      } else {
        if (currentItem?.customImages) {
          if (!isMainImage) {
            setIsMainImage(0);
            newCustomImages[0].isMainImage = true;
          } else {
            newCustomImages[isMainImage].isMainImage = true;
          }
        }
      }
      updatedBuildData[currentIndex].customImages = newCustomImages;
    } else {
      // if there are no custom images, remove data and set main image index to default
      updatedBuildData[currentIndex].customImages = [];
      setIsMainImage(0);
    }
    updatedBuildData[currentIndex].printFiles = printFilesRef.current;

    setIsChanged();
    setBuildData(updatedBuildData);
  };

  const handleSetIsMainImage = (imageIndex: number) => {
    const updatedBuildData = [...buildData];

    if (currentItem?.customImages) {
      const newCustomImages: CustomImage[] = [];

      currentItem.customImages.forEach((image, index) => {
        newCustomImages.push({ ...image, isMainImage: index === imageIndex });
      });

      updatedBuildData[currentIndex].customImages = newCustomImages;
      updatedBuildData[currentIndex].printFiles = printFilesRef.current;

      setIsMainImage(imageIndex);
      setBuildData(updatedBuildData);
    }

    setIsChanged();
  };

  const renderGenderOptions = () => {
    const showGenderTabNotifications =
      !isHatConstructor && !isRashGuardConstructor;

    return (
      <>
        <div className={styles.genderOptions}>
          {genderOptions.map((option) => {
            if (!isHatConstructor) {
              return (
                <Button
                  key={option}
                  onClick={() => handleGenderClick(option)}
                  className={cn(styles.genderOption, {
                    [styles.selectedGender]: option === gender,
                  })}
                >
                  {option !== Gender.Unisex ? option + ' ' : ''}
                  {merchTitle}
                  {genderErrors?.find(
                    (item) => item === option && option !== gender
                  ) && <span className={styles.genderError}>!</span>}
                </Button>
              );
            } else return <div className={styles.hatTitle}>Hat</div>;
          })}
        </div>

        {showGenderTabNotifications && (
          <div className={styles.genderTabNotification}>
            <span>&#42;</span>
            Male variations are applied to female tab automatically
          </div>
        )}
      </>
    );
  };

  const renderColorOptions = () => {
    return (
      <div
        className={cn(styles.selectorColorOptions, {
          [styles.hideColorOptions]: !showColorSelector,
        })}
      >
        {colorOptions.map((item) => {
          return (
            <button
              key={item.title}
              className={styles.colorOption}
              title={item.title}
              style={computeMerchBackgroundColor(item.color, item.color2)}
              onClick={() => handleNewColor(item.title)}
            />
          );
        })}
      </div>
    );
  };

  const renderExtraColors = (options: PRProductExtraColors) => {
    const extraColor =
      buildData.length > currentIndex ? currentItem?.extraColor : undefined;

    return (
      <div className={styles.extraColorOptions}>
        {options.map((option) => {
          // in case of editing old merch, incoming merch data will match the option.color
          // for some new merch, incoming variable matches the option.title
          const isChecked =
            extraColor === option.color || extraColor === option.title;
          const isDisabled = !!extraColor && !isChecked;

          // in case of hats, the extra color must be a hex value-rash guard needs a regular color title
          const color = isHatConstructor ? option.color : option.title;

          return (
            <div key={option.title} className={styles.colorWrapper}>
              <button
                className={cn(styles.colorOption, {
                  [styles.checked]: isChecked,
                  [styles.disabled]: isDisabled,
                })}
                title={option.title}
                style={{ backgroundColor: option.color }}
                onClick={() => handleExtraColorClick(color)}
                disabled={isDisabled}
              />
            </div>
          );
        })}
      </div>
    );
  };

  // this covers both stitches(rashguard) and threads(hats)
  const renderExtraColorOptions = () => {
    if (isHatConstructor) {
      return (
        <div className={styles.extraColors}>
          <p className={styles.extraColorTitle}>Logo color: </p>
          {renderExtraColors(threadOptions)}
        </div>
      );
    }

    if (isRashGuardConstructor) {
      return (
        <div className={styles.extraColorOptions}>
          <p className={styles.extraColorTitle}>Stitches color: </p>
          {renderExtraColors(stitchOptions)}
        </div>
      );
    }
  };

  const merchTitle = createMerchTitle(productType as MerchType);

  const hasExtraColorOptions =
    !!buildData.length &&
    !!currentGenderBuildData.length &&
    (isHatConstructor || isRashGuardConstructor);

  useEffect(() => {
    refreshCanvas();
  }, [currentIndex, currentItem, gender, backgroundImage]);

  // set first item of selected gender as current
  useEffect(() => {
    const newBuildData = [...buildData];

    const newIndex = buildData.findIndex((item) => item.gender === gender);

    if (newBuildData[currentIndex] && newBuildData[currentIndex].printFiles) {
      // changing gender tab will now properly save print data
      newBuildData[currentIndex].printFiles = { ...printFilesRef.current };
      setBuildData(newBuildData);
    }
    setPreviousIndex(currentIndex);
    setCurrentIndex(newIndex);
    // adding buildData as a dependency would change to first color of this gender
    // whenever we change anything, which is not a desired behavior
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gender]);

  // set placing to first populated placing of selected color-or make it default in case of a new color
  useEffect(() => {
    setSelectedPlacement(
      buildData[currentIndex]?.printPlacements?.[0] || initialPlacement
    );
    // adding build data as a dependency would reset the placement to initial one after adding a logo
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, initialPlacement]);

  const isValidGender = currentItem?.gender === gender;

  const allowImageSelection = currentIndex > -1 && !!currentItem?.colorTitle;
  const extraColorError =
    hasExtraColorOptions && !currentItem?.extraColor
      ? isRashGuardConstructor
        ? 'Please select stitches color first'
        : 'Please select logo color first'
      : '';

  const currentCustomImagesList: UploadImage[] = [];
  customImages?.forEach((image) => {
    currentCustomImagesList.push({
      data_key: image.imageFileKey,
      data_url: image.customMerchImageURL || undefined,
    });
  });

  const isNextButtonDisabled = Boolean(
    !isDesigner && (!productName || !profit || isWaitingPrintFile)
  );

  const isFinishButtonLoading =
    finishButtonLoading ||
    computeCoordinatesLoading ||
    createMerchProductLoading ||
    updateMerchProductLoading;

  const isFinishButtonDisabled =
    !buildData.length ||
    hasInvalidExtraColors ||
    hasInvalidPlacements ||
    isNextButtonDisabled ||
    limitForJoggers ||
    computeCoordinatesLoading;

  const handleHashtagsChange = (hashtags: string[]) => {
    setFormValues({
      ...formValues,
      hashtags: hashtags,
    });
  };

  return (
    <>
      <div className={styles.closeButtonContainer}>
        <button className={styles.cta} onClick={handleCloseButtonClick}>
          <CloseOutlined size={150} />
        </button>
      </div>
      <Form
        form={form}
        layout="vertical"
        name="setupMerch"
        autoComplete="off"
        onValuesChange={setValue}
      >
        <header className={styles.header}>
          {productType ? (
            <div className={styles.headerButtonContainer}>
              {merchTypesArray.map((type, index) => (
                <Button
                  key={`${type}_${index}`}
                  disabled={type !== productType}
                  className={
                    productType === type
                      ? styles.headerActiveButton
                      : styles.headerInactiveButton
                  }
                >
                  {type}
                  {index < merchTypesArray.indexOf(productType) && (
                    <Tooltip title="The merch design request has been published">
                      <CheckCircleFilled style={{ color: 'green' }} />
                    </Tooltip>
                  )}
                </Button>
              ))}
            </div>
          ) : (
            <div className={styles.loader}>
              <Spin size="large" />
            </div>
          )}
          {!isDesigner && (
            <Form.Item
              name="productName"
              label="Product Name"
              className={styles.productNameField}
              rules={[
                {
                  required: true,
                  message: 'Please enter the product name',
                },
              ]}
            >
              <Input
                placeholder="Recommended: {Merch Name} {Athlete Name} {Product Type}"
                style={{ width: 350 }}
              />
            </Form.Item>
          )}
          <Form.Item
            name="hashtags"
            label="Tagging"
            className={styles.tagsInput}
          >
            <Controller
              name="hashtags"
              defaultValue={hashtags}
              control={control}
              render={() => (
                <TagsInput
                  hashtags={hashtags || []}
                  onChange={(val: any) => handleHashtagsChange(val)}
                  name="hashtags"
                />
              )}
            />
          </Form.Item>
        </header>

        <main className={styles.main}>
          <div className={styles.genderAndColorPicker}>
            <div className={styles.genderContainer}>
              {productType && renderGenderOptions()}
            </div>
            <div className={styles.colorSelector}>
              <div className={styles.addColorContainer}>
                <PlusCircleOutlined
                  className={styles.addColorIcon}
                  onClick={toggleColorSelector}
                />
                Add color
              </div>
              {renderColorOptions()}
            </div>
            {hasExtraColorOptions && renderExtraColorOptions()}
          </div>
          <div className={styles.body}>
            {imageProcessing && (
              <div className={styles.loader}>
                <Spin size="large" />
              </div>
            )}
            <div className={styles.placementAndCanvasWrapper}>
              <div className={styles.selectedPlacementWrapper}>
                <h2 className={styles.selectedPlacementWrapperTitle}>
                  {selectedPlacement.replace(/_/g, ' ')}
                </h2>
              </div>

              <div className={styles.canvasAndPlacementWrapper}>
                <div className={styles.canvasWrapper} ref={canvasBlockRef}>
                  {refreshLoading || !defaultTemplate ? (
                    <Spin size="large" />
                  ) : (
                    <div
                      style={{
                        backgroundColor: isCanvasMount
                          ? backgroundCanvasColor || 'transparent'
                          : 'transparent',
                      }}
                    >
                      <div className={styles.selectedColors}>
                        <div
                          className={cn(
                            styles.colorOptions,
                            styles.pickedColors,
                            {
                              [styles.rashguardColorOptions]:
                                isRashGuardConstructor,
                            }
                          )}
                        >
                          {buildData.map((item, index) => {
                            const pRProductColor = colorOptions.find(
                              (color) => color.title === item.colorTitle
                            );
                            const isChecked = currentIndex === index;

                            // show errors for colors not currently selected
                            const isError = !isChecked && item.hasError;

                            if (item.gender === gender) {
                              return (
                                <div
                                  key={item.colorTitle + '_' + index}
                                  className={styles.pickedColorOptionWrapper}
                                >
                                  <button
                                    className={cn(styles.colorOption, {
                                      [styles.checked]: isChecked,
                                      [styles.errorOption]: isError,
                                    })}
                                    title={item.colorTitle}
                                    style={computeMerchBackgroundColor(
                                      pRProductColor?.color || '',
                                      pRProductColor?.color2
                                    )}
                                    onClick={() => handleMerchColorClick(index)}
                                  />

                                  <CloseOutlined
                                    size={30}
                                    onClick={() => removeColor(index)}
                                    className={styles.cancelButton}
                                  />
                                </div>
                              );
                            }

                            return null;
                          })}
                        </div>
                      </div>

                      <PrintCanvas
                        defaultTemplate={defaultTemplate}
                        printPlacements={printFile}
                        mockupSize={mockupSize}
                        ref={canvasBlockRef}
                        isConstructorMode
                        isEditMode={isEditMode}
                        withLogoPlaceholder={withLogoPlaceholder}
                        backgroundImage={isValidGender ? backgroundImage : null}
                        printImage={isValidGender ? selectedPrintImage : null}
                        onSetPrintCoordinates={handlePrintCoordinatesSet}
                        onComputeCoordinates={
                          handleComputeCoordinatesLoadingChange
                        }
                        threadColorsOptions={threadColor}
                        onMount={setIsCanvasMount}
                        isRashGuard={isRashGuardConstructor}
                        onObjectMoved={setIsChanged}
                      />
                    </div>
                  )}
                </div>
                <div>
                  {!isHatConstructor && (
                    <PrintPlacements
                      currentPrintPlacement={selectedPlacement}
                      selectedPrintPlacements={
                        currentItem?.printPlacements || []
                      }
                      availablePrintPlacements={productPrintPlacements || null}
                      onChangePrintSide={handlePrintSideChange}
                      onRemovePrintSide={handlePrintSideRemove}
                      platformMargin={platformMargin}
                      isJoggersConstructor={isJoggersConstructor}
                    />
                  )}
                </div>
              </div>
              <div className={styles.lifestyleTitleAndImageWrapper}>
                <h2 className={styles.lifestyleTitle}>
                  Lifestyle/custom image for {currentItem?.colorTitle ?? null}{' '}
                  color
                </h2>
                <div className={styles.imageWrapper}>
                  {currentItem?.colorTitle === '' && (
                    <div className={styles.customImagesButtonText}>
                      Select a color on the canvas to enable the buttons for
                      custom image upload
                    </div>
                  )}
                  <div
                    className={cn(styles.imageUploader, {
                      [styles.imageUploaderExtended]: customImages?.length,
                    })}
                  >
                    <UploadMultipleImages
                      images={currentCustomImagesList}
                      setImages={handleSetImages}
                      isMainImage={isMainImage}
                      setIsMainImage={handleSetIsMainImage}
                      disabledButtons={!allowImageSelection}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.printImagesWrapper}>
              <PrintImages
                allowSelection={allowImageSelection}
                onSelectPrintImage={handleSelectedPrintImageChange}
                printImage={selectedPrintImage}
                initialPrintImageId={initialPrintImageId}
                extraColorError={extraColorError}
                designRequestId={designRequestId}
              />
            </div>
          </div>
        </main>

        <div className={styles.footer}>
          {isDesigner ? (
            <div className={styles.profitBlocks}>
              <Button
                className={styles.finishButton}
                disabled={isFinishButtonDisabled}
                onClick={handleFinishButtonClick}
                loading={finishButtonLoading}
                color="harvest-gold"
                size="small"
              >
                {productId ? 'Update' : 'Publish'}
              </Button>
            </div>
          ) : (
            <div className={styles.profitBlocks}>
              <div className={cn(styles.profitItem, styles.fee)}>
                <span className={styles.priceLabel}>Product price</span>
                <span className={styles.priceValue}>
                  {formatPrice(estimate?.baseTotal)}
                </span>
              </div>
              <div className={cn(styles.profitItem, styles.profit)}>
                <span className={styles.priceLabel}>Your profit</span>
                <Form.Item
                  name="profit"
                  rules={[
                    {
                      required: true,
                      type: 'number',
                      message: 'Please enter the price',
                    },
                  ]}
                  noStyle
                >
                  <InputNumber
                    placeholder="$00.00"
                    className={cn(
                      styles.input,
                      styles.profit,
                      styles.customInput
                    )}
                  />
                </Form.Item>
              </div>

              <div className={cn(styles.profitItem, styles.fee)}>
                <span className={styles.priceLabel}>Platform fee</span>
                <span className={styles.priceValue}>
                  {formatPrice(estimate?.platformFee)}
                </span>
              </div>

              <div>
                <p className={styles.priceValue}>
                  <span className={cn(styles.priceLabel, styles.finalPrice)}>
                    Final price
                  </span>
                  <span className={cn(styles.priceValue, styles.finalPrice)}>
                    {formatPrice(estimate?.finalPrice)}
                  </span>
                </p>
              </div>
              <Button
                className={styles.finishButton}
                disabled={isFinishButtonDisabled}
                onClick={handleFinishButtonClick}
                loading={isFinishButtonLoading}
                color="harvest-gold"
                size="small"
              >
                {productId ? 'Update' : 'Publish'}
              </Button>
            </div>
          )}
        </div>
      </Form>
    </>
  );
};

export default MerchConstructor;
