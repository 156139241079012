import { Button } from 'antd';
import React, { useState } from 'react';
// Components
import StreamPurchasesModal from 'components/common/Stream/StreamPurchasesModal/StreamPurchasesModal';

type StreamNumberOfOrderColumnProps = {
  numberOfOrders: number;
  streamId: string;
  streamName: string | null;
  isFree: boolean;
};

const StreamNumberOfOrdersButton = ({
  numberOfOrders,
  streamId,
  streamName,
  isFree,
}: StreamNumberOfOrderColumnProps): JSX.Element => {
  const [isModalVisible, setModalVisible] = useState(false);
  const handleModalToggle = () => {
    setModalVisible((prev) => !prev);
  };

  return (
    <>
      <Button type="link" onClick={handleModalToggle}>
        {numberOfOrders}
      </Button>
      <StreamPurchasesModal
        streamId={streamId}
        streamName={streamName}
        isFree={isFree}
        isVisible={isModalVisible}
        onCancel={handleModalToggle}
      />
    </>
  );
};

export default StreamNumberOfOrdersButton;
