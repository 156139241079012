import React, { useState } from 'react';
import { Image, Space } from 'antd';
import { useQuery } from '@apollo/client';
// Api
import { GET_USER_STREAM_ORDERS_EXTENDED } from 'api/streams/queries';
// Types
import {
  SortDirection,
  StreamOrderByFilter,
  StreamOrdersQueryInput,
} from 'api/graphql-global-types';
import {
  FetchStreamDataVariables,
  FetchStreamData_getUsersStreamPurchase_entities_stream,
} from 'api/streams/types/FetchStreamData';
import {
  GetUserStreamOrdersExtended,
  GetUserStreamOrdersExtended_getUsersStreamPurchase,
  GetUserStreamOrdersExtended_getUsersStreamPurchase_entities,
  GetUserStreamOrdersExtendedVariables,
} from 'api/streams/types/GetUserStreamOrdersExtended';
// UI
import Table, { SortedInfo, TableFilter } from 'ui/Table';
// Helpers
import { getPublicStreamDate, paymentStatus, statuses } from 'helpers/utils';
import { FALLBACK_IMAGE } from 'constants/global';
// Components
import StreamOrderCancelButton from 'components/common/Stream/StreamOrderCancelButton/StreamOrderCancelButton';

type StreamsListProps = {
  customerId: string;
};

const StreamsList: React.FC<StreamsListProps> = ({ customerId }) => {
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<StreamOrderByFilter>>({
    order: SortDirection.ASC,
    key: StreamOrderByFilter.createdAt,
  });
  const getStreamOrdersInput = (): FetchStreamDataVariables => {
    const input: StreamOrdersQueryInput = {
      customerId: customerId,
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      if (key === 'scheduleDate') {
        return (input['createdAtDateRange'] = {
          from: Array.isArray(value) ? value?.[0] : value,
          to: Array.isArray(value) ? value?.[1] : value,
        });
      }

      (input as any)[key] = value;
    });

    return {
      input: input,
    };
  };

  const extractEntitiesFromData = (
    data: GetUserStreamOrdersExtended
  ): GetUserStreamOrdersExtended_getUsersStreamPurchase => {
    if (data && data.getUsersStreamPurchase) {
      const { total, offset, limit, entities, __typename } =
        data.getUsersStreamPurchase;
      return { total, offset, limit, entities, __typename };
    }
    return {
      total: 0,
      offset: 0,
      limit: 0,
      entities: [],
      __typename: 'StreamPurchasesList',
    };
  };
  const { data, loading } = useQuery<
    GetUserStreamOrdersExtended,
    GetUserStreamOrdersExtendedVariables
  >(GET_USER_STREAM_ORDERS_EXTENDED, {
    variables: { ...getStreamOrdersInput() },
    fetchPolicy: 'cache-and-network',
  });
  const tableData = data ? extractEntitiesFromData(data) : undefined;

  const columns = [
    {
      title: 'Cover',
      dataIndex: 'stream',
      key: StreamOrderByFilter.createdAt,
      align: 'center' as const,
      fixed: 'left' as const,
      sorterType: 'text',
      render: function ImageStream(
        stream: FetchStreamData_getUsersStreamPurchase_entities_stream
      ) {
        return (
          <Image
            width={127}
            src={stream?.imageURL || ''}
            alt="stream"
            fallback={FALLBACK_IMAGE}
          />
        );
      },
    },
    {
      title: 'Streamer',
      dataIndex: ['stream', 'store', 'email'],
      key: StreamOrderByFilter.email,
      align: 'center' as const,
      fixed: 'left' as const,
      sorterType: 'text',
      withSearch: true,
    },
    {
      title: 'Title',
      dataIndex: ['stream', 'name'],
      key: StreamOrderByFilter.name,
      align: 'center' as const,
      sorterType: 'text',
      withSearch: true,
    },
    {
      title: 'Details',
      dataIndex: ['stream', 'description'],
      align: 'left' as const,
    },
    {
      title: 'Event Date',
      dataIndex: 'stream',
      key: StreamOrderByFilter.scheduleDate,
      align: 'center' as const,
      sorterType: 'number',
      withDateRangeFilter: true,
      render: (
        stream: FetchStreamData_getUsersStreamPurchase_entities_stream
      ) => getPublicStreamDate(stream?.scheduleDate, stream?.timeZone),
    },
    {
      title: 'Payment Status',
      dataIndex: StreamOrderByFilter.orderStatus,
      key: StreamOrderByFilter.orderStatus,
      filters: paymentStatus,
      filterMultiple: true,
      withCheckboxFilters: true,
      align: 'center' as const,
      sorterType: 'text',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      align: 'center' as const,
    },
    {
      title: 'Event Status',
      dataIndex: ['stream', 'streamStatus'],
      key: StreamOrderByFilter.streamStatus,
      filters: statuses,
      filterMultiple: true,
      withCheckboxFilters: true,
      align: 'center' as const,
      sorterType: 'text',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 130,
      render: function Actions(
        _: any,
        record: GetUserStreamOrdersExtended_getUsersStreamPurchase_entities
      ) {
        return (
          <Space size="middle" direction="vertical">
            {!record.stream.isFree && (
              <StreamOrderCancelButton
                orderStatus={record.orderStatus}
                streamOrderId={record.id}
              />
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <Table<
      GetUserStreamOrdersExtended_getUsersStreamPurchase_entities,
      StreamOrderByFilter
    >
      columns={columns}
      data={tableData?.entities}
      scroll={{ x: 1280 }}
      loading={loading}
      sortInfo={sort}
      setSortInfo={setSortInfo}
      total={tableData?.total}
      defaultPageSize={pageSize}
      defaultCurrent={currentPage}
      setPageSize={setPageSize}
      setCurrentPage={setCurrentPage}
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
    />
  );
};

export default StreamsList;
