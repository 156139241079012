import React, { FC } from 'react';
import { useMutation } from '@apollo/client';
import { Divider, Typography, Form, Button, Input } from 'antd';
// API
import { CREATE_STREAM_NOTE } from 'api/streams/mutations';
// Types
import { GetAdminWatchStreams_adminStreams_entities_streamRecords } from 'api/streams/types/GetAdminWatchStreams';
import {
  CreateStreamNote,
  CreateStreamNoteVariables,
} from 'api/streams/types/CreateStreamNote';
// UI
import { successNotification, errorNotification } from 'ui/Notification';

type CreateStreamRecordNoteProps = {
  selectedRecord: GetAdminWatchStreams_adminStreams_entities_streamRecords | null;
  videoRef: any;
  onNewRecordCreate: () => void;
  hideNoteForm: () => void;
};

const { Title } = Typography;

const CreateStreamRecordNote: FC<CreateStreamRecordNoteProps> = ({
  selectedRecord,
  onNewRecordCreate,
  videoRef,
  hideNoteForm,
}) => {
  const [form] = Form.useForm();

  const [createStreamNote, { loading: createStreamNoteLoading }] = useMutation<
    CreateStreamNote,
    CreateStreamNoteVariables
  >(CREATE_STREAM_NOTE);

  const handleNoteCreate = async (values: { note: string }) => {
    if (selectedRecord && videoRef.current) {
      try {
        await createStreamNote({
          variables: {
            input: {
              streamId: selectedRecord.streamId,
              streamRecordId: selectedRecord.id,
              relativeTs: +(videoRef.current.currentTime * 1000).toFixed(0),
              note: values.note,
            },
          },
        });

        form.resetFields();
        hideNoteForm();
        onNewRecordCreate();

        successNotification('You have successfully created a new note');
      } catch (error) {
        errorNotification(
          (error as Error).message || 'Something went wrong while note save'
        );
      }
    } else {
      errorNotification('Something went wrong');
    }
  };

  return (
    <>
      <Divider />

      <Title level={3}>Add a note</Title>

      <Form
        form={form}
        layout="horizontal"
        name="noteForm"
        autoComplete="off"
        onFinish={handleNoteCreate}
      >
        <Form.Item
          name="note"
          rules={[
            {
              required: true,
              message: 'Please enter the note',
            },
          ]}
        >
          <Input placeholder="Make a note" disabled={createStreamNoteLoading} />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={createStreamNoteLoading}
          disabled={createStreamNoteLoading}
          style={{
            marginRight: 10,
          }}
        >
          Confirm
        </Button>
        <Button type="primary" danger onClick={hideNoteForm}>
          Cancel
        </Button>
      </Form>
    </>
  );
};

export default CreateStreamRecordNote;
