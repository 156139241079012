import { gql } from '@apollo/client';

export const AdminFields = gql`
  fragment AdminFields on Admin {
    id
    createdAt
    email
    firstName
    lastName
    phoneNumber
    status
  }
`;
