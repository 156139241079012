import React, { useState } from 'react';
import cn from 'classnames';
import Cropper from 'react-easy-crop';

import './styles.scss';

export type CropArea = {
  x: number;
  y: number;
  width: number;
  height: number;
  zoom: number;
};

export type CropVideoNaturalMediaSize = {
  naturalHeight: number;
  naturalWidth: number;
};

type CropVideoProps = {
  videoLink: string | null;
  aspect: number;
  onCropComplete: (
    area: CropArea,
    naturalMediaSize: CropVideoNaturalMediaSize
  ) => void;
  containerClassName?: string;
};

const CropVideo: React.FC<CropVideoProps> = ({
  videoLink,
  aspect,
  onCropComplete,
  containerClassName,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [naturalMediaSize, setNaturalMediaSize] =
    useState<CropVideoNaturalMediaSize>({
      naturalHeight: 0,
      naturalWidth: 0,
    });

  return videoLink ? (
    <Cropper
      video={videoLink}
      crop={crop}
      zoom={zoom}
      aspect={aspect}
      onCropChange={setCrop}
      onZoomChange={setZoom}
      onCropComplete={(croppedArea, croppedAreaPixels) => {
        onCropComplete({ ...croppedAreaPixels, zoom }, { ...naturalMediaSize });
      }}
      onMediaLoaded={setNaturalMediaSize}
      objectFit="contain"
      classes={{
        containerClassName: cn('cropVideoContainer', containerClassName),
      }}
    />
  ) : null;
};

export default CropVideo;
