import React, { useState, useRef, useEffect } from 'react';
import { PlayCircleOutlined } from '@ant-design/icons';
import { Empty, Card, Divider, Typography, Button } from 'antd';
// Types
import { GetAdminWatchStreams_adminStreams_entities_streamRecords } from 'api/streams/types/GetAdminWatchStreams';
import { UserRole } from 'api/graphql-global-types';
// Hooks
import { useAppContext } from 'hooks';
// Helpers
import { computeDuration } from 'helpers/time';
// UI
import VideoPreview from 'ui/VideoPreview/VideoPreview';
// Component
import CreateStreamRecordClip from './CreateStreamRecordClip/CreateStreamRecordClip';
import CreateStreamRecordNote from './CreateStreamRecordNote/CreateStreamRecordNote';
// Styles
import './styles.scss';

type StreamsPlaylistProps = {
  streamCoverImage: string | null | undefined;
  records:
    | GetAdminWatchStreams_adminStreams_entities_streamRecords[]
    | null
    | undefined;
  onNewRecordCreate: () => void;
  noteTime: string | null;
  noteStreamRecord?:
    | GetAdminWatchStreams_adminStreams_entities_streamRecords
    | undefined
    | null;
  clearNoteIndex: () => void;
  handleClipLoading: (loading: boolean) => void;
};

const { Title } = Typography;

const StreamsPlaylist: React.FC<StreamsPlaylistProps> = ({
  streamCoverImage,
  records,
  onNewRecordCreate,
  noteTime,
  noteStreamRecord,
  clearNoteIndex,
  handleClipLoading,
}) => {
  const { authUser } = useAppContext();

  const isModerator = authUser?.role === UserRole.Moderator;
  const isAdmin = authUser?.role === UserRole.Admin;

  const [showNoteForm, setShowNoteForm] = useState<boolean>(false);
  const [showClipForm, setShowClipForm] = useState<boolean>(false);
  const [streamRecord, setStreamRecord] =
    useState<GetAdminWatchStreams_adminStreams_entities_streamRecords | null>(
      noteStreamRecord || records?.[0] || null
    );
  const [createClipLoading, setCreateClipLoading] = useState(false);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const createClipRef = useRef<HTMLElement | null>(null);

  const handleShowNoteForm = () => setShowNoteForm(!showNoteForm);
  const handleShowClipForm = () => {
    setShowClipForm(true);
  };
  const hideNoteForm = () => setShowNoteForm(false);
  const hideClipForm = () => setShowClipForm(false);
  const handleCreateClipLoading = (loading: boolean) => {
    setCreateClipLoading(loading);
    handleClipLoading(loading);
  };

  useEffect(() => {
    if (noteStreamRecord) {
      handleShowClipForm();
      setStreamRecord(noteStreamRecord);
    } else {
      hideClipForm();
    }
  }, [noteStreamRecord]);

  useEffect(() => {
    if (createClipRef && createClipRef.current && showClipForm) {
      createClipRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [showClipForm]);

  if (!records?.length) {
    return <Empty />;
  }

  const recordClickHandle = (
    record: GetAdminWatchStreams_adminStreams_entities_streamRecords
  ) => {
    clearNoteIndex();
    hideClipForm();
    setStreamRecord(record);
  };

  return (
    <div className="streams-playlist">
      <VideoPreview
        wrapperClassName="streams-playlist__video"
        video={streamRecord?.videoURL}
        ref={videoRef}
        show
      />

      {records?.length > 1 && (
        <>
          <Divider />
          <Title level={3}>Playlist</Title>
          <div className="streams-playlist__playlist-items-wrapper">
            {records.map((record) => (
              <Card
                key={record.id}
                hoverable
                style={{
                  width: 140,
                  backgroundColor:
                    record?.id === streamRecord?.id ? 'white' : 'grey',
                  ...(record?.id === streamRecord?.id && {
                    backgroundColor: 'white',
                    border: '4px solid #d2ab64',
                  }),
                  ...(record?.id !== streamRecord?.id &&
                    showClipForm && {
                      opacity: 0.5,
                      pointerEvents: 'none',
                    }),
                }}
                bodyStyle={{ padding: '8px', textAlign: 'center' }}
                cover={
                  <img
                    alt={record.videoFileKey}
                    src={record?.videoThumbnailURL || streamCoverImage || ''}
                  />
                }
                onClick={() => recordClickHandle(record)}
              >
                <Title level={5}>
                  <PlayCircleOutlined /> {computeDuration(record?.duration)}
                </Title>
              </Card>
            ))}
          </div>
        </>
      )}

      <Divider />

      {(isAdmin || isModerator) && !showClipForm && (
        <Button
          type="primary"
          htmlType="submit"
          onClick={handleShowClipForm}
          loading={createClipLoading}
          disabled={createClipLoading}
          style={{
            marginRight: 10,
          }}
        >
          Add Clip
        </Button>
      )}

      {isModerator && !showNoteForm && (
        <Button
          type="primary"
          htmlType="submit"
          onClick={handleShowNoteForm}
          loading={createClipLoading}
          disabled={createClipLoading}
        >
          Add Note
        </Button>
      )}

      {(isAdmin || isModerator) && (
        <CreateStreamRecordClip
          streamRecord={streamRecord}
          onNewRecordCreate={onNewRecordCreate}
          showClipForm={showClipForm}
          hideClipForm={hideClipForm}
          noteTime={noteTime}
          clearNoteIndex={clearNoteIndex}
          createClipRef={createClipRef}
          setCreateClipLoading={handleCreateClipLoading}
        />
      )}

      {isModerator && showNoteForm && (
        <CreateStreamRecordNote
          videoRef={videoRef}
          selectedRecord={streamRecord}
          onNewRecordCreate={onNewRecordCreate}
          hideNoteForm={hideNoteForm}
        />
      )}
    </div>
  );
};

export default StreamsPlaylist;
