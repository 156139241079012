import { GetStoresPromoCodePayload_getStoresPromoCodePayload } from 'api/promocodes/types/GetStoresPromoCodePayload';
import { TreeDataConverter } from '../../converter/tree-data.converter';
import { TreeDataEntity } from '../../data/tree-data.entity';

export const storesPayloadMapper = ({
  products,
  amas,
  streams,
}: GetStoresPromoCodePayload_getStoresPromoCodePayload): TreeDataEntity[] => {
  let result: TreeDataEntity[] = [];

  const productsData = products.map((p) => TreeDataConverter.from(p));
  if (productsData.length) {
    result = result.concat([
      {
        title: 'Merch Products',
        value: 'merch_products',
        children: productsData,
      },
    ]);
  }

  const amasData = amas.map((a) => TreeDataConverter.from(a));
  if (amasData.length) {
    result = result.concat([
      {
        title: 'AMAs',
        value: 'store_amas',
        children: amasData,
      },
    ]);
  }

  const streamsData = streams.map((s) => TreeDataConverter.from(s));
  if (streamsData.length) {
    result = result.concat([
      {
        title: 'Streams',
        value: 'store_streams',
        children: streamsData,
      },
    ]);
  }

  return result;
};
