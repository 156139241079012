import moment from 'moment';

export const computeDuration = (
  durationTime: number | null | undefined
): string => {
  const durationInSeconds = durationTime || 0;
  const durationMoment = moment.duration(durationInSeconds, 'seconds');
  const formatString = durationInSeconds >= 3600 ? 'HH:mm:ss' : 'mm:ss';

  return moment.utc(durationMoment.asMilliseconds()).format(formatString);
};
