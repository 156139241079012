import React from 'react';
import { useMutation } from '@apollo/client';
import { Button } from 'antd';
// Api
import { CHANGE_DESIGN_PRIORITY } from 'api/designLab/mutations';
// Types
import { DesignRequestsFormattedData } from 'helpers/designRequests';
import { DesignPriorityType } from 'api/graphql-global-types';
import {
  ChangeMerchDesignPriorityVariables,
  ChangeMerchDesignPriority,
} from 'api/designLab/types/ChangeMerchDesignPriority';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';

// Styles
import styles from './ChangeDesignPriority.module.scss';

type ChangeDesignPriorityProps = {
  request: DesignRequestsFormattedData;
  priority: DesignPriorityType;
  designerId?: string;
};

const ChangeDesignPriority = ({
  request,
  priority,
  designerId,
}: ChangeDesignPriorityProps): JSX.Element | null => {
  const [changeDesignPriority, { loading: changeDesignPriorityLoading }] =
    useMutation<ChangeMerchDesignPriority, ChangeMerchDesignPriorityVariables>(
      CHANGE_DESIGN_PRIORITY
    );

  const { id: designRequestId } = request;

  const onChangeDesignPriority = async () => {
    try {
      await changeDesignPriority({
        variables: {
          input: {
            designRequestId: designRequestId,
            designPriorityType:
              priority === DesignPriorityType.High
                ? DesignPriorityType.Regular
                : DesignPriorityType.High,
          },
        },
      });

      successNotification(
        `Request #${designRequestId} has had it's priority changed`
      );
    } catch (error) {
      errorNotification((error as Error)?.message || 'Something went wrong');
      console.log('onChangeDesignPriority error:', error);
    }
  };

  return (
    <>
      {designerId && <div>{priority}</div>}
      {!designerId && (
        <Button
          className={styles.linkButton}
          type="text"
          onClick={onChangeDesignPriority}
          loading={changeDesignPriorityLoading}
        >
          {priority}
        </Button>
      )}
    </>
  );
};

export default ChangeDesignPriority;
