import React, { useState } from 'react';
import { Form, Select, Typography, Space, Button, Spin } from 'antd';
// Api
import { ADD_CONNECTED_ACCOUNT_AND_GENERATE_LINK } from 'api/store/mutations';
import { useMutation } from '@apollo/client';
// Types
import { GetAdminStores_adminStores_entities } from 'api/store/types/GetAdminStores';
// Helpers
import getEnvVariables from 'api/env';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';

type selectItemType = {
  value: string;
  label: string;
};

type selectItemsType = selectItemType[];

const ACCOUNT_TYPE: selectItemsType = [
  {
    value: 'Individual',
    label: 'Individual',
  },
  { value: 'Company', label: 'Company' },
  { value: 'Government_entity', label: 'Government_entity' },
  { value: 'Non_profit', label: 'Non_profit' },
];

const COUNTRY: selectItemsType = [
  {
    value: 'CA',
    label: 'Canada',
  },
  { value: 'US', label: 'United States' },
];

const { Option } = Select;
const { Text } = Typography;

type ConnectFormValues = {
  accountType: string;
  country: string;
};

type ConnectFormProps = {
  record: GetAdminStores_adminStores_entities & { storeId?: string };
};

const ConnectForm = ({ record }: ConnectFormProps): JSX.Element => {
  const initialFormValues: ConnectFormValues = {
    accountType: 'Individual',
    country: 'US',
  };

  const [form] = Form.useForm();
  // eslint-disable-next-line
  const [formFields, setFormFields] =
    useState<ConnectFormValues>(initialFormValues);

  const setFields = () => {
    const fields: ConnectFormValues = form.getFieldsValue();

    setFormFields({
      ...fields,
    });
  };

  const [addConnectedAccountAndGenerateLink, { loading }] = useMutation(
    ADD_CONNECTED_ACCOUNT_AND_GENERATE_LINK
  );

  const handleRedirectToStripe = (url: string) => {
    window.location.href = url;
  };
  const url = `${getEnvVariables.REACT_APP_URL}`;

  const handleConnect = async () => {
    try {
      const { data } = await addConnectedAccountAndGenerateLink({
        variables: {
          input: {
            userId: record?.storeId,
            businessType: formFields.accountType,
            refreshUrl: url,
            returnUrl: url,
            country: formFields.country,
          },
        },
      });

      successNotification(`link generated successfully `);
      handleRedirectToStripe(data.addConnectedAccountAndGenerateLink.url);
    } catch (err: any) {
      errorNotification(err?.message);
    }
  };

  return (
    <Space direction="vertical" size="middle" align="center">
      <Text strong>
        After you submit this form you will be redirected to stripe{' '}
      </Text>

      <Form
        form={form}
        layout="horizontal"
        name="setupStream"
        initialValues={initialFormValues}
        autoComplete="off"
        onValuesChange={setFields}
        onLoad={setFields}
        onFinish={handleConnect}
      >
        <Form.Item
          className="connectFormInput"
          name="accountType"
          label={<Text>Account Type</Text>}
          rules={[
            {
              required: true,
              message: 'select account type',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select account type"
            optionFilterProp="children"
          >
            {ACCOUNT_TYPE.map(({ value, label }) => {
              return (
                <Option key={label} value={value}>
                  {label}
                </Option>
              );
            })}
          </Select>
          <Form.Item
            className="connectFormInput"
            name="country"
            label={<Text>Country</Text>}
            rules={[
              {
                required: true,
                message: 'select country',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select country"
              optionFilterProp="children"
            >
              {COUNTRY.map(({ value, label }) => {
                return (
                  <Option key={label} value={value}>
                    {label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form.Item>

        {loading ? (
          <Spin size="large" />
        ) : (
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        )}
      </Form>
    </Space>
  );
};

export default ConnectForm;
