import { gql } from '@apollo/client';

export const SETUP_HOME_PAGE_DATA = gql`
  mutation SetupHomePageData($input: HomePageDataSetupType!) {
    setupHomePageData(input: $input) {
      stores {
        id
      }
      amas {
        id
      }
      merch {
        id
      }
      watchStreams {
        id
      }
      mainBanners {
        id
        image {
          id
          url
        }
        video {
          id
          url
        }
        content
      }
      ctaBanners {
        id
        image {
          id
          url
        }
        content
      }
      athleteStories {
        id
        description
        title
        link
        storeId
      }
    }
  }
`;

export const ADMIN_CREATE_GALLERY_IMAGE = gql`
  mutation AdminCreateGalleryImage($input: AdminCreateGalleryImageInput!) {
    adminCreateGalleryImage(input: $input) {
      id
      url
    }
  }
`;

export const ADMIN_DELETE_GALLERY_IMAGE = gql`
  mutation AdminDeleteGalleryImage($input: AdminDeleteGalleryImageInput!) {
    adminDeleteGalleryImage(input: $input) {
      id
      url
    }
  }
`;

export const ADMIN_CREATE_GALLERY_VIDEO = gql`
  mutation AdminCreateGalleryVideo($input: AdminCreateGalleryVideoInput!) {
    adminCreateGalleryVideo(input: $input) {
      id
      url
    }
  }
`;

export const ADMIN_DELETE_GALLERY_VIDEO = gql`
  mutation AdminDeleteGalleryVideo($input: AdminDeleteGalleryVideoInput!) {
    adminDeleteGalleryVideo(input: $input) {
      id
      url
    }
  }
`;
