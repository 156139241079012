import React from 'react';
import { useQuery } from '@apollo/client';
import { Typography, Space } from 'antd';
// Hooks
import { useAppContext } from 'hooks';
// Api
import { GET_VIDEO_EDITOR } from 'api/videoLab/queries';
// Types
import {
  GetVideoEditor,
  GetVideoEditorVariables,
} from 'api/videoLab/types/GetVideoEditor';

const { Paragraph } = Typography;

const VideoEditorProfile = (): JSX.Element => {
  const { authUser } = useAppContext();

  const { data: videoEditorData } = useQuery<
    GetVideoEditor,
    GetVideoEditorVariables
  >(GET_VIDEO_EDITOR, {
    variables: {
      id: `${authUser?.id}`,
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <>
      <Space>
        <Paragraph strong>First name:</Paragraph>
        <Paragraph>{videoEditorData?.getVideoEditor.firstName || ''}</Paragraph>
      </Space>

      <Space>
        <Paragraph strong>Last name:</Paragraph>
        <Paragraph>{videoEditorData?.getVideoEditor.lastName || ''}</Paragraph>
      </Space>

      <Space>
        <Paragraph strong>Email:</Paragraph>
        <Paragraph>{videoEditorData?.getVideoEditor.email || ''}</Paragraph>
      </Space>
    </>
  );
};

export default VideoEditorProfile;
