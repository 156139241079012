import { gql } from '@apollo/client';

export const UserListRequestFields = gql`
  fragment UserListRequestFields on UserList {
    entities {
      id
      status
      lastName
      firstName
      email
      phoneNumber
      role
      isOnboarded
      createdAt
      hasAma
      hasMerch
      hasStreams
      storeDetails {
        id
        storeName
        gymRole
        socialMediaLink
      }
      sports {
        id
        name
      }
      purchaseStats {
        customerTotalCount
        customerRevenue
        storeTotalCount
        storeRevenue
        favouriteStore {
          id
          firstName
          lastName
        }
      }
      addresses {
        id
        countryCode
        country
        city
        stateCode
        state
        addressLine1
        addressLine2
        firstName
        lastName
        phone
        email
        zipCode
        isDefault
      }
      paymentMethods {
        id
        type
        last4
        expMonth
        expYear
        methodId
        isDefault
        cardholderName
      }
      outcomingAmaRequests {
        id
        amaId
        instructions
        orderStatus
        declineReason
        paymentMethodId
        dateToResponse
        customerId
        email
        stripeIntentClientSecret
        stripeIntentID
        createdAt
        store {
          id
          firstName
          lastName
        }
        amaVideoURL
      }
      outcomingStreamOrders {
        id
        stream {
          id
          description
          name
          store {
            id
            email
          }
          price
          requestedPrice
          scheduleDate
          timeZone {
            tzCode
            offset
          }
          streamStatus
          slug
          isAvailable
          imageURL
          isPurchased
        }
        stripeIntentClientSecret
        orderStatus
        price
        paymentMethodId
        createdAt
      }
      outcomingMerchOrders {
        id
        merchOrderItems {
          id
          merchProduct {
            id
            title
            gender
          }
          price
          amount
        }
        printfulShipment {
          id
          trackingUrl
        }
        createdAt
        status
        stripeIntentID
        stripeIntentClientSecret
        printfulOrderId
        addressLine1
        addressLine2
        zipCode
        name
        countryCode
        country
        city
        stateCode
        state
        subtotal
        shipping
        total
      }
    }
    total
    offset
  }
`;

export const UserSettingsRequestFields = gql`
  fragment UserSettingsRequestFields on User {
    id
    lastName
    firstName
    email
    phoneNumber
  }
`;

export const UserAddressRequestFields = gql`
  fragment UserAddressRequestFields on Address {
    id
    countryCode
    country
    city
    stateCode
    state
    addressLine1
    addressLine2
    firstName
    lastName
    phone
    email
    zipCode
    isDefault
  }
`;
