import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, FilterOutlined } from '@ant-design/icons';
import {
  Space,
  Radio,
  Input,
  InputNumber,
  Select,
  Button,
  Checkbox,
  DatePicker,
  Typography,
  Table as AntTable,
} from 'antd';
// Constants
import { LAYOUT_ID } from 'ui/LayoutWrapper/LayoutWrapper';
// Types
import { TableProps } from 'antd/es/table';
import { SortDirection } from 'api/graphql-global-types';
import { ColumnFilterItem } from 'antd/lib/table/interface';
import { ColumnType, ColumnGroupType } from 'antd/es/table/interface';
import { GetAdmins } from 'api/admin/types/GetAdmins';
// Helpers
import { renderedCheckForBooleans } from 'helpers/utils';

import './styles.scss';

const { Title, Text } = Typography;

type CustomColumnsProps<OrderBy> = {
  dataIndex: string | string[];
  key?: OrderBy | string;
  dateFormat?: string;
  sorterType?: string;
  filters?: TableColumnFilterItem[];
  defaultValues?: DateRangeFilterValue[];
  defaultNumericalValues?: number[];
  withSearch?: boolean;
  /**
   * Use Client's renderer instead of default highlighter when withSearch=true
   */
  forceSearchRender?: boolean;
  withAdminSearch?: boolean;
  adminSearchData?: GetAdmins;
  withRadioFilters?: boolean;
  withCheckboxFilters?: boolean;
  withDateRangeFilter?: boolean;
  withNumericalRangeFilter?: boolean;
  withFloatRangeFilter?: boolean;
};

export type Column<RecordType, OrderBy> =
  | (ColumnGroupType<RecordType> & CustomColumnsProps<OrderBy>)
  | (ColumnType<RecordType> & CustomColumnsProps<OrderBy>);

type CustomTableProps<RecordType, OrderBy> = {
  columns: Column<RecordType, OrderBy>[];
  data?: RecordType[] | null | undefined;
  defaultCurrent?: number;
  total?: number;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  setPageSize?: React.Dispatch<React.SetStateAction<number>>;
  searchFilters?: TableFilter[];
  setSearchFilters?: React.Dispatch<React.SetStateAction<TableFilter[]>>;
  setPageAndSize?: (page: number, size: number) => void;
  defaultPageSize?: number;
  scrollToTopOnPagination?: boolean;
  sortInfo?: SortedInfo<OrderBy>;
  setSortInfo?: Dispatch<SetStateAction<SortedInfo<OrderBy>>>;
  onRowClick?: (record: RecordType) => void;
};

interface FilterDropdownProps {
  setSelectedKeys: any;
  selectedKeys: any;
  confirm: any;
  clearFilters: any;
}

export type FilterValue = string | number | boolean;

export type DateRangeFilterValue = Moment | null;

// export type TableFilter<keyType> = {
export type TableFilter = {
  key: string;
  value: FilterValue | FilterValue[] | DateRangeFilterValue[];
};

export type TableColumnFilterItem = ColumnFilterItem & {
  title?: string;
  subItems?: ColumnFilterItem[];
};

export type SortedInfo<OrderBy> = {
  order: SortDirection.DESC | SortDirection.ASC;
  key: OrderBy;
};

export const GQL_TO_TABLE_ORDER_TYPE = {
  [SortDirection.DESC]: 'descend',
  [SortDirection.ASC]: 'ascend',
};

export const DEFAULT_NUMBER_ITEMS_PER_PAGE = 10;
export const DEFAULT_CURRENT_PAGE = 1;

export const Table = <
  RecordType extends Record<keyof RecordType, unknown>,
  OrderBy extends string = ''
>({
  columns,
  data,
  rowKey = 'id',
  scroll,
  loading,
  defaultCurrent = DEFAULT_CURRENT_PAGE,
  total = 0,
  setCurrentPage,
  setPageSize,
  searchFilters,
  setSearchFilters,
  defaultPageSize,
  setPageAndSize,
  scrollToTopOnPagination = true,
  sortInfo,
  setSortInfo,
  onRowClick,
  pagination,
}: TableProps<RecordType> &
  CustomTableProps<RecordType, OrderBy>): JSX.Element => {
  const [searchText, setSearchText] = useState<string>('');
  const [filterValues, setFilterValues] = useState<TableFilter[]>([]);
  const [searchedColumn, setSearchedColumn] = useState<string | string[]>('');
  const [resetKey, setResetKey] = useState<number>(0);

  useEffect(() => {
    if (searchFilters?.length) {
      setFilterValues([...filterValues, ...searchFilters]);
    }
    // eslint-disable-next-line
  }, []);

  const getValue = (record: any, key: any) => {
    if (typeof key === 'string') return record[key];
    let data = record;
    key.forEach((el: any) => {
      data = data?.[el];
    });
    return data;
  };

  const handleReset = (clearFilters: any, key: string) => {
    clearFilters();
    setSearchText('');
    setFilterValues([...(filterValues?.filter((i) => i.key !== key) || [])]);
    setResetKey((prev) => prev + 1);

    if (searchFilters && setSearchFilters) {
      const newFilters = searchFilters?.filter((i) => i.key !== key) || [];
      setSearchFilters([...newFilters]);
    }
  };

  const handleFilter = (
    selectedKeys: string[],
    confirm: any,
    key: string,
    isRadio?: boolean
  ) => {
    const getNewFilters = (oldFilters?: TableFilter[]) => {
      const newFilters: TableFilter[] =
        oldFilters?.filter((i) => i.key !== key) || [];
      newFilters.push({
        key,
        value: isRadio ? selectedKeys[0] : selectedKeys,
      });

      return newFilters;
    };

    confirm();
    setFilterValues([...getNewFilters(filterValues)]);
    setSearchedColumn(key);
    setSearchFilters && setSearchFilters([...getNewFilters(searchFilters)]);
    setResetKey((prev) => prev + 1);
  };

  const getColumnSortProps = (
    column: Column<RecordType, OrderBy>,
    sortInfo?: SortedInfo<OrderBy>
  ) => {
    const key: string = Array.isArray(column.dataIndex)
      ? column.dataIndex?.[column.dataIndex?.length - 1]
      : column.dataIndex;

    const onHeaderCell = () => {
      return sortInfo && setSortInfo
        ? {
            onClick: () => {
              const order =
                sortInfo.order === SortDirection.DESC
                  ? SortDirection.ASC
                  : SortDirection.DESC;
              setSortInfo({ order, key: column.key as OrderBy });
            },
          }
        : null;
    };

    const numberSort = (a: any, b: any) => getValue(a, key) - getValue(b, key);

    const textSort = (a: any, b: any) =>
      getValue(a, key) < getValue(b, key)
        ? -1
        : getValue(a, key) > getValue(b, key)
        ? 1
        : 0;

    const booleanSort = (a: any, b: any) =>
      getValue(a, key) === getValue(b, key) ? 0 : getValue(a, key) ? -1 : 1;

    const dateSort = (a: any, b: any) =>
      moment(getValue(a, key)).diff(moment(getValue(b, key)));

    const handleSort = (a: any, b: any, sorterType?: string) => {
      const sort =
        sorterType === 'number'
          ? numberSort
          : sorterType === 'text'
          ? textSort
          : sorterType === 'boolean'
          ? booleanSort
          : sorterType === 'date'
          ? dateSort
          : null;
      return sort && sort(a, b);
    };

    return {
      onHeaderCell,
      sortDirection: sortInfo
        ? sortInfo.key === key && GQL_TO_TABLE_ORDER_TYPE[sortInfo.order]
        : null,
      sorter: (a: any, b: any) => {
        handleSort(a, b, column.sorterType);
      },
    };
  };

  const getAdminColumnSearchProps = (column: Column<RecordType, OrderBy>) => {
    const { dataIndex, key = '' } = column;

    const dataIndexKey: string = Array.isArray(dataIndex)
      ? dataIndex?.[dataIndex?.length - 1]
      : dataIndex;

    const handleSearch = (selectedOption: string, confirm: any) => {
      confirm();
      setSearchText(selectedOption);
      setSearchedColumn(dataIndexKey);
      setSearchFilters &&
        setSearchFilters([
          ...(searchFilters || []),
          { key: key, value: selectedOption },
        ]);
      setResetKey((prev) => prev + 1);
    };

    return {
      filterDropdown: function FilterDropdownComponent({
        confirm,
        clearFilters,
      }: FilterDropdownProps) {
        return (
          <div className="basic-table__search-wrapper">
            <Select
              className="basic-table__search-input"
              placeholder={`Search by ${dataIndexKey}`}
              showSearch
              optionFilterProp="children"
              onChange={(e) => {
                handleSearch(e, confirm);
              }}
            >
              {column.adminSearchData?.admins.entities.map(({ id, email }) => {
                return (
                  <Select.Option key={id} value={id}>
                    {email}
                  </Select.Option>
                );
              })}
            </Select>
            <Space>
              <Button
                onClick={() => handleReset(clearFilters, key)}
                size="small"
              >
                Reset
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: function FilterIconComponent(filtered: boolean) {
        return (
          <SearchOutlined
            className={filtered ? 'basic-table__search-icon_is-active' : ''}
          />
        );
      },
      onFilter: (value: any, record: any) => {
        const dataEl = getValue(record, dataIndex);
        return (
          dataEl?.toString()?.toLowerCase()?.includes(value.toLowerCase()) || ''
        );
      },
    };
  };

  const getColumnSearchProps = (column: Column<RecordType, OrderBy>) => {
    const { dataIndex, key = '' } = column;

    let searchInput: any;

    const dataIndexKey: string = Array.isArray(dataIndex)
      ? dataIndex?.[dataIndex?.length - 1]
      : dataIndex;

    const handleSearch = (selectedKeys: string[], confirm: any) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndexKey);
      setSearchFilters &&
        setSearchFilters([
          ...(searchFilters || []),
          { key: key, value: selectedKeys[0] },
        ]);
      setResetKey((prev) => prev + 1);
    };

    return {
      filterDropdown: function FilterDropdownComponent({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) {
        return (
          <div className="basic-table__search-wrapper">
            <Input
              ref={(node) => (searchInput = node)}
              className="basic-table__search-input"
              placeholder={`Search ${dataIndexKey}`}
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                handleSearch(selectedKeys, confirm);
              }}
            />

            <Space>
              <Button
                type="primary"
                onClick={() => {
                  handleSearch(selectedKeys, confirm);
                }}
                icon={<SearchOutlined />}
                size="small"
              >
                Search
              </Button>

              <Button
                onClick={() => handleReset(clearFilters, key)}
                size="small"
              >
                Reset
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: function FilterIconComponent(filtered: boolean) {
        return (
          <SearchOutlined
            className={filtered ? 'basic-table__search-icon_is-active' : ''}
          />
        );
      },
      onFilter: (value: any, record: any) => {
        const dataEl = getValue(record, dataIndex);
        return (
          dataEl?.toString()?.toLowerCase()?.includes(value.toLowerCase()) || ''
        );
      },
      onFilterDropdownVisibleChange: (visible: boolean) =>
        visible ? setTimeout(() => searchInput.select(), 100) : null,
      render: column.forceSearchRender
        ? column.render
        : function HighlighterComponent(text: string | boolean) {
            const renderedText = renderedCheckForBooleans(text);
            return searchedColumn === dataIndexKey ? (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={renderedText}
              />
            ) : (
              renderedText
            );
          },
    };
  };

  const getColumnFilterProps = (
    key = '',
    filters?: TableColumnFilterItem[],
    isRadio?: boolean
  ) => {
    return {
      filterDropdown: function FilterDropdownComponent({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) {
        const groupStyle = {
          maxHeight: '300px',
          overflow: 'scroll',
          width: '100%',
        };
        const itemStyle = {
          display: 'block',
          height: '30px',
          lineHeight: '30px',
          ...(!isRadio && { marginLeft: '8px' }),
        };
        const checkboxValues: FilterValue[] = [];
        filterValues?.forEach((item) => {
          if (item.key === key) {
            Array.isArray(item.value)
              ? item.value.forEach((subItem: any) =>
                  checkboxValues.push(subItem)
                )
              : checkboxValues.push(item.value);
          }
        });

        const haveSubItems = Boolean(
          filters?.some(
            (i: TableColumnFilterItem) =>
              Boolean(i?.title) && Boolean(i?.subItems)
          )
        );
        const ifHaveOptions = (
          list?: TableColumnFilterItem[] | ColumnFilterItem[]
        ): boolean =>
          Boolean(
            list?.length &&
              list?.every((i) => Boolean(i?.value) && Boolean(i?.text))
          );

        const renderRadioItem = (
          index: number,
          item: ColumnFilterItem,
          preIndex?: number
        ) =>
          item?.value && item?.text ? (
            <Radio
              key={`${index}_${preIndex}`}
              style={itemStyle}
              value={item?.value}
            >
              {item?.text}
            </Radio>
          ) : null;

        const renderCheckboxItem = (
          index: number,
          item: ColumnFilterItem,
          preIndex?: number
        ) =>
          item?.value && item?.text ? (
            <Checkbox
              key={[index, preIndex].join('_')}
              style={itemStyle}
              value={item?.value}
              className="basic-table__filter-checkbox-item"
            >
              {item?.text}
            </Checkbox>
          ) : null;

        const renderItemsList = (listType: 'radio' | 'checkbox') =>
          haveSubItems ? (
            filters?.map(
              ({ title, subItems }: TableColumnFilterItem, index) => (
                <div key={index} style={{ margin: '0 0 16px' }}>
                  <Title level={5}>{title}</Title>
                  {!ifHaveOptions(subItems) ? (
                    <Text type="secondary">No options</Text>
                  ) : (
                    subItems?.map((subItem, subIndex) =>
                      listType === 'radio'
                        ? renderRadioItem(subIndex, subItem, index)
                        : renderCheckboxItem(subIndex, subItem, index)
                    )
                  )}
                </div>
              )
            )
          ) : !ifHaveOptions(filters) ? (
            <Text type="secondary">No options</Text>
          ) : (
            filters?.map((item, index) =>
              listType === 'radio'
                ? renderRadioItem(index, item)
                : renderCheckboxItem(index, item)
            )
          );

        const renderRadio = () => (
          <Radio.Group
            style={groupStyle}
            onChange={({ target }) => {
              const newFilters =
                filterValues?.filter((i) => i.key !== key) || [];
              newFilters.push({ key, value: target.value });

              setFilterValues([...newFilters]);
              setSelectedKeys(target.value ? [target.value] : []);
            }}
            value={filterValues?.find((i) => i.key === key)?.value}
          >
            {renderItemsList('radio')}
          </Radio.Group>
        );

        const renderCheckbox = () => (
          <Checkbox.Group
            style={groupStyle}
            onChange={(checkedValues) => {
              const newFilterValues: TableFilter[] = [];
              checkedValues?.forEach((value) => {
                newFilterValues?.push({ key, value });
              });

              setFilterValues(newFilterValues);
              setSelectedKeys(checkedValues);
            }}
            value={checkboxValues}
          >
            {renderItemsList('checkbox')}
          </Checkbox.Group>
        );

        return (
          <div className="basic-table__search-wrapper">
            {isRadio ? renderRadio() : renderCheckbox()}
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  handleFilter(selectedKeys, confirm, key, isRadio);
                }}
                icon={<SearchOutlined />}
                size="small"
              >
                Search
              </Button>

              <Button
                onClick={() => handleReset(clearFilters, key)}
                size="small"
              >
                Reset
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: function FilterIconComponent(filtered: boolean) {
        return (
          <FilterOutlined
            className={filtered ? 'basic-table__search-icon_is-active' : ''}
          />
        );
      },
      onFilter: (value: any, record: any) => {
        return record?.[key] === value;
      },
    };
  };

  const getColumnDateRangeFilterProps = (
    key = '',
    dateFormat = 'MM/DD/YYYY',
    defaultValues?: DateRangeFilterValue[]
  ) => {
    return {
      filterDropdown: function FilterDropdownComponent({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) {
        const filterValue = filterValues?.find((i) => i.key === key)
          ?.value as DateRangeFilterValue[];

        return (
          <div className="basic-table__search-wrapper">
            <DatePicker.RangePicker
              defaultValue={
                defaultValues?.length
                  ? [
                      moment(defaultValues[0], dateFormat),
                      moment(defaultValues[1], dateFormat),
                    ]
                  : null
              }
              format={dateFormat}
              onChange={(dates) => {
                const newFilters =
                  filterValues?.filter((i) => i.key !== key) || [];
                newFilters.push({
                  key,
                  value: [dates?.[0] || null, dates?.[1] || null],
                });

                setFilterValues([...newFilters]);
                setSelectedKeys(dates?.length ? dates : []);
              }}
              value={[
                filterValue?.[0] ? moment(filterValue[0]) : null,
                filterValue?.[1] ? moment(filterValue[1]) : null,
              ]}
            />

            <Space
              style={{
                width: '100%',
                margin: '8px 0 0 0',
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  handleFilter(selectedKeys, confirm, key);
                }}
                icon={<SearchOutlined />}
                size="small"
              >
                Search
              </Button>

              <Button
                onClick={() => handleReset(clearFilters, key)}
                size="small"
              >
                Reset
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: function FilterIconComponent(filtered: boolean) {
        return (
          <FilterOutlined
            className={filtered ? 'basic-table__search-icon_is-active' : ''}
          />
        );
      },
      onFilter: (value: any, record: any) => {
        const range: DateRangeFilterValue[] | undefined = filterValues?.find(
          (i) => i.key === key
        )?.value as DateRangeFilterValue[];
        const from = range?.length
          ? moment(range[0]).format(dateFormat)
          : undefined;
        const to = range?.length
          ? moment(range[1]).format(dateFormat)
          : undefined;
        return range?.length ? moment(record?.[key]).isBetween(from, to) : true;
      },
    };
  };

  const getColumnFloatRangeFilterProps = (
    key = '',
    withNumericalRangeFilter = [0, 5]
  ) => {
    return {
      filterDropdown: function FilterDropdownComponent({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) {
        const filterValue = filterValues?.find((i) => i.key === key)
          ?.value as FilterValue[];

        const [min, setMin] = useState<number>(
          Number(filterValue?.[0]) || withNumericalRangeFilter[0]
        );
        const [max, setMax] = useState<number>(
          Number(filterValue?.[1]) || withNumericalRangeFilter[1]
        );

        const setNewMin = (newValue: any) => {
          const parsedMin = parseFloat(newValue);
          if (parsedMin >= 0) {
            setMin(parsedMin);
          } else {
            setMin(0);
          }
        };

        const setNewMax = (newValue: any) => {
          const parsedMax = parseFloat(newValue);
          if (parsedMax <= 5) {
            setMax(parsedMax);
          } else {
            setMax(5);
          }
        };

        useEffect(() => {
          setSelectedKeys([min, max]);
          // eslint-disable-next-line
        }, [min, max]);

        return (
          <div className="basic-table__search-wrapper">
            <InputNumber
              placeholder="minimum"
              controls={false}
              defaultValue={min}
              value={min || 0}
              min={0}
              onInput={setNewMin}
              type={'number'}
            />

            <div
              style={{
                margin: '10px',
                display: 'inline',
              }}
            >
              to
            </div>

            <InputNumber
              placeholder="maximum"
              controls={false}
              defaultValue={max}
              value={max || 0}
              min={min}
              max={withNumericalRangeFilter[1]}
              onInput={setNewMax}
              type="number"
            />
            <Space
              style={{
                width: '100%',
                margin: '8px 0 0 0',
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  handleFilter(selectedKeys, confirm, key);
                }}
                icon={<SearchOutlined />}
                size="small"
              >
                Search
              </Button>

              <Button
                onClick={() => handleReset(clearFilters, key)}
                size="small"
              >
                Reset
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: function FilterIconComponent(filtered: boolean) {
        return (
          <FilterOutlined
            className={filtered ? 'basic-table__search-icon_is-active' : ''}
          />
        );
      },
    };
  };

  const getColumnNumberRangeFilterProps = (
    key = '',
    defaultNumericalValues = [0, 1]
  ) => {
    return {
      filterDropdown: function FilterDropdownComponent({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) {
        const filterValue = filterValues?.find((i) => i.key === key)
          ?.value as FilterValue[];

        const [min, setMin] = useState<number>(
          Number(filterValue?.[0]) || defaultNumericalValues[0]
        );
        const [max, setMax] = useState<number>(
          Number(filterValue?.[1]) || defaultNumericalValues[1]
        );

        const setNewMin = (newValue: any) => {
          setMin(newValue);
        };

        const setNewMax = (newValue: any) => {
          setMax(newValue);
        };

        useEffect(() => {
          setSelectedKeys([min, max]);
          // eslint-disable-next-line
        }, [min, max]);

        return (
          <div className="basic-table__search-wrapper">
            <InputNumber
              placeholder="minimum"
              controls={false}
              defaultValue={min}
              value={min || 0}
              min={0}
              onInput={setNewMin}
              type={'number'}
            />

            <div
              style={{
                margin: '10px',
                display: 'inline',
              }}
            >
              to
            </div>

            <InputNumber
              placeholder="maximum"
              controls={false}
              defaultValue={max}
              value={max || 0}
              min={min}
              onInput={setNewMax}
              type={'number'}
            />
            <Space
              style={{
                width: '100%',
                margin: '8px 0 0 0',
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  handleFilter(selectedKeys, confirm, key);
                }}
                icon={<SearchOutlined />}
                size="small"
              >
                Search
              </Button>

              <Button
                onClick={() => handleReset(clearFilters, key)}
                size="small"
              >
                Reset
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: function FilterIconComponent(filtered: boolean) {
        return (
          <FilterOutlined
            className={filtered ? 'basic-table__search-icon_is-active' : ''}
          />
        );
      },
    };
  };

  const onPageChange = (page: number, size?: number) => {
    setCurrentPage && setCurrentPage(page);
    setPageSize && setPageSize(size || 0);
    setPageAndSize &&
      setPageAndSize(page, size || DEFAULT_NUMBER_ITEMS_PER_PAGE);
    scrollToTopOnPagination &&
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const onRow = (record: RecordType) => {
    return {
      onClick: () => {
        if (onRowClick) {
          onRowClick(record);
        }
      },
    };
  };

  const tableColumns: any[] =
    columns?.map((column: Column<RecordType, OrderBy>) => {
      const {
        filters,
        sorterType,
        dateFormat,
        defaultValues,
        defaultNumericalValues,
        withSearch,
        withAdminSearch,
        adminSearchData,
        withRadioFilters,
        withCheckboxFilters,
        withDateRangeFilter,
        withNumericalRangeFilter,
        withFloatRangeFilter,
        ...rest
      } = column;

      return {
        ...rest,
        ...(sorterType && getColumnSortProps(column, sortInfo)),
        ...(withSearch && getColumnSearchProps(column)),
        ...(withAdminSearch &&
          adminSearchData &&
          getAdminColumnSearchProps(column)),
        ...(withRadioFilters &&
          getColumnFilterProps(column?.key, filters, true)),
        ...(withCheckboxFilters && getColumnFilterProps(column?.key, filters)),
        ...(withDateRangeFilter &&
          getColumnDateRangeFilterProps(
            column?.key,
            dateFormat,
            defaultValues
          )),
        ...(withNumericalRangeFilter &&
          getColumnNumberRangeFilterProps(column?.key, defaultNumericalValues)),
        ...(withFloatRangeFilter &&
          getColumnFloatRangeFilterProps(column?.key, defaultNumericalValues)),
      };
    }) || [];

  const pageSizeOptions = ['5', '10', '20', '50', '100'];

  return (
    <AntTable
      key={resetKey}
      columns={tableColumns}
      dataSource={data || []}
      rowKey={rowKey}
      loading={loading}
      scroll={scroll || { x: 850 }}
      pagination={
        pagination === false
          ? false
          : {
              onChange: (page, pageSize) => {
                const elementToScroll = document.getElementById(LAYOUT_ID);
                if (elementToScroll && scrollToTopOnPagination) {
                  elementToScroll.scrollTop = 0;
                }
                onPageChange && onPageChange(page, pageSize);
              },
              defaultPageSize: defaultPageSize || DEFAULT_NUMBER_ITEMS_PER_PAGE,
              showSizeChanger: total > 10 || (data ? data?.length > 10 : false),
              pageSizeOptions,
              defaultCurrent,
              total: total || data?.length || 0,
            }
      }
      onRow={onRow}
    />
  );
};

export default Table;
