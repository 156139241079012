export const renderComment = (
  status: string,
  isForAuction?: boolean | null
): string => {
  switch (status) {
    case 'Active':
      return 'Item has been listed';
    case 'Removed':
      if (!isForAuction) {
        return 'Item was removed. You may re-list it from the action column to the right.';
      } else {
        return 'Item was removed.';
      }
    case 'Sold':
      return 'All items have been sold';
    case 'Expired':
      return 'This item is expired. You can list it again from action tab on the right.';
    default:
      return '';
  }
};

export const getDelta = (
  currentNumberOfUnits: number,
  newNumberOfUnits: number
): number => {
  return newNumberOfUnits > currentNumberOfUnits
    ? newNumberOfUnits - currentNumberOfUnits
    : -(currentNumberOfUnits - newNumberOfUnits);
};
