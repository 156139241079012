import React, { useState } from 'react';
import moment from 'moment';
import { Button, Modal } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// Api
import { GET_USERS_AMA_PURCHASE } from 'api/ama/queries';
import { DECLINE_AMA_REQUEST } from 'api/ama/mutations';
// Types
import {
  AmaRequestOrderByFilter,
  AmaRequestQueryInput,
  SortDirection,
} from 'api/graphql-global-types';
import {
  DeclineAmaRequest,
  DeclineAmaRequestVariables,
} from 'api/ama/types/DeclineAmaRequest';
import {
  FetchAmaRequests,
  FetchAmaRequestsVariables,
  FetchAmaRequests_getUsersAmaPurchase,
  FetchAmaRequests_getUsersAmaPurchase_entities,
} from 'api/ama/types/FetchAmaRequests';
// UI
import Table, { SortedInfo, TableFilter } from 'ui/Table';
import { errorNotification, successNotification } from 'ui/Notification';
// Helpers
import { orderStatus } from 'helpers/utils';

type AmaListProps = {
  customerId: string;
};

const { confirm } = Modal;

const AmaList: React.FC<AmaListProps> = ({ customerId }) => {
  const [declineAmaRequest] = useMutation<
    DeclineAmaRequest,
    DeclineAmaRequestVariables
  >(DECLINE_AMA_REQUEST);

  const handleDeclineAmaRequest = async (id: string) => {
    confirm({
      title: `Are you sure you want to cancel this order?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await declineAmaRequest({
            variables: {
              input: { id, declineReason: 'Canceled by service' },
            },
          });
          successNotification('AMA is declined');
        } catch (e) {
          errorNotification();
          console.log('declineAmaRequest error:', { e });
        }
      },
    });
  };

  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<AmaRequestOrderByFilter>>({
    order: SortDirection.ASC,
    key: AmaRequestOrderByFilter.createdAt,
  });
  const getAmaRequestInput = (): FetchAmaRequestsVariables => {
    const input: AmaRequestQueryInput = {
      customerId: customerId,
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      if (key === 'createdAt') {
        return (input['createdAtDateRange'] = {
          from: Array.isArray(value) ? value?.[0] : value,
          to: Array.isArray(value) ? value?.[1] : value,
        });
      }
      (input as any)[key] = value;
    });

    return {
      input: input,
    };
  };

  function extractEntitiesFromData(
    data: FetchAmaRequests
  ): FetchAmaRequests_getUsersAmaPurchase {
    if (data && data.getUsersAmaPurchase) {
      const { total, offset, limit, entities, __typename } =
        data.getUsersAmaPurchase;
      return { total, offset, limit, entities, __typename };
    }
    return {
      total: 0,
      offset: 0,
      limit: 0,
      entities: [],
      __typename: 'AmaRequestList',
    };
  }
  const { data, loading } = useQuery<
    FetchAmaRequests,
    FetchAmaRequestsVariables
  >(GET_USERS_AMA_PURCHASE, {
    variables: { ...getAmaRequestInput() },
    fetchPolicy: 'cache-and-network',
  });
  const tableData = data ? extractEntitiesFromData(data) : undefined;
  const columns = [
    {
      title: 'Order ID',
      dataIndex: AmaRequestOrderByFilter.amaId,
      key: AmaRequestOrderByFilter.amaId,
      align: 'center' as const,
      fixed: 'left' as const,
      sorterType: 'text',
    },
    {
      title: 'Store First Name',
      dataIndex: ['store', 'firstName'],
      key: AmaRequestOrderByFilter.firstName,
      align: 'center' as const,
      fixed: 'left' as const,
      sorterType: 'text',
      withSearch: true,
    },
    {
      title: 'Store Last Name',
      dataIndex: ['store', 'lastName'],
      key: AmaRequestOrderByFilter.lastName,
      align: 'center' as const,
      fixed: 'left' as const,
      sorterType: 'text',
      withSearch: true,
    },
    {
      title: 'Delivery Details',
      dataIndex: ['store', 'email'],
      key: AmaRequestOrderByFilter.email,
      align: 'center' as const,
      sorterType: 'text',
      withSearch: true,
    },
    {
      title: 'Instructions',
      dataIndex: 'instructions',
    },
    {
      title: 'Order Date',
      dataIndex: AmaRequestOrderByFilter.createdAt,
      key: AmaRequestOrderByFilter.createdAt,
      align: 'center' as const,
      sorterType: 'number',
      withDateRangeFilter: true,
      render: function CreatedAtDate(_: any, record: any) {
        return record?.createdAt ? moment(record?.createdAt).format('ll') : '-';
      },
    },
    {
      title: 'Payment Status',
      dataIndex: AmaRequestOrderByFilter.orderStatus,
      key: AmaRequestOrderByFilter.orderStatus,
      filters: orderStatus,
      filterMultiple: true,
      withCheckboxFilters: true,
      align: 'center' as const,
      sorterType: 'text',
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      align: 'center' as const,
      fixed: 'right' as const,
      width: 120,
      render: function CancelButton(_: any, record: any) {
        return (
          <Button
            type="primary"
            danger
            onClick={() => handleDeclineAmaRequest(record.id)}
            disabled={record.orderStatus === 'Declined'}
          >
            Cancel
          </Button>
        );
      },
    },
  ];

  return (
    <Table<
      FetchAmaRequests_getUsersAmaPurchase_entities,
      AmaRequestOrderByFilter
    >
      columns={columns}
      data={tableData?.entities}
      scroll={{ x: 1280 }}
      loading={loading}
      sortInfo={sort}
      setSortInfo={setSortInfo}
      total={tableData?.total}
      defaultPageSize={pageSize}
      defaultCurrent={currentPage}
      setPageSize={setPageSize}
      setCurrentPage={setCurrentPage}
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
    />
  );
};
export default AmaList;
