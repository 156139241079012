import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment, { Moment } from 'moment';
import momentTimezone from 'moment-timezone';
import { useMutation, useQuery } from '@apollo/client';
import { Space, Button, Modal } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import {
  ExclamationCircleOutlined,
  EyeFilled,
  EyeInvisibleFilled,
  VideoCameraOutlined,
} from '@ant-design/icons';
// Api
import {
  CANCEL_STREAM,
  END_STREAM,
  RESET_STREAM,
  DELETE_STREAM,
  RECOVER_STREAM,
  HIDE_STREAM,
  UNHIDE_STREAM,
  UPDATE_STREAM_ADMIN,
} from 'api/streams/mutations';
import { GET_MODERATORS, GET_STREAMS } from 'api/streams/queries';
import { CREATE_UNREGISTERED_CONTACT } from 'api/mentions/mutations';
// Types
import {
  GetModerators,
  GetModeratorsVariables,
} from 'api/streams/types/GetModerators';
import {
  CancelStream,
  CancelStreamVariables,
} from 'api/streams/types/CancelStream';
import {
  ResetStream,
  ResetStreamVariables,
} from 'api/streams/types/ResetStream';
import {
  DeleteStream,
  DeleteStreamVariables,
} from 'api/streams/types/DeleteStream';
import {
  RecoverStream,
  RecoverStreamVariables,
} from 'api/streams/types/RecoverStream';
import { HideStream, HideStreamVariables } from 'api/streams/types/HideStream';
import {
  UnhideStream,
  UnhideStreamVariables,
} from 'api/streams/types/UnhideStream';
import {
  StreamStatus,
  StreamCreateSponsorsInput,
  StreamIntervalEnum,
  SortDirection,
  StreamOrderBy,
  CreateUnregisteredContactInput,
  CreateMentionInput,
} from 'api/graphql-global-types';
import {
  CreateUnregisteredContact,
  CreateUnregisteredContactVariables,
} from 'api/mentions/types/CreateUnregisteredContact';
import {
  UpdateStreamAdmin,
  UpdateStreamAdminVariables,
  UpdateStreamAdmin_updateStreamAdmin_affiliateProducts,
} from 'api/streams/types/UpdateStreamAdmin';
import {
  Streams_adminStreams_entities,
  Streams_adminStreams_entities_mentions,
} from 'api/streams/types/Streams';
import { EndStream, EndStreamVariables } from 'api/streams/types/EndStream';
// Constants
import { WATCH_STREAM_GEN } from 'constants/routes';
// Helpers
import { getTimeZoneName, getCurrentTimeZoneOffset } from 'helpers/timeZone';
import { formatHashtagInput } from 'helpers/hashtags';
import { formatMentionsInput } from 'helpers/mentions';
import { getProperErrorMessage } from 'helpers/errors';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
import { MentionValues } from 'uiShared/Mentions/Mentions';
// Components
import {
  CreateEditFormValues,
  CreateEditStreamForm,
} from 'components/common/CreateEditStreamForm/CreateEditStreamForm';

const { confirm } = Modal;

type ActionsProps = {
  stream: Streams_adminStreams_entities;
};

type FormValues = {
  date: Moment | null;
  tzCode: string | null | undefined;
  picture: UploadFile[] | string | null;
  name: string | null;
  requestedPrice: number | null;
  isFree: boolean;
  repeatsEveryEnum: StreamIntervalEnum | null;
  description: string | null;
  sponsorImage?: UploadFile[] | string | null;
  sponsorName?: string | null;
  sponsorPromoMessage?: string | null;
  moderatorId?: string | null;
  affiliateProducts:
    | UpdateStreamAdmin_updateStreamAdmin_affiliateProducts[]
    | [];
};

const Actions = ({ stream }: ActionsProps): JSX.Element => {
  const {
    id,
    storeId,
    streamStatus,
    deletedAt,
    sponsors,
    requestedPrice,
    isFree,
  } = stream;
  const history = useHistory();

  const [selectedStoreIds, setSelectedStoreIds] = useState<string[]>(
    stream?.participants?.map((item) => item.id) || []
  );

  const { data: moderatorsData } = useQuery<
    GetModerators,
    GetModeratorsVariables
  >(GET_MODERATORS);

  const [updateStream, { loading }] = useMutation<
    UpdateStreamAdmin,
    UpdateStreamAdminVariables
  >(UPDATE_STREAM_ADMIN);

  const [cancelStreamRequest] = useMutation<
    CancelStream,
    CancelStreamVariables
  >(CANCEL_STREAM);

  const [endStream] = useMutation<EndStream, EndStreamVariables>(END_STREAM);

  const [resetStream] = useMutation<ResetStream, ResetStreamVariables>(
    RESET_STREAM
  );

  const [recoverStream] = useMutation<RecoverStream, RecoverStreamVariables>(
    RECOVER_STREAM
  );

  const [deleteStream] = useMutation<DeleteStream, DeleteStreamVariables>(
    DELETE_STREAM
  );

  const [unhideStream] = useMutation<UnhideStream, UnhideStreamVariables>(
    UNHIDE_STREAM
  );

  const [hideStream] = useMutation<HideStream, HideStreamVariables>(
    HIDE_STREAM
  );

  const [createUnregisteredContact] = useMutation<
    CreateUnregisteredContact,
    CreateUnregisteredContactVariables
  >(CREATE_UNREGISTERED_CONTACT);

  const [editModalIsVisible, setEditModalIsVisible] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<Partial<FormValues>>({});

  const handleEditStreamModalToggle = () => {
    setEditModalIsVisible((prev) => !prev);
  };

  const showError = (message?: string) => {
    errorNotification(`Something went wrong.${message ? ` (${message})` : ''}`);
  };

  const onFinish = async (value: CreateEditFormValues) => {
    const timeZoneOffset = momentTimezone(formValues?.date)
      .tz(getTimeZoneName(formValues?.tzCode))
      .utcOffset();

    const scheduleDate = moment(formValues?.date)
      .add(getCurrentTimeZoneOffset(), 'm')
      .toISOString();

    const isSponsorImageModified = Array.isArray(formValues?.sponsorImage);

    const sponsorInput: StreamCreateSponsorsInput[] =
      !formValues?.sponsorImage || !formValues?.sponsorName
        ? []
        : [
            isSponsorImageModified
              ? {
                  name: formValues?.sponsorName || '',
                  imageFile: (
                    (formValues?.sponsorImage?.[0] as UploadFile) || undefined
                  )?.originFileObj,
                  promoMessage: formValues?.sponsorPromoMessage
                    ?.replace(/\s\s+/g, ' ')
                    .trim(),
                }
              : {
                  id: sponsors?.[0]?.id,
                  name: formValues?.sponsorName,
                  logo: sponsors?.[0]?.logoUrl,
                  promoMessage: formValues?.sponsorPromoMessage,
                },
          ];

    const createUnregisteredContactInput =
      (value.mentions as MentionValues[])?.filter(
        (mention) => !mention?.targetType
      ) || [];

    const unregisterContactsData = await createUnregisteredContact({
      variables: {
        input:
          createUnregisteredContactInput as CreateUnregisteredContactInput[],
      },
    });

    const newUnregisteredContacts =
      (unregisterContactsData?.data?.createUnregisteredContact || [])?.map(
        (contact) => ({
          unregisteredContactId: contact?.id,
        })
      ) || [];

    const oldMentions =
      value.mentions?.filter((mention) => mention?.targetType) || [];
    const formatedOldMentions = formatMentionsInput(
      oldMentions as Streams_adminStreams_entities_mentions[]
    );

    try {
      await updateStream({
        variables: {
          input: {
            id,
            storeId,
            scheduleDate,
            timeZone: {
              tzCode: formValues?.tzCode,
              offset: timeZoneOffset,
            },
            mentionsInputs: [
              ...formatedOldMentions,
              ...newUnregisteredContacts,
            ] as CreateMentionInput[],
            hashtagInputs: value?.hashtags?.length
              ? formatHashtagInput(value?.hashtags)
              : null,
            image:
              ((formValues?.picture?.[0] as UploadFile) || undefined)
                ?.originFileObj || null,
            name: formValues?.repeatsEveryEnum ? null : formValues?.name || '',
            requestedPrice,
            repeatingTitle: formValues?.repeatsEveryEnum
              ? formValues.name
              : null,
            repeatsEveryEnum: formValues?.repeatsEveryEnum,
            isFree,
            isHidden: value?.isHidden,
            description: formValues?.description || '',
            sponsors: sponsorInput,
            moderatorId: formValues?.moderatorId,
            affiliateProducts: value?.affiliateProducts || [],
            participantIds: selectedStoreIds,
          },
        },
        refetchQueries: [
          {
            query: GET_STREAMS,
            variables: {
              input: {
                direction: SortDirection.DESC,
                orderBy: StreamOrderBy.ScheduleDateStrict,
                limit: 10,
                offset: 0,
              },
            },
          },
        ],
      });
      handleEditStreamModalToggle();
      successNotification('The stream has been updated successfully');
    } catch (error) {
      errorNotification(getProperErrorMessage(error, 'Something went wrong!'));
    }
  };

  const handleStreamCancel = () => {
    confirm({
      title: 'Are you sure you want to cancel stream?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await cancelStreamRequest({
            variables: { id },
          });
          successNotification('The stream has been canceled successfully');
        } catch (err: any) {
          showError(err?.message);
        }
      },
    });
  };

  const handleStreamEnd = () => {
    confirm({
      title: 'Are you sure you want to end stream?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await endStream({
            variables: { id },
          });
          successNotification('The stream has been ended successfully');
        } catch (err: any) {
          showError(err?.message);
        }
      },
    });
  };

  const handleStreamRestart = () => {
    confirm({
      title: 'Are you sure you want to restart stream?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await resetStream({
            variables: { input: { id } },
          });
          successNotification('The stream has been restarted successfully');
        } catch (err: any) {
          showError(err?.message);
        }
      },
    });
  };

  const handleStreamRecover = () => {
    confirm({
      title: 'Are you sure you want to recover stream?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await recoverStream({
            variables: { input: { id } },
          });
          successNotification('The stream has been recovered successfully');
        } catch (err: any) {
          showError(err?.message);
        }
      },
    });
  };

  const handleStreamDelete = () => {
    confirm({
      title: 'Are you sure you want to delete stream?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await deleteStream({
            variables: { input: { id } },
          });
          successNotification('The stream has been deleted successfully');
        } catch (err: any) {
          showError(err?.message);
        }
      },
    });
  };

  const handleStreamHide = () => {
    confirm({
      title: 'Do you want to hide this stream?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await hideStream({
            variables: {
              input: {
                id: stream.id,
              },
            },
          });
          successNotification('The stream has been hidden successfully');
        } catch (err) {
          showError((err as Error)?.message);
        }
      },
    });
  };

  const handleStreamUnhide = () => {
    confirm({
      title: 'Do you want to unhide this stream?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await unhideStream({
            variables: {
              input: {
                id: stream.id,
              },
            },
          });
          successNotification('The stream has been unhidden successfully');
        } catch (err) {
          showError((err as Error)?.message);
        }
      },
    });
  };

  const handleWatchStream = () => {
    history.push(`${WATCH_STREAM_GEN}/${stream.id}`);
  };

  const isEndButtonDisabled =
    streamStatus !== StreamStatus.Active &&
    streamStatus !== StreamStatus.Paused &&
    streamStatus !== StreamStatus.Interrupted;

  return (
    <>
      <Space size="middle" direction="vertical">
        {stream.streamStatus === StreamStatus.Ended && (
          <Button
            type="primary"
            icon={<VideoCameraOutlined />}
            onClick={handleWatchStream}
          >
            Watch
          </Button>
        )}

        <Button
          disabled={
            streamStatus !== StreamStatus.Scheduled &&
            streamStatus !== StreamStatus.Ended
          }
          type="primary"
          onClick={handleEditStreamModalToggle}
        >
          Edit
        </Button>

        <Button
          disabled={streamStatus !== StreamStatus.Scheduled}
          type="primary"
          danger
          onClick={handleStreamCancel}
        >
          Cancel
        </Button>

        <Button
          disabled={isEndButtonDisabled}
          type="primary"
          onClick={handleStreamEnd}
        >
          End
        </Button>

        <Button
          disabled={streamStatus !== StreamStatus.Ended}
          type="primary"
          onClick={handleStreamRestart}
        >
          Restart
        </Button>

        <Button
          disabled={!deletedAt}
          type="primary"
          danger
          onClick={handleStreamRecover}
        >
          Recover
        </Button>

        <Button
          disabled={deletedAt}
          type="primary"
          danger
          onClick={handleStreamDelete}
        >
          Delete
        </Button>

        {stream.isHidden ? (
          <Button
            type="primary"
            icon={<EyeFilled />}
            onClick={handleStreamUnhide}
          >
            Unhide
          </Button>
        ) : (
          <Button
            danger
            type="primary"
            icon={<EyeInvisibleFilled />}
            onClick={handleStreamHide}
          >
            Hide
          </Button>
        )}
      </Space>

      <Modal
        title="Edit Stream"
        visible={editModalIsVisible}
        centered
        width={640}
        onCancel={handleEditStreamModalToggle}
        footer={null}
        destroyOnClose
      >
        <CreateEditStreamForm
          selectedStoreIds={selectedStoreIds}
          setSelectedStoreIds={setSelectedStoreIds}
          stream={stream}
          onFinish={onFinish}
          loading={loading}
          submitButtonTitle="Save Stream"
          setFormValues={setFormValues}
          moderators={moderatorsData?.moderators?.entities}
          isEdit={true}
        />
      </Modal>
    </>
  );
};

export default Actions;
