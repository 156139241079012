import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { useParams } from 'react-router-dom';
import DatePicker, { RangePickerProps } from 'antd/lib/date-picker';
import { Typography } from 'antd';
import { useQuery } from '@apollo/client';
// Types
import { GetScheduleEvents_getScheduleEvents } from 'api/scheduleEvents/types/GetScheduleEvents';
import {
  GetMyScheduleEvents,
  GetMyScheduleEventsVariables,
} from 'api/scheduleEvents/types/GetMyScheduleEvents';
// Api
import { GET_MY_SCHEDULE_EVENTS } from 'api/scheduleEvents/queries';
// UI
import Table from 'ui/Table';
// Helpers
import { getPublicStreamDate } from 'helpers/utils';

const { RangePicker } = DatePicker;
const { Text } = Typography;

const MyScheduledEvents = (): JSX.Element => {
  const { storeId } = useParams<{ storeId: string | undefined }>();

  const defaultStartDate = moment()
    .subtract(1, 'months')
    .startOf('day')
    .utc(true);

  const defaultEndDate = moment().endOf('day').utc(true);
  const [startDate, setStartDate] = useState<Moment>(defaultStartDate);
  const [endDate, setEndDate] = useState<Moment>(defaultEndDate);
  const [datePickerStartDate, setDatePickerStartDate] = useState<Moment | null>(
    startDate
  );
  const [datePickerEndDate, setDatePickerEndDate] = useState<Moment | null>(
    endDate
  );

  const { data } = useQuery<GetMyScheduleEvents, GetMyScheduleEventsVariables>(
    GET_MY_SCHEDULE_EVENTS,
    {
      variables: {
        input: {
          dateRange: {
            from: startDate.toISOString(),
            to: endDate.toISOString(),
          },
        },
      },
      skip: !storeId,
      fetchPolicy: 'cache-and-network',
    }
  );
  const getMaxDate = (current: Moment) =>
    moment().diff(current, 'days', true) <= 0;

  const onDateRangeChange = (start: Moment, end: Moment) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleDateRangeChange: RangePickerProps['onChange'] = (dates) => {
    if (!dates) return null;

    const [start, end] = dates;

    setDatePickerStartDate(start);
    setDatePickerEndDate(end);

    if (start && end) {
      onDateRangeChange(
        moment.parseZone(start).utc(true).startOf('day'),
        moment.parseZone(end).utc(true).endOf('day')
      );
    }
  };

  const startDateColumn = {
    title: 'Start date',
    dataIndex: 'startTs',
    key: 'scheduleDate',
    align: 'center' as const,
    width: 110,
    sorterType: 'date',
    render: (
      id: string,
      record: GetScheduleEvents_getScheduleEvents
    ): JSX.Element => {
      return <>{record.startTs ? getPublicStreamDate(record.startTs) : '-'}</>;
    },
  };

  const endDateColumn = {
    title: 'End date',
    dataIndex: 'startTs',
    key: 'endTs',
    align: 'center' as const,
    width: 110,
    sorterType: 'date',
    render: (
      id: string,
      record: GetScheduleEvents_getScheduleEvents
    ): JSX.Element => {
      return <>{record.endTs ? getPublicStreamDate(record.endTs) : '-'}</>;
    },
  };

  const columns = [
    { title: 'Id', dataIndex: 'id', key: 'id', align: 'left' as const },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'left' as const,
      width: 60,
    },
    startDateColumn,
    endDateColumn,
  ];

  return (
    <>
      <div className="payoutsListDatePicker">
        <Text>Select date:</Text>
        <RangePicker
          defaultValue={[datePickerStartDate, datePickerEndDate]}
          disabledDate={getMaxDate}
          onChange={handleDateRangeChange}
        />
      </div>

      <Table
        columns={columns}
        scroll={{ x: 300 }}
        data={data?.getMyScheduleEvents}
        total={data?.getMyScheduleEvents.length}
      />
    </>
  );
};

export default MyScheduledEvents;
