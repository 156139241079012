import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Spin, Result, Button } from 'antd';
// Hooks
import {
  useGetUserTypeFromRoute,
  useDefaultStoreRedirectOnEmptyId,
} from 'hooks';
// Helpers
import { STORE_ROLES } from 'helpers/editStore';
// API
import { MANAGE_BIO_GET_STORE } from 'api/store/queries';
// Types
import { StoreStatus } from 'api/graphql-global-types';
import {
  GetManageBioStore,
  GetManageBioStoreVariables,
} from 'api/store/types/GetManageBioStore';
// Components
import EditAthleteForm from './components/EditAthleteForm';
import EditOrganizationForm from './components/EditOrganizationForm';
import EditContentCreatorForm from './components/EditContentCreatorForm';

const ManageStoreBio = (): JSX.Element => {
  useDefaultStoreRedirectOnEmptyId();

  const { isAthlete, isOrganization, isContentCreator } =
    useGetUserTypeFromRoute();

  const { storeId } = useParams<{ storeId: string | undefined }>();

  /**
   *  Using this query so we can get inactive stores without changing BE,
   *  getStoreInfo only returns active stores
   */
  const { data, loading } = useQuery<
    GetManageBioStore,
    GetManageBioStoreVariables
  >(MANAGE_BIO_GET_STORE, {
    variables: {
      storeRoles: STORE_ROLES,
      input: {
        limit: 1,
        storeIds: [storeId || ''],
        status: [StoreStatus.Active, StoreStatus.Inactive, StoreStatus.Pending],
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !storeId,
  });

  const store = data?.adminStores?.entities[0];

  if (loading) {
    return <Spin />;
  }

  if (isAthlete) {
    return <EditAthleteForm athlete={store} />;
  }

  if (isOrganization) {
    return <EditOrganizationForm organization={store} />;
  }

  if (isContentCreator) {
    return <EditContentCreatorForm contentCreator={store} />;
  }

  return (
    <Result
      status="500"
      subTitle="Sorry, something went wrong."
      extra={
        <Button type="primary" href="/">
          Back Home
        </Button>
      }
    />
  );
};

export default ManageStoreBio;
