import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
// Api
import { GET_USERS_LIST } from 'api/users/queries';
// Types
import {
  StoreStatus,
  SortDirection,
  UsersOrderFields,
} from 'api/graphql-global-types';
import {
  GetUsersList,
  GetUsersListVariables,
  GetUsersList_users_entities,
} from 'api/users/types/GetUsersList';
import { SortedInfo, TableFilter } from 'ui/Table';
// Helpers
import { formatCurrencyString } from 'helpers/utils';
// Constants
import { DEFAULT_CURRENT_PAGE, DEFAULT_NUMBER_ITEMS_PER_PAGE } from 'ui/Table';
import { CUSTOMER_PURCHASES_GEN, CUSTOMERS } from 'constants/routes';
// UI
import Table from 'ui/Table';

type CustomersListItem = GetUsersList_users_entities & {
  name: string;
};

const Customers: React.FC = () => {
  const history = useHistory();
  const { itemsPerPage, pageNumber } = useParams<{
    itemsPerPage: string | undefined;
    pageNumber: string | undefined;
  }>();
  const [pageSize, setPageSize] = useState<number>(
    Number(itemsPerPage) || DEFAULT_NUMBER_ITEMS_PER_PAGE
  );
  const [currentPage, setCurrentPage] = useState<number>(
    Number(pageNumber) || DEFAULT_CURRENT_PAGE
  );

  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  useEffect(() => {
    if (Number(itemsPerPage) !== pageSize) {
      setPageSize(Number(itemsPerPage));
    }
  }, [itemsPerPage, pageSize]);

  useEffect(() => {
    if (Number(pageNumber) !== currentPage) {
      setCurrentPage(Number(pageNumber));
    }
  }, [pageNumber, currentPage]);

  const [sort, setSortInfo] = useState<SortedInfo<UsersOrderFields>>({
    order: SortDirection.DESC,
    key: UsersOrderFields.createdAt,
  });

  const getUserInput: any = () => {
    const input: any = {
      status: [StoreStatus.Active],
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;
    });

    return { input };
  };

  const { data, loading } = useQuery<GetUsersList, GetUsersListVariables>(
    GET_USERS_LIST,
    {
      variables: { ...getUserInput() },
      fetchPolicy: 'cache-and-network',
    }
  );

  const customers: CustomersListItem[] =
    data?.users?.entities?.map((item) => ({
      ...item,
      name: `${item.firstName} ${item.lastName}`,
    })) || [];

  const handleEdit = (id: string): void => {
    history.push(`${CUSTOMER_PURCHASES_GEN}/${id}`);
  };

  const handleSetPagination = (page: number, size: number): void => {
    history.push(`${CUSTOMERS}/${size}/${page}`);
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      fixed: 'left' as const,
      width: 70,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: UsersOrderFields.firstName,
      fixed: 'left' as const,
      sorterType: 'text',
      width: 150,
      withSearch: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: UsersOrderFields.email,
      fixed: 'left' as const,
      sorterType: 'text',
      withSearch: true,
      width: 130,
    },
    {
      title: 'Registered at',
      dataIndex: 'createdAt',
      key: UsersOrderFields.createdAt,
      align: 'center' as const,
      width: 120,
      sorterType: 'date',
      render: (registered: number) => moment(registered).format('ll'),
    },
    {
      title: 'Total amount of purchases',
      dataIndex: ['purchaseStats', 'customerTotalCount'],
      align: 'center' as const,
      width: 130,
      render: (customerTotalCount: any) => `${customerTotalCount}`,
    },
    {
      title: 'Revenue',
      dataIndex: ['purchaseStats', 'customerRevenue'],
      align: 'center' as const,
      width: 110,
      render: (customerRevenue: any) => formatCurrencyString(customerRevenue),
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      align: 'center' as const,
      width: 120,
      render: function Edit(_: any, record: any) {
        return (
          <Button type="primary" onClick={() => handleEdit(record.id)}>
            View details
          </Button>
        );
      },
    },
  ];

  return (
    <Table<CustomersListItem, UsersOrderFields>
      columns={columns}
      data={customers}
      scroll={{ x: 800 }}
      loading={loading}
      total={data?.users?.total}
      setPageAndSize={handleSetPagination}
      defaultPageSize={pageSize}
      defaultCurrent={currentPage}
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      sortInfo={sort}
      setSortInfo={setSortInfo}
    />
  );
};

export default Customers;
