import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { Typography, AutoComplete } from 'antd';
// Api
import { SEARCH_STREAMS } from 'api/interview/queries';
// Types
import {
  SearchStreams,
  SearchStreamsVariables,
} from 'api/interview/types/SearchStreams';
// Components
import { AutoCompleteOption } from '../SendInviteModal/SendInviteModal';
// Styles
import styles from './SearchStreams.module.scss';

const { Text } = Typography;

type SearchStreamsProps = {
  storeIds: string[];
  selectedStreamOption: AutoCompleteOption | null;
  setSelectedStreamOption: React.Dispatch<
    React.SetStateAction<AutoCompleteOption | null>
  >;
};

export const SearchStreamsComponent = ({
  storeIds,
  selectedStreamOption,
  setSelectedStreamOption,
}: SearchStreamsProps): JSX.Element => {
  const [searchStreams, { data }] = useLazyQuery<
    SearchStreams,
    SearchStreamsVariables
  >(SEARCH_STREAMS);

  const [streamOptions, setStreamOptions] = useState<AutoCompleteOption[]>([]);

  const handleSelectStream = (
    value: string,
    option: AutoCompleteOption
  ): void => {
    setSelectedStreamOption(option);
  };

  const handleOnClearStream = () => {
    setSelectedStreamOption(null);
  };

  const handleOnSearchStream = useMemo(() => {
    const loadOptions = (searchTerm: string) => {
      searchStreams({
        variables: {
          input: {
            searchTerm: searchTerm,
            storeIds,
          },
        },
      });
    };

    return debounce(loadOptions, 400);
  }, [searchStreams, storeIds]);

  useEffect(() => {
    if (data?.streamsV2?.entities) {
      setStreamOptions(
        data?.streamsV2?.entities.map((stream) => ({
          value: stream.id,
          label: stream.name || '',
        }))
      );
    }
  }, [data]);

  return (
    <div className={styles.streamSection}>
      <Text>Stream:</Text>
      <AutoComplete
        id="stream-search"
        allowClear
        className={styles.autoComplete}
        placeholder="Search Stream Name"
        options={streamOptions}
        value={selectedStreamOption?.label}
        onSearch={handleOnSearchStream}
        onSelect={handleSelectStream}
        onClear={handleOnClearStream}
      />

      {selectedStreamOption && (
        <Text className={styles.text}>
          Selected stream: {selectedStreamOption?.label}
        </Text>
      )}
    </div>
  );
};

export default SearchStreamsComponent;
