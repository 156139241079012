import { gql } from '@apollo/client';

export const ADD_AREA_OF_INFLUENCE = gql`
  mutation AddAreaOfInfluence($input: AddUserAreaOfInfluenceInput!) {
    addAreaOfInfluence(input: $input) {
      id
    }
  }
`;

export const EDIT_AREA_OF_INFLUENCE = gql`
  mutation EditAreaOfInfluence($input: EditUserAreaOfInfluenceInput!) {
    editAreaOfInfluence(input: $input) {
      id
    }
  }
`;

export const DELETE_AREA_OF_INFLUENCE = gql`
  mutation DeleteAreaOfInfluence($id: String!) {
    deleteAreaOfInfluence(id: $id) {
      id
    }
  }
`;

export const DELETE_AREA_OF_INFLUENCE_FROM_STORE = gql`
  mutation DeleteAreaOfInfluenceFromStore(
    $areaOfInfluenceId: String!
    $storeId: String!
  ) {
    deleteAreaOfInfluenceFromStore(
      areaOfInfluenceId: $areaOfInfluenceId
      storeId: $storeId
    ) {
      id
    }
  }
`;
