import React, { ChangeEvent, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Space, Button, Row, Col, Typography, Input, Rate } from 'antd';
// Api
import {
  ACCEPT_ALL_EDITED_VIDEOS,
  REJECT_AND_REASSIGN,
  REJECT_ALL_AND_SEND_BACK_BY_ADMIN,
} from 'api/videoLab/mutation';
import { GET_VIDEO_EDIT_REQUESTS } from 'api/videoLab/queries';
// Types
import {
  AcceptAllEditedVideos,
  AcceptAllEditedVideosVariables,
} from 'api/videoLab/types/acceptAllEditedVideos';
import { VideoEditorOrderBy } from 'api/graphql-global-types';
import {
  RejectAllAndSendBackByAdmin,
  RejectAllAndSendBackByAdminVariables,
} from 'api/videoLab/types/RejectAllAndSendBackByAdmin';
import {
  RejectAndReassign,
  RejectAndReassignVariables,
} from 'api/videoLab/types/RejectAndReassign';
// Helpers
import { VideoEditingRequestsFormattedData } from 'helpers/videoEditingRequest';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import AssignVideoEditor from '../AssignVideoEditor/AssignVideoEditor';
import AssignVideoEditorModal from '../AssignVideoEditor/AssignVideoEditorModal';

const { TextArea } = Input;
const { Text } = Typography;

type ManageVideoEditRequestResultProps = {
  request: VideoEditingRequestsFormattedData;
  isAwaitingApproval?: boolean;
};

const ManageVideoEditRequestResult: React.FC<
  ManageVideoEditRequestResultProps
> = ({ request, isAwaitingApproval = false }) => {
  const [isApproveModalVisible, setApproveModalVisible] =
    useState<boolean>(false);
  const [isRejectModalVisible, setRejectModalVisible] =
    useState<boolean>(false);
  const [isRejectAndReassignModalVisible, setRejectAndReassignModalVisible] =
    useState<boolean>(false);
  const [isAssignModalVisible, setAssignModalVisible] =
    useState<boolean>(false);
  const [approveFeedback, setApproveFeedback] = useState<string | undefined>(
    undefined
  );
  const [videoEditorRate, setVideoEditorRate] = useState<number | null>(
    request.rate
  );

  const [rejectReason, setRejectReason] = useState<string | undefined>(
    undefined
  );

  const [assignedVideoEditor, setAssignedVideoEditor] = useState<string | null>(
    null
  );

  const handleShowApprovalModal = () => {
    setApproveModalVisible(true);
  };

  const handleHideApproveModal = () => {
    setApproveModalVisible(false);
    setApproveFeedback(undefined);
  };

  const onApproveReasonChange = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setApproveFeedback(value);
  };

  const handleShowRejectModal = () => {
    setRejectModalVisible(true);
  };

  const handleShowRejectAndReassignModal = (videoEditorId: string | null) => {
    setAssignedVideoEditor(videoEditorId);
    setRejectAndReassignModalVisible(true);
  };

  const handleHideRejectModal = () => {
    setRejectModalVisible(false);
    setRejectReason(undefined);
  };

  const handleHideRejectAndReassigModal = () => {
    setRejectAndReassignModalVisible(false);
    setRejectReason(undefined);
  };

  const onRejectReasonChange = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setRejectReason(value);
  };

  const [acceptAllEditedVideos, { loading: approveLoading }] = useMutation<
    AcceptAllEditedVideos,
    AcceptAllEditedVideosVariables
  >(ACCEPT_ALL_EDITED_VIDEOS);
  const [rejectAndReassign, { loading: rejectAndReassignLoading }] =
    useMutation<RejectAndReassign, RejectAndReassignVariables>(
      REJECT_AND_REASSIGN
    );
  const [rejectAndSendBack, { loading: rejectAndSendBackLoading }] =
    useMutation<
      RejectAllAndSendBackByAdmin,
      RejectAllAndSendBackByAdminVariables
    >(REJECT_ALL_AND_SEND_BACK_BY_ADMIN);

  // this method rejects video edit request and sends it back to video editor
  const handleRejectAndSendBack = async () => {
    if (rejectReason?.length && request?.id) {
      try {
        await rejectAndSendBack({
          variables: {
            input: {
              videoEditRequestId: request?.id,
              feedback: rejectReason,
              videoEditorId: request.assignedEditorId,
            },
          },
          refetchQueries: [
            {
              query: GET_VIDEO_EDIT_REQUESTS,
              variables: {
                input: {
                  direction: 'ASC',
                  orderBy: VideoEditorOrderBy.createdAt,
                  limit: 10,
                  offset: 0,
                },
              },
            },
          ],
        });

        successNotification(
          `Video edit request #${request?.id} has been rejected successfully`
        );
        handleHideRejectModal();
      } catch (err) {
        errorNotification((err as Error)?.message);
        console.error(err);
      }
    }
  };
  // this method rejects video edit request and reassigns it to another video editor
  const handleRejectAndReassign = async () => {
    setRejectAndReassignModalVisible(true);
    if (rejectReason?.length && request?.id) {
      if (assignedVideoEditor) {
        try {
          await rejectAndReassign({
            variables: {
              input: {
                videoEditRequestId: request?.id,
                feedback: rejectReason,
                newVideoEditorId: assignedVideoEditor,
                videoEditorId: request.assignedEditorId,
              },
            },
            refetchQueries: [
              {
                query: GET_VIDEO_EDIT_REQUESTS,
                variables: {
                  input: {
                    direction: 'ASC',
                    orderBy: VideoEditorOrderBy.createdAt,
                    limit: 10,
                    offset: 0,
                  },
                },
              },
            ],
          });

          successNotification(
            `Video edit request #${request?.id} has been rejected successfully`
          );
          handleHideRejectModal();
        } catch (err) {
          errorNotification((err as Error)?.message);
          console.error(err);
        }
      } else {
        errorNotification(
          'Please choose video editor to reassign video edit request'
        );
      }
    }
  };

  const handleApproveVideoEditRequest = async () => {
    if (request?.id) {
      try {
        await acceptAllEditedVideos({
          variables: {
            input: {
              feedback: approveFeedback,
              rate: videoEditorRate || 0,
              videoEditRequestId: request.id,
              videoEditorId: request.assignedEditorId,
            },
          },
          refetchQueries: [
            {
              query: GET_VIDEO_EDIT_REQUESTS,
              variables: {
                input: {
                  direction: 'ASC',
                  orderBy: VideoEditorOrderBy.createdAt,
                  limit: 30,
                  offset: 0,
                },
              },
            },
          ],
        });

        successNotification(
          `Video edit request #${request?.id} by video editor ${request?.videoEditorName} has been approved successfully`
        );
        handleHideApproveModal();
      } catch (err) {
        errorNotification((err as Error)?.message);
        console.error(err);
      }
    }
  };

  const handleSetAssignModalVisibility = (isVisible: boolean) => {
    setAssignModalVisible(isVisible);
  };

  const handleRateChange = (value: any) => {
    setVideoEditorRate(value);
  };

  return (
    <>
      <Space direction="vertical" size="middle" align="center">
        <Button type="primary" danger onClick={handleShowRejectModal}>
          Reject and send back
        </Button>

        <Button
          type="primary"
          danger
          onClick={() => setAssignModalVisible(true)}
        >
          Reject and reassign video
        </Button>

        <Button
          type="primary"
          onClick={handleShowApprovalModal}
          loading={approveLoading}
        >
          Approve
        </Button>

        {!isAwaitingApproval && (
          <AssignVideoEditor
            videoEditRequestId={request?.id || ''}
            buttonTitle="Re-assign"
            buttonType="default"
          />
        )}
      </Space>

      <Modal
        title="Approve video edit request"
        visible={isApproveModalVisible}
        okText="Approve"
        onOk={handleApproveVideoEditRequest}
        onCancel={handleHideApproveModal}
        confirmLoading={approveLoading}
      >
        <Row>
          <Col>
            <Text>Are you sure you want to approve this video request?</Text>
          </Col>
          <Col style={{ width: '100%' }}>
            <TextArea
              value={approveFeedback}
              onChange={onApproveReasonChange}
              placeholder="Type video approve feedback"
              allowClear
            />
          </Col>
          <Col>
            <Rate
              value={videoEditorRate || 0}
              count={5}
              onChange={handleRateChange}
            />
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Reject video edit request"
        visible={isRejectModalVisible}
        okText="Reject"
        onOk={handleRejectAndSendBack}
        okButtonProps={{ disabled: !rejectReason }}
        onCancel={handleHideRejectModal}
        confirmLoading={rejectAndSendBackLoading}
      >
        <Row>
          <Col>
            <Text>
              Reject request result by video editor
              <strong>{`${request?.videoEditorName || ''}`}</strong> for request{' '}
              <strong>{`#${request?.id}`}</strong>
            </Text>
          </Col>
          <Col style={{ display: 'flex', width: '100%' }}>
            <TextArea
              style={{ width: '100%' }}
              value={rejectReason}
              onChange={onRejectReasonChange}
              placeholder="Type video edit reject reason"
              allowClear
            />
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Reject video edit request"
        visible={isRejectAndReassignModalVisible}
        okText="Reject"
        onOk={handleRejectAndReassign}
        okButtonProps={{ disabled: !rejectReason }}
        onCancel={handleHideRejectAndReassigModal}
        confirmLoading={rejectAndReassignLoading}
      >
        <Row>
          <Col style={{ marginBottom: '16px' }}>
            <Text>
              Reject request result by video editor{' '}
              <strong>{`${request?.videoEditorName || ''}`}</strong> for request{' '}
              <strong>{`#${request?.id}`}</strong>
            </Text>
          </Col>
          <Col style={{ display: 'flex', width: '100%' }}>
            <TextArea
              style={{ width: '100%' }}
              value={rejectReason}
              onChange={onRejectReasonChange}
              placeholder="Type video edit reject reason"
              allowClear
            />
          </Col>
        </Row>
      </Modal>

      <AssignVideoEditorModal
        videoEditRequestId={request.id}
        isModalVisible={isAssignModalVisible}
        setModalVisible={handleSetAssignModalVisibility}
        handleAssignVideoEditorId={handleShowRejectAndReassignModal}
        isFromRejectAndReassignModal
      />
    </>
  );
};

export default ManageVideoEditRequestResult;
