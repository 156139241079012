import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Typography, Input, Form, Modal } from 'antd';
import {
  EyeTwoTone,
  EyeInvisibleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
// Api
import { CHANGE_PASSWORD } from 'api/users/mutations';
// Types
import {
  ChangePassword,
  ChangePasswordVariables,
} from 'api/users/types/ChangePassword';
// UI
import { errorNotification, successNotification } from 'ui/Notification';

const { Text, Title } = Typography;
const { confirm } = Modal;

const Account = (): JSX.Element => {
  const [isChangePasswordDisabled, setIsChangePasswordDisabled] =
    useState<boolean>(true);

  const [form] = Form.useForm();

  const [changePassword, { loading }] = useMutation<
    ChangePassword,
    ChangePasswordVariables
  >(CHANGE_PASSWORD);

  const changePasswordHandler = async () => {
    confirm({
      title: `Are you sure you want to change a password?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await changePassword({
            variables: {
              input: {
                newPassword: form.getFieldValue('password'),
                oldPassword: form.getFieldValue('oldPassword'),
              },
            },
          });
          successNotification('Password is changed');

          form.resetFields();
        } catch (error) {
          errorNotification(
            (error as Error)?.message || 'Something went wrong'
          );
        }
      },
    });
  };

  const passwordIconRender = (visible: boolean): React.ReactNode => {
    return visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />;
  };

  const isPasswordMatching = () =>
    form.getFieldValue('password') === form.getFieldValue('confirmPassword');

  const checkPasswordValues = (): void => {
    setIsChangePasswordDisabled(
      Object.values(form.getFieldsValue()).some((x) => !x || x === '') ||
        !isPasswordMatching() ||
        form.getFieldsError()?.some(({ errors }) => errors?.length > 0)
    );
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      name="setupAddress"
      autoComplete="off"
      onValuesChange={checkPasswordValues}
    >
      <Title>Password</Title>

      <Form.Item
        name="oldPassword"
        label={<Text>Old Password</Text>}
        rules={[
          {
            required: true,
            message: 'Please enter your old password',
          },
        ]}
      >
        <Input.Password
          placeholder="Old password"
          iconRender={passwordIconRender}
        />
      </Form.Item>
      <Form.Item
        name="password"
        label={<Text>New Password</Text>}
        rules={[
          {
            required: true,
            message: 'Please enter your new password',
          },
          {
            min: 6,
            max: 256,
            required: true,
            message:
              "Password's length is minimum 6 and maximum 256 characters.",
          },
          {
            pattern: /^(?=.*?[a-z])(?=.*?[A-Z]).*$/gm,
            required: true,
            message:
              'Password should contain 1 uppercase and 1 lowercase characters.',
          },
        ]}
      >
        <Input.Password
          placeholder="New password"
          iconRender={passwordIconRender}
        />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        validateStatus={
          !isPasswordMatching() || form.getFieldsError()[1]?.errors[0]
            ? 'error'
            : ''
        }
        help={!isPasswordMatching() ? 'Password does not match' : null}
        label={<Text>Confirm Password</Text>}
        rules={[
          {
            required: true,
            message: 'Please confirm your Password',
          },
        ]}
      >
        <Input.Password
          placeholder="Confirm password"
          iconRender={passwordIconRender}
        />
      </Form.Item>
      <Form.Item>
        <Button
          loading={loading}
          onClick={changePasswordHandler}
          type="primary"
          disabled={isChangePasswordDisabled}
        >
          Change Password
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Account;
