import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { Space, Button } from 'antd';
// Api
import { GET_UNMERGED_MERCH } from 'api/merch/queries';
import {
  Gender,
  MerchType,
  SortDirection,
  UnmergedMerchOrderBy,
} from 'api/graphql-global-types';
// Types
import {
  GetUnmergedMerch,
  GetUnmergedMerchVariables,
  GetUnmergedMerch_getUnmergedMerch_entities,
} from 'api/merch/types/GetUnmergedMerch';
// Ui
import Table, {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_ITEMS_PER_PAGE,
  SortedInfo,
  TableFilter,
} from 'ui/Table';
// Components
import MergeInto from './components/MergeInto/MergeInto';

const MerchMerge = (): JSX.Element => {
  const [showMergeIntoPage, setShowMergIntoPage] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [pageSize, setPageSize] = useState<number>(
    DEFAULT_NUMBER_ITEMS_PER_PAGE
  );
  const [sort, setSortInfo] = useState<SortedInfo<UnmergedMerchOrderBy>>({
    order: SortDirection.DESC,
    key: UnmergedMerchOrderBy.createdAt,
  });
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [merchItem, setMerchItem] =
    useState<GetUnmergedMerch_getUnmergedMerch_entities | null>(null);

  const getUnmergedMerchRequestsInput: any = () => {
    const input: any = {
      orderBy: sort.key,
      direction: sort.order,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;
    });

    return { input };
  };

  const { data, loading } = useQuery<
    GetUnmergedMerch,
    GetUnmergedMerchVariables
  >(GET_UNMERGED_MERCH, {
    variables: { ...getUnmergedMerchRequestsInput() },
    fetchPolicy: 'cache-and-network',
  });

  const unmergedMerchList =
    data?.getUnmergedMerch?.entities?.map((item) => ({
      ...item,
      storeName: `${item?.store?.firstName || ''} ${
        item?.store?.lastName || ''
      } `,
    })) || [];

  const handleMergeInto = (
    record: GetUnmergedMerch_getUnmergedMerch_entities
  ) => {
    setShowMergIntoPage(true);
    setMerchItem(record);
  };

  const columns = [
    {
      title: 'Store name',
      dataIndex: 'storeName',
      key: 'storeName',
      fixed: 'left' as const,
      align: 'center' as const,
      withSearch: true,
      width: 160,
    },
    {
      title: 'Store email',
      dataIndex: 'email',
      key: 'email',
      fixed: 'left' as const,
      align: 'center' as const,
      width: 160,
      render: (
        text: string,
        item: GetUnmergedMerch_getUnmergedMerch_entities
      ) => item?.store?.email || '',
    },
    {
      title: 'Merch title',
      dataIndex: 'title',
      key: 'merchTitle',
      fixed: 'left' as const,
      align: 'center' as const,
      withSearch: true,
      width: 160,
    },
    {
      title: 'Merch type',
      dataIndex: 'type',
      key: 'types',
      fixed: 'left' as const,
      align: 'center' as const,
      withRadioFilters: true,
      width: 160,
      filters: [
        {
          text: MerchType.TShirt,
          value: 'TShirt',
        },
        {
          text: MerchType.Joggers,
          value: 'Joggers',
        },
        {
          text: MerchType.Hoodie,
          value: 'Hoodie',
        },
        {
          text: MerchType.RashGuard,
          value: 'RashGuard',
        },
      ],
      filterMultiple: true,
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center' as const,
      fixed: 'left' as const,
      width: 110,
      render: (createdAt: string) =>
        moment(createdAt).local().format('MM/DD/YYYY, h:mm a'),
    },
    {
      title: 'Gender',
      dataIndex: 'record',
      fixed: 'left' as const,
      align: 'center' as const,
      width: 160,
      render: function gender(
        text: string,
        record: GetUnmergedMerch_getUnmergedMerch_entities
      ) {
        const gender =
          record.type === MerchType.TShirt &&
          `${record.genders}` === Gender.Unisex
            ? Gender.Youth
            : record.genders;
        return <>{gender}</>;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 120,
      render: function Actions(
        text: string,
        record: GetUnmergedMerch_getUnmergedMerch_entities
      ) {
        return (
          <Space size="middle" direction="vertical">
            <Button
              type="primary"
              onClick={() => {
                handleMergeInto(record);
              }}
            >
              Merge into
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      {!showMergeIntoPage && (
        <>
          <h1>Unmerged Merch</h1>
          <Table
            columns={columns}
            data={unmergedMerchList}
            rowKey={(item) => `${item.storeId}${item.slug}`}
            scroll={{ x: 1024 }}
            loading={loading}
            total={data?.getUnmergedMerch?.total}
            defaultPageSize={pageSize}
            setPageSize={setPageSize}
            setCurrentPage={setCurrentPage}
            searchFilters={searchFilters}
            setSearchFilters={setSearchFilters}
            setSortInfo={setSortInfo}
          />
        </>
      )}
      {showMergeIntoPage && merchItem && <MergeInto childMerch={merchItem} />}
    </>
  );
};

export default MerchMerge;
