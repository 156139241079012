export const renderComment = (status: string): string => {
  switch (status) {
    case 'Active':
      return 'Item has been listed';
    // Inactive is currently not fetched by BE
    case 'Inactive':
      return 'Item has been removed. You may re-list it from the action column to the right';
    case 'Sold':
      return 'All items have been sold';
    default:
      return '';
  }
};
