import React, { ChangeEvent, useState } from 'react';
import { Input, Space, Image, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
// Types
import { BannersOption } from 'pages/HomePageManagement';
import { ToolbarItem } from 'components/common/RichText/components/Toolbar/Toolbar';
// Ui
import VideoPreview from 'ui/VideoPreview/VideoPreview';
// Components
import RichText from 'components/common/RichText/RichText';
import UploadImageModal from 'pages/HomePageManagement/components/UploadImageModal/UploadImageModal';
import UploadVideoModal from 'pages/HomePageManagement/components/UploadVideoModal/UploadVideoModal';
// Styles
import './styles.scss';

// Note: this component can't be covered by unit tests for now because
// the issue caused by the Editable component from the 'slate-react' package
export type SetupBannersItemProps = {
  onBannerChange: (
    index: number,
    data: string | boolean,
    field: string,
    imageId?: string
  ) => void;
  item: BannersOption;
  index: number;
};

const SetupBannersItem: React.FC<SetupBannersItemProps> = ({
  onBannerChange,
  item,
  index,
}) => {
  const [isUploadImageVisible, setUploadImageVisible] =
    useState<boolean>(false);
  const [isVideoUploadVisible, setVideoUploadVisible] =
    useState<boolean>(false);

  const handleImageChange = (url: string, id: string) => {
    onBannerChange(index, url, 'image', id);
  };

  const handleVideoChange = (url: string, id: string) => {
    onBannerChange(index, url, 'video', id);
  };

  const handleBannerTitleChange = (value: string): void => {
    onBannerChange(index, value, 'title');
  };

  const handleBannerInputChange = ({
    target: { value, name },
  }: ChangeEvent<HTMLInputElement>) => {
    onBannerChange(index, value, name);
  };

  const handleBannerCheckboxChange = ({
    target: { name, checked },
  }: ChangeEvent<HTMLInputElement>) => {
    onBannerChange(index, checked, name);
  };

  const toggleUploadModal = () => {
    setUploadImageVisible((prev) => !prev);
  };

  const toggleVideoUploadModal = () => {
    setVideoUploadVisible((prev) => !prev);
  };

  return (
    <>
      <Space className="upload-container" direction="vertical">
        {item?.image ? (
          <Image
            width={102}
            height={102}
            src={item.image}
            preview={false}
            onClick={toggleUploadModal}
            className="setup-banners-item-image"
            style={{ cursor: 'pointer' }}
          />
        ) : (
          <Button
            className="setup-banners-item-upload-button ant-upload ant-upload-select-picture-card"
            type="dashed"
            size="large"
            color="primary"
            onClick={toggleUploadModal}
          >
            <PlusOutlined />
            <span>Choose image</span>
          </Button>
        )}
      </Space>
      <Space className="upload-container" direction="vertical">
        {item?.video ? (
          <button
            onClick={toggleVideoUploadModal}
            className="setup-banners-item-video"
          >
            <VideoPreview video={item.video} show noControl />
          </button>
        ) : (
          <Button
            className="setup-banners-item-upload-button ant-upload ant-upload-select-picture-card"
            type="dashed"
            size="large"
            color="primary"
            onClick={toggleVideoUploadModal}
          >
            <PlusOutlined />
            <span>Choose video</span>
          </Button>
        )}
      </Space>
      <Space className="input-container" direction="vertical">
        <div className="setup-banners-item-richtext-wrapper">
          <RichText
            onChange={handleBannerTitleChange}
            placeholder="Type title"
            initialValue={item.title || ''}
            toolbarItems={[ToolbarItem.Mark]}
          />
        </div>
      </Space>
      <Space className="input-container" direction="vertical">
        <Input
          name="description"
          value={item.description || ''}
          onChange={handleBannerInputChange}
          placeholder="Type description"
        />
      </Space>
      <Space className="input-container" direction="vertical">
        <Input
          name="buttonContent"
          value={item.buttonContent || ''}
          onChange={handleBannerInputChange}
          placeholder="Type content"
        />
      </Space>
      <Space className="input-container" direction="vertical">
        <Input
          name="link"
          value={item.link || ''}
          onChange={handleBannerInputChange}
          placeholder="Type link"
        />
      </Space>
      <Space className="input-container" direction="vertical">
        <div className="checkbox-wrapper">
          <Input
            className="checkbox-input"
            id="shouldLinkRedirect"
            name="shouldLinkRedirect"
            type="checkbox"
            checked={item.shouldLinkRedirect || false}
            onChange={handleBannerCheckboxChange}
          />
          <label htmlFor="shouldLinkRedirect">Should link redirect</label>
        </div>
      </Space>
      {isUploadImageVisible && (
        <UploadImageModal
          isVisible={isUploadImageVisible}
          onCancel={toggleUploadModal}
          title="Upload Banner Image"
          onImageChange={handleImageChange}
        />
      )}
      {isVideoUploadVisible && (
        <UploadVideoModal
          isVisible={isVideoUploadVisible}
          onCancel={toggleVideoUploadModal}
          title="Upload Banner Video"
          onVideoChange={handleVideoChange}
        />
      )}
    </>
  );
};

export default SetupBannersItem;
