import React from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Card, Image, Space, Badge, Button, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
// Types
import {
  DesignRequestType,
  DesignRequestStatus,
} from 'api/graphql-global-types';
import { DesignRequestsFormattedData } from 'helpers/designRequests';
// Constants
import { FALLBACK_IMAGE } from 'constants/global';
// Styles
import styles from './ManageDesignsCard.module.scss';

const { Title, Text } = Typography;

type ManageDesignsCardProps = {
  data: DesignRequestsFormattedData;
};

const ManageDesignsCard = ({ data }: ManageDesignsCardProps): JSX.Element => {
  const {
    type,
    status,
    storeName,
    designerName,
    requestDetails,
    requestResults,
    designRequestId,
    designCreationDetails,
  } = data;

  const getStatusString = (status: DesignRequestStatus): string => {
    switch (status) {
      case DesignRequestStatus.Accepted: {
        return 'Accepted';
      }
      case DesignRequestStatus.AutoAccepted: {
        return 'Auto-Accepted';
      }
      case DesignRequestStatus.Archived: {
        return 'Archived';
      }
      case DesignRequestStatus.AwaitingApproval: {
        return 'Awaiting approval';
      }
      case DesignRequestStatus.InProgress: {
        return 'In progress';
      }
      case DesignRequestStatus.OnReview: {
        return 'On review';
      }
      case DesignRequestStatus.Rejected: {
        return 'Rejected';
      }
      case DesignRequestStatus.Todo: {
        return 'To do';
      }
    }
  };

  const getStatusColor = (status: DesignRequestStatus): string => {
    switch (status) {
      case DesignRequestStatus.Accepted: {
        return 'green';
      }
      case DesignRequestStatus.AutoAccepted: {
        return 'green';
      }
      case DesignRequestStatus.Archived: {
        return 'orange';
      }
      case DesignRequestStatus.AwaitingApproval: {
        return 'yellow';
      }
      case DesignRequestStatus.InProgress: {
        return 'cyan';
      }
      case DesignRequestStatus.OnReview: {
        return 'gold';
      }
      case DesignRequestStatus.Rejected: {
        return 'red';
      }
      case DesignRequestStatus.Todo: {
        return 'blue';
      }
    }
  };

  const handleZippedImagesDownload = async () => {
    const zip = new JSZip();
    const folder = zip.folder('images');

    for (const result of requestResults) {
      const imageBlob = await fetch(result.link).then((response) => {
        return response.blob();
      });

      const imageFile = new File([imageBlob as any], 'filename.jpg');

      folder?.file(`${result.name}.jpg`, imageFile);
    }

    folder
      ?.generateAsync({ type: 'blob' })
      .then((content) => saveAs(content, 'Design Results'));
  };

  const imageSize = 200;

  const isLogoAudit = type === DesignRequestType.LogoAudit;

  const designName = designCreationDetails?.designName
    ? `"${designCreationDetails.designName}"`
    : '';

  const isMerchCreation = type === DesignRequestType.MerchCreation;

  const ribbonText = isMerchCreation
    ? 'Merch creation'
    : isLogoAudit
    ? 'Logo audit'
    : 'Logo creation';

  return (
    <div key={designRequestId}>
      <Badge.Ribbon text={ribbonText} color={isLogoAudit ? 'coral' : 'green'}>
        <Card
          className={styles.card}
          title={
            <Badge
              color={getStatusColor(status)}
              text={getStatusString(status)}
            />
          }
        >
          <Title level={5} className={styles.title}>
            {isLogoAudit
              ? `Auditing ${storeName}'s Logo`
              : `Creating ${designName} Logo`}
          </Title>

          <Space align="center" direction="vertical">
            <div className={styles.cardImageWrapper}>
              <Image
                width={imageSize}
                src={requestResults[0]?.link}
                fallback={FALLBACK_IMAGE}
                placeholder={
                  <Image
                    preview={false}
                    src={`${requestResults[0]?.link}?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_${imageSize},w_${imageSize}`}
                    width={imageSize}
                  />
                }
              />
            </div>

            {designerName.length ? (
              <Text>
                <b>Designer name:</b> {designerName}
              </Text>
            ) : null}

            <Space direction="horizontal" align="center">
              {requestResults[0]?.link !==
              'https://cdn.stg1.millions.co/null' ? (
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  title="Design Results"
                  onClick={handleZippedImagesDownload}
                >
                  File
                </Button>
              ) : null}

              <Button
                ghost
                type="primary"
                icon={<DownloadOutlined />}
                href={requestDetails.link}
                download={requestDetails.name}
                title={requestDetails.name}
              >
                Details
              </Button>
            </Space>
          </Space>
        </Card>
      </Badge.Ribbon>
    </div>
  );
};

export default ManageDesignsCard;
