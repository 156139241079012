import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
// Types
import { RequestImage } from 'helpers/designRequests';

type RequestResultPropTypes = {
  requestResults: RequestImage[];
};

const RequestResultDownloadLinks = ({
  requestResults,
}: RequestResultPropTypes): JSX.Element => {
  return (
    <>
      {requestResults?.map((requestResult: RequestImage) => {
        if (requestResult?.link?.length) {
          return (
            <div key={requestResult.name}>
              <a
                key={requestResult.name}
                href={requestResult.link}
                download={requestResult.name}
                title={requestResult.name}
              >
                <DownloadOutlined /> Download file
              </a>
              <br />
            </div>
          );
        }

        return null;
      })}
    </>
  );
};

export default RequestResultDownloadLinks;
