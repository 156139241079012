import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Space } from 'antd';
// Api
import { GET_MODERATORS } from 'api/streams/queries';
// Types
import {
  GetModerators,
  GetModerators_moderators_entities,
} from 'api/streams/types/GetModerators';
import { TableFilter } from 'ui/Table';
// UI
import Table from 'ui/Table';
// Components
import CreateEditModeratorModal from './components/CreateEditModeratorModal';
import Actions from './components/Actions';

const Moderators: React.FC = () => {
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const [createEditModeratorModalVisible, setCreateEditModeratorModalVisible] =
    useState<boolean>(false);

  const closeCreateEditModeratorModal = (): void => {
    setCreateEditModeratorModalVisible(false);
  };

  const onAddModerator = () => {
    setCreateEditModeratorModalVisible(true);
  };

  const { data } = useQuery<GetModerators>(GET_MODERATORS);

  const columns = [
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName',
      fixed: 'left' as const,
      align: 'left' as const,
      width: 100,
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName',
      align: 'left' as const,
      width: 100,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'left' as const,
      width: 100,
    },
    {
      title: 'Phone number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'left' as const,
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'left' as const,
      width: 100,
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 60,
      render: function ActionsWrap(
        id: string,
        record: GetModerators_moderators_entities
      ) {
        return <Actions moderator={record} key={id} />;
      },
    },
  ];

  return (
    <>
      <div className="moderators">
        <Space direction="vertical" size="middle" align="end">
          <Button type="primary" onClick={onAddModerator}>
            Add new moderator
          </Button>
        </Space>

        <Table<GetModerators_moderators_entities>
          columns={columns}
          data={data?.moderators?.entities}
          scroll={{ x: 300 }}
          total={data?.moderators?.entities?.length}
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
        />
      </div>

      <CreateEditModeratorModal
        isVisible={createEditModeratorModalVisible}
        onClose={closeCreateEditModeratorModal}
      />
    </>
  );
};

export default Moderators;
