import React from 'react';
import QRCode from 'qrcode.react';
import { Button, notification } from 'antd';
import {
  TwitterOutlined,
  FacebookOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
// Helpers
import { getEnvLink } from 'helpers/getEnvLink';
// Hooks
import { useGetSocialLinks } from 'hooks';
// Config
import ENV from 'api/env';
// Styles
import styles from './SocialShareQR.module.scss';

type SocialShareQRProps = {
  title: string;
  subTitle: string;
  path: string;
  twitterText: string;
  itemTitle?: string;
};

const SocialShareQR: React.FC<SocialShareQRProps> = ({
  title,
  subTitle,
  path,
  twitterText,
  itemTitle,
}) => {
  const { facebookShareLink, twitterShareLink } = useGetSocialLinks({
    url: path,
    twitterText,
  });

  const sharePathname = `${getEnvLink(ENV.REACT_APP_ENV)}${path}` || '';

  const handleCopyLinkButtonClick = async () => {
    try {
      await navigator.clipboard.writeText(sharePathname);
      notification.success({
        description: 'Copied',
        message: 'Success',
        duration: 3,
      });
    } catch (error) {
      notification.error({
        description: 'Could not copy text',
        message: 'Error',
        duration: 3,
      });
    }
  };

  return (
    <div className={styles.entry}>
      <h2>{title}</h2>
      <h3>{subTitle}</h3>
      <div className={styles.qrWrapper}>
        <QRCode
          value={sharePathname}
          size={175}
          bgColor="#ffffff"
          fgColor="#000000"
          renderAs="svg"
          level="H"
          imageSettings={{
            src: '/images/qr-code/qr-code-logo.png',
            height: 40,
            width: 40,
            excavate: true,
          }}
        />
      </div>
      <span className={styles.tag}>{itemTitle}</span>
      <div className={styles.socialLinks}>
        <a
          className={styles.socialButton}
          href={twitterShareLink}
          rel="noopener noreferrer"
          target="_blank"
          aria-label="twitter"
        >
          <Button className={styles.socialItem} icon={<TwitterOutlined />}>
            <span className={styles.itemName}>Twitter</span>
          </Button>
        </a>
        <a
          className={styles.socialButton}
          href={facebookShareLink}
          rel="noopener noreferrer"
          target="_blank"
          aria-label="facebook"
        >
          <Button className={styles.socialItem} icon={<FacebookOutlined />}>
            <span className={styles.itemName}>Facebook</span>
          </Button>
        </a>
        <Button
          className={styles.socialItem}
          icon={<ShareAltOutlined />}
          onClick={handleCopyLinkButtonClick}
        >
          <span className={styles.itemName}>Copy Link</span>
        </Button>
      </div>
    </div>
  );
};

export default SocialShareQR;
