import React, { useState } from 'react';
import { Button, Modal, Upload } from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
// Types
import { ButtonType } from 'antd/lib/button/button';
import { UploadFile } from 'antd/es/upload/interface';
import { UploadProps } from 'antd/lib/upload/interface';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
// Helpers
import { getBase64 } from 'helpers/base64';
import { customRequest } from 'helpers/utils';
// UI
import { errorNotification } from 'ui/Notification';
// Styles
import styles from './UploadImageFile.module.scss';

type UploadImageFileProps = UploadProps & {
  returnPreviewImage?: (image: File) => void;
  onRemove?: (file: UploadFile) => void | boolean | Promise<void | boolean>;
  wrapperClassName?: string;
  hideButton?: boolean;
  buttonIsLoading?: boolean;
  buttonIsDisabled?: boolean;
  buttonTitle?: string;
  buttonType?: ButtonType;
  buttonSize?: SizeType;
  buttonIsWide?: boolean;
  imageAlt?: string;
  maxFileSize?: number;
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  beforePictureUploadCb?: (file: File, fileList: File[]) => void;
  setPictureValidation?: (message: string) => void;
  withCustomRequest?: boolean;
  defaultFileUrl?: string | null;
  name?: string;
  hideRemoveIcon?: boolean;
};

const UploadImageFile = ({
  returnPreviewImage,
  wrapperClassName,
  hideButton,
  buttonIsLoading,
  buttonIsDisabled,
  buttonTitle = 'Upload',
  buttonType = 'default',
  buttonSize = 'middle',
  buttonIsWide = true,
  imageAlt = 'Uploaded image',
  maxFileSize = 2,
  minWidth,
  maxWidth,
  minHeight,
  beforePictureUploadCb,
  setPictureValidation,
  withCustomRequest,
  defaultFileUrl,
  listType = 'picture',
  maxCount = 1,
  accept = 'image/jpg,image/jpeg,image/png',
  name,
  hideRemoveIcon = false,
  ...rest
}: UploadImageFileProps): JSX.Element => {
  const [previewImage, setPreviewImage] = useState<string>('');

  const handleCancelPreview = (): void => {
    setPreviewImage('');
  };

  const handleShowImagePreview = async (
    file: UploadFile<string>
  ): Promise<void> => {
    let preview = file?.url || file?.preview || '';

    if (!file.url && !file.preview && file.originFileObj) {
      preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(preview);
  };

  const validateImageType = (
    file: File
  ): { isValidFileType: boolean; validTypesString: string } => {
    const validTypes = accept.split(',');
    let isValidFileType = false;
    validTypes.forEach((i) => {
      if (file.type === i) {
        return (isValidFileType = true);
      }
    });

    const validTypesFormatted = validTypes.map((i) =>
      i.replace('image/', '').toUpperCase()
    );

    const validTypesStringStart = validTypesFormatted.slice(0, -1).join(', ');

    const validTypesStringEnd =
      validTypesFormatted.length > 1
        ? ` or ${validTypesFormatted.slice(-1)}`
        : '';

    const validTypesString = `${validTypesStringStart}${validTypesStringEnd}`;
    returnPreviewImage && returnPreviewImage(file);

    return { isValidFileType, validTypesString };
  };

  const beforePictureUpload = (file: File, fileList: File[]): boolean => {
    const { isValidFileType, validTypesString } = validateImageType(file);

    if (!isValidFileType) {
      errorNotification(
        `Image "${file.name}" can't be uploaded because it should be a ${validTypesString} file!`
      );

      if (setPictureValidation) {
        setPictureValidation(`Please upload only ${validTypesString} files`);
      }

      return true;
    }

    if (!(file.size / 1024 / 1024 < maxFileSize)) {
      errorNotification(
        `Image "${file.name}" can't be uploaded because it's size is larger than ${maxFileSize}MB!`
      );

      if (setPictureValidation) {
        setPictureValidation(`Picture should be smaller than ${maxFileSize}MB`);
      }

      return true;
    }

    if (setPictureValidation) {
      setPictureValidation(``);
    }

    if (beforePictureUploadCb) {
      beforePictureUploadCb(file, fileList);
    }

    // the code below is written only for single image upload, per case needed
    if (setPictureValidation && (minWidth || maxWidth || minHeight)) {
      const url = URL.createObjectURL(file);
      const img = new Image();
      img.onload = function () {
        if (minWidth && img.width < minWidth) {
          setPictureValidation(`Picture should be wider than ${minWidth}`);
        }
        if (maxWidth && img.width > maxWidth) {
          setPictureValidation(`Picture should not be wider than ${maxWidth}`);
        }
        if (minHeight && img.height < minHeight) {
          setPictureValidation(
            `Picture should have height larger than ${minHeight}`
          );
        }
      };
      img.src = url;
    }

    return false;
  };

  return (
    <div className={wrapperClassName} id={name}>
      <Upload
        listType={listType}
        onPreview={handleShowImagePreview}
        beforeUpload={beforePictureUpload}
        maxCount={maxCount}
        accept={accept}
        showUploadList={{
          showPreviewIcon: true,
          showRemoveIcon: !hideRemoveIcon,
        }}
        {...(withCustomRequest && { customRequest })}
        {...(defaultFileUrl?.length && {
          defaultFileList: [
            {
              uid: '1',
              type: 'image/jpeg',
              size: 2000000,
              name: defaultFileUrl.split('/')[4] || 'uploaded-image',
              status: 'done',
              response: 'Server Error 500',
              url: defaultFileUrl,
            },
          ],
        })}
        {...rest}
      >
        {!hideButton && (
          <Button
            className={styles.uploadButton}
            icon={buttonIsLoading ? <LoadingOutlined /> : <UploadOutlined />}
            disabled={buttonIsLoading || buttonIsDisabled}
            type={buttonType}
            size={buttonSize}
            block={buttonIsWide}
          >
            {buttonTitle}
          </Button>
        )}
      </Upload>

      <Modal
        visible={!!previewImage.length}
        footer={null}
        onCancel={handleCancelPreview}
        title="Image Preview"
      >
        <img
          alt={imageAlt}
          src={previewImage}
          className={styles.previewImage}
        />
      </Modal>
    </div>
  );
};

export default UploadImageFile;
