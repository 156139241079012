import React, { useState } from 'react';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Spin,
  Typography,
} from 'antd';
import moment, { Moment } from 'moment';
import { useMutation } from '@apollo/client';
// Api
import { CREATE_PAYOUT_ORDER } from 'api/store/mutations';
// Types
import {
  CreatePayoutOrder,
  CreatePayoutOrderVariables,
} from 'api/store/types/CreatePayoutOrder';
import { GetAdminStores_adminStores_entities } from 'api/store/types/GetAdminStores';
import { RangePickerProps } from 'antd/lib/date-picker';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
// Styles
import './styles.scss';

type PayoutModalProps = {
  record: GetAdminStores_adminStores_entities & { storeId?: string };
  hidePayoutModal: () => void;
};

const { Option } = Select;
const { Text } = Typography;
const { RangePicker } = DatePicker;

const CURRENCY_ARRAY = [
  {
    value: 'CAD',
    label: 'CAD',
  },
  { value: 'USD', label: 'USD' },
];

const getMaxDate = (current: Moment) =>
  moment().diff(current, 'days', true) <= 0;

const PayoutForm = ({
  record,
  hidePayoutModal,
}: PayoutModalProps): JSX.Element => {
  const [createPayoutOrder, { loading }] = useMutation<
    CreatePayoutOrder,
    CreatePayoutOrderVariables
  >(CREATE_PAYOUT_ORDER);

  const defaultStartDate = moment()
    .subtract(1, 'months')
    .startOf('day')
    .utc(true);

  const defaultEndDate = moment().endOf('day').utc(true);
  const [startDate, setStartDate] = useState<Moment>(defaultStartDate);
  const [endDate, setEndDate] = useState<Moment>(defaultEndDate);
  const [datePickerStartDate, setDatePickerStartDate] = useState<Moment | null>(
    startDate
  );
  const [datePickerEndDate, setDatePickerEndDate] = useState<Moment | null>(
    endDate
  );

  const onDateRangeChange = (start: Moment, end: Moment) => {
    setStartDate(start);
    setEndDate(end);
  };

  type FormValues = {
    amount: string;
    currency?: string | null;
    userId: string;
    periodFrom: Moment;
    periodTo: Moment;
  };

  const initialFormValues: FormValues = {
    amount: '',
    currency: 'USD',
    periodFrom: startDate,
    periodTo: endDate,
    userId: record?.storeId || '',
  };

  const [form] = Form.useForm();

  // eslint-disable-next-line
  const [formFields, setFormFields] = useState<FormValues>(initialFormValues);

  const setFields = () => {
    const fields: FormValues = form.getFieldsValue();

    setFormFields({
      ...fields,
    });
  };

  const handlePayout = async () => {
    try {
      await createPayoutOrder({
        variables: {
          input: {
            storeId: record?.storeId || '',
            amount: parseFloat(formFields.amount),
            periodFrom: startDate,
            periodTo: endDate,
            currency: formFields.currency || '',
            payouts: [
              {
                userId: record?.storeId || '',
                amount: parseFloat(formFields.amount),
              },
            ],
          },
        },
      });
      successNotification(`Payout order successfully created`);
      hidePayoutModal();
    } catch (err: any) {
      errorNotification(err?.message);
    }
  };

  const handleDateRangeChange: RangePickerProps['onChange'] = (dates) => {
    if (!dates) return null;

    const [start, end] = dates;

    setDatePickerStartDate(start);
    setDatePickerEndDate(end);

    if (start && end) {
      onDateRangeChange(
        moment.parseZone(start).utc(true).startOf('day'),
        moment.parseZone(end).utc(true).endOf('day')
      );
    }
  };

  return (
    <div>
      <Form
        form={form}
        layout="horizontal"
        name="setupStream"
        initialValues={initialFormValues}
        autoComplete="off"
        onValuesChange={setFields}
        onLoad={setFields}
        onFinish={handlePayout}
      >
        <Form.Item
          name="currency"
          label={<Text>Currency</Text>}
          rules={[
            {
              required: true,
              message: 'please select currency',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select currency"
            optionFilterProp="children"
          >
            {CURRENCY_ARRAY.map(({ value, label }) => {
              return (
                <Option key={label} value={value}>
                  {label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="amount"
          label={<Text>Amount</Text>}
          rules={[
            {
              required: true,
              message: 'Please enter amount',
            },
          ]}
        >
          <Input style={{ width: 180 }} />
        </Form.Item>

        <div>
          <Text>Select date:</Text>
          <RangePicker
            defaultValue={[datePickerStartDate, datePickerEndDate]}
            disabledDate={getMaxDate}
            onChange={handleDateRangeChange}
          />
        </div>

        {loading ? (
          <Spin size="large" />
        ) : (
          <Button type="primary" htmlType="submit" className="payoutSubmit">
            Submit
          </Button>
        )}
      </Form>
    </div>
  );
};

export default PayoutForm;
