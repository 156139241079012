import React, { useState } from 'react';
import moment from 'moment';
import { Modal, Button, Typography, Space } from 'antd';
// Types
import {
  GetInterviews_getInterviews_entities_auditLogs,
  GetInterviews_getInterviews_entities_auditLogs_interviewer,
  GetInterviews_getInterviews_entities_auditLogs_performedBy,
} from 'api/interview/types/GetInterviews';
// UI
import Table from 'ui/Table';

const { Text } = Typography;

type AuditLogsProps = {
  data: GetInterviews_getInterviews_entities_auditLogs[];
};

const AuditLogs: React.FC<AuditLogsProps> = ({ data }) => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const handleShowModal = () => {
    setModalVisible(true);
  };

  const handleHideModal = () => {
    setModalVisible(false);
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      align: 'center' as const,
      width: 150,
      render: (id: string) => id,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center' as const,
      width: 150,
      action: (action: string) => action,
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      align: 'center' as const,
      width: 150,
      render: (createdAt: string) =>
        moment(createdAt).local().format('MM/DD/YYYY, h:mm a [GMT]Z'),
    },
    {
      title: 'Interviewer name',
      dataIndex: 'interviewer',
      width: 200,
      render: (
        interviewer: GetInterviews_getInterviews_entities_auditLogs_interviewer
      ) => (
        <Space size="small">
          {interviewer?.firstName || ''}
          {interviewer?.lastName || ''}
        </Space>
      ),
    },
    {
      title: 'Performed by',
      dataIndex: 'performedBy',
      width: 200,
      render: (
        performedBy: GetInterviews_getInterviews_entities_auditLogs_performedBy
      ) => (
        <Space size="small">
          {performedBy?.firstName || ''}
          {performedBy?.lastName || ''}
        </Space>
      ),
    },
  ];

  if (!data || !data?.length) {
    return <Text>-</Text>;
  }

  const sortedAuditLogs = [...data].sort((a, b) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  });

  return (
    <>
      <Button type="link" onClick={handleShowModal}>
        Show audit logs
      </Button>

      <Modal
        title="Interview audit logs"
        visible={isModalVisible}
        footer={null}
        onCancel={handleHideModal}
        width={770}
      >
        <Table<GetInterviews_getInterviews_entities_auditLogs>
          columns={columns}
          data={sortedAuditLogs}
          scroll={{ x: 430 }}
          total={data.length}
          defaultPageSize={10}
        />
      </Modal>
    </>
  );
};

export default AuditLogs;
