import { TreeDataBuilder, TreeDataEntity } from '../data/tree-data.entity';
import { TreeSelectValue } from '../data/tree-select-value';

export class TreeDataConverter {
  public static from(data: TreeDataBuilder): TreeDataEntity {
    return TreeDataEntity.fromObject(data);
  }

  public static to(data: TreeDataEntity): TreeSelectValue {
    return TreeSelectValue.fromObject(data);
  }
}
