import React from 'react';
import { Card, Col, Row, Typography } from 'antd';
// Types
import { MerchOption } from 'pages/HomePageManagement';
import { OptionsItem } from 'pages/HomePageManagement/components/SectionItem';
// Components
import SetupMerchSection from './components/SetupMerchSection';

const { Text } = Typography;

export type SetupMerchProps = {
  selectedItems: MerchOption[];
  onItemChange: React.Dispatch<React.SetStateAction<MerchOption[]>>;
};

const SetupMerch: React.FC<SetupMerchProps> = ({
  selectedItems,
  onItemChange,
}) => {
  const onSelect = (
    index: number,
    item: {
      merch?: OptionsItem;
      store?: OptionsItem;
    }
  ) => {
    const emptyOption: OptionsItem = {
      value: '',
      label: '',
    };

    onItemChange([
      ...selectedItems.slice(0, index),
      ...(item.store
        ? [
            {
              store: { ...item.store },
              merch: { ...emptyOption },
            },
          ]
        : item.merch
        ? [
            {
              store: { ...selectedItems[index].store },
              merch: { ...item.merch },
            },
          ]
        : [
            {
              store: { ...emptyOption },
              merch: { ...emptyOption },
            },
          ]),
      ...selectedItems.slice(index + 1),
    ]);
  };

  return (
    <Card title="Setup Merch" style={{ width: '100%' }}>
      <Row gutter={[16, 16]} align="middle">
        <Col className="gutter-row" span={12}>
          <Text type="secondary">Select Store</Text>
        </Col>
        <Col className="gutter-row" span={12}>
          <Text type="secondary">{"Select Store's merch"}</Text>
        </Col>
      </Row>

      {selectedItems.map((item, index: number) => (
        <SetupMerchSection
          key={index}
          selectedMerch={item}
          onChange={(item) => {
            onSelect(index, item);
          }}
        />
      ))}
    </Card>
  );
};

export default SetupMerch;
