import { gql } from '@apollo/client';
// Fragments
import { MediaPostFields } from './fragments';

export const CREATE_IMAGE_POST_ADMIN = gql`
  mutation CreateImagePostAdmin($input: CreateImagePostAdminInput!) {
    createImagePostAdmin(input: $input) {
      ...MediaPostFields
    }
  }
  ${MediaPostFields}
`;

export const ADMIN_CREATE_POST_IMAGE_PRESIGNED_URL_V2 = gql`
  mutation AdminCreatePostImagePresignedUrlV2(
    $input: AdminCreatePostImagePresignedUrlV2Input!
  ) {
    adminCreatePostImagePresignedUrlV2(input: $input) {
      fields
      key
      url
    }
  }
`;

export const EDIT_IMAGE_POST_ADMIN = gql`
  mutation EditImagePostAdmin($input: EditImagePostAdminInput!) {
    editImagePostAdmin(input: $input) {
      slug
      ...MediaPostFields
    }
  }
  ${MediaPostFields}
`;

export const CREATE_VIDEO_POST_ADMIN = gql`
  mutation CreateVideoPostAdmin($input: CreateVideoPostAdminInput!) {
    createVideoPostAdmin(input: $input) {
      ...MediaPostFields
    }
  }
  ${MediaPostFields}
`;

export const EDIT_VIDEO_POST_ADMIN = gql`
  mutation EditVideoPostAdmin($input: EditVideoPostAdminInput!) {
    editVideoPostAdmin(input: $input) {
      slug
      ...MediaPostFields
    }
  }
  ${MediaPostFields}
`;

export const DELETE_MEDIA_POST_ADMIN = gql`
  mutation DeleteMediaPostAdmin($input: DeleteMediaPostAdminInput!) {
    deleteMediaPostAdmin(input: $input) {
      id
    }
  }
`;

export const ADMIN_INIT_POST_VIDEO_MULTIPART_UPLOAD_V2 = gql`
  mutation AdminInitPostVideoMultipartUploadV2(
    $input: AdminInitVideoPostMultipartUploadInput!
  ) {
    adminInitPostVideoMultipartUploadV2(input: $input) {
      fileId
      fileKey
      signedUrls {
        partNumber
        signedUrl
      }
    }
  }
`;

export const COMPLETE_POST_VIDEO_MULTIPART_UPLOAD = gql`
  mutation CompletePostVideoMultipartUpload(
    $input: CompleteMultipartUploadInput!
  ) {
    completePostVideoMultipartUpload(input: $input)
  }
`;
