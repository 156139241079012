import React from 'react';
import { useMutation } from '@apollo/client';
import { Result, Form, Input, Button } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
// Helpers
import { getIsInterviewer } from 'helpers/auth';
// Hooks
import { useAppContext } from 'hooks';
// Api
import { SIGN_IN_ADMIN_V2 } from 'api/auth/mutations';
// Types
import {
  SignInAdminV2,
  SignInAdminV2Variables,
} from 'api/auth/types/SignInAdminV2';
// UI
import { errorNotification } from 'ui/Notification';
// Styles
import styles from './LoginForm.module.scss';

type LoginFormProps = {
  title?: string;
  subTitle?: string;
};

const LoginForm = ({ title, subTitle }: LoginFormProps): JSX.Element => {
  const { setAuthUser } = useAppContext();
  const [signInAdminV2, { loading }] = useMutation<
    SignInAdminV2,
    SignInAdminV2Variables
  >(SIGN_IN_ADMIN_V2);

  const onFinish = async (values: any): Promise<void> => {
    const { email, password } = values;

    try {
      const { data } = await signInAdminV2({
        variables: {
          input: {
            email,
            password,
          },
        },
      });

      const authUser = data?.signInAdminV2;

      if (authUser) {
        const { id, role, accessToken, refreshToken } = authUser;

        const isInterviewer = getIsInterviewer(authUser);

        setAuthUser({
          id,
          role,
          accessToken,
          refreshToken,
          isInterviewer,
        });
      }
    } catch (err) {
      errorNotification((err as Error)?.message);
    }
  };

  return (
    <Result
      status="403"
      title={title}
      subTitle={subTitle}
      className={styles.root}
      extra={
        <Form name="SignInForm" aria-label="SignIn Form" onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
              {
                type: 'email',
                message: 'Please input a valid email address',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="Username"
              aria-label="Input your email"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
              aria-label="Input your password"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Login
            </Button>
          </Form.Item>
        </Form>
      }
    />
  );
};

export default LoginForm;
