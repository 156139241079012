import React, { useState } from 'react';
import { Button, Form, Input, Spin, Typography } from 'antd';
import { useMutation } from '@apollo/client';
// Api
import { GET_ALL_AREAS_OF_INFLUENCE } from 'api/areaOfInfluence/queries';
import {
  ADD_AREA_OF_INFLUENCE,
  EDIT_AREA_OF_INFLUENCE,
} from 'api/areaOfInfluence/mutations';
// Types
import { AddUserAreaOfInfluenceInput } from 'api/graphql-global-types';
import {
  GetAllAreasOfInfluenceVariables,
  GetAllAreasOfInfluence_getAllAreasOfInfluence_entities,
} from 'api/areaOfInfluence/types/GetAllAreasOfInfluence';
// Ui
import { successNotification, errorNotification } from 'ui/Notification';

type FormValues = {
  name: string;
};

export type CreateEditAreaOfInfluenceProps = {
  record: GetAllAreasOfInfluence_getAllAreasOfInfluence_entities | null;
  hideItemModal: () => void;
  currentAction: string;
  getAreaInput: () => GetAllAreasOfInfluenceVariables;
};

const { Text } = Typography;

const CreateEditAreaOfInfluence = ({
  record,
  hideItemModal,
  currentAction,
  getAreaInput,
}: CreateEditAreaOfInfluenceProps): JSX.Element => {
  const initialFormValues: FormValues = {
    name: record?.name || '',
  };

  const [formFields, setFormFields] = useState<FormValues>(initialFormValues);

  const [addUserAreaOfInfluence, { loading }] =
    useMutation<AddUserAreaOfInfluenceInput>(ADD_AREA_OF_INFLUENCE, {
      refetchQueries: [
        {
          query: GET_ALL_AREAS_OF_INFLUENCE,
          variables: {
            ...getAreaInput(),
          },
        },
      ],
    });

  const [editUserAreaOfInfluence, { loading: updateLoading }] =
    useMutation<AddUserAreaOfInfluenceInput>(EDIT_AREA_OF_INFLUENCE, {
      refetchQueries: [
        {
          query: GET_ALL_AREAS_OF_INFLUENCE,
          variables: {
            ...getAreaInput(),
          },
        },
      ],
    });

  const [form] = Form.useForm();

  const setFields = () => {
    const fields: FormValues = form.getFieldsValue();

    setFormFields({
      ...fields,
    });
  };

  const handleCreateArea = async () => {
    try {
      await addUserAreaOfInfluence({
        variables: {
          input: {
            name: formFields.name,
          },
        },
      });

      successNotification('New item created');
      hideItemModal();
    } catch (error) {
      errorNotification((error as Error)?.message);
    }
  };

  const handleEditArea = async () => {
    try {
      await editUserAreaOfInfluence({
        variables: {
          input: {
            id: record?.id,
            name: formFields.name,
          },
        },
      });
      successNotification('Item updated');
      hideItemModal();
    } catch (error) {
      errorNotification((error as Error)?.message);
    }
  };

  const handleFinish = async () => {
    if (currentAction === 'Edit') {
      await handleEditArea();
    } else {
      await handleCreateArea();
    }
  };

  return (
    <>
      <Form
        form={form}
        layout="horizontal"
        name="createEditArea"
        initialValues={initialFormValues}
        autoComplete="off"
        onValuesChange={setFields}
        onLoad={setFields}
        onFinish={handleFinish}
      >
        <div>
          <Form.Item
            name="name"
            label={<Text>Name</Text>}
            help="Name your item"
            rules={[
              {
                required: true,
                message: 'Please enter the name',
              },
            ]}
          >
            <Input placeholder="Example of name" />
          </Form.Item>

          {loading || updateLoading ? (
            <Spin size="large" />
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              className="createEditFormSubmit"
            >
              save
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default CreateEditAreaOfInfluence;
