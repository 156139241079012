import React, { useState } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { Form, Input, Typography, Select, Button, DatePicker } from 'antd';
// Api
import { UPDATE_EVENT_MARKETING_CAMPAIGN } from 'api/campaignV2/mutations';
// Hooks
import { useGetSports } from 'hooks';
// Constants
import { dateTimeFormat } from 'constants/global';
// Types
import {
  GetCampaignsV2_getCampaigns_entities,
  GetCampaignsV2_getCampaigns_entities_activities_EventMarketingActivity,
} from 'api/campaignV2/types/GetCampaignsV2';
import {
  UpdateEventMarketingCampaign,
  UpdateEventMarketingCampaignVariables,
} from 'api/campaignV2/types/UpdateEventMarketingCampaign';
import {
  CampaignActivityType,
  EventMarketingActivityInput,
  EventMarketingCampaignActivityInput,
  UpdateEventMarketingCampaignInput,
  UserRole,
} from 'api/graphql-global-types';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
// Style
import styles from './EventMarketingEditModal.module.scss';

const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;

type FormValues = {
  name: string;
  participantCount: number;
  startDate: any;
  sport: string;
  description: string;
};

type EventMarketingEditModalProps = {
  record: GetCampaignsV2_getCampaigns_entities | null;
  onFinish: () => void;
};

const EventMarketingEditModal = ({
  record,
  onFinish,
}: EventMarketingEditModalProps): JSX.Element | null => {
  const [form] = Form.useForm();

  const activity = record?.activities?.find(
    (item) => item.__typename === 'EventMarketingActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_EventMarketingActivity;

  const [socialMediaLinks, setSocialMediaLinks] = useState<string[]>(
    activity?.socialMediaLinks || ['']
  );

  const { data: sportsData } = useGetSports({
    variables: {
      storeRoles: [UserRole.Athlete, UserRole.ContentCreator],
    },
  });

  const [updateCampaign, { loading: loadingUpdateCampaign }] = useMutation<
    UpdateEventMarketingCampaign,
    UpdateEventMarketingCampaignVariables
  >(UPDATE_EVENT_MARKETING_CAMPAIGN);

  const sports = sportsData?.sports || [];

  const initialFormValues: FormValues = {
    name: record?.name || '',
    participantCount: activity?.participantCount,
    startDate: activity?.startDate ? moment(activity?.startDate) : null,
    sport: activity?.sport,
    description: activity?.description,
  };

  const handleSubmit = async () => {
    const { name, participantCount, startDate, sport, description } =
      form.getFieldsValue();

    const newSocialMediaLinks: string[] = [];
    socialMediaLinks.forEach((url) => {
      if (url.trim() !== '') {
        newSocialMediaLinks.push(url.trim());
      }
    });

    const eventMarketingInput: EventMarketingActivityInput = {
      description: description,
      participantCount: Number(participantCount),
      ...(newSocialMediaLinks.length && {
        socialMediaLinks: newSocialMediaLinks,
      }),
      sport,
      startDate,
    };

    const activity: EventMarketingCampaignActivityInput[] = [
      {
        eventMarketing: eventMarketingInput,
        type: CampaignActivityType.EventMarketing,
      },
    ];

    const updateCampaignInputVariables: UpdateEventMarketingCampaignInput = {
      activities: activity,
      id: record?.id || '',
      name: name,
      ownerId: record?.agency?.id,
    };

    try {
      const { data } = await updateCampaign({
        variables: {
          input: updateCampaignInputVariables,
        },
      });
      if (data?.updateEventMarketingCampaign) {
        successNotification('The campaign was successfully updated');
        onFinish();
      }
    } catch (error) {
      errorNotification((error as Error)?.message);
      console.error('Campaign update error:', { error });
    }
  };

  const handleEditUrl = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newSocialMediaLinks = [...socialMediaLinks];
    newSocialMediaLinks[index] = event.target.value;

    setSocialMediaLinks(newSocialMediaLinks);
  };

  const handleDeleteUrl = (index: number) => {
    setSocialMediaLinks(
      socialMediaLinks.filter((_, counter) => counter !== index)
    );
  };

  const handleAddNewUrlField = () => {
    setSocialMediaLinks([...socialMediaLinks, '']);
  };

  if (!record) return null;

  return (
    <div className={styles.root}>
      <Form
        name="EditCampaignForm"
        aria-label="Update Social Campaign"
        onFinish={handleSubmit}
        form={form}
        initialValues={initialFormValues}
      >
        <div className={styles.columnWrapper}>
          <div className={styles.heading}>Event Details</div>
          <Text>Event name</Text>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'This field is required, please fill it.',
              },
            ]}
          >
            <Input
              type="text"
              placeholder="Enter Event name"
              aria-label="Event name"
            />
          </Form.Item>

          <div className={styles.heading}>
            How many participants do you have on your event?
          </div>
          <Text>Enter the number of participants.</Text>
          <Form.Item
            name="participantCount"
            rules={[
              {
                required: true,
                message: 'This field is required, please fill it.',
              },
            ]}
          >
            <Input
              type="text"
              placeholder="Enter number of participants"
              aria-label="Participant Count"
            />
          </Form.Item>

          <div className={styles.heading}>Event Date & Time </div>
          <Text>
            Select the date and start time for the event (important for
            scheduling interviews).
          </Text>
          <Form.Item
            name="startDate"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <DatePicker name="startDate" format={dateTimeFormat} />
          </Form.Item>

          <div className={styles.heading}>Additional information</div>
          <Text>Select the sport of the event.</Text>
          <Text>Select Sport</Text>
          <Form.Item
            name="sport"
            rules={[
              {
                required: true,
                message: 'Sport is required',
              },
            ]}
          >
            <Select
              id="sport"
              placeholder="Select sport type"
              filterOption={(inputValue, option) =>
                option?.props.children
                  .toString()
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            >
              {sports.map((item) => {
                return (
                  <Option value={`${item.name}`} key={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Text>Event description</Text>
          <Form.Item
            name="description"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <TextArea
              autoSize={{ minRows: 3 }}
              name="description"
              placeholder="Ane specific details or requests for the interviews, production or hosts."
            />
          </Form.Item>

          <div className={styles.heading}>Social Media Links</div>
          <Text>
            Provide any relevant social media accounts or event pages for better
            content tagging and promotion.
          </Text>
          <Text>URL</Text>
          {socialMediaLinks?.map((url, index) => {
            return (
              <div key={index} className={styles.linkRow}>
                <Input
                  type="text"
                  placeholder="URL"
                  aria-label="URL"
                  defaultValue={url}
                  onChange={(event) => handleEditUrl(event, index)}
                />

                {index > 0 && (
                  <Button
                    className={styles.deleteButton}
                    type="primary"
                    danger
                    onClick={() => handleDeleteUrl(index)}
                  >
                    Delete
                  </Button>
                )}
              </div>
            );
          })}
          <div className={styles.addLinkButtonWrapper}>
            <Button type="primary" onClick={handleAddNewUrlField}>
              Add more
            </Button>
          </div>
        </div>
        <div className={styles.submitButtonWrapper}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingUpdateCampaign}
          >
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EventMarketingEditModal;
