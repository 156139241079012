import moment from 'moment';
// Constants
import { commonTimeFormat } from 'constants/global';
// Types
import { InterviewSourceType } from 'api/graphql-global-types';

const statusColumn = {
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
  align: 'center' as const,
  width: 130,
};

const createdAtColumn = {
  title: 'Created at',
  dataIndex: 'createdAt',
  key: 'createdAt',
  align: 'center' as const,
  width: 110,
  sorterType: 'date',
  render: (createdAt: string): string =>
    moment(createdAt).format(commonTimeFormat),
};

const interviewTypeColumn = {
  title: 'Interview Type',
  dataIndex: 'interviewType',
  key: 'interviewType',
  align: 'left' as const,
  width: 110,
  filterMultiple: true,
  withCheckboxFilters: true,
  filters: [
    {
      text: 'Other',
      value: InterviewSourceType.Other,
    },
    {
      text: 'PPV Participant',
      value: InterviewSourceType.PPVParticipant,
    },
    {
      text: 'Paid',
      value: InterviewSourceType.Paid,
    },
    {
      text: 'Product',
      value: InterviewSourceType.Product,
    },
    {
      text: 'QA Millions',
      value: InterviewSourceType.QAMillions,
    },
  ],
};

export { statusColumn, createdAtColumn, interviewTypeColumn };
