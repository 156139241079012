import React from 'react';
import { useMutation } from '@apollo/client';
import { Button, Dropdown, Menu, Modal, Space } from 'antd';
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
// Api
import env from 'api/env';
import { CANCEL_INTERVIEW } from 'api/interview/mutations';
import { GET_MY_INTERVIEWS } from 'api/interview/queries';
// Helpers
import { getEnvLink } from 'helpers/getEnvLink';
// Types
import { GetMyInterviews_getMyInterviews_entities } from 'api/interview/types/GetMyInterviews';
import {
  CancelInterview,
  CancelInterviewVariables,
} from 'api/interview/types/CancelInterview';
import {
  InterviewStatus,
  InterviewerTasksOrderBy,
  SortDirection,
} from 'api/graphql-global-types';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';

const { confirm } = Modal;

type InterviewsActionsActionsProps = {
  interview: GetMyInterviews_getMyInterviews_entities;
  showScheduleModal: (
    record: GetMyInterviews_getMyInterviews_entities | null,
    isReschedule?: boolean
  ) => void;
  showUpdateInfoModal: (
    record: GetMyInterviews_getMyInterviews_entities | null
  ) => void;
};

const InterviewsActions = ({
  interview,
  showScheduleModal,
  showUpdateInfoModal,
}: InterviewsActionsActionsProps): JSX.Element | null => {
  const [cancelInterview] = useMutation<
    CancelInterview,
    CancelInterviewVariables
  >(CANCEL_INTERVIEW, {
    refetchQueries: [
      {
        query: GET_MY_INTERVIEWS,
        variables: {
          input: {
            direction: SortDirection.DESC,
            orderBy: InterviewerTasksOrderBy.createdAt,
            limit: 10,
            offset: 0,
          },
        },
      },
    ],
  });

  const handleStartStream = () => {
    window.open(
      `${getEnvLink(env.REACT_APP_ENV)}/${
        interview?.interviewer?.slug
      }/start-stream/${interview?.stream?.slug}`,
      '_blank'
    );
  };

  const handleInterviewCancel = () => {
    confirm({
      title: 'Are you sure you want to cancel interview?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await cancelInterview({
            variables: { input: { id: interview.id } },
          });
          successNotification('The interview has been canceled successfully');
        } catch (error) {
          errorNotification(
            (error as Error)?.message || 'Something went wrong'
          );
        }
      },
    });
  };

  const getInterviewerMenuItems = (): JSX.Element | null => {
    switch (interview.status) {
      case InterviewStatus.Canceled:
        return (
          <Menu.Item key={interview.id}>
            <Space direction="vertical" size="middle" align="center">
              <Button
                type="primary"
                onClick={() => showUpdateInfoModal(interview)}
              >
                Update info
              </Button>
            </Space>
          </Menu.Item>
        );
      case InterviewStatus.Completed:
        return (
          <Menu.Item key={interview.id}>
            <Button
              type="primary"
              onClick={() => showUpdateInfoModal(interview)}
            >
              Update info
            </Button>
          </Menu.Item>
        );
      case InterviewStatus.Scheduled:
        return (
          <Menu.Item key={interview.id}>
            <Space direction="vertical" size="middle" align="center">
              <Button type="primary" danger onClick={handleInterviewCancel}>
                Cancel
              </Button>

              {interview?.stream?.slug && (
                <>
                  <Button onClick={handleStartStream} danger>
                    Start interview
                  </Button>
                </>
              )}

              <Button
                type="primary"
                onClick={() => showUpdateInfoModal(interview)}
              >
                Update info
              </Button>

              <Button
                type="primary"
                onClick={() => showScheduleModal(interview, true)}
              >
                Reschedule stream
              </Button>
            </Space>
          </Menu.Item>
        );
      case InterviewStatus.Invited:
        return (
          <Menu.Item key={interview.id}>
            <Space direction="vertical" size="middle" align="center">
              <Button type="primary" danger onClick={handleInterviewCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => showUpdateInfoModal(interview)}
              >
                Update info
              </Button>

              <Button
                type="primary"
                onClick={() => showScheduleModal(interview)}
              >
                Schedule stream
              </Button>
            </Space>
          </Menu.Item>
        );

      case InterviewStatus.InviteViewed:
        return (
          <Menu.Item key={interview.id}>
            <Space direction="vertical" size="middle" align="center">
              <Button type="primary" danger onClick={handleInterviewCancel}>
                Cancel
              </Button>

              <Button
                type="primary"
                onClick={() => showUpdateInfoModal(interview)}
              >
                Update info
              </Button>

              <Button
                type="primary"
                onClick={() => showScheduleModal(interview)}
              >
                Schedule stream
              </Button>
            </Space>
          </Menu.Item>
        );
      case InterviewStatus.ScheduleAttempted:
        return (
          <Menu.Item key={interview.id}>
            <Space direction="vertical" size="middle" align="center">
              <Button type="primary" danger onClick={handleInterviewCancel}>
                Cancel
              </Button>

              <Button
                type="primary"
                onClick={() => showUpdateInfoModal(interview)}
              >
                Update info
              </Button>

              <Button
                type="primary"
                onClick={() => showScheduleModal(interview)}
              >
                Schedule stream
              </Button>
            </Space>
          </Menu.Item>
        );

      default:
        return null;
    }
  };

  const menu = <Menu>{getInterviewerMenuItems()}</Menu>;

  return (
    <Dropdown trigger={['click']} placement="bottomCenter" overlay={menu}>
      <Button
        aria-label="show the available actions"
        icon={<EllipsisOutlined />}
      />
    </Dropdown>
  );
};

export default InterviewsActions;
