import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Space, Button, Modal, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// Api
import { DEACTIVATE_MODERATOR, RECOVER_MODERATOR } from 'api/streams/mutations';
import { GET_MODERATORS } from 'api/streams/queries';
// Types
import {
  RecoverModerator,
  RecoverModeratorVariables,
} from 'api/streams/types/RecoverModerator';
import { StoreStatus } from 'api/graphql-global-types';
import {
  DeactivateModerator,
  DeactivateModeratorVariables,
} from 'api/streams/types/DeactivateModerator';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import CreateEditModeratorModal from './CreateEditModeratorModal';
import { GetModerators_moderators_entities } from 'api/streams/types/GetModerators';

const { confirm } = Modal;

type ActionsProps = {
  moderator: GetModerators_moderators_entities;
};

const Actions: React.FC<ActionsProps> = ({ moderator }) => {
  const [editModeratorModalVisible, setEditModeratorModalVisible] =
    useState<boolean>(false);

  const onEditModerator = async () => {
    setEditModeratorModalVisible(true);
  };

  const closeEditModeratorModal = (): void => {
    setEditModeratorModalVisible(false);
  };

  const [deactivateModerator, { loading: deactivateModeratorLoading }] =
    useMutation<DeactivateModerator, DeactivateModeratorVariables>(
      DEACTIVATE_MODERATOR
    );

  const [recoverModerator, { loading: recoverModeratorLoading }] = useMutation<
    RecoverModerator,
    RecoverModeratorVariables
  >(RECOVER_MODERATOR);

  const onDeactivateModerator = () => {
    confirm({
      title: 'Are you sure you want to deactivate this moderator?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: handleDeactivateModerator,
    });
  };

  const onRecoverModerator = () => {
    confirm({
      title: 'Are you sure you want to reactivate this moderator',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: handleRecoverModerator,
    });
  };

  const handleDeactivateModerator = async () => {
    try {
      await deactivateModerator({
        variables: {
          id: moderator.id,
        },
        refetchQueries: [
          {
            query: GET_MODERATORS,
          },
        ],
      });

      successNotification(
        `${moderator?.firstName || ''} ${
          moderator?.lastName || ''
        } has been deactivated`
      );
    } catch (error) {
      errorNotification((error as Error)?.message || 'Something went wrong');
    }
  };

  const handleRecoverModerator = async () => {
    try {
      await recoverModerator({
        variables: {
          id: moderator.id,
        },
        refetchQueries: [
          {
            query: GET_MODERATORS,
          },
        ],
      });

      successNotification(
        `${moderator?.firstName || ''} ${
          moderator?.lastName || ''
        } has been reactivated`
      );
    } catch (error) {
      errorNotification((error as Error)?.message || 'Something went wrong');
    }
  };

  return (
    <>
      <Spin spinning={deactivateModeratorLoading || recoverModeratorLoading}>
        <Space direction="vertical" size="middle" align="center">
          <Button onClick={onEditModerator} type="primary">
            Edit
          </Button>

          <Button
            onClick={onDeactivateModerator}
            type="primary"
            danger
            disabled={moderator?.status === StoreStatus.Inactive}
          >
            Deactivate
          </Button>

          <Button
            onClick={onRecoverModerator}
            type="primary"
            danger
            disabled={moderator?.status === StoreStatus.Active}
          >
            Recover
          </Button>
        </Space>
      </Spin>

      {editModeratorModalVisible && (
        <CreateEditModeratorModal
          isVisible={editModeratorModalVisible}
          moderator={moderator}
          onClose={closeEditModeratorModal}
        />
      )}
    </>
  );
};

export default Actions;
