import React, { FC } from 'react';
import { Button, Dropdown, Menu, Space } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
// Types
import { StoreStatus, VideoEditRequestStatus } from 'api/graphql-global-types';
import { GetVideoEditRequests_getVideoEditRequests_entities_store } from 'api/videoLab/types/GetVideoEditRequests';
// Helpers
import { VideoEditingRequestsFormattedData } from 'helpers/videoEditingRequest';
// Components
import AssignVideoEditor from 'pages/VideoEditingRequest/common/AssignVideoEditor/AssignVideoEditor';
import ArchiveVideoEditRequest from 'pages/VideoEditingRequest/common/ArchiveVideoEditRequest/ArchiveVideoEditRequest';
import ManageVideoEditRequestResult from 'pages/VideoEditingRequest/common/ManageVideoEditRequestResult/ManageVideoEditRequestResult';

type VideoEditingRequestsActionsProps = {
  request: VideoEditingRequestsFormattedData;
  store?: GetVideoEditRequests_getVideoEditRequests_entities_store | null;
  isVideoEditor?: boolean;
  handleShowEditModal?: () => void;
};

const VideoEditingRequestsActions: FC<VideoEditingRequestsActionsProps> = ({
  request,
  store,
  isVideoEditor,
  handleShowEditModal,
}) => {
  const { id: videoEditRequestId, status } = request;

  // when store is not approved is should show no actions
  const isNotAssignable = store && store.status !== StoreStatus.Active;

  const getAdminMenuItem = (): JSX.Element | null => {
    switch (status) {
      case VideoEditRequestStatus.Archived:
        return (
          <Menu.Item key={request.id + 'Archived'}>
            <Space direction="vertical" size="middle" align="center">
              <ManageVideoEditRequestResult
                request={request}
                isAwaitingApproval
              />
            </Space>
          </Menu.Item>
        );

      case VideoEditRequestStatus.Todo:
        return (
          <Menu.Item key={request.id + 'ToDo'}>
            <Space direction="vertical" size="middle" align="center">
              <ArchiveVideoEditRequest request={request} />
              <AssignVideoEditor
                videoEditRequestId={videoEditRequestId}
                buttonTitle={request.assignedEditorId ? 'Re-assign' : 'Assign'}
              />
              <Button type="primary" onClick={handleShowEditModal}>
                Edit
              </Button>
            </Space>
          </Menu.Item>
        );

      case VideoEditRequestStatus.InProgress:
        return (
          <Menu.Item key={request.id + 'InReview'}>
            <Space direction="vertical" size="middle" align="center">
              <ArchiveVideoEditRequest request={request} />
              <AssignVideoEditor
                videoEditRequestId={videoEditRequestId}
                buttonTitle={request.assignedEditorId ? 'Re-assign' : 'Assign'}
              />
              <Button type="primary" onClick={handleShowEditModal}>
                Edit
              </Button>
            </Space>
          </Menu.Item>
        );

      case VideoEditRequestStatus.OnReview:
        return (
          <Menu.Item key={request.id + 'OnReview'}>
            <Space direction="vertical" size="middle" align="center">
              <ArchiveVideoEditRequest request={request} />

              <ManageVideoEditRequestResult request={request} />
              <Button type="primary" onClick={handleShowEditModal}>
                Edit
              </Button>
            </Space>
          </Menu.Item>
        );

      case VideoEditRequestStatus.Rejected:
        return (
          <Menu.Item key={request.id + 'Rejected'}>
            <Space direction="vertical" size="middle" align="center">
              <ArchiveVideoEditRequest request={request} />

              <AssignVideoEditor
                videoEditRequestId={videoEditRequestId}
                buttonTitle="Re-assign"
                buttonType="default"
              />
            </Space>
          </Menu.Item>
        );

      default:
        return null;
    }
  };

  const videoEditorMenuItems = (): JSX.Element | null => {
    return (
      <Menu.Item key={request.id}>
        <Space align="center" direction="vertical">
          <Button type="primary" onClick={handleShowEditModal}>
            Edit
          </Button>
        </Space>
      </Menu.Item>
    );
  };

  const showAdminMenu =
    status !== VideoEditRequestStatus.Accepted &&
    !isNotAssignable &&
    status !== VideoEditRequestStatus.AutoAccepted &&
    !isNotAssignable;

  const menu = (
    <Menu>{showAdminMenu ? getAdminMenuItem() : videoEditorMenuItems()}</Menu>
  );

  // allowing video editors to work on "InProgress" as well as on "Rejected" requests
  if (
    isVideoEditor &&
    request.status !== VideoEditRequestStatus.InProgress &&
    request.status !== VideoEditRequestStatus.Rejected
  ) {
    return null;
  }

  if (!getAdminMenuItem()) {
    return null;
  }

  return (
    <Dropdown trigger={['click']} placement="bottomCenter" overlay={menu}>
      <Button
        aria-label="show the available actions"
        icon={<EllipsisOutlined />}
      />
    </Dropdown>
  );
};

export default VideoEditingRequestsActions;
