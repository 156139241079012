import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Space, Button, Typography } from 'antd';
// Hooks
import {
  useGetUserTypeFromRoute,
  useDefaultStoreRedirectOnEmptyId,
} from 'hooks';
// Api
import { GET_STREAMS } from 'api/streams/queries';
// Types
import {
  Streams,
  StreamsVariables,
  Streams_adminStreams_entities,
  Streams_adminStreams_entities_moderator,
  Streams_adminStreams_entities_sponsors,
} from 'api/streams/types/Streams';
import { SortedInfo, TableFilter } from 'ui/Table';
import {
  SortDirection,
  StreamOrderBy,
  StreamStatus,
} from 'api/graphql-global-types';
// Constants
import {
  SETUP_ATHLETE_STREAM_GEN,
  SETUP_ORGANIZATION_STREAM_GEN,
  SETUP_CONTENT_CREATOR_STREAM_GEN,
} from 'constants/routes';
// Components
import Actions from './components/Actions';
import StreamViewsTooltip from 'components/common/Stream/StreamViewsTooltip/StreamViewsTooltip';
import StreamNumberOfOrdersButton from 'components/common/Stream/StreamNumberOfOrdersButton/StreamNumberOfOrdersButton';
// UI
import Table from 'ui/Table';
// Helpers
import { formatCurrencyString, getPublicStreamDate } from 'helpers/utils';
import { parseForInnerHTML } from 'helpers/textEditor';
// Styles
import './styles.scss';

const { Text, Paragraph } = Typography;

const ScheduledStreamsTable: React.FC = () => {
  useDefaultStoreRedirectOnEmptyId();
  const { isAthlete, isContentCreator, isOrganization } =
    useGetUserTypeFromRoute();
  const history = useHistory();
  const { storeId } = useParams<{ storeId: string | undefined }>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<StreamOrderBy>>({
    order: SortDirection.DESC,
    key: StreamOrderBy.ScheduleDateStrict,
  });
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const getStreamRequestsInput: any = () => {
    const input: any = {
      storeId,
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      withDeleted: true,
    };

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;
    });

    return { input };
  };

  const { data, loading } = useQuery<Streams, StreamsVariables>(GET_STREAMS, {
    variables: { ...getStreamRequestsInput() },
    fetchPolicy: 'cache-and-network',
    skip: !storeId,
  });

  const handleSetupStream = () => {
    let path = '/';

    if (isAthlete) {
      path = `${SETUP_ATHLETE_STREAM_GEN}/${storeId}`;
    }

    if (isOrganization) {
      path = `${SETUP_ORGANIZATION_STREAM_GEN}/${storeId}`;
    }

    if (isContentCreator) {
      path = `${SETUP_CONTENT_CREATOR_STREAM_GEN}/${storeId}`;
    }

    history.push(path);
  };

  const columns = [
    {
      title: 'Image',
      dataIndex: 'imageURL',
      fixed: 'left' as const,
      align: 'center' as const,
      render: function ImageStream(imageURL: string) {
        return <img width={127} src={imageURL} alt="stream" />;
      },
    },
    {
      title: 'Title',
      dataIndex: 'name',
      key: StreamOrderBy.Name,
      fixed: 'left' as const,
      align: 'center' as const,
      sorterType: 'text',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (description: string) => (
        <Paragraph ellipsis={{ rows: 10 }} title={description}>
          <div
            dangerouslySetInnerHTML={{ __html: parseForInnerHTML(description) }}
          />
        </Paragraph>
      ),
    },
    {
      title: 'Sponsor name',
      dataIndex: 'sponsors',
      render: (sponsors: Streams_adminStreams_entities_sponsors[] | null) => {
        const sponsorsNames =
          sponsors?.map((item) => item.name)?.join(', ') || '';

        return (
          <Text disabled={!sponsorsNames.length}>
            {!sponsorsNames.length ? 'no sponsor' : sponsorsNames}
          </Text>
        );
      },
    },
    {
      title: 'Moderator',
      dataIndex: 'moderator',
      render: (moderator: Streams_adminStreams_entities_moderator | null) => {
        const moderatorName = `${moderator?.firstName || ''} ${
          moderator?.lastName || ''
        }`.trim();

        return (
          <Text disabled={!moderatorName.length}>
            {!moderatorName.length ? 'no moderator' : moderatorName}
          </Text>
        );
      },
    },

    {
      title: 'Date',
      dataIndex: 'scheduleDate',
      key: StreamOrderBy.ScheduleDateStrict,
      align: 'center' as const,
      sorterType: 'date',
      render: (registered: number, record: Streams_adminStreams_entities) =>
        getPublicStreamDate(record?.scheduleDate, record?.timeZone),
    },
    {
      title: 'Status',
      dataIndex: 'streamStatus',
      key: 'streamStatus',
      align: 'center' as const,
      withRadioFilters: true,
      render: (
        streamStatus: StreamStatus,
        record: Streams_adminStreams_entities
      ) => {
        return record?.deletedAt ? 'Deleted' : streamStatus;
      },
      filters: [
        {
          text: StreamStatus.Active,
          value: StreamStatus.Active,
        },
        {
          text: StreamStatus.Cancelled,
          value: StreamStatus.Cancelled,
        },
        {
          text: StreamStatus.Ended,
          value: StreamStatus.Ended,
        },
        {
          text: StreamStatus.Scheduled,
          value: StreamStatus.Scheduled,
        },
      ],
      filterMultiple: false,
    },
    {
      title: 'Requested Price',
      dataIndex: 'requestedPrice',
      key: StreamOrderBy.Price,
      align: 'center' as const,
      sorterType: 'number',
      width: 106,
      render: (registered: number, record: Streams_adminStreams_entities) =>
        record?.isFree
          ? 'Free'
          : formatCurrencyString(record?.requestedPrice || 0),
    },
    {
      title: 'Orders',
      dataIndex: 'numberOfOrders',
      align: 'center' as const,
      render: function NumberOfOrdersWrapper(
        registered: number,
        record: Streams_adminStreams_entities
      ) {
        return (
          <StreamNumberOfOrdersButton
            numberOfOrders={record.numberOfOrders || 0}
            streamId={record.id}
            streamName={record.name}
            isFree={record.isFree}
          />
        );
      },
    },
    {
      title: 'Views',
      dataIndex: 'views',
      align: 'center' as const,
      render: function StreamViewsWrap(
        registered: number,
        record: Streams_adminStreams_entities
      ) {
        return <StreamViewsTooltip views={record.views} />;
      },
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 120,
      render: function ActionsWrap(text: any, record: any) {
        return <Actions stream={record} />;
      },
    },
  ];

  return (
    <div className="streams-requests">
      <Space
        direction="vertical"
        size="middle"
        align="end"
        className="streams-requests__cta-wrapper"
      >
        <Button
          type="primary"
          onClick={handleSetupStream}
          className="streams-requests__headeer__cta"
        >
          Create new stream
        </Button>
      </Space>

      <Table<Streams_adminStreams_entities, StreamOrderBy>
        columns={columns}
        data={data?.adminStreams?.entities}
        scroll={{ x: 800 }}
        loading={loading}
        total={data?.adminStreams?.total}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />
    </div>
  );
};

export default ScheduledStreamsTable;
