import { Button, Modal } from 'antd';
import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Api
import { CANCEL_STREAM_ORDER } from 'api/streams/mutations';
// Types
import { StreamOrderStatus } from 'api/graphql-global-types';
import {
  CancelStreamOrder,
  CancelStreamOrderVariables,
} from 'api/streams/types/CancelStreamOrder';

export type StreamOrderCancelButtonProps = {
  streamOrderId: string;
  orderStatus: StreamOrderStatus;
  onDone?: () => void;
};

const StreamOrderCancelButton = ({
  streamOrderId,
  orderStatus,
  onDone,
}: StreamOrderCancelButtonProps): JSX.Element => {
  const [cancelStreamOrder] = useMutation<
    CancelStreamOrder,
    CancelStreamOrderVariables
  >(CANCEL_STREAM_ORDER);

  const handleCancelAndRefund = async (streamOrderId: string) => {
    try {
      await cancelStreamOrder({ variables: { input: { streamOrderId } } });
      onDone && onDone();
      successNotification('Store has been deactivated');
    } catch (err) {
      errorNotification((err as Error)?.message);
      console.log('handleCancelAndRefund error:', { ...(err as Error) });
    }
  };

  const handleCancelAndRefundConfirmation = (streamOrderId: string) => {
    Modal.confirm({
      title: `Are you sure you want to cancel and refund?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      zIndex: 3000,
      onOk: async () => {
        await handleCancelAndRefund(streamOrderId);
      },
    });
  };

  return (
    <>
      {orderStatus === StreamOrderStatus.Completed && (
        <Button
          type="primary"
          onClick={() => handleCancelAndRefundConfirmation(streamOrderId)}
        >
          Cancel
        </Button>
      )}
    </>
  );
};

export default StreamOrderCancelButton;
