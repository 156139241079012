import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
// Api
import { GET_ANY_PRODUCT_SALES } from 'api/memorabilia/queries';
import {
  MemorabiliaFulfillment,
  MerchOrderStatus,
  AnyProductSalesOrderBy,
  SortDirection,
} from 'api/graphql-global-types';
// Types
import {
  GetAnyProductSales,
  GetAnyProductSalesVariables,
  GetAnyProductSales_getAnyProductSales_entities_order,
} from 'api/memorabilia/types/GetAnyProductSales';
// Constants
import { onlyDateTimeFormat } from 'constants/global';
// Helpers
import { getCommonAddressString } from 'components/common/Sales/ProductSales/helpers';
import { formatCurrencyString, getCustomerName } from 'helpers/utils';
import {
  getMerchType,
  getMerchOrderStatus,
  getNumberOfSuborderItems,
} from 'components/common/Sales/ProductSales/helpers';
// Ui
import Table, {
  DEFAULT_NUMBER_ITEMS_PER_PAGE,
  DEFAULT_CURRENT_PAGE,
  TableFilter,
  SortedInfo,
} from 'ui/Table';
// Components
import ProductSalesDetails from './components/ProductSalesDetails';
// Styles
import styles from './ProductSales.module.scss';

type ProductSalesItems = {
  id: number;
  email: string | null;
  type: string;
  name: string;
  units: number;
  status: MerchOrderStatus;
  price: string;
  shippingAddress: string;
  createdAt: string;
  order: GetAnyProductSales_getAnyProductSales_entities_order;
};

const ProductSales = (): JSX.Element => {
  const [productDetailsModal, setProductDetailsModal] =
    useState<GetAnyProductSales_getAnyProductSales_entities_order | null>();
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [pageSize, setPageSize] = useState<number>(
    DEFAULT_NUMBER_ITEMS_PER_PAGE
  );

  const { storeId } = useParams<{ storeId: string | undefined }>();

  const defaultSortInfo: SortedInfo<AnyProductSalesOrderBy> = {
    order: SortDirection.DESC,
    key: AnyProductSalesOrderBy.createdAt,
  };

  const [sort, setSortInfo] =
    useState<SortedInfo<AnyProductSalesOrderBy>>(defaultSortInfo);
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const getQueryVariables: any = () => {
    const input: any = {
      storeId,
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      if (key === 'status') {
        return (input['subOrderStatuses'] = [value]);
      }

      if (key === 'createdAt') {
        return (input['createdAtDateRange'] = {
          from: Array.isArray(value) ? value?.[0] : value,
          to: Array.isArray(value) ? value?.[1] : value,
        });
      }

      if (key === 'subtotal') {
        return (input['subtotalRange'] = {
          from: Array.isArray(value) ? Number(value?.[0]) : value,
          to: Array.isArray(value) ? Number(value?.[1]) : value,
        });
      }

      if (key === 'type') {
        return (input['types'] = value);
      }

      if (key === 'totalStoreRevenues') {
        return (input['totalStoreRevenuesRange'] = {
          from: Array.isArray(value) ? Number(value?.[0]) : value,
          to: Array.isArray(value) ? Number(value?.[1]) : value,
        });
      }
      input[key] = value;
    });

    return { input };
  };

  const { data: productSalesData, loading } = useQuery<
    GetAnyProductSales,
    GetAnyProductSalesVariables
  >(GET_ANY_PRODUCT_SALES, {
    variables: { ...getQueryVariables() },
    fetchPolicy: 'cache-and-network',
  });

  const tableData: ProductSalesItems[] =
    productSalesData?.getAnyProductSales?.entities?.map((sale) => {
      const { id, customer, subOrders, subtotal, createdAt } = sale.order;
      const subtotalRevenue = storeId ? sale.totalStoreRevenues : subtotal;
      return {
        id: id,
        email: customer.email,
        type: getMerchType(subOrders),
        name: getCustomerName(customer.firstName, customer.lastName),
        units: getNumberOfSuborderItems(subOrders),
        status: getMerchOrderStatus(subOrders),
        price: formatCurrencyString(subtotalRevenue),
        shippingAddress: getCommonAddressString(subOrders[0]),
        createdAt: moment(createdAt).format(onlyDateTimeFormat),
        order: sale.order,
      };
    }) || [];

  const handleCloseModal = () => {
    setProductDetailsModal(null);
  };

  const sales = productSalesData?.getAnyProductSales?.entities || [];

  const filteredSuborders = sales
    .flatMap(({ order: { subOrders } }) => subOrders)
    .filter(
      ({ status, fulfillment }) =>
        status === MerchOrderStatus.Pending &&
        fulfillment === MemorabiliaFulfillment.Millions
    );

  const shouldShowNotification = Boolean(filteredSuborders.length);

  const columns = [
    {
      title: 'OrderId',
      dataIndex: 'id',
      fixed: 'left' as const,
      width: 140,
      sorterType: 'number',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: AnyProductSalesOrderBy.type,
      width: 110,
      withCheckboxFilters: true,
      filterMultiple: true,
      sorterType: 'text',
      filters: [
        {
          text: 'Product',
          value: 'product',
        },
        {
          text: 'Merch',
          value: 'merch',
        },
        {
          text: 'Memorabilia',
          value: 'memorabilia',
        },
      ],
    },
    {
      title: 'Customer`s Name',
      dataIndex: 'name',
      width: 160,
      key: AnyProductSalesOrderBy.customerName,
      sorterType: 'text',
      withSearch: true,
    },
    {
      title: 'Customer Email',
      dataIndex: 'email',
      key: AnyProductSalesOrderBy.customerEmail,
      width: 160,
      sorterType: 'text',
      withSearch: true,
    },
    {
      title: 'Units',
      dataIndex: 'units',
      align: 'center' as const,
      width: 70,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'right' as const,
      width: 100,
      key: AnyProductSalesOrderBy.status,
      sorterType: 'text',
      withRadioFilters: true,
      filters: [
        {
          text: MerchOrderStatus.Canceled,
          value: MerchOrderStatus.Canceled,
        },
        {
          text: MerchOrderStatus.Completed,
          value: MerchOrderStatus.Completed,
        },
        {
          text: MerchOrderStatus.Pending,
          value: MerchOrderStatus.Pending,
        },
        {
          text: MerchOrderStatus.Processing,
          value: MerchOrderStatus.Processing,
        },
      ],
      render: (status: MerchOrderStatus) => {
        return <p className={styles[`is${status}`]}>{status}</p>;
      },
    },
    {
      title: 'Total Price',
      dataIndex: 'price',
      align: 'right' as const,
      width: 100,
      key: storeId
        ? AnyProductSalesOrderBy.totalStoreRevenues
        : AnyProductSalesOrderBy.subtotal,
      sorterType: 'number',
      withNumericalRangeFilter: true,
      defaultNumericalValues: [0, 1000],
    },
    {
      title: 'Shipping address',
      dataIndex: 'shippingAddress',
      align: 'center' as const,
      width: 180,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: AnyProductSalesOrderBy.createdAt,
      width: 110,
      sorterType: 'date',
      withDateRangeFilter: true,
    },
    {
      title: 'Actions',
      dataIndex: 'order',
      align: 'center' as const,
      fixed: 'right' as const,
      width: 100,
      render: function ActionWrapper(
        order: GetAnyProductSales_getAnyProductSales_entities_order
      ) {
        return (
          <Button onClick={() => setProductDetailsModal(order)}>Details</Button>
        );
      },
    },
  ];

  return (
    <>
      {shouldShowNotification && (
        <div className={styles.bannerWrapper}>
          <p>
            <ExclamationCircleOutlined /> You have sold products pending for
            fulfillment
          </p>
        </div>
      )}

      <Table<ProductSalesItems, AnyProductSalesOrderBy>
        columns={columns}
        data={tableData}
        scroll={{ x: 1200 }}
        loading={loading}
        total={productSalesData?.getAnyProductSales?.total}
        defaultPageSize={pageSize}
        defaultCurrent={currentPage}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />
      <Modal
        visible={!!productDetailsModal}
        onCancel={handleCloseModal}
        title="Details"
        footer={null}
        width="90vw"
      >
        {productDetailsModal && (
          <ProductSalesDetails
            data={productDetailsModal}
            onUpdateShipmentStatus={handleCloseModal}
          />
        )}
      </Modal>
    </>
  );
};

export default ProductSales;
