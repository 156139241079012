import React, { useState } from 'react';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { Form, Button, Typography, Switch, Select } from 'antd';
// Api
import { SET_INTERVIEWER_CAPABILITY } from 'api/interviewer/mutations';
// Hooks
import { useGetSports } from 'hooks';
// Types
import {
  SetInterviewerCapability,
  SetInterviewerCapabilityVariables,
} from 'api/interviewer/types/SetInterviewerCapability';
import {
  GetAdminStores,
  GetAdminStoresVariables,
  GetAdminStores_adminStores_entities,
} from 'api/store/types/GetAdminStores';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
import Tip from 'ui/Tip';

const { Text } = Typography;
const { Option } = Select;

type InterviewerCapabilityProps = {
  record: GetAdminStores_adminStores_entities & { storeId?: string };
  refetchStores: (
    variables?: Partial<GetAdminStoresVariables> | undefined
  ) => Promise<ApolloQueryResult<GetAdminStores>>;
  hideInterviewerCapabilityModal: () => void;
};

export const InterviewerCapability = ({
  record,
  refetchStores,
  hideInterviewerCapabilityModal,
}: InterviewerCapabilityProps): JSX.Element => {
  const [form] = Form.useForm();
  const { data: sportsData } = useGetSports();
  const sports = sportsData?.sports || [];

  const [setInterviewerCapability, { loading }] = useMutation<
    SetInterviewerCapability,
    SetInterviewerCapabilityVariables
  >(SET_INTERVIEWER_CAPABILITY, {
    onCompleted: () => {
      refetchStores();
    },
  });

  const [isIncludedInAutoAssignment, setIsIncludedInAutoAssignment] =
    useState<boolean>(false);

  const handleAutoAssignChange = (checked: boolean) => {
    setIsIncludedInAutoAssignment(checked);
  };

  const handleFormFinish = async () => {
    const values = form.getFieldsValue();

    try {
      await setInterviewerCapability({
        variables: {
          input: {
            storeId: record?.storeId || '',
            isIncludedInAutoAssignment,
            sportIds: values?.sportType?.map(Number),
          },
        },
      });

      successNotification('Interviewer table has been updated successfully');
      setIsIncludedInAutoAssignment(false);
      form.resetFields();
      hideInterviewerCapabilityModal();
    } catch (err) {
      errorNotification((err as Error)?.message || 'Something went wrong');
    }
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      name="interviewerCapabilityForm"
      autoComplete="off"
      onFinish={handleFormFinish}
    >
      <Form.Item
        name="isIncludedInAutoAssignment"
        label={
          <Text>
            Auto Assign
            <Tip title="Enable auto assignment for interviewer" />
          </Text>
        }
      >
        <Switch
          onChange={handleAutoAssignChange}
          checked={isIncludedInAutoAssignment}
        />
      </Form.Item>

      <Form.Item name="sportType" label="Specific sports">
        <Select
          id="sportType"
          placeholder="Select sports"
          mode="multiple"
          filterOption={(inputValue, option) =>
            option?.props.children
              .toString()
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          }
        >
          {sports.map((item) => {
            return (
              <Option value={`${item.id}`} key={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default InterviewerCapability;
