import { gql } from '@apollo/client';

export const CANCEL_ORDER = gql`
  mutation CancelOrder($id: Float!) {
    cancelOrder(id: $id) {
      id
      subOrders {
        id
        status
      }
    }
  }
`;

export const DELETE_MERCH_PRODUCT = gql`
  mutation DeleteMerchProduct($id: Float!) {
    deleteMerchProduct(id: $id) {
      id
      title
      slug
    }
  }
`;

export const CREATE_MERCH_PRODUCT_V2 = gql`
  mutation CreateMerchProductV2($input: CreateMerchProductV2AdminInput!) {
    createMerchProductV2Admin(input: $input) {
      id
    }
  }
`;

export const EDIT_MERCH_PRODUCT_V2 = gql`
  mutation EditMerchProductV2($input: EditMerchProductV2AdminInput!) {
    editMerchProductV2Admin(input: $input) {
      id
    }
  }
`;

export const UPLOAD_PRINT_IMAGE = gql`
  mutation UploadPrintImage($input: PrintImageUploadInput!) {
    uploadPrintImage(input: $input) {
      id
      imageURL
    }
  }
`;

export const DELETE_PRINT_IMAGE = gql`
  mutation DeletePrintImage($input: PrintImageInput!) {
    deletePrintImage(input: $input) {
      id
      imageURL
    }
  }
`;

export const CREATE_PRESIGNED_URL = gql`
  mutation CreatePresignedUrlMerch($input: CreateMerchImagePresignedUrl!) {
    createPresignedUrlMerch(input: $input) {
      fields
      key
      url
    }
  }
`;

export const ADMIN_SET_PRODUCT_SUBORDER_SHIPPED = gql`
  mutation AdminSetProductSuborderShipped(
    $input: AdminSetProductSuborderShippedInput!
  ) {
    adminSetProductSuborderShipped(input: $input) {
      id
      orderId
      status
    }
  }
`;

export const MANUAL_MERCH_MERGE = gql`
  mutation ManualMerchMerge($input: MergeMerchInput!) {
    manualMerchMerge(input: $input) {
      id
      title
      requestedProfit
      slug
    }
  }
`;

export const CREATE_VARIANT_PRINT_FILE_PRESIGNED_URLS = gql`
  mutation CreateVariantPrintFilePresignedUrls(
    $input: CreateVariantPrintfilePresignedUrlsInput!
  ) {
    createVariantPrintFilePresignedUrls(input: $input) {
      fields
      key
      url
    }
  }
`;
