import { gql } from '@apollo/client';
import { StreamFields, AdminStreamFields } from './fragments';

export const CREATE_STREAM = gql`
  mutation CreateStream($input: StreamCreateInput!) {
    createStream(input: $input) {
      ...StreamFields
    }
  }
  ${StreamFields}
`;

export const CREATE_STREAM_ADMIN = gql`
  mutation CreateStreamAdmin($input: CreateStreamAdminInput!) {
    createStreamAdmin(input: $input) {
      ...AdminStreamFields
    }
  }
  ${AdminStreamFields}
`;

export const CANCEL_STREAM = gql`
  mutation CancelStream($id: String!) {
    cancelStream(id: $id) {
      ...StreamFields
    }
  }
  ${StreamFields}
`;

export const UPDATE_STREAM = gql`
  mutation UpdateStream($input: StreamUpdateInput!) {
    updateStream(input: $input) {
      ...StreamFields
    }
  }
  ${StreamFields}
`;

export const UPDATE_STREAM_ADMIN = gql`
  mutation UpdateStreamAdmin($input: EditStreamAdminInput!) {
    updateStreamAdmin(input: $input) {
      ...AdminStreamFields
    }
  }
  ${AdminStreamFields}
`;

export const END_STREAM = gql`
  mutation EndStream($id: String!) {
    endStream(id: $id) {
      ...StreamFields
    }
  }
  ${StreamFields}
`;

export const RESET_STREAM = gql`
  mutation ResetStream($input: ResetStreamInput!) {
    resetStream(input: $input) {
      ...StreamFields
    }
  }
  ${StreamFields}
`;

export const DELETE_STREAM = gql`
  mutation DeleteStream($input: DeleteStreamInput!) {
    deleteStream(input: $input) {
      ...StreamFields
    }
  }
  ${StreamFields}
`;

export const RECOVER_STREAM = gql`
  mutation RecoverStream($input: RecoverStreamInput!) {
    recoverStream(input: $input) {
      ...StreamFields
    }
  }
  ${StreamFields}
`;

export const HIDE_STREAM = gql`
  mutation HideStream($input: HideStreamInput!) {
    hideStream(input: $input) {
      id
      isHidden
    }
  }
`;

export const UNHIDE_STREAM = gql`
  mutation UnhideStream($input: UnhideStreamInput!) {
    unhideStream(input: $input) {
      id
      isHidden
    }
  }
`;

export const CREATE_MODERATOR = gql`
  mutation CreateModerator($input: ModeratorCreateInput!) {
    createModerator(input: $input) {
      id
    }
  }
`;

export const EDIT_MODERATOR = gql`
  mutation EditModerator($input: ModeratorEditInput!) {
    editModerator(input: $input) {
      id
    }
  }
`;

export const DEACTIVATE_MODERATOR = gql`
  mutation DeactivateModerator($id: String!) {
    deactivateModerator(id: $id) {
      id
    }
  }
`;

export const RECOVER_MODERATOR = gql`
  mutation RecoverModerator($id: String!) {
    recoverModerator(id: $id) {
      id
    }
  }
`;

export const CREATE_STREAM_NOTE = gql`
  mutation CreateStreamNote($input: StreamNoteCreateInput!) {
    createStreamNote(input: $input) {
      id
    }
  }
`;

export const CREATE_STREAM_CLIP = gql`
  mutation CreateStreamClip($input: CreateStreamClipInput!) {
    createStreamClip(input: $input) {
      id
      startTs
      endTs
      status
      title
      url
    }
  }
`;

export const DELETE_STREAM_CLIP = gql`
  mutation DeleteStreamClip($input: DeleteStreamClipInput!) {
    deleteStreamClip(input: $input) {
      id
    }
  }
`;

// Stream Orders
export const CANCEL_STREAM_ORDER = gql`
  mutation CancelStreamOrder($input: AdminCancelStreamOrderInput!) {
    adminCancelStreamOrder(input: $input) {
      id
      orderStatus
    }
  }
`;
