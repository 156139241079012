import { gql } from '@apollo/client';
import { DesignRequestsFields, DesignersFields } from './fragments';

export const DESIGN_REQUESTS = gql`
  query DesignRequests($input: DesignRequestsFilterInput!) {
    designRequests(input: $input) {
      entities {
        ...DesignRequestsFields
      }
      total
      offset
      limit
      counters {
        inProgressCount
        pastDueDateCount
        unassignedCount
      }
    }
  }
  ${DesignRequestsFields}
`;

export const DESIGNERS = gql`
  query Designers($input: DesignersFilterInput!) {
    designers(input: $input) {
      entities {
        ...DesignersFields
      }
      total
      offset
      limit
    }
  }
  ${DesignersFields}
`;

export const DESIGN_TYPES = gql`
  query DesignTypes {
    designTypes {
      id
      name
      posterImage
      description
      designSamples {
        id
        name
        posterImage
        designTypeId
      }
    }
  }
`;

export const DESIGN_COLOR_PALETTES = gql`
  query DesignColorPalettes {
    designColorPalettes {
      id
      name
      posterImage
    }
  }
`;
