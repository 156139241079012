import React from 'react';
// Helpers
import {
  createPublicStreamLink,
  createPublicMediaPostLink,
} from 'helpers/links';
// Types
import { VideoEditRequestSourceType } from 'api/graphql-global-types';

type VideoRequestSourceProps = {
  withInterviewId?: boolean;
  withStreamId?: boolean;
  withMediaPostId?: boolean;
  source?:
    | {
        type: VideoEditRequestSourceType;
        __typename: 'VideoEditInterviewSource';
        interview: {
          id: string;
          stream: {
            slug: string;
          } | null;
          interviewer: {
            slug: string | null;
          } | null;
        };
      }
    | {
        type: VideoEditRequestSourceType;
        __typename: 'VideoEditStreamSource';
        stream: {
          id: string;
          slug: string;
          store: {
            slug?: string | null;
          };
        };
      }
    | {
        type: VideoEditRequestSourceType;
        __typename: 'VideoEditMediaPostSource';
        mediaPost: {
          id: string;
          slug: string;
          store: {
            slug?: string | null;
          };
        };
      }
    | null;
};

const VideoRequestSourceFields: React.FC<VideoRequestSourceProps> = ({
  withStreamId,
  withInterviewId,
  withMediaPostId,
  source,
}) => {
  return (
    <>
      <div>
        <p>Video type: {source?.type || 'manual creation'}</p>
      </div>

      {source?.__typename === 'VideoEditInterviewSource' && (
        <>
          {withInterviewId && <p>Interview Id: {source.interview.id}</p>}
          {source.interview.interviewer?.slug && source.interview.stream?.slug && (
            <p>
              Interview link:{' '}
              <a
                href={createPublicStreamLink(
                  source.interview.interviewer.slug,
                  source.interview.stream.slug
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                {createPublicStreamLink(
                  source.interview.interviewer.slug,
                  source.interview.stream.slug
                )}
              </a>
            </p>
          )}
        </>
      )}

      {source?.__typename === 'VideoEditStreamSource' && (
        <>
          {withStreamId && <p>Stream Id: {source?.stream.id}</p>}
          {source.stream.slug && source.stream.store.slug && (
            <p>
              Stream link:{' '}
              <a
                href={createPublicStreamLink(
                  source.stream.store.slug,
                  source.stream.slug
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                {createPublicStreamLink(
                  source.stream.store.slug,
                  source.stream.slug
                )}
              </a>
            </p>
          )}
        </>
      )}
      {source?.__typename === 'VideoEditMediaPostSource' && (
        <>
          {withMediaPostId && <p>Media Post Id: {source?.mediaPost.id}</p>}
          {source.mediaPost.slug && source.mediaPost.store.slug && (
            <p>
              Media Post link:{' '}
              <a
                href={createPublicMediaPostLink(
                  source.mediaPost.store.slug,
                  source.mediaPost.slug
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                {createPublicMediaPostLink(
                  source.mediaPost.store.slug,
                  source.mediaPost.slug
                )}
              </a>
            </p>
          )}
        </>
      )}
    </>
  );
};

export default VideoRequestSourceFields;
