import { gql } from '@apollo/client';

export const MemorabiliaFields = gql`
  fragment MemorabiliaFields on Memorabilia {
    id
    comment
    description
    fulfillment
    memorabiliaProductType
    numberOfUnits
    requestedPrice
    status
    storeId
    title
    createdAt
    deletedAt
    price
    slug
    shipmentCategory
    customsDescription
    hashtags {
      id
      name
    }
    isForAuction
    onAuctionFrom
    onAuctionTo
    auctions {
      currentPrice
      deletedAt
      expiresAt
      id
      startPrice
      startsAt
      status
    }
    store {
      id
      slug
      storeDetails {
        id
        storeName
      }
    }
    images {
      id
      isMainImage
      memorabiliaId
      url
      key
      inappropriateMark {
        createdAt
        id
        isInappropriate
        key
        labels {
          Confidence
          Name
          ParentName
        }
        updatedAt
        url
      }
    }
    originAddress {
      id
    }
    createdBy {
      role
      storeDetails {
        id
      }
    }
    isCreatedByAdmin
  }
`;

export const AuctionFields = gql`
  fragment AuctionFields on Auction {
    id
    currentPrice
    deletedAt
    expiresAt
    memorabiliaId
    startPrice
    startsAt
    status
    bids {
      actualAmount
      amount
      cancelledAt
      createdAt
      customer {
        id
        email
        firstName
        lastName
      }
      id
      paidAt
      reservedAt
      shippingCost
    }
  }
`;
