import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Space, Button, Modal, Alert } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import cn from 'classnames';
// Helpers
import { formatPrice } from 'components/common/ManageMerch/helpers';
// Constants
import { commonTimeFormat } from 'constants/global';
// Api
import {
  DELETE_MEMORABILIA,
  RECOVER_MEMORABILIA,
} from 'api/memorabilia/mutations';
import {
  GET_MEMORABILIA,
  GET_INAPPROPRIATE_PRODUCT_IMAGE_COUNT,
} from 'api/memorabilia/queries';
// Types
import {
  GetMemorabilia,
  GetMemorabiliaVariables,
  GetMemorabilia_getMemorabilia_entities,
  GetMemorabilia_getMemorabilia_entities_images,
} from 'api/memorabilia/types/GetMemorabilia';
import {
  MemorabiliaFulfillment,
  MemorabiliaOrderBy,
  MemorabiliaProductType,
  MemorabiliaStatus,
  SortDirection,
  MemorabiliaSaleMethod,
  AuctionStatus,
} from 'api/graphql-global-types';
import {
  DeleteMemorabilia,
  DeleteMemorabiliaVariables,
} from 'api/memorabilia/types/DeleteMemorabilia';
import {
  RecoverMemorabilia,
  RecoverMemorabiliaVariables,
} from 'api/memorabilia/types/RecoverMemorabilia';
import { GetInappropriateProductImageCount } from 'api/memorabilia/types/GetInappropriateProductImageCount';
// Components
import InappropriateImageModal from './InappropriateImageModal/InappropriateImageModal';
import CreateEditMemorabilia from 'components/common/ManageProductsTable/components/CreateEditMemorabilia/CreateEditMemorabilia';
import CreateMemorabilia from './CreateMemorabilia/CreateMemorabilia';
import AuctionDetails from 'components/common/ManageProductsTable/components/AuctionDetails/AuctionDetails';
// UI
import { SortedInfo, TableFilter } from 'ui/Table';
import Table from 'ui/Table';
import { errorNotification, successNotification } from 'ui/Notification';
// Styles
import styles from './AllProductMemorabilia.module.scss';

const { confirm } = Modal;

const AllProductMemorabilia = (): JSX.Element => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<MemorabiliaOrderBy>>({
    order: SortDirection.DESC,
    key: MemorabiliaOrderBy.inappropriateImageCount,
  });

  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [selectedRecord, setSelectedRecord] =
    useState<GetMemorabilia_getMemorabilia_entities | null>(null);
  const [isDetailsModalVisible, setIsDetailsModalVisible] =
    useState<boolean>(false);
  const [imageModalVisible, setImageModalVisible] = useState<boolean>(false);
  const [inappropriateImages, setInappropriateImages] = useState<any[]>([]);
  const [isCreateModalVisible, setIsCreateModalVisible] =
    useState<boolean>(false);
  const [isEditModalVisible, setIsEditVisible] = useState<boolean>(false);

  const getMemorabiliaInput: any = () => {
    const input: any = {
      direction: sort.order,
      isOrphanPage: null,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      orderBy: sort.key,
      storeIds: null,
      storeStatuses: null,
      auctionStatuses: [
        AuctionStatus.Scheduled,
        AuctionStatus.Live,
        AuctionStatus.Finished,
      ],
    };

    searchFilters?.forEach(({ key, value }) => {
      if (key === 'isCreatedByAdmin') {
        if (value === 'true') {
          return (input['isCreatedByAdmin'] = true);
        } else {
          return (input['isCreatedByAdmin'] = false);
        }
      }

      input[key] = value;
    });

    return { input };
  };

  const { data, loading } = useQuery<GetMemorabilia, GetMemorabiliaVariables>(
    GET_MEMORABILIA,
    {
      variables: { ...getMemorabiliaInput() },
      fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        // Initiate refetch to get the correct inappropriate image count
        // Adding delay for BE to update the count correctly
        setTimeout(() => {
          refetchInappropriateImageCount();
        }, 1500);
      },
    }
  );

  const {
    data: inappropriateImageCountData,
    refetch: refetchInappropriateImageCount,
  } = useQuery<GetInappropriateProductImageCount>(
    GET_INAPPROPRIATE_PRODUCT_IMAGE_COUNT
  );
  const inappropriateImageCount =
    inappropriateImageCountData?.getInappropriateProductImageCount ?? 0;

  const [deleteMemorabilia] = useMutation<
    DeleteMemorabilia,
    DeleteMemorabiliaVariables
  >(DELETE_MEMORABILIA);

  const [relistMemorabilia] = useMutation<
    RecoverMemorabilia,
    RecoverMemorabiliaVariables
  >(RECOVER_MEMORABILIA);

  const handleDeleteProduct = async (memorabiliaId: string) => {
    confirm({
      title: 'Are you sure you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await deleteMemorabilia({
            variables: {
              input: {
                id: memorabiliaId,
              },
            },

            refetchQueries: [
              {
                query: GET_MEMORABILIA,
                variables: { ...getMemorabiliaInput() },
              },
            ],
          });
          successNotification('Successfully removed');
        } catch (err) {
          errorNotification(
            'Looks like something went wrong. Please try again later.'
          );
        }
      },
    });
  };

  const handleRelistProduct = async (memorabiliaId: string) => {
    confirm({
      title: 'Are you sure you want to relist this item?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await relistMemorabilia({
            variables: {
              input: {
                id: memorabiliaId,
              },
            },

            refetchQueries: [
              {
                query: GET_MEMORABILIA,
                variables: { ...getMemorabiliaInput() },
              },
            ],
          });
          successNotification('Successfully relisted');
        } catch (err) {
          errorNotification(
            'Looks like something went wrong. Please try again later.'
          );
        }
      },
    });
  };

  const showEditModal = (
    record: GetMemorabilia_getMemorabilia_entities | null
  ) => {
    setSelectedRecord(record);
    setIsEditVisible(true);
  };

  const hideCreateEditModal = () => {
    setIsEditVisible(false);
    setIsCreateModalVisible(false);
    setSelectedRecord(null);
  };

  const handleShowImageModal = () => {
    setImageModalVisible(true);
  };

  const handleHideImageModal = () => {
    setImageModalVisible(false);
  };

  const handleShowAddProductModal = () => {
    setIsCreateModalVisible(true);
  };

  const handleCloseAddProductModal = () => {
    setIsCreateModalVisible(false);
  };

  const showDetailsModal = (
    record: GetMemorabilia_getMemorabilia_entities | null
  ) => {
    setSelectedRecord(record);
    setIsDetailsModalVisible(true);
  };

  const hideDetailsModal = () => {
    setIsDetailsModalVisible(false);
    setSelectedRecord(null);
  };

  const columns = [
    {
      title: 'Item ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left' as const,
      align: 'center' as const,
      withSearch: true,
    },
    {
      title: 'Athlete/Organization/ContentCreator',
      dataIndex: ['store', 'storeDetails', 'storeName'],
      key: 'storeName',
      fixed: 'left' as const,
      align: 'center' as const,
      withSearch: true,
    },
    {
      title: 'Item Name',
      dataIndex: 'title',
      key: 'title',
      fixed: 'left' as const,
      align: 'center' as const,
      withSearch: true,
    },
    {
      title: 'Type',
      dataIndex: 'memorabiliaProductType',
      fixed: 'left' as const,
      align: 'center' as const,
      key: 'productTypes',
      withRadioFilters: true,
      filters: [
        {
          text: MemorabiliaProductType.Product,
          value: MemorabiliaProductType.Product,
        },
        {
          text: MemorabiliaProductType.Memorabilia,
          value: MemorabiliaProductType.Memorabilia,
        },
      ],
      filterMultiple: false,
    },
    {
      title: 'Sale Type',
      dataIndex: 'saleType',
      key: 'saleMethods',
      fixed: 'left' as const,
      align: 'center' as const,
      render: function SellType(
        text: any,
        record: GetMemorabilia_getMemorabilia_entities
      ) {
        return (
          <span style={{ textTransform: 'capitalize' }}>
            {record.isForAuction
              ? MemorabiliaSaleMethod.auction
              : MemorabiliaSaleMethod.standard}
          </span>
        );
      },
      withRadioFilters: true,
      filters: [
        {
          text: MemorabiliaSaleMethod.standard,
          value: MemorabiliaSaleMethod.standard,
        },
        {
          text: MemorabiliaSaleMethod.auction,
          value: MemorabiliaSaleMethod.auction,
        },
      ],
    },
    {
      title: 'Improper Image',
      dataIndex: 'images',
      render: function Image(
        images: GetMemorabilia_getMemorabilia_entities_images[]
      ) {
        const inappropriateImages = images.filter(
          (image) => image.inappropriateMark
        );
        return (
          <Button
            disabled={!inappropriateImages.length}
            onClick={() => {
              setInappropriateImages(inappropriateImages);
              handleShowImageModal();
            }}
          >
            <span>{inappropriateImages?.length}</span>
          </Button>
        );
      },
      key: 'inappropriateImageCount',
      sorterType: 'number',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: MemorabiliaOrderBy.price,
      align: 'center' as const,
      sorterType: 'number',
      width: 106,
      render: function Price(
        text: any,
        record: GetMemorabilia_getMemorabilia_entities
      ) {
        return <span>{formatPrice(record.price)}</span>;
      },
    },

    {
      title: 'Stock',
      dataIndex: 'numberOfUnits',
      align: 'center' as const,
      width: 106,
    },
    {
      title: 'Fulfillment',
      dataIndex: 'fulfillment',
      align: 'center' as const,
      width: 106,
      key: 'fulfillment',
      withRadioFilters: true,
      render: (fulfillment: string) => {
        return (
          <p>
            {fulfillment === MemorabiliaFulfillment.Athlete
              ? 'Store'
              : 'Millions'}
          </p>
        );
      },
      filters: [
        {
          text: 'Store',
          value: MemorabiliaFulfillment.Athlete,
        },
        {
          text: MemorabiliaFulfillment.Millions,
          value: MemorabiliaFulfillment.Millions,
        },
      ],
      filterMultiple: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'statuses',
      align: 'center' as const,
      withRadioFilters: true,
      width: 106,
      filters: [
        {
          text: MemorabiliaStatus.Active,
          value: MemorabiliaStatus.Active,
        },
        {
          text: MemorabiliaStatus.Expired,
          value: MemorabiliaStatus.Expired,
        },
        {
          text: MemorabiliaStatus.Removed,
          value: MemorabiliaStatus.Removed,
        },
        {
          text: MemorabiliaStatus.Sold,
          value: MemorabiliaStatus.Sold,
        },
      ],
      filterMultiple: false,
    },
    {
      title: 'Auction Status',
      dataIndex: 'auctionStatus',
      key: 'auctionStatuses',
      align: 'center' as const,
      width: 106,
      withCheckboxFilters: true,
      filterMultiple: true,
      filters: [
        { text: AuctionStatus.Scheduled, value: AuctionStatus.Scheduled },
        { text: AuctionStatus.Live, value: AuctionStatus.Live },
        { text: AuctionStatus.Finished, value: AuctionStatus.Finished },
      ],
      render: function AuctionStatus(
        text: any,
        record: GetMemorabilia_getMemorabilia_entities
      ) {
        let auctionStatus: string | null = 'N/A';

        if (record.isForAuction && record.auctions.length) {
          auctionStatus = record.auctions[0].status;
        }

        return <span>{auctionStatus}</span>;
      },
    },
    {
      title: 'List date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center' as const,
      width: 106,
      render: (createdAt: string) => moment(createdAt).format(commonTimeFormat),
      sorterType: 'date',
    },
    {
      title: 'Created By',
      dataIndex: 'isCreatedByAdmin',
      key: 'isCreatedByAdmin',
      align: 'center' as const,
      width: 106,
      withRadioFilters: true,
      filters: [
        {
          text: 'Admin',
          value: 'true',
        },
        {
          text: 'Store',
          value: 'false',
        },
      ],
      filterMultiple: false,
      render: (isCreatedByAdmin: boolean) => {
        return <div>{isCreatedByAdmin ? 'Admin' : 'Store'}</div>;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 120,
      render: function Actions(
        text: string,
        record: GetMemorabilia_getMemorabilia_entities
      ) {
        const inappropriateImages:
          | GetMemorabilia_getMemorabilia_entities_images[]
          | null =
          (record &&
            record?.images?.filter((image) => image.inappropriateMark)) ||
          null;
        return (
          <Space size="middle" direction="vertical">
            {record?.isForAuction && (
              <Button
                type="default"
                onClick={() => {
                  showDetailsModal(record);
                }}
              >
                Details
              </Button>
            )}
            <Button
              disabled={!inappropriateImages?.length}
              onClick={() => {
                inappropriateImages &&
                  setInappropriateImages(inappropriateImages);
                handleShowImageModal();
              }}
            >
              Approve image
            </Button>
            <Button
              type="primary"
              onClick={() => {
                showEditModal(record);
              }}
            >
              Edit
            </Button>

            {record.status !== MemorabiliaStatus.Removed && (
              <Button
                type="default"
                onClick={() => handleDeleteProduct(record.id)}
              >
                Remove
              </Button>
            )}

            {record?.status === MemorabiliaStatus.Removed &&
              !record?.isForAuction && (
                <Button
                  type="default"
                  onClick={() => handleRelistProduct(record.id)}
                >
                  Relist
                </Button>
              )}
          </Space>
        );
      },
    },
  ];

  const showInappropriateImageWarning = inappropriateImageCount > 0;

  return (
    <div>
      <Space
        direction={showInappropriateImageWarning ? 'horizontal' : 'vertical'}
        size="middle"
        align={showInappropriateImageWarning ? 'start' : 'end'}
        className={cn(styles.ctaWrapper, {
          [styles.withWarning]: showInappropriateImageWarning,
        })}
      >
        {showInappropriateImageWarning && (
          <Alert
            message={`There ${
              inappropriateImageCount > 1
                ? `are ${inappropriateImageCount}`
                : `is ${inappropriateImageCount}`
            } inappropriate ${
              inappropriateImageCount > 1 ? 'images' : 'image'
            } on products.`}
            type="warning"
          />
        )}
        <Button type="primary" onClick={handleShowAddProductModal}>
          Add new item
        </Button>
      </Space>

      <Table<GetMemorabilia_getMemorabilia_entities, MemorabiliaOrderBy>
        columns={columns}
        data={data?.getMemorabilia?.entities}
        scroll={{ x: 800 }}
        loading={loading}
        total={data?.getMemorabilia?.total}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />

      <div className="editItemModal">
        <Modal
          title="Edit item"
          visible={!!selectedRecord && isEditModalVisible}
          onCancel={hideCreateEditModal}
          className="createEditItemModal"
          width="90vw"
          footer={null}
          destroyOnClose
        >
          <CreateEditMemorabilia
            currentAction={'Edit'}
            storeId={selectedRecord?.storeId || ''}
            record={selectedRecord}
            hideItemModal={hideCreateEditModal}
            getMemorabiliaInput={getMemorabiliaInput}
          />
        </Modal>
      </div>

      <div className="imageModal">
        <InappropriateImageModal
          onHide={handleHideImageModal}
          isVisible={imageModalVisible}
          inappropriateImages={inappropriateImages}
          getMemorabiliaInput={getMemorabiliaInput}
        />
      </div>

      <Modal
        title="New item"
        visible={isCreateModalVisible}
        onCancel={handleCloseAddProductModal}
        width="90vw"
        footer={null}
        destroyOnClose
      >
        <CreateMemorabilia
          getMemorabiliaInput={getMemorabiliaInput}
          onClose={handleCloseAddProductModal}
        />
      </Modal>

      <Modal
        title="Auction Details"
        visible={!!selectedRecord && isDetailsModalVisible}
        onCancel={hideDetailsModal}
        width="90vw"
        destroyOnClose
      >
        <AuctionDetails auctionId={selectedRecord?.auctions?.[0]?.id || ''} />
      </Modal>
    </div>
  );
};

export default AllProductMemorabilia;
