import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Space, Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// Api
import { GET_ALL_AREAS_OF_INFLUENCE } from 'api/areaOfInfluence/queries';
import { DELETE_AREA_OF_INFLUENCE } from 'api/areaOfInfluence/mutations';
// Types
import { AreaOfInfluenceFilterInput } from 'api/graphql-global-types';
import { DeleteAreaOfInfluence } from 'api/areaOfInfluence/types/DeleteAreaOfInfluence';
import { DeleteAreaOfInfluenceVariables } from 'api/areaOfInfluence/types/DeleteAreaOfInfluence';
import {
  GetAllAreasOfInfluence,
  GetAllAreasOfInfluenceVariables,
  GetAllAreasOfInfluence_getAllAreasOfInfluence_entities,
} from 'api/areaOfInfluence/types/GetAllAreasOfInfluence';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
import { TableFilter } from 'ui/Table';
import Table from 'ui/Table';
// Components
import CreateEditAreaOfInfluence from './components/CreateEditAreaOfInfluence/CreateEditAreaOfInfluence';
import Stores from './components/Stores/Stores';
// Styles
import styles from './ManageAreaOfInfluence.module.scss';

const { confirm } = Modal;

const ManageAreaOfInfluence = (): JSX.Element => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const getInput = (): GetAllAreasOfInfluenceVariables => {
    const input: AreaOfInfluenceFilterInput = {
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      input[key as keyof AreaOfInfluenceFilterInput] = value as any;
    });

    return { input };
  };

  const [selectedRecord, setSelectedRecord] =
    useState<GetAllAreasOfInfluence_getAllAreasOfInfluence_entities | null>(
      null
    );

  const [isStoresModalVisible, setIsStoresModalVisible] =
    useState<boolean>(false);

  const [isCreateModalVisible, setIsCreateModalVisible] =
    useState<boolean>(false);
  const [isEditModalVisible, setIsEditVisible] = useState<boolean>(false);

  const { data, loading } = useQuery<
    GetAllAreasOfInfluence,
    GetAllAreasOfInfluenceVariables
  >(GET_ALL_AREAS_OF_INFLUENCE, {
    variables: { ...getInput() },
    fetchPolicy: 'cache-and-network',
  });

  const [deleteAreaOfInfluence, { loading: deleteAreaLoading }] = useMutation<
    DeleteAreaOfInfluence,
    DeleteAreaOfInfluenceVariables
  >(DELETE_AREA_OF_INFLUENCE);

  const hideCreateEditModal = () => {
    setIsEditVisible(false);
    setIsCreateModalVisible(false);
    setSelectedRecord(null);
  };

  const showEditModal = (
    record: GetAllAreasOfInfluence_getAllAreasOfInfluence_entities | null
  ) => {
    setSelectedRecord(record);
    setIsEditVisible(true);
  };

  const showStoresModal = (
    record: GetAllAreasOfInfluence_getAllAreasOfInfluence_entities | null
  ) => {
    setSelectedRecord(record);
    setIsStoresModalVisible(true);
  };

  const hideStoresModal = () => {
    setIsStoresModalVisible(false);
    setSelectedRecord(null);
  };

  const handleShowAddProductModal = () => {
    setIsCreateModalVisible(true);
  };

  const handleDelete = async (id: string) => {
    confirm({
      title: 'Are you sure you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await deleteAreaOfInfluence({
            variables: {
              id,
            },
            refetchQueries: [
              {
                query: GET_ALL_AREAS_OF_INFLUENCE,
                variables: { ...getInput() },
              },
            ],
          });
          successNotification('Successfully removed');
        } catch (err) {
          errorNotification(
            'Looks like something went wrong. Please try again later.'
          );
        }
      },
    });
  };

  const columns = [
    {
      title: 'Item',
      dataIndex: 'name',
      key: 'keyword',
      fixed: 'left' as const,
      align: 'center' as const,
      withSearch: true,
      width: 150,
    },
    {
      title: 'Stores',
      dataIndex: 'storeCount',
      align: 'center' as const,
      width: 150,
      render: function StoresNumber(
        text: string,
        record: GetAllAreasOfInfluence_getAllAreasOfInfluence_entities
      ) {
        return (
          <Button
            disabled={record?.storeCount === 0}
            type="link"
            onClick={() => showStoresModal(record)}
            title={`Manage stores with area of interest: ${record?.name}`}
          >
            {record?.storeCount || '-'}
          </Button>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 120,
      render: function Actions(
        text: string,
        record: GetAllAreasOfInfluence_getAllAreasOfInfluence_entities
      ) {
        return (
          <Space size="middle" direction="vertical">
            <Button
              type="primary"
              onClick={() => {
                showEditModal(record);
              }}
            >
              Edit
            </Button>

            <Button type="default" onClick={() => handleDelete(record.id)}>
              Remove
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <div className={styles.addBtnWrapper}>
        <h1>Manage Areas of Interest </h1>
        <Button type="primary" onClick={handleShowAddProductModal}>
          Add new item
        </Button>
      </div>

      <Modal
        title={selectedRecord?.id ? 'Edit item' : 'New item'}
        visible={
          isCreateModalVisible || (!!selectedRecord && isEditModalVisible)
        }
        onCancel={hideCreateEditModal}
        className="createEditItemModal"
        footer={null}
        destroyOnClose
      >
        <CreateEditAreaOfInfluence
          currentAction={selectedRecord?.id ? 'Edit' : 'New'}
          record={selectedRecord}
          hideItemModal={hideCreateEditModal}
          getAreaInput={getInput}
        />
      </Modal>

      <Modal
        title={`Store with area of interest ${selectedRecord?.name || ''}`}
        visible={isStoresModalVisible}
        onCancel={hideStoresModal}
        footer={null}
        destroyOnClose
        width="50vw"
      >
        {selectedRecord && (
          <Stores
            record={selectedRecord}
            getAreaInput={getInput}
            onFinish={hideStoresModal}
          />
        )}
      </Modal>

      <Table<GetAllAreasOfInfluence_getAllAreasOfInfluence_entities>
        columns={columns}
        data={data?.getAllAreasOfInfluence?.entities}
        scroll={{ x: 800 }}
        loading={loading || deleteAreaLoading}
        total={data?.getAllAreasOfInfluence?.total}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
      />
    </div>
  );
};

export default ManageAreaOfInfluence;
