// Config
import ENV from 'api/env';

export const getCookiesDomain = (): string => {
  if (ENV.REACT_APP_ENV === 'production' || ENV.REACT_APP_ENV === 'staging') {
    return '.millions.co';
  }

  return '.localhost';
};

export const getCookiesTokenEnvName = (): string => {
  if (ENV.REACT_APP_ENV === 'production') {
    return 'prod';
  }

  if (ENV.REACT_APP_ENV === 'staging') {
    return 'stg';
  }

  return 'local';
};
