import React, { FC } from 'react';
import { useQuery } from '@apollo/client';
import { Spin, Button } from 'antd';
import { uniqBy } from 'lodash';
// Api
import { GET_MERCH_PRODUCT_V2 } from 'api/merch/queries';
// Types
import { GetAdminMerchProducts_getAdminMerchProducts_entities } from 'api/merch/types/GetAdminMerchProducts';
import {
  GetMerchProductV2,
  GetMerchProductV2Variables,
  GetMerchProductV2_getMerchProduct,
} from 'api/merch/types/GetMerchProductV2';
import { MerchType } from 'api/graphql-global-types';
// Helpers
import {
  computeMerchBackgroundColor,
  getProductColors,
} from 'components/common/ManageMerch/helpers';
import { listGenders } from 'helpers/mergeMerch';
// Styles
import styles from './MergeMerchParent.module.scss';

interface MergeMerchParentProp {
  parentMerch: GetAdminMerchProducts_getAdminMerchProducts_entities;
  handleMerge: () => void;
}

const MergeMerchParent: FC<MergeMerchParentProp> = ({
  parentMerch,
  handleMerge,
}) => {
  const { data: myProductData, loading } = useQuery<
    GetMerchProductV2,
    GetMerchProductV2Variables
  >(GET_MERCH_PRODUCT_V2, {
    variables: {
      input: {
        id: parentMerch?.id,
      },
    },
  });

  const merch = myProductData?.getMerchProduct;
  const colors =
    (merch && getProductColors(merch as GetMerchProductV2_getMerchProduct)) ||
    [];
  const uniqueMockupImageURLs = uniqBy(
    merch?.merchProductVariants,
    'mockupImageURL'
  ).map((variant) => variant.mockupImageURL);

  if (loading) {
    return <Spin size="large" />;
  }

  const parentGenders = parentMerch.genders?.length
    ? listGenders(parentMerch.genders, parentMerch.type === MerchType.TShirt)
    : '';

  return (
    <div className={styles.merchParentRoot}>
      <div className={styles.merchImages}>
        {uniqueMockupImageURLs.map((item, index) => (
          <div key={index} className={styles.imageWrapper}>
            <img className={styles.photo} src={item || ''} alt="" />
          </div>
        ))}
      </div>
      <div className={styles.merchInformation}>
        <p>Merch title: {merch?.title || ''}</p>
        <p>Gender: {parentGenders}</p>
        <div className={styles.colors}>
          Colors:
          {colors.map((item) => {
            return (
              <span
                key={item.color}
                className={styles.colorItem}
                style={computeMerchBackgroundColor(
                  item.colorCode,
                  item.colorCode2
                )}
              />
            );
          })}
        </div>
        <p>Request profit: {merch?.requestedProfit} $</p>
      </div>
      <div className={styles.mergeButton}>
        <Button type="primary" onClick={handleMerge}>
          MERGE INTO THIS
        </Button>
      </div>
    </div>
  );
};

export default MergeMerchParent;
