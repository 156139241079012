import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout } from 'antd';
// Hooks
import { useAppContext } from 'hooks';
// Types
import { UserRole } from 'api/graphql-global-types';
// Constants
import {
  DASHBOARD,
  MY_DESIGN_REQUESTS,
  LIST_OF_ASSIGNED_STREAMS,
  MY_INTERVIEWS,
  MY_EDITING_REQUESTS,
} from 'constants/routes';
// Components
import LoginForm from 'components/common/LoginForm/LoginForm';
// Styles
import styles from './Login.module.scss';

const { Header, Content } = Layout;

const Login = (): JSX.Element => {
  const history = useHistory();
  const { authUser } = useAppContext();
  const isDesigner = authUser?.role === UserRole.Designer;
  const isModerator = authUser?.role === UserRole.Moderator;
  const isVideoEditor = authUser?.role === UserRole.VideoEditor;
  const isInterviewer = authUser?.isInterviewer || false;

  useEffect(() => {
    if (authUser?.accessToken) {
      history.push(
        isModerator
          ? LIST_OF_ASSIGNED_STREAMS
          : isDesigner
          ? MY_DESIGN_REQUESTS
          : isVideoEditor
          ? MY_EDITING_REQUESTS
          : isInterviewer
          ? MY_INTERVIEWS
          : DASHBOARD
      );
    }
  }, [
    isDesigner,
    isInterviewer,
    isModerator,
    authUser,
    history,
    isVideoEditor,
  ]);

  return (
    <Layout id="Login" className={styles.root}>
      <Header className={styles.header}>
        <div className={styles.logo} />
      </Header>

      <Content className={styles.content}>
        <LoginForm title="Please, login" />
      </Content>
    </Layout>
  );
};

export default Login;
