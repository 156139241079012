import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Select, Typography } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
// Constants
import { commonTimeFormat } from 'constants/global';
import { TIME_ZONE_OPTIONS } from 'constants/timeZone';
// Api
import { GET_MY_INTERVIEWS } from 'api/interview/queries';
import {
  CREATE_STREAM_IMAGE,
  SCHEDULE_INTERVIEW,
} from 'api/interview/mutations';
// Types
import {
  CreateStreamImage,
  CreateStreamImageVariables,
  CreateStreamImage_createStreamImage,
} from 'api/interview/types/CreateStreamImage';
import {
  InterviewerTasksOrderBy,
  InterviewStatus,
  SortDirection,
} from 'api/graphql-global-types';
import { GetMyInterviews_getMyInterviews_entities } from 'api/interview/types/GetMyInterviews';
import {
  ScheduleInterview,
  ScheduleInterviewVariables,
} from 'api/interview/types/ScheduleInterview';
// Helpers
import {
  getTimeZoneName,
  getCurrentTimeZoneOffset,
  getDateWithoutCurrentTimeZone,
} from 'helpers/timeZone';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import CroppedPictureInput from 'components/common/CroppedPictureInput/CroppedPictureInput';

const { Text } = Typography;
const { Option } = Select;

export type UploadImage = {
  data_url?: string;
  data_key?: string;
  originFileObj?: UploadFile;
};

type ImageToUpload = {
  fields: string;
  url: string;
  name: string;
  type: string;
  file: File | '';
};

type ScheduleInterviewProps = {
  interview: GetMyInterviews_getMyInterviews_entities | null;
  hideScheduleModal: () => void;
  isReschedule?: boolean;
};
const ScheduleInterviewModal = ({
  interview,
  hideScheduleModal,
  isReschedule = false,
}: ScheduleInterviewProps): JSX.Element => {
  const [scheduleInterview, { loading }] = useMutation<
    ScheduleInterview,
    ScheduleInterviewVariables
  >(SCHEDULE_INTERVIEW, {
    refetchQueries: [
      {
        query: GET_MY_INTERVIEWS,
        variables: {
          input: {
            direction: SortDirection.DESC,
            orderBy: InterviewerTasksOrderBy.createdAt,
            limit: 10,
            offset: 0,
          },
        },
      },
    ],
  });

  const [, setPictureValidation] = useState<string>('');

  const [createStreamImage] = useMutation<
    CreateStreamImage,
    CreateStreamImageVariables
  >(CREATE_STREAM_IMAGE);

  const getImagesToUpload = (
    preSignedUrls: CreateStreamImage_createStreamImage[],
    images: UploadImage[]
  ): ImageToUpload[] => {
    const res: ImageToUpload[] = [];

    images.forEach((img, index) => {
      const originFileObj = img?.originFileObj;
      const preSignedUrl = preSignedUrls?.[index];

      res.push({
        fields: preSignedUrl?.fields,
        url: preSignedUrl?.url,
        name: preSignedUrl?.key,
        type: originFileObj?.type || '',
        file: (originFileObj as unknown as File) || '',
      });
    });

    return res;
  };

  const uploadImages = async (
    preSignedUrls: CreateStreamImage_createStreamImage[],
    images: UploadImage[]
  ): Promise<string[]> => {
    const imagesToUpload = getImagesToUpload(preSignedUrls, images);

    for (const image of imagesToUpload) {
      const { fields, url, name, type, file } = image;
      const formData = new FormData();

      Object.entries(JSON.parse(fields)).forEach(([key, value]) => {
        formData.append(key, value as string);
      });

      formData.append('key', name);
      formData.append('Content-Type', type);
      formData.append('file', file);

      await fetch(url, {
        method: 'POST',
        body: formData,
      });
    }

    return imagesToUpload.map(({ name }) => name);
  };

  const handleScheduleInterview = async (values: any) => {
    const timeZoneOffset = momentTimezone(values.date)
      .tz(getTimeZoneName(values.tzCode))
      .utcOffset();

    const shouldUpload = Boolean(Array.isArray(values.streamImage));

    if (shouldUpload) {
      const interviewerId = interview?.interviewer?.id as string;
      const contentType = values?.streamImage[0]?.type;
      const ext = contentType?.split('/')[1];

      if (ext && contentType && interviewerId) {
        const { data } = await createStreamImage({
          variables: {
            input: {
              files: [
                {
                  contentType,
                  ext,
                },
              ],
            },
          },
        });

        try {
          if (data) {
            const imagesUrls = await uploadImages(
              data?.createStreamImage,
              values.streamImage
            );

            await scheduleInterview({
              variables: {
                input: {
                  id: interview?.id as string,
                  name: values.streamName ? values.streamName : null,
                  imageFileKey: imagesUrls[0],
                  scheduleDate: {
                    scheduleDate: moment(values.date)
                      .add(getCurrentTimeZoneOffset(), 'minute')
                      .startOf('minute')
                      .toISOString(),
                    timeZone: {
                      tzCode: values.tzCode,
                      offset: timeZoneOffset,
                    },
                  },
                },
              },
            });
            successNotification('The stream has been created successfully');
            hideScheduleModal();
          }
        } catch (error) {
          errorNotification((error as Error)?.message);
        }
      }
    } else {
      try {
        await scheduleInterview({
          variables: {
            input: {
              id: interview?.id as string,
              name: values.streamName ? values.streamName : null,
              scheduleDate: {
                scheduleDate: moment(values.date)
                  .add(getCurrentTimeZoneOffset(), 'minute')
                  .startOf('minute')
                  .toISOString(),
                timeZone: {
                  tzCode: values.tzCode,
                  offset: timeZoneOffset,
                },
              },
            },
          },
        });
        successNotification('The stream has been created successfully');
        hideScheduleModal();
      } catch (error) {
        errorNotification((error as Error)?.message);
      }
    }
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (isReschedule && interview) {
      const streamDate = getDateWithoutCurrentTimeZone(
        interview?.stream?.scheduleDate
      );
      form.setFieldsValue({
        date: interview ? moment(streamDate) : null,
        tzCode: interview?.stream?.timeZone?.tzCode,
        streamName: interview.stream?.name,
        streamImage: interview.stream?.mainImageUrl,
      });
    } else {
      form.setFieldsValue({
        tzCode: 'ET',
      });
    }
  }, [interview, form, isReschedule]);

  return (
    <Form
      form={form}
      layout="horizontal"
      name="scheduleStream"
      autoComplete="off"
      onFinish={handleScheduleInterview}
    >
      <Form.Item
        name="date"
        label={<Text>Set up the date</Text>}
        rules={[
          {
            required: true,
            message: 'Please enter the date',
          },
        ]}
      >
        <DatePicker
          data-testid="streamDate"
          showTime
          format={commonTimeFormat}
          style={{ width: 250 }}
          minuteStep={15}
          showNow={false}
        />
      </Form.Item>

      <Form.Item name="tzCode" label={<Text>Time Zone</Text>}>
        <Select
          showSearch
          placeholder="Select time zone"
          optionFilterProp="children"
        >
          {TIME_ZONE_OPTIONS.map(({ tzCode, label }) => {
            return (
              <Option key={tzCode} value={tzCode}>
                {label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      {interview?.status === InterviewStatus.Invited ||
      interview?.status === InterviewStatus.InviteViewed ? (
        <></>
      ) : (
        <>
          <Form.Item name="streamName" label={<Text>Stream Title</Text>}>
            <Input />
          </Form.Item>

          <Form.Item name="streamImage" label="Stream Cover Image">
            <CroppedPictureInput
              setPictureValidation={setPictureValidation}
              defaultImage={interview?.stream?.mainImageUrl || null}
              buttonTitle="Upload stream cover image"
            />
          </Form.Item>
        </>
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ScheduleInterviewModal;
