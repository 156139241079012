import React, {
  forwardRef,
  DetailedHTMLProps,
  TextareaHTMLAttributes,
} from 'react';
import cn from 'classnames';
// Types
import { InputSize } from 'uiShared/Input/Input';
// Ui2
import Text from 'uiShared/Text/Text';
// Styles
import styles from './Textarea.module.scss';

type TextAreaProps = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  label?: string;
  error?: string;
  resize?: boolean;
  fieldSize?: InputSize;
  theme?: 'white' | 'black';
};
// NOTE: copy from ui2 from fs-web
const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      error,
      name,
      label,
      rows = 2,
      className,
      children,
      resize = false,
      fieldSize = 'large',
      theme = 'white',
      ...rest
    },
    ref
  ) => {
    return (
      <div className={cn(styles.textFieldContainerWrapper, className)}>
        <div
          className={cn(styles.textFieldContainer, {
            [styles[`size-${fieldSize}`]]: !!fieldSize,
            [styles.error]: error,
            [styles[`theme-${theme}`]]: !!theme,
          })}
        >
          {label && (
            <label
              htmlFor={name}
              className={cn(styles.label, {
                [styles[`size-${fieldSize}`]]: !!fieldSize,
              })}
            >
              {label}
            </label>
          )}

          <textarea
            className={cn(styles.textField, {
              [styles.error]: !!error,
              [styles.withResize]: resize,
            })}
            rows={rows}
            ref={ref}
            name={name}
            id={name}
            {...rest}
          >
            {children}
          </textarea>
        </div>

        {error && (
          <Text
            color="maximum-red"
            xs="extra-small"
            className={cn(styles.errorHint, {
              [styles[`size-${fieldSize}`]]: !!fieldSize,
            })}
          >
            {error}
          </Text>
        )}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';

export default TextArea;
