import React, { ChangeEvent, useState } from 'react';
import { Input, Space, Image, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
// Types
import { SpaceProps } from 'antd/lib/space';
import { CtaOption } from 'pages/HomePageManagement';
// Components
import UploadImageModal from 'pages/HomePageManagement/components/UploadImageModal/UploadImageModal';
// Styles
import './styles.scss';

export enum CtaItemType {
  Merch = 'merch',
  Enroll = 'enroll',
}

export type SetupCtaItemProps = {
  onCtaChange: (
    index: number,
    data: string | boolean,
    field: string,
    imageId?: string
  ) => void;
  item?: CtaOption;
  index: number;
  type: CtaItemType;
};

const SetupCtaItem = ({
  onCtaChange,
  item,
  index,
  type,
}: SetupCtaItemProps): JSX.Element => {
  const [isUploadVisible, setUploadVisible] = useState<boolean>(false);

  const handleImageChange = (url: string, id: string) => {
    onCtaChange(index, url, 'image', id);
  };

  const handleBannerInputChange = ({
    target: { value, name },
  }: ChangeEvent<HTMLInputElement>) => {
    onCtaChange(index, value, name);
  };

  const handleBannerCheckboxChange = ({
    target: { name, checked },
  }: ChangeEvent<HTMLInputElement>) => {
    onCtaChange(index, checked, name);
  };

  const toggleUploadModal = () => {
    setUploadVisible((prev) => !prev);
  };

  const sectionProps: SpaceProps = {
    style: { width: '100%', marginBottom: 14 },
    direction: 'vertical',
  };

  return (
    <>
      <Space
        style={{ width: '100%', height: '112px', marginBottom: 9 }}
        direction="vertical"
      >
        {item?.image ? (
          <Image
            width={102}
            height={102}
            src={item.image}
            preview={false}
            onClick={toggleUploadModal}
            className="setup-banners-item-image"
            alt={item.title || 'CTA item'}
            style={{ cursor: 'pointer' }}
          />
        ) : (
          <Button
            className="setup-banners-item-upload-button ant-upload ant-upload-select-picture-card"
            type="dashed"
            size="large"
            color="primary"
            onClick={toggleUploadModal}
          >
            <PlusOutlined />
            <span>Choose image</span>
          </Button>
        )}
      </Space>

      <Space {...sectionProps}>
        <Input
          name="title"
          value={item?.title || ''}
          onChange={handleBannerInputChange}
          placeholder="Type title"
        />
      </Space>

      <Space {...sectionProps}>
        <Input
          name="content"
          value={item?.content || ''}
          onChange={handleBannerInputChange}
          placeholder="Type description"
        />
      </Space>

      <Space {...sectionProps}>
        <Input
          name="buttonContent"
          value={item?.buttonContent || ''}
          onChange={handleBannerInputChange}
          placeholder="Type button content"
        />
      </Space>

      {type === CtaItemType.Enroll && (
        <Space {...sectionProps}>
          <Input
            name="link"
            value={item?.link || ''}
            onChange={handleBannerInputChange}
            placeholder="Type link"
          />
        </Space>
      )}

      <Space {...sectionProps}>
        <Input
          name="linkContent"
          value={item?.linkContent || ''}
          onChange={handleBannerInputChange}
          placeholder={
            type === CtaItemType.Enroll
              ? 'Type link title'
              : 'Type text for promocodes'
          }
        />
      </Space>

      {type === CtaItemType.Merch && (
        <Space {...sectionProps}>
          <Input
            name="buttonLink"
            value={item?.buttonLink || ''}
            onChange={handleBannerInputChange}
            placeholder="Type button link"
          />
        </Space>
      )}

      <Space {...sectionProps}>
        <div className="checkbox-wrapper">
          <Input
            className="checkbox-input"
            id="isBannerVisible"
            name="isBannerVisible"
            type="checkbox"
            checked={Boolean(item?.isBannerVisible)}
            onChange={handleBannerCheckboxChange}
          />
          <label htmlFor="isBannerVisible">Is banner visible</label>
        </div>
      </Space>

      {isUploadVisible && (
        <UploadImageModal
          isVisible={isUploadVisible}
          onCancel={toggleUploadModal}
          title="Upload Banner Image"
          onImageChange={handleImageChange}
          acceptFileType=".png"
        />
      )}
    </>
  );
};

export default SetupCtaItem;
