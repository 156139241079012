import React, { ChangeEvent, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Col, Input, Modal, Row, Typography } from 'antd';
// Api
import { ARCHIVE_VIDEO_EDIT } from 'api/videoLab/mutation';
// Types
import { ButtonType } from 'antd/lib/button';
import {
  ArchiveVideoEdit,
  ArchiveVideoEditVariables,
} from 'api/videoLab/types/ArchiveVideoEdit';
import { VideoEditingRequestsFormattedData } from 'helpers/videoEditingRequest';
import { GET_VIDEO_EDIT_REQUESTS } from 'api/videoLab/queries';
import { VideoEditorOrderBy } from 'api/graphql-global-types';
// UI
import { errorNotification, successNotification } from 'ui/Notification';

type ArchiveVideoEditRequestProps = {
  request: VideoEditingRequestsFormattedData;
  buttonTitle?: string;
  buttonType?: ButtonType;
};

const { Text } = Typography;
const { TextArea } = Input;

const ArchiveVideoEditRequest: React.FC<ArchiveVideoEditRequestProps> = ({
  request,
  buttonTitle = 'Archive',
  buttonType = 'primary',
}) => {
  const [isArchiveModalVisible, setArchiveModalVisible] =
    useState<boolean>(false);
  const [feedback, setFeedback] = useState<string | undefined>(undefined);
  const [archiveVideoEdit, { loading: archiveVideoEditLoading }] = useMutation<
    ArchiveVideoEdit,
    ArchiveVideoEditVariables
  >(ARCHIVE_VIDEO_EDIT);

  const handleShowArchiveModal = () => {
    setArchiveModalVisible(true);
  };

  const onArchiveReasonChange = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback(value);
  };

  const handleHideArchiveModal = () => {
    setArchiveModalVisible(false);
    setFeedback(undefined);
  };

  const handleArchiveVideoEdit = async () => {
    if (request) {
      try {
        await archiveVideoEdit({
          variables: {
            input: {
              videoEditRequestId: request.id,
              videoEditorId: request.assignedEditorId,
              feedback,
            },
          },
          refetchQueries: [
            {
              query: GET_VIDEO_EDIT_REQUESTS,
              variables: {
                input: {
                  direction: 'ASC',
                  orderBy: VideoEditorOrderBy.createdAt,
                  limit: 10,
                  offset: 0,
                },
              },
            },
          ],
        });
        successNotification(
          `Request #${request.id} has been archived successfully`
        );
        handleHideArchiveModal();
      } catch (err) {
        errorNotification((err as Error)?.message);
        console.log('handleArchiveVideoEdit error:', { ...(err as Error) });
      }
    }
  };

  return (
    <>
      <Button
        type={buttonType}
        loading={archiveVideoEditLoading}
        onClick={handleShowArchiveModal}
      >
        {buttonTitle}
      </Button>
      <Modal
        title="Archive video edit request"
        visible={isArchiveModalVisible}
        okText="Archive"
        onOk={handleArchiveVideoEdit}
        okButtonProps={{ disabled: !feedback }}
        onCancel={handleHideArchiveModal}
        confirmLoading={archiveVideoEditLoading}
      >
        <Row>
          <Col>
            <Text>
              Archieve video edit request result by editor{' '}
              <strong>{`${request?.videoEditorName || ''}`}</strong> for request{' '}
              <strong>{`#${request?.id}`}</strong>
            </Text>
          </Col>
          <Col style={{ width: '100%' }}>
            <TextArea
              value={feedback}
              onChange={onArchiveReasonChange}
              placeholder="Type video archive feedback"
              allowClear
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ArchiveVideoEditRequest;
