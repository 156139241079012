import { gql } from '@apollo/client';

export const ExperienceFields = gql`
  fragment ExperienceFields on Experience {
    id
    comment
    description
    requestedPrice
    status
    storeId
    title
    hashtags {
      name
      id
    }
    createdAt
    deletedAt
    numberOfUnits
    price
    slug
    store {
      id
      firstName
      lastName
      storeDetails {
        id
        storeName
      }
    }
    images {
      id
      isMainImage
      experienceId
      experienceImageFileKey
    }
  }
`;

export const ExperienceRequestFields = gql`
  fragment ExperienceRequestFields on ExperienceRequest {
    createdAt
    customer {
      id
      lastName
      firstName
      email
      phoneNumber
    }
    customerId
    declineReason
    email
    earning
    experience {
      id
      comment
      description
      requestedPrice
      status
      title
      createdAt
      deletedAt
      numberOfUnits
      price
      slug
      images {
        id
        isMainImage
        experienceId
        experienceImageFileKey
      }
    }
    store {
      id
      slug
      role
      email
      phoneNumber
      storeDetails {
        id
        storeName
        avatarURL
      }
    }
    experienceId
    id
    paidAt
    paymentMethodId
    paymentMethod {
      type
      last4
    }
    price
    quantity
    requestStatus
    requestedPrice
    stripeIntentClientSecret
    stripeIntentID
    title
  }
`;
