import React, { useState } from 'react';
import { Tabs } from 'antd';
// Components
import ManageHashtags from 'pages/ManageHashtags';
import ManageAreaOfInfluence from 'pages/ManageAreaOfInfluence/ManageAreaOfInfluence';
import ManagePsychosocialKeywords from 'pages/ManagePsychosocialKeywords/ManagePsychosocialKeywords';

const { TabPane } = Tabs;

/*
In v1 of this feature we had different names, 
prod decided to update names for each module so:
OLD | NEW
hashtags = hashtags
Area of influence = Area of interest
Psychosocial keywords = Internal tags
*/

export enum ManageTagsTabs {
  Hashtags = 'hashtags',
  AreaOfInterest = 'areaOfInterest',
  InternalTags = 'internalTags',
}

const ManageTags = (): JSX.Element => {
  const [tabKey, setTabKey] = useState<ManageTagsTabs | string>(
    ManageTagsTabs.Hashtags
  );

  const handleTabChange = (activeKey: ManageTagsTabs | string) => {
    setTabKey(activeKey);
  };

  return (
    <Tabs size="large" activeKey={tabKey} onChange={handleTabChange}>
      <TabPane tab="Hashtags" key={ManageTagsTabs.Hashtags}>
        <ManageHashtags />
      </TabPane>
      <TabPane tab="Area of interest" key={ManageTagsTabs.AreaOfInterest}>
        <ManageAreaOfInfluence />
      </TabPane>
      <TabPane tab="Internal tags" key={ManageTagsTabs.InternalTags}>
        <ManagePsychosocialKeywords />
      </TabPane>
    </Tabs>
  );
};

export default ManageTags;
