import React, { useState } from 'react';
import { useAppContext } from 'hooks';
import cn from 'classnames';
import { useMutation } from '@apollo/client';
import {
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Button, Modal } from 'antd';
import {
  useHistory,
  useParams,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
// Api
import { DELETE_MERCH_PRODUCT } from 'api/merch/mutations';
// Types
import { UserRole } from 'api/graphql-global-types';
import {
  DeleteMerchProduct,
  DeleteMerchProductVariables,
} from 'api/merch/types/DeleteMerchProduct';
import { GetAdminMerchProducts_getAdminMerchProducts_entities } from 'api/merch/types/GetAdminMerchProducts';
import { DesignRequests_designRequests_entities_designCreationDetails_merchItems_merch } from 'api/designLab/types/DesignRequests';
// Helpers
import {
  getProductColors,
  computeMerchBackgroundColor,
  sortMerchProductVariantsByMainCustomImages,
} from '../../helpers';
import { formatCurrencyString } from 'helpers/utils';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import SocialMediaPack from '../SocialMediaPack/SocialMediaPack';
import SocialShareQR from 'components/common/SocialShareQR/SocialShareQR';
// Styles
import styles from './MerchItem.module.scss';

type MerchItemProps = {
  product:
    | GetAdminMerchProducts_getAdminMerchProducts_entities
    | DesignRequests_designRequests_entities_designCreationDetails_merchItems_merch;
  isMerchDesignReview?: boolean;
  store?: any;
  designRequestId?: string;
};

const { confirm } = Modal;

const MerchItem: React.FC<MerchItemProps> = ({
  product,
  isMerchDesignReview,
  store,
  designRequestId,
}) => {
  const { authUser } = useAppContext();
  const isDesigner = authUser?.role === UserRole.Designer;
  const isAthlete = store?.role === UserRole.Athlete;
  const isOrganization = store?.role === UserRole.Organization;
  const isContentCreator = store?.role === UserRole.ContentCreator;

  const match = useRouteMatch();
  const history = useHistory();
  const { storeId } = useParams<{ storeId: string | undefined }>();
  const location = useLocation();
  const manageMerchRole = `/${location.pathname.split('/')[1]}`;
  const [showSocialMediaPack, setShowSocialMediaPack] =
    useState<boolean>(false);
  const [showPromote, setShowPromote] = useState<boolean>(false);
  const shareableLink = `/${product?.store?.slug}/merch/${product?.slug}`;

  const [deleteMerch] = useMutation<
    DeleteMerchProduct,
    DeleteMerchProductVariables
  >(DELETE_MERCH_PRODUCT, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          getMerchProducts(existingProducts = [], { readField }) {
            return existingProducts?.entities?.filter(
              (merchRef: any) =>
                readField('id', merchRef) !== data?.deleteMerchProduct.id
            );
          },
        },
      });
    },
  });

  const colors = getProductColors(product);
  const price = formatCurrencyString(product.minPrice);

  const sortedVariantsByCustomImages =
    sortMerchProductVariantsByMainCustomImages(product.merchProductVariants);

  const initialVariant = sortedVariantsByCustomImages[0];

  const image =
    initialVariant?.customImages?.[0]?.customMerchImageURL ||
    product.mainImageUrl ||
    '';

  const type = product.type;

  const handleEditButtonClick = () => {
    let path = '';

    if (isMerchDesignReview) {
      path = `/manage-${
        isAthlete
          ? 'athlete'
          : isContentCreator
          ? 'content-creator'
          : isOrganization
          ? 'organization'
          : ''
      }-merch/${type}/${store?.id}/${
        product.id
      }?designRequestId=${designRequestId}`;
    } else {
      path = !storeId
        ? match.path
        : `${manageMerchRole}/${type}/${storeId}/${product.id}`;
    }

    history.push(path);
  };

  const handleRemoveButtonClick = () => {
    confirm({
      title: 'Are you sure you want to remove this product?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: handleProductRemove,
    });
  };

  const handleProductRemove = async () => {
    try {
      await deleteMerch({
        variables: {
          id: product.id,
        },
      });
      successNotification('Product successfully removed');
    } catch (e) {
      errorNotification();
      console.log('deleteMerch', { ...(e as Error) });
    }
  };

  const handleSocialMediaPackClick = () => setShowSocialMediaPack(true);

  const handlePromote = () => setShowPromote(true);

  const handleOnSocialMediaPackClose = () => setShowSocialMediaPack(false);

  const handlePromoteClose = () => setShowPromote(false);

  const createdBy = product.isCreatedByAdmin ? 'Admin' : 'Store';
  // disable the editing of already accepted designs
  const isActiveProduct = product.status === 'active';
  const disabledEditForDesigns = isActiveProduct && isMerchDesignReview;

  return (
    <>
      <div className={styles.merchCard}>
        <div className={styles.photoWrapper}>
          {product.productImageURL ? (
            <img src={image} alt={product.title} className={styles.photo} />
          ) : (
            <p className={styles.pendingStatus}>Image will be ready soon</p>
          )}

          <button
            type="button"
            disabled={
              disabledEditForDesigns ||
              !product.productImageURL ||
              product.status === 'pending-mockups'
            }
            className={cn(styles.iconButton, styles.edit)}
            onClick={handleEditButtonClick}
          >
            <EditOutlined />
          </button>
          {!isMerchDesignReview && (
            <button
              type="button"
              className={cn(styles.iconButton, styles.remove)}
              onClick={handleRemoveButtonClick}
            >
              <DeleteOutlined />
            </button>
          )}
        </div>
        <div className={styles.entry}>
          <span className={styles.supTitle}>Product name</span>
          <p className={styles.cardTitle}>{product.title}</p>
          <span className={styles.supTitle}>Status</span>
          <p className={styles.cardTitle}>{product.status}</p>
          <div className={styles.meta}>
            <div className={styles.metaBlock}>
              <span className={styles.metaTitle}>Colors</span>
              <div className={styles.colors}>
                {colors.map((item) => {
                  return (
                    <span
                      key={item.color}
                      className={styles.colorItem}
                      style={computeMerchBackgroundColor(
                        item.colorCode,
                        item.colorCode2
                      )}
                    />
                  );
                })}
              </div>
            </div>

            {!isDesigner && (
              <div className={styles.metaBlock}>
                <span className={styles.metaTitle}>Price</span>
                <p className={styles.priceValue}>{price}</p>
              </div>
            )}
            <div className={styles.metaBlock}>
              <span className={styles.metaTitle}>Created By</span>
              <p className={styles.priceValue}>{createdBy}</p>
            </div>
          </div>

          {product.autoSocialPackImages?.length && (
            <Button type="primary" onClick={handleSocialMediaPackClick}>
              Social Media Pack
            </Button>
          )}

          {isActiveProduct && (
            <Button
              type="primary"
              onClick={handlePromote}
              className={styles.promoteButton}
            >
              Promote
            </Button>
          )}
        </div>
      </div>

      <Modal
        title="Social Media Pack"
        visible={showSocialMediaPack}
        footer={null}
        onCancel={handleOnSocialMediaPackClose}
        width="90vw"
      >
        <SocialMediaPack merchItem={product} />
      </Modal>

      <Modal
        title="Promote"
        visible={showPromote}
        footer={false}
        onCancel={handlePromoteClose}
        width="90vw"
      >
        <SocialShareQR
          title="Share Merch"
          subTitle="Share this merch in any way convenient for you"
          path={shareableLink}
          twitterText="New merch alert! Check out my latest drop on @millionsdotco. Order now while inventory lasts! #newmerch #MILLIONSco"
          itemTitle={product?.title}
        />
      </Modal>
    </>
  );
};

export default MerchItem;
