import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Modal, Space } from 'antd';
import moment from 'moment/moment';
// UI
import Table from 'ui/Table';
// Types
import {
  GetStreamOrders,
  GetStreamOrders_adminStreamOrders_entities,
  GetStreamOrders_adminStreamOrders_entities_customer,
  GetStreamOrdersVariables,
} from 'api/streams/types/GetStreamOrders';
// API
import { GET_STREAM_ORDERS } from 'api/streams/queries';
// Components
import StreamOrderCancelButton from 'components/common/Stream/StreamOrderCancelButton/StreamOrderCancelButton';

type StreamPurchasesModalProps = {
  streamId: string;
  streamName: string | null;
  isFree: boolean;
  isVisible: boolean;
  onCancel: () => void;
};

const StreamPurchasesModal = ({
  streamId,
  streamName,
  isFree,
  isVisible = false,
  onCancel,
}: StreamPurchasesModalProps): JSX.Element => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, loading } = useQuery<GetStreamOrders, GetStreamOrdersVariables>(
    GET_STREAM_ORDERS,
    {
      variables: {
        input: {
          limit: pageSize,
          offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
          streamId,
        },
      },
      fetchPolicy: 'cache-and-network',
      skip: !isVisible,
    }
  );

  const columns = [
    {
      title: 'Email',
      dataIndex: ['customer', 'email'],
      align: 'left' as const,
      width: 100,
    },
    {
      title: 'Name',
      dataIndex: 'customer',
      align: 'left' as const,
      width: 150,
      render: (
        customer: GetStreamOrders_adminStreamOrders_entities_customer
      ) => {
        return `${customer.firstName} ${customer.lastName}`;
      },
    },
    {
      title: 'Order Status',
      dataIndex: 'orderStatus',
      align: 'left' as const,
      width: 130,
    },
    {
      title: 'Purchase date',
      dataIndex: 'createdAt',
      align: 'center' as const,
      width: 150,
      render: (createdAt: string) => moment(createdAt).format('ll'),
    },
    ...(isFree
      ? []
      : [
          {
            title: 'Actions',
            dataIndex: 'actions',
            fixed: 'right' as const,
            align: 'center' as const,
            width: 130,
            render: function Actions(_: any, record: any) {
              return (
                <Space size="middle" direction="vertical">
                  <StreamOrderCancelButton
                    orderStatus={record.orderStatus}
                    streamOrderId={record.id}
                  />
                </Space>
              );
            },
          },
        ]),
  ];

  return (
    <Modal
      title={
        <>
          {`Customer's that bought stream `}
          {streamName && <strong>{`"${streamName}"`}</strong>}
        </>
      }
      visible={isVisible}
      footer={null}
      onCancel={onCancel}
      width={700}
      zIndex={2000}
    >
      <Table<GetStreamOrders_adminStreamOrders_entities>
        columns={columns}
        data={data?.adminStreamOrders?.entities}
        scroll={{ x: 430 }}
        loading={loading}
        total={data?.adminStreamOrders?.total}
        defaultPageSize={pageSize}
        defaultCurrent={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
      />
    </Modal>
  );
};

export default StreamPurchasesModal;
