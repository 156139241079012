export const convertToMmSs = (durationInSeconds: number): string => {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = Math.round(durationInSeconds % 60);
  return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
    seconds < 10 ? '0' : ''
  }${seconds}`;
};

export const formatTime = (value: number | undefined): string => {
  if (value) {
    const date = new Date(value * 1000);
    const hh = padNumber(date?.getUTCHours());
    const mm = padNumber(date.getUTCMinutes());
    const ss = padNumber(date.getUTCSeconds());
    return `${hh}:${mm}:${ss}`;
  }
  return '00:00:00';
};

export const parseTime = (value: string | undefined): number => {
  const parts = value?.split(':');

  const hh = parts ? parseInt(parts[0], 10) : 0;
  const mm = parts ? parseInt(parts[1], 10) : 0;
  const ss = parts ? parseInt(parts[2], 10) : 0;
  return hh * 3600 + mm * 60 + ss;
};

export const padNumber = (num: number): string => {
  return num < 10 ? `0${num}` : num.toString();
};

export const calculateNoteStartTs = (time: number): number =>
  time - 15 < 0 ? 0 : time - 15;

export const calculateNoteEndTs = (
  time: number,
  duration: number | null | undefined
): number => (duration && time + 15 > duration ? duration : time + 15);
