import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Switch,
  Typography,
  InputNumber,
} from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
// Hooks
import { useDefaultStoreRedirectOnEmptyId } from 'hooks';
// Api
import { ADMIN_GET_AMAS } from 'api/ama/queries';
import {
  ADMIN_CREATE_AMAS,
  ADMIN_EDIT_AMAS,
  CREATE_AMA_PRESIGNED_URL,
} from 'api/ama/mutations';
// Types
import { CreateAmaPresignedUrl } from 'api/ama/types/CreateAmaPresignedUrl';
import {
  AmaType,
  AmaStatus,
  AmaItemEditInput,
  AmaItemCreateInput,
  MediaJobStatus,
} from 'api/graphql-global-types';
import {
  AdminGetAmas,
  AdminGetAmasVariables,
} from 'api/ama/types/AdminGetAmas';
import {
  AdminEditAmas,
  AdminEditAmasVariables,
} from 'api/ama/types/AdminEditAmas';
import {
  AdminCreateAmas,
  AdminCreateAmasVariables,
} from 'api/ama/types/AdminCreateAmas';
import { CropArea } from 'components/common/CropVideo/CropVideo';
// UI
import Tip from 'ui/Tip';
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import VideoPreview from 'ui/VideoPreview/VideoPreview';
// Helpers
import { getEvenNumber } from 'helpers/utils';
import { getIfVideoSupportedByCropper } from 'helpers/media';
// Styles
import styles from './SetupAma.module.scss';

const { Text } = Typography;

const DEFAULT_CROP_AREA = {
  width: 122,
  height: 270,
  x: 179,
  y: 0,
  zoom: 1,
};

const formItemLayout = {
  labelCol: { span: 14 },
  wrapperCol: { span: 10 },
};

const SetupAma = (): JSX.Element => {
  useDefaultStoreRedirectOnEmptyId();
  const { storeId } = useParams<{ storeId: string | undefined }>();

  const [amaVideo, setAmaVideo] = useState<
    Blob | File | null | undefined | string
  >(null);
  const [videoPreview, setVideoPreview] = useState<string | null | undefined>(
    null
  );
  const [amaVideoCrop, setAmaVideoCrop] = useState<CropArea>(DEFAULT_CROP_AREA);
  const [cropIsVisible, setCropIsVisible] = useState<boolean>(true);
  const [cropNotSupported, setCropNotSupported] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<
    {
      value: any;
      name: string[];
      errors?: string[];
    }[]
  >([]);
  const [deleteVideo, setDeleteVideo] = useState<boolean>(false);

  const { data, refetch, startPolling, stopPolling } = useQuery<
    AdminGetAmas,
    AdminGetAmasVariables
  >(ADMIN_GET_AMAS, {
    variables: {
      storeId: storeId || '',
    },
    pollInterval: 1000,
    skip: !storeId,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setVideoPreview(
        `${data.adminGetAmas[0]?.videoURL}?refreshParam=${Math.random()}` || ''
      );
    },
  });

  const hasAmaOriginally = !!data?.adminGetAmas[0]?.videoURL;

  const [createAma, { loading: createLoading }] = useMutation<
    AdminCreateAmas,
    AdminCreateAmasVariables
  >(ADMIN_CREATE_AMAS);
  const [editAma, { loading: editLoading }] = useMutation<
    AdminEditAmas,
    AdminEditAmasVariables
  >(ADMIN_EDIT_AMAS);

  const [createAmaPresignedUrl] = useMutation<CreateAmaPresignedUrl>(
    CREATE_AMA_PRESIGNED_URL
  );

  const amas = data?.adminGetAmas;

  const [form] = Form.useForm();

  useEffect(() => {
    if (amas?.length) {
      amas?.forEach((ama) => {
        form.setFieldsValue({
          [`${ama.type}.status`]: ama?.status === AmaStatus.Active,
        });
        form.setFieldsValue({
          [`${ama.type}.price`]: ama?.requestedPrice,
        });
        form.setFieldsValue({
          [`${ama.type}.daysToResponse`]: ama?.daysToResponse,
        });
        form.setFieldsValue({
          [`${ama.type}.welcomeVideo`]: ama?.videoURL,
        });
      });
      setAmaVideo(amas?.[0]?.videoURL || '');
      setVideoPreview(amas?.[0]?.videoURL || '');
      resetCrop();
    }
  }, [amas, form]);

  const resetCrop = () => {
    setCropNotSupported(false);
    setCropIsVisible(false);
    setAmaVideoCrop(DEFAULT_CROP_AREA);
  };

  const handleFileUpload = ({
    target: { validity, files },
  }: ChangeEvent<HTMLInputElement>): void => {
    if (validity.valid) {
      const file = files?.[0] || null;
      const videoURL = file ? URL?.createObjectURL(file) : null;
      if (!file || !videoURL) {
        return;
      }
      setAmaVideo(file);
      setVideoPreview(videoURL);
      setCropNotSupported(!getIfVideoSupportedByCropper(file?.type || ''));
      setCropIsVisible(true);
      form.setFieldsValue({
        [`Ama.status`]: true,
      });
      setDeleteVideo(false);
    }
  };

  const handleClearVideo = () => {
    setAmaVideo(undefined);
    if (hasAmaOriginally) {
      setDeleteVideo(true);
    }
    resetCrop();
    form.setFieldsValue({
      [`Ama.status`]: false,
    });
  };

  const handleVideoPreviewError = () => {
    setTimeout(() => {
      refetch();
    }, 10000);
  };

  const onFinish = async () => {
    // kluge for quick fix
    const isNewVideo = typeof amaVideo === 'object';

    let storeHaveAma = false;

    const videoArea =
      amaVideoCrop && amaVideoCrop.width > 0 && amaVideoCrop.height > 0
        ? {
            x: getEvenNumber(amaVideoCrop?.x),
            y: getEvenNumber(amaVideoCrop?.y),
            width: getEvenNumber(amaVideoCrop?.width),
            height: getEvenNumber(amaVideoCrop?.height),
            zoom: amaVideoCrop?.zoom || 1,
          }
        : DEFAULT_CROP_AREA;
    const updatedAmas: AmaItemCreateInput[] | AmaItemEditInput[] = [];

    // TODO: add 'AmaType.Shoutout' back when Shoutout feature will be turned on
    [AmaType.Ama].forEach((type: AmaType) => {
      const updatedAmaId = amas?.find((ama) => ama.type === type)?.id;
      const requestedPrice = Number(form.getFieldValue(`${type}.price`) || 0);
      const daysToResponse = Number(
        form.getFieldValue(`${type}.daysToResponse`) || 0
      );

      if (updatedAmaId) {
        storeHaveAma = true;
      }

      if (requestedPrice && daysToResponse) {
        updatedAmas.push({
          ...(updatedAmaId && { id: updatedAmaId }),
          status: form.getFieldValue(`${type}.status`)
            ? AmaStatus.Active
            : AmaStatus.Inactive,
          requestedPrice,
          daysToResponse,
          type,
        });
      }
    });

    if (deleteVideo) {
      const existingKey =
        data?.adminGetAmas?.[0]?.video?.videoFileUrl?.split('/ama/')[1] || '';

      const options = {
        variables: {
          input: {
            ...(isNewVideo
              ? {
                  videoV2: amaVideo
                    ? {
                        videoKey: existingKey,
                        area: videoArea,
                        type: (amaVideo as Blob | File)?.type || '',
                      }
                    : null,
                }
              : {}),
            amas: updatedAmas,
            storeId: storeId || '',
            ...(deleteVideo
              ? {
                  deleteVideo: true,
                }
              : {}),
          },
        },
      };
      try {
        if (storeHaveAma) {
          await editAma(options);
        } else {
          await createAma(options);
        }

        resetCrop();
        successNotification(
          `Ama has been ${storeHaveAma ? 'updated' : 'created'} successfully`
        );
      } catch (error) {
        errorNotification((error as Error)?.message);
        console.error(error);
      }
    } else {
      const getUrls = await createAmaPresignedUrl();

      if (getUrls.data) {
        const { fields, url, key } = getUrls.data?.createAmaPresignedUrl ?? {};

        let videoKey = '';

        const formData = new FormData();

        Object.entries(JSON.parse(fields)).forEach(([k, v]) => {
          formData.append(k, v as string | Blob);
        });
        const fileExt =
          amaVideo instanceof File ? amaVideo.name?.split('.').pop() : 'mov';

        const keyWithExt = `${key}.${fileExt}`;
        // for file that has mkv extension, file type is empty string
        // we need to add 'video/mkv' for file type
        const type =
          amaVideo instanceof File
            ? fileExt === 'mkv'
              ? 'video/mkv'
              : amaVideo.type
            : 'video/webm';

        videoKey = keyWithExt;

        if (amaVideo) {
          formData.append('key', keyWithExt);
          formData.append('Content-Type', type);
          formData.append('file', amaVideo);
        }

        const options = {
          variables: {
            input: {
              ...(isNewVideo
                ? {
                    videoV2: amaVideo
                      ? {
                          videoKey,
                          area: videoArea,
                          type: (amaVideo as Blob | File)?.type || '',
                        }
                      : null,
                  }
                : {}),
              amas: updatedAmas,
              storeId: storeId || '',
              ...(deleteVideo
                ? {
                    deleteVideo: true,
                  }
                : {}),
            },
          },
        };

        try {
          const response = await fetch(url, {
            method: 'POST',
            body: formData,
          });

          if (!response.ok) {
            throw new Error('Failed to upload to S3');
          }

          if (storeHaveAma) {
            await editAma(options);
          } else {
            await createAma(options);
          }

          resetCrop();
          successNotification(
            `Ama has been ${storeHaveAma ? 'updated' : 'created'} successfully`
          );
        } catch (error) {
          errorNotification((error as Error)?.message);
          console.error(error);
        }
      }
    }
  };

  const isSubmitIsDisabled = Boolean(
    formFields?.some(({ value }) => value === null || value === '') ||
      (!amaVideo && !deleteVideo) ||
      formFields?.filter(({ errors }) => errors?.length)?.length ||
      cropNotSupported ||
      deleteVideo
  );

  const isLoading = createLoading || editLoading;

  useEffect(() => {
    const mediaJobStatus = data?.adminGetAmas[0]?.video?.jobStatus;

    if (
      mediaJobStatus === MediaJobStatus.COMPLETED ||
      mediaJobStatus === null
    ) {
      stopPolling();
    }

    if (mediaJobStatus === MediaJobStatus.FAILED) {
      stopPolling();

      errorNotification('Please change video file format');
    }

    if (mediaJobStatus === MediaJobStatus.PROCESSING) {
      startPolling(1000);
    }
    // eslint-disable-next-line
  }, [data?.adminGetAmas, startPolling, stopPolling]);

  return (
    <div className={styles.root}>
      <Form
        form={form}
        layout="horizontal"
        name="setupAma"
        onFinish={onFinish}
        autoComplete="off"
        {...formItemLayout}
        fields={formFields}
        onFieldsChange={(_, allFields: any) => setFormFields(allFields)}
      >
        <Row gutter={[16, 16]} justify="start">
          <Col sm={24} md={14} lg={16}>
            <Row gutter={[16, 16]} justify="start">
              {/* TODO: add 'AmaType.Shoutout' back when Shoutout feature will be turned on */}
              {[AmaType.Ama].map((type: AmaType) => (
                <Col key={type} span={24}>
                  <Card
                    title={
                      type === AmaType.Shoutout
                        ? 'Personal Shout Out Settings'
                        : 'AMA Settings'
                    }
                  >
                    <Form.Item
                      name={`${type}.status`}
                      label={
                        <Text>
                          AMA status
                          <Tip
                            title={
                              "If disabled, users won't see the AMA on store's page"
                            }
                          />
                        </Text>
                      }
                    >
                      <Switch
                        checkedChildren="Enabled"
                        unCheckedChildren="Disabled"
                        checked={form.getFieldValue(`${type}.status`)}
                      />
                    </Form.Item>

                    <Form.Item
                      name={`${type}.price`}
                      label={
                        <Text>
                          Requested price ($)
                          <Tip title="The final price will be increased due to the platform's fee" />
                        </Text>
                      }
                      rules={[
                        {
                          type: 'number',
                          min: 1,
                          message:
                            "Field can't be empty and should be 1 or more. Please, select the number of days to response",
                        },
                      ]}
                    >
                      <InputNumber min={0} max={799999} step={1} />
                    </Form.Item>

                    <Form.Item
                      name={`${type}.daysToResponse`}
                      label={
                        <Text>
                          Time for response (days)
                          <Tip title="The maximum number of days to response is 7" />
                        </Text>
                      }
                      rules={[
                        {
                          type: 'number',
                          min: 1,
                          max: 7,
                          message:
                            "Field can't be empty and should be between 1 and 7. Please, select the number of days to response",
                        },
                      ]}
                    >
                      <InputNumber min={1} max={7} step={1} />
                    </Form.Item>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>

          <Col sm={24} md={10} lg={8}>
            <Card
              title={
                <Text>
                  Welcome video
                  <Tip title="Upload Your welcome video to present Your self" />
                </Text>
              }
            >
              <Form.Item
                name="welcomeVideo"
                validateStatus={
                  amaVideo === null ? '' : !amaVideo ? 'error' : ''
                }
                wrapperCol={{ span: 24 }}
                style={{ margin: 0 }}
              >
                <div className={styles.videoWrapper}>
                  <VideoPreview
                    video={videoPreview}
                    show={Boolean(amaVideo)}
                    onError={handleVideoPreviewError}
                    isCropVisible={cropIsVisible}
                    cropNotSupported={cropNotSupported}
                    onCropVideoComplete={setAmaVideoCrop}
                    wrapperClassName={
                      amaVideo && !cropNotSupported
                        ? styles.videoPreviewWrapper
                        : undefined
                    }
                    videoClassName={styles.videoPreviewVideo}
                  />
                  <div>
                    {!amaVideo ? (
                      <>
                        <input
                          type="file"
                          id="file"
                          accept="video/*"
                          onChange={handleFileUpload}
                        />
                        <label htmlFor="file">
                          <UploadOutlined />
                          <span> Select file</span>
                        </label>
                      </>
                    ) : (
                      <Button
                        className={styles.clearButton}
                        onClick={handleClearVideo}
                      >
                        <DeleteOutlined />
                        <span> Clear</span>
                      </Button>
                    )}
                  </div>
                </div>
              </Form.Item>
            </Card>
          </Col>

          <Col span={24}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isSubmitIsDisabled}
                loading={isLoading}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SetupAma;
