import { MutableRefObject } from 'react';
// Helpers
import { formatCurrencyString } from 'helpers/utils';
// Types
import {
  GetAdminMerchProducts_getAdminMerchProducts_entities,
  GetAdminMerchProducts_getAdminMerchProducts_entities_merchProductVariants,
} from 'api/merch/types/GetAdminMerchProducts';
import { GetMerchProduct_getMerchProduct_merchProductVariants } from 'api/merch/types/GetMerchProduct';
import {
  Age,
  Gender,
  MerchCustomImageV2Input,
  MerchProductCustomImageSetInput,
  MerchProductPrintFileSetInput,
  MerchProductVariantSet,
  MerchType,
  PlacementTypes,
  VariantPrintFileInput,
} from 'api/graphql-global-types';
import { DesignRequests_designRequests_entities_designCreationDetails_merchItems_merch } from 'api/designLab/types/DesignRequests';
import {
  GetMerchProductV2_getMerchProduct,
  GetMerchProductV2_getMerchProduct_merchProductVariants,
} from 'api/merch/types/GetMerchProductV2';
import {
  GetPRProductByTypeV2,
  GetPRProductByTypeV2_getPRProductByTypeV2,
  GetPRProductByTypeV2_getPRProductByTypeV2_data_product_variants,
  GetPRProductByTypeV2_getPRProductByTypeV2_data_templates_templates,
} from 'api/merch/types/GetPRProductByTypeV2';
import { PresignedUrlResultItem } from 'helpers/single-uploader';
import {
  BuildData,
  CustomImage,
  PrintCoordinates,
  PrintFiles,
  SelectedPrintImages,
} from './CreateEditMerch/MerchConstructor/MerchConstructor';
import { GetPrintImages_getPrintImages } from 'api/merch/types/GetPrintImages';

type ColorPickerOption = {
  value: string;
  disabled?: boolean;
};

export type Options = Record<
  string,
  GetMerchProduct_getMerchProduct_merchProductVariants & {
    colorOptions: ColorPickerOption[];
    sizeOptions: {
      value: string;
      label: string;
      disabled: boolean;
    }[];
  }
>;

export const getMerchOptions = (
  merch: GetMerchProduct_getMerchProduct_merchProductVariants[] | undefined
): Options => {
  const availableColors = new Set<string>();
  const availableSizes = new Set<string>();
  const merchBySize: any = {};
  const merchByColor: any = {};

  merch?.forEach((item: any) => {
    availableColors.add(item.colorCode);
    if (item.size) {
      availableSizes.add(item.size);
    }
    merchBySize[`${item.size}`] = {
      ...merchBySize[`${item.size}`],
      [item.colorCode]: true,
    };
    merchByColor[`${item.colorCode}`] = {
      ...merchByColor[`${item.colorCode}`],
      ...(item?.size && { [item.size]: true }),
    };
  });

  const getMerchSizeOptions = (colorCode: string) => {
    const sizeOptions: {
      value: string;
      label: string;
      disabled: boolean;
    }[] = [];

    availableSizes.forEach((item) => {
      sizeOptions.push({
        value: item,
        label: item,
        disabled: !merchBySize[item][colorCode],
      });
    });

    return sizeOptions;
  };

  const getMerchColorOptions = (size: string | null) => {
    const colorOptions: {
      value: string;
      disabled: boolean;
    }[] = [];

    if (size) {
      availableColors.forEach((item) => {
        colorOptions.push({
          value: item,
          disabled: !merchByColor[item][size],
        });
      });
    }

    return colorOptions;
  };

  return (
    merch?.reduce((acc: any, value: any) => {
      acc[`${value.size || ''}-${value.colorCode}`] = {
        ...value,
        colorOptions: getMerchColorOptions(value.size),
        sizeOptions: getMerchSizeOptions(value.colorCode),
      };

      return acc;
    }, {}) || {}
  );
};

type GetProductColor = {
  color: string;
  colorCode: string;
  colorCode2: string | null;
};

export type GetProductColors = GetProductColor[];

export const getProductColors = (
  product:
    | GetAdminMerchProducts_getAdminMerchProducts_entities
    | DesignRequests_designRequests_entities_designCreationDetails_merchItems_merch
    | GetMerchProductV2_getMerchProduct
): GetProductColors => {
  const colorOptions: GetProductColors = [];

  const availableColors: Record<string, GetProductColor> = {};

  product.merchProductVariants?.forEach((item: any) => {
    if (!availableColors[item.color]) {
      const color = {
        color: item.color,
        // fix 'White' color because the "printful" send for us
        // a '#e2e3de'  color which is a wrong and we rewrite it below
        colorCode: item.color === 'White' ? '#fff' : item.colorCode,
        colorCode2: item.colorCode2 || null,
      };

      availableColors[item.color] = color;
      colorOptions.push(color);
    }
  });

  return colorOptions;
};

export const getMerchVariantsLowestPrice = (
  variants: GetMerchProduct_getMerchProduct_merchProductVariants[] | undefined
): number => {
  const allPrices: number[] = [];

  variants?.forEach(({ price }: any) => allPrices.push(price));

  return Math.min(...allPrices) || 0;
};

type PRProductColor = {
  id: number;
  color: string;
  color2: string | null;
  title: string;
  price: string | null;
  image: string | null;
};

type PRProductColors = PRProductColor[];

export const getPRProductsColorsVariants = (
  productByType: GetPRProductByTypeV2_getPRProductByTypeV2 | undefined
): PRProductColors => {
  const uniqColors = new Set<string>();
  const colors: PRProductColors = [];

  productByType?.data.product?.variants?.forEach((item) => {
    if (
      item?.color &&
      !uniqColors.has(item.color) &&
      item.id &&
      item.color_code
    ) {
      uniqColors.add(item.color);
      colors.push({
        id: item.id,
        // fix 'White' color because the "printful" send for us
        // a '#e2e3de'  color which is a wrong and we rewrite it below
        color: item.color === 'White' ? '#fff' : item.color_code,
        color2: item.color_code2,
        title: item.color,
        price: item.price,
        image: item.image || null,
      });
    }
  });

  return colors;
};

export const findColorByTitle = (
  colorOptions: PRProductColors,
  title: string
): PRProductColor | undefined => {
  return colorOptions.find((option) => option.title === title);
};

export const createMerchTitle = (merchType: keyof typeof MerchType): string => {
  switch (merchType) {
    case MerchType.TShirt: {
      return 't-shirts';
    }
    case MerchType.Hoodie: {
      return 'hoodies';
    }
    case MerchType.Hat: {
      return 'Hats';
    }
    case MerchType.Joggers: {
      return 'Joggers';
    }
    case MerchType.RashGuard: {
      return 'compression shirts';
    }
    default: {
      return '';
    }
  }
};

type ComputePRProducts =
  | Record<MerchType, GetAdminMerchProducts_getAdminMerchProducts_entities[]>
  | Record<string, undefined>;

export const computePRProducts = (
  products: GetAdminMerchProducts_getAdminMerchProducts_entities[]
): ComputePRProducts => {
  const result = products.reduce((prev, acc) => {
    /**
     *  merchType: MerchType | null; it can be null so we need to check it
     */

    const prevValue = acc.type ? prev[acc.type] : undefined;

    const newValue = prevValue
      ? prevValue.concat(acc)
      : ([] as any).concat(acc);

    if (acc.type) {
      prev[acc.type] = newValue;
    }

    return prev;
  }, {} as Record<string, any>);

  return result;
};

type PrintCanvasDimensions = {
  templateWidth: number;
  templateHeight: number;
  printAreaWidth: number;
  printAreaHeight: number;
  printAreaTop: number;
  printAreaLeft: number;
  scaleFactorX: number;
  scaleFactorY: number;
  originTemplateWidth: number;
  originTemplateHeight: number;
  originPrintAreaWidth: number;
  originPrintAreaHeight: number;
  originPrintAreaTop: number;
  originPrintAreaLeft: number;
};

export const computePrintCanvasDimensions = (
  wrapperRef:
    | ((instance: HTMLDivElement | null) => void)
    | React.MutableRefObject<HTMLDivElement | null>
    | null,
  template:
    | GetPRProductByTypeV2_getPRProductByTypeV2_data_templates_templates
    | null
    | undefined
): PrintCanvasDimensions | null => {
  if (
    wrapperRef &&
    template?.template_width &&
    template?.template_height &&
    template.print_area_width &&
    template.print_area_height &&
    template.print_area_left
  ) {
    const imageBlockSizes = (
      wrapperRef as MutableRefObject<HTMLDivElement | null>
    )?.current?.getBoundingClientRect();
    const boxWidth = imageBlockSizes?.width ? imageBlockSizes.width : 0;
    const boxHeight = imageBlockSizes?.height ? imageBlockSizes.height : 0;
    const scaleFactorX = boxWidth / template.template_width;
    const scaleFactorY = boxHeight / template.template_height;
    const templateWidth = template.template_width * scaleFactorX;
    const templateHeight = template.template_height * scaleFactorY;
    const printAreaWidth = template.print_area_width * scaleFactorX;
    const printAreaHeight = template.print_area_height * scaleFactorY;
    const printAreaTop = template.print_area_top
      ? template.print_area_top * scaleFactorY
      : scaleFactorY;
    const printAreaLeft = template.print_area_left * scaleFactorX;

    return {
      scaleFactorX,
      scaleFactorY,
      templateWidth,
      templateHeight,
      printAreaWidth,
      printAreaHeight,
      printAreaTop,
      printAreaLeft,
      originTemplateWidth: template.template_width,
      originTemplateHeight: template.template_height,
      originPrintAreaWidth: template.print_area_width,
      originPrintAreaHeight: template.print_area_height,
      originPrintAreaTop: template.print_area_top || 0,
      originPrintAreaLeft: template.print_area_left,
    };
  }

  return null;
};

type PrintImageDimensions = {
  angle: number;
  top: number;
  left: number;
  scaleX: number;
  scaleY: number;
  width: number;
  height: number;
  rotatedLeft: number;
  rotatedTop: number;
};

export type ComputePrintfulDimensions = {
  angle: number;
  top: number;
  left: number;
  width: number;
  height: number;
  scaleX: number;
  scaleY: number;
  rotatedLeft: number;
  rotatedTop: number;
};

export const computePrintfulDimensions = (
  initialCanvasDimensions: PrintCanvasDimensions,
  printImageDimensions: PrintImageDimensions
): ComputePrintfulDimensions => {
  const { printAreaTop, printAreaLeft, scaleFactorX, scaleFactorY } =
    initialCanvasDimensions;
  const {
    top,
    left,
    width,
    height,
    angle,
    scaleX,
    scaleY,
    rotatedLeft,
    rotatedTop,
  } = printImageDimensions;

  return {
    angle: +angle,
    top: +(scaleFactorY
      ? (top - printAreaTop) / scaleFactorY
      : top - printAreaTop),
    left: +(scaleFactorX
      ? (left - printAreaLeft) / scaleFactorX
      : left - printAreaLeft),
    width: +(scaleFactorX ? width / scaleFactorX : width),
    height: +(scaleFactorY ? height / scaleFactorY : height),
    scaleX: +scaleX,
    scaleY: +scaleY,
    rotatedTop: +(scaleFactorY
      ? (rotatedTop - printAreaTop) / scaleFactorY
      : rotatedTop - printAreaTop),
    rotatedLeft: +(scaleFactorX
      ? (rotatedLeft - printAreaLeft) / scaleFactorX
      : rotatedLeft - printAreaLeft),
  };
};

export const formatPrice = (
  value: string | number | undefined | null
): string => (value ? formatCurrencyString(value) : '$0.00 USD');

export const calculateAspectRatioFit = (
  srcWidth: number,
  srcHeight: number,
  maxWidth: number,
  maxHeight: number
): { width: number; height: number } => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

export const hexToRgb = (hex: string): number[] => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return [r, g, b];
};

export type PRProductExtraColors = {
  color: string;
  title: string;
}[];

export const getPRProductsThreads = (
  productByType: GetPRProductByTypeV2_getPRProductByTypeV2 | undefined
): PRProductExtraColors => {
  const colors: PRProductExtraColors = [];
  const threadColors =
    productByType?.data.product.product?.options?.find(
      (item) => item.id === 'thread_colors_front_large'
    )?.values || {};

  Object.keys(threadColors).forEach((key) => {
    colors.push({
      title: threadColors[key],
      color: key,
    });
  });

  return colors;
};

export const getPRProductStitches = (
  productByType: GetPRProductByTypeV2_getPRProductByTypeV2 | undefined
): PRProductExtraColors => {
  const colors: PRProductExtraColors = [];
  const stitchColors =
    productByType?.data.product.product?.options?.find(
      (item) => item.id === 'stitch_color'
    )?.values || {};

  Object.keys(stitchColors).forEach((key) => {
    colors.push({
      title: stitchColors[key],
      color: key,
    });
  });

  return colors;
};

export const getThreadColorsOptions = (
  threads: PRProductExtraColors,
  threadColor: string | undefined
): number[][] => {
  const rgbThreads: number[][] = [];

  threads.forEach((item) => {
    if (threadColor === item.color) {
      const rgbThread = hexToRgb(item.color);

      rgbThreads.push(rgbThread);
    }
  });

  return rgbThreads;
};

type GetTemplateImageUrl = {
  PRProductByType: GetPRProductByTypeV2_getPRProductByTypeV2 | undefined;
  selectedColorTitle: string;
  colorOptions: PRProductColors;
  placement: string;
};

export const getTemplateImageUrl = ({
  PRProductByType,
  selectedColorTitle,
  colorOptions,
  placement,
}: GetTemplateImageUrl): string | null => {
  const selectedVariantId =
    colorOptions.find((item) => item.title === selectedColorTitle)?.id || null;
  const variantTemplates =
    PRProductByType?.data.templates.variant_mapping?.find(
      (item) => item?.variant_id === selectedVariantId
    )?.templates || [];
  const variantTemplateId =
    variantTemplates.find((item) => item?.placement === placement)
      ?.template_id || null;
  const backgroundImage =
    PRProductByType?.data.templates.templates?.find(
      (item) => item?.template_id === variantTemplateId
    )?.image_url || null;

  // hardcoded to avoid displaying new images for rash-guard t-shirts, which resulting in no CORS policy error from prinful
  // 5955 is the id for man variant and 5965 is the id for woman variant
  if (variantTemplateId === 5955 || variantTemplateId === 5965) {
    return null;
  }
  return backgroundImage;
};

export const getMerchGenderOptions = (merchType: MerchType): Gender[] => {
  switch (merchType) {
    case MerchType.Hat:
      return [Gender.Unisex];
    case MerchType.Hoodie:
    case MerchType.Joggers:
    case MerchType.RashGuard: {
      return [Gender.Male, Gender.Female];
    }
    case MerchType.TShirt: {
      return [Gender.Male, Gender.Female, Gender.Youth];
    }
  }
};

type variants =
  | (
      | GetPRProductByTypeV2_getPRProductByTypeV2_data_product_variants
      | GetAdminMerchProducts_getAdminMerchProducts_entities_merchProductVariants
      | null
    )[]
  | null
  | undefined;

export const getVariantsByColor = (
  productVariants: variants,
  pickedColor?: string
): variants => {
  const variants =
    productVariants?.filter(
      (item) => item?.color && pickedColor === item.color
    ) || productVariants?.slice();

  return variants;
};

type MerchProductVariantsType =
  | GetAdminMerchProducts_getAdminMerchProducts_entities_merchProductVariants
  | GetMerchProduct_getMerchProduct_merchProductVariants;

export const sortMerchProductVariantsByMainCustomImages = <
  T extends MerchProductVariantsType
>(
  productVariants: T[] = []
): T[] => {
  const sortVariants = productVariants
    .map((item) => {
      const sortedCustomImages = item?.customImages?.slice().sort((a, b) => {
        return Number(b.isMainImage) - Number(a.isMainImage);
      });

      return {
        ...item,
        customImages: sortedCustomImages,
      };
    })
    .sort((a, b) => {
      const aHasCustomImages = !!a.customImages?.length;
      const bHasCustomImages = !!b.customImages?.length;

      return Number(bHasCustomImages) - Number(aHasCustomImages);
    })
    .sort((a, b) => {
      const isAMainImageExist =
        a.customImages?.some((item) => item.isMainImage) || false;
      const isBMainImageExist =
        b.customImages?.some((item) => item.isMainImage) || false;

      return Number(isBMainImageExist) - Number(isAMainImageExist);
    });

  return sortVariants;
};

export const computeMerchBackgroundColor = (
  colorCode1: string,
  colorCode2?: string | null
): {
  background: string;
} => {
  // fix 'White' color because the "printful" send for us
  // '#e2e3de' and '#ebeaef' for hats
  //  which is a wrong colors and we rewrite them below
  const primaryColor =
    colorCode1 === '#e2e3de' || colorCode1 === '#ebeaef' ? '#fff' : colorCode1;
  const background = {
    ...(colorCode2
      ? {
          background: `linear-gradient(90deg, ${primaryColor} 50%, ${colorCode2} 50%)`,
        }
      : {
          background: primaryColor,
        }),
  };

  return background;
};

export const designMerchTypeOptions = [
  {
    label: 'Hat',
    value: MerchType.Hat,
  },
  {
    label: 'Joggers',
    value: MerchType.Joggers,
  },
  {
    label: 'Hoodie',
    value: MerchType.Hoodie,
  },
  {
    label: 'Rash guard',
    value: MerchType.RashGuard,
  },
  {
    label: 'T-Shirt',
    value: MerchType.TShirt,
  },
];

export const formatProductType = (type: MerchType | string): string => {
  switch (type) {
    case MerchType.Hat:
      return 'Hat';
    case MerchType.Joggers:
      return 'Joggers';
    case MerchType.Hoodie:
      return 'Hoodie';
    case MerchType.RashGuard:
      return 'Compression Shirt';
    case MerchType.TShirt:
      return 'T-Shirt';
    default:
      return '';
  }
};

export const getPrintfulVariantIds = (
  prProductData: GetPRProductByTypeV2 | undefined,
  gender: Gender,
  colorTitle: string
): number[] => {
  const genderVariant = prProductData?.getPRProductByTypeV2.find(
    (type) => type.gender === gender
  );
  const variants: number[] =
    genderVariant?.data.product.variants?.reduce((acc, value) => {
      if (value?.id && value?.color && colorTitle === value?.color) {
        acc.push(value.id);
      }

      return acc;
    }, [] as number[]) || [];

  return variants;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCommonInput = (
  isDesigner: boolean,
  buildData: BuildData[],
  prProductData: GetPRProductByTypeV2 | undefined,
  type: MerchType,
  title: string,
  profit: number,
  printAreaKeys: PresignedUrlResultItem[],
  customImagesKeys: PresignedUrlResultItem[],
  storeId: string
) => {
  const customImageSets: MerchProductCustomImageSetInput[] = [];
  const printFileSets: MerchProductPrintFileSetInput[] = [];
  const variantSets: MerchProductVariantSet[] = [];
  const allImagesBase64: string[] = [];

  buildData.forEach((item, index) => {
    const updatedGender = item.age === Age.Youth ? Gender.Unisex : item.gender;

    const variantIds = getPrintfulVariantIds(
      prProductData,
      updatedGender,
      item.colorTitle
    );

    if (type === MerchType.RashGuard) {
      variantSets.push({
        gender: updatedGender,
        age: item.age,
        variantIds,
        stitches: item.extraColor || '',
      });
    } else if (type === MerchType.Hat) {
      variantSets.push({
        gender: updatedGender,
        age: item.age,
        variantIds,
        threads: [item.extraColor || ''],
      });
    } else {
      variantSets.push({
        gender: updatedGender,
        age: item.age,
        variantIds,
      });
    }

    const printFiles: VariantPrintFileInput[] = [];

    item.printPlacements.forEach((placement) => {
      allImagesBase64.push(item.printFiles[placement]?.pngFromCanvas || '');
      printFiles.push({
        key:
          item.printFiles[placement]?.uploadedAreaKey ??
          (printAreaKeys.shift()?.key || ''),
        placementType: placement,
        printFilePlacements: [
          {
            angle: item.printFiles[placement]?.angle || 0,
            left: item.printFiles[placement]?.left || 0,
            printImageId: item.printImages[placement]?.id || '',
            rotatedLeft: item.printFiles[placement]?.rotatedLeft || 0,
            rotatedTop: item.printFiles[placement]?.rotatedTop || 0,
            scaleX: item.printFiles[placement]?.scaleX || 0,
            scaleY: item.printFiles[placement]?.scaleY || 0,
            top: item.printFiles[placement]?.top || 0,
          },
        ],
      });
    });

    printFileSets.push({
      variantSetIndices: [index],
      printFiles,
    });

    if (item.customImages && item.customImages.length) {
      const customImages: MerchCustomImageV2Input[] = [];

      item.customImages.forEach((image) => {
        customImages.push({
          isMain: image.isMainImage,
          key:
            image.imageFileKey !== ''
              ? image.imageFileKey
              : customImagesKeys.shift()?.key || '',
        });
      });

      customImageSets.push({
        customImages: customImages,
        variantSetIndices: [index],
      });
    }
  });

  const requestedProfit = isDesigner ? 10 : +profit.toFixed(2);
  return {
    title,
    type,
    requestedProfit: requestedProfit,
    printFileSets,
    variantSets,
    customImageSets,
    storeId,
  };
};

export const prepareBuildData = (
  type: MerchType,
  buildData: BuildData[]
): BuildData[] => {
  // we don't duplicate rashguard or hat data
  const isDuplicateProductType: boolean =
    type !== MerchType.RashGuard && type !== MerchType.Hat;

  const hasMale = !!buildData.find((item) => item.gender === Gender.Male);
  const hasFemale = !!buildData.find((item) => item.gender === Gender.Female);

  // duplicate only if there is a male/female gender missing and merch type is valid
  const shouldDuplicate: boolean =
    isDuplicateProductType &&
    ((!hasMale && hasFemale) || (!hasFemale && hasMale));

  if (shouldDuplicate) {
    const updatedBuildData: BuildData[] = [];
    buildData.forEach((item) => {
      updatedBuildData.push(item);
      // we copy hoodies, shirts and joggers depending on what gender is missing
      // we dont copy the youth shirts variants
      if (item.gender === Gender.Male) {
        updatedBuildData.push({ ...item, gender: Gender.Female });
      }
      if (item.gender === Gender.Female) {
        updatedBuildData.push({ ...item, gender: Gender.Male });
      }
    });
    return updatedBuildData;
  } else {
    return buildData;
  }
};

type UniqueVariant = {
  color: string;
  colorGroupId: number;
  gender: Gender;
  age: Age;
};

export const convertMerchDataIntoBuildData = (
  variants: GetMerchProductV2_getMerchProduct_merchProductVariants[]
): BuildData[] => {
  // go trough the items, extract color+gender+age+colorGroupId array
  // then go trough the array and create buildData
  const uniqueVariants: UniqueVariant[] = [];
  const buildData: BuildData[] = [];

  variants?.forEach((variant) => {
    if (variant.colorGroupId && variant.gender && variant.age) {
      const hasItem = !!uniqueVariants.find((item) => {
        return (
          variant.color === item.color &&
          variant.colorGroupId === item.colorGroupId &&
          variant.gender === item.gender &&
          variant.age === item.age
        );
      });
      if (!hasItem) {
        // add this version to the uniqueVariants array so we have it for future comparisons
        uniqueVariants.push({
          color: variant.color,
          colorGroupId: variant.colorGroupId,
          gender: variant.gender,
          age: variant.age,
        });
        const extraColor = variant.threads || variant.stitches || undefined;

        const placements: PlacementTypes[] = [];
        let printImages: SelectedPrintImages = {};
        let printFiles: PrintFiles = {};

        variant.files.forEach((file) => {
          const placement = file.placementType as PlacementTypes;
          placements.push(placement);

          // we always have only one image
          const merchProductImage = file.merchProductPrintfilePlacements[0];

          const printImage: GetPrintImages_getPrintImages = {
            __typename: 'PrintImage',
            id: merchProductImage.printImageId,
            imageURL: null,
          };
          printImages = {
            ...printImages,
            [placement]: printImage,
          };

          const printCoordinates: PrintCoordinates = {
            pngFromCanvas: undefined,
            uploadedAreaKey: file.imageFileKey,
            angle: merchProductImage.angle || 0,
            left: merchProductImage.left || 0,
            rotatedLeft: merchProductImage.rotatedLeft || 0,
            rotatedTop: merchProductImage.rotatedTop || 0,
            scaleX: merchProductImage.scaleX || 0,
            scaleY: merchProductImage.scaleY || 0,
            top: merchProductImage.top || 0,
            width: 0,
            height: 0,
          };

          printFiles = { ...printFiles, [placement]: printCoordinates };
        });

        const customImages: CustomImage[] = [];

        variant.customImages?.forEach((image) => {
          customImages.push({
            imageFileKey: image.imageFileKey || '',
            isMainImage: image.isMainImage || false,
            customMerchImageURL: image.customMerchImageURL,
            file: undefined,
          });
        });

        const hasMainCustomImage = variant.customImages?.find(
          (image) => image.isMainImage === true
        );

        if (!hasMainCustomImage && customImages.length) {
          customImages[0].isMainImage = true;
        }

        buildData.push({
          gender: variant.age === Age.Adult ? variant.gender : Gender.Youth,
          age: variant.age,
          colorTitle: variant.color,
          extraColor: extraColor
            ? extraColor.replace(`["`, '').replace(`"]`, '')
            : undefined,
          printImages: printImages,
          printPlacements: placements,
          hasError: false,
          printFiles: printFiles,
          customImages: customImages,
        });
      }
    }
  });

  return buildData;
};
