import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { Controller, useForm } from 'react-hook-form';
import {
  Form,
  Select,
  Button,
  Typography,
  AutoComplete,
  Input,
  DatePicker,
  Tag,
  Tooltip,
} from 'antd';
import { DeleteOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import moment, { Moment } from 'moment';
// Api
import { GET_STORES } from 'api/store/queries';
import { GET_VIDEO_EDITORS } from 'api/videoLab/queries';
import { CREATE_VIDEO_EDIT_REQUEST } from 'api/videoLab/mutation';
import { CREATE_UNREGISTERED_CONTACT } from 'api/mentions/mutations';
// Types
import {
  CreateVideoEditRequest,
  CreateVideoEditRequestVariables,
} from 'api/videoLab/types/CreateVideoEditRequest';
import { GetStores, GetStoresVariables } from 'api/store/types/GetStores';
import {
  CreateUnregisteredContact,
  CreateUnregisteredContactVariables,
} from 'api/mentions/types/CreateUnregisteredContact';
import {
  GetVideoEditors,
  GetVideoEditorsVariables,
} from 'api/videoLab/types/GetVideoEditors';
import {
  AdminStoresOrderBy,
  CreateMentionInput,
  CreateUnregisteredContactInput,
  StoreStatus,
  UserRole,
  VideoEditorOrderBy,
} from 'api/graphql-global-types';
// Constants
import { commonTimeFormat } from 'constants/global';
// Components
import { refetchQueries } from '../../VideoEditingRequestsTable/VideoEditingRequestsTable';
import TagWithButton from '../../../../../uiShared/TagWithButtons/TagWithButtons';
// Helpers
import { tagStyles } from 'uiShared/TagWithButtons/helpers';
import { formatHashtagInput } from 'helpers/hashtags';
import { formatMentionsInput } from 'helpers/mentions';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
import TagsInput from 'uiShared/TagsInput/TagsInput';
import Mentions, { MentionValues } from 'uiShared/Mentions/Mentions';
// Styles
import styles from './CreateRequestModal.module.scss';

const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

// for initial phase we will have Interview only
export const videoTypeOptions = [{ label: 'Interview', value: 'Interview' }];

export type AutoCompleteOption = {
  value: string | number;
  label: string;
};

type CreateRequestModalProps = {
  onClose: () => void;
};

export type CreateVideoRequest = {
  videoEditorId: string | null;
  createdAt: Moment;
  dueDate: Moment | null;
  rawUrls: string | null;
  adminNotes: string | null;
};

export const CreateRequestModal = ({
  onClose,
}: CreateRequestModalProps): JSX.Element => {
  const [storeOptions, setStoreOptions] = useState<AutoCompleteOption[]>([]);
  const [additionalStoreOptions, setAdditionalStoreOptions] = useState<
    AutoCompleteOption[]
  >([]);
  const [mentionsValues, setMentionsValues] = useState<MentionValues[]>([]);
  const [hashtagValues, setHashtagValues] = useState<string[]>([]);
  const [selectedStoreOption, setSelectedStoreOption] =
    useState<AutoCompleteOption | null>(null);

  const [selectedAdditionalStoreOptions, setSelectedAdditionalStoreOptions] =
    useState<AutoCompleteOption[]>([]);

  const [rawUrls, setRawUrls] = useState<string[]>([]);
  const [rawUrlnputValue, setRawUrlInputValue] = useState('');
  const [additionalStoreInputValue, setAdditionalStoreInputValue] =
    useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRawUrlInputValue(e.target.value);
  };
  const { control } = useForm();
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && rawUrlnputValue.trim()) {
      e.preventDefault();
      const updatedUrls = [...rawUrls, rawUrlnputValue.trim()];
      setRawUrls(updatedUrls);
      form.setFieldsValue({ rawUrls: updatedUrls });
      form.validateFields(['rawUrls']);
      setRawUrlInputValue('');
    }
  };

  const handleRemoveUrl = (removedUrl: string) => {
    const updatedUrls = rawUrls.filter((url) => url !== removedUrl);
    setRawUrls(updatedUrls);
    form.setFieldsValue({ rawUrls: updatedUrls });
    form.validateFields(['rawUrls']);
  };
  const disabledDate = (current: Moment | null) => {
    if (!current) return false;

    return moment(current).isBefore(moment().add(-1, 'minute'));
  };

  const handleAdditionalStoreChange = (e: string) => {
    setAdditionalStoreInputValue(e);

    const updatedAdditionalStoreOptions = [
      ...selectedAdditionalStoreOptions,
      additionalStoreInputValue.trim(),
    ];
    form.setFieldsValue({
      additionalStoreOptions: updatedAdditionalStoreOptions,
    });
  };

  const handleRemoveAditionalStoreName = (storeId: string | number) => {
    const updatedAdditionalStoreOptions = selectedAdditionalStoreOptions.filter(
      (storeOption) => storeOption.value !== storeId
    );
    setSelectedAdditionalStoreOptions(updatedAdditionalStoreOptions);
    form.setFieldsValue({
      additionalStoreOptions: updatedAdditionalStoreOptions,
    });
  };

  const initialFormValues: CreateVideoRequest = {
    videoEditorId: null,
    createdAt: moment(),
    dueDate: moment().add(3, 'days'),
    rawUrls: null,
    adminNotes: null,
  };

  const handleSelectAdditionalStore = (
    value: string,
    option: AutoCompleteOption
  ): void => {
    setSelectedAdditionalStoreOptions([
      ...selectedAdditionalStoreOptions,
      option,
    ]);

    setAdditionalStoreInputValue('');
  };

  const handleSelectStore = (
    value: string,
    option: AutoCompleteOption
  ): void => {
    setSelectedStoreOption(option);
  };

  const handleOnClearStore = () => {
    setSelectedStoreOption(null);
  };

  const [form] = Form.useForm();

  const [createVideoEditRequest, { loading: createVideoEditRequestLoading }] =
    useMutation<CreateVideoEditRequest, CreateVideoEditRequestVariables>(
      CREATE_VIDEO_EDIT_REQUEST,
      { refetchQueries }
    );

  const [createUnregisteredContact] = useMutation<
    CreateUnregisteredContact,
    CreateUnregisteredContactVariables
  >(CREATE_UNREGISTERED_CONTACT);

  const { data: videoEditors } = useQuery<
    GetVideoEditors,
    GetVideoEditorsVariables
  >(GET_VIDEO_EDITORS, {
    variables: {
      input: {
        limit: 100,
        offset: 0,
        orderBy: VideoEditorOrderBy.createdAt,
        videoEditorStatus: [StoreStatus.Active],
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: storesData, refetch } = useQuery<GetStores, GetStoresVariables>(
    GET_STORES,
    {
      variables: {
        input: {
          orderBy: AdminStoresOrderBy.storeName,
          status: [StoreStatus.Active],
        },
        storeRoles: [
          UserRole.Athlete,
          UserRole.Organization,
          UserRole.ContentCreator,
        ],
      },
    }
  );

  const handleOnSearchStores = useMemo(() => {
    const loadOptions = (storeName: string) => {
      refetch({
        storeRoles: [
          UserRole.Athlete,
          UserRole.Organization,
          UserRole.ContentCreator,
        ],
        input: {
          storeName,
          orderBy: AdminStoresOrderBy.storeName,
          status: [StoreStatus.Active],
        },
      });
    };

    return debounce(loadOptions, 400);
  }, [refetch]);

  const handleOnSearchAdditionalStores = useMemo(() => {
    const loadOptions = (storeName: string) => {
      refetch({
        storeRoles: [
          UserRole.Athlete,
          UserRole.Organization,
          UserRole.ContentCreator,
        ],
        input: {
          storeName,
          orderBy: AdminStoresOrderBy.storeName,
          status: [StoreStatus.Active],
        },
      });
    };

    return debounce(loadOptions, 400);
  }, [refetch]);

  useEffect(() => {
    if (storesData?.adminStores.entities) {
      setStoreOptions(
        storesData.adminStores.entities.map((store) => ({
          value: store.id,
          label: store.storeDetails?.storeName
            ? store.storeDetails.storeName
            : `${store.firstName} ${store.lastName}`,
        }))
      );
    }
  }, [storesData]);

  useEffect(() => {
    if (storesData?.adminStores.entities) {
      setAdditionalStoreOptions(
        storesData.adminStores.entities.map((store) => ({
          value: store.id,
          label: store.storeDetails?.storeName
            ? store.storeDetails.storeName
            : `${store.firstName} ${store.lastName}`,
        }))
      );
    }
  }, [storesData]);

  const handleFormFinish = async () => {
    const storeId = selectedStoreOption?.value as string;
    const additionalStoreIds = selectedAdditionalStoreOptions.map(
      (additionalStore) => additionalStore.value
    ) as string[];
    const values = form.getFieldsValue();

    if (storeId) {
      const createUnregisteredContactInput =
        (mentionsValues as MentionValues[])?.filter(
          (mention) => !mention?.targetType
        ) || [];

      const unregisterContactsData = await createUnregisteredContact({
        variables: {
          input:
            createUnregisteredContactInput as CreateUnregisteredContactInput[],
        },
      });

      const newUnregisteredContacts =
        (unregisterContactsData?.data?.createUnregisteredContact || [])?.map(
          (contact) => ({
            unregisteredContactId: contact?.id,
          })
        ) || [];

      const oldMentions: MentionValues[] =
        mentionsValues?.filter((mention) => mention?.targetType) || [];
      const formatedOldMentions = formatMentionsInput(oldMentions);
      try {
        await createVideoEditRequest({
          variables: {
            input: {
              videoEditorId: values.videoEditorId as string,
              storeId,
              rawUrls,
              dueDate: values.dueDate,
              adminNotes: values.adminNotes,
              additionalStoreIds,
              mentionsInputs: [
                ...formatedOldMentions,
                ...newUnregisteredContacts,
              ] as CreateMentionInput[],
              hashtagInputs: hashtagValues?.length
                ? formatHashtagInput(hashtagValues)
                : null,
            },
          },
        });

        successNotification('Video request was created successfully');
        form.resetFields();
        setRawUrls([]);

        onClose();
      } catch (err) {
        errorNotification((err as Error)?.message || 'Something went wrong');
        setRawUrls([]);
      }
    }
  };

  const selectedStoreOptionId = selectedStoreOption?.value;

  const filteredAdditionalStoreOptions = additionalStoreOptions.filter(
    (item) => item.value !== selectedStoreOptionId
  );

  const handleCopyLinkButtonClick = async (url: string) => {
    try {
      await navigator.clipboard.writeText(url);

      successNotification('Link copied');
    } catch (error) {
      errorNotification('Could not copy text');
    }
  };

  const handleMentionChange = useCallback(
    (mentions) => {
      if (JSON.stringify(mentions) !== JSON.stringify(mentionsValues)) {
        form.setFieldsValue({ mentions: mentions });
        setMentionsValues(mentions);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mentionsValues]
  );

  const handleHashtagsChange = (hashtags: string[]) => {
    setHashtagValues(hashtags);
  };

  const isLoading = createVideoEditRequestLoading;

  return (
    <Form
      form={form}
      layout="horizontal"
      name="createVideoRequestForm"
      initialValues={initialFormValues}
      autoComplete="off"
      onFinish={handleFormFinish}
    >
      <Form.Item
        name="videoEditorId"
        label={<Text>Video editor</Text>}
        rules={[
          {
            required: true,
            message: 'Please select video editor',
          },
        ]}
      >
        <Select
          showSearch
          allowClear
          placeholder="Select video editor"
          filterOption={(input, option) =>
            option?.name
              ?.toLowerCase()
              .indexOf(input.replace(/\s\s+/g, ' ').trim().toLowerCase()) >= 0
          }
        >
          {videoEditors?.getVideoEditors?.entities?.map(
            ({ firstName, lastName, id }) => {
              const name = [firstName, lastName].join(' ').trim();
              return (
                <Option key={id} value={id} name={name}>
                  {name}
                </Option>
              );
            }
          )}
        </Select>
      </Form.Item>

      <div className={styles.storeSection}>
        <Text className={styles.accountText}>Store name: </Text>
        <AutoComplete
          id="store-search"
          allowClear
          className={styles.autoComplete}
          placeholder="Search store Name"
          options={storeOptions}
          value={selectedStoreOption?.label}
          onSearch={handleOnSearchStores}
          onSelect={handleSelectStore}
          onClear={handleOnClearStore}
        />
      </div>

      <Form.Item
        label={<Text>Additional store names</Text>}
        name="additionalStoreNames"
      >
        <AutoComplete
          id="additional-store-search"
          className={styles.autoComplete}
          placeholder="Search additional store Name"
          options={filteredAdditionalStoreOptions}
          value={additionalStoreInputValue}
          onSearch={handleOnSearchAdditionalStores}
          onSelect={handleSelectAdditionalStore}
          onChange={(e) => handleAdditionalStoreChange(e)}
        />

        <div style={{ marginTop: '10px' }}>
          {selectedAdditionalStoreOptions.map((option) => (
            <Tag key={option.value} style={tagStyles}>
              <div className={styles.textLabel}>{option.label}</div>
              <Button
                type="link"
                icon={<DeleteOutlined />}
                onClick={() => handleRemoveAditionalStoreName(option.value)}
              />
            </Tag>
          ))}
        </div>
      </Form.Item>

      <Form.Item name="createdAt" label={<Text>Created at</Text>}>
        <DatePicker
          data-testid="createdAt"
          showTime
          format={commonTimeFormat}
          style={{ width: 250 }}
          showNow
          disabled
        />
      </Form.Item>

      <Form.Item name="dueDate" label={<Text>Due date</Text>}>
        <DatePicker
          data-testid="dueDate"
          disabledDate={disabledDate}
          showTime
          format={commonTimeFormat}
          style={{ width: 250 }}
          minuteStep={15}
          showNow={true}
          disabled={false}
        />
      </Form.Item>

      <Form.Item
        label={<Text>Raw video link</Text>}
        name="rawUrls"
        rules={[
          {
            required: true,
            message: 'Please add raw video urls',
          },
        ]}
      >
        <TextArea
          className={styles.customTextarea}
          value={rawUrlnputValue}
          onChange={(e) => handleInputChange(e)}
          onKeyPress={handleKeyPress}
          placeholder="Enter each URL and press Enter"
          autoSize={{ minRows: 1, maxRows: 10 }}
        />
        <div style={{ marginTop: '10px' }}>
          {rawUrls.map((url, index) => (
            <TagWithButton
              key={index}
              url={url}
              onRemoveUrl={handleRemoveUrl}
              onCopyLink={() => handleCopyLinkButtonClick(url)}
            />
          ))}
        </div>
      </Form.Item>

      <Form.Item name="adminNotes" label={<Text>Admin Notes</Text>}>
        <Input />
      </Form.Item>

      <Form.Item name="hashtags" label="Tagging">
        <Controller
          name="hashtags"
          control={control}
          render={() => (
            <TagsInput
              hashtags={hashtagValues}
              onChange={(val: any) => handleHashtagsChange(val)}
              name="hashtags"
            />
          )}
        />
      </Form.Item>

      <Form.Item name="mentions" className={styles.mentionsRoot}>
        <Text className={styles.mentionSectionTitleWrapper}>
          <p className={styles.mentionsSectionTitle}>Mentioning options</p>
          <Tooltip
            title="Mention athletes, brands, and organizations by using their names and
          links to directly engage them and increase interaction on your
          content. Write down their names and url links."
          >
            <QuestionCircleTwoTone />
          </Tooltip>
        </Text>
      </Form.Item>

      <div className={styles.mentionsContainer}>
        <Mentions mentions={mentionsValues} onChange={handleMentionChange} />
      </div>

      <Form.Item className={styles.finishButtonContainer}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Create video request
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateRequestModal;
