import React from 'react';
import { useApolloClient } from '@apollo/client';
// Api
import { GET_UPCOMING_STREAMS } from 'api/streams/queries';
// Types
import {
  StoreStatus,
  StreamStatus,
  StreamOrderBy,
  SortDirection,
} from 'api/graphql-global-types';
import {
  GetUpcomingStreams,
  GetUpcomingStreamsVariables,
  GetUpcomingStreams_adminStreams_entities,
} from 'api/streams/types/GetUpcomingStreams';
import { LoadMore, OptionsItem } from './SectionItem';
import { HomePageSectionItemProps } from 'pages/HomePageManagement';
import { GetHomePageData_getHomePageData_watchStreams } from 'api/homePageSetup/types/GetHomePageData';
// Helpers
import { getPublicStreamDate, getStoreName } from 'helpers/utils';
// Components
import SectionItem from './SectionItem';

export const getStreamLabel = (
  stream:
    | GetHomePageData_getHomePageData_watchStreams
    | GetUpcomingStreams_adminStreams_entities
): string => {
  return stream
    ? `"${stream?.name || ''}" by ${getStoreName(
        stream?.store
      )} (${getPublicStreamDate(stream?.scheduleDate, stream?.timeZone)})`
    : '';
};

const PER_PAGE = 10;

const SetupStreamSectionItem: React.FC<HomePageSectionItemProps> = ({
  selectedItems,
  onItemChange,
  item,
  itemIndex,
}) => {
  const client = useApolloClient();

  const loadMore: LoadMore = async (offset, search) => {
    const options: OptionsItem[] = [];

    const { data } = await client.query<
      GetUpcomingStreams,
      GetUpcomingStreamsVariables
    >({
      query: GET_UPCOMING_STREAMS,
      variables: {
        input: {
          ...(search
            ? {
                storeName: search,
              }
            : {}),
          streamStatus: [StreamStatus.Scheduled],
          orderBy: StreamOrderBy.StoreName,
          direction: SortDirection.DESC,
          storeStatus: StoreStatus.Active,
          limit: PER_PAGE,
          offset,
        },
      },
      fetchPolicy: 'network-only',
    });

    const total = data?.adminStreams?.total || 0;

    const unselectedStreams = data?.adminStreams?.entities?.filter(
      (stream) => !selectedItems.some((item) => item.value === stream.id)
    );

    unselectedStreams?.forEach((item) => {
      options.push({
        value: item.id,
        label: getStreamLabel(item),
      });
    });

    return { total, options };
  };

  return (
    <SectionItem
      selectPlaceholder="Pick the Event"
      selectedItems={selectedItems}
      onItemsChange={onItemChange}
      item={item}
      itemIndex={itemIndex}
      paginationSize={PER_PAGE}
      loadMore={loadMore}
    />
  );
};

export default SetupStreamSectionItem;
