// Types
import {
  CompleteMultipartUploadInput,
  CompleteMultipartUploadPartInput,
} from 'api/graphql-global-types';
import {
  AdminInitPostVideoMultipartUploadV2_adminInitPostVideoMultipartUploadV2,
  AdminInitPostVideoMultipartUploadV2_adminInitPostVideoMultipartUploadV2_signedUrls,
} from 'api/mediaPost/types/AdminInitPostVideoMultipartUploadV2';

export type UploaderOptions = {
  chunkSize?: number;
  threadsQuantity?: number;
  file: File;
  initData: AdminInitPostVideoMultipartUploadV2_adminInitPostVideoMultipartUploadV2;
};

export type UploadedPart = {
  ETag: string;
  PartNumber: number;
};

export type ProgressParam = {
  sent: number;
  total: number;
  percentage: number;
};

// original source: https://github.com/pilovm/multithreaded-uploader/blob/master/frontend/uploader.js
export class Uploader {
  chunkSize: number;
  threadsQuantity: number;
  file: File;
  aborted: boolean;
  uploadedSize: number;
  progressCache: { [key: number]: number };
  activeConnections: { [key: number]: XMLHttpRequest };
  parts: AdminInitPostVideoMultipartUploadV2_adminInitPostVideoMultipartUploadV2_signedUrls[];
  uploadedParts: CompleteMultipartUploadPartInput[];
  fileId: string | null;
  fileKey: string | null;
  onProgressFn: (progressData: ProgressParam) => void;
  onErrorFn: (error?: Error) => void;
  onCompleteFn: (input: CompleteMultipartUploadInput) => void;

  constructor(options: UploaderOptions) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.chunkSize = options.chunkSize || 1024 * 1024 * 5;
    // number of parallel uploads
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
    this.file = options.file;
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = options.initData.signedUrls;
    this.uploadedParts = [];
    this.fileId = options.initData.fileId;
    this.fileKey = options.initData.fileKey;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.onProgressFn = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.onErrorFn = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.onCompleteFn = () => {};
  }

  // starting the multipart upload request
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  start() {
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async initialize() {
    try {
      this.sendNext();
    } catch (error: any) {
      await this.complete(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }

      return;
    }

    const part = this.parts.pop();
    if (this.file && part) {
      const sentSize = (part.partNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          this.parts.push(part);

          this.complete(error);
        });
    }
  }

  // terminating the multipart upload request on success or failure
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async complete(error?: Error) {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    try {
      await this.sendCompleteRequest();
    } catch (error: any) {
      this.onErrorFn(error);
    }
  }

  // finalizing the multipart upload request on success by calling
  // the finalization API
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async sendCompleteRequest() {
    if (this.fileId && this.fileKey) {
      const videoFinalizationMultiPartInput = {
        fileId: this.fileId,
        fileKey: this.fileKey,
        parts: this.uploadedParts,
      };

      this.onCompleteFn(videoFinalizationMultiPartInput);
    }
  }

  sendChunk(
    chunk: Blob,
    part: AdminInitPostVideoMultipartUploadV2_adminInitPostVideoMultipartUploadV2_signedUrls,
    sendChunkStarted: () => void
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error('Failed chunk upload'));
            return;
          }

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // calculating the current progress of the multipart upload request
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleProgress(part: any, event: any) {
    if (this.file) {
      if (
        event.type === 'progress' ||
        event.type === 'error' ||
        event.type === 'abort'
      ) {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === 'uploaded') {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
      });
    }
  }

  // uploading a part through its pre-signed URL
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  upload(
    file: Blob,
    part: AdminInitPostVideoMultipartUploadV2_adminInitPostVideoMultipartUploadV2_signedUrls,
    sendChunkStarted: () => void
  ) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        // - 1 because PartNumber is an index starting from 1 and not 0
        const xhr = (this.activeConnections[part.partNumber - 1] =
          new XMLHttpRequest());

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(
          this,
          part.partNumber - 1
        );

        xhr.upload.addEventListener('progress', progressListener);

        xhr.addEventListener('error', progressListener);
        xhr.addEventListener('abort', progressListener);
        xhr.addEventListener('loadend', progressListener);

        xhr.open('PUT', part.signedUrl);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // retrieving the ETag parameter from the HTTP headers
            const ETag = xhr.getResponseHeader('Etag');

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.partNumber,
                // removing the " enclosing carachters from
                // the raw ETag
                ETag: ETag.replaceAll('"', ''),
              };

              this.uploadedParts.push(uploadedPart);

              resolve(xhr.status);
              delete this.activeConnections[part.partNumber - 1];
            }
          }
        };

        xhr.onerror = (error) => {
          reject(error);
          delete this.activeConnections[part.partNumber - 1];
        };

        xhr.onabort = () => {
          reject(new Error('Upload canceled by user'));
          delete this.activeConnections[part.partNumber - 1];
        };

        xhr.send(file);
      }
    });
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onProgress(onProgress: (data: ProgressParam) => void) {
    this.onProgressFn = onProgress;
    return this;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onError(onError: (error: any) => void) {
    this.onErrorFn = onError;
    return this;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onComplete(onComplete: (input: CompleteMultipartUploadInput) => void) {
    this.onCompleteFn = onComplete;
    return this;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  }
}
