import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { Typography, Steps, Button, Spin, Tooltip } from 'antd';
import {
  SendOutlined,
  LeftOutlined,
  RightOutlined,
  InfoCircleTwoTone,
} from '@ant-design/icons';
// Api
import {
  ADMIN_CREATE_MERCH_DESIGN,
  ADMIN_EDIT_MERCH_DESIGN,
  CREATE_PRESIGNED_URL_FOR_EXAMPLE_IMAGES,
} from 'api/designLab/mutations';
import {
  DESIGN_TYPES,
  DESIGN_REQUESTS,
  DESIGN_COLOR_PALETTES,
} from 'api/designLab/queries';
// Types
import {
  DesignTypes,
  DesignTypes_designTypes,
  DesignTypes_designTypes_designSamples,
} from 'api/designLab/types/DesignTypes';
import {
  AdminCreateMerchDesign,
  AdminCreateMerchDesignVariables,
} from 'api/designLab/types/AdminCreateMerchDesign';
import {
  AdminEditMerchDesign,
  AdminEditMerchDesignVariables,
} from 'api/designLab/types/AdminEditMerchDesign';
import {
  CreatePresignedUrlForExampleImages,
  CreatePresignedUrlForExampleImagesVariables,
  CreatePresignedUrlForExampleImages_createPresignedUrlForExampleImages,
} from 'api/designLab/types/CreatePresignedUrlForExampleImages';
import {
  SortDirection,
  DesignRequestsOrderBy,
  MerchType,
} from 'api/graphql-global-types';
import {
  DesignColorPalettes,
  DesignColorPalettes_designColorPalettes,
} from 'api/designLab/types/DesignColorPalettes';
import { DesignStyleSettingsInput } from 'api/graphql-global-types';
import { DesignRequests_designRequests_entities } from 'api/designLab/types/DesignRequests';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import TypesOfLogoForm from './components/TypesOfLogoForm/TypesOfLogoForm';
import PreferredDesignsForm from './components/PreferredDesignsForm/PreferredDesignsForm';
import DetailsForm from './components/DetailsForm/DetailsForm';
import PreferredColorsForm from './components/PreferredColorsForm/PreferredColorsForm';
import ExtraInformationForm from './components/ExtraInformationForm/ExtraInformationForm';
// Helpers
import { ImageToUpload } from 'helpers/createPresignedUrl';
// Styles
import styles from './DLConstructor.module.scss';

type DLConstructorProps = {
  onComplete?: () => void;
  initialData?: Partial<DesignRequests_designRequests_entities>;
  isMerchDesign?: boolean;
};

export type Details = Omit<DesignStyleSettingsInput, 'id'>;

type DLValues = {
  typesOfLogo: number[];
  designs: number[];
  details: Details;
  colors: number[];
  designCustomColor: string;
  extra: Partial<{
    name: string;
    extraInfo: string;
    socialMediaURL: string;
    examples?: (FileWithPreview | string)[];
    merchTypes: MerchType[] | null;
  }>;
};

export type DLStateValues = Partial<DLValues>;

type CreationStep = {
  title: string;
  description: string;
  info?: string;
};

const { Title, Text } = Typography;
const { Step } = Steps;

const initDLValues: DLValues = {
  typesOfLogo: [],
  designs: [],
  details: {
    playfulSophisticated: 3,
    matureYouthful: 3,
    classicModern: 3,
    feminineMasculine: 3,
    geometricOrganic: 3,
    abstractLiteral: 3,
    economicalLuxurious: 3,
  },
  colors: [],
  designCustomColor: '',
  extra: {
    name: '',
    extraInfo: '',
    socialMediaURL: '',
    examples: [],
    merchTypes: [],
  },
};

const DLConstructor = ({
  onComplete,
  initialData,
  isMerchDesign = false,
}: DLConstructorProps): JSX.Element => {
  const merchOrLogo = isMerchDesign ? 'merch design' : 'logo';

  const steps: CreationStep[] = [
    {
      title: `What type of ${merchOrLogo} are you looking for?`,
      description: `Help us understand the type of ${merchOrLogo} you want`,
      info: "Note, that You can't select more than 3 items",
    },
    {
      title: 'Which designs do you like?',
      description: `Help the designers get a feel for your ideal ${merchOrLogo}. Pick up to 2.`,
    },
    {
      title: 'Define your style',
      description: 'Help our designers understand your personal style',
    },
    {
      title: 'Choose your color palette',
      description: 'Pick up to 2 colors you want the designers to incorporate',
    },
    {
      title: "Let's wrap this up!",
      description: 'Almost there, just a few final details',
    },
  ];

  const { storeId } = useParams<{ storeId: string | undefined }>();
  const [step, setStep] = useState<number>(1);
  const [values, setValues] = useState<DLValues>(initDLValues);

  const { typesOfLogo, designs, details, colors, designCustomColor, extra } =
    values;

  const { data: designTypesData, loading: designTypesLoading } =
    useQuery<DesignTypes>(DESIGN_TYPES);

  const { data: designColorPalettesData, loading: designColorPalettesLoading } =
    useQuery<DesignColorPalettes>(DESIGN_COLOR_PALETTES);

  const [adminCreateMerchDesign, { loading: createRequestLoading }] =
    useMutation<AdminCreateMerchDesign, AdminCreateMerchDesignVariables>(
      ADMIN_CREATE_MERCH_DESIGN
    );

  const [adminEditMerchDesign, { loading: editRequestLoading }] = useMutation<
    AdminEditMerchDesign,
    AdminEditMerchDesignVariables
  >(ADMIN_EDIT_MERCH_DESIGN);

  const [createPresignedUrlForExampleImages] = useMutation<
    CreatePresignedUrlForExampleImages,
    CreatePresignedUrlForExampleImagesVariables
  >(CREATE_PRESIGNED_URL_FOR_EXAMPLE_IMAGES);

  // TODO: cover this with test cases in ticket https://millions.atlassian.net/browse/DEV-308
  useEffect(() => {
    if (initialData && Object.keys(initialData).length) {
      const {
        designColorPalettes,
        designCustomColor,
        designExtraInfo,
        socialMediaURL,
        designName,
        designSamples,
        designStyleSettings,
        designTypes,
        storeExampleImages,
      } = initialData.designCreationDetails || {};

      const {
        playfulSophisticated,
        matureYouthful,
        classicModern,
        feminineMasculine,
        geometricOrganic,
        abstractLiteral,
        economicalLuxurious,
      } = designStyleSettings || {};

      const initialDesignColorPalettes =
        designColorPalettes?.map((item) => item.id) || [];
      const initialTypesOfLogo = designTypes?.map((item) => item.id) || [];
      const initialDesignSamples = designSamples?.map((item) => item.id) || [];
      const initialExampleImages = storeExampleImages || [];

      const initialValues: DLValues = {
        typesOfLogo: initialTypesOfLogo,
        designs: initialDesignSamples,
        details: {
          playfulSophisticated,
          matureYouthful,
          classicModern,
          feminineMasculine,
          geometricOrganic,
          abstractLiteral,
          economicalLuxurious,
        },
        colors: initialDesignColorPalettes,
        designCustomColor: designCustomColor || '',
        extra: {
          name: designName || '',
          extraInfo: designExtraInfo || '',
          socialMediaURL: socialMediaURL || '',
          examples: initialExampleImages.map((item) => item.url),
        },
      };

      setValues(initialValues);
    }
  }, [initialData]);

  const onClose = () => {
    setValues(initDLValues);
    onComplete && onComplete();
  };

  const getImagesToUpload = (
    preSignedUrls: CreatePresignedUrlForExampleImages_createPresignedUrlForExampleImages[],
    images: File[]
  ): ImageToUpload[] => {
    const res: ImageToUpload[] = [];

    images.forEach((file, index) => {
      const preSignedUrl = preSignedUrls?.[index];
      const imageExtension = file
        ? file.name.split('.')[file.name.split('.').length - 1]
        : '';

      const imageName = `${preSignedUrl?.key || ''}.${imageExtension}`;

      res.push({
        fields: preSignedUrl?.fields || '',
        url: preSignedUrl?.url || '',
        name: imageName,
        type: file?.type || '',
        file: file || '',
      });
    });

    return res;
  };

  const uploadImages = async (
    preSignedUrls: CreatePresignedUrlForExampleImages_createPresignedUrlForExampleImages[],
    images: File[]
  ): Promise<string[]> => {
    const imagesToUpload = getImagesToUpload(preSignedUrls, images);

    for (const image of imagesToUpload) {
      const { fields, url, name, type, file } = image;
      const formData = new FormData();

      Object.entries(JSON.parse(fields)).forEach(([key, value]) => {
        formData.append(key, value as string);
      });

      formData.append('key', name);
      formData.append('Content-Type', type);
      formData.append('file', file);

      await fetch(url, {
        method: 'POST',
        body: formData,
      });
    }

    return imagesToUpload.map(({ name }) => name);
  };

  const createLogoRequest = async () => {
    try {
      const { data: preSignedUrlsData } =
        await createPresignedUrlForExampleImages({
          variables: {
            input: {
              numberOfImages: extra?.examples?.length || 0,
            },
          },
        });

      const preSignedUrls =
        preSignedUrlsData?.createPresignedUrlForExampleImages || [];

      const imagesUrls = await uploadImages(
        preSignedUrls,
        extra.examples as any[]
      );

      try {
        await adminCreateMerchDesign({
          variables: {
            input: {
              designCustomColor: designCustomColor || null,
              designColorPaletteIds: colors,
              designExtraInfo: extra.extraInfo,
              designName: extra.name || '',
              designSampleIds: designs,
              designStyleSettingsInput: details,
              designTypeIds: typesOfLogo,
              merchTypes: extra.merchTypes || [],
              socialMediaURL: extra.socialMediaURL,
              storeExampleImages: imagesUrls.map((item) => ({
                key: item,
              })),
              storeId: storeId || 'something went wrong',
            },
          },
          refetchQueries: [
            {
              query: DESIGN_REQUESTS,
              variables: {
                input: {
                  storeIds: [storeId],
                  orderBy: DesignRequestsOrderBy.createdAt,
                  direction: SortDirection.DESC,
                },
              },
            },
          ],
        });

        successNotification('Request successfully created');

        onClose();
      } catch (error) {
        errorNotification((error as Error)?.message || 'Something went wrong');
      }
    } catch (error) {
      errorNotification((error as Error)?.message || 'Something went wrong');
    }
  };

  // TODO: cover this with test cases in ticket https://millions.atlassian.net/browse/DEV-308
  const editLogoRequest = async () => {
    try {
      await adminEditMerchDesign({
        variables: {
          input: {
            designColorPaletteIds: colors,
            designCustomColor: designCustomColor || null,
            designExtraInfo: extra.extraInfo,
            designName: extra.name || '',
            designRequestId: initialData?.id || 'something went wrong',
            designSampleIds: designs,
            designStyleSettingsInput: details,
            designTypeIds: typesOfLogo,
            socialMediaURL: extra.socialMediaURL,
            // filter images that were uploaded early
            storeExampleImages: (extra.examples as any)?.filter(
              (item: any) => typeof item !== 'string'
            ),
            storeId: storeId || 'something went wrong',
          },
        },
        refetchQueries: [
          {
            query: DESIGN_REQUESTS,
            variables: {
              input: {
                storeIds: [storeId],
                orderBy: DesignRequestsOrderBy.createdAt,
                direction: SortDirection.DESC,
              },
            },
          },
        ],
      });

      successNotification('Request successfully updated');

      onClose();
    } catch (error) {
      errorNotification((error as Error)?.message || 'Something went wrong');
    }
  };

  const handleValuesChange = (values: DLStateValues): void => {
    setValues((prev) => ({
      ...prev,
      ...values,
    }));
  };

  const handleNextClick = async () => {
    // keep only available designs
    if (step === 1) {
      const onlyAvailableDesigns = designs.filter((design) =>
        designSamples.some((designSample) => designSample.id === design)
      );

      setValues((prev) => ({
        ...prev,
        designs: onlyAvailableDesigns,
      }));
    }

    if (step < 5) {
      setStep((prev) => prev + 1);
    }

    if (step === 5) {
      if (initialData) {
        // TODO: cover this with test cases in ticket https://millions.atlassian.net/browse/DEV-308
        await editLogoRequest();
      } else {
        await createLogoRequest();
      }
    }
  };

  const handlePrevClick = () => {
    setStep((prev) => prev - 1);
  };

  const checkIsNextButtonEnabled = (): boolean => {
    switch (step) {
      case 1: {
        return typesOfLogo.length > 0;
      }
      case 2: {
        return designSamples.length > 0 ? designs.length > 0 : true;
      }
      case 3: {
        return !!Object.keys(details).length;
      }
      case 4: {
        return Boolean(designCustomColor || colors.length);
      }
      case 5: {
        return Boolean(extra.name && extra.merchTypes?.length);
      }
      default: {
        return true;
      }
    }
  };

  const designTypes = designTypesData?.designTypes || [];
  const selectedDesignTypes: DesignTypes_designTypes[] = designTypes?.filter(
    (item: DesignTypes_designTypes) => {
      return item?.id ? typesOfLogo?.includes(item?.id) : false;
    }
  );
  const designSamples = selectedDesignTypes.reduce((acc, value) => {
    if (value.designSamples) {
      return acc.concat(value.designSamples);
    }

    return acc;
  }, [] as DesignTypes_designTypes_designSamples[]);
  const designColorPalettes: DesignColorPalettes_designColorPalettes[] =
    designColorPalettesData?.designColorPalettes || [];
  const isNextButtonDisabled = !checkIsNextButtonEnabled();

  if (designTypesLoading || designColorPalettesLoading) {
    return (
      <Spin size="large">
        <div className="spin" />
      </Spin>
    );
  }

  return (
    <>
      <Steps current={step} size="small" initial={1} responsive progressDot>
        {steps.map(({ title }) => (
          <Step key={title} title={title} />
        ))}
      </Steps>

      <div className="steps-content">
        <Title level={4} className={styles.stepTitle}>
          {steps[step - 1].title}
        </Title>

        <Text strong className={styles.stepDescription}>
          {steps[step - 1].description}
          {steps[step - 1]?.info && (
            <Tooltip title={steps[step - 1]?.info}>
              <InfoCircleTwoTone className={styles.stepDescriptionInfoIcon} />
            </Tooltip>
          )}
        </Text>

        <>
          {step === 1 && (
            <TypesOfLogoForm
              onChange={handleValuesChange}
              options={designTypes}
              selectedValues={typesOfLogo}
            />
          )}

          {step === 2 && (
            <PreferredDesignsForm
              onChange={handleValuesChange}
              options={designSamples}
              selectedValues={designs}
            />
          )}

          {step === 3 && (
            <DetailsForm
              onChange={handleValuesChange}
              selectedValues={details}
            />
          )}

          {step === 4 && (
            <PreferredColorsForm
              onChange={handleValuesChange}
              options={designColorPalettes}
              selectedValues={colors}
              designCustomColor={designCustomColor}
            />
          )}

          {step === 5 && (
            <ExtraInformationForm
              onChange={handleValuesChange}
              selectedValues={extra}
              isMerchDesign={isMerchDesign}
            />
          )}
        </>
      </div>

      <div className={cn('steps-action', styles.stepsActionWrapper)}>
        {step > 1 && (
          <Button
            onClick={handlePrevClick}
            icon={<LeftOutlined />}
            className={styles.stepsActionButton}
          >
            Back
          </Button>
        )}

        {step <= steps.length && (
          <Button
            type="primary"
            onClick={handleNextClick}
            disabled={isNextButtonDisabled}
            loading={createRequestLoading || editRequestLoading}
          >
            {step === 5
              ? initialData
                ? 'Update'
                : 'Finish and Request Design'
              : 'Next'}

            {step === 5 ? <SendOutlined /> : <RightOutlined />}
          </Button>
        )}
      </div>
    </>
  );
};

export default DLConstructor;
