import { gql } from '@apollo/client';

export const ASSIGN_INTERVIEW_ADMIN = gql`
  mutation AssignInterviewAdmin($input: AssignInterviewAdminInput!) {
    assignInterviewAdmin(input: $input) {
      id
    }
  }
`;

export const SCHEDULE_INTERVIEW_ADMIN = gql`
  mutation ScheduleInterviewAdmin($input: ScheduleInterviewAdminInput!) {
    scheduleInterviewAdmin(input: $input) {
      id
    }
  }
`;

export const UPDATE_INTERVIEW_INFO_ADMIN = gql`
  mutation UpdateInterviewInfoAdmin($input: UpdateInterviewInfoAdminInput!) {
    updateInterviewInfoAdmin(input: $input) {
      id
    }
  }
`;

export const CANCEL_INTERVIEW_ADMIN = gql`
  mutation CancelInterviewAdmin($input: CancelInterviewAdminInput!) {
    cancelInterviewAdmin(input: $input) {
      id
    }
  }
`;

export const CANCEL_INTERVIEW = gql`
  mutation CancelInterview($input: CancelInterviewInput!) {
    cancelInterview(input: $input) {
      id
    }
  }
`;

export const COMPLETE_INTERVIEW_ADMIN = gql`
  mutation CompleteInterviewAdmin($input: CompleteInterviewAdminInput!) {
    completeInterviewAdmin(input: $input) {
      id
    }
  }
`;

export const RESET_INTERVIEW_ADMIN = gql`
  mutation ResetInterviewAdmin($input: ResetInterviewAdminInput!) {
    resetInterviewAdmin(input: $input) {
      id
    }
  }
`;

export const SEND_MANUAL_INTERVIEW_INVITES = gql`
  mutation SendManualInterviewInvites(
    $input: SendManualInterviewInvitesInput!
  ) {
    sendManualInterviewInvites(input: $input) {
      id
    }
  }
`;

export const SCHEDULE_INTERVIEW = gql`
  mutation ScheduleInterview($input: ScheduleInterviewInput!) {
    scheduleInterview(input: $input) {
      id
    }
  }
`;

export const UPDATE_INTERVIEW_INFO = gql`
  mutation UpdateInterviewInfo($input: UpdateInterviewInfoInput!) {
    updateInterviewInfo(input: $input) {
      id
    }
  }
`;

export const SET_INTERVIEW_SETTINGS = gql`
  mutation SetInterviewSettings($input: SetInterviewSettingsInput!) {
    setInterviewSettings(input: $input) {
      id
    }
  }
`;

export const CREATE_STREAM_IMAGE_ADMIN = gql`
  mutation CreateStreamImageAdmin($input: CreateStreamImageAdminInput!) {
    createStreamImageAdmin(input: $input) {
      fields
      key
      url
    }
  }
`;

export const CREATE_STREAM_IMAGE = gql`
  mutation CreateStreamImage($input: CreateStreamImageInput!) {
    createStreamImage(input: $input) {
      fields
      key
      url
    }
  }
`;
