import React from 'react';
// Hooks
import { useAppContext } from 'hooks';
// Components
import MyVideoEditRequestTable from './MyVideoEditRequestTable/MyVideoEditRequestTable';

const MyVideoEditRequests: React.FC = () => {
  const { authUser } = useAppContext();

  return <MyVideoEditRequestTable videoEditorId={authUser?.id} />;
};

export default MyVideoEditRequests;
