import React, { useEffect, useMemo, useState, forwardRef } from 'react';
import cn from 'classnames';
import {
  isFirefox,
  isMobileSafari,
  isMobileOnly,
  isTablet,
  isChrome,
  isSafari,
} from 'react-device-detect';
// UI
import { Button, Typography } from 'antd';
// Components
import CropVideo, {
  CropArea,
  CropVideoNaturalMediaSize,
} from 'components/common/CropVideo/CropVideo';

import './styles.scss';

const { Text } = Typography;

type Props = {
  video: string | null | undefined;
  withToggle?: boolean;
  show?: boolean;
  onError?: () => void;
  isCropVisible?: boolean;
  onCropVideoComplete?: (area: CropArea) => void;
  cropAspect?: number;
  cropNotSupported?: boolean;
  wrapperClassName?: string;
  videoClassName?: string;
  noControl?: boolean;
};

export const VideoPreview = forwardRef<HTMLVideoElement, Props>(
  (
    {
      video,
      withToggle,
      onError,
      show = false,
      isCropVisible,
      cropAspect = 240 / 512,
      cropNotSupported,
      onCropVideoComplete,
      noControl,
      wrapperClassName,
      videoClassName,
    },
    ref
  ) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
      setIsVisible(show);
    }, [show]);

    const src = useMemo(
      () =>
        isMobileSafari || isSafari || isFirefox || isChrome
          ? video
          : `${video}#t=0.001`,
      [video]
    );
    const videoProps =
      isChrome && (isMobileOnly || isTablet) ? { preload: 'metadata' } : {};

    const onCropVideo = (
      area: CropArea,
      naturalMediaSize: CropVideoNaturalMediaSize
    ) => {
      let cropArea: CropArea = area;

      if (area?.width === 0 && area.height === 0) {
        const { naturalWidth, naturalHeight } = naturalMediaSize;
        let x = 0;
        let y = 0;
        let width = naturalWidth;
        let height = naturalHeight;

        if (naturalWidth < naturalHeight) {
          width = naturalHeight * cropAspect;
          x = (naturalWidth - width) / 2;
        }

        if (naturalWidth > naturalHeight) {
          height = naturalWidth * cropAspect;
          y = (naturalHeight - height) / 2;
        }

        cropArea = {
          x,
          y,
          width,
          height,
          zoom: 1,
        };
      }

      onCropVideoComplete && onCropVideoComplete(cropArea);
    };

    const player = video ? (
      <video
        className={cn(
          'videoPreview',
          {
            hidden: isCropVisible,
          },
          videoClassName
        )}
        src={src || ''}
        controls={noControl ? false : !!src}
        onError={onError}
        ref={ref}
        muted
        {...videoProps}
      >
        <source src={video} />
        Your browser does not support HTML5 video.
      </video>
    ) : null;

    return (
      <div
        className={cn(
          'previewWrapper',
          {
            previewWrapperWithCrop: show && isCropVisible,
          },
          wrapperClassName
        )}
      >
        {withToggle ? (
          <Button disabled={!video} onClick={() => setIsVisible(!isVisible)}>
            {isVisible ? 'Close preview' : 'Preview'}
          </Button>
        ) : null}

        {isVisible ? player : null}

        {isCropVisible && onCropVideoComplete && !cropNotSupported ? (
          <CropVideo
            videoLink={src || ''}
            aspect={cropAspect}
            onCropComplete={onCropVideo}
            containerClassName="cropVideoContainerWrapper"
          />
        ) : null}

        {isCropVisible && cropNotSupported ? (
          <Text type="danger">
            The <strong>video format</strong> is not supported. Please, select
            another video file or try another browser.
          </Text>
        ) : null}
      </div>
    );
  }
);

VideoPreview.displayName = 'VideoPreview';

export default VideoPreview;
