import React from 'react';
import { useSlate } from 'slate-react';
import cn from 'classnames';
import { Button } from 'antd';
import { HighlightOutlined } from '@ant-design/icons';
// Helpers
import {
  toggleMark,
  isMarkActive,
} from 'components/common/RichText/helpers/mark';
// Styles
import styles from './MarkButton.module.scss';

const MarkButton = (): JSX.Element => {
  const editor = useSlate();
  const isActive = isMarkActive(editor);

  const onMouseDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    toggleMark(editor);
  };

  return (
    <Button
      className={cn(styles.root, { [styles.isActive]: isActive })}
      type={isActive ? 'primary' : 'default'}
      onMouseDown={onMouseDown}
      icon={
        <HighlightOutlined
          className={cn(styles.icon, { [styles.isActive]: isActive })}
        />
      }
      htmlType="button"
    />
  );
};

export default MarkButton;
