import React from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Button, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// Api
import { GET_ALL_HASHTAGS } from 'api/hashtags/queries';
import { DELETE_HASHTAG, EDIT_HASHTAG } from 'api/hashtags/mutations';
// Types
import {
  DeleteHashtag,
  DeleteHashtagVariables,
} from 'api/hashtags/types/DeleteHashtag';
import {
  EditHashtag,
  EditHashtagVariables,
} from 'api/hashtags/types/EditHashtag';
import {
  GetAllHashtagsVariables,
  GetAllHashtags_getAllHashtags_entities,
} from 'api/hashtags/types/GetAllHashtags';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import CreateEditHashtagModal from 'pages/ManageHashtags/components/CreateEditHashtagModal/CreateEditHashtagModal';

const { confirm } = Modal;

type ActionsProps = {
  hashtag: GetAllHashtags_getAllHashtags_entities;
  getAllHashtagsInput: GetAllHashtagsVariables;
};

const Actions: React.FC<ActionsProps> = ({ hashtag, getAllHashtagsInput }) => {
  const [deleteHashtag, { loading: deleteHashtagLoading }] = useMutation<
    DeleteHashtag,
    DeleteHashtagVariables
  >(DELETE_HASHTAG);

  const [editHashtag, { loading: editHashtagLoading }] = useMutation<
    EditHashtag,
    EditHashtagVariables
  >(EDIT_HASHTAG);

  const initialHashtagName = hashtag.name;
  const initialSportIds = hashtag.sports?.map((item) => item.id);

  const onDeleteHashtagButtonClick = () => {
    confirm({
      title: `Are You sure You want to delete hashtag #${hashtag.name}?`,
      content:
        'By doing this, You will delete this hashtag from platform and all stores.',
      icon: <ExclamationCircleOutlined />,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: handleHashtagDelete,
    });
  };

  const handleHashtagDelete = async () => {
    try {
      await deleteHashtag({
        variables: { hashtagId: hashtag.id },
        refetchQueries: [
          {
            query: GET_ALL_HASHTAGS,
            variables: { ...getAllHashtagsInput },
          },
        ],
      });
      successNotification(
        `Hashtag #${hashtag.name} has been deleted successfully`
      );
    } catch (error) {
      errorNotification((error as Error)?.message);
      console.error(error);
    }
  };

  const handleHashtagUpdate = async ({
    hashtagName,
    sportIds,
    onSuccess,
  }: {
    hashtagName: string;
    sportIds: number[];
    onSuccess: () => void;
  }) => {
    try {
      if (hashtagName) {
        await editHashtag({
          variables: { input: { id: hashtag.id, name: hashtagName, sportIds } },
          refetchQueries: [
            {
              query: GET_ALL_HASHTAGS,
              variables: { ...getAllHashtagsInput },
            },
          ],
        });

        onSuccess();

        successNotification(
          `Hashtag #${hashtagName} has been updated successfully`
        );
      }
    } catch (error) {
      errorNotification((error as Error)?.message);
      console.error(error);
    }
  };

  return (
    <Space size="middle" direction="vertical">
      <CreateEditHashtagModal
        onSubmit={handleHashtagUpdate}
        loading={editHashtagLoading}
        initialHashtagName={initialHashtagName}
        initialSportIds={initialSportIds}
      >
        {({ onModalOpen }) => {
          return (
            <Button type="primary" onClick={onModalOpen}>
              Edit
            </Button>
          );
        }}
      </CreateEditHashtagModal>

      <Button
        type="primary"
        danger
        onClick={onDeleteHashtagButtonClick}
        loading={deleteHashtagLoading}
      >
        Delete
      </Button>
    </Space>
  );
};

export default Actions;
