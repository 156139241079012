import { CreateCampaignExampleFiles_createCampaignExampleFiles } from 'api/campaignV2/types/CreateCampaignExampleFiles';
import {
  CampaignMediaType,
  FromToFilter,
  StoreGender,
} from 'api/graphql-global-types';

export type FromToValueLabel = {
  value: FromToFilter;
  label: string;
};

export const GENDER_OPTIONS = [
  { value: StoreGender.male, label: 'Male' },
  { value: StoreGender.female, label: 'Female' },
  { value: StoreGender.other, label: 'Other' },
  { value: StoreGender.preferNotToSay, label: 'Prefer not to say' },
  { value: '', label: 'All' },
];

export const PRODUCT_TYPES = [
  {
    value: 'Non-Battery Product',
    label: 'Non-Battery Product',
  },
  {
    value: 'Battery Product',
    label: 'Battery Product',
  },
  {
    value: 'Non-Alcoholic Liquid',
    label: 'Non-Alcoholic Liquid',
  },
  {
    value: 'Oversized/weight Product',
    label: 'Oversized/weight Product',
  },
  {
    value: 'Food',
    label: 'Food',
  },
  {
    value: 'Plants',
    label: 'Plants',
  },
  {
    value: 'CBD/Cannabis',
    label: 'CBD/Cannabis',
  },
  {
    value: 'Alcohol',
    label: 'Alcohol',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const MEDIA_TYPES = [
  { value: CampaignMediaType.IMAGE, label: 'Image' },
  { value: CampaignMediaType.VIDEO, label: 'Video' },
];

export const FOLLOWING_OPTIONS: FromToValueLabel[] = [
  { value: { from: 1_000, to: 100_000_000 }, label: '1k+' },
  { value: { from: 5_000, to: 100_000_000 }, label: '5k+' },
  { value: { from: 10_000, to: 100_000_000 }, label: '10K+' },
  { value: { from: 25_000, to: 100_000_000 }, label: '25k+' },
  { value: { from: 50_000, to: 100_000_000 }, label: '50k+' },
  { value: { from: 100_000, to: 100_000_000 }, label: '100k+' },
  { value: { from: 500_000, to: 100_000_000 }, label: '500K+' },
  { value: { from: 1_000_000, to: 100_000_000 }, label: '1M+' },
];

export const findSocialFollowersRange = (
  numberOfFollowers: string | null
): FromToFilter | null => {
  if (numberOfFollowers === '0') {
    return FOLLOWING_OPTIONS[0].value;
  } else if (numberOfFollowers === '1000') {
    return FOLLOWING_OPTIONS[1].value;
  } else if (numberOfFollowers === '10000') {
    return FOLLOWING_OPTIONS[2].value;
  } else if (numberOfFollowers === '100000') {
    return FOLLOWING_OPTIONS[3].value;
  } else if (numberOfFollowers === '1000000') {
    return FOLLOWING_OPTIONS[4].value;
  } else {
    return null;
  }
};

type ImageToUpload = {
  fields: string;
  url: string;
  key: string;
  type: string;
  file: File | string;
};

export const getImagesToUpload = (
  preSignedUrls: CreateCampaignExampleFiles_createCampaignExampleFiles[],
  images: FileWithPreview[]
): ImageToUpload[] => {
  const res: ImageToUpload[] = [];

  images.forEach((file, index) => {
    const preSignedUrl = preSignedUrls?.[index];

    res.push({
      fields: preSignedUrl?.fields || '',
      url: preSignedUrl?.url || '',
      key: preSignedUrl?.key,
      type: file?.type || '',
      file: file || '',
    });
  });

  return res;
};

export const uploadImages = async (
  preSignedUrls: CreateCampaignExampleFiles_createCampaignExampleFiles[],
  images: FileWithPreview[]
): Promise<string[]> => {
  const imagesToUpload = getImagesToUpload(preSignedUrls, images);

  for (const image of imagesToUpload) {
    const { fields, url, key, file } = image;
    const formData = new FormData();

    Object.entries(JSON.parse(fields)).forEach(([key, value]) => {
      formData.append(key, value as string);
    });

    formData.append('key', key);
    formData.append('file', file);

    await fetch(url, {
      method: 'POST',
      body: formData,
    });
  }

  return imagesToUpload.map(({ key }) => key);
};

export const isUsOrCa = (country: string): boolean => {
  return (
    country === 'United States' ||
    country === 'Canada' ||
    country === 'US' ||
    country === 'CA'
  );
};
