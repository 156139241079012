import { useQuery, QueryResult } from '@apollo/client';
// Api
import { GET_PRICE_MARGINS } from 'api/margin/queries';
import { GetPriceMargins } from 'api/margin/types/GetPriceMargins';

export const useGetPriceMargins = (): QueryResult<GetPriceMargins> => {
  const data = useQuery<GetPriceMargins>(GET_PRICE_MARGINS);

  return data;
};
