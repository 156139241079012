export const DECLINE_REASON: Record<string, string> = {
  CONFLICT_INTERESTS: 'Conflict of Interest',
  INAPPROPRIATE: 'Inappropriate',
  UNABLE_ANSWER: 'Unable To Answer',
};

export const VALID_VIDEO_EXTENSIONS = [
  '3gp',
  '3g2',
  'wmv',
  'avi',
  'flv',
  'f4v',
  'f4p',
  'f4a',
  'f4b',
  'ts',
  'imf',
  'mkv',
  'mpg',
  'mpeg',
  'm2v',
  'mp2',
  'mp4',
  'm4p',
  'm4v',
  'mpe',
  'mpv',
  'mxf',
  'mov',
  'qt',
  'webm',
];

export const isValidVideoExtension = (type: string): boolean => {
  return VALID_VIDEO_EXTENSIONS.includes(type.toLowerCase());
};

export const STATUS: Record<string, string> = {
  PENDING: 'Pending',
  PROCESSING: 'Processing',
  ACCEPTED: 'Accepted',
  COMPLETED: 'Completed',
  DECLINED: 'Declined',
  IGNORED: 'Ignored',
};
