import React, { useState } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
// Api
import { GET_STORES_AMA_REQUESTS } from 'api/ama/queries';
// Types
import {
  AllStoresAmaRequestsPaginated,
  AllStoresAmaRequestsPaginatedVariables,
  AllStoresAmaRequestsPaginated_amaRequestsPaginated_entities,
} from 'api/ama/types/AllStoresAmaRequestsPaginated';
import {
  AmaOrderStatus,
  AmaRequestOrderBy,
  SortDirection,
} from 'api/graphql-global-types';
// UI
import Table, { SortedInfo } from 'ui/Table';
// Components
import Actions from 'components/common/ManageAmaRequests/components/Actions';
// Helpers
import { calculateRemainingDays } from 'helpers/utils';

const AllAmaRequests: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<AmaRequestOrderBy>>({
    order: SortDirection.DESC,
    key: AmaRequestOrderBy.CreatedAt,
  });
  const { data, loading } = useQuery<
    AllStoresAmaRequestsPaginated,
    AllStoresAmaRequestsPaginatedVariables
  >(GET_STORES_AMA_REQUESTS, {
    variables: {
      input: {
        orderStatus: [AmaOrderStatus.Accepted, AmaOrderStatus.Processing],
        direction: sort.order,
        orderBy: sort.key,
        limit: pageSize,
        offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    {
      title: 'Store name',
      dataIndex: 'store',
      fixed: 'left' as const,
      render: (store: any) => `${store.firstName} ${store.lastName}`,
    },
    {
      title: 'Customer name',
      dataIndex: 'customer',
      fixed: 'left' as const,
      render: (customer: any) => `${customer.firstName} ${customer.lastName}`,
    },
    {
      title: 'Type',
      dataIndex: ['ama', 'type'],
      align: 'left' as const,
    },
    {
      title: 'Description',
      dataIndex: 'instructions',
    },
    {
      title: 'Status',
      dataIndex: 'orderStatus',
      align: 'center' as const,
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: AmaRequestOrderBy.CreatedAt,
      align: 'center' as const,
      sorterType: 'date',
      render: (registered: number) => moment(registered).format('ll'),
    },
    {
      title: 'Response days left',
      dataIndex: 'dateToResponse',
      align: 'center' as const,
      render: (dateToResponse: string) =>
        `${calculateRemainingDays(dateToResponse)} days`,
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      align: 'center' as const,
      render: function ActionsWrap(text: any, record: any) {
        return <Actions amaId={record.id} orderStatus={record.orderStatus} />;
      },
    },
  ];

  return (
    <Table<
      AllStoresAmaRequestsPaginated_amaRequestsPaginated_entities,
      AmaRequestOrderBy
    >
      columns={columns}
      data={data?.amaRequestsPaginated?.entities}
      scroll={{ x: 800 }}
      loading={loading}
      total={data?.amaRequestsPaginated?.total}
      setPageSize={setPageSize}
      setCurrentPage={setCurrentPage}
      sortInfo={sort}
      setSortInfo={setSortInfo}
    />
  );
};

export default AllAmaRequests;
