type DataType = {
  required?: boolean;
  message: string;
};

export const formItemRules = {
  name: [
    {
      required: true,
      message: 'Please enter the name of the promo code',
    },
  ],
  startDate: [
    {
      required: true,
      message: 'Please enter the start date of the promo code',
    },
  ],
  validityPeriod: [
    {
      required: true,
      message: 'Please enter the start and end date of the promo code',
    },
  ],
  timeZone: [
    {
      required: true,
      message: 'Please select time zone.',
    },
  ],
  amount: [
    {
      required: true,
      message: 'Please enter the amount of discount',
    },
  ],
  limitations(required?: boolean): DataType[] {
    return [
      {
        required,
        message: 'Please enter the limit of usage',
      },
    ];
  },
  usagePerUser(required?: boolean): DataType[] {
    return [
      {
        required,
        message: 'Please enter the limit of usage per user',
      },
    ];
  },
  chargeSubject: [
    {
      message: 'Please enter the name of the promo code',
    },
  ],
  siteWideData(required?: boolean): DataType[] {
    return [
      {
        required,
        message: 'Please select a product from the list',
      },
    ];
  },
};
