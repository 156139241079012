import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Space } from 'antd';
import moment from 'moment';
// Api
import { GET_PROMO_CODES } from 'api/promocodes/queries';
// Types
import {
  SortDirection,
  PromoCodesOrderBy,
  PromoCodesChargeSubject,
} from 'api/graphql-global-types';
import {
  GetPromocodes,
  GetPromocodesVariables,
  GetPromocodes_getPromocodes_entities,
} from 'api/promocodes/types/GetPromocodes';
import { SortedInfo, TableFilter } from 'ui/Table';
// Components
import Actions from './components/Actions/Actions';
import ProductsList from './components/ProductsList/ProductsList';
import CreatePromoCodeModal from './components/CreatePromoCodeModal';
// UI
import Table from 'ui/Table';

import './styles.scss';

const PromoCodes: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<PromoCodesOrderBy>>({
    order: SortDirection.DESC,
    key: PromoCodesOrderBy.createdAt,
  });
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [createPromoCodeModalVisible, setCreatePromoCodeModalVisible] =
    useState<boolean>(false);

  const getPromoCodesInput: any = () => {
    const input: any = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      if (key === 'startDate' || key === 'endDate') {
        return (input[key] = {
          from: Array.isArray(value) ? value?.[0] : value,
          to: Array.isArray(value) ? value?.[1] : value,
        });
      }
      input[key] = value;
    });

    return { input };
  };

  const { data: promoCodesData, loading } = useQuery<
    GetPromocodes,
    GetPromocodesVariables
  >(GET_PROMO_CODES, {
    fetchPolicy: 'cache-and-network',
    variables: { ...getPromoCodesInput() },
  });

  const closeCreatePromoCodeModal = (): void => {
    setCreatePromoCodeModalVisible(false);
  };

  const onAddPromoCode = () => {
    setCreatePromoCodeModalVisible(true);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: PromoCodesOrderBy.name,
      fixed: 'left' as const,
      align: 'left' as const,
      width: 100,
      withSearch: true,
      sorterType: 'text',
    },
    {
      title: 'Start date',
      dataIndex: 'startDate',
      key: PromoCodesOrderBy.startDate,
      align: 'left' as const,
      width: 70,
      sorterType: 'text',
      withDateRangeFilter: true,
      render: function StartDate(_: any, record: any) {
        return record?.startDate
          ? moment(record.startDate, 'YYYY-MM-DD').format('MMM DD YYYY')
          : '-';
      },
    },
    {
      title: 'End date',
      dataIndex: 'endDate',
      key: PromoCodesOrderBy.endDate,
      align: 'left' as const,
      width: 70,
      sorterType: 'text',
      withDateRangeFilter: true,
      render: function EndDate(_: any, record: any) {
        return record?.endDate
          ? moment(record.endDate, 'YYYY-MM-DD').format('MMM DD YYYY')
          : '-';
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: PromoCodesOrderBy.amount,
      align: 'left' as const,
      width: 50,
      sorterType: 'number',
      render: function Amount(_: any, record: any) {
        return record?.amount ? `${record?.amount}%` : '-';
      },
    },
    {
      title: 'Charge',
      dataIndex: 'chargeSubject',
      key: PromoCodesOrderBy.chargeSubject,
      align: 'left' as const,
      width: 40,
      withCheckboxFilters: true,
      filters: [
        {
          text: PromoCodesChargeSubject.Store,
          value: PromoCodesChargeSubject.Store,
        },
        {
          text: PromoCodesChargeSubject.Platform,
          value: PromoCodesChargeSubject.Platform,
        },
      ],
      filterMultiple: true,
      sorterType: 'string',
    },
    {
      title: 'Products',
      dataIndex: 'productsList',
      key: 'productsList',
      align: 'center' as const,
      width: 60,
      render: function Products(
        _: any,
        record: GetPromocodes_getPromocodes_entities
      ) {
        if (record.isSiteWide) {
          return 'Sitewide';
        } else {
          return <ProductsList promoCodeId={record.id} />;
        }
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 60,
      render: function ActionsWrap(
        id: string,
        record: GetPromocodes_getPromocodes_entities
      ) {
        return (
          <Actions
            promoCode={record}
            getPromoCodesInput={getPromoCodesInput()}
          />
        );
      },
    },
  ];

  return (
    <>
      <div className="promo-codes">
        <Space
          direction="vertical"
          size="middle"
          align="end"
          className="promo-codes__cta-wrapper"
        >
          <Button
            type="primary"
            onClick={onAddPromoCode}
            className="promo-codes__headeer__cta"
          >
            Add promo code
          </Button>
        </Space>

        <Table<GetPromocodes_getPromocodes_entities, PromoCodesOrderBy>
          columns={columns}
          data={promoCodesData?.getPromocodes.entities}
          scroll={{ x: 1000 }}
          loading={loading}
          total={promoCodesData?.getPromocodes.total}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
          sortInfo={sort}
          setSortInfo={setSortInfo}
        />
      </div>

      {createPromoCodeModalVisible && (
        <CreatePromoCodeModal
          show={createPromoCodeModalVisible}
          onCancel={closeCreatePromoCodeModal}
        />
      )}
    </>
  );
};

export default PromoCodes;
