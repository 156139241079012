import React from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Form, Typography, Input } from 'antd';
// Api
import { CREATE_MODERATOR, EDIT_MODERATOR } from 'api/streams/mutations';
import { GET_MODERATORS } from 'api/streams/queries';
// Types
import {
  CreateModerator,
  CreateModeratorVariables,
} from 'api/streams/types/CreateModerator';
import {
  EditModerator,
  EditModeratorVariables,
} from 'api/streams/types/EditModerator';
import { GetModerators_moderators_entities } from 'api/streams/types/GetModerators';
// UI
import { errorNotification, successNotification } from 'ui/Notification';

const { Text } = Typography;

type CreateEditModeratorModalProps = {
  onClose: () => void;
  moderator?: GetModerators_moderators_entities;
  isVisible: boolean;
};

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
};

const CreateEditModeratorModal: React.FC<CreateEditModeratorModalProps> = ({
  onClose,
  moderator,
  isVisible,
}) => {
  const [form] = Form.useForm();

  const initialFormValues: FormValues = {
    firstName: moderator?.firstName || '',
    lastName: moderator?.lastName || '',
    email: moderator?.email || '',
    phoneNumber: moderator?.phoneNumber || '',
  };

  const onModalClose = () => {
    form.resetFields();
    onClose();
  };

  const [createModerator] = useMutation<
    CreateModerator,
    CreateModeratorVariables
  >(CREATE_MODERATOR);

  const [updateModerator] = useMutation<EditModerator, EditModeratorVariables>(
    EDIT_MODERATOR
  );

  const handleCreateModerator = async () => {
    const { firstName, lastName, email, phoneNumber } = form.getFieldsValue();

    try {
      await createModerator({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            phoneNumber,
          },
        },
        refetchQueries: [
          {
            query: GET_MODERATORS,
          },
        ],
      });

      successNotification(`Moderator created successfully`);
      onModalClose();
    } catch (err: any) {
      errorNotification(err?.message || 'Something went wrong');
    }
  };

  const handleEditModerator = async () => {
    const { firstName, lastName, email, phoneNumber } = form.getFieldsValue();

    try {
      await updateModerator({
        variables: {
          input: {
            moderatorId: moderator?.id || '',
            firstName,
            lastName,
            email,
            phoneNumber,
          },
        },
        refetchQueries: [
          {
            query: GET_MODERATORS,
          },
        ],
      });

      successNotification(
        `Moderator ${moderator?.firstName || ''} ${
          moderator?.lastName || ''
        } updated successfully`
      );

      onModalClose();
    } catch (err) {
      errorNotification((err as Error)?.message || 'Something went wrong');
    }
  };

  const onFormSubmit = async () => {
    try {
      await form.validateFields();
      return moderator ? handleEditModerator() : handleCreateModerator();
    } catch (err) {
      errorNotification((err as Error)?.message || 'Something went wrong');
    }
  };

  return (
    <Modal
      title={`${moderator ? 'Edit' : 'Create new'} Moderator`}
      destroyOnClose
      visible={isVisible}
      okText={moderator ? 'Save' : 'Create'}
      cancelText="Cancel"
      onOk={onFormSubmit}
      onCancel={onModalClose}
    >
      <Form
        form={form}
        layout="vertical"
        name="CreateEditModerator"
        initialValues={initialFormValues}
      >
        <Form.Item
          name="firstName"
          label={<Text>First name</Text>}
          rules={[
            {
              required: true,
              message: 'Please enter first name',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastName"
          label={<Text>Last name</Text>}
          rules={[
            {
              required: true,
              message: 'Please enter last name',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label={<Text>Email</Text>}
          rules={[
            {
              required: true,
              pattern: /\S+@\S+\.\S+/,
              message: 'Please enter a valid e-mail',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phoneNumber"
          label="Phone number"
          rules={[
            {
              required: true,
              pattern:
                /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/gm,
              message: 'Please enter a valid phone number',
            },
          ]}
        >
          <Input placeholder="Enter phone number" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateEditModeratorModal;
