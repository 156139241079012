import { gql } from '@apollo/client';

export const InterviewFields = gql`
  fragment InterviewFields on Interview {
    id
    adminNotes
    notes
    completedAt
    createdAt
    creationType
    interviewee {
      id
      firstName
      lastName
      slug
      storeDetails {
        id
        storeName
      }
      sports {
        id
        name
      }
    }
    interviewer {
      id
      firstName
      lastName
      slug
      interviewerDetails {
        id
      }
    }
    isError
    notes
    scheduledMeetingLink
    source {
      id
      type
    }
    status
    stream {
      id
      slug
      mainImageUrl
      scheduleDate
      name
      timeZone {
        tzCode
        offset
      }
      hashtags {
        id
        name
      }
      mentions {
        id
        mentionType
        store {
          slug
          id
          storeDetails {
            id
            storeName
            storeId
            avatarURL
          }
          firstName
          lastName
        }
        targetType
        unregisteredContact {
          email
          id
          name
          representativeName
          type
          url
        }
      }
    }
    streamCohostToken
    updatedAt
  }
`;
