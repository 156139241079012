import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Input, Form, Switch, Typography, Button, Space } from 'antd';
// Api
import {
  CREATE_VIDEO_EDITOR,
  EDIT_VIDEO_EDITOR_ADMIN,
} from 'api/videoLab/mutation';
import { GET_VIDEO_EDITORS } from 'api/videoLab/queries';
// Types
import {
  CreateVideoEditor,
  CreateVideoEditorVariables,
} from 'api/videoLab/types/CreateVideoEditor';
import { VideoEditorOrderBy } from 'api/graphql-global-types';
import {
  EditVideoEditorAdmin,
  EditVideoEditorAdminVariables,
} from 'api/videoLab/types/EditVideoEditorAdmin';
import { VideoEditors_videoEditors_entities } from 'api/videoLab/types/VideoEditors';
// UI
import { successNotification, errorNotification } from 'ui/Notification';
import Tip from 'ui/Tip';

const { Text } = Typography;

const formItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 12 },
};

type CreateEditVideoEditorModalProps = {
  videoEditor?: VideoEditors_videoEditors_entities;
  isCreateEditEditorModalOpen: boolean;
  toggleVideoEditorModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateEditVideoEditorModal: React.FC<CreateEditVideoEditorModalProps> = ({
  videoEditor,
  isCreateEditEditorModalOpen,
  toggleVideoEditorModal,
}) => {
  const [editVideoEditorAdmin, { loading: isUpdatingInProgress }] = useMutation<
    EditVideoEditorAdmin,
    EditVideoEditorAdminVariables
  >(EDIT_VIDEO_EDITOR_ADMIN, {
    onCompleted: () => {
      toggleVideoEditorModal(false);
    },
  });

  const [createVideoEditor, { loading: createVideoEditorLoading }] =
    useMutation<CreateVideoEditor, CreateVideoEditorVariables>(
      CREATE_VIDEO_EDITOR
    );

  const [isAutoAssigned, setIsAutoAssigned] = useState<boolean>(
    videoEditor?.videoEditorDetails.isIncludedInAutoAssignment || false
  );

  const [form] = Form.useForm();

  const handleVideoEditor = async () => {
    if (videoEditor) {
      try {
        const { firstName, lastName, email, autoAssign } =
          form.getFieldsValue();
        const inputValues = {
          videoEditorDetails: { isIncludedInAutoAssignment: autoAssign },
          videoEditorId: videoEditor.id,
          email,
          firstName,
          lastName,
        };

        await editVideoEditorAdmin({ variables: { input: inputValues } });
        successNotification('The video editor is updated');
      } catch (e) {
        errorNotification();
      }
    }
  };

  const onFormSubmit = () => {
    if (videoEditor) {
      handleVideoEditor();
    } else {
      form.validateFields().then(handleCreateVideoEditor);
    }
  };

  const handleCreateVideoEditor = async (values: any) => {
    const { email, firstName, lastName } = values;

    if (email && firstName && lastName) {
      try {
        await createVideoEditor({
          variables: { input: { email, firstName, lastName } },
          refetchQueries: [
            {
              query: GET_VIDEO_EDITORS,
              variables: {
                input: {
                  limit: 10,
                  offset: 0,
                  orderBy: VideoEditorOrderBy.createdAt,
                },
              },
            },
          ],
        });

        successNotification(
          `Invitation for video editor ${firstName} ${lastName} has been sent successfully`
        );
        toggleVideoEditorModal(true);
      } catch (error) {
        errorNotification((error as Error)?.message || 'Something went wrong');
        console.error(error);
      }
    }
  };

  const handleAutoAssignChange = (checked: boolean) =>
    setIsAutoAssigned(checked);

  const hideVideoEditorEditModal = () => {
    toggleVideoEditorModal(false);
    form.resetFields();
    setIsAutoAssigned(
      videoEditor?.videoEditorDetails.isIncludedInAutoAssignment || false
    );
  };

  return (
    <Modal
      title={videoEditor ? 'Edit Video editor' : 'Create Video editor'}
      visible={isCreateEditEditorModalOpen}
      onCancel={hideVideoEditorEditModal}
      confirmLoading={isUpdatingInProgress || createVideoEditorLoading}
      footer={null}
    >
      <Form
        form={form}
        layout="horizontal"
        name="setupVideoEditor"
        autoComplete="off"
        initialValues={videoEditor}
        onFinish={onFormSubmit}
        {...formItemLayout}
      >
        <Form.Item name="videoEditorId" hidden>
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="firstName"
          label={<Text>First Name</Text>}
          rules={[
            {
              required: true,
              message: 'Please enter first name',
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label={<Text>Last Name</Text>}
          rules={[
            {
              required: true,
              message: 'Please enter last name',
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label={<Text>Email</Text>}
          rules={[
            {
              required: true,
              message: 'Please enter email',
            },
          ]}
        >
          <Input />
        </Form.Item>
        {videoEditor && (
          <Form.Item
            name="autoAssign"
            label={
              <Text>
                Auto Assign
                <Tip title="Enable auto assignment for video editor" />
              </Text>
            }
          >
            <Switch
              onChange={handleAutoAssignChange}
              checked={isAutoAssigned}
            />
          </Form.Item>
        )}

        <Space
          direction="horizontal"
          align="baseline"
          style={{ width: '100%', justifyContent: 'flex-end' }}
        >
          <Form.Item>
            <Button onClick={hideVideoEditorEditModal}>Cancel</Button>
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              loading={isUpdatingInProgress || createVideoEditorLoading}
            >
              Ok
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default CreateEditVideoEditorModal;
