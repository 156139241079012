import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Form } from 'antd';
// API
import { CREATE_PROMO_CODE } from 'api/promocodes/mutations';
// Types
import {
  CreatePromoCode,
  CreatePromoCodeVariables,
} from 'api/promocodes/types/CreatePromoCode';
import { GetPromocodes } from 'api/promocodes/types/GetPromocodes';
import { PromocodeFormValuesInterface } from '../../data/promocode-form-values.interface';
// Context
import { useStoresSelectProducts } from 'context/StoresSelectedProducts';
// Helpers
import { promoCodeInputDataConverter } from '../../converter/promocode-input-data.converter';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import CreatePromoCodeForm from '../CreatePromoCodeForm/CreatePromoCodeForm';

export type CreatePromoCodeModalProps = {
  show: boolean;
  onCancel: () => void;
};

const CreatePromoCodeModal: React.FC<CreatePromoCodeModalProps> = ({
  show,
  onCancel,
}) => {
  const [createPromocode, { loading: createPromocodeLoading }] = useMutation<
    CreatePromoCode,
    CreatePromoCodeVariables
  >(CREATE_PROMO_CODE, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          getPromocodes(getPromocodes: GetPromocodes['getPromocodes']) {
            const promoCodes = getPromocodes?.entities || [];

            if (data?.createPromocode) {
              return [data.createPromocode, ...promoCodes];
            } else {
              return promoCodes;
            }
          },
        },
      });
    },
  });

  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const { getSelectedProducts } = useStoresSelectProducts();
  const onCreateSuccess = (name: string) => {
    const message = `Promo code [${name}] created.`;

    setLoading(false);
    onCancel();
    successNotification(message);
  };

  const handleCreateClick = async (): Promise<void> => {
    const values =
      (await form.validateFields()) as PromocodeFormValuesInterface;

    try {
      setLoading(true);

      if (!values.isSiteWide) {
        values.storeProducts = getSelectedProducts();

        if (!values?.storeProducts?.length) {
          errorNotification('Pick at least 1 Store Item.');

          setLoading(false);

          return;
        }
      }

      const input = promoCodeInputDataConverter(values);
      await createPromocode({ variables: { input } });

      onCreateSuccess(values.name);
    } catch (error) {
      if (values.amount === 0) {
        errorNotification('Minimum discount amount is 1%');
      } else {
        errorNotification((error as Error)?.message);
      }
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Create new promo code"
      destroyOnClose
      visible={show}
      width={920}
      okText="Create"
      cancelText="Cancel"
      onOk={handleCreateClick}
      onCancel={onCancel}
      confirmLoading={loading || createPromocodeLoading}
    >
      <CreatePromoCodeForm formInstance={form} />
    </Modal>
  );
};

export default CreatePromoCodeModal;
