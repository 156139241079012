import React from 'react';
import moment from 'moment';
// Helpers
import { formatCurrencyString } from 'helpers/utils';
// Types
import { TooltipProps } from 'recharts';
import { DashboardChartData } from '../DashboardChart';

import styles from './CustomTooltip.module.scss';

interface ToolTipPayload {
  payload?: DashboardChartData;
}

type CustomTooltipProps = TooltipProps<
  number | string | Array<number | string>,
  number | string
> & {
  payload?: ToolTipPayload[];
  currency?: string;
};

export const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  currency,
}) => {
  if (active && payload) {
    return (
      <div className={styles.customTooltip}>
        <p className={styles.customTooltipLabel}>
          {moment(payload?.[0]?.payload?.date).format('MMM DD YYYY')}
        </p>
        <ul className={styles.customTooltipItemsList}>
          {payload?.map(({ color, name, value = 0 }, index: number) => (
            <li
              key={`${index}${name}`}
              style={{ color }}
              className={styles.customTooltipItemsListItem}
            >
              <span className={styles.customTooltipItemsListItemName}>
                {name}
              </span>
              {': '}
              {formatCurrencyString(Number(value), currency, currency ? 2 : 0)}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
};
