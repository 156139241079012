export const getEnvLink = (env: string): string => {
  const envLinks: { [key: string]: string } = {
    development: 'http://localhost:4000',
    staging: 'https://stg1.millions.co',
    production: 'https://millions.co',
  };

  const selectedEnvLink = envLinks[env] || envLinks.development;
  return selectedEnvLink;
};
