export interface TreeDataBuilder {
  id: string | number;
  storeId: string;
  type?: string;
  name?: string | null;
  title?: string | null;
  __typename: string;
}

export class TreeDataEntity {
  value: string;
  title: string;
  children?: TreeDataEntity[];

  public static fromObject({
    id,
    __typename: typename,
    type,
    title,
    name,
    storeId,
  }: TreeDataBuilder): TreeDataEntity {
    const data = new TreeDataEntity();

    data.title =
      typename === 'Ama' ? type || '' : title || name || `${typename} [${id}]`;
    data.value = [typename, id, storeId].join('|');

    return data;
  }
}
