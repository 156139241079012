import React, { useCallback, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
// Hooks
import { useHasMounted } from 'hooks/index';
// Api
import { DASHBOARD_STATS } from 'api/dashboard/queries';
// Types
import {
  GetDashboardStats,
  GetDashboardStatsVariables,
  GetDashboardStats_getDashboardStats,
  GetDashboardStats_getDashboardStats_popularItems,
} from 'api/dashboard/types/GetDashboardStats';
import { DashboardChartData } from './DashboardChart/DashboardChart';
// Helpers
import { formatCurrencyString } from 'helpers/utils';
// Ui
import Table from 'ui/Table';
// Components
import DashboardHeader from './DashboardHeader/DashboardHeader';
import DashboardSection from './DashboardSection/DashboardSection';
import DashboardChart from './DashboardChart/DashboardChart';
import DashboardPromoCodes from './DashboardPromoCodes/DashboardPromoCodes';
// Styles
import styles from './Dashboard.module.scss';

type StatsDataItem = {
  date: string;
  total?: number;
  sum?: number;
};

type StatsDataJson = {
  ama_request: StatsDataItem[];
  experience_request: StatsDataItem[];
  memorabilia_order: StatsDataItem[];
  merch_order: StatsDataItem[];
  product_order: StatsDataItem[];
  stream_order: StatsDataItem[];
  stream_tip: StatsDataItem[];
  offer: StatsDataItem[];
  event_marketing: StatsDataItem[];
};

type GraphItemData = {
  ama: number;
  experience: number;
  memorabilia: number;
  merch: number;
  products: number;
  streams: number;
  offers: number;
  eventMarketing: number;
  tips: number;
  date: string;
};

export enum ChartType {
  Revenue = 'revenueStats',
  ProfitMargin = 'profitStats',
}

const format = 'YYYY-MM-DD';

const Dashboard: React.FC = () => {
  const hasMounted = useHasMounted();

  const { storeId } = useParams<{ storeId: string | undefined }>();

  const defaultStartDate = moment()
    .subtract(1, 'months')
    .startOf('day')
    .utc(true);
  const defaultEndDate = moment().endOf('day').utc(true);
  const [startDate, setStartDate] = useState<Moment>(defaultStartDate);
  const [currentChartType, changeChartType] = useState<ChartType>(
    ChartType.Revenue
  );
  const [endDate, setEndDate] = useState<Moment>(defaultEndDate);
  const [revenueChartData, setRevenueChartData] = useState<
    DashboardChartData[]
  >([]);
  const [profitChartData, setProfitChartData] = useState<DashboardChartData[]>(
    []
  );
  const [amountChartData, setAmountChartData] = useState<DashboardChartData[]>(
    []
  );

  const skip = Boolean(!startDate || !endDate);

  const { data } = useQuery<GetDashboardStats, GetDashboardStatsVariables>(
    DASHBOARD_STATS,
    {
      variables: {
        input: {
          from: startDate,
          to: endDate,
          storeId,
        },
      },
      // "skip ? 'cache-only' : 'cache-and-network'" is the hotfix for issue
      // that request has been sent even if "skip" is "true"
      // https://github.com/apollographql/apollo-client/issues/6190
      fetchPolicy: skip ? 'cache-only' : 'cache-and-network',
      skip,
    }
  );

  const dashboardStats: GetDashboardStats_getDashboardStats | undefined =
    data?.getDashboardStats;

  const totalRevenue: string = formatCurrencyString(
    dashboardStats?.revenueTotal || 0
  );

  const totalProfit: string = formatCurrencyString(
    dashboardStats?.profitTotal || 0
  );

  const totalAmount: string = formatCurrencyString(
    dashboardStats?.amountTotal || 0,
    '',
    0
  );

  const mostPopularData: GetDashboardStats_getDashboardStats_popularItems[] =
    dashboardStats?.popularItems
      ?.slice()
      ?.sort((a, b) =>
        a?.revenue && b?.revenue
          ? Number(a.revenue) < Number(b.revenue)
            ? 1
            : Number(a.revenue) > Number(b.revenue)
            ? -1
            : 0
          : 0
      ) || [];

  const onDateRangeChange = (start: Moment, end: Moment) => {
    setStartDate(start);
    setEndDate(end);
  };

  const setAllData = (startDate: Moment, endDate: Moment): void => {
    const getUniqueDates = (stats: StatsDataJson): string[] => {
      const dates = new Set<string>();
      const toDate = (date: any) => new Date(date);

      if (stats) {
        stats?.ama_request?.forEach((i: StatsDataItem) => dates.add(i.date));
        stats?.experience_request?.forEach((i: StatsDataItem) =>
          dates.add(i.date)
        );
        stats?.memorabilia_order?.forEach((i: StatsDataItem) =>
          dates.add(i.date)
        );
        stats?.merch_order?.forEach((i: StatsDataItem) => dates.add(i.date));
        stats?.product_order?.forEach((i: StatsDataItem) => dates.add(i.date));
        stats?.stream_order?.forEach((i: StatsDataItem) => dates.add(i.date));
        stats?.stream_tip?.forEach((i: StatsDataItem) => dates.add(i.date));
        stats?.offer?.forEach((i: StatsDataItem) => dates.add(i.date));
        stats?.event_marketing?.forEach((i: StatsDataItem) =>
          dates.add(i.date)
        );
        dates.add(startDate.format(format));
        dates.add(endDate.format(format));
      }

      return Array.from(dates).sort(
        (a, b) =>
          toDate(a)[Symbol.toPrimitive]('number') -
          toDate(b)[Symbol.toPrimitive]('number')
      );
    };

    const getStatValue = (
      stats: StatsDataJson,
      field:
        | 'ama_request'
        | 'experience_request'
        | 'memorabilia_order'
        | 'merch_order'
        | 'product_order'
        | 'stream_order'
        | 'stream_tip'
        | 'offer'
        | 'event_marketing',
      date: string
    ): number => {
      let value = 0;
      if (stats) {
        const statItem: any = stats?.[field]?.find((i: any) => i.date === date);
        value = statItem?.total || statItem?.sum || 0;
      }
      return value;
    };

    const getData = (
      field: 'amountStats' | 'revenueStats' | 'profitStats'
    ): DashboardChartData[] => {
      const res: GraphItemData[] = [];
      const stats = dashboardStats?.[field];

      if (stats) {
        const uniqDates = getUniqueDates(stats);

        uniqDates.forEach((date) => {
          res.push({
            date: date,
            experience: getStatValue(stats, 'experience_request', date),
            memorabilia: getStatValue(stats, 'memorabilia_order', date),
            merch: getStatValue(stats, 'merch_order', date),
            products: getStatValue(stats, 'product_order', date),
            streams: getStatValue(stats, 'stream_order', date),
            ama: getStatValue(stats, 'ama_request', date),
            tips: getStatValue(stats, 'stream_tip', date),
            offers: getStatValue(stats, 'offer', date),
            eventMarketing: getStatValue(stats, 'event_marketing', date),
          });
        });
      }

      return res?.map((i) => ({
        ...i,
        dayNumber: moment(i.date).format('D'),
      }));
    };

    if (startDate && endDate) {
      setRevenueChartData(getData('revenueStats'));
      setProfitChartData(getData('profitStats'));
      setAmountChartData(getData('amountStats'));
    }
  };

  const memoizedSetAllData = useCallback(setAllData, [dashboardStats]);

  useEffect(() => {
    memoizedSetAllData(startDate, endDate);
  }, [dashboardStats, startDate, endDate, memoizedSetAllData]);

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      width: 80,
      render: function TypeCell(type: string) {
        return <span className={styles.dashboardTableTypeCol}>{type}</span>;
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      width: 100,
    },
    {
      title: 'Amount',
      dataIndex: 'count',
      width: 40,
    },
    {
      title: 'Total revenue',
      dataIndex: 'revenue',
      width: 80,
      render: function RevenueCell(revenue: string) {
        return formatCurrencyString(revenue || 0, '$', 2);
      },
    },
  ];

  if (!hasMounted) {
    return null;
  }

  const isProfitChart = currentChartType === ChartType.ProfitMargin;

  return (
    <div className={styles.dashboardWrapper}>
      <DashboardHeader
        startDate={startDate}
        endDate={endDate}
        onDateRangeChange={onDateRangeChange}
        onChangeChartType={changeChartType}
        currentChartType={currentChartType}
      />

      <div className={styles.dashboardBody}>
        <DashboardSection
          title={isProfitChart ? 'Margin profit' : 'Total Revenue'}
          value={isProfitChart ? totalProfit : totalRevenue}
        >
          <DashboardChart
            data={isProfitChart ? profitChartData : revenueChartData}
            currency="$"
          />
        </DashboardSection>

        <DashboardSection title="Total Amount" value={totalAmount}>
          <DashboardChart data={amountChartData} />
        </DashboardSection>

        <DashboardSection title="Most Popular">
          <Table<GetDashboardStats_getDashboardStats_popularItems>
            columns={columns}
            data={mostPopularData}
            scroll={{ x: 400 }}
            scrollToTopOnPagination={false}
            defaultPageSize={5}
            rowKey={(record) =>
              `${record.type}_${record.title}_${record.count}_${record.revenue}`
            }
          />
        </DashboardSection>

        <DashboardSection title="Promo Codes">
          <DashboardPromoCodes
            startDate={startDate}
            endDate={endDate}
            storeId={storeId}
          />
        </DashboardSection>
      </div>
    </div>
  );
};

export default Dashboard;
