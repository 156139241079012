import { gql } from '@apollo/client';

export const GET_ALL_AREAS_OF_INFLUENCE = gql`
  query GetAllAreasOfInfluence($input: AreaOfInfluenceFilterInput!) {
    getAllAreasOfInfluence(input: $input) {
      entities {
        id
        name
        storeCount
      }
      limit
      offset
      total
    }
  }
`;
