import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Upload, Space, Modal, Image, Pagination } from 'antd';
import {
  UploadOutlined,
  CheckOutlined,
  DeleteOutlined,
  EyeOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import cn from 'classnames';
//Api
import {
  ADMIN_CREATE_GALLERY_IMAGE,
  ADMIN_DELETE_GALLERY_IMAGE,
} from 'api/homePageSetup/mutations';
import { GET_ADMIN_GALLERY_IMAGES } from 'api/homePageSetup/querries';
// Types
import { UploadRequestOption } from 'rc-upload/lib/interface';
import {
  AdminGalleryImages,
  AdminGalleryImagesVariables,
} from 'api/homePageSetup/types/AdminGalleryImages';
// Ui
import { errorNotification } from 'ui/Notification';
import './styles.scss';

const DEFAULT_NUMBER_ITEMS_PER_PAGE = 10;
const DEFAULT_CURRENT_PAGE = 1;

type UploadImageModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  title: string;
  onImageChange: (url: string, id: string) => void;
  acceptFileType?: string;
};

function beforeUpload(file: File): boolean {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    errorNotification('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    errorNotification('Image should be smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const UploadImageModal = ({
  isVisible,
  onCancel,
  title,
  onImageChange,
  acceptFileType = '.jpeg,.jpg,.png',
}: UploadImageModalProps): JSX.Element => {
  const [rowIndex, setRowIndex] = useState<number>(-1);
  const [activeImageUrl, setActiveImageUrl] = useState<string>('');
  const [activeImageId, setActiveImageId] = useState<string>('');
  const [previewImage, setPreviewImage] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const getAdminGetGalleryImagesInput: any = () => {
    const input: any = {
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    return { input };
  };

  const { data } = useQuery<AdminGalleryImages, AdminGalleryImagesVariables>(
    GET_ADMIN_GALLERY_IMAGES,
    {
      variables: {
        ...getAdminGetGalleryImagesInput(),
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const [adminCreateGalleryImage, { loading }] =
    useMutation<AdminGalleryImages>(ADMIN_CREATE_GALLERY_IMAGE);

  const [adminDeleteGalleryImage] = useMutation<AdminGalleryImages>(
    ADMIN_DELETE_GALLERY_IMAGE
  );

  const customRequest = async ({
    data,
    file,
    onSuccess,
  }: UploadRequestOption) => {
    try {
      await adminCreateGalleryImage({
        variables: {
          input: {
            file,
          },
        },
        refetchQueries: [
          {
            query: GET_ADMIN_GALLERY_IMAGES,
            variables: {
              ...getAdminGetGalleryImagesInput(),
            },
          },
        ],
      });
    } catch (error) {
      errorNotification((error as Error)?.message);
      console.error(error);
    }

    if (onSuccess) {
      onSuccess(data, file as any);
    }
  };

  const handleOk = () => {
    onImageChange(activeImageUrl, activeImageId);
    onCancel();
  };

  const handleDeleteImage = async (id: string) => {
    try {
      await adminDeleteGalleryImage({
        variables: {
          input: {
            id,
          },
        },
        refetchQueries: [
          {
            query: GET_ADMIN_GALLERY_IMAGES,
            variables: {
              ...getAdminGetGalleryImagesInput(),
            },
          },
        ],
      });
    } catch (error) {
      errorNotification((error as Error)?.message);
      console.error(error);
    }
  };

  const mouseIn = (id: number) => {
    setRowIndex(id);
  };

  const mouseOut = () => {
    setRowIndex(-1);
  };

  const handleCancelPreview = (): void => {
    setPreviewImage('');
  };

  const handleShowImagePreview = async (url: string) => {
    setPreviewImage(url);
  };

  const handleChangePage = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    setPageSize(pageSize || 0);
  };

  const handleSetActiveImage = (url: string, id: string) => {
    setActiveImageUrl(url);
    setActiveImageId(id);
  };

  return (
    <>
      <Modal
        visible={isVisible}
        width={610}
        onOk={handleOk}
        onCancel={onCancel}
        title={title}
        okButtonProps={{ disabled: !activeImageUrl }}
      >
        <Space style={{ width: '100%', marginBottom: 14 }} direction="vertical">
          <Upload
            showUploadList={false}
            beforeUpload={beforeUpload}
            customRequest={customRequest}
            accept={acceptFileType}
            multiple
          >
            <Button
              icon={loading ? <LoadingOutlined /> : <UploadOutlined />}
              disabled={loading}
            >
              Upload
            </Button>
          </Upload>
        </Space>

        <div className="upload-image-modal-image-wrapper">
          {data?.adminGalleryImages.entities.map((item, index) => (
            <div
              className={cn('upload-image-modal-image-block', {
                'upload-image-modal-image-block-active':
                  activeImageUrl === item.url,
              })}
              key={item.id}
              onMouseEnter={() => mouseIn(index)}
              onMouseLeave={mouseOut}
            >
              <Image
                width={102}
                height={102}
                src={item.url}
                preview={false}
                style={{ objectFit: 'cover' }}
                alt={item.url}
              />

              {rowIndex === index && (
                <div className="upload-image-modal-action-block-wrapper">
                  <div className="upload-image-modal-action-block">
                    <CheckOutlined
                      style={{
                        fontSize: '16px',
                        color: '#fff',
                        marginRight: 4,
                      }}
                      onClick={() => handleSetActiveImage(item.url, item.id)}
                    />

                    <EyeOutlined
                      style={{
                        fontSize: '16px',
                        color: '#fff',
                        marginRight: 4,
                      }}
                      onClick={() => handleShowImagePreview(item.url)}
                    />

                    <DeleteOutlined
                      style={{ fontSize: '16px', color: '#fff' }}
                      onClick={() => handleDeleteImage(item.id)}
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {data &&
          data?.adminGalleryImages.total > DEFAULT_NUMBER_ITEMS_PER_PAGE && (
            <Pagination
              onChange={handleChangePage}
              defaultCurrent={DEFAULT_CURRENT_PAGE}
              total={data?.adminGalleryImages.total}
              current={currentPage}
            />
          )}
      </Modal>

      <Modal
        visible={!!previewImage.length}
        footer={null}
        onCancel={handleCancelPreview}
        title="Image Preview"
        width="90vw"
      >
        <img alt="Banner" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export default UploadImageModal;
