import { gql } from '@apollo/client';

export const VideoEditorFields = gql`
  fragment VideoEditorFields on VideoEditor {
    createdAt
    email
    firstName
    id
    lastName
    phoneNumber
    status
    videoEditRequestCnt
    videoEditorDetails {
      id
      isIncludedInAutoAssignment
    }
    videoEditorRate
    videoEditorStatus
  }
`;
