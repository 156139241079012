import { gql } from '@apollo/client';

export const MerchRequestFields = gql`
  fragment MerchRequestFields on MerchOrder {
    id
    merchOrderItems {
      id
    }
    status
    createdAt
    stripeIntentID
    stripeIntentClientSecret
    printfulOrderId
    addressLine1
    addressLine2
    zipCode
    name
    countryCode
    country
    city
    stateCode
    state
    subtotal
    shipping
    total
    paidAt
  }
`;

export const MerchProductBaseFields = gql`
  fragment MerchProductBaseFields on MerchProductList {
    entities {
      id
      status
      slug
      title
      minPrice
      mainImageUrl
      type
      genders
      merchProductVariants {
        id
        size
        color
        colorCode
        colorCode2
        price
        customImages {
          id
          customMerchImageURL
          imageFileKey
          isMainImage
        }
      }
      productImageURL
      requestedProfit
      createdBy {
        role
        storeDetails {
          id
        }
      }
      isCreatedByAdmin
      autoSocialMediaPackStatus
      autoSocialPackImages {
        id
        status
        url
      }
      store {
        slug
      }
    }
  }
`;
