import React, { useState } from 'react';
import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button';
// Components
import AssignDesignerModal from './AssignDesignerModal';

type AssignDesignerProps = {
  designRequestId?: string;
  buttonTitle?: string;
  buttonType?: ButtonType;
};

const AssignDesigner: React.FC<AssignDesignerProps> = ({
  designRequestId,
  buttonTitle = 'Assign',
  buttonType = 'primary',
}) => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const handleShowModal = () => {
    setModalVisible(true);
  };

  const handleSetModalVisibility = (isVisible: boolean) => {
    setModalVisible(isVisible);
  };

  return (
    <>
      <Button type={buttonType} onClick={handleShowModal}>
        {buttonTitle}
      </Button>

      <AssignDesignerModal
        designRequestId={designRequestId}
        isModalVisible={isModalVisible}
        setModalVisible={handleSetModalVisibility}
      />
    </>
  );
};

export default AssignDesigner;
