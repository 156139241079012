import { gql } from '@apollo/client';
// Fragments

export const CREATE_BULK_UPLOAD_MUTATION = gql`
  mutation createBulkUploadV2($input: CreateBulkUploadInput!) {
    createBulkUploadV2(input: $input) {
      athleteName
      title
      mediaUrl
      description
      originalMediaUrl
      twitter
      instagram
      facebook
    }
  }
`;
