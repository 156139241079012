import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Spin, Image, Space } from 'antd';
import { useQuery } from '@apollo/client';
// Constants
import { FALLBACK_IMAGE } from 'constants/global';
// Api
import { DESIGN_REQUESTS } from 'api/designLab/queries';
// Types
import {
  DesignRequests,
  DesignRequestsVariables,
} from 'api/designLab/types/DesignRequests';
import { DesignRequestType, UserRole } from 'api/graphql-global-types';
// Hooks
import { useAppContext } from 'hooks';
// Helpers
import { getDesignRequestsFormattedData } from 'helpers/designRequests';
// Components
import DetailsForm from 'components/common/ManageDesigns/components/DLConstructor/components/DetailsForm/DetailsForm';
import DesignRequestsActions from 'components/common/DesignRequestsTable/components/DesignRequestsActions/DesignRequestsActions';
// Styles
import styles from './DesignRequestDetails.module.scss';

const { Title, Paragraph } = Typography;

const DesignRequestDetailsComponent: React.FC = () => {
  const { requestId } = useParams<{ requestId: string | undefined }>();
  const { authUser } = useAppContext();
  const isDesigner = authUser?.role === UserRole.Designer;

  const { data, loading } = useQuery<DesignRequests, DesignRequestsVariables>(
    DESIGN_REQUESTS,
    {
      variables: {
        input: {
          designRequestId: requestId,
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const designDetails = data?.designRequests?.entities[0];
  const designCreationDetails = designDetails?.designCreationDetails;

  const isMerchCreationWithLogoDesignRequest =
    designDetails?.type === DesignRequestType.MerchCreationWithLogo;

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <>
      <Title level={2}>General</Title>

      {!isDesigner && (
        <Space direction="vertical" align="end">
          {designDetails && (
            <DesignRequestsActions
              request={getDesignRequestsFormattedData(data)[0]}
              store={designDetails?.store}
            />
          )}
        </Space>
      )}

      {designDetails?.type === DesignRequestType.LogoAudit ? (
        <>
          <Space>
            <Paragraph strong>Design request id:</Paragraph>
            <Paragraph>{designDetails?.id || ''}</Paragraph>
          </Space>

          <Space>
            <Paragraph strong>Status:</Paragraph>
            <Paragraph>{designDetails?.status || ''}</Paragraph>
          </Space>

          {designDetails?.designer && (
            <>
              <Space>
                <Paragraph strong>Designer email:</Paragraph>
                <Paragraph>{designDetails?.designer?.email || ''}</Paragraph>
              </Space>

              <Space>
                <Paragraph strong>Designer:</Paragraph>
                <Paragraph>
                  {`${designDetails?.designer?.firstName}  ${designDetails?.designer?.lastName}` ||
                    ''}
                </Paragraph>
              </Space>
            </>
          )}
        </>
      ) : (
        <>
          <Space>
            <Paragraph strong>Design request id:</Paragraph>
            <Paragraph>{designDetails?.id || ''}</Paragraph>
          </Space>

          <Space>
            <Paragraph strong>Status:</Paragraph>
            <Paragraph>{designDetails?.status || ''}</Paragraph>
          </Space>

          {designDetails?.designer && (
            <>
              <Space>
                <Paragraph strong>Designer email:</Paragraph>
                <Paragraph>{designDetails?.designer?.email || ''}</Paragraph>
              </Space>

              <Space>
                <Paragraph strong>Designer:</Paragraph>
                <Paragraph>
                  {`${designDetails?.designer?.firstName}  ${designDetails?.designer?.lastName}` ||
                    ''}
                </Paragraph>
              </Space>
            </>
          )}
          {!isMerchCreationWithLogoDesignRequest && (
            <Space>
              <Paragraph strong>Design name:</Paragraph>
              <Paragraph>{designCreationDetails?.designName}</Paragraph>
            </Space>
          )}
          <Space>
            <Paragraph strong>Design additional information:</Paragraph>
            <Paragraph>
              {designCreationDetails?.designExtraInfo || ''}
            </Paragraph>
          </Space>
          <Space>
            <Paragraph strong>Design social media link:</Paragraph>
            <Paragraph>{designCreationDetails?.socialMediaURL || ''}</Paragraph>
          </Space>

          <Title level={2}>Design Types:</Title>

          <div className={styles.itemsWrapper}>
            {designCreationDetails?.designTypes.map(
              ({ id, description, name, posterImage }) => (
                <div key={id}>
                  <Paragraph strong>{name}</Paragraph>
                  <Paragraph>{description}</Paragraph>
                  <Image
                    alt={name}
                    width={200}
                    src={posterImage || ''}
                    fallback={FALLBACK_IMAGE}
                  />
                </div>
              )
            )}
          </div>

          <>
            <Title level={2}>Design Samples:</Title>
            <div className={styles.itemsWrapper}>
              {designCreationDetails?.designSamples.map(
                ({ id, posterImage, name }) => (
                  <div key={id}>
                    <Image
                      width={200}
                      src={posterImage || ''}
                      fallback={FALLBACK_IMAGE}
                      alt={name || ''}
                    />
                  </div>
                )
              )}
            </div>
          </>
          {!isMerchCreationWithLogoDesignRequest && (
            <>
              <Title level={2}>Design colors:</Title>
              <div className={styles.itemsWrapper}>
                {designCreationDetails?.designColorPalettes.map(
                  ({ id, name, posterImage }) => (
                    <div key={id}>
                      <Paragraph strong>{name}</Paragraph>
                      <Image
                        width={200}
                        src={posterImage || ''}
                        fallback={FALLBACK_IMAGE}
                        alt={name}
                      />
                    </div>
                  )
                )}
              </div>

              {designCreationDetails?.designCustomColor && (
                <Paragraph>{`Custom color: ${designCreationDetails.designCustomColor}`}</Paragraph>
              )}

              <Title level={2}>Example images:</Title>

              <div className={styles.itemsWrapper}>
                {designCreationDetails?.storeExampleImages?.map((image) => (
                  <div key={image.key}>
                    <Image
                      width={200}
                      src={image.url || ''}
                      fallback={FALLBACK_IMAGE}
                    />
                  </div>
                ))}
              </div>

              <Title level={2}>{`Brand's style options:`}</Title>

              <DetailsForm
                onChange={() => {
                  return;
                }}
                selectedValues={
                  designCreationDetails?.designStyleSettings || undefined
                }
              />
            </>
          )}

          {isMerchCreationWithLogoDesignRequest && (
            <>
              <Title level={2}>Logo images:</Title>
              <div className={styles.itemsWrapper}>
                {designCreationDetails?.storeLogos?.map((image) => (
                  <div key={image.key}>
                    <Image
                      width={200}
                      src={image.url || ''}
                      fallback={FALLBACK_IMAGE}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default DesignRequestDetailsComponent;
