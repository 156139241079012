import React, { useState, useEffect } from 'react';
import { Card, Tabs } from 'antd';
// Types
import { StoryOption } from 'pages/HomePageManagement';
// Components
import SetupStoryItem from './components/SetupStoryItem';

const { TabPane } = Tabs;

export type SetupStoriesProps = {
  selectedItems: StoryOption[];
  onInputChange: React.Dispatch<React.SetStateAction<StoryOption[]>>;
};

const SetupStories: React.FC<SetupStoriesProps> = ({
  selectedItems,
  onInputChange,
}) => {
  const [activeKey, setActiveKey] = useState<string>('0');
  const [numberOfTabs, setNumberOfTabs] = useState<number>(0);
  const [kludgeLoading, setKludgeLoading] = useState<boolean>(false);

  useEffect(() => {
    setNumberOfTabs(selectedItems.length);
    setKludgeLoading(true);

    setTimeout(() => {
      setKludgeLoading(false);
    }, 1);
  }, [selectedItems.length]);

  const handleTabChange = (activeKey: string) => {
    setActiveKey(activeKey);
  };

  const onEdit = (
    targetKey:
      | string
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>,
    action: 'add' | 'remove'
  ) => {
    if (action === 'remove') {
      remove(targetKey);
      setNumberOfTabs(numberOfTabs - 1);
    } else if (numberOfTabs < 3) {
      add();
      setNumberOfTabs(numberOfTabs + 1);
    }
  };

  const add = () => {
    const newStories = [...selectedItems];
    newStories.push({
      id: '',
      image: null,
      imageURL: '',
      title: '',
      description: '',
      link: '',
      publishedDate: null,
      store: {
        label: '',
        value: '',
      },
    });
    setActiveKey(String(selectedItems.length));
    onInputChange(newStories);
  };

  const remove = (
    targetKey:
      | string
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
  ) => {
    const newStores = selectedItems.filter(
      (_, index) => index !== Number(targetKey)
    );
    let newActiveKey = activeKey;

    if (newActiveKey === targetKey) {
      if (Number(targetKey) !== 0) {
        newActiveKey = String(Number(activeKey) - 1);
      } else {
        newActiveKey = '0';
      }
    } else if (
      newActiveKey > targetKey &&
      newActiveKey === String(newStores.length)
    ) {
      newActiveKey = String(newStores.length - 1);
    } else if (
      newActiveKey > targetKey &&
      newActiveKey < String(newStores.length)
    ) {
      newActiveKey = String(Number(activeKey) - 1);
    }

    setActiveKey(String(newActiveKey));
    onInputChange(newStores);
  };

  const handleStoryChange = (index: number, data: Partial<StoryOption>) => {
    const newStories = [...selectedItems];

    newStories[index] = { ...selectedItems[index], ...data };
    onInputChange(newStories);
  };

  return (
    <Card title="Setup Stories" style={{ width: '100%' }}>
      <Tabs
        type="editable-card"
        onChange={handleTabChange}
        onEdit={onEdit}
        activeKey={activeKey}
      >
        {selectedItems.map((item, index) => (
          <TabPane
            tab={index === 0 ? 'Main story' : 'Featured story'}
            key={index}
            closable
          >
            {kludgeLoading ? (
              <p>Update...</p>
            ) : (
              <SetupStoryItem
                index={index}
                onStoryChange={handleStoryChange}
                item={item}
              />
            )}
          </TabPane>
        ))}
      </Tabs>
    </Card>
  );
};

export default SetupStories;
