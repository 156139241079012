import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Space } from 'antd';
// Api
import { GET_ADMINS } from 'api/admin/queries';
// Types
import {
  GetAdmins,
  GetAdminsVariables,
  GetAdmins_admins_entities,
} from 'api/admin/types/GetAdmins';
import { TableFilter } from 'ui/Table';
import { AdminFilterInput, StoreStatus } from 'api/graphql-global-types';
// UI
import Table from 'ui/Table';
// Components
import CreateEditAdminModal from 'pages/Admins/components/CreateEditAdminModal';
import Actions from 'pages/Admins/components/Actions';

const Admins = (): JSX.Element => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [createEditAdminModalVisible, setCreateEditAdminModalVisible] =
    useState<boolean>(false);

  const getAdminsInput = (): GetAdminsVariables => {
    const input: AdminFilterInput = {
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      status: [StoreStatus.Active, StoreStatus.Inactive, StoreStatus.Pending],
    };

    searchFilters.forEach(({ key, value }) => {
      input[key as keyof AdminFilterInput] = value as any;
    });

    return { input };
  };

  const { data } = useQuery<GetAdmins, GetAdminsVariables>(GET_ADMINS, {
    variables: { ...getAdminsInput() },
    fetchPolicy: 'cache-and-network',
  });

  const handleCreateEditAdminModalToggle = () => {
    setCreateEditAdminModalVisible((prev) => !prev);
  };

  const columns = [
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName',
      fixed: 'left' as const,
      align: 'left' as const,
      width: 100,
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName',
      align: 'left' as const,
      width: 100,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'left' as const,
      width: 100,
      withSearch: true,
    },
    {
      title: 'Phone number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'left' as const,
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'left' as const,
      width: 100,
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 60,
      render: function ActionsWrap(
        id: string,
        record: GetAdmins_admins_entities
      ) {
        return <Actions admin={record} key={id} />;
      },
    },
  ];

  return (
    <>
      <div>
        <Space direction="vertical" size="middle" align="end">
          <Button type="primary" onClick={handleCreateEditAdminModalToggle}>
            Add new admin
          </Button>
        </Space>

        <Table<GetAdmins_admins_entities>
          columns={columns}
          data={data?.admins?.entities}
          scroll={{ x: 300 }}
          total={data?.admins.total}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
        />
      </div>

      <CreateEditAdminModal
        isVisible={createEditAdminModalVisible}
        onClose={handleCreateEditAdminModalToggle}
      />
    </>
  );
};

export default Admins;
