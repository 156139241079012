import React from 'react';
import { useMutation } from '@apollo/client';
import { Button, Dropdown, Menu, Modal, Space } from 'antd';
import { EllipsisOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
// Helpers
import { getEnvLink } from 'helpers/getEnvLink';
// Api
import env from 'api/env';
import {
  CANCEL_INTERVIEW_ADMIN,
  RESET_INTERVIEW_ADMIN,
} from 'api/interview/mutations';
// Types
import {
  CancelInterviewAdmin,
  CancelInterviewAdminVariables,
} from 'api/interview/types/CancelInterviewAdmin';
import {
  ResetInterviewAdmin,
  ResetInterviewAdminVariables,
} from 'api/interview/types/ResetInterviewAdmin';
import {
  InterviewsFormattedData,
  refetchQueries,
} from '../../InterviewsTable/InterviewsTable';
import { InterviewStatus } from 'api/graphql-global-types';
// Components
import AssignInterviewer from '../AssignInterviewer/AssignInterviewer';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';

const { confirm } = Modal;

type InterviewsActionsActionsProps = {
  interview: InterviewsFormattedData;
  showScheduleModal: (
    record: InterviewsFormattedData | null,
    isReschedule?: boolean
  ) => void;
  showUpdateInfoModal: (record: InterviewsFormattedData | null) => void;
  // showCompleteModal: (record: InterviewsFormattedData | null) => void;
};

const InterviewsActions = ({
  interview,
  showScheduleModal,
  showUpdateInfoModal,
}: // showCompleteModal,
InterviewsActionsActionsProps): JSX.Element | null => {
  const [cancelInterviewAdmin] = useMutation<
    CancelInterviewAdmin,
    CancelInterviewAdminVariables
  >(CANCEL_INTERVIEW_ADMIN, {
    refetchQueries,
  });

  const [resetInterviewAdmin] = useMutation<
    ResetInterviewAdmin,
    ResetInterviewAdminVariables
  >(RESET_INTERVIEW_ADMIN, {
    refetchQueries,
  });

  const handleStartStream = () => {
    window.open(
      `${getEnvLink(env.REACT_APP_ENV)}/${
        interview?.interviewer?.slug
      }/start-stream/${interview?.stream?.slug}`,
      '_blank'
    );
  };

  const handleResetInterviewAdmin = () => {
    confirm({
      title: 'Are you sure you want to reset interview?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await resetInterviewAdmin({
            variables: { input: { id: interview.id } },
          });
          successNotification('The interview has been reset successfully');
        } catch (error) {
          errorNotification(
            (error as Error)?.message || 'Something went wrong'
          );
        }
      },
    });
  };

  const handleInterviewCancelAdmin = () => {
    confirm({
      title: 'Are you sure you want to cancel interview?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await cancelInterviewAdmin({
            variables: { input: { id: interview.id } },
          });
          successNotification('The interview has been canceled successfully');
        } catch (error) {
          errorNotification(
            (error as Error)?.message || 'Something went wrong'
          );
        }
      },
    });
  };

  const getAdminMenuItems = (): JSX.Element | null => {
    switch (interview.status) {
      case InterviewStatus.Canceled:
        return (
          <Menu.Item key={interview.id}>
            <Space direction="vertical" size="middle" align="center">
              <Button
                type="primary"
                onClick={() => showUpdateInfoModal(interview)}
              >
                Update info
              </Button>
            </Space>
          </Menu.Item>
        );
      case InterviewStatus.Completed:
        return (
          <Menu.Item key={interview.id}>
            {/* <Button type="primary" onClick={() => showCompleteModal(interview)}>
              Complete
            </Button> */}
            <Button
              type="primary"
              onClick={() => showUpdateInfoModal(interview)}
            >
              Update info
            </Button>
          </Menu.Item>
        );
      case InterviewStatus.Scheduled:
        return (
          <Menu.Item key={interview.id}>
            <Space direction="vertical" size="middle" align="center">
              <AssignInterviewer interviewId={interview.id} />
              {/* <Button
                type="primary"
                onClick={() => showCompleteModal(interview)}
              >
                Complete
              </Button> */}

              {interview?.stream?.slug && (
                <>
                  <Button onClick={handleStartStream} danger>
                    Start interview
                  </Button>
                </>
              )}
              <Button type="primary" onClick={handleResetInterviewAdmin}>
                Reset
              </Button>
              <Button
                type="primary"
                danger
                onClick={handleInterviewCancelAdmin}
              >
                Cancel
              </Button>

              <Button
                type="primary"
                onClick={() => showUpdateInfoModal(interview)}
              >
                Update info
              </Button>

              <Button
                type="primary"
                onClick={() => showScheduleModal(interview, true)}
              >
                Reschedule stream
              </Button>
            </Space>
          </Menu.Item>
        );

      case InterviewStatus.Invited:
        return (
          <Menu.Item key={interview.id}>
            <Space direction="vertical" size="middle" align="center">
              <Button
                type="primary"
                onClick={() => showScheduleModal(interview)}
              >
                Schedule stream
              </Button>

              <Button
                type="primary"
                onClick={() => showUpdateInfoModal(interview)}
              >
                Update info
              </Button>
              {/* <Button
                type="primary"
                onClick={() => showCompleteModal(interview)}
              >
                Complete
              </Button> */}

              <Button type="primary" danger onClick={handleResetInterviewAdmin}>
                Reset
              </Button>

              <Button
                type="primary"
                danger
                onClick={handleInterviewCancelAdmin}
              >
                Cancel
              </Button>

              {/* <Button
                type="primary"
                onClick={() => showCompleteModal(interview)}
              >
                Complete
              </Button> */}

              <AssignInterviewer interviewId={interview.id} />
            </Space>
          </Menu.Item>
        );

      case InterviewStatus.InviteViewed:
        return (
          <Menu.Item key={interview.id}>
            <Space direction="vertical" size="middle" align="center">
              <Button
                type="primary"
                onClick={() => showScheduleModal(interview)}
              >
                Schedule stream
              </Button>

              <Button
                type="primary"
                onClick={() => showUpdateInfoModal(interview)}
              >
                Update info
              </Button>
              {/* <Button
                type="primary"
                onClick={() => showCompleteModal(interview)}
              >
                Complete
              </Button> */}

              <Button
                type="primary"
                danger
                onClick={handleInterviewCancelAdmin}
              >
                Cancel
              </Button>
              <AssignInterviewer interviewId={interview.id} />
            </Space>
          </Menu.Item>
        );
      case InterviewStatus.ScheduleAttempted:
        return (
          <Menu.Item key={interview.id}>
            <Space direction="vertical" size="middle" align="center">
              <Button
                type="primary"
                onClick={() => showScheduleModal(interview)}
              >
                Schedule stream
              </Button>

              <Button
                type="primary"
                onClick={() => showUpdateInfoModal(interview)}
              >
                Update info
              </Button>
              {/* <Button
                type="primary"
                onClick={() => showCompleteModal(interview)}
              >
                Complete
              </Button> */}

              <Button
                type="primary"
                danger
                onClick={handleInterviewCancelAdmin}
              >
                Cancel
              </Button>

              <AssignInterviewer interviewId={interview.id} />
            </Space>
          </Menu.Item>
        );

      default:
        return null;
    }
  };

  const menu = <Menu>{getAdminMenuItems()}</Menu>;

  return (
    <Dropdown trigger={['click']} placement="bottomCenter" overlay={menu}>
      <Button
        aria-label="show the available actions"
        icon={<EllipsisOutlined />}
      />
    </Dropdown>
  );
};

export default InterviewsActions;
