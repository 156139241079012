import React from 'react';
// Types
import { XAxisProps } from 'recharts';

import styles from './CustomXAxisTick.module.scss';

interface CustomXAxisTickPayload {
  value: string | number;
}

type CustomXAxisTickProps = XAxisProps & {
  payload?: CustomXAxisTickPayload;
};

export const CustomXAxisTick: React.FC<CustomXAxisTickProps> = ({
  x,
  y,
  payload,
}) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        width={16}
        textAnchor="end"
        fill="#333333"
        className={styles.chartCustomAxisTick}
      >
        {payload?.value}
      </text>
    </g>
  );
};
