import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Divider, Space, Spin, List, Typography, Button } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
// Api
import { GET_ADMIN_WATCH_STREAMS } from 'api/streams/queries';
// Types
import {
  GetAdminWatchStreams,
  GetAdminWatchStreamsVariables,
  GetAdminWatchStreams_adminStreams_entities_streamNotes,
  GetAdminWatchStreams_adminStreams_entities_streamRecords,
} from 'api/streams/types/GetAdminWatchStreams';
import { StreamStatus } from 'api/graphql-global-types';
// Constants
import { WATCH_STREAM } from 'constants/routes';
// Components
import StreamInfo from './components/StreamInfo/StreamInfo';
import StreamsPlaylist from './components/StreamsPlaylist/StreamsPlaylist';
import StreamClip from './components/StreamClip/StreamClip';
// Styles
import './styles.scss';

dayjs.extend(duration);

const { Title } = Typography;

const WatchStream: React.FC = () => {
  const history = useHistory();
  const [selectedNoteTime, setSelectedNoteTime] = useState<string | null>(null);
  const [noteSectionIndex, setNoteSectionIndex] = useState<number | null>(null);
  const [clipCreateLoading, setClipCreateLoading] = useState(false);
  const { streamId: streamIdFromParams } = useParams<{
    streamId: string;
  }>();

  const { data, loading, refetch } = useQuery<
    GetAdminWatchStreams,
    GetAdminWatchStreamsVariables
  >(GET_ADMIN_WATCH_STREAMS, {
    variables: { input: { id: streamIdFromParams } },
    fetchPolicy: 'cache-and-network',
  });

  const stream = data?.adminStreams?.entities?.[0];

  const selectedNoteStreamRecord =
    noteSectionIndex != null
      ? data?.adminStreams?.entities?.[0].streamRecords?.[noteSectionIndex]
      : null;

  useEffect(() => {
    if (!streamIdFromParams || streamIdFromParams === 'undefined') {
      history.push(WATCH_STREAM);
    }
  }, [history, streamIdFromParams]);

  const notes = stream?.streamNotes?.reduce((prev, acc) => {
    prev[acc.streamStart] = (prev[acc.streamStart] || []).concat(acc);

    return prev;
  }, {} as Record<string, GetAdminWatchStreams_adminStreams_entities_streamNotes[]>);

  const records = stream?.streamRecords?.reduce((prev, acc) => {
    if (acc.clips && acc.clips.length) {
      prev[acc.id] = (prev[acc.id] || []).concat(acc);
    }

    return prev;
  }, {} as Record<string, GetAdminWatchStreams_adminStreams_entities_streamRecords[]>);

  const handleNoteClick = (sectionIndex: number, time: string) => {
    setSelectedNoteTime(time);
    setNoteSectionIndex(sectionIndex);
  };

  const handleNoteClear = () => {
    setSelectedNoteTime(null);
    setNoteSectionIndex(null);
  };

  if (loading && !stream) {
    return (
      <Spin spinning={loading}>
        <div className="spin" aria-label="loading" />
      </Spin>
    );
  }

  return (
    <Space className="watch-stream-wrapper" size="middle" direction="vertical">
      <div className="watch-stream-item">
        <StreamInfo stream={stream} />
      </div>

      {stream?.streamStatus === StreamStatus.Ended && (
        <div className="watch-stream-item">
          <StreamsPlaylist
            streamCoverImage={stream?.imageURL}
            records={stream?.streamRecords}
            onNewRecordCreate={refetch}
            noteTime={selectedNoteTime}
            noteStreamRecord={selectedNoteStreamRecord}
            clearNoteIndex={handleNoteClear}
            handleClipLoading={setClipCreateLoading}
          />
        </div>
      )}
      <Divider />
      {!!stream?.streamRecords?.length && (
        <Title level={3}>Clip Video Section</Title>
      )}
      {records &&
        Object.keys(records).map((key, index) => {
          return (
            <List
              key={key}
              header={
                <div>
                  <p>Session{index + 1}</p>
                  <div className="list-header">
                    <p>Title</p>
                    <p>Time</p>
                    <p>Status</p>
                    <p>Actions</p>
                  </div>
                </div>
              }
              bordered
              dataSource={records[key]}
              renderItem={(item) => <StreamClip streamRecord={item} />}
            />
          );
        })}
      <Divider />
      {!!stream?.streamNotes?.length && <Title level={3}>Notes</Title>}
      {notes &&
        Object.keys(notes).map((key, index) => {
          return (
            <List
              key={key}
              header={
                <div>
                  <p>Session{index + 1}</p>
                  <div className="list-header">
                    <p>Note Content</p>
                    <p>Time</p>
                  </div>
                </div>
              }
              bordered
              dataSource={notes[key]}
              renderItem={(item) => (
                <List.Item className="note-wrapper">
                  <p>{item.note}</p>
                  <Button
                    disabled={!!selectedNoteTime || clipCreateLoading}
                    className="note-time"
                    onClick={() =>
                      handleNoteClick(
                        index,
                        dayjs.duration(item.relativeTs || 0).format('HH:mm:ss')
                      )
                    }
                  >
                    {dayjs.duration(item.relativeTs || 0).format('HH:mm:ss')}
                  </Button>
                </List.Item>
              )}
            />
          );
        })}
    </Space>
  );
};

export default WatchStream;
