import React, { FC, useEffect, useRef, useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
// Types
import { ContactType } from 'api/graphql-global-types';
// Ui
import { Mention } from 'uiShared/Mentions/Mentions';
// Components
import UnregisteredMentionsSelect from '../UnregisteredMentionsSelect/UnregisteredMentionsSelect';
// Styles
import styles from './OutsideMillionsAthletesSelect.module.scss';

interface OutsideMillionsSelectProps {
  mentions: Mention[];
  onChange: (values: Mention[]) => void;
}

export const OutsideMillionsAthletesSelect: FC<OutsideMillionsSelectProps> = ({
  mentions,
  onChange,
}) => {
  const [unregisteredAthletesMentions, setUnregisteredAthletesMentions] =
    useState<Mention[]>(mentions);
  const previousMentionsRef = useRef(unregisteredAthletesMentions);

  useEffect(() => {
    if (mentions && mentions.length > 0) {
      setUnregisteredAthletesMentions(mentions);
    }
  }, [mentions]);

  const handleUnregisteredAthleteChange = (mention: any, index: number) => {
    setUnregisteredAthletesMentions((prevMentions) => {
      const updatedMentions = [...prevMentions];
      updatedMentions[index] = mention;

      return updatedMentions;
    });
  };

  useEffect(() => {
    // Compare the previous and current mentions arrays
    if (
      JSON.stringify(previousMentionsRef.current) !==
      JSON.stringify(unregisteredAthletesMentions)
    ) {
      // Only trigger onChange if the values have actually changed
      onChange(unregisteredAthletesMentions);
      previousMentionsRef.current = unregisteredAthletesMentions; // Update the ref with the current mentions
    }
  }, [unregisteredAthletesMentions, onChange]);

  const addNewAthletesMention = () => {
    setUnregisteredAthletesMentions((prevMentions) => [
      ...prevMentions,
      {
        id: null,
        targetType: null,
        user: null,
        unregisteredContact: null,
      },
    ]);
  };

  const handleOtherAthleteMentionRemove = (index: number) => {
    setUnregisteredAthletesMentions((prevMentions) => {
      const updatedMentions = [...prevMentions];
      updatedMentions.splice(index, 1);
      return updatedMentions;
    });
  };

  return (
    <div className={styles.otherAthletes}>
      <label
        htmlFor="otherMentionsSelectedTypesSelect"
        className={styles.otherMentionsLabel}
      >{`Athletes outside of MILLION'S`}</label>

      {unregisteredAthletesMentions.length === 0 ? (
        <UnregisteredMentionsSelect
          mention={null}
          index={0}
          type={ContactType.ATHLETE}
          onChange={handleUnregisteredAthleteChange}
          handleMillionsMentionRemove={handleOtherAthleteMentionRemove}
        />
      ) : (
        unregisteredAthletesMentions.map((mention, index) => (
          <div key={index} className={styles.selectContainer}>
            <UnregisteredMentionsSelect
              mention={mention}
              index={index}
              type={ContactType.ATHLETE}
              onChange={handleUnregisteredAthleteChange}
              handleMillionsMentionRemove={handleOtherAthleteMentionRemove}
            />
          </div>
        ))
      )}

      {unregisteredAthletesMentions.length < 5 && (
        <div className={styles.addNewMentionContainer}>
          <Button
            onClick={addNewAthletesMention}
            color="white"
            className={styles.addNewMentionButton}
          >
            <PlusCircleOutlined className={styles.addMentionIcon} />
            <p className={styles.addNewMentionTitle}>Add new mention</p>
          </Button>
        </div>
      )}
    </div>
  );
};

export default OutsideMillionsAthletesSelect;
