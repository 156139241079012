import { gql } from '@apollo/client';

export const StreamListFields = gql`
  fragment StreamListFields on StreamList {
    entities {
      id
      description
      name
      storeId
      deletedAt
      hashtags {
        id
        name
      }
      mentions {
        id
        mentionType
        store {
          slug
          id
          storeDetails {
            storeName
            storeId
            avatarURL
          }
          firstName
          lastName
        }
        targetType
        unregisteredContact {
          email
          id
          name
          phoneNumber
          representativeName
          type
          url
        }
      }
      slug
      participants {
        id
        firstName
        lastName
        email
      }
      moderator {
        id
        firstName
        lastName
      }
      streamTips {
        id
        amount
        message
        customer {
          id
          firstName
          lastName
        }
      }
      store {
        id
        lastName
        firstName
        slug
      }
      sponsors {
        id
        name
        logoUrl
        promoMessage
      }
      price
      requestedPrice
      repeatsEveryEnum
      repeatingTitle
      isFree
      isHidden
      scheduleDate
      timeZone {
        tzCode
        offset
      }
      affiliateProducts {
        id
        imageKey
        imageUrl
        orderIndex
        price
        title
        url
      }
      streamStatus
      repeatingTitle
      repeatsEveryEnum
      imageURL
      numberOfOrders
      views {
        liveViewCount
        endedViewCount
      }
    }
    total
  }
`;

export const StreamFields = gql`
  fragment StreamFields on Stream {
    id
    description
    name
    storeId
    price
    requestedPrice
    repeatingTitle
    repeatsEveryEnum
    scheduleDate
    moderator {
      id
    }
    sponsors {
      id
      name
      logoUrl
      promoMessage
    }
    affiliateProducts {
      id
      imageKey
      imageUrl
      orderIndex
      price
      title
      url
    }
    timeZone {
      tzCode
      offset
    }
    streamStatus
    imageURL
  }
`;

export const StreamClipFields = gql`
  fragment StreamClipFields on StreamClip {
    endTs
    id
    startTs
    status
    title
    url
  }
`;

export const AdminStreamFields = gql`
  fragment AdminStreamFields on Stream {
    id
    description
    name
    storeId
    price
    requestedPrice
    repeatingTitle
    repeatsEveryEnum
    scheduleDate
    storeId
    streamStatus
    streamStatus
    imageURL
    slug
    isHidden
    moderator {
      id
    }
    sponsors {
      id
      name
      logoUrl
      promoMessage
    }
    affiliateProducts {
      id
      imageKey
      imageUrl
      orderIndex
      price
      title
      url
    }
    timeZone {
      tzCode
      offset
    }
  }
`;
