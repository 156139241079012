import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Rate, Typography, Button, Space } from 'antd';
// Api
import { GET_VIDEO_EDITORS } from 'api/videoLab/queries';
// Types
import { VideoEditors_videoEditors_entities } from 'api/videoLab/types/VideoEditors';
import {
  GetVideoEditors,
  GetVideoEditorsVariables,
} from 'api/videoLab/types/GetVideoEditors';
import { SortDirection, VideoEditorOrderBy } from 'api/graphql-global-types';
import { SortedInfo, TableFilter } from 'ui/Table';
// Components
import CreateEditVideoEditorModal from './components/CreateEditVideoEditorModal';
import Actions from './components/Actions';
import Requests from './components/Requests';
// UI
import Table from 'ui/Table';

export type VideoEditorsFormattedData = VideoEditors_videoEditors_entities & {
  videoEditorName: string;
};

const { Text } = Typography;

enum VideoEditorStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

const VideoEditorStatusTitle = {
  [VideoEditorStatus.Active]: 'Active',
  [VideoEditorStatus.Inactive]: 'Inactive',
};

export interface GetAllEditorsInput {
  direction?: SortDirection | null;
  limit: number;
  offset: number;
  orderBy: VideoEditorOrderBy;
  [key: string]: any;
}

const VideoEditorsPage: React.FC = () => {
  const [selectedVideoEditor, setSelectedVideoEditor] =
    useState<VideoEditors_videoEditors_entities | null>(null);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<VideoEditorOrderBy>>({
    order: SortDirection.DESC,
    key: VideoEditorOrderBy.createdAt,
  });
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [showRequestsModal, setShowRequestsModal] = useState<boolean>(false);
  const [isCreateEditorModalOpen, setIsCreateEditorModalOpen] =
    useState<boolean>(false);
  const getAllEditorsInput = (): { input: GetAllEditorsInput } => {
    const input: GetAllEditorsInput = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;
    });

    return { input };
  };

  const { data, loading } = useQuery<GetVideoEditors, GetVideoEditorsVariables>(
    GET_VIDEO_EDITORS,
    {
      variables: { ...getAllEditorsInput() },
      fetchPolicy: 'cache-and-network',
    }
  );

  const getName = (
    firstName: string | undefined | null,
    lastName: string | undefined | null,
    email: string | undefined | null
  ): string => {
    return firstName || lastName ? `${firstName} ${lastName}` : email || '';
  };

  const handleShowModal = (videoEditor: VideoEditors_videoEditors_entities) => {
    setSelectedVideoEditor(videoEditor);
    setShowRequestsModal(true);
  };

  const handleHideModal = () => {
    setShowRequestsModal(false);
    setSelectedVideoEditor(null);
  };

  const handleShowCreateModal = () => {
    setIsCreateEditorModalOpen(true);
  };

  const toggleVideoEditorModal = () => {
    setIsCreateEditorModalOpen(false);
  };

  const getFormattedData = (): VideoEditorsFormattedData[] => {
    return (
      data?.getVideoEditors?.entities?.map((i) => ({
        ...i,
        videoEditorName: getName(i?.firstName, i?.lastName, i?.email),
        isIncludedInAutoAssignment:
          i?.videoEditorDetails?.isIncludedInAutoAssignment ?? false,
      })) || []
    );
  };

  const columns = [
    {
      title: 'Editor name',
      dataIndex: VideoEditorOrderBy.videoEditorName,
      key: VideoEditorOrderBy.videoEditorName,
      fixed: 'left' as const,
      align: 'left' as const,
      width: 100,
      withSearch: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center' as const,
      width: 80,
    },
    {
      title: 'Requests',
      dataIndex: 'videoEditRequestCnt',
      key: 'videoEditRequestCnt',
      align: 'center' as const,
      width: 80,
      render: function RequestsWrap(
        videoEditRequestCnt: number,
        record: VideoEditors_videoEditors_entities
      ) {
        return (
          <Button
            type="link"
            onClick={() => handleShowModal(record)}
            title={`${record.firstName} ${record.lastName} video editor requests`}
            disabled={!videoEditRequestCnt}
          >
            {videoEditRequestCnt > 0 ? videoEditRequestCnt : 'No requests yet'}
          </Button>
        );
      },
    },
    {
      title: 'Auto Assign',
      dataIndex: 'isIncludedInAutoAssignment',
      key: 'autoAssign',
      align: 'center' as const,
      width: 120,
      render: function AutoAssignWrap(
        isIncludedInAutoAssignment: boolean | undefined
      ) {
        const displayValue = isIncludedInAutoAssignment ? 'Yes' : 'No';
        return <span>{displayValue}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: VideoEditorOrderBy.videoEditorStatus,
      key: VideoEditorOrderBy.videoEditorStatus,
      align: 'center' as const,
      width: 80,
      withCheckboxFilters: true,
      filters: [
        {
          text: VideoEditorStatusTitle[VideoEditorStatus.Active],
          value: VideoEditorStatus.Active,
        },
        {
          text: VideoEditorStatusTitle[VideoEditorStatus.Inactive],
          value: VideoEditorStatus.Inactive,
        },
      ],
      filterMultiple: true,
      render: function StatusWrap(videoEditorStatus: VideoEditorStatus) {
        return (
          <Text>
            {videoEditorStatus
              ? VideoEditorStatusTitle[videoEditorStatus] || videoEditorStatus
              : '-'}
          </Text>
        );
      },
    },
    {
      title: 'Rate',
      dataIndex: 'videoEditorRate',
      key: 'videoEditorRate',
      align: 'center' as const,
      width: 150,
      render: function RateWrap(videoEditorRate: number | null) {
        return (
          <Rate value={videoEditorRate || 0} disabled allowHalf count={5} />
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 70,
      render: function ActionsWrap(_: any, record: any) {
        return (
          <Actions
            videoEditor={record}
            getAllEditorsInput={getAllEditorsInput()}
          />
        );
      },
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <Space
        direction="vertical"
        size="large"
        align="end"
        style={{ width: '100%', marginBottom: '10px' }}
      >
        <Button type="primary" onClick={handleShowCreateModal}>
          Add new video editor
        </Button>
      </Space>

      <Table<VideoEditorsFormattedData, VideoEditorOrderBy>
        columns={columns}
        data={getFormattedData()}
        scroll={{ x: 1024 }}
        loading={loading}
        total={data?.getVideoEditors?.total}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />

      <CreateEditVideoEditorModal
        toggleVideoEditorModal={toggleVideoEditorModal}
        isCreateEditEditorModalOpen={isCreateEditorModalOpen}
      />

      {selectedVideoEditor && (
        <Requests
          show={showRequestsModal}
          onClose={handleHideModal}
          videoEditorId={selectedVideoEditor.id}
          videoEditorName={`${getName(
            selectedVideoEditor.firstName,
            selectedVideoEditor.lastName,
            selectedVideoEditor.email
          )}`}
        />
      )}
    </div>
  );
};

export default VideoEditorsPage;
