import React from 'react';
import { Space, Slider } from 'antd';
// Types
import { DLStateValues } from 'components/common/ManageDesigns/components/DLConstructor/DLConstructor';
// Styles
import styles from './DetailsForm.module.scss';

type DetailsFormProps = {
  onChange: (values: DLStateValues) => void;
  selectedValues: DLStateValues['details'];
};

type SliderOptions = {
  name: string;
  marks: string[];
  selectedValue?: number | null;
};

const DetailsForm = ({
  selectedValues,
  onChange,
}: DetailsFormProps): JSX.Element => {
  const sliders: SliderOptions[] = [
    {
      name: 'playfulSophisticated',
      marks: ['Playful', 'Sophisticated'],
      selectedValue: selectedValues?.playfulSophisticated,
    },
    {
      name: 'matureYouthful',
      marks: ['Mature', 'Youthful'],
      selectedValue: selectedValues?.matureYouthful,
    },
    {
      name: 'classicModern',
      marks: ['Classic', 'Modern'],
      selectedValue: selectedValues?.classicModern,
    },
    {
      name: 'feminineMasculine',
      marks: ['Feminine', 'Masculine'],
      selectedValue: selectedValues?.feminineMasculine,
    },
    {
      name: 'geometricOrganic',
      marks: ['Geometric', 'Organic'],
      selectedValue: selectedValues?.geometricOrganic,
    },
    {
      name: 'abstractLiteral',
      marks: ['Abstract', 'Literal'],
      selectedValue: selectedValues?.abstractLiteral,
    },
    {
      name: 'economicalLuxurious',
      marks: ['Economical', 'Luxurious'],
      selectedValue: selectedValues?.economicalLuxurious,
    },
  ];

  return (
    <Space
      className={styles.root}
      direction="vertical"
      align="center"
      size="large"
    >
      {sliders.map(({ name, marks, selectedValue }) => {
        const handleSliderChange = (value: number) => {
          onChange({
            details: {
              ...selectedValues,
              [name]: value,
            },
          });
        };

        return (
          <Slider
            key={name}
            min={1}
            max={5}
            step={1}
            marks={{
              1: marks[0],
              5: marks[1],
            }}
            tooltipVisible={false}
            value={selectedValue || 3}
            onChange={handleSliderChange}
            className={styles.slider}
          />
        );
      })}
    </Space>
  );
};

export default DetailsForm;
