import React from 'react';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { Space, Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// Api
import { GET_AUCTIONS } from 'api/memorabilia/queries';
import { CANCEL_BID } from 'api/memorabilia/mutations';
// Types
import { CancelBid, CancelBidVariables } from 'api/memorabilia/types/CancelBid';
import {
  GetAuctions,
  GetAuctionsVariables,
  GetAuctions_getAuctions_entities_bids,
} from 'api/memorabilia/types/GetAuctions';
import { AuctionStatus } from 'api/graphql-global-types';
// Constants
import { commonTimeFormat } from 'constants/global';
// Ui
import Table from 'ui/Table';
import { errorNotification, successNotification } from 'ui/Notification';

const { confirm } = Modal;

type AuctionDetailsProps = {
  auctionId: string;
};

const AuctionDetails = ({ auctionId }: AuctionDetailsProps): JSX.Element => {
  const { data: auctionsData, loading } = useQuery<
    GetAuctions,
    GetAuctionsVariables
  >(GET_AUCTIONS, {
    variables: {
      input: {
        auctionIds: [auctionId],
      },
    },
    skip: !auctionId,
    fetchPolicy: 'cache-and-network',
  });

  const auction = auctionsData?.getAuctions?.entities?.[0] || null;

  const allBids = auction?.bids || [];
  const sortedBids = [...allBids].sort(
    (bid1, bid2) => bid2.amount - bid1.amount
  );
  const isAuctionFinished = auction?.status === AuctionStatus.Finished;

  const checkIfWinningBid = (bidId: string) => {
    const activeBids = sortedBids.filter((bid) => !bid.cancelledAt);

    return activeBids?.[0]?.id === bidId;
  };

  const [cancelBid] = useMutation<CancelBid, CancelBidVariables>(CANCEL_BID);

  const showError = (message?: string) => {
    errorNotification(`Something went wrong.${message ? ` (${message})` : ''}`);
  };

  const handleCancelBid = (bidId: string) => {
    confirm({
      title: 'Are you sure you want to cancel this bid?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await cancelBid({
            variables: { input: { bidId } },
          });
          successNotification('The bid has been canceled successfully');
        } catch (err: any) {
          showError((err as Error)?.message);
        }
      },
    });
  };

  const columns = [
    {
      title: 'Customer',
      dataIndex: 'customer',

      align: 'center' as const,
      render: function Customer(
        text: any,
        record: GetAuctions_getAuctions_entities_bids
      ) {
        return (
          <Space direction="vertical">
            <span>
              {record.customer.firstName} {record.customer.lastName}
            </span>
            <span>{record.customer.email}</span>
          </Space>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      align: 'center' as const,
    },
    {
      title: 'Bid',
      dataIndex: 'actualAmount',
      align: 'center' as const,
    },
    {
      title: 'Shipping Cost',
      dataIndex: 'shippingCost',
      align: 'center' as const,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center' as const,
      render: function Customer(
        text: any,
        record: GetAuctions_getAuctions_entities_bids
      ) {
        return <span>{record?.cancelledAt ? 'Canceled' : 'Active'}</span>;
      },
    },
    {
      title: 'Placed at',
      dataIndex: 'createdAt',
      align: 'center' as const,
      width: 120,
      render: (createdAt: string) => moment(createdAt).format(commonTimeFormat),
    },
    {
      title: 'Canceled at',
      dataIndex: 'cancelledAt',
      align: 'center' as const,
      width: 120,
      render: (cancelledAt: string) =>
        cancelledAt ? moment(cancelledAt).format(commonTimeFormat) : 'N/A',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'center' as const,
      render: function Actions(
        text: any,
        record: GetAuctions_getAuctions_entities_bids
      ) {
        return (
          <Space size="middle" direction="vertical">
            {!isAuctionFinished && checkIfWinningBid(record.id) && (
              <Button
                danger
                type="primary"
                onClick={() => handleCancelBid(record.id)}
              >
                Cancel Bid
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Table<GetAuctions_getAuctions_entities_bids>
        columns={columns}
        data={sortedBids}
        total={sortedBids.length}
        loading={loading}
      />
    </div>
  );
};

export default AuctionDetails;
