import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Modal } from 'antd';
import moment from 'moment';
// Api
import { GET_ANY_PRODUCT_SALES } from 'api/memorabilia/queries';
import {
  GetAnyProductSales,
  GetAnyProductSalesVariables,
  GetAnyProductSales_getAnyProductSales_entities,
  GetAnyProductSales_getAnyProductSales_entities_order,
} from 'api/memorabilia/types/GetAnyProductSales';
// Types
import {
  AnyProductSalesOrderBy,
  SortDirection,
} from 'api/graphql-global-types';
// UI
import Table, { SortedInfo, TableFilter } from 'ui/Table';
import ProductSalesDetails from 'components/common/Sales/ProductSales/components/ProductSalesDetails';
// Helpers
import {
  getCommonAddressString,
  getMerchOrderStatus,
  getMerchType,
  getNumberOfSuborderItems,
  orderStatus,
} from 'components/common/Sales/ProductSales/helpers';
import { formatCurrencyString, getCustomerName } from 'helpers/utils';
// Constants
import { onlyDateTimeFormat } from 'constants/global';
type MerchListProps = {
  customerId: string;
};

const MerchList: React.FC<MerchListProps> = ({ customerId }) => {
  const [productDetailsModal, setProductDetailsModal] =
    useState<GetAnyProductSales_getAnyProductSales_entities_order | null>();
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<AnyProductSalesOrderBy>>({
    order: SortDirection.ASC,
    key: AnyProductSalesOrderBy.createdAt,
  });
  const getMerchRequestInput = (): GetAnyProductSalesVariables => {
    const input: any = {
      customerId: customerId,
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      if (key === 'rawCreatedAt') {
        return (input['createdAtDateRange'] = {
          from: Array.isArray(value) ? value?.[0] : value,
          to: Array.isArray(value) ? value?.[1] : value,
        });
      }

      if (key === 'status') {
        return (input['subOrderStatuses'] = value);
      }

      if (key === 'type') {
        return (input['types'] = value);
      }

      input[key] = value;
    });

    return {
      input: input,
    };
  };

  const { data, loading } = useQuery<
    GetAnyProductSales,
    GetAnyProductSalesVariables
  >(GET_ANY_PRODUCT_SALES, {
    variables: { ...getMerchRequestInput() },
    fetchPolicy: 'cache-and-network',
  });
  const tableData: GetAnyProductSales_getAnyProductSales_entities[] =
    data?.getAnyProductSales.entities?.map((sale) => {
      const { id, customer, subOrders, subtotal, createdAt } = sale.order;
      const subtotalRevenue = subtotal;
      return {
        id: id,
        email: customer.email,
        type: getMerchType(subOrders),
        name: getCustomerName(customer.firstName, customer.lastName),
        units: getNumberOfSuborderItems(subOrders),
        status: getMerchOrderStatus(subOrders),
        price: formatCurrencyString(subtotalRevenue),
        shippingAddress: getCommonAddressString(subOrders[0]),
        createdAt: moment(createdAt).format(onlyDateTimeFormat),
        order: sale.order,
        totalStoreEarnings: sale.totalStoreEarnings,
        totalStoreRevenues: sale.totalStoreRevenues,
        __typename: 'StoreOrderSale',
      };
    }) || [];

  const handleCloseModal = () => {
    setProductDetailsModal(null);
  };

  const columns = [
    {
      title: 'Order ID',
      dataIndex: ['order', 'id'],
      align: 'center' as const,
      fixed: 'left' as const,
      sorterType: 'text',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center' as const,
      fixed: 'left' as const,
      filters: [
        {
          text: 'Product',
          value: 'product',
        },
        {
          text: 'Merch',
          value: 'merch',
        },
        {
          text: 'Memorabilia',
          value: 'memorabilia',
        },
      ],
      sorterType: 'text',
      withCheckboxFilters: true,
      filterMultiple: true,
    },
    {
      title: 'Units',
      dataIndex: 'units',
      align: 'center' as const,
      width: 70,
    },
    {
      title: 'Address',
      dataIndex: 'shippingAddress',
      align: 'center' as const,
    },
    {
      title: 'Order Date',
      dataIndex: 'createdAt',
      key: 'rawCreatedAt',
      align: 'center' as const,
      sorterType: 'date',
      withDateRangeFilter: true,
      render: (createdAt: Date) =>
        createdAt ? moment(createdAt).format('ll') : '-',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center' as const,
      sorterType: 'text',
      filters: orderStatus,
      filterMultiple: true,
      withCheckboxFilters: true,
    },
    {
      title: 'Total Price',
      dataIndex: 'price',
      key: 'subtotal',
      align: 'center' as const,
      sorterType: 'number',
      render: (total: number) => total,
    },
    {
      title: 'Actions',
      dataIndex: 'order',
      key: 'actions',
      align: 'center' as const,
      fixed: 'right' as const,
      width: 120,
      render: function ActionWrapper(
        order: GetAnyProductSales_getAnyProductSales_entities_order
      ) {
        return (
          <Button onClick={() => setProductDetailsModal(order)}>Details</Button>
        );
      },
    },
  ];

  return (
    <>
      <Table<
        GetAnyProductSales_getAnyProductSales_entities,
        AnyProductSalesOrderBy
      >
        columns={columns}
        data={tableData}
        scroll={{ x: 1280 }}
        loading={loading}
        sortInfo={sort}
        setSortInfo={setSortInfo}
        total={data?.getAnyProductSales?.total}
        defaultPageSize={pageSize}
        defaultCurrent={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
      />
      <Modal
        visible={!!productDetailsModal}
        onCancel={handleCloseModal}
        title="Details"
        footer={null}
        width="90vw"
      >
        {productDetailsModal && (
          <ProductSalesDetails
            data={productDetailsModal}
            onUpdateShipmentStatus={handleCloseModal}
          />
        )}
      </Modal>
    </>
  );
};
export default MerchList;
