import { gql } from '@apollo/client';
import {
  UserSettingsRequestFields,
  UserAddressRequestFields,
} from './fragments';

export const EDIT_USER = gql`
  mutation EditUser($input: UserUpdateInput!) {
    editUser(input: $input) {
      ...UserSettingsRequestFields
    }
  }
  ${UserSettingsRequestFields}
`;

export const CREATE_ADDRESS = gql`
  mutation CreateAddress($input: CreateAddressInput!) {
    createAddress(input: $input) {
      ...UserAddressRequestFields
    }
  }
  ${UserAddressRequestFields}
`;

export const UPDATE_ADDRESS = gql`
  mutation UpdateAddress($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      ...UserAddressRequestFields
    }
  }
  ${UserAddressRequestFields}
`;

export const DELETE_ADDRESS = gql`
  mutation DeleteAddress($id: String!) {
    deleteAddress(id: $id) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id) {
      id
      lastName
      firstName
      email
      phoneNumber
      storeDetails {
        id
        socialMediaLink
      }
      createdAt
      activatedAt
      role
      isOnboarded
      slug
    }
  }
`;

export const REJECT_STORE_USER = gql`
  mutation RejectAwaitingApprovalStore($input: RejectAwaitingStoreInput!) {
    rejectAwaitingApprovalStore(input: $input) {
      id
      lastName
      firstName
      email
      phoneNumber
      storeDetails {
        id
        socialMediaLink
      }
      createdAt
      activatedAt
      role
      isOnboarded
      slug
    }
  }
`;

export const REINSTATE_REJECTED_STORE_USER = gql`
  mutation ReinstateRejectedStore($input: ReinstateRejectedStoreInput!) {
    reinstateRejectedStore(input: $input) {
      id
      lastName
      firstName
      email
      phoneNumber
      storeDetails {
        id
        socialMediaLink
      }
      createdAt
      activatedAt
      role
      isOnboarded
      slug
    }
  }
`;

export const ADMIN_CHANGE_PASSWORD = gql`
  mutation AdminChangePassword($input: AdminChangePasswordInput!) {
    adminChangePassword(input: $input) {
      id
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      id
    }
  }
`;
