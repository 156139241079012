import { gql } from '@apollo/client';

export const GET_STORE_INTERVIEWERS = gql`
  query GetStoreInterviewers($input: StoreInterviewerFilterInput!) {
    getStoreInterviewers(input: $input) {
      entities {
        id
        createdAt
        email
        firstName
        lastName
        status
        scheduledInterviewCnt
        interviewerDetails {
          id
          isIncludedInAutoAssignment
          sports {
            id
            name
          }
        }
      }
      limit
      offset
      total
    }
  }
`;
