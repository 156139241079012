import { CampaignStatus } from 'api/graphql-global-types';
import { SettingsFormInputs } from 'components/common/SetupDeals/SetupDeals';

export const mapCampaignStatusToLabel = (status: CampaignStatus): string => {
  switch (status) {
    case CampaignStatus.DETAIL_FILLED:
      return 'Details filled';
    case CampaignStatus.ON_REVIEW:
      return 'On review';
    case CampaignStatus.DECLINED:
      return 'Declined';
    case CampaignStatus.COMPLETED:
      return 'Completed';
    case CampaignStatus.PAID_AND_ACTIVE:
      return 'Paid and active';
    case CampaignStatus.PAID_AND_BUDGET_FULFILLED:
      return 'Paid and budget fulfilled';
    default:
      return 'N/a';
  }
};

const excludedKeys = [
  'contactEmail',
  'contactPhoneNumberE164',
  'minFlatPrice',
  'minBookMePrice',
  'minSocialPrice',
  'minSponsoredLivePrice',
  'isArticlesPrEnabled',
  'isAcceptingFreeProducts',
];

export const getDisabledCategories = (values: SettingsFormInputs): string[] => {
  return (Object.keys(values) as (keyof SettingsFormInputs)[]).filter(
    (key) => !excludedKeys.includes(key) && values[key] === false
  ) as string[];
};
