import { gql } from '@apollo/client';
import { StreamListFields, StreamClipFields } from './fragments';

export const GET_STREAMS = gql`
  query Streams($input: StreamFilterInput) {
    adminStreams(input: $input) {
      ...StreamListFields
    }
  }
  ${StreamListFields}
`;

export const GET_ADMIN_WATCH_STREAMS = gql`
  query GetAdminWatchStreams($input: StreamFilterInput) {
    adminStreams(input: $input) {
      entities {
        id
        description
        name
        isFree
        streamStatus
        store {
          id
          lastName
          firstName
        }
        scheduleDate
        timeZone {
          tzCode
          offset
        }
        imageURL
        streamNotes {
          id
          note
          relativeTs
          streamStart
        }
        streamRecords {
          id
          streamId
          videoFileKey
          videoURL
          duration
          videoThumbnailURL
          clips {
            id
            title
            startTs
            status
            endTs
            url
          }
        }
      }
    }
  }
`;

export const GET_UPCOMING_STREAMS = gql`
  query GetUpcomingStreams($input: StreamFilterInput!) {
    adminStreams(input: $input) {
      entities {
        id
        isFree
        name
        streamStatus
        scheduleDate
        numberOfOrders
        timeZone {
          tzCode
          offset
        }
        store {
          id
          lastName
          firstName
          email
          role
          storeDetails {
            id
            storeName
          }
        }
      }
      total
    }
  }
`;

export const GET_STREAM_ORDERS = gql`
  query GetStreamOrders($input: PaginatedStreamOrdersInput!) {
    adminStreamOrders(input: $input) {
      entities {
        id
        orderStatus
        price
        createdAt
        customer {
          id
          lastName
          firstName
          email
        }
      }
      total
      offset
      limit
    }
  }
`;

export const GET_STREAM_SALES_BY_STORE = gql`
  query GetStreamSales($input: StreamSalesInput) {
    getStreamSales(input: $input) {
      entities {
        stream {
          id
          name
          streamStatus
          scheduleDate
        }
        thankYouVideo {
          id
          thankYouVideoUrl
        }
        requestedProfit
        orderAmount
        tipAmount
      }
      total
      offset
      limit
    }
  }
`;

export const GET_STREAM_ORDER_SALES_BY_STORE = gql`
  query GetStreamOrderSales(
    $input: StreamOrderAndTipsTypeInput
    $streamId: String!
  ) {
    getStreamOrderSales(input: $input, streamId: $streamId) {
      total
      offset
      limit
      entities {
        id
        firstName
        lastName
        email
        paidat
        earnings
        discount
        type
      }
    }
  }
`;

export const GET_MODERATORS = gql`
  query GetModerators($input: ModeratorsFilterInput) {
    moderators(input: $input) {
      entities {
        createdAt
        email
        firstName
        id
        lastName
        status
        phoneNumber
      }
    }
  }
`;

export const GET_STREAM_SALES_REPORT = gql`
  query GetStreamSalesReport($input: SalesReportInput!) {
    getStreamSalesReport(input: $input) {
      entities {
        storeId
        firstName
        lastName
        sports
        totalRevenue
      }
    }
  }
`;

export const GET_STREAM_CLIP = gql`
  query GetStreamClip($input: GetStreamClipInput!) {
    getStreamClip(input: $input) {
      ...StreamClipFields
    }
  }
  ${StreamClipFields}
`;

export const GET_OR_CREATE_STREAM_PARTICIPANT_INVITE_ADMIN = gql`
  query GetOrCreateStreamParticipantInviteAdmin(
    $input: StreamParticipantInviteInputAdmin!
  ) {
    getOrCreateStreamParticipantInviteAdmin(input: $input) {
      id
      invite
    }
  }
`;

export const GET_USER_STREAM_ORDERS_EXTENDED = gql`
  query GetUserStreamOrdersExtended($input: StreamOrdersQueryInput!) {
    getUsersStreamPurchase(input: $input) {
      entities {
        id
        stripeIntentClientSecret
        orderStatus
        price
        paymentMethodId
        createdAt
        stream {
          id
          isFree
          description
          name
          store {
            id
            email
          }
          price
          requestedPrice
          scheduleDate
          timeZone {
            tzCode
            offset
          }
          streamStatus
          slug
          isAvailable
          imageURL
          isPurchased
        }
      }
      total
      offset
      limit
    }
  }
`;
