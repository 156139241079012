import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Modal, Button, Typography, Rate } from 'antd';
// Api
import { DESIGNERS } from 'api/designLab/queries';
import { ASSIGN_DESIGN_TO_DESIGNER } from 'api/designLab/mutations';
// Types
import {
  Designers,
  DesignersVariables,
  Designers_designers_entities,
} from 'api/designLab/types/Designers';
import {
  AssignMerchDesignToDesigner,
  AssignMerchDesignToDesignerVariables,
} from 'api/designLab/types/AssignMerchDesignToDesigner';
import {
  DesignerStatus,
  DesignersOrderBy,
  SortDirection,
} from 'api/graphql-global-types';
// UI
import Table, { SortedInfo, TableFilter } from 'ui/Table';
import { errorNotification, successNotification } from 'ui/Notification';

const { Text } = Typography;

type AssignDesignerModalProps = {
  designRequestId?: string;
  isModalVisible?: boolean;
  setModalVisible: (isVisible: boolean) => void;
  handleAssignDesignerId?: (designerId: string | null) => void;
  isFromRejectAndReassignModal?: boolean;
};

const AssignDesignerModal: React.FC<AssignDesignerModalProps> = ({
  designRequestId,
  isModalVisible = false,
  setModalVisible,
  handleAssignDesignerId,
  isFromRejectAndReassignModal = false,
}) => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<DesignersOrderBy>>({
    order: SortDirection.DESC,
    key: DesignersOrderBy.designerRate,
  });
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const getDesignersInput: any = () => {
    const input: any = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      designerStatus: [DesignerStatus.Active],
    };

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;
    });

    return { input };
  };

  const [assignDesignToDesigner, { loading: assignDesignToDesignerLoading }] =
    useMutation<
      AssignMerchDesignToDesigner,
      AssignMerchDesignToDesignerVariables
    >(ASSIGN_DESIGN_TO_DESIGNER);
  const [assignDesignerId, setAssignDesignerId] = useState<string | null>(null);

  const handleHideModal = () => {
    setModalVisible(false);
    setSearchFilters([]);
  };

  const handleAssignDesigner = async (
    designerId: string,
    designerName: string
  ) => {
    if (isFromRejectAndReassignModal) {
      handleAssignDesignerId && handleAssignDesignerId(designerId);
      handleHideModal();
    } else {
      if (designerId && designRequestId) {
        setAssignDesignerId(designerId);
        try {
          await assignDesignToDesigner({
            variables: {
              input: {
                designRequestId,
                designerId,
              },
            },
          });

          setAssignDesignerId(null);
          handleHideModal();
          successNotification(
            `Request #${designRequestId} has been assigned to designer ${designerName} successfully`
          );
        } catch (err) {
          errorNotification((err as Error)?.message);

          console.log('handleAssignDesigner error:', { ...(err as Error) });
        }
        setAssignDesignerId(null);
      }
    }
  };

  const { data, loading } = useQuery<Designers, DesignersVariables>(DESIGNERS, {
    variables: {
      ...getDesignersInput(),
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    {
      title: 'Designer name',
      dataIndex: 'firstName',
      align: 'left' as const,
      key: 'designerName',
      width: 200,
      withSearch: true,
      render: function RequestDetails(
        firstName: string,
        record: Designers_designers_entities
      ) {
        return (
          <Text>{`${record?.firstName || ''} ${record?.lastName || ''}`}</Text>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'center' as const,
      width: 200,
      withSearch: true,
      key: 'email',
    },
    {
      title: 'Rate',
      sorterType: 'text',
      key: DesignersOrderBy.designerRate,
      dataIndex: 'designerRate',
      align: 'center' as const,
      width: 200,
      render: function RateWrap(designerRate: number | null) {
        return <Rate value={designerRate || 0} disabled allowHalf count={5} />;
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      align: 'center' as const,
      width: 50,
      render: function ActionsWrap(
        id: string,
        record: Designers_designers_entities
      ) {
        return (
          <Button
            type="primary"
            onClick={() =>
              handleAssignDesigner(
                id,
                `${record?.firstName || ''} ${record?.lastName || ''}`
              )
            }
            loading={assignDesignerId === id && assignDesignToDesignerLoading}
          >
            Assign
          </Button>
        );
      },
    },
  ];

  return (
    <Modal
      title={
        <>
          Choose designer to assign design request{' '}
          <strong>{`#${designRequestId}`}</strong>
        </>
      }
      visible={isModalVisible}
      footer={null}
      onCancel={handleHideModal}
      width={970}
    >
      <Table<Designers_designers_entities, DesignersOrderBy>
        columns={columns}
        data={data?.designers?.entities}
        scroll={{ x: 450 }}
        loading={loading}
        total={data?.designers?.total}
        defaultPageSize={pageSize}
        setSearchFilters={setSearchFilters}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />
    </Modal>
  );
};

export default AssignDesignerModal;
