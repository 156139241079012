import React, { ChangeEvent, useState } from 'react';
import { Modal, Button, Row, Col, Input, Select } from 'antd';
// Hooks
import { useGetSports } from 'hooks';

import './styles.scss';

const { Option } = Select;

type CreateEditHashtagModalProps = {
  initialHashtagName?: string;
  initialSportIds?: number[];
  onSubmit: (props: {
    hashtagName: string;
    sportIds: number[];
    onSuccess: () => void;
  }) => Promise<void>;
  loading: boolean;
  children({ onModalOpen }: { onModalOpen: () => void }): JSX.Element;
};

const CreateEditHashtagModal: React.FC<CreateEditHashtagModalProps> = ({
  initialHashtagName = '',
  initialSportIds = [],
  onSubmit,
  loading,
  children,
}) => {
  const [hashtagName, setHashtagName] = useState<string>(initialHashtagName);
  const [sports, setSports] = useState<number[]>(initialSportIds);
  const [hashTagIsValid, setHashtagIsValid] = useState<boolean>(
    !!initialHashtagName
  );
  const [isModalOpen, setModalVisibility] = useState<boolean>(false);

  const { data: sportsData } = useGetSports();

  const handleModalOpen = () => {
    setModalVisibility(true);
  };

  const handleModalClose = () => {
    setModalVisibility(false);

    // reset values only for "create" form
    if (!initialHashtagName) {
      setHashtagName('');
      setSports([]);
      setHashtagIsValid(false);
    }
  };

  const validateHashtag = (value?: string) => {
    const regexp = /^[0-9a-zA-Z_-]+$/;
    return value ? regexp.test(value) : false;
  };

  const handleHashtagNameChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setHashtagName(value);
    setHashtagIsValid(validateHashtag(value));
  };

  const handleSportsChange = (value: number[]) => {
    setSports(value);
  };

  const handleSubmit = async () => {
    await onSubmit({
      hashtagName,
      sportIds: sports,
      onSuccess: handleModalClose,
    });
  };

  const sportsOptions = sportsData?.sports?.map((item) => (
    <Option key={item.id} value={item.id}>
      {item.name}
    </Option>
  ));

  const title = initialHashtagName ? 'Edit #hashtag' : 'Create new #hashtag';
  const buttonName = initialHashtagName ? 'Update' : 'Create';

  return (
    <>
      {children({ onModalOpen: handleModalOpen })}

      <Modal
        title={title}
        visible={isModalOpen}
        footer={null}
        onCancel={handleModalClose}
      >
        <Row>
          <Col className="create-edit-hashtag-fields-wrapper">
            <Input
              className="create-edit-hashtag-field "
              value={hashtagName}
              onChange={handleHashtagNameChange}
              placeholder={'Type your hashtag name'}
              allowClear
            />
            <Select
              optionFilterProp="children"
              className="create-edit-hashtag-field"
              placeholder="Select sports"
              mode="multiple"
              value={sports}
              onChange={handleSportsChange}
            >
              {sportsOptions}
            </Select>
            <Button
              onClick={handleSubmit}
              type="primary"
              loading={loading}
              disabled={!hashTagIsValid}
            >
              {buttonName}
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default CreateEditHashtagModal;
