import React, { useState } from 'react';
import moment from 'moment';
import { Modal, Button, Typography, Space } from 'antd';
// Types
import {
  DesignRequests_designRequests_entities_auditLogs,
  DesignRequests_designRequests_entities_auditLogs_designer,
  DesignRequests_designRequests_entities_auditLogs_manager,
  DesignRequests_designRequests_entities_auditLogs_performedBy,
} from 'api/designLab/types/DesignRequests';
import {
  GetVideoEditRequests_getVideoEditRequests_entities_auditLogs,
  GetVideoEditRequests_getVideoEditRequests_entities_auditLogs_performedBy,
} from 'api/videoLab/types/GetVideoEditRequests';
// UI
import Table from 'ui/Table';

const { Text } = Typography;

type AuditLogsProps = {
  data:
    | DesignRequests_designRequests_entities_auditLogs[]
    | GetVideoEditRequests_getVideoEditRequests_entities_auditLogs[];
  isVideoRequest?: boolean;
};

const AuditLogs: React.FC<AuditLogsProps> = ({
  data,
  isVideoRequest = false,
}) => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const handleShowModal = () => {
    setModalVisible(true);
  };

  const handleHideModal = () => {
    setModalVisible(false);
  };

  const designRequestColumns = [
    {
      title: 'Name',
      dataIndex: '__typename',
      align: 'center' as const,
      width: 150,
      render: (name: string) => name,
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      align: 'center' as const,
      width: 150,
      render: (createdAt: string) =>
        moment(createdAt).local().format('MM/DD/YYYY, h:mm a'),
    },
    {
      title: 'Designer name',
      dataIndex: 'designer',
      width: 200,
      render: (
        designer: DesignRequests_designRequests_entities_auditLogs_designer
      ) => (
        <Space size="small">
          {designer?.firstName || ''}
          {designer?.lastName || ''}
        </Space>
      ),
    },
    {
      title: 'Performed by',
      dataIndex: 'performedBy',
      width: 200,
      render: (
        performedBy: DesignRequests_designRequests_entities_auditLogs_performedBy
      ) => (
        <Space size="small">
          {performedBy?.firstName || ''}
          {performedBy?.lastName || ''}
        </Space>
      ),
    },
    {
      title: 'Manager',
      dataIndex: 'manager',
      align: 'center' as const,
      width: 150,
      render: (
        manager: DesignRequests_designRequests_entities_auditLogs_manager
      ) => (
        <Space size="small">
          {manager?.firstName || ''}
          {manager?.lastName || ''}
        </Space>
      ),
    },
  ];

  const videoRequestColumns = [
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center' as const,
      width: 150,
      render: (action: string) => action,
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      align: 'center' as const,
      width: 150,
      render: (createdAt: string) =>
        moment(createdAt).local().format('MM/DD/YYYY, h:mm a'),
    },
    {
      title: 'Performed by',
      dataIndex: 'performedBy',
      width: 200,
      render: (
        performedBy: GetVideoEditRequests_getVideoEditRequests_entities_auditLogs_performedBy
      ) => (
        <Space size="small">
          {performedBy?.firstName || ''}
          {performedBy?.lastName || ''}
        </Space>
      ),
    },
    {
      title: 'Feedback',
      dataIndex: 'feedback',
      align: 'center' as const,
      width: 150,
      render: (feedback: string) => feedback,
    },
  ];

  if (!data || !data?.length) {
    return <Text>-</Text>;
  }

  const title = isVideoRequest
    ? 'Video request audit logs'
    : 'Design request audit logs';

  return (
    <>
      <Button type="link" onClick={handleShowModal}>
        Show audit logs
      </Button>

      <Modal
        title={title}
        visible={isModalVisible}
        footer={null}
        onCancel={handleHideModal}
        width={770}
      >
        <Table<
          | DesignRequests_designRequests_entities_auditLogs
          | GetVideoEditRequests_getVideoEditRequests_entities_auditLogs
        >
          columns={isVideoRequest ? videoRequestColumns : designRequestColumns}
          data={data}
          scroll={{ x: 430 }}
          total={data.length}
          defaultPageSize={10}
        />
      </Modal>
    </>
  );
};

export default AuditLogs;
