import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Form,
  Input,
  Space,
  Spin,
  Typography,
  Radio,
  Select,
  Checkbox,
  DatePicker,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
// Api
import {
  ESTIMATE_PRODUCT_PRICE,
  GET_ADDRESSES,
  GET_MEMORABILIA,
  GET_SHIPMENT_CATEGORIES,
} from 'api/memorabilia/queries';
import {
  CREATE_MEMORABILIA,
  CREATE_PRESIGNED_URL,
  UPDATE_MEMORABILIA,
} from 'api/memorabilia/mutations';
// Types
import {
  EstimateProductPrice,
  EstimateProductPriceVariables,
} from 'api/memorabilia/types/EstimateProductPrice';
import {
  GetMemorabiliaVariables,
  GetMemorabilia_getMemorabilia_entities,
} from 'api/memorabilia/types/GetMemorabilia';
import {
  CreateMemorabiliaInput,
  MemorabiliaFulfillment,
  MemorabiliaProductType,
  UpdateMemorabiliaInput,
} from 'api/graphql-global-types';
import { CreateMemorabiliaVariables } from 'api/memorabilia/types/CreateMemorabilia';
import { UpdateMemorabiliaVariables } from 'api/memorabilia/types/UpdateMemorabilia';
import { GetShipmentCategories } from 'api/memorabilia/types/GetShipmentCategories';
import {
  CreatePresignedUrl,
  CreatePresignedUrlVariables,
  CreatePresignedUrl_createPresignedUrl,
} from 'api/memorabilia/types/CreatePresignedUrl';
import {
  GetAddresses,
  GetAddressesVariables,
} from 'api/memorabilia/types/GetAddresses';
// Helpers
import { getDelta } from 'components/common/ManageProductsTable/components/helpers';
import { formatPrice } from 'components/common/ManageMerch/helpers';
// Constants
import { PRODUCT_TYPE_OPTIONS } from 'components/common/ManageProductsTable/components/constants';
import { commonTimeFormat } from 'constants/global';
// Components
import CreateAddressModal from 'pages/ManageAccount/components/CustomerAddresses/Components/CreateAddressModal';
// Ui
import TagsInput from 'uiShared/TagsInput/TagsInput';
import {
  successNotification,
  errorNotification,
  warningNotification,
} from 'ui/Notification';
import UploadMultipleImages, {
  UploadImage,
} from 'ui/UploadMultipleImages/UploadMultipleImages';

import styles from './CreateEditMemorabilia.module.scss';
import { formatHashtagInput } from 'helpers/hashtags';

type FormValues = {
  title: string;
  description: string;
  fulfillment: MemorabiliaFulfillment;
  memorabiliaProductType: MemorabiliaProductType;
  requestedPrice: number;
  hashtags: string[];
  numberOfUnits: number;
  shipmentCategory: string;
  addressId: string;
  customsDescription: string;
  isForAuction: boolean;
  onAuctionFrom: Moment | null;
  onAuctionTo: Moment | null;
  images: UploadImage[];
};

type ImageToUpload = {
  fields: string;
  url: string;
  name: string;
  type: string;
  file: File | '';
};

type CreateEditMemorabiliaProps = {
  record: GetMemorabilia_getMemorabilia_entities | null;
  hideItemModal: () => void;
  storeId: string;
  currentAction: string;
  getMemorabiliaInput: () => GetMemorabiliaVariables;
};

const { Text, Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const CreateEditMemorabilia = ({
  record,
  hideItemModal,
  storeId,
  currentAction,
  getMemorabiliaInput,
}: CreateEditMemorabiliaProps): JSX.Element => {
  const initialFormValues: FormValues = {
    title: record?.title || '',
    description: record?.description || '',
    fulfillment: record?.fulfillment || MemorabiliaFulfillment.Athlete,
    memorabiliaProductType:
      record?.memorabiliaProductType || MemorabiliaProductType.Memorabilia,
    requestedPrice: record?.requestedPrice || 0,
    numberOfUnits: record?.numberOfUnits || 0,
    shipmentCategory: record?.shipmentCategory || '',
    customsDescription: record?.customsDescription || '',
    hashtags: record?.hashtags.map((tag) => tag.name) || [],
    addressId: record?.originAddress?.id || '',
    isForAuction: record?.isForAuction || false,
    onAuctionFrom: record?.onAuctionFrom ? moment(record.onAuctionFrom) : null,
    onAuctionTo: record?.onAuctionTo ? moment(record.onAuctionTo) : null,
    images:
      record?.images?.map((item) => ({
        data_url: item.url || undefined,
        data_key: item.key || undefined,
      })) || [],
  };

  const [isCreateAddressModalOpen, setIsCreateAddressModalOpen] =
    useState<boolean>(false);

  const mainImageIndex: number =
    record?.images?.findIndex(function (image) {
      return image.isMainImage === true;
    }) || 0;

  const [isMainImage, setIsMainImage] = useState<number | null>(mainImageIndex);
  const [formFields, setFormFields] = useState<FormValues>(initialFormValues);
  const [onAuctionFromDate, setOnAuctionFromDate] = useState<Moment | null>(
    record?.onAuctionFrom ? moment(record?.onAuctionFrom) : null
  );
  const [onAuctionToDate, setOnAuctionToDate] = useState<Moment | null>(
    record?.onAuctionTo ? moment(record?.onAuctionTo) : null
  );

  const [form] = Form.useForm();
  const methods = useForm();
  const { control } = methods;

  const setFields = () => {
    const fields: FormValues = form.getFieldsValue();

    setFormFields({
      ...fields,
    });
  };

  // Shipment categories
  const { data: shipmentCategories } = useQuery<GetShipmentCategories>(
    GET_SHIPMENT_CATEGORIES
  );

  // User addresses used for rendering origin addresses
  const { data: userInfo, refetch: refetchAddresses } = useQuery<
    GetAddresses,
    GetAddressesVariables
  >(GET_ADDRESSES, {
    variables: {
      input: {
        userId: storeId,
      },
    },
  });

  const currentPrice = Number(formFields.requestedPrice);
  const currentNumberOfItems = Number(formFields.numberOfUnits);
  const isForAuction = formFields.isForAuction;

  const { data: estimatedPrice } = useQuery<
    EstimateProductPrice,
    EstimateProductPriceVariables
  >(ESTIMATE_PRODUCT_PRICE, {
    variables: {
      input: {
        fulfillment: MemorabiliaFulfillment.Athlete,
        numberOfUnits: currentNumberOfItems,
        requestedPrice: currentPrice,
      },
    },
    skip: !currentPrice || !currentNumberOfItems,
    fetchPolicy: 'cache-and-network',
  });

  const [createMemorabilia, { loading }] = useMutation<
    CreateMemorabiliaInput,
    CreateMemorabiliaVariables
  >(CREATE_MEMORABILIA, {
    refetchQueries: [
      {
        query: GET_MEMORABILIA,
        variables: {
          ...getMemorabiliaInput(),
        },
      },
    ],
  });

  const [updateMemorabilia, { loading: updateLoading }] = useMutation<
    UpdateMemorabiliaInput,
    UpdateMemorabiliaVariables
  >(UPDATE_MEMORABILIA, {
    refetchQueries: [
      {
        query: GET_MEMORABILIA,
        variables: { ...getMemorabiliaInput() },
      },
    ],
  });

  const [createPresignedUrl] = useMutation<
    CreatePresignedUrl,
    CreatePresignedUrlVariables
  >(CREATE_PRESIGNED_URL);

  const addresses = userInfo?.getAddresses?.map((item) => ({
    label: (
      <div className={styles.addresses}>
        <div>
          <Text>{`${item.firstName || ''} ${item.lastName || ''}`}</Text>
        </div>

        <div>
          <div>
            <Text>{`${item.addressLine1 || ''} `}</Text>
            <Text>{`${item.addressLine2 || ''} `}</Text>
          </div>

          <div>
            <Text>{`${item.city + ', ' || ''} `}</Text>
            {item.state && <Text>{`${item.state}, `}</Text>}
            <Text>{`${item.zipCode || ''} `}</Text>
          </div>

          <div>
            <Text>{item.country || ''}</Text>
          </div>
        </div>

        <Space direction="vertical">
          <Text>{item.phone || ''}</Text>
          <Text>{item.email || ''} </Text>
        </Space>
      </div>
    ),
    value: item.id,
  }));

  const getImagesToUpload = (
    preSignedUrls: CreatePresignedUrl_createPresignedUrl[],
    images: UploadImage[]
  ): ImageToUpload[] => {
    const res: ImageToUpload[] = [];

    images.forEach(({ file }, index) => {
      const preSignedUrl = preSignedUrls?.[index];

      const imageExtension = file
        ? file.name.split('.')[file.name.split('.').length - 1]
        : '';

      const imageName = `${preSignedUrl?.key || ''}.${imageExtension}`;

      res.push({
        fields: preSignedUrl?.fields || '',
        url: preSignedUrl?.url || '',
        name: imageName,
        type: file?.type || '',
        file: file || '',
      });
    });

    return res;
  };

  const uploadImages = async (
    preSignedUrls: CreatePresignedUrl_createPresignedUrl[],
    images: UploadImage[]
  ): Promise<string[]> => {
    const imagesToUpload = getImagesToUpload(preSignedUrls, images);

    for (const image of imagesToUpload) {
      const { fields, url, name, type, file } = image;
      const formData = new FormData();

      Object.entries(JSON.parse(fields)).forEach(([key, value]) => {
        formData.append(key, value as string);
      });

      formData.append('key', name);
      formData.append('Content-Type', type);
      formData.append('file', file);

      await fetch(url, {
        method: 'POST',
        body: formData,
      });
    }

    return imagesToUpload.map(({ name }) => name);
  };

  const checkAndAdjustAuctionStartTime = () => {
    if (moment(formFields.onAuctionFrom).isBefore(moment())) {
      return moment(formFields.onAuctionFrom).add(1, 'minute');
    }

    return formFields.onAuctionFrom;
  };

  const handleCreateMemorabilia = async () => {
    if (!storeId) {
      warningNotification('Please select store');
    } else {
      try {
        const { data } = await createPresignedUrl({
          variables: {
            input: {
              numberOfImages: formFields.images.length,
            },
          },
        });

        if (data) {
          try {
            const imagesUrls = await uploadImages(
              data.createPresignedUrl,
              formFields.images
            );

            if (imagesUrls) {
              const millionsFulfillmentInput = {
                title: formFields.title,
                description: formFields.description,
                images: imagesUrls.map((item) => ({
                  isMainImage: imagesUrls.indexOf(item) === isMainImage,
                  memorabiliaImageFileKey: item,
                })),
                fulfillment: MemorabiliaFulfillment.Athlete,
                memorabiliaProductType: formFields.memorabiliaProductType,
                requestedPrice: Number(formFields.requestedPrice),
                numberOfUnits: Number(formFields.numberOfUnits),
                storeId,
                width: 25.4,
                height: 25.4,
                length: 25.4,
                weight: 0.907185,
                shipmentCategory: formFields.shipmentCategory,
                customsDescription: formFields.customsDescription,
                hashtagInputs: formFields?.hashtags?.length
                  ? formatHashtagInput(formFields.hashtags)
                  : null,
                auctionDateRange: formFields.onAuctionFrom
                  ? {
                      onAuctionFrom: checkAndAdjustAuctionStartTime(),
                      onAuctionTo: formFields.onAuctionTo,
                    }
                  : null,
              };

              const athleteFulfillment = {
                ...millionsFulfillmentInput,
                addressId: formFields.addressId,
              };

              try {
                await createMemorabilia({
                  variables: {
                    input: athleteFulfillment,
                  },
                });
                successNotification(`New item created`);
                hideItemModal();
              } catch (error) {
                errorNotification((error as Error)?.message);
              }
            }
          } catch (error) {
            errorNotification((error as Error)?.message);
          }
        }
      } catch (error) {
        errorNotification((error as Error)?.message);
      }
    }
  };

  const handleEditMemorabilia = async () => {
    const newImages = formFields.images?.filter((item: any) => {
      return item.file;
    });

    const existingImages = formFields.images?.filter((item: any) => {
      return !item.file;
    });

    const newAndExistingImages = [
      ...existingImages.map((item: any) => item.data_key),
    ];

    if (newImages.length) {
      try {
        const { data } = await createPresignedUrl({
          variables: {
            input: {
              numberOfImages: newImages.length,
            },
          },
        });

        if (data) {
          try {
            const imageKeys = await uploadImages(
              data.createPresignedUrl,
              newImages
            );

            if (imageKeys) {
              // here we combine old images and new ones, uploading only new ones to s3
              imageKeys.forEach((newImageKey) => {
                newAndExistingImages.push(newImageKey);
              });
            }
          } catch (error) {
            errorNotification((error as Error)?.message);
          }
        }
      } catch (error) {
        errorNotification((error as Error)?.message);
      }
    }
    try {
      const currenNumberOfUnits = record?.numberOfUnits || 0;

      await updateMemorabilia({
        variables: {
          input: {
            id: record?.id || '',
            addressId: formFields?.addressId,
            title: formFields.title,
            description: formFields.description,
            hashtagInputs: formFields?.hashtags?.length
              ? formatHashtagInput(formFields.hashtags)
              : null,
            shipmentCategory: formFields.shipmentCategory,
            requestedPrice: Number(formFields.requestedPrice),
            changedStock: getDelta(
              currenNumberOfUnits,
              formFields.numberOfUnits
            ),
            storeId,
            images: newAndExistingImages.map((item) => ({
              isMainImage: newAndExistingImages.indexOf(item) === isMainImage,
              memorabiliaImageFileKey: item,
            })),
          },
        },
      });
      successNotification(`Item Updated`);
      hideItemModal();
    } catch (error) {
      errorNotification((error as Error)?.message);
    }
  };

  const handleFinish = async () => {
    if (currentAction === 'Edit') {
      await handleEditMemorabilia();
    } else {
      await handleCreateMemorabilia();
    }
  };

  const handleCreateAddressModalOpen = () => {
    setIsCreateAddressModalOpen(true);
  };

  const handleCreateAddressModalClose = () => {
    setIsCreateAddressModalOpen(false);
  };

  const handleOnIsForAuctionChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      form.setFieldsValue({
        numberOfUnits: 1,
      });
    }
  };

  const disabledAuctionFromDate = (current: Moment | null) => {
    if (!current) return false;

    return moment(current).isBefore(moment().add(-1, 'minute'));
  };

  const disabledAuctionFromTime = () => ({
    disabledHours: () => {
      if (onAuctionFromDate?.isSame(moment(), 'day')) {
        const currentHour = moment().get('hour');

        return Array.from(Array(currentHour).keys());
      }

      return [];
    },
    disabledMinutes: () => {
      if (
        onAuctionFromDate?.isSame(moment(), 'day') &&
        onAuctionFromDate?.isSame(moment(), 'hour')
      ) {
        const currentMinute = moment().get('minute');

        return Array.from(Array(currentMinute).keys());
      }

      return [];
    },
  });

  const disabledAuctionToDate = (current: Moment | null) => {
    if (!current) return false;

    return (
      moment(current).isAfter(moment(onAuctionFromDate).add(7, 'days')) ||
      moment(current).isBefore(moment(onAuctionFromDate))
    );
  };

  const disabledAuctionToTime = () => ({
    disabledHours: () => {
      if (
        onAuctionToDate?.isSame(moment(onAuctionFromDate).add(7, 'days'), 'day')
      ) {
        const latestHour = onAuctionFromDate?.get('hour') || 0;

        return Array.from(
          { length: 24 - latestHour },
          (_, index) => index + latestHour
        );
      }

      return [];
    },
  });

  const isEdit = currentAction === 'Edit';

  const finalPrice = estimatedPrice?.estimateProductPrice?.price || 0;

  const handleHashtagsChange = (hashtags: string[]) => {
    form.setFieldsValue({ hashtags: hashtags });
    setFields();
  };

  return (
    <>
      <Form
        form={form}
        layout="horizontal"
        name="setupMemorabilia"
        initialValues={initialFormValues}
        autoComplete="off"
        onValuesChange={setFields}
        onLoad={setFields}
        onFinish={handleFinish}
      >
        <div className={styles.formWrapper}>
          <div>
            <Title>Item details</Title>

            <Space direction="horizontal">
              <Form.Item name="memorabiliaProductType">
                <Radio.Group options={PRODUCT_TYPE_OPTIONS} disabled={isEdit} />
              </Form.Item>
            </Space>

            <Form.Item
              name="isForAuction"
              label="Is for auction sale"
              valuePropName="checked"
            >
              <Checkbox
                disabled={isEdit}
                onChange={handleOnIsForAuctionChange}
              />
            </Form.Item>

            {isForAuction && (
              <Space>
                <Form.Item
                  name="onAuctionFrom"
                  label="On Auction From"
                  rules={[
                    {
                      required: isForAuction,
                      message: 'Auctions start date is required',
                    },
                  ]}
                  tooltip="If selected time is in past it will be set 1 minute in future"
                >
                  <DatePicker
                    showTime
                    showSecond={false}
                    disabled={isEdit}
                    format={commonTimeFormat}
                    disabledDate={disabledAuctionFromDate}
                    disabledTime={disabledAuctionFromTime}
                    onSelect={setOnAuctionFromDate}
                  />
                </Form.Item>

                <Form.Item
                  name="onAuctionTo"
                  label="On Auction To"
                  rules={[
                    {
                      required: isForAuction,
                      message: 'Auction end date is required',
                    },
                  ]}
                >
                  <DatePicker
                    showTime
                    showSecond={false}
                    disabled={!onAuctionFromDate || isEdit}
                    format={commonTimeFormat}
                    disabledDate={disabledAuctionToDate}
                    disabledTime={disabledAuctionToTime}
                    onSelect={setOnAuctionToDate}
                  />
                </Form.Item>
              </Space>
            )}

            <Form.Item
              className={styles.customInput}
              name="title"
              label={<Text>Title</Text>}
              help="Name your item"
              rules={[
                {
                  required: true,
                  message: 'Please enter the title',
                },
              ]}
            >
              <Input placeholder="Example of title" />
            </Form.Item>

            <Form.Item
              className={styles.customInput}
              name="description"
              label={<Text>Description</Text>}
              rules={[
                {
                  required: true,
                  message: 'Please enter the description',
                },
              ]}
            >
              <TextArea placeholder="This should be a short description which reflects the uniqueness of the memorabilia item." />
            </Form.Item>

            <Form.Item
              name="shipmentCategory"
              label={<Text>Shipment Category</Text>}
              rules={[
                {
                  required: true,
                  message: 'please select shipment category',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select shipment category"
                optionFilterProp="children"
              >
                {shipmentCategories?.getShipmentCategories?.map(
                  ({ name, slug }) => {
                    return (
                      <Option key={name} value={slug}>
                        {name}
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>

            <Form.Item name="hashtags" label="Tagging">
              <Controller
                name="hashtags"
                defaultValue={formFields.hashtags}
                control={control}
                render={() => (
                  <TagsInput
                    hashtags={formFields.hashtags}
                    onChange={(val: any) => handleHashtagsChange(val)}
                    name="hashtags"
                  />
                )}
              />
            </Form.Item>

            <Space direction="vertical">
              <Form.Item
                className={styles.customInput}
                name="numberOfUnits"
                label="Number of units in stock"
                help="How many units of the item are you selling?"
                rules={[
                  {
                    required: true,
                    message: 'Please provide number of units',
                  },
                ]}
              >
                <Input
                  disabled={isForAuction}
                  type="number"
                  placeholder="Type here"
                />
              </Form.Item>
            </Space>

            <div className={styles.priceWrapper}>
              <Form.Item
                className={styles.customInput}
                name="requestedPrice"
                label={isForAuction ? 'Starting price' : 'Price'}
                help="Max price is $799999"
                rules={[
                  {
                    required: true,
                    message: 'Price is required',
                  },
                ]}
              >
                <Input
                  disabled={isForAuction && isEdit}
                  max={799999}
                  type="number"
                  placeholder="Type here"
                />
              </Form.Item>

              <Text>Final Price: {formatPrice(finalPrice)}</Text>
            </div>

            <Form.Item
              className={styles.customInput}
              name="customsDescription"
              label={<Text>Customs description</Text>}
              rules={[
                {
                  required: true,
                  message:
                    'Please enter the customs description (Max 200 characters)',
                  max: 200,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Title>Origin address</Title>
            <Space direction="vertical">
              <Form.Item name="addressId">
                <Radio.Group options={addresses} />
              </Form.Item>
            </Space>
          </div>

          <div className={styles.imageUploadWrapper}>
            <Title>Image upload</Title>
            <Form.Item
              name="images"
              rules={[
                {
                  required: true,
                  message: 'Please provide at least one image',
                },
              ]}
            >
              <UploadMultipleImages
                images={formFields.images}
                setImages={(images) => {
                  form.setFieldsValue({
                    images,
                  });
                  setFields();
                }}
                isMainImage={isMainImage}
                setIsMainImage={setIsMainImage}
              />
            </Form.Item>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <Button type="primary" onClick={handleCreateAddressModalOpen}>
            Add new address
          </Button>

          <CreateAddressModal
            userId={storeId}
            open={isCreateAddressModalOpen}
            onComplete={refetchAddresses}
            onClose={handleCreateAddressModalClose}
          />

          {loading || updateLoading ? (
            <Spin size="large" />
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              className="createEditFormSubmit"
            >
              Confirm
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default CreateEditMemorabilia;
