import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import Cropper, { CropperProps } from 'react-easy-crop';
// Helpers
import { getCroppedImageFile, getEasyCroppedImage } from 'helpers/crop-image';
// Types
import { Point, Area } from 'react-easy-crop/types';

import styles from './EasyImageCrop.module.scss';

type BasicProps = Partial<Pick<CropperProps, 'aspect' | 'classes'>>;

type EasyImageCropProps = {
  imageFile: File;
  onApplyImage: (file: File) => void;
  className?: string;
} & BasicProps;

const EasyImageCrop = ({
  imageFile,
  aspect = 3 / 2,
  onApplyImage,
  classes,
  className,
}: EasyImageCropProps): JSX.Element => {
  const [image, setImage] = useState<string>('');
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);

  useEffect(() => {
    if (imageFile && !image) {
      setImage(URL.createObjectURL(imageFile));
    }
  }, [imageFile, image]);

  const handleCropComplete = useCallback(
    async (_: Area, croppedAreaPixels: Area) => {
      if (image && croppedAreaPixels) {
        const dataUrl = await getEasyCroppedImage(
          image,
          croppedAreaPixels,
          imageFile.type
        );

        const file = await getCroppedImageFile(
          dataUrl,
          imageFile.name,
          imageFile.type
        );

        onApplyImage(file);
      }
    },
    [image, imageFile.name, imageFile.type, onApplyImage]
  );

  return (
    <div className={cn(styles.root, className)}>
      <Cropper
        image={image}
        crop={crop}
        zoom={zoom}
        aspect={aspect}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        onCropComplete={handleCropComplete}
        objectFit="contain"
        classes={{
          ...classes,
          containerClassName: cn(styles.container, classes?.containerClassName),
        }}
      />
    </div>
  );
};

export default EasyImageCrop;
