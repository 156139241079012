// eslint-disable react-hooks/exhaustive-deps
import { notification } from 'antd';
import React, { useState, useRef, useEffect, useMemo, forwardRef } from 'react';
import { fabric } from 'fabric';
// Types
import {
  GetPRProductByTypeV2_getPRProductByTypeV2_data_templates_templates,
  GetPRProductByTypeV2_getPRProductByTypeV2_data_printfiles_printfiles,
} from 'api/merch/types/GetPRProductByTypeV2';
import { GetMerchProduct_getMerchProduct_merchProductFiles_merchProductPrintfilePlacements } from 'api/merch/types/GetMerchProduct';
import { PrintImage, PrintCoordinates } from '../MerchConstructor';
// Helpers
import {
  computePrintCanvasDimensions,
  computePrintfulDimensions,
  calculateAspectRatioFit,
} from '../../../helpers';
// UI
import { errorNotification } from 'ui/Notification';

const filters = fabric.Image.filters as any,
  createClass = fabric.util.createClass;

filters.SwapThreadColors = createClass(filters.BaseFilter, {
  type: 'SwapThreadColors',
  threadsColors: [],
  prevThreadsColors: [],
  applyTo2d: function (options: any) {
    const imageData = options.imageData,
      data = imageData.data,
      len = data.length,
      threadsColors = this.threadsColors || [];

    for (let i = 0; i < len; i += 4) {
      const r = data[i];
      const g = data[i + 1];
      const b = data[i + 2];

      let currentLength: number | null = null;
      let currentThread: number[] | null = null;

      for (const thread of threadsColors) {
        const [tr, tg, tb] = thread;

        const [diffR, diffG, diffB] = [tr - r, tg - g, tb - b];
        const lengthVector = Math.sqrt(
          diffR * diffR + diffG * diffG + diffB * diffB
        );

        if (lengthVector === 0) {
          currentThread = thread;
          break;
        }

        if (!currentLength || currentLength >= lengthVector) {
          currentLength = lengthVector;
          currentThread = thread;
        }
      }

      if (currentThread) {
        data[i] = currentThread[0];
        data[i + 1] = currentThread[1];
        data[i + 2] = currentThread[2];
      }
    }

    this.prevThreadsColors = threadsColors;
  },
  isNeutralState: function () {
    return (
      JSON.stringify(this.threadsColors) ===
      JSON.stringify(this.prevThreadsColors)
    );
  },
  toObject: function () {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      threadsColors: this.threadsColors,
      prevThreadsColors: this.prevThreadsColors,
    });
  },
});

filters.SwapThreadColors.fromObject = filters.BaseFilter.fromObject;

type PrintCanvasProps = {
  defaultTemplate?: GetPRProductByTypeV2_getPRProductByTypeV2_data_templates_templates | null;
  printPlacements?:
    | GetMerchProduct_getMerchProduct_merchProductFiles_merchProductPrintfilePlacements
    | null
    | PrintCoordinates;
  isConstructorMode: boolean;
  isEditMode: boolean;
  withLogoPlaceholder: boolean;
  printImage: PrintImage;
  onSetPrintCoordinates: (coordinates: PrintCoordinates) => void;
  onComputeCoordinates: (isLoading: boolean) => void;
  mockupSize?: GetPRProductByTypeV2_getPRProductByTypeV2_data_printfiles_printfiles;
  threadColorsOptions: number[][];
  backgroundImage: string | null;
  onMount: React.Dispatch<React.SetStateAction<boolean>>;
  isRashGuard: boolean;
  onObjectMoved: () => void;
};

const PrintCanvas = forwardRef<HTMLDivElement, PrintCanvasProps>(
  (
    {
      defaultTemplate,
      printPlacements,
      isConstructorMode,
      isEditMode,
      withLogoPlaceholder,
      printImage,
      onSetPrintCoordinates,
      onComputeCoordinates,
      mockupSize,
      threadColorsOptions,
      backgroundImage,
      onMount,
      isRashGuard,
      onObjectMoved,
    },
    ref
  ) => {
    const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
    const prevImageRef = useRef<null | string>(null);
    const [canvasPrintImage, setCanvasPrintImage] =
      useState<null | fabric.Image>(null);
    const isNewImage = prevImageRef.current !== printImage?.imageURL;
    const dimensions = useMemo(
      () => computePrintCanvasDimensions(ref, defaultTemplate),
      [ref, defaultTemplate]
    );

    const threadColors = useMemo(
      () => threadColorsOptions,
      // eslint-disable-next-line
      [threadColorsOptions.length]
    );

    useEffect(() => {
      if (canvas && backgroundImage) {
        fabric.Image.fromURL(
          backgroundImage,
          function (img) {
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
              scaleX: canvas.width && img.width ? canvas.width / img.width : 0,
              scaleY:
                canvas.height && img.height ? canvas.height / img.height : 0,
            });

            onMount(true);
          },
          {
            crossOrigin: 'anonymous',
          }
        );
      }
    }, [canvas, backgroundImage, onMount]);

    useEffect(() => {
      if (canvas && canvasPrintImage) {
        if (threadColors.length) {
          const swapThreadFilter = new filters.SwapThreadColors();

          canvasPrintImage.filters = [swapThreadFilter];
          swapThreadFilter.threadsColors = threadColors;
        } else if (canvas && canvasPrintImage) {
          canvasPrintImage.filters = [];
        }

        canvasPrintImage.applyFilters();
        canvas.fire('custom:imageFilterApplied', { target: canvasPrintImage });
        canvas.requestRenderAll();
      }
    }, [threadColors, canvasPrintImage, canvas]);

    useEffect(() => {
      if (canvasPrintImage && canvas) {
        canvasPrintImage.set({
          hasBorders: isConstructorMode,
          hasControls: isConstructorMode,
          lockMovementX: !isConstructorMode,
          lockMovementY: !isConstructorMode,
          evented: isConstructorMode,
          hoverCursor: isConstructorMode ? 'pointer' : 'default',
        });

        if (isConstructorMode) {
          canvas.setActiveObject(canvasPrintImage);
        }
        canvas.requestRenderAll();
      }
    }, [canvasPrintImage, canvas, isConstructorMode]);

    useEffect(() => {
      if (canvas && dimensions && mockupSize) {
        const setSafeAreaOpacity = (opacity: number) => {
          canvas.forEachObject(function (obj) {
            if (obj.type !== 'image') {
              obj.set('opacity', opacity);
            }
          });
        };

        const checkContainedImage = (fabricEvent: fabric.IEvent) => {
          const printImage = fabricEvent.target;
          const objects = canvas?.getObjects();
          const safeArea = objects?.find((item) => item.type === 'rect');

          if (printImage && safeArea) {
            const isContained = printImage.isContainedWithinObject(safeArea);

            if (!isContained && !isRashGuard) {
              notification.destroy();
              errorNotification('Logo is Outside of the Printing Bounds');
            }
          }
        };

        const updateCoordinates = (fabricEvent: fabric.IEvent) => {
          const printImage = fabricEvent.target;

          if (!printImage) {
            return;
          }

          onComputeCoordinates(true);

          fabricEvent.target?.clone((data: any) => {
            data.rotate(0);

            const printImageCoordinates = {
              angle: printImage?.angle || 0,
              top: data.top || printImage.top || 0,
              left: data.left || printImage.left || 0,
              scaleX: printImage?.scaleX || 0,
              scaleY: printImage?.scaleY || 0,
              width:
                Number(printImage?.width) * Number(printImage?.scaleX) || 0,
              height:
                Number(printImage?.height) * Number(printImage?.scaleY) || 0,
              rotatedLeft: printImage.left || 0,
              rotatedTop: printImage.top || 0,
            };

            const bgImage = canvas.backgroundImage || '';

            const { height } = calculateAspectRatioFit(
              Number(mockupSize?.width),
              Number(mockupSize?.height),
              Number(printImage.clipPath?.width),
              Number(printImage.clipPath?.height)
            );

            const multiplier = Number(mockupSize?.height) / height;

            // Remove backgroundImage to have proper export image
            canvas.backgroundImage = undefined;
            if (isRashGuard) {
              canvas.setOverlayImage('', canvas.renderAll.bind(canvas));
              canvas.overlayImage = undefined;
              canvas.renderAll.bind(canvas);
            }

            const dataURL = canvas.toDataURL({
              format: 'png',
              multiplier,
              enableRetinaScaling: false,
              left: Math.round(printImage.clipPath?.left || 0),
              top: Math.round(printImage.clipPath?.top || 0),
              width: Math.round(printImage.clipPath?.width || 0),
              height: Math.round(printImage.clipPath?.height || 0),
            });

            // Restore backgroundImage
            canvas.backgroundImage = bgImage;
            if (isRashGuard) {
              canvas.setOverlayImage(bgImage, canvas.renderAll.bind(canvas));
              canvas.setOverlayImage('', canvas.renderAll.bind(canvas));
            }

            const printfulDimensions = computePrintfulDimensions(
              dimensions,
              printImageCoordinates
            );

            const newPrintCoordinates = {
              ...printfulDimensions,
              pngFromCanvas: dataURL,
            };

            onSetPrintCoordinates(newPrintCoordinates);

            canvas.remove(data);
            canvas.renderAll();
            onComputeCoordinates(false);
          });
        };

        canvas.on('mouse:up', function (event) {
          setSafeAreaOpacity(0);
          checkContainedImage(event);
          updateCoordinates(event);
        });

        canvas.on('object:added', function (event) {
          // Update coordinates only for image objects
          if (event.target?.type === 'image') {
            checkContainedImage(event);
            updateCoordinates(event);
          }
        });

        const onObjectChange = () => {
          setSafeAreaOpacity(1);
          onObjectMoved();
        };

        canvas.on('custom:imageFilterApplied', function (event) {
          updateCoordinates(event);
        });

        canvas.on('object:moving', onObjectChange);
        canvas.on('object:scaling', onObjectChange);
        canvas.on('object:rotating', onObjectChange);
      }
    }, [
      canvas,
      dimensions,
      mockupSize,
      onSetPrintCoordinates,
      onComputeCoordinates,
      isRashGuard,
      onObjectMoved,
    ]);

    useEffect(() => {
      if (!canvas && defaultTemplate) {
        const canvasInstance = new fabric.Canvas('canvas', {
          selection: false,
        });

        const canvas2dBackend = new fabric.Canvas2dFilterBackend();

        fabric.filterBackend = canvas2dBackend;

        fabric.util.loadImage(
          backgroundImage || defaultTemplate.image_url || '',
          function (img) {
            const object = new fabric.Image(img);
            canvasInstance.setBackgroundImage(
              object,
              canvasInstance.renderAll.bind(canvasInstance),
              {
                scaleX:
                  canvasInstance.width && object.width
                    ? canvasInstance.width / object.width
                    : 0,
                scaleY:
                  canvasInstance.height && object.height
                    ? canvasInstance.height / object.height
                    : 0,
              }
            );
          }
        );

        setCanvas(canvasInstance);
      }
    }, [backgroundImage, canvas, defaultTemplate, isNewImage]);

    useEffect(() => {
      // this check is no longer valid due to constructor changes for edit
      // const shouldNotWaitPrintPlacements = isEditMode
      //   ? Boolean(isEditMode && printPlacements)
      //   : true;

      if (
        canvas &&
        (isConstructorMode || isEditMode) &&
        printImage?.imageURL &&
        isNewImage &&
        dimensions?.templateHeight &&
        dimensions.templateWidth &&
        defaultTemplate
        // && shouldNotWaitPrintPlacements
      ) {
        const {
          printAreaTop,
          printAreaLeft,
          printAreaWidth,
          printAreaHeight,
          scaleFactorX,
          scaleFactorY,
        } = dimensions;

        canvas.forEachObject(function (obj) {
          canvas.remove(obj);
        });

        const safeAreaCoordinates = {
          top: printAreaTop,
          left: printAreaLeft,
          width: printAreaWidth,
          height: printAreaHeight,
        };

        const safeArea = new fabric.Rect({
          ...safeAreaCoordinates,
          fill: 'rgba(0, 0, 0, 0.1)',
          hasBorders: true,
          hasControls: false,
          lockMovementX: true,
          lockMovementY: true,
          evented: false,
          hoverCursor: 'default',
          stroke: '#77B5E7',
          opacity: 0,
        });

        const safeAreaClipPath = new fabric.Rect({
          ...safeAreaCoordinates,
          absolutePositioned: true,
          strokeWidth: 0,
        });

        canvas.add(safeArea);

        fabric.Image.fromURL(
          `${printImage.imageURL}`,
          function (img) {
            img.clipPath = safeAreaClipPath;

            img.setControlsVisibility({
              mb: false,
              ml: false,
              mr: false,
              mt: false,
            });

            if (printPlacements && !prevImageRef.current) {
              const topPosition = printPlacements.angle
                ? printAreaTop +
                  Number(printPlacements.rotatedTop) * (scaleFactorY || 1)
                : printAreaTop +
                  Number(printPlacements.top) * (scaleFactorY || 1);
              const leftPosition = printPlacements.angle
                ? printAreaLeft +
                  Number(printPlacements.rotatedLeft) * (scaleFactorX || 1)
                : printAreaLeft +
                  Number(printPlacements.left) * (scaleFactorX || 1);

              img.set({
                top: topPosition,
                left: leftPosition,
                borderColor: '#4394D7',
                cornerColor: 'white',
                cornerSize: 7,
                transparentCorners: false,
                scaleX: printPlacements.scaleX || 0,
                scaleY: printPlacements.scaleY || 0,
                angle: printPlacements.angle || 0,
              });
            } else {
              const printAreaLowestValue =
                printAreaHeight < printAreaWidth
                  ? printAreaHeight
                  : printAreaWidth;
              const padding = 2;

              img.scaleToWidth(printAreaLowestValue - padding);

              const { height, width } = img.getBoundingRect();

              const topPosition =
                printAreaTop + printAreaHeight / 2 - height / 2;
              const leftPosition =
                printAreaLeft + printAreaWidth / 2 - width / 2;

              img.set({
                top: topPosition,
                left: leftPosition,
                borderColor: '#4394D7',
                cornerColor: 'white',
                cornerSize: 7,
                transparentCorners: false,
              });
            }

            // Block any events for the 'preview' mode and update prevImageRef for the 'constructor' mode
            if (!isConstructorMode && isEditMode) {
              img.set({
                hasBorders: false,
                hasControls: false,
                lockMovementX: true,
                lockMovementY: true,
                evented: false,
                hoverCursor: 'default',
              });
            } else {
              prevImageRef.current = printImage.imageURL;
            }

            setCanvasPrintImage(img);
            canvas.add(img);
            canvas.setActiveObject(img);
          },
          {
            crossOrigin: 'anonymous',
          }
        );
      }
      // eslint-disable-next-line
    }, [
      canvas,
      dimensions,
      defaultTemplate,
      printPlacements,
      isConstructorMode,
      isEditMode,
      // eslint-disable-next-line
      printImage?.imageURL,
      isNewImage,
    ]);

    useEffect(() => {
      if (
        canvas &&
        !isConstructorMode &&
        withLogoPlaceholder &&
        dimensions?.templateHeight &&
        dimensions.templateWidth &&
        defaultTemplate &&
        !canvasPrintImage
      ) {
        const { printAreaTop, printAreaLeft, printAreaWidth, printAreaHeight } =
          dimensions;

        canvas.forEachObject(function (obj) {
          canvas.remove(obj);
        });

        fabric.Image.fromURL(
          '/images/manage-merch/logo-place.svg',
          function (img) {
            const printAreaLowestValue =
              printAreaHeight < printAreaWidth
                ? printAreaHeight
                : printAreaWidth;

            img.scaleToWidth(printAreaLowestValue);
            img.scaleToHeight(printAreaLowestValue);

            const topPosition =
              printAreaTop + printAreaHeight / 2 - printAreaLowestValue / 2;
            const leftPosition =
              printAreaLeft + printAreaWidth / 2 - printAreaLowestValue / 2;

            img.set({
              top: topPosition,
              left: leftPosition,
              hasBorders: false,
              hasControls: false,
              lockMovementX: true,
              lockMovementY: true,
              evented: false,
              hoverCursor: 'default',
            });

            canvas.add(img);
          }
        );
      }
    }, [
      canvas,
      dimensions,
      canvasPrintImage,
      withLogoPlaceholder,
      defaultTemplate,
      isConstructorMode,
    ]);

    return (
      <canvas
        id="canvas"
        width={dimensions?.templateWidth || 516}
        height={dimensions?.templateHeight || 522}
      />
    );
  }
);

PrintCanvas.displayName = 'PrintCanvas';

export default PrintCanvas;
