import React from 'react';
import { useApolloClient } from '@apollo/client';
// Api
import { GET_ALL_AMAS } from 'api/ama/queries';
// Types
import {
  GetAllAmas,
  GetAllAmasVariables,
  GetAllAmas_amas_entities,
} from 'api/ama/types/GetAllAmas';
import { LoadMore, OptionsItem } from './SectionItem';
import { HomePageSectionItemProps } from 'pages/HomePageManagement';
import { AmaOrderBy, AmaStatus, SortDirection } from 'api/graphql-global-types';
import { GetHomePageData_getHomePageData_amas } from 'api/homePageSetup/types/GetHomePageData';
// Helpers
import { getStoreName } from 'helpers/utils';
// Components
import SectionItem from './SectionItem';

export const getAmaLabel = (
  ama: GetHomePageData_getHomePageData_amas | GetAllAmas_amas_entities
): string => {
  return getStoreName(ama.store);
};

const PER_PAGE = 10;

const SetupAmaSectionItem: React.FC<HomePageSectionItemProps> = ({
  selectedItems,
  onItemChange,
  item,
  itemIndex,
}) => {
  const client = useApolloClient();

  const loadMore: LoadMore = async (offset, search) => {
    const options: OptionsItem[] = [];

    const { data } = await client.query<GetAllAmas, GetAllAmasVariables>({
      query: GET_ALL_AMAS,
      variables: {
        input: {
          ...(search
            ? {
                storeName: search,
              }
            : {}),
          orderBy: AmaOrderBy.StoreName,
          direction: SortDirection.ASC,
          status: AmaStatus.Active,
          limit: PER_PAGE,
          offset,
        },
      },
      fetchPolicy: 'network-only',
    });

    const total = data?.amas?.total || 0;

    data?.amas?.entities?.forEach((item) => {
      options.push({
        value: item.id,
        label: getAmaLabel(item),
      });
    });

    return { total, options };
  };

  return (
    <SectionItem
      selectPlaceholder="Pick the Store"
      selectedItems={selectedItems}
      onItemsChange={onItemChange}
      item={item}
      itemIndex={itemIndex}
      paginationSize={PER_PAGE}
      loadMore={loadMore}
    />
  );
};

export default SetupAmaSectionItem;
