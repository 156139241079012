import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { Button, Modal, Space } from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';
// Types
import { Streams_adminStreams_entities } from 'api/streams/types/Streams';
// UI
import Table from 'ui/Table';
import { successNotification } from 'ui/Notification';

type AttendeesColumnProps = {
  data: Streams_adminStreams_entities;
};

const AttendeesColumn = ({
  data,
}: AttendeesColumnProps): JSX.Element | null => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const participantsList = data.participants || [];

  const handleShowModal = () => {
    setModalVisible(true);
  };

  const handleHideModal = () => {
    setModalVisible(false);
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      align: 'center' as const,
      width: 50,
      render: (participantId: string) => participantId,
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      width: 150,
      render: (firstName: any) => firstName,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      width: 150,
      render: (lastName: any) => lastName,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 150,
      render: (participantEmail: string) => participantEmail,
    },
  ];

  const onCSVButtonClick = () => {
    successNotification('File downloading');
  };

  return (
    <>
      <Button
        type="link"
        onClick={handleShowModal}
        style={{ cursor: 'pointer' }}
        icon={<UsergroupAddOutlined />}
      />

      <Modal
        title="Attendees"
        visible={isModalVisible}
        footer={null}
        onCancel={handleHideModal}
        width={900}
      >
        <Table columns={columns} data={participantsList} />

        <Space
          direction="horizontal"
          align="baseline"
          style={{ marginTop: '20px' }}
        >
          <Button type="primary">
            <CSVLink
              filename="Stream participants"
              data={participantsList}
              className="btn btn-primary"
              onClick={onCSVButtonClick}
            >
              Export to CSV
            </CSVLink>
          </Button>
        </Space>
      </Modal>
    </>
  );
};

export default AttendeesColumn;
