import React from 'react';
import { Button, Input } from 'antd';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
// Styles
import styles from './CopyLink.module.scss';

type CopyLinkProps = {
  name: string;
  label: string;
  link: string;
};

const CopyLink = ({ name, label, link }: CopyLinkProps): JSX.Element => {
  const handleCopyLinkButtonClick = async () => {
    try {
      await navigator.clipboard.writeText(link);

      successNotification('Link copied to clipboard');
    } catch (error) {
      errorNotification('Could not copy text');
    }
  };

  return (
    <div className={styles.root}>
      <span className={styles.label}> {label}:</span>

      <div className={styles.inputAndButtonWrapper}>
        <Input
          name={name}
          style={{ width: '100%' }}
          readOnly
          onClick={handleCopyLinkButtonClick}
          value={link}
          aria-label={label}
        />

        <Button
          type="primary"
          onClick={handleCopyLinkButtonClick}
          className={styles.copyLinkButton}
        >
          Copy
        </Button>
      </div>
    </div>
  );
};

export default CopyLink;
