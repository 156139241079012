import React, { useState, useEffect, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Space, Button, message, Spin, Card } from 'antd';
import moment from 'moment';
// Api
import { GET_HOME_PAGE_DATA } from 'api/homePageSetup/querries';
import { SETUP_HOME_PAGE_DATA } from 'api/homePageSetup/mutations';
// Hooks
import { useHasMounted } from 'hooks';
// Types
import {
  AthleteStoryInputType,
  BannerInputType,
  BannerMediaType,
  HomePageEntityNumber,
  HomePageEntityString,
} from 'api/graphql-global-types';
import {
  GetHomePageData,
  GetHomePageData_getHomePageData_amas,
  GetHomePageData_getHomePageData_merch,
  GetHomePageData_getHomePageData_stores,
  GetHomePageData_getHomePageData_mainBanners,
  GetHomePageData_getHomePageData_watchStreams,
  GetHomePageData_getHomePageData_ctaBanners,
  GetHomePageData_getHomePageData_athleteStories,
} from 'api/homePageSetup/types/GetHomePageData';
import { SetupHomePageData } from 'api/homePageSetup/types/SetupHomePageData';
import { OptionsItem, OnItemChange } from './components/SectionItem';
// Helpers
import { getStoreName } from 'helpers/utils';
// Components
import SetupBanners from './components/SetupBanners/SetupBanners';
import SetupStoreSectionItem, {
  getStoreLabel,
} from './components/SetupStoreSectionItem';
import SetupAmaSectionItem, {
  getAmaLabel,
} from './components/SetupAmaSectionItem';
import SetupMerch from './components/SetupMerch/SetupMerch';
import SetupStreamSectionItem, {
  getStreamLabel,
} from './components/SetupStreamSectionItem';
import SetupCta from './components/SetupCta/SetupCta';
import SetupStories from './components/SetupStories/SetupStories';

export type HomePageSectionItemProps = {
  selectedItems: OptionsItem[];
  onItemChange: OnItemChange;
  item: OptionsItem;
  itemIndex: number;
};

export type HomePageSectionWithSelectProps = {
  selectedItems: OptionsItem[];
  onItemChange: OnItemChange;
  item: OptionsItem;
  itemIndex: number;
};

export type MerchOption = {
  store: OptionsItem;
  merch: OptionsItem;
};

export type BannersOption = {
  image: string;
  video: string;
  mediaType: BannerMediaType.image | BannerMediaType.video;
  mediaId: string;
  title?: string | null;
  description?: string | null;
  buttonContent?: string | null;
  link?: string | null;
  shouldLinkRedirect?: boolean | null;
};

export type CtaOption = {
  image: string;
  mediaId: string;
  title: string | null;
  content: string | null;
  buttonContent: string | null;
  link: string | null;
  shouldLinkRedirect: boolean | null;
  isBannerVisible: boolean | null;
  linkContent: string | null;
  buttonLink: string | null;
};

export type StoryOption = {
  title: string;
  description: string;
  link: string;
  store: OptionsItem;
  image: File | null;
  imageURL: string;
  id: string;
  publishedDate: moment.Moment | null;
};

const MAX_STREAMS = 3;

const HomePageManagement: React.FC = () => {
  const hasMounted = useHasMounted();

  const { data, loading: homePageDataLoading } = useQuery<GetHomePageData>(
    GET_HOME_PAGE_DATA,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const [setupHomePageData, { loading }] = useMutation<SetupHomePageData>(
    // TODO: rollback when HomePage feature will be ready
    // , SetupHomePageDataVariables
    SETUP_HOME_PAGE_DATA
  );

  const emptySelectItem: OptionsItem = {
    value: '',
    label: '',
  };

  const [selectedBanners, setSelectedBanners] = useState<BannersOption[]>([
    {
      image: '',
      video: '',
      mediaType: BannerMediaType.image,
      mediaId: '',
      title: '',
      description: '',
      buttonContent: '',
      link: '',
      shouldLinkRedirect: false,
    },
  ]);

  const initialCtaValues = {
    image: '',
    mediaId: '',
    title: '',
    content: '',
    buttonContent: '',
    link: '',
    shouldLinkRedirect: false,
    isBannerVisible: false,
    linkContent: '',
    buttonLink: '',
  };

  const [selectedCta, setSelectedCta] = useState<CtaOption[]>([
    initialCtaValues,
    initialCtaValues,
  ]);
  const [selectedStories, setSelectedStories] = useState<StoryOption[]>([]);
  const [selectedStores, setSelectedStores] = useState<OptionsItem[]>(
    new Array(12).fill(emptySelectItem)
  );
  const [selectedAmas, setSelectedAmas] = useState<OptionsItem[]>(
    new Array(12).fill(emptySelectItem)
  );
  const [selectedMerch, setSelectedMerch] = useState<MerchOption[]>(
    new Array(12).fill({
      store: emptySelectItem,
      merch: emptySelectItem,
    })
  );
  const [selectedStreams, setSelectedStreams] = useState<OptionsItem[]>(
    new Array(MAX_STREAMS).fill(emptySelectItem)
  );

  const setDefaultValues = (): void => {
    const banners: GetHomePageData_getHomePageData_mainBanners[] =
      data?.getHomePageData?.mainBanners || [];
    const cta: GetHomePageData_getHomePageData_ctaBanners[] =
      data?.getHomePageData?.ctaBanners || [];
    const athleteStories: GetHomePageData_getHomePageData_athleteStories[] =
      data?.getHomePageData?.athleteStories || [];
    const stores: GetHomePageData_getHomePageData_stores[] =
      data?.getHomePageData?.stores || [];
    const amas: GetHomePageData_getHomePageData_amas[] =
      data?.getHomePageData?.amas || [];
    const merch: GetHomePageData_getHomePageData_merch[] =
      data?.getHomePageData?.merch || [];
    const streams: GetHomePageData_getHomePageData_watchStreams[] =
      data?.getHomePageData?.watchStreams || [];

    const getDefaultBannersValues = (
      elements: GetHomePageData_getHomePageData_mainBanners[]
    ) => {
      if (elements?.length) {
        const defaultValues: BannersOption[] = [];
        elements.forEach((item) => {
          defaultValues.push({
            image: item.image?.url || '',
            video: item.video?.url || '',
            mediaId: item.image?.id || item.video?.id || '',
            mediaType: item.image?.id
              ? BannerMediaType.image
              : BannerMediaType.video,
            title: item.title,
            description: item.content,
            buttonContent: item.buttonContent,
            link: item.link,
            shouldLinkRedirect: item.shouldLinkRedirect,
          });
        });
        return defaultValues;
      }
      return new Array(1).fill({
        image: '',
        mediaId: '',
        title: '',
        description: '',
        buttonContent: '',
        link: '',
        shouldLinkRedirect: null,
      });
    };

    if (banners?.length) {
      setSelectedBanners([...getDefaultBannersValues(banners)]);
    }

    const getDefaultCtaValues = (
      elements: GetHomePageData_getHomePageData_ctaBanners[]
    ) => {
      if (elements?.length) {
        const defaultValues: CtaOption[] = [];
        elements.forEach((item) => {
          defaultValues.push({
            image: item.image?.url || '',
            mediaId: item.image?.id || '',
            title: item.title,
            content: item.content,
            buttonContent: item.buttonContent,
            link: item.link,
            shouldLinkRedirect: item.shouldLinkRedirect,
            isBannerVisible: item.isBannerVisible,
            buttonLink: item.buttonLink,
            linkContent: item.linkContent,
          });
        });

        return defaultValues;
      }

      return selectedCta;
    };

    if (cta?.length) {
      setSelectedCta([...getDefaultCtaValues(cta)]);
    }

    const getDefaultStoriesValues = (
      elements: GetHomePageData_getHomePageData_athleteStories[]
    ) => {
      if (elements?.length) {
        const defaultValues: StoryOption[] = [];
        elements.forEach((item) => {
          defaultValues.push({
            id: item.id,
            image: null,
            title: item.title,
            description: item.description,
            link: item.link,
            imageURL: item.imageURL || '',
            publishedDate: moment(item.publishedDate) || null,
            store: {
              label: item.store.storeDetails?.storeName || '',
              value: item.store.id,
            },
          });
        });

        return defaultValues;
      }

      return selectedStories;
    };

    if (athleteStories?.length) {
      setSelectedStories([...getDefaultStoriesValues(athleteStories)]);
    }

    if (stores?.length) {
      const newStores: OptionsItem[] = new Array(12).fill(emptySelectItem);

      stores.forEach((store) => {
        if (store.orderIndex !== null) {
          newStores[store.orderIndex] = {
            value: store.id,
            label: getStoreLabel(store),
          };
        }
      });

      setSelectedStores(newStores);
    }

    if (amas?.length) {
      const newAmas: OptionsItem[] = new Array(12).fill(emptySelectItem);

      amas.forEach((ama) => {
        if (ama.orderIndex !== null) {
          newAmas[ama.orderIndex] = {
            value: ama.id,
            label: getAmaLabel(ama),
          };
        }
      });

      setSelectedAmas(newAmas);
    }

    if (merch?.length) {
      const newMerchItems: MerchOption[] = new Array(12).fill({
        store: emptySelectItem,
        merch: emptySelectItem,
      });

      merch.forEach((merchItem) => {
        if (merchItem.orderIndex !== null) {
          newMerchItems[merchItem.orderIndex] = {
            store: {
              value: merchItem?.store?.id || '',
              label: getStoreName(merchItem.store),
            },
            merch: {
              value: String(merchItem.id),
              label: merchItem.title,
            },
          };
        }
      });

      setSelectedMerch(newMerchItems);
    }

    if (streams?.length) {
      const newStreams: OptionsItem[] = new Array(MAX_STREAMS).fill(
        emptySelectItem
      );

      /**
       * Keep backward compatibility after streams count reduce
       * by setting initial values by "index" instead of "orderIndex"
       */
      const sortedStreamsByOrderIndex = streams.slice().sort((a, b) => {
        if (a.orderIndex === 0) {
          return -1;
        }
        if (a.orderIndex && b.orderIndex) {
          return a.orderIndex - b.orderIndex;
        }

        return 1;
      });

      sortedStreamsByOrderIndex.forEach((stream, index) => {
        newStreams[index] = {
          value: stream.id,
          label: getStreamLabel(stream),
        };
      });

      setSelectedStreams(newStreams);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedSetDefaultValues = useCallback(setDefaultValues, [
    data?.getHomePageData,
  ]);

  useEffect(() => {
    if (!homePageDataLoading) {
      memoizedSetDefaultValues();
    }
  }, [memoizedSetDefaultValues, homePageDataLoading]);

  const onSaveSettings = async () => {
    type DataItem = {
      id: string | number;
      orderIndex: number;
    };
    const prepareData = (list: string[], isMerch?: boolean): DataItem[] => {
      const preparedList: DataItem[] = [];

      list.forEach((id, index) => {
        if (id.length) {
          preparedList.push({
            id: isMerch ? Number(id) : String(id),
            orderIndex: index,
          });
        }
      });

      return preparedList;
    };

    const prepareBannersData = (list: BannersOption[]) => {
      const data: BannerInputType[] = [];
      list.forEach((item, index) => {
        if (!item.mediaId) {
          return;
        }
        data.push({
          mediaId: item.mediaId,
          orderIndex: index,
          title: item.title,
          content: item.description,
          buttonContent: item.buttonContent,
          link: item.link,
          shouldLinkRedirect: item.shouldLinkRedirect,
          mediaType: item.mediaType,
        });
      });
      return data;
    };

    const prepareCtaData = (list: CtaOption[]) => {
      const data: BannerInputType[] = [];
      list.forEach((item, index) => {
        if (!item.mediaId) {
          return;
        }
        data.push({
          mediaId: item.mediaId,
          orderIndex: index,
          title: item.title,
          content: item.content,
          buttonContent: item.buttonContent,
          link: item.link,
          shouldLinkRedirect: item.shouldLinkRedirect,
          isBannerVisible: item.isBannerVisible,
          linkContent: item.linkContent,
          buttonLink: item.buttonLink,
        });
      });

      return data;
    };

    const prepareStoriesData = (list: StoryOption[]) => {
      const data: AthleteStoryInputType[] = [];
      list.forEach((item, index) => {
        data.push({
          ...(item.image ? { image: item.image } : null),
          title: item.title,
          description: item.description,
          link: item.link,
          storeId: item.store.value,
          orderIndex: index,
          publishedDate: moment(item.publishedDate).toDate(),
          id: item.id,
        });
      });

      return data;
    };

    try {
      await setupHomePageData({
        variables: {
          input: {
            stores: prepareData(
              selectedStores.map(({ value }) => value)
            ) as HomePageEntityString[],
            amas: prepareData(
              selectedAmas.map(({ value }) => value)
            ) as HomePageEntityString[],
            merch: prepareData(
              selectedMerch.map(({ merch }) => merch.value),
              true
            ) as HomePageEntityNumber[],
            watchStreams: prepareData(
              selectedStreams.map(({ value }) => value)
            ) as HomePageEntityString[],
            mainBanners: prepareBannersData(
              selectedBanners
            ) as BannerInputType[],
            ctaBanners: prepareCtaData(selectedCta) as BannerInputType[],
            athleteStories: prepareStoriesData(
              selectedStories
            ) as AthleteStoryInputType[],
          },
        },
      });
      message.success(
        'The home page settings has been updated successfully',
        15
      );
    } catch (error) {
      message.error((error as Error)?.message, 15);
    }
  };

  const onCancel = () => {
    setDefaultValues();
  };

  if (!hasMounted) {
    return null;
  }

  return !data && homePageDataLoading ? (
    <Spin spinning={homePageDataLoading}>
      <div className="spin" />
    </Spin>
  ) : (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <SetupBanners
          selectedItems={selectedBanners}
          onInputChange={setSelectedBanners}
        />

        <SetupCta selectedItems={selectedCta} onInputChange={setSelectedCta} />

        <SetupStories
          selectedItems={selectedStories}
          onInputChange={setSelectedStories}
        />

        <Card title="Setup Athletes" style={{ width: '100%' }}>
          {selectedStores.map((item: OptionsItem, index: number) => (
            <SetupStoreSectionItem
              key={index}
              item={item}
              itemIndex={index}
              selectedItems={selectedStores}
              onItemChange={setSelectedStores}
            />
          ))}
        </Card>

        <Card title="Setup AMA" style={{ width: '100%' }}>
          {selectedAmas.map((item: OptionsItem, index: number) => (
            <SetupAmaSectionItem
              key={index}
              item={item}
              itemIndex={index}
              selectedItems={selectedAmas}
              onItemChange={setSelectedAmas}
            />
          ))}
        </Card>

        <SetupMerch
          selectedItems={selectedMerch}
          onItemChange={setSelectedMerch}
        />

        <Card title="Setup Watchstreams" style={{ width: '100%' }}>
          {selectedStreams.map((item: OptionsItem, index: number) => (
            <SetupStreamSectionItem
              key={index}
              item={item}
              itemIndex={index}
              selectedItems={selectedStreams}
              onItemChange={setSelectedStreams}
            />
          ))}
        </Card>
      </Space>

      <Space
        direction="horizontal"
        align="end"
        style={{
          width: '100%',
          padding: '24px 0',
          justifyContent: 'flex-end',
        }}
      >
        <Button danger type="primary" onClick={onCancel} disabled={loading}>
          Cancel
        </Button>

        <Button
          type="primary"
          onClick={onSaveSettings}
          loading={loading}
          disabled={loading}
        >
          Save
        </Button>
      </Space>
    </>
  );
};

export default HomePageManagement;
