import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// Constants
import { CUSTOMERS_DEFAULT_ROUTE } from 'App';

export const useDefaultCustomerRedirectOnEmptyId = (): void => {
  const history = useHistory();
  const { customerId } = useParams<{ customerId: string | undefined }>();

  useEffect(() => {
    if (!customerId) {
      history.push(CUSTOMERS_DEFAULT_ROUTE);
    }
  }, [customerId, history]);
};
