import React, { FC } from 'react';
import { Button, Tag } from 'antd';
import { ShareAltOutlined, DeleteOutlined } from '@ant-design/icons';
// Helpers
import { tagStyles } from './helpers';
// Styles
import styles from './TagWithButton.module.scss';

interface TagWithButtonProps {
  url: string;
  onRemoveUrl: (url: string) => void;
  onCopyLink: (url: string) => void;
}

export const TagWithButton: FC<TagWithButtonProps> = ({
  url,
  onRemoveUrl,
  onCopyLink,
}) => {
  return (
    <Tag style={tagStyles}>
      <div className={styles.urlText}>{url}</div>

      <Button
        type="link"
        icon={<DeleteOutlined />}
        onClick={() => onRemoveUrl(url)}
      />

      <Button
        type="link"
        icon={<ShareAltOutlined />}
        onClick={() => onCopyLink(url)}
      />
    </Tag>
  );
};

export default TagWithButton;
