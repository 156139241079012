import React, { useState } from 'react';
import { Modal, Typography } from 'antd';
import { useQuery } from '@apollo/client';
// Api
import { GET_VIDEO_EDIT_REQUESTS } from 'api/videoLab/queries';
// Types
import {
  GetVideoEditRequests,
  GetVideoEditRequestsVariables,
} from 'api/videoLab/types/GetVideoEditRequests';
import {
  SortDirection,
  VideoEditRequestsOrderBy,
} from 'api/graphql-global-types';
// Helpers
import {
  VideoEditingRequestsFormattedData,
  getVideoRequestsFormattedData,
} from 'helpers/videoEditingRequest';
// UI
import Table, { SortedInfo, TableFilter } from 'ui/Table';

const { Text } = Typography;

type RequestsProps = {
  videoEditorId: string;
  videoEditorName: string;
  show: boolean;
  onClose: () => void;
};

export enum VideoEditingRequestStatus {
  Accepted = 'Accepted',
  Archived = 'Archived',
  AutoAccepted = 'AutoAccepted',
  AwaitingApproval = 'AwaitingApproval',
  InProgress = 'InProgress',
  OnReview = 'OnReview',
  Rejected = 'Rejected',
  Todo = 'Todo',
}

const RequestStatusTitle = {
  [VideoEditingRequestStatus.Accepted]: 'Accepted',
  [VideoEditingRequestStatus.AutoAccepted]: 'Auto-Accepted',
  [VideoEditingRequestStatus.Archived]: 'Archived',
  [VideoEditingRequestStatus.AwaitingApproval]: 'Awaiting approval',
  [VideoEditingRequestStatus.InProgress]: 'In progress',
  [VideoEditingRequestStatus.OnReview]: 'On review',
  [VideoEditingRequestStatus.Rejected]: 'Rejected',
  [VideoEditingRequestStatus.Todo]: 'Todo',
};

const Requests: React.FC<RequestsProps> = ({
  show,
  onClose,
  videoEditorId,
  videoEditorName,
}) => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<VideoEditRequestsOrderBy>>({
    order: SortDirection.DESC,
    key: VideoEditRequestsOrderBy.createdAt,
  });
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const getVideoRequestsInput = () => {
    const input: any = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      videoEditorIds: [videoEditorId || ''],
    };

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;
    });
    return {
      input,
    };
  };

  const { data } = useQuery<
    GetVideoEditRequests,
    GetVideoEditRequestsVariables
  >(GET_VIDEO_EDIT_REQUESTS, {
    variables: {
      ...getVideoRequestsInput(),
    },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    {
      title: 'Request id',
      dataIndex: 'id',
      align: 'left' as const,
      width: 120,
    },
    {
      title: 'Store Name',
      dataIndex: 'storeName',
      key: 'storeName',
      align: 'left' as const,
      width: 100,
      withSearch: true,
    },
    {
      title: 'Request status',
      dataIndex: 'status',
      align: 'left' as const,
      width: 180,
      render: function RequestsStatus(status: VideoEditingRequestStatus) {
        return <Text>{RequestStatusTitle?.[status] || status}</Text>;
      },
    },
  ];

  return (
    <Modal
      title={
        <>
          <strong>{videoEditorName}</strong> video editor requests
        </>
      }
      visible={show}
      footer={null}
      onCancel={onClose}
      width="70vw"
    >
      <Table<VideoEditingRequestsFormattedData, VideoEditRequestsOrderBy>
        columns={columns}
        data={getVideoRequestsFormattedData(data)}
        total={data?.getVideoEditRequests?.total}
        defaultPageSize={pageSize}
        defaultCurrent={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />
    </Modal>
  );
};

export default Requests;
