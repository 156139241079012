import React from 'react';
import { Tooltip } from 'antd';

type StreamViewsColumnProps = {
  views: {
    liveViewCount: number;
    endedViewCount: number;
  } | null;
};

const StreamViewsTooltip = ({ views }: StreamViewsColumnProps): JSX.Element => {
  return (
    <>
      {views ? (
        <Tooltip
          title={`Live: ${views.liveViewCount}, Ended: ${views.endedViewCount}`}
        >
          {views.liveViewCount + views.endedViewCount}
        </Tooltip>
      ) : (
        0
      )}
    </>
  );
};

export default StreamViewsTooltip;
