import { gql } from '@apollo/client';

export const SET_SCHEDULE = gql`
  mutation SetSchedule($input: SetScheduleInput!) {
    setSchedule(input: $input) {
      id
    }
  }
`;

export const SET_MY_SCHEDULE = gql`
  mutation SetMySchedule($input: SetMyScheduleInput!) {
    setMySchedule(input: $input) {
      id
    }
  }
`;
