import React from 'react';
import { Space } from 'antd';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
// Types
import { GroupBase, SingleValue } from 'react-select';

export interface OptionsItem {
  value: string;
  label: string;
}

export type OnItemChange =
  | React.Dispatch<React.SetStateAction<OptionsItem[]>>
  | ((item: OptionsItem[]) => void);

export type OnInputChange = React.Dispatch<React.SetStateAction<string[]>>;

export type LoadMoreResponse = {
  total: number;
  options: OptionsItem[];
};

export type LoadMore = (
  offset: number,
  search?: string
) => Promise<LoadMoreResponse>;

type SectionItemBaseProps = {
  item: OptionsItem;
  selectPlaceholder: string;
  paginationSize: number;
  loadMore: LoadMore;
  setClearCachedOptions?: React.Dispatch<React.SetStateAction<boolean>>;
  cacheUniqs?: [string, boolean];
  isDisabled?: boolean;
};

type SectionItemProps =
  | ({
      itemIndex?: never;
      selectedItems?: never;
      onItemsChange?: never;
      onItemChange?: (item: OptionsItem) => void;
    } & SectionItemBaseProps)
  | ({
      itemIndex?: number;
      selectedItems?: OptionsItem[];
      onItemsChange?: OnItemChange;
      onItemChange?: never;
    } & SectionItemBaseProps);

const SectionItem: React.FC<SectionItemProps> = ({
  itemIndex,
  selectedItems,
  onItemsChange,
  onItemChange,
  item,
  selectPlaceholder,
  paginationSize,
  loadMore,
  setClearCachedOptions,
  cacheUniqs,
  isDisabled,
}) => {
  const onSelect = (item: SingleValue<OptionsItem>) => {
    if (onItemsChange && selectedItems && (itemIndex || itemIndex === 0)) {
      onItemsChange([
        ...selectedItems.slice(0, itemIndex),
        {
          value: item?.value || '',
          label: item?.label || '',
        },
        ...selectedItems.slice(itemIndex + 1),
      ]);
    } else if (onItemChange) {
      onItemChange({
        value: item?.value || '',
        label: item?.label || '',
      });
    }
  };

  const loadOptions: LoadOptions<
    OptionsItem,
    GroupBase<OptionsItem>,
    any
  > = async (search, prevOptions) => {
    let hasMore = false;
    const offset = prevOptions.length < paginationSize ? 0 : prevOptions.length;
    let options: OptionsItem[] = [];

    if (setClearCachedOptions) {
      setClearCachedOptions(true);
    }

    await loadMore(offset, search)
      .then(({ total, options: newOptions }) => {
        options = newOptions;
        hasMore = offset < total && !(total < paginationSize);
      })
      .catch(() => {
        hasMore = false;
      });

    return { options, hasMore };
  };

  return (
    <Space style={{ width: '100%', marginBottom: 14 }} direction="vertical">
      <AsyncPaginate<OptionsItem, GroupBase<OptionsItem>, any>
        value={item?.value?.length ? item : null}
        isClearable
        placeholder={selectPlaceholder}
        onChange={onSelect}
        loadOptions={loadOptions}
        cacheUniqs={cacheUniqs}
        isDisabled={isDisabled}
        styles={{
          placeholder: (provided) => ({
            ...provided,
            color: '#cccccc',
          }),
        }}
      />
    </Space>
  );
};

export default SectionItem;
