import { gql } from '@apollo/client';

export const CREATE_IMAGES_PRESIGNED_URL = gql`
  mutation CreateArticlePresignedUrl($input: CreateArticlePresignedUrlInput!) {
    createArticlePresignedUrl(input: $input) {
      key
      url
      fields
    }
  }
`;

export const CREATE_ARTICLE = gql`
  mutation CreateArticle($input: CreateArticleInput!) {
    createArticle(input: $input) {
      id
    }
  }
`;

export const UPDATE_ARTICLE = gql`
  mutation UpdateArticle($input: UpdateArticleInput!) {
    updateArticle(input: $input) {
      id
    }
  }
`;
