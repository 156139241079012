import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Typography, Spin, Image, Space, Button } from 'antd';
import { useQuery } from '@apollo/client';
// Hooks
import { useAppContext } from 'hooks';
// Constants
import { FALLBACK_IMAGE } from 'constants/global';
// Api
import { DESIGN_REQUESTS } from 'api/designLab/queries';
import {
  ADMIN_EDIT_MERCH_DESIGN,
  ADMIN_EDIT_MERCH_DESIGN_WITH_LOGO,
  CREATE_PRESIGNED_URL_FOR_SOCIAL_PACK_REQUEST,
} from 'api/designLab/mutations';
// Types
import { DesignRequestType, UserRole } from 'api/graphql-global-types';
import {
  AdminEditMerchDesign,
  AdminEditMerchDesignVariables,
} from 'api/designLab/types/AdminEditMerchDesign';
import {
  AdminEditMerchDesignWithLogo,
  AdminEditMerchDesignWithLogoVariables,
} from 'api/designLab/types/AdminEditMerchDesignWithLogo';
import {
  DesignRequests,
  DesignRequestsVariables,
} from 'api/designLab/types/DesignRequests';
import {
  CreatePresignedUrlForSocialPack,
  CreatePresignedUrlForSocialPackVariables,
} from 'api/designLab/types/CreatePresignedUrlForSocialPack';
// Helpers
import { uploadToS3 } from 'helpers/createPresignedUrl';
import { getDesignRequestsFormattedData } from 'helpers/designRequests';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import DesignRequestsActions from 'components/common/DesignRequestsTable/components/DesignRequestsActions/DesignRequestsActions';
import SocialMediaPackItem, {
  SocialMediaPackItemType,
  FileWithUid,
  SocialMediaPackDataType,
  emptySocialMediaItem,
} from '../../SocialMediaPackItem/SocialMediaPackItem';
// Styles
import styles from './DesignResultDetails.module.scss';

const { Title, Paragraph } = Typography;

const DesignResultDetailsComponent: React.FC = () => {
  const { requestId } = useParams<{ requestId: string | undefined }>();
  const { authUser } = useAppContext();
  const isDesigner = authUser?.role === UserRole.Designer;

  const { data, loading } = useQuery<DesignRequests, DesignRequestsVariables>(
    DESIGN_REQUESTS,
    {
      variables: {
        input: {
          designRequestId: requestId,
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const designDetails = data?.designRequests?.entities[0];
  const designCreationDetails = designDetails?.designCreationDetails;
  const isMerchCreationType =
    designDetails?.type === DesignRequestType.MerchCreation;
  const isMerchCreationWithLogoType =
    designDetails?.type === DesignRequestType.MerchCreationWithLogo;

  const [createPresignedUrls, { loading: uploadingImages }] = useMutation<
    CreatePresignedUrlForSocialPack,
    CreatePresignedUrlForSocialPackVariables
  >(CREATE_PRESIGNED_URL_FOR_SOCIAL_PACK_REQUEST);

  const [adminEditMerchDesign, { loading: adminEditMerchDesignLoader }] =
    useMutation<AdminEditMerchDesign, AdminEditMerchDesignVariables>(
      ADMIN_EDIT_MERCH_DESIGN
    );

  const [
    adminEditMerchDesignWithLogo,
    { loading: adminEditMerchDesignWithLogoLoader },
  ] = useMutation<
    AdminEditMerchDesignWithLogo,
    AdminEditMerchDesignWithLogoVariables
  >(ADMIN_EDIT_MERCH_DESIGN_WITH_LOGO);

  const [socialMediaPackItems, setSocialMediaPackItems] = useState<
    SocialMediaPackItemType[]
  >([]);
  const socialMediaPackImages = designDetails?.socialMediaPackImages;
  useEffect(() => {
    setSocialMediaPackItems(
      socialMediaPackImages?.map((item) => {
        return {
          isValid: true,
          label: item.label || '',
          image: undefined,
          imageFileKey: item.imageFileKey || '',
          imgURL: item.socialMediaPackImageUrl || '',
        };
      }) || []
    );
  }, [socialMediaPackImages]);

  const [s3Loader, setS3Loader] = useState<boolean>(false);

  const allImagesForUpload: FileWithUid[] = []; // new images
  const socialMediaPackImagesIndex: number[] = []; // where new social media images should be added
  const socialMediaPackImageUrls: SocialMediaPackDataType[] = []; // array that will contain social media images (old and new)

  const addNewSocialMediaPack = () => {
    setSocialMediaPackItems([...socialMediaPackItems, emptySocialMediaItem]);
  };

  const clearSocialMediaPack = () => {
    setSocialMediaPackItems([]);
  };

  const updateSocialMediaPackItems = (
    value: SocialMediaPackItemType,
    valueIndex: number
  ) => {
    setSocialMediaPackItems((prevData) =>
      prevData.map((currentItem, index) => {
        if (index === valueIndex) {
          return value;
        }
        return currentItem;
      })
    );
  };

  const deleteSocialMediaItem = (index: number) => {
    const newSocialMediaPackItems = [...socialMediaPackItems];
    newSocialMediaPackItems.splice(index, 1);
    setSocialMediaPackItems(newSocialMediaPackItems);
  };

  const uploadDesign = async (uploadData: SocialMediaPackDataType[]) => {
    const types = isMerchCreationType || isMerchCreationWithLogoType;
    const apiCall =
      types === isMerchCreationType
        ? adminEditMerchDesign
        : adminEditMerchDesignWithLogo;

    try {
      await apiCall({
        variables: {
          input: {
            designRequestId: requestId || '',
            socialMediaPackImages: uploadData,
            storeId: designDetails?.store?.id || '',
          },
        },
        refetchQueries: [
          {
            query: DESIGN_REQUESTS,
            variables: { input: {} },
          },
        ],
      });
      successNotification(
        `Upload for request #${requestId || ''} has been finished successfully.`
      );
    } catch (error) {
      errorNotification((error as Error)?.message);
    }
  };

  const handleUploadDesignRequestResult = async () => {
    for (let counter = 0; counter < socialMediaPackItems.length; counter++) {
      socialMediaPackImageUrls.push({
        imageFileKey: socialMediaPackItems[counter].imageFileKey || '',
        label: socialMediaPackItems[counter].label,
        imgURL: socialMediaPackItems[counter].imgURL || '',
      });
      const image = socialMediaPackItems[counter].image;
      if (image) {
        allImagesForUpload.push(image);
        socialMediaPackImagesIndex.push(counter);
      }
    }

    if (allImagesForUpload.length) {
      try {
        const { data } = await createPresignedUrls({
          variables: {
            input: {
              designRequestId: requestId || '',
              numberOfImages: allImagesForUpload.length,
            },
          },
        });
        if (data?.createPresignedUrlForSocialPack.length) {
          try {
            setS3Loader(true);
            const uploadData = await uploadToS3(
              data.createPresignedUrlForSocialPack,
              allImagesForUpload,
              socialMediaPackImageUrls,
              socialMediaPackImagesIndex
            );
            setS3Loader(false);
            uploadDesign(uploadData);
          } catch (error) {
            errorNotification((error as Error)?.message);
            setS3Loader(false);
          }
        }
      } catch (error) {
        errorNotification((error as Error)?.message);
      }
    } else {
      uploadDesign(socialMediaPackImageUrls);
    }
  };

  const buttonLoading: boolean =
    uploadingImages ||
    s3Loader ||
    adminEditMerchDesignLoader ||
    adminEditMerchDesignWithLogoLoader;

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <>
      <Title level={2}>General</Title>

      {!isDesigner && (
        <Space direction="vertical" align="end">
          {designDetails && (
            <DesignRequestsActions
              request={getDesignRequestsFormattedData(data)[0]}
              store={designDetails?.store}
            />
          )}
        </Space>
      )}

      <>
        <Space>
          <Paragraph strong>Design request id:</Paragraph>
          <Paragraph>{designDetails?.id || ''}</Paragraph>
        </Space>

        <Space>
          <Paragraph strong>Status:</Paragraph>
          <Paragraph>{designDetails?.status || ''}</Paragraph>
        </Space>

        <Title level={2}>Result:</Title>

        {designDetails?.designer && (
          <>
            <Space>
              <Paragraph strong>Designer email:</Paragraph>
              <Paragraph>{designDetails?.designer?.email || ''}</Paragraph>
            </Space>

            <Space>
              <Paragraph strong>Designer:</Paragraph>
              <Paragraph>
                {`${designDetails?.designer?.firstName}  ${designDetails?.designer?.lastName}` ||
                  ''}
              </Paragraph>
            </Space>
          </>
        )}

        <div className={styles.itemsWrapper}>
          {designCreationDetails?.designCreationDetailsImages.map(
            ({ id, imageFileURL }) => (
              <div key={id}>
                <Image
                  alt={`logoResultImage-${id}`}
                  width={200}
                  src={imageFileURL || ''}
                  fallback={FALLBACK_IMAGE}
                />
              </div>
            )
          )}
        </div>
      </>
      <div className={styles.socialAndUpdateWrapper}>
        <div className={styles.socialMediaPackWrapper}>
          {socialMediaPackItems.map(
            (socialMediaItem: SocialMediaPackItemType, index: number) => {
              return (
                <SocialMediaPackItem
                  key={socialMediaItem.label + '_social_media_item_' + index}
                  index={index}
                  socialMediaItem={socialMediaItem}
                  setValue={updateSocialMediaPackItems}
                  deleteValue={deleteSocialMediaItem}
                />
              );
            }
          )}
          <Button onClick={addNewSocialMediaPack} loading={buttonLoading}>
            <span>Add New Social Media Image</span>
          </Button>

          {socialMediaPackItems.length ? (
            <Button onClick={clearSocialMediaPack} loading={buttonLoading}>
              <span>Clear All Social Media Images</span>
            </Button>
          ) : null}
        </div>
        <div className={styles.updateWrapper}>
          <Button
            disabled={buttonLoading}
            loading={buttonLoading}
            type="primary"
            onClick={handleUploadDesignRequestResult}
          >
            <span>Update</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default DesignResultDetailsComponent;
