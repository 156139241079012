import { gql } from '@apollo/client';

export const SendoutFields = gql`
  fragment SendoutFields on Sendout {
    id
    status
    title
    description
    createdAtIso
    csvKey
    csvUrl
    analyticsLabel
    errorMessages
    size
    emailConfig {
      subject
      paragraph
      imageUrl
      button {
        label
        url
      }
    }
    inAppConfig {
      title
      body
      action
      imageKey
      imageUrl
      category
    }
    pushConfig {
      title
      body
      action
      imageKey
      imageUrl
      category
    }
  }
`;
