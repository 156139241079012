import { gql } from '@apollo/client';
// Fragments
import { VideoEditorFields } from './fragments';

export const CREATE_VIDEO_EDITOR = gql`
  mutation CreateVideoEditor($input: VideoEditorCreateInput!) {
    createVideoEditor(input: $input) {
      ...VideoEditorFields
    }
  }
  ${VideoEditorFields}
`;

export const EDIT_VIDEO_EDITOR_ADMIN = gql`
  mutation EditVideoEditorAdmin($input: VideoEditorEditInput!) {
    editVideoEditorAdmin(input: $input) {
      ...VideoEditorFields
    }
  }
  ${VideoEditorFields}
`;

export const EDIT_VIDEO_EDITOR = gql`
  mutation EditVideoEditor($input: VideoEditorEditInput!) {
    editVideoEditor(input: $input) {
      ...VideoEditorFields
    }
  }
  ${VideoEditorFields}
`;

export const DEACTIVATE_VIDEO_EDITOR_ACCOUNT = gql`
  mutation DeactivateVideoEditorAccount($id: String!) {
    deactivateVideoEditorAccount(id: $id) {
      ...VideoEditorFields
    }
  }
  ${VideoEditorFields}
`;

export const CREATE_VIDEO_EDIT_REQUEST = gql`
  mutation CreateVideoEditRequest($input: VideoEditRequestAdminInput!) {
    createVideoEditRequest(input: $input) {
      id
      status
    }
  }
`;

export const EDIT_VIDEO_REQUEST_INFO = gql`
  mutation EditVideoRequestInfo($input: UpdateVideoEditInfoInput!) {
    editVideoRequestInfo(input: $input) {
      id
      status
      notes
      adminNotes
      dueDate
    }
  }
`;

export const EDIT_UPLOADED_VIDEO_REQUEST_INFO = gql`
  mutation UpdateEditedVideoById($input: EditEditedVideoInput!) {
    updateEditedVideoById(input: $input) {
      id
      title
      body
    }
  }
`;

export const INIT_EDITED_VIDEO_MULTIPART_UPLOAD = gql`
  mutation InitEditedVideoMultipartUpload(
    $input: InitEditedVideoMultipartUploadInput!
  ) {
    initEditedVideoMultipartUpload(input: $input) {
      fileId
      fileKey
      signedUrls {
        partNumber
        signedUrl
      }
    }
  }
`;

export const COMPLETE_POST_EDITED_VIDEO_MULTIPART_UPLOAD = gql`
  mutation CompletePostEditedVideoMultipartUpload(
    $input: CompleteMultipartUploadInput!
  ) {
    completePostEditedVideoMultipartUpload(input: $input)
  }
`;

export const CREATE_EDITED_VIDEO = gql`
  mutation CreateEditedVideo($input: UploadEditedVideo!) {
    createEditedVideo(input: $input) {
      id
      key
      videoEditRequestId
      videoUrl
    }
  }
`;

export const REUPLOAD_EDITED_VIDEO = gql`
  mutation ReuploadEditedVideo($input: ReuploadEditedVideoInput!) {
    reuploadEditedVideo(input: $input) {
      id
      key
      videoEditRequestId
      videoUrl
    }
  }
`;

export const REASSIGN_VIDEO_EDITOR = gql`
  mutation ReassignVideoEditor($input: ReassignVideoEditRequestInput!) {
    reassignVideoEditor(input: $input) {
      adminNotes
      id
      notes
      assignedEditor {
        createdAt
        email
        firstName
        id
        lastName
        slug
      }
    }
  }
`;

export const ARCHIVE_VIDEO_EDIT = gql`
  mutation ArchiveVideoEdit($input: VideoEditRequestAction!) {
    archiveVideoEdit(input: $input) {
      adminNotes
      id
      notes
    }
  }
`;

export const ACCEPT_ALL_EDITED_VIDEOS = gql`
  mutation AcceptAllEditedVideos($input: AcceptVideoEditRequestInput!) {
    acceptAllEditedVideos(input: $input) {
      adminNotes
      id
      assignedEditorId
      notes
    }
  }
`;

export const REJECT_ALL_AND_SEND_BACK_BY_ADMIN = gql`
  mutation RejectAllAndSendBackByAdmin($input: VideoEditRequestAction!) {
    rejectAllAndSendBackByAdmin(input: $input) {
      adminNotes
      id
      assignedEditorId
      notes
    }
  }
`;

export const REJECT_AND_REASSIGN = gql`
  mutation RejectAndReassign($input: ReassignVideoEditRequestInput!) {
    rejectAndReassign(input: $input) {
      adminNotes
      id
      assignedEditorId
      notes
    }
  }
`;

export const CREATE_PRESIGNED_URLS = gql`
  mutation CreatePresignedUrls(
    $input: CreateVideoEditThumbnailImagePresignedUrlV2Input!
  ) {
    createPresignedUrls(input: $input) {
      fields
      key
      url
    }
  }
`;

export const DELETE_EDITED_VIDEO_BY_ID = gql`
  mutation DeleteEditedVideoById($input: DeleteEditedVideoInput!) {
    deleteEditedVideoById(input: $input)
  }
`;

export const SET_VIDEO_EDIT_REQUEST_SETTINGS = gql`
  mutation SetVideoEditRequestSettings(
    $input: SetVideoEditRequestSettingsInput!
  ) {
    setVideoEditRequestSettings(input: $input) {
      id
    }
  }
`;
