import React from 'react';
// Components
import ProductSales from 'components/common/Sales/ProductSales';

const GeneralSales: React.FC = () => {
  // reusing component just without storeId
  return <ProductSales />;
};

export default GeneralSales;
