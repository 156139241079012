import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Button, Space } from 'antd';
// Api
import { DELETE_INAPPROPRIATE_MARK } from 'api/memorabilia/mutations';
import { GET_MEMORABILIA } from 'api/memorabilia/queries';
// Types
import {
  DeleteInappropriateMark,
  DeleteInappropriateMarkVariables,
} from 'api/memorabilia/types/DeleteInappropriateMark';
import {
  GetMemorabiliaVariables,
  GetMemorabilia_getMemorabilia_entities_images,
} from 'api/memorabilia/types/GetMemorabilia';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
// Styles
import styles from './InappropriateImageModal.module.scss';

type InappropriateImageModalProps = {
  onHide: () => void;
  isVisible?: boolean;
  inappropriateImages: GetMemorabilia_getMemorabilia_entities_images[] | null;
  getMemorabiliaInput: () => GetMemorabiliaVariables;
};

const InappropriateImageModal = ({
  onHide,
  isVisible,
  inappropriateImages,
  getMemorabiliaInput,
}: InappropriateImageModalProps): JSX.Element | null => {
  const [images, setImages] = useState<
    GetMemorabilia_getMemorabilia_entities_images[]
  >([]);

  const [deleteInappropriateImage] = useMutation<
    DeleteInappropriateMark,
    DeleteInappropriateMarkVariables
  >(DELETE_INAPPROPRIATE_MARK);

  useEffect(() => {
    if (inappropriateImages) {
      setImages(inappropriateImages);
    }
  }, [inappropriateImages]);

  const handleApproveInappropriateImage = async (id: string) => {
    try {
      await deleteInappropriateImage({
        variables: {
          input: {
            id,
          },
        },
        refetchQueries: [
          {
            query: GET_MEMORABILIA,
            variables: { ...getMemorabiliaInput() },
          },
        ],
      });
      successNotification('Successfully marked');
    } catch (err) {
      errorNotification(
        (err as Error)?.message ||
          'Looks like something went wrong. Please try again later.'
      );
    }
  };

  const handleRemoveImage = (
    imageToRemove: GetMemorabilia_getMemorabilia_entities_images
  ) => {
    setImages((prevImages) =>
      prevImages.filter((image) => image !== imageToRemove)
    );
  };

  const handleOnClick = (
    image: GetMemorabilia_getMemorabilia_entities_images
  ) => {
    if (image.inappropriateMark) {
      handleApproveInappropriateImage(image.inappropriateMark.id);
      handleRemoveImage(image);
    }
  };

  return (
    <Modal
      title="Inappropriate images"
      onCancel={onHide}
      visible={isVisible}
      destroyOnClose
      footer={null}
      centered
    >
      <Space
        direction="vertical"
        size="large"
        align="center"
        className={styles.root}
      >
        {images?.map((image) => {
          return (
            <div key={image.url || ''}>
              <img
                src={image.url || ''}
                alt="inappropriate content"
                className={styles.inappropriateImage}
              />
              <Space
                direction="vertical"
                align="center"
                className={styles.section}
              >
                <Button
                  type="primary"
                  size="large"
                  onClick={() => handleOnClick(image)}
                  className={styles.markSafeButton}
                >
                  Mark image as safe
                </Button>
              </Space>
            </div>
          );
        })}
      </Space>
    </Modal>
  );
};

export default InappropriateImageModal;
