import React from 'react';
import ImgCrop from 'antd-img-crop';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/es/upload/interface';
// Helpers
import { pictureSizeAndFormatValidation } from 'helpers/utils';
// UI
import UploadImageFile from 'ui/UploadImageFile/UploadImageFile';

type CroppedPictureInputProps = {
  setPictureValidation: React.Dispatch<React.SetStateAction<string>>;
  value?: UploadFile<string>[];
  onChange?: (value: UploadFile<string>[] | null) => void;
  defaultImage?: string | null | undefined;
  buttonTitle?: string;
  requiredMessage?: string;
  disabled?: boolean;
};

const CroppedPictureInput = ({
  setPictureValidation,
  value,
  onChange,
  defaultImage,
  buttonTitle,
  requiredMessage,
  disabled,
}: CroppedPictureInputProps): JSX.Element => {
  const beforeCrop = (file: File) => {
    return pictureSizeAndFormatValidation(file, setPictureValidation);
  };

  const onImageChange = (file: UploadChangeParam<string>) => {
    if (onChange) {
      onChange(file.fileList);
    }
  };

  const onImageRemove = () => {
    if (onChange) {
      onChange(null);
    }

    if (requiredMessage?.length) {
      setPictureValidation(requiredMessage);
    }
  };

  return (
    <ImgCrop
      beforeCrop={beforeCrop}
      modalMaskTransitionName="none"
      modalTransitionName="none"
      fillColor="transparent"
    >
      <UploadImageFile
        onChange={onImageChange}
        onRemove={onImageRemove}
        hideButton={value ? !!value.length : !!defaultImage?.length}
        defaultFileUrl={defaultImage}
        buttonTitle={buttonTitle}
        withCustomRequest
        disabled={disabled}
        buttonIsDisabled={disabled}
      />
    </ImgCrop>
  );
};

export default CroppedPictureInput;
