import { gql } from '@apollo/client';
// Fragments
import { ExperienceFields } from './fragments';

export const CREATE_EXPERIENCE = gql`
  mutation CreateExperience($input: CreateExperienceInput!) {
    createExperience(input: $input) {
      ...ExperienceFields
    }
  }
  ${ExperienceFields}
`;

export const UPDATE_EXPERIENCE = gql`
  mutation UpdateExperience($input: UpdateExperienceInput!) {
    updateExperience(input: $input) {
      ...ExperienceFields
    }
  }
  ${ExperienceFields}
`;

export const DELETE_EXPERIENCE = gql`
  mutation DeleteExperience($input: DeleteExperienceInput!) {
    deleteExperience(input: $input) {
      id
    }
  }
`;

export const RECOVER_EXPERIENCE = gql`
  mutation RecoverExperience($input: RecoverExperienceInput!) {
    recoverExperience(input: $input) {
      id
    }
  }
`;

export const CREATE_EXPERIENCE_PRESIGNED_URL = gql`
  mutation CreateExperienceImagePresignedUrl(
    $input: CreateExperienceImagePresignedUrlInput!
  ) {
    createExperiencePresignedUrl(input: $input) {
      fields
      key
      url
    }
  }
`;
