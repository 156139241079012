import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import moment, { Moment } from 'moment';
import { Calendar, Spin } from 'antd';
// API
import { GET_UPCOMING_STREAMS } from 'api/streams/queries';
// Types
import {
  StoreStatus,
  StreamStatus,
  SortDirection,
  StreamOrderBy,
} from 'api/graphql-global-types';
import {
  GetUpcomingStreams,
  GetUpcomingStreamsVariables,
  GetUpcomingStreams_adminStreams_entities,
} from 'api/streams/types/GetUpcomingStreams';
import { CalendarMode } from 'antd/es/calendar/generateCalendar';
// Components
import DayCell from './components/DayCell/DayCell';
import MonthCell from './components/MonthCell/MonthCell';
// Styles
import styles from './StreamsCalendar.module.scss';

const StreamsCalendar = (): JSX.Element => {
  const [calendarState, setCalendarState] = useState<{
    mode: CalendarMode;
    date: Moment;
  }>({ mode: 'month', date: moment() });

  const { data, loading } = useQuery<
    GetUpcomingStreams,
    GetUpcomingStreamsVariables
  >(GET_UPCOMING_STREAMS, {
    variables: {
      input: {
        streamStatus: [
          StreamStatus.Scheduled,
          StreamStatus.Active,
          StreamStatus.Paused,
          StreamStatus.Interrupted,
          StreamStatus.Cancelled,
          StreamStatus.Ended,
        ],
        scheduleDate: {
          from: calendarState.date.clone().startOf(calendarState.mode),
          to: calendarState.date.clone().endOf(calendarState.mode),
        },
        storeStatus: StoreStatus.Active,
        orderBy: StreamOrderBy.ScheduleDateStrict,
        direction: SortDirection.DESC,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const handlePanelChange = (date: moment.Moment, mode: CalendarMode): void => {
    setCalendarState({ mode, date });
  };

  const handleSelectClick = (date: moment.Moment): void => {
    if (calendarState.mode === 'year') {
      if (
        date.date() === calendarState.date.date() &&
        date.year() === calendarState.date.year()
      ) {
        setCalendarState({ mode: 'month', date });
      } else {
        setCalendarState({ mode: 'year', date });
      }
    }
  };

  const streamsList: GetUpcomingStreams_adminStreams_entities[] =
    data?.adminStreams?.entities || [];

  const streamsByMonth = streamsList.filter(({ scheduleDate }) =>
    moment(scheduleDate).isSame(calendarState.date, 'month')
  );

  const streamsByYear = streamsList.filter(({ scheduleDate }) =>
    moment(scheduleDate).isSame(calendarState.date, 'year')
  );

  return (
    <Spin spinning={loading}>
      <Calendar
        value={calendarState.date}
        mode={calendarState.mode}
        dateCellRender={(date) => (
          <DayCell date={date} streams={streamsByMonth} />
        )}
        monthCellRender={(date) => (
          <MonthCell date={date} streams={streamsByYear} />
        )}
        onPanelChange={handlePanelChange}
        onSelect={handleSelectClick}
        className={styles.root}
      />
    </Spin>
  );
};

export default StreamsCalendar;
