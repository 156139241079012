import { gql } from '@apollo/client';
// Fragments
import { MemorabiliaFields } from './fragments';

export const CREATE_MEMORABILIA = gql`
  mutation CreateMemorabilia($input: CreateMemorabiliaInput!) {
    createMemorabilia(input: $input) {
      ...MemorabiliaFields
    }
  }
  ${MemorabiliaFields}
`;

export const UPDATE_MEMORABILIA = gql`
  mutation UpdateMemorabilia($input: UpdateMemorabiliaInput!) {
    updateMemorabilia(input: $input) {
      ...MemorabiliaFields
    }
  }
  ${MemorabiliaFields}
`;

export const DELETE_MEMORABILIA = gql`
  mutation DeleteMemorabilia($input: DeleteMemorabiliaInput!) {
    deleteMemorabilia(input: $input) {
      id
    }
  }
`;

export const RECOVER_MEMORABILIA = gql`
  mutation RecoverMemorabilia($input: RecoverMemorabiliaInput!) {
    recoverMemorabilia(input: $input) {
      id
    }
  }
`;

export const CREATE_PRESIGNED_URL = gql`
  mutation CreatePresignedUrl($input: CreateMemorabiliaImagePresignedUrl!) {
    createPresignedUrl(input: $input) {
      fields
      key
      url
    }
  }
`;

export const DELETE_INAPPROPRIATE_MARK = gql`
  mutation DeleteInappropriateMark($input: DeleteInappropriateMarkInput!) {
    deleteInappropriateMark(input: $input) {
      id
    }
  }
`;

export const CANCEL_BID = gql`
  mutation CancelBid($input: CancelBidInput!) {
    cancelBid(input: $input) {
      id
      cancelledAt
      auctionId
    }
  }
`;
