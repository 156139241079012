import React from 'react';
import cn from 'classnames';
// Components
import { Button, Input, InputNumber } from 'antd';
import UploadImageFile from 'ui/UploadImageFile/UploadImageFile';
// Styles
import styles from './AffiliateProductItem.module.scss';

export type UploadImage = {
  data_url?: string;
  file?: File;
};

export type AffiliateProductItemType = {
  id?: string;
  index: number;
  isValid?: boolean;
  price: number;
  title: string;
  image: UploadImage;
  url: string;
  imageUrl?: string;
};

type AffiliateProductItemProps = AffiliateProductItemType & {
  setValue: (value: AffiliateProductItemType) => void;
  deleteValue: (index: number) => void;
};

const AffiliateProductItem = ({
  id,
  isValid,
  index,
  price,
  title,
  image,
  url,
  imageUrl,
  setValue,
  deleteValue,
}: AffiliateProductItemProps): JSX.Element => {
  const affiliateProduct: AffiliateProductItemType = {
    id,
    index,
    price,
    title,
    url,
    image,
    imageUrl,
  };

  const handleSetValue = (affiliateProductItem: AffiliateProductItemType) => {
    const { image, title, url, price } = affiliateProductItem;
    if (
      (!!image.file || !!image.data_url) &&
      title.length > 0 &&
      url.length > 0 &&
      price >= 0
    ) {
      affiliateProductItem.isValid = true;
    } else {
      affiliateProductItem.isValid = false;
    }
    setValue(affiliateProductItem);
  };

  const handleImageChange = (image: File) => {
    handleSetValue({
      ...affiliateProduct,
      image: { file: image, data_url: '' },
    });
  };

  const handleTitleChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    handleSetValue({ ...affiliateProduct, title: target.value });
  };

  const handleLinkChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    handleSetValue({ ...affiliateProduct, url: target.value });
  };

  const handlePriceChange = (price: any) => {
    handleSetValue({ ...affiliateProduct, price: parseFloat(price) });
  };

  const handleDeleteAffiliate = () => {
    deleteValue(index);
  };

  const handleOnRemove = () => {
    handleSetValue({
      ...affiliateProduct,
      image: { file: undefined, data_url: '' },
    });
  };

  const displayUrl =
    image?.file instanceof File
      ? URL.createObjectURL(image.file)
      : imageUrl || '';

  return (
    <div
      className={cn(styles.imageItem, {
        [styles.invalidImageItem]: !isValid,
      })}
    >
      <UploadImageFile
        returnPreviewImage={handleImageChange}
        defaultFileUrl={displayUrl}
        buttonTitle={'Add photo'}
        imageAlt="Upload"
        onRemove={handleOnRemove}
      />
      <div className={styles.inputWrapper}>
        <label className={styles.inputLabel} htmlFor="title">
          Title:
        </label>
        <Input
          name="title"
          className={styles.inputField}
          type="text"
          defaultValue={title}
          onBlur={handleTitleChange}
        />
      </div>

      <div className={styles.inputWrapper}>
        <label className={styles.inputLabel} htmlFor="url">
          Link:
        </label>
        <Input
          name="url"
          className={styles.inputField}
          type="text"
          defaultValue={url}
          onBlur={handleLinkChange}
        />
      </div>
      <div className={styles.inputWrapper}>
        <label className={styles.inputLabel} htmlFor="price">
          Price:
        </label>
        <InputNumber
          name="price"
          className={styles.inputField}
          type="number"
          min={0}
          defaultValue={price}
          onInput={handlePriceChange}
        />
      </div>
      <Button
        danger
        onClick={handleDeleteAffiliate}
        className={styles.deleteAffiliateButton}
      >
        Delete affiliate product
      </Button>
    </div>
  );
};

export default AffiliateProductItem;
