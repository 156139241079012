import { gql } from '@apollo/client';

export const ScheduleFields = gql`
  fragment ScheduleFields on Schedule {
    id
    durationMinutes
    timezone
    type
    durationMinutes
    minimumNoticeMinutes
    rules {
      id
      type
      ... on ScheduleWeekDayRule {
        id
        type
        weekday
        intervals {
          id
          from
          to
        }
      }
      ... on ScheduleDateRule {
        id
        date
        dateUtc
        type
        intervals {
          id
          from
          to
        }
      }
      intervals {
        id
        from
        to
      }
    }
  }
`;
