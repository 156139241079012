import { NextLink, Operation, fromPromise, FetchResult } from '@apollo/client';
import { Observable } from '@apollo/client/utilities';
// Types
import { SignOut } from 'api/auth/types/SignOut';
import { RefreshTokens } from 'api/auth/types/RefreshTokens';
import { SignInAdminV2_signInAdminV2 } from 'api/auth/types/SignInAdminV2';
import { UserRole } from 'api/graphql-global-types';
// Config
import ENV from 'api/env';
// Helpers
import {
  setAuthUserToCookies,
  getAuthUserFromCookies,
  clearAuthUserFromCookies,
} from 'helpers/cookies';

export type FetchResponse<T> = {
  data: T | null;
  errors?: Error[];
};

export let isRefreshing = false;
export let pendingRequests: (() => void)[] = [];

export const setIsRefreshing = (value: boolean): void => {
  isRefreshing = value;
};

export const addPendingRequest = (pendingRequest: () => void): void => {
  pendingRequests.push(pendingRequest);
};

export const resolvePendingRequests = (): void => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

export const clearPendingRequests = (): void => {
  pendingRequests = [];
};

export const getIfHaveTokenError = (message: string): boolean => {
  return [
    'Unauthorized',
    'You are not authorized to make this call.',
    'token is not defined',
    'Forbidden resource',
  ]
    .map((i) => i.toLowerCase())
    .includes(message.toLowerCase());
};

const getIfHaveAccessError = (message: string): boolean => {
  return [
    'Refresh token is stale',
    'Invalid token',
    'JWT token has expired or invalid',
    'Your account has been deactivated',
  ]
    .map((i) => i.toLowerCase())
    .includes(message.toLowerCase());
};

export const clearAuthData = (): void => {
  setIsRefreshing(false);
  clearPendingRequests();

  clearAuthUserFromCookies();
  window.location.reload();
};

const handleFetch = async <T = any>(
  query: string
): Promise<FetchResponse<T>> => {
  const authUser = getAuthUserFromCookies();

  return fetch(ENV.PUBLIC_API_HOST, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${authUser?.accessToken || ''}`,
    },
    body: JSON.stringify({
      query,
      variables: { input: { refreshToken: authUser?.refreshToken || '' } },
    }),
  }).then((res) => res.json());
};

export const refreshTokenMutation = `
  mutation RefreshTokens($input: RefreshTokensInput!) {
    refreshTokens(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const refreshUserToken = async (): Promise<void> => {
  const authUser = getAuthUserFromCookies();

  const orError = () => {
    clearPendingRequests();
    clearAuthUserFromCookies();
  };

  try {
    const { data, errors } = await handleFetch<RefreshTokens>(
      refreshTokenMutation
    );

    const accessToken = data?.refreshTokens.accessToken;
    const refreshToken = data?.refreshTokens.refreshToken;

    if (authUser && accessToken && refreshToken) {
      setAuthUserToCookies({
        ...authUser,
        accessToken,
        refreshToken,
      });
    }

    if (errors) {
      orError();
    }
  } catch (error) {
    orError();
  }
};

export const signOutMutation = `
  mutation SignOut($input: SignOutInput!) {
    singOut(input: $input)
  }
`;

export const signOut = async (): Promise<void> => {
  try {
    await handleFetch<SignOut>(signOutMutation);
  } catch (error) {
    console.log('signOut error', error);
  }
};

export const onAuthError = async (errorMessages: string[]): Promise<void> => {
  const haveTokenError = errorMessages.some(getIfHaveTokenError);
  const haveAccessError = errorMessages.some(getIfHaveAccessError);

  const authUser = getAuthUserFromCookies();

  if (
    !authUser?.refreshToken?.length &&
    authUser?.accessToken?.length &&
    haveTokenError
  ) {
    clearAuthData();
  } else if (authUser?.refreshToken?.length) {
    if (haveAccessError) {
      await signOut();
      clearAuthData();
    }

    if (haveTokenError) {
      await refreshUserToken();
    }
  }
};

export const handleAuthError = ({
  operation,
  forward,
  errorMessages,
}: {
  errorMessages: string[];
  operation: Operation;
  forward: NextLink;
}): Observable<
  FetchResult<{ [key: string]: any }, Record<string, any>, Record<string, any>>
> => {
  if (!isRefreshing) {
    setIsRefreshing(true);

    return fromPromise(
      onAuthError(errorMessages).catch(() => {
        setIsRefreshing(false);

        return forward(operation);
      })
    ).flatMap(() => {
      resolvePendingRequests();
      setIsRefreshing(false);

      return forward(operation);
    });
  } else {
    return fromPromise(
      new Promise((resolve) => {
        addPendingRequest(() => resolve(true));
      })
    ).flatMap(() => {
      return forward(operation);
    });
  }
};

export const getIsInterviewer = (
  authUser: SignInAdminV2_signInAdminV2 | null
): boolean => {
  if (!authUser) return false;

  return (
    authUser?.role !== UserRole.Admin &&
    authUser?.role !== UserRole.Moderator &&
    authUser?.role !== UserRole.Designer &&
    Boolean(authUser.interviewerDetails)
  );
};
