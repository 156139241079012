import { Text, Node, BaseText, BaseElement, Editor, Element } from 'slate';
import { ReactEditor } from 'slate-react';
import { jsx } from 'slate-hyperscript';
import escapeHtml from 'escape-html';
import isUrl from 'is-url';
// Helpers
import { wrapLink, LinkTarget } from 'components/common/RichText/helpers/link';

export const createParagraphNode = (text: string): Node => {
  return {
    // eslint-disable-next-line
    // @ts-ignore
    type: 'paragraph',
    children: [{ text }],
  };
};

export const serialize = (
  node: (BaseElement | BaseText) & {
    type?: string;
    href?: string;
    target?: LinkTarget;
  }
): string => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);

    if ('bold' in node) {
      string = `<strong>${string}</strong>`;
    }

    return string;
  }

  const children = node.children.map((n) => serialize(n)).join('');

  switch (node.type) {
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`;
    case 'paragraph':
      return `<p>${children}</p>`;
    case 'link':
      return `<a href="${escapeHtml(node?.href || '')}" target="${
        node?.target || LinkTarget.Blank
      }">${children}</a>`;
    default:
      return children;
  }
};

export const deserialize = (el: HTMLElement): any => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  }

  const children = Array.from(el.childNodes).map((i) =>
    deserialize(i as HTMLElement)
  );
  const validChildren = children.length ? children : [''];

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, validChildren);
    case 'BR':
      return '\n';
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'quote' }, validChildren);
    case 'P':
      return jsx('element', { type: 'paragraph' }, validChildren);
    case 'A':
      return jsx(
        'element',
        { type: 'link', href: el.getAttribute('href') },
        validChildren
      );
    case 'STRONG':
      return jsx('text', { bold: true }, validChildren);
    default:
      return el.textContent;
  }
};

export const isBlockActive = (editor: ReactEditor, format: string): any => {
  // eslint-disable-next-line
  // @ts-ignore
  const [match] = Editor.nodes?.(editor, {
    match: (n: Node & { type?: string }) =>
      !Editor.isEditor(n) &&
      Element.isElement(n) &&
      (n.type !== undefined ? n.type === format : false),
  });

  return !!match;
};

export const withInlines = (editor: ReactEditor): ReactEditor => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) =>
    // eslint-disable-next-line
    // @ts-ignore
    ['link'].includes(element?.type) || isInline(element);

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};
