import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import { useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import CreatableSelect from 'react-select/creatable';
// Types
import { GET_ALL_HASHTAGS } from 'api/hashtags/queries';
// Styles
import styles from './TagsInput.module.scss';

type TagsInputProps = {
  editable?: boolean;
  hashtags: string[];
  name: string;
  disabled?: boolean;
  onChange: (value: string[]) => void;
  classNames?: string;
} & React.ComponentPropsWithRef<'input'>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TagsInput = ({
  hashtags = [],
  editable = true,
  name,
  disabled,
  onChange,
  classNames,
}: TagsInputProps) => {
  const [options, setOptions] = useState([]);

  // Format tags to the required structure for react-select
  const formattedTags = hashtags.map((tag) => ({
    label: tag,
    value: tag,
  }));

  const { refetch } = useQuery(GET_ALL_HASHTAGS, {
    variables: { input: { keyword: 'a' } },
  });

  // Debounce function to prevent rapid API calls
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLoadOptions = useCallback(
    debounce((inputValue, callback) => {
      if (inputValue) {
        refetch({ input: { keyword: inputValue } })
          .then(({ data }) => {
            const options =
              data?.getAllHashtags?.entities?.map((tag: any) => ({
                label: tag.name,
                value: tag.name,
              })) || [];
            setOptions(options);
            callback(options);
          })
          .catch((error) => {
            console.error('Error fetching hashtags:', error);
            setOptions([]);
            callback([]);
          });
      } else {
        setOptions([]);
        callback([]);
      }
    }, 500),
    [refetch]
  );

  const handleMenuOpen = () => {
    debouncedLoadOptions('a', (options: any) => setOptions(options));
  };

  const handleSelectChange = (selectedOptions: any) => {
    const newTags = selectedOptions
      ? selectedOptions.map((option: any) => option.value)
      : [];
    onChange(newTags);
  };

  const handleCreateOption = (inputValue: string) => {
    const newOption = { label: inputValue, value: inputValue };
    const newOptions = [...options, newOption];
    setOptions(newOptions as []);
    handleSelectChange([...formattedTags, newOption]);
  };

  const handleInputChange = (newValue: any) => {
    debouncedLoadOptions(newValue, (options: any) => setOptions(options));
  };

  return (
    <div className={cn(styles.root, classNames)}>
      {editable && (
        <CreatableSelect
          isMulti
          options={options}
          onChange={handleSelectChange}
          onCreateOption={handleCreateOption}
          onMenuOpen={handleMenuOpen}
          onInputChange={handleInputChange}
          value={formattedTags}
          placeholder="Add tags here"
          name={name}
          className={styles.inputWrapper}
          isDisabled={disabled}
        />
      )}
      {hashtags.length > 5 && (
        <span
          className={styles.errorLabel}
          role="alert"
          aria-label={'tags-error-label'}
        >
          Please add up to 5 tags
        </span>
      )}
    </div>
  );
};

export default TagsInput;
