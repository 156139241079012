import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { DatePicker, Radio } from 'antd';
// Types
import { RadioChangeEvent } from 'antd/lib/radio';
import { ChartType } from 'pages/Dashboard';

import styles from './DashboardHeader.module.scss';

const { RangePicker } = DatePicker;

type DashboardHeaderProps = {
  startDate: Moment;
  endDate: Moment;
  onDateRangeChange: (startDate: Moment, endDate: Moment) => void;
  onChangeChartType: (value: ChartType) => void;
  currentChartType: ChartType;
};

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  startDate,
  endDate,
  onDateRangeChange,
  onChangeChartType,
  currentChartType,
}) => {
  const [datePickerStartDate, setDatePickerStartDate] = useState<Moment | null>(
    startDate
  );
  const [datePickerEndDate, setDatePickerEndDate] = useState<Moment | null>(
    endDate
  );

  const handleDateRangeChange = (dates: any): void | null => {
    if (!dates) return null;
    const [start, end] = dates;
    setDatePickerStartDate(start);
    setDatePickerEndDate(end);
    if (start && end) {
      onDateRangeChange(
        moment.parseZone(start).utc(true).startOf('day'),
        moment.parseZone(end).utc(true).endOf('day')
      );
    }
  };

  const getMaxDate = (current: Moment) =>
    moment().diff(current, 'days', true) <= 0;

  const handleChangeChartType = (e: RadioChangeEvent) => {
    onChangeChartType(e.target.value);
  };

  return (
    <>
      <RangePicker
        defaultValue={[datePickerStartDate, datePickerEndDate]}
        disabledDate={getMaxDate}
        onChange={handleDateRangeChange}
        className={styles.rangePicker}
      />
      <Radio.Group onChange={handleChangeChartType} value={currentChartType}>
        <Radio value={ChartType.Revenue}>Revenue</Radio>
        <Radio value={ChartType.ProfitMargin}>Profit margin</Radio>
      </Radio.Group>
    </>
  );
};

export default DashboardHeader;
