import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Space, Button, Modal, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// Api
import { REMOVE_INTERVIEWER_CAPABILITY } from 'api/interviewer/mutations';
import { GET_STORE_INTERVIEWERS } from 'api/interviewer/queries';
// Types
import { StoreInterviewerOrderBy } from 'api/graphql-global-types';
import {
  RemoveInterviewerCapability,
  RemoveInterviewerCapabilityVariables,
} from 'api/interviewer/types/RemoveInterviewerCapability';
import { GetStoreInterviewers_getStoreInterviewers_entities } from 'api/interviewer/types/GetStoreInterviewers';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import CreateEditInterviewerModal from '../CreateEditInterviewModal/CreateEditInterviewerModal';
import AvailabilityModal from '../AvailabilityModal/AvailabilityModal';

const { confirm } = Modal;

type ActionsProps = {
  interviewer: GetStoreInterviewers_getStoreInterviewers_entities;
};

const Actions = ({ interviewer }: ActionsProps): JSX.Element => {
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [availabilityModalVisible, setAvailabilityModalVisible] =
    useState<boolean>(false);

  const handleEditModalToggle = () => {
    setEditModalVisible((prev) => !prev);
  };

  const handleAvailabilityModalToggle = () => {
    setAvailabilityModalVisible((prev) => !prev);
  };

  const [removeInterviewerCapability, { loading }] = useMutation<
    RemoveInterviewerCapability,
    RemoveInterviewerCapabilityVariables
  >(REMOVE_INTERVIEWER_CAPABILITY, {
    refetchQueries: [
      {
        query: GET_STORE_INTERVIEWERS,
        variables: {
          input: {
            limit: 100,
            offset: 0,
            orderBy: StoreInterviewerOrderBy.createdAt,
          },
        },
      },
    ],
  });

  const handleRemoveInterviewerCapability = async (storeId: string) => {
    try {
      await removeInterviewerCapability({
        variables: { input: { storeId } },
      });

      successNotification('Interviewer capability has been removed');
    } catch (err) {
      errorNotification();
    }
  };

  const handleRemoveInterviewerCapabilityConfirmation = (
    id: string,
    name: string
  ) => {
    confirm({
      title: `Are you sure to remove interviewer capabilities for store "${name}"?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleRemoveInterviewerCapability(id);
      },
    });
  };

  return (
    <>
      <Spin spinning={loading}>
        <Space direction="vertical" size="middle" align="center">
          <Button onClick={handleEditModalToggle} type="primary">
            Edit
          </Button>

          <Button
            type="primary"
            danger
            onClick={() => {
              handleRemoveInterviewerCapabilityConfirmation(
                interviewer.id,
                interviewer.firstName || ''
              );
            }}
          >
            Remove
          </Button>
          <Button onClick={handleAvailabilityModalToggle} type="primary">
            Availability
          </Button>
        </Space>
      </Spin>

      <CreateEditInterviewerModal
        isVisible={editModalVisible}
        interviewer={interviewer}
        onClose={handleEditModalToggle}
      />

      <AvailabilityModal
        isVisible={availabilityModalVisible}
        interviewer={interviewer}
        onClose={handleAvailabilityModalToggle}
      />
    </>
  );
};

export default Actions;
