import React, { useState, createContext, useEffect } from 'react';
// Types
import { UserRole } from 'api/graphql-global-types';
// Helpers
import {
  setAuthUserToCookies,
  getAuthUserFromCookies,
  clearAuthUserFromCookies,
} from 'helpers/cookies';

export type AuthUser = {
  id: string;
  role: UserRole;
  accessToken: string;
  refreshToken: string;
  isInterviewer: boolean;
};

type AppContextState = {
  authUser: AuthUser | null;
};

type AppContextHandlers = {
  setAuthUser: (authUser: AuthUser) => void;
  clearAuthUser: () => void;
  setAppContext: React.Dispatch<React.SetStateAction<AppContextState>>;
};

export type AppContextType = AppContextHandlers & AppContextState;

const initialState = {
  authUser: getAuthUserFromCookies(),
};

export const AppContext = createContext<AppContextType>({} as AppContextType);

export const AppProvider: React.FC = ({ children }) => {
  const [appContext, setAppContext] = useState<AppContextState>(initialState);

  useEffect(() => {
    const authUserFromCookies = getAuthUserFromCookies();

    if (authUserFromCookies && !appContext.authUser) {
      setAppContext((state) => ({
        ...state,
        authUser: authUserFromCookies,
      }));
    }
  }, [appContext.authUser]);

  const setAuthUser = (authUser: AuthUser) => {
    setAuthUserToCookies(authUser);
    setAppContext((state) => ({
      ...state,
      authUser,
    }));
  };

  const clearAuthUser = () => {
    clearAuthUserFromCookies();
    setAppContext((state) => ({
      ...state,
      authUser: null,
    }));
  };

  return (
    <AppContext.Provider
      value={{
        ...appContext,
        setAuthUser,
        clearAuthUser,
        setAppContext,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
