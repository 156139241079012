import React from 'react';
import { useMutation } from '@apollo/client';
import { Button } from 'antd';
// Api
import { GET_ALL_HASHTAGS } from 'api/hashtags/queries';
import { ADD_HASHTAG } from 'api/hashtags/mutations';
// Types
import { AddHashtag, AddHashtagVariables } from 'api/hashtags/types/AddHashtag';
import { GetAllHashtagsVariables } from 'api/hashtags/types/GetAllHashtags';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import CreateEditHashtagModal from 'pages/ManageHashtags/components/CreateEditHashtagModal/CreateEditHashtagModal';

import './styles.scss';

type CreateHashtagProps = {
  getAllHashtagsInput: GetAllHashtagsVariables;
};

const CreateHashtag: React.FC<CreateHashtagProps> = ({
  getAllHashtagsInput,
}) => {
  const [addHashtag, { loading }] = useMutation<
    AddHashtag,
    AddHashtagVariables
  >(ADD_HASHTAG);

  const handleHashtagCreate = async ({
    hashtagName,
    sportIds,
    onSuccess,
  }: {
    hashtagName: string;
    sportIds: number[];
    onSuccess: () => void;
  }) => {
    try {
      if (hashtagName) {
        await addHashtag({
          variables: { input: { name: hashtagName, sportIds } },
          refetchQueries: [
            {
              query: GET_ALL_HASHTAGS,
              variables: { ...getAllHashtagsInput },
            },
          ],
        });

        onSuccess();

        successNotification(
          `Hashtag #${hashtagName} has been created successfully`
        );
      }
    } catch (error) {
      errorNotification((error as Error)?.message);
      console.error(error);
    }
  };

  return (
    <CreateEditHashtagModal onSubmit={handleHashtagCreate} loading={loading}>
      {({ onModalOpen }) => {
        return (
          <div className="create-hashtag-wrapper">
            <h1>Manage Hashtags</h1>
            <Button type="primary" onClick={onModalOpen}>
              Create new #hashtag
            </Button>
          </div>
        );
      }}
    </CreateEditHashtagModal>
  );
};

export default CreateHashtag;
