import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Space } from 'antd';
// Hooks
import { useAppContext } from 'hooks';
// Types
import { UserRole } from 'api/graphql-global-types';
// Constants
import { DESIGN_REQUESTS, MY_DESIGN_REQUESTS } from 'constants/routes';
// Components
import DesignResultDetailsComponent from 'components/common/DesignRequestsDetails/DesignResultDetails/DesignResultDetails';

const DesignResultDetails: React.FC = () => {
  const history = useHistory();

  const { authUser } = useAppContext();

  const isDesigner = authUser?.role === UserRole.Designer;

  // determining if user came to this page from design requests table component by
  // checking if it has previous location, which we set when changing the address to here
  const hasFiltersData =
    history.location?.state &&
    typeof history.location.state === 'object' &&
    'previousLocation' in history.location.state;

  const handleGoBack = async () => {
    if (hasFiltersData) {
      // going back to the design requests table component in a way that loads all the filters we left
      await history.goBack();
    } else {
      await history.push(isDesigner ? MY_DESIGN_REQUESTS : DESIGN_REQUESTS);
    }
  };

  return (
    <>
      {!isDesigner && (
        <Space direction="vertical" align="end">
          <Button type="primary" onClick={handleGoBack}>
            Back to design requests
          </Button>
        </Space>
      )}

      <DesignResultDetailsComponent />
    </>
  );
};

export default DesignResultDetails;
