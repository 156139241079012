import React, { useState } from 'react';
import cn from 'classnames';
import isUrl from 'is-url';
import { Transforms, Selection } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { Button, Popover, Space, Input, Checkbox, Typography } from 'antd';
import { LinkOutlined, PlusCircleFilled } from '@ant-design/icons';
// Helpers
import {
  insertLink,
  removeLink,
  LinkTarget,
} from 'components/common/RichText/helpers/link';
import { isBlockActive } from 'components/common/RichText/helpers/basic';
// Styles
import styles from './LinkButton.module.scss';

const { Text } = Typography;

const LinkButton = (): JSX.Element => {
  const editor = useSlate() as ReactEditor;

  const [url, setUrl] = useState<string>('');
  const [urlIsValid, setUrlIsValid] = useState<boolean>(true);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [showInNewTab, setShowInNewTab] = useState<boolean>(true);
  const [selection, setSelection] = useState<Selection | null>(null);

  const handlePopoverToggle = () => {
    setOpenPopover((prev) => !prev);
  };

  const handleResetState = () => {
    setUrl('');
    setUrlIsValid(true);
    setOpenPopover(false);
    setShowInNewTab(true);
  };

  const toggleLink = () => {
    setSelection(editor.selection as any);
    handlePopoverToggle();
  };

  const handleInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(target.value);
    setUrlIsValid(isUrl(target.value));
  };

  const handleCheckboxChange = () => {
    setShowInNewTab((prev) => !prev);
  };

  const handleInsertLink = () => {
    Transforms.select(editor, selection as any);
    insertLink(editor, {
      href: url,
      target: showInNewTab ? LinkTarget.Blank : LinkTarget.Self,
    });
    handleResetState();
  };

  const handleRemoveLink = () => {
    removeLink(editor);
    handleResetState();
  };

  const isActive = isBlockActive(editor, 'link');

  if (isActive) {
    return (
      <Button
        type="primary"
        danger
        onClick={handleRemoveLink}
        icon={<LinkOutlined className={cn(styles.icon, styles.isActive)} />}
        disabled={!editor.selection}
        title="Remove the link"
        htmlType="button"
      />
    );
  }

  return (
    <Popover
      visible={openPopover}
      onVisibleChange={toggleLink}
      trigger="click"
      title="Add the link"
      destroyTooltipOnHide
      content={
        <Space size="small" direction="vertical">
          <Input.Group compact>
            <Input
              className={cn(styles.input, {
                [styles.isInvalid]: !urlIsValid,
              })}
              defaultValue={url}
              placeholder="https://site.com"
              onChange={handleInputChange}
              type="url"
              allowClear
            />

            <Button
              type="primary"
              onClick={handleInsertLink}
              icon={<PlusCircleFilled />}
              disabled={!urlIsValid}
              title="Add"
              htmlType="button"
            />
          </Input.Group>

          <Checkbox checked={showInNewTab} onChange={handleCheckboxChange}>
            Open in new tab
          </Checkbox>

          {!urlIsValid && (
            <Text type="danger">Please add valid url address</Text>
          )}
        </Space>
      }
    >
      <Button
        type="default"
        icon={<LinkOutlined className={styles.icon} />}
        disabled={!editor.selection}
        title="Create the link"
        htmlType="button"
      />
    </Popover>
  );
};

export default LinkButton;
