import React from 'react';
import { useQuery } from '@apollo/client';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
// Api
import { GET_STORES } from 'api/store/queries';
// Types
import { GetStores, GetStoresVariables } from 'api/store/types/GetStores';
import { AdminStoresOrderBy, UserRole } from 'api/graphql-global-types';
// Styles
import styles from './Participants.module.scss';

type ParticipantsProps = {
  storeIds: string[];
  onRemove: (storeId: string) => void;
};

const Participants = ({
  storeIds,
  onRemove,
}: ParticipantsProps): JSX.Element | null => {
  const { data, loading } = useQuery<GetStores, GetStoresVariables>(
    GET_STORES,
    {
      variables: {
        input: {
          storeIds,
          orderBy: AdminStoresOrderBy.storeName,
        },

        storeRoles: [
          UserRole.Athlete,
          UserRole.Organization,
          UserRole.ContentCreator,
        ],
      },
    }
  );

  if (!data) {
    return null;
  }

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div className={styles.root}>
      {data?.adminStores?.entities.map(({ id, storeDetails }) => (
        <div key={id} className={styles.inner}>
          <p>{storeDetails?.storeName || ''}</p>

          <Button
            onClick={() => {
              onRemove(id);
            }}
          >
            <DeleteOutlined /> Delete
          </Button>
        </div>
      ))}
    </div>
  );
};

export default Participants;
