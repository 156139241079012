import React from 'react';
import cn from 'classnames';
import { isSafari } from 'react-device-detect';
// UI
import { DeleteOutlined } from '@ant-design/icons';

import styles from './UploadImagePlaceholder.module.scss';

type UploadImagePlaceholderProps = {
  title?: string;
  description?: string;
  onClick: () => void;
  error?: boolean;
  disabled?: boolean;
};

const UploadImagePlaceholder = ({
  title,
  description,
  onClick,
  error,
  disabled,
}: UploadImagePlaceholderProps): JSX.Element => {
  return (
    <button
      className={cn(styles.root, {
        [styles.error]: error,
      })}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      {!isSafari && <DeleteOutlined />}

      {title && <span className={styles.title}>{title}</span>}

      {description && <span className={styles.description}>{description}</span>}
    </button>
  );
};

export default UploadImagePlaceholder;
