import { gql } from '@apollo/client';
import { AmaRequestFields, AmaFields } from './fragments';

export const ACCEPT_AMA_REQUEST = gql`
  mutation AcceptAmaRequest($id: String!) {
    acceptAmaRequest(id: $id) {
      ...AmaRequestFields
    }
  }
  ${AmaRequestFields}
`;

export const DECLINE_AMA_REQUEST = gql`
  mutation DeclineAmaRequest($input: AmaRequestDeclineInput!) {
    declineAmaRequest(input: $input) {
      ...AmaRequestFields
    }
  }
  ${AmaRequestFields}
`;

export const SUBMIT_AMA_REQUEST_VIDEO = gql`
  mutation SubmitAmaRequestVideo($input: AmaRequestSubmitVideoInput!) {
    submitAmaRequestVideo(input: $input) {
      ...AmaRequestFields
    }
  }
  ${AmaRequestFields}
`;

export const CREATE_AMA_REQUEST_VIDEO_PRESIGNED_URL = gql`
  mutation CreateAmaRequestVideoPresignedUrl(
    $input: AmaRequestCreatePresignedUrlInput!
  ) {
    createAmaRequestVideoPresignedUrl(input: $input) {
      fields
      key
      url
    }
  }
`;

export const ADMIN_CREATE_AMAS = gql`
  mutation AdminCreateAmas($input: AdminCreateAmasInput!) {
    adminCreateAmas(input: $input) {
      ...AmaFields
    }
  }
  ${AmaFields}
`;

export const ADMIN_EDIT_AMAS = gql`
  mutation AdminEditAmas($input: AdminEditAmasInput!) {
    adminEditAmas(input: $input) {
      ...AmaFields
    }
  }
  ${AmaFields}
`;

export const CREATE_AMA_PRESIGNED_URL = gql`
  mutation CreateAmaPresignedUrl {
    createAmaPresignedUrl {
      fields
      key
      url
    }
  }
`;
