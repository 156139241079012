import { UploadFile } from 'antd/lib/upload/interface';
import { CreateStreamImageAdmin_createStreamImageAdmin } from 'api/interview/types/CreateStreamImageAdmin';
import { ImageToUpload } from './createPresignedUrl';
import { CreateStreamImage_createStreamImage } from 'api/interview/types/CreateStreamImage';

export type UploadImage = {
  data_url?: string;
  data_key?: string;
  originFileObj?: UploadFile;
};

export const getImagesToUpload = (
  preSignedUrls:
    | CreateStreamImageAdmin_createStreamImageAdmin[]
    | CreateStreamImage_createStreamImage[],
  images: UploadImage[]
): ImageToUpload[] => {
  const res: ImageToUpload[] = [];

  images.forEach((img, index) => {
    const originFileObj = img?.originFileObj;
    const preSignedUrl = preSignedUrls?.[index];

    res.push({
      fields: preSignedUrl?.fields,
      url: preSignedUrl?.url,
      name: preSignedUrl?.key,
      type: originFileObj?.type || '',
      file: (originFileObj as unknown as File) || '',
    });
  });

  return res;
};

export const uploadImages = async (
  preSignedUrls:
    | CreateStreamImage_createStreamImage[]
    | CreateStreamImageAdmin_createStreamImageAdmin[],
  images: UploadImage[]
): Promise<string[]> => {
  const imagesToUpload = getImagesToUpload(preSignedUrls, images);

  for (const image of imagesToUpload) {
    const { fields, url, name, type, file } = image;
    const formData = new FormData();

    Object.entries(JSON.parse(fields)).forEach(([key, value]) => {
      formData.append(key, value as string);
    });

    formData.append('key', name);
    formData.append('Content-Type', type);
    formData.append('file', file);

    await fetch(url, {
      method: 'POST',
      body: formData,
    });
  }

  return imagesToUpload.map(({ name }) => name);
};
