import { gql } from '@apollo/client';
import { PromocodeFields } from './fragments';

export const CREATE_PROMO_CODE = gql`
  mutation CreatePromoCode($input: CreatePromocodeInput!) {
    createPromocode(input: $input) {
      ...PromocodeFields
    }
  }
  ${PromocodeFields}
`;

export const ENABLE_PROMO_CODE = gql`
  mutation EnablePromocode($id: String!) {
    enablePromocode(id: $id) {
      ...PromocodeFields
    }
  }
  ${PromocodeFields}
`;

export const DISABLE_PROMO_CODE = gql`
  mutation DisablePromocode($id: String!) {
    disablePromocode(id: $id) {
      ...PromocodeFields
    }
  }
  ${PromocodeFields}
`;

export const DEACTIVATE_PROMO_CODE = gql`
  mutation DeactivatePromocode($id: String!) {
    deactivatePromocode(id: $id) {
      ...PromocodeFields
    }
  }
  ${PromocodeFields}
`;
