import { gql } from '@apollo/client';

export const SET_BRAND_DEAL_SETTINGS = gql`
  mutation SetBrandDealSettings($input: SetBrandDealSettingsInput!) {
    setBrandDealSettings(input: $input) {
      id
      contactEmail
      contactPhone
      minFlatPrice
      minBookMePrice
      minSocialPrice
      minSponsoredLivePrice
      industryCategories {
        isEnabled
        label
        value
      }
      isAcceptingFreeProducts
      isArticlesPrEnabled
    }
  }
`;
