import { gql } from '@apollo/client';

export const CREATE_PRESIGNED_AFFILIATE_PRODUCT_IMAGE_URL = gql`
  mutation CreatePresignedAffiliateProductImageUrls(
    $input: CreatePresignedAffiliateProductImageUrlsInput!
  ) {
    createPresignedAffiliateProductImageUrls(input: $input) {
      fields
      key
      url
    }
  }
`;
