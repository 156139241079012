import { gql } from '@apollo/client';

export const SIGN_IN_ADMIN_V2 = gql`
  mutation SignInAdminV2($input: SignInInput!) {
    signInAdminV2(input: $input) {
      id
      role
      accessToken
      refreshToken
      interviewerDetails {
        id
      }
    }
  }
`;

/** The mutation and its types are used at helpers/auth.ts helper */
export const REFRESH_TOKENS = gql`
  mutation RefreshTokens($input: RefreshTokensInput!) {
    refreshTokens(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const SIGN_OUT = gql`
  mutation SignOut($input: SignOutInput!) {
    singOut(input: $input)
  }
`;
