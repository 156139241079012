import React, { useState, useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { Spin, Form, AutoComplete } from 'antd';
// Api
import { GET_STORES } from 'api/store/queries';
// Types
import { UserRole } from 'api/graphql-global-types';
import { GetStores, GetStoresVariables } from 'api/store/types/GetStores';
// Helpers
import { getUnique } from 'helpers/utils';
// Context
import { useStoresSelectProducts } from 'context/StoresSelectedProducts';
// Components
import FormItemLabel from '../FormItemLabel/FormItemLabel';
import StoreProducts from '../StoreProducts/StoreProducts';

import './styles.scss';

const StoreDataSelect: React.FC = () => {
  const [getStores, { loading, data }] = useLazyQuery<
    GetStores,
    GetStoresVariables
  >(GET_STORES);

  const { setSelected, removeStoreSelectedItems } = useStoresSelectProducts();
  const [autoCompleteValue, setAutoCompleteValue] = useState<string>('');
  const [asyncOptions, setAsyncOptions] = useState<Array<any>>([]);
  const [selectedStoreIds, setSelectedStoreIds] = useState<string[]>([]);

  useEffect(() => {
    const options =
      data?.adminStores.entities.map(({ id, firstName, lastName }) => ({
        value: id,
        label: `${firstName} ${lastName}`,
      })) || [];

    setAsyncOptions(options);
  }, [data]);

  const handleSelectStoreId = (value: string): void => {
    setAutoCompleteValue('');

    setSelectedStoreIds((prevState) => getUnique([...prevState, value]));
  };

  const handleAutoCompleteChange = (value: string): void => {
    setAutoCompleteValue(value);
  };

  const handleRemoveStore = (storeId: string): void => {
    removeStoreSelectedItems(storeId);
    setSelectedStoreIds((prev) => prev.filter((id) => id !== storeId));
  };

  const handleOnSearch = useMemo(() => {
    const loadOptions = (storeName: string) => {
      setAsyncOptions([]);

      getStores({
        variables: {
          storeRoles: [
            UserRole.Athlete,
            UserRole.Organization,
            UserRole.ContentCreator,
          ],
          input: { storeName },
        },
      });
    };

    return debounce(loadOptions, 400);
  }, [getStores]);

  return (
    <>
      <Form.Item required label={<FormItemLabel labelText="Select Store" />}>
        <AutoComplete
          placeholder="Search by store"
          notFoundContent={loading ? <Spin size="small" /> : null}
          className="promo-code-form__input"
          value={autoCompleteValue}
          options={asyncOptions}
          onSearch={handleOnSearch}
          onChange={handleAutoCompleteChange}
          onSelect={handleSelectStoreId}
        />
      </Form.Item>
      {selectedStoreIds.map((storeId) => (
        <StoreProducts
          key={storeId}
          storeId={storeId}
          onChange={setSelected}
          onRemove={handleRemoveStore}
        />
      ))}
    </>
  );
};

export default StoreDataSelect;
