import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Space, Button, Form, Input } from 'antd';
// Api
import { DESIGNERS } from 'api/designLab/queries';
import { CREATE_DESIGNER } from 'api/designLab/mutations';
// Types
import {
  CreateDesigner,
  CreateDesignerVariables,
} from 'api/designLab/types/CreateDesigner';
// UI
import { errorNotification, successNotification } from 'ui/Notification';

type AddNewDesignerProps = {
  getAllDesignersInput: any;
};

const AddNewDesigner: React.FC<AddNewDesignerProps> = ({
  getAllDesignersInput,
}) => {
  const [createDesigner, { loading: createDesignerLoading }] = useMutation<
    CreateDesigner,
    CreateDesignerVariables
  >(CREATE_DESIGNER);
  const [isModalVisible, setModalIsVisible] = useState<boolean>(false);
  const [form] = Form.useForm();

  const handleShowModal = () => {
    setModalIsVisible(true);
  };

  const handleHideModal = () => {
    setModalIsVisible(false);
    form.resetFields();
  };

  const onFormSubmit = () => {
    form.validateFields().then(handleCreateDesigner).catch(console.log);
  };

  const handleCreateDesigner = async (values: any) => {
    const { email, firstName, lastName } = values;

    if (email && firstName && lastName) {
      try {
        await createDesigner({
          variables: { input: { email, firstName, lastName } },
          refetchQueries: [
            {
              query: DESIGNERS,
              variables: {
                ...getAllDesignersInput,
              },
            },
          ],
        });

        successNotification(
          `Invitation for designer ${firstName} ${lastName} has been sent successfully`
        );
        handleHideModal();
      } catch (err) {
        errorNotification(err?.message);
        console.error(err);
      }
    }
  };

  return (
    <Space
      direction="vertical"
      size="large"
      align="end"
      style={{ width: '100%' }}
    >
      <Button type="primary" onClick={handleShowModal}>
        Add new designer
      </Button>

      <Modal
        title="Add new designer"
        visible={isModalVisible}
        okText="Send invitation"
        cancelText="Cancel"
        onCancel={handleHideModal}
        onOk={onFormSubmit}
        okButtonProps={{
          disabled: createDesignerLoading,
          loading: createDesignerLoading,
        }}
      >
        <Form form={form} layout="vertical" name="create_designer_form">
          <Form.Item
            name="firstName"
            label="First name"
            rules={[
              {
                required: true,
                message: 'Please input designers first name!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last name"
            rules={[
              {
                required: true,
                message: 'Please input designers last name!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                type: 'email',
                message:
                  'Email field should not be empty and be a valid email!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Space>
  );
};

export default AddNewDesigner;
