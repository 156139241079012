import {
  useQuery,
  QueryResult,
  QueryOptions,
  WatchQueryFetchPolicy,
  QueryFunctionOptions,
} from '@apollo/client';
// Api
import { GET_LEAGUES_LIST } from 'api/store/queries';
// Types
import { GetLeaguesList } from 'api/store/types/GetLeaguesList';

type BasicQueryOptions = Omit<
  QueryOptions,
  'query' | 'variables' | 'fetchPolicy'
> & {
  fetchPolicy?: WatchQueryFetchPolicy;
} & QueryFunctionOptions;

type UseGetAthleteMerchProductsOptions = {
  options?: BasicQueryOptions;
};

export const useGetLeagues = (
  options?: UseGetAthleteMerchProductsOptions
): QueryResult<GetLeaguesList> => {
  const leagues = useQuery<GetLeaguesList>(GET_LEAGUES_LIST, {
    ...options?.options,
  });

  return leagues;
};
