// Types
import { CreatePresignedUrlForSocialPack_createPresignedUrlForSocialPack } from 'api/designLab/types/CreatePresignedUrlForSocialPack';
import { SocialMediaPackDataType } from 'components/common/SocialMediaPackItem/SocialMediaPackItem';

export type ImageToUpload = {
  fields: string;
  url: string;
  name: string;
  type: string;
  file: File | '';
};

export const getImagesToUpload = (
  preSignedUrls: CreatePresignedUrlForSocialPack_createPresignedUrlForSocialPack[],
  fileList: File[]
): ImageToUpload[] => {
  const res: ImageToUpload[] = [];

  fileList.forEach((file, index) => {
    const preSignedUrl = preSignedUrls[index];
    const imageExtension = file
      ? file.name.split('.')[file.name.split('.').length - 1]
      : '';

    const imageName = `${preSignedUrl?.key || ''}.${imageExtension}`;

    res.push({
      fields: preSignedUrl?.fields || '',
      url: preSignedUrl?.url || '',
      name: imageName,
      type: file?.type || '',
      file: file || '',
    });
  });
  return res;
};

export const uploadToS3 = async (
  responses: CreatePresignedUrlForSocialPack_createPresignedUrlForSocialPack[],
  fileList: File[],
  imageUrls: SocialMediaPackDataType[],
  imageIndexes: number[]
): Promise<SocialMediaPackDataType[]> => {
  const imagesToUpload: ImageToUpload[] = getImagesToUpload(
    responses,
    fileList
  );

  for (const image of imagesToUpload) {
    const { fields, url, name, type, file } = image;
    const formData = new FormData();

    Object.entries(JSON.parse(fields)).forEach(([key, value]) => {
      formData.append(key, value as string);
    });

    const imageToUpdate = imageUrls[imageIndexes[0]];

    imageToUpdate.imgURL = image.url + '/' + image.name;
    imageToUpdate.imageFileKey = image.name;

    imageIndexes.shift();

    formData.append('key', name);
    formData.append('Content-Type', type);
    formData.append('file', file);

    await fetch(url, {
      method: 'POST',
      body: formData,
    });
  }

  return imageUrls;
};

export const returnStringImages = (
  imageUrls: SocialMediaPackDataType[]
): string[] => {
  const images: string[] = [];
  for (const image of imageUrls) {
    images.push(image.imgURL);
  }
  return images;
};
