import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Modal, Button, Typography, Rate } from 'antd';
// Api
import {
  GET_VIDEO_EDITORS,
  GET_VIDEO_EDIT_REQUESTS,
} from 'api/videoLab/queries';
import { REASSIGN_VIDEO_EDITOR } from 'api/videoLab/mutation';
// Types
import { SortDirection, VideoEditorOrderBy } from 'api/graphql-global-types';
import { VideoEditors_videoEditors_entities } from 'api/videoLab/types/VideoEditors';
import {
  ReassignVideoEditor,
  ReassignVideoEditorVariables,
} from 'api/videoLab/types/ReassignVideoEditor';
import {
  GetVideoEditors,
  GetVideoEditorsVariables,
} from 'api/videoLab/types/GetVideoEditors';
// UI
import Table, { SortedInfo, TableFilter } from 'ui/Table';
import { errorNotification, successNotification } from 'ui/Notification';

const { Text } = Typography;

type AssignVideoEditorModalProps = {
  videoEditRequestId?: string;
  isModalVisible?: boolean;
  setModalVisible: (isVisible: boolean) => void;
  handleAssignVideoEditorId?: (designerId: string | null) => void;
  isFromRejectAndReassignModal?: boolean;
};

const AssignVideoEditorModal: React.FC<AssignVideoEditorModalProps> = ({
  videoEditRequestId,
  isModalVisible = false,
  setModalVisible,
  handleAssignVideoEditorId,
  isFromRejectAndReassignModal = false,
}) => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<VideoEditorOrderBy>>({
    order: SortDirection.DESC,
    key: VideoEditorOrderBy.createdAt,
  });
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const getVideoEditorsInput: any = () => {
    const input: any = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;
    });

    return { input };
  };

  const [reassignVideoEditor] = useMutation<
    ReassignVideoEditor,
    ReassignVideoEditorVariables
  >(REASSIGN_VIDEO_EDITOR, {
    refetchQueries: [
      {
        query: GET_VIDEO_EDIT_REQUESTS,
        variables: {
          input: {
            direction: 'ASC',
            orderBy: VideoEditorOrderBy.createdAt,
            limit: 10,
            offset: 0,
          },
        },
      },
    ],
  });

  const handleHideModal = () => {
    setModalVisible(false);
    setSearchFilters([]);
  };

  const handleAssignVideoEditor = async (
    newVideoEditorId: string,
    videoEditorName: string,
    videoEditorId: string,
    videoEditRequestId: string
  ) => {
    if (isFromRejectAndReassignModal) {
      handleAssignVideoEditorId && handleAssignVideoEditorId(videoEditorId);
      handleHideModal();
    } else {
      if (videoEditorId && videoEditRequestId) {
        try {
          await reassignVideoEditor({
            variables: {
              input: {
                newVideoEditorId,
                videoEditorId,
                videoEditRequestId,
              },
            },
          });
          handleHideModal();
          successNotification(
            `Request #${videoEditRequestId} has been assigned to designer ${videoEditorName} successfully`
          );
        } catch (err) {
          errorNotification((err as Error)?.message);
          console.log('reassignVideoEditor error:', { ...(err as Error) });
        }
      }
    }
  };

  const { data, loading } = useQuery<GetVideoEditors, GetVideoEditorsVariables>(
    GET_VIDEO_EDITORS,
    {
      variables: {
        ...getVideoEditorsInput(),
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const columns = [
    {
      title: 'Video editor name',
      dataIndex: 'firstName',
      align: 'left' as const,
      key: 'videoEditorName',
      width: 200,
      withSearch: true,
      render: function RequestDetails(
        firstName: string,
        record: VideoEditors_videoEditors_entities
      ) {
        return (
          <Text>{`${record?.firstName || ''} ${record?.lastName || ''}`}</Text>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'center' as const,
      width: 200,
      withSearch: true,
      key: 'email',
    },
    {
      title: 'Rate',
      key: 'videoEditorRate',
      dataIndex: 'videoEditorRate',
      sorterType: 'text',
      align: 'center' as const,
      width: 200,
      render: function RateWrap(rate: number | null) {
        return <Rate value={rate || 0} disabled allowHalf count={5} />;
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      align: 'center' as const,
      width: 50,
      render: function ActionsWrap(
        id: string,
        record: VideoEditors_videoEditors_entities
      ) {
        return (
          <Button
            type="primary"
            onClick={() =>
              handleAssignVideoEditor(
                id,
                `${record?.firstName || ''} ${record?.lastName || ''}`,
                record.videoEditorDetails.id,
                videoEditRequestId || ''
              )
            }
          >
            Assign
          </Button>
        );
      },
    },
  ];

  return (
    <Modal
      title={
        <>
          Choose video editor to assign video edit request{' '}
          <strong>{`#${videoEditRequestId}`}</strong>
        </>
      }
      visible={isModalVisible}
      footer={null}
      onCancel={handleHideModal}
      width={970}
    >
      <Table<VideoEditors_videoEditors_entities, VideoEditorOrderBy>
        columns={columns}
        data={data?.getVideoEditors?.entities}
        scroll={{ x: 450 }}
        loading={loading}
        total={data?.getVideoEditors?.total}
        defaultPageSize={pageSize}
        setSearchFilters={setSearchFilters}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />
    </Modal>
  );
};

export default AssignVideoEditorModal;
