import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Space, Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// Helpers
import { renderComment } from './components/helpers';
import { formatPrice } from '../ManageMerch/helpers';
// Api
import { GET_EXPERIENCES } from 'api/experience/queries';
import {
  DELETE_EXPERIENCE,
  RECOVER_EXPERIENCE,
} from 'api/experience/mutations';
// Types
import {
  GetExperiences,
  GetExperiencesVariables,
  GetExperiences_getExperiences_entities,
  GetExperiences_getExperiences_entities_images,
} from 'api/experience/types/GetExperiences';
import {
  DeleteExperience,
  DeleteExperienceVariables,
} from 'api/experience/types/DeleteExperience';
import {
  RecoverExperience,
  RecoverExperienceVariables,
} from 'api/experience/types/RecoverExperience';
import {
  ExperienceOrderBy,
  ExperienceStatus,
  SortDirection,
} from 'api/graphql-global-types';
// Components
import CreateEditExperience from './components/CreateEditExperience';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
import { SortedInfo, TableFilter } from 'ui/Table';
import Table from 'ui/Table';

const { confirm } = Modal;

const ManageExperienceTable = (): JSX.Element => {
  const { storeId } = useParams<{ storeId: string | undefined }>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [sort, setSortInfo] = useState<SortedInfo<ExperienceOrderBy>>({
    order: SortDirection.DESC,
    key: ExperienceOrderBy.createdAt,
  });

  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const getExperienceInput: any = () => {
    const input: any = {
      storeIds: [storeId],
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      isOrphanPage: null,
      storeStatuses: null,
    };

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;
    });

    return { input };
  };

  const { data, loading } = useQuery<GetExperiences, GetExperiencesVariables>(
    GET_EXPERIENCES,
    {
      variables: { ...getExperienceInput() },
      fetchPolicy: 'cache-and-network',
    }
  );

  const [deleteExperience] = useMutation<
    DeleteExperience,
    DeleteExperienceVariables
  >(DELETE_EXPERIENCE);

  const [relistExperience] = useMutation<
    RecoverExperience,
    RecoverExperienceVariables
  >(RECOVER_EXPERIENCE);

  const handleRelist = async (id: string) => {
    confirm({
      title: 'Are you sure you want to relist this item?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await relistExperience({
            variables: {
              input: {
                id: id,
              },
            },

            refetchQueries: [
              {
                query: GET_EXPERIENCES,
                variables: { ...getExperienceInput() },
              },
            ],
          });
          successNotification('Successfully relisted');
        } catch (err) {
          errorNotification(
            'Looks like something went wrong. Please try again later.'
          );
        }
      },
    });
  };

  const handleDelete = async (id: string) => {
    confirm({
      title: 'Are you sure you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await deleteExperience({
            variables: {
              input: {
                id: id,
              },
            },

            refetchQueries: [
              {
                query: GET_EXPERIENCES,
                variables: { ...getExperienceInput() },
              },
            ],
          });
          successNotification('Successfully removed');
        } catch (err) {
          errorNotification(
            'Looks like something went wrong. Please try again later.'
          );
        }
      },
    });
  };

  const [itemModalData, setItemModalData] =
    useState<GetExperiences_getExperiences_entities | null>(null);

  const [isProductModalVisible, setIsProductVisible] = useState<boolean>(false);

  const handleShowAddProductModal = () => {
    setIsProductVisible(true);
  };

  const showItemModal = (
    record: GetExperiences_getExperiences_entities | null
  ) => {
    setItemModalData(record);
  };

  const hideItemModal = () => {
    setItemModalData(null);
    setIsProductVisible(false);
  };

  const displayNumberOfUnits = (number: number | null) => {
    if (number === null) {
      return 'unlimited';
    } else {
      return number;
    }
  };

  const columns = [
    {
      title: 'Item',
      dataIndex: 'title',
      fixed: 'left' as const,
      align: 'center' as const,
      width: 206,
    },
    {
      title: 'Images',
      dataIndex: 'images',
      align: 'center' as const,
      width: 96,
      render: function Image(
        images: GetExperiences_getExperiences_entities_images[]
      ) {
        return (
          <Space size="middle" direction="vertical">
            <span>{images?.length}</span>
          </Space>
        );
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: ExperienceOrderBy.price,
      align: 'center' as const,
      sorterType: 'number',
      width: 106,
      render: function Price(
        text: any,
        record: GetExperiences_getExperiences_entities
      ) {
        return <span>{formatPrice(record.price)}</span>;
      },
    },
    {
      title: 'Stock',
      dataIndex: 'numberOfUnits',
      align: 'center' as const,
      width: 96,
      render: function Price(
        text: any,
        record: GetExperiences_getExperiences_entities
      ) {
        return <span>{displayNumberOfUnits(record.numberOfUnits)}</span>;
      },
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      align: 'center' as const,
      width: 210,
      render: function Comment(
        text: any,
        record: GetExperiences_getExperiences_entities
      ) {
        return <span>{renderComment(record.status)}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center' as const,
      width: 106,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 120,
      render: function Actions(
        text: string,
        record: GetExperiences_getExperiences_entities
      ) {
        return (
          <Space size="middle" direction="vertical">
            <Button
              type="primary"
              onClick={() => {
                showItemModal(record);
              }}
            >
              Edit
            </Button>

            <Button type="default" onClick={() => handleDelete(record.id)}>
              Remove
            </Button>
            {record?.status === ExperienceStatus.Inactive && (
              <Button type="default" onClick={() => handleRelist(record.id)}>
                Relist
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Space
        direction="vertical"
        size="middle"
        align="end"
        className="streams-requests__cta-wrapper"
      >
        <Button type="primary" onClick={handleShowAddProductModal}>
          Add new item
        </Button>
      </Space>

      <Table<GetExperiences_getExperiences_entities, ExperienceOrderBy>
        columns={columns}
        data={data?.getExperiences?.entities}
        scroll={{ x: 800 }}
        loading={loading}
        total={data?.getExperiences?.total}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />

      <div className="createEditItemModal">
        <Modal
          title={itemModalData?.id ? 'Edit item' : 'New item'}
          visible={!!itemModalData || isProductModalVisible}
          onCancel={hideItemModal}
          className="createEditItemModal"
          width={'90vw'}
          footer={null}
          destroyOnClose
        >
          <CreateEditExperience
            currentAction={itemModalData?.id ? 'Edit' : 'New'}
            storeId={storeId || ''}
            record={itemModalData}
            hideItemModal={hideItemModal}
            getExperienceInput={getExperienceInput}
          />
        </Modal>
      </div>
    </div>
  );
};

export default ManageExperienceTable;
