import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { AutoComplete, Typography, Space } from 'antd';
// Api
import { GET_STORES } from 'api/store/queries';
// Types
import { GetStores, GetStoresVariables } from 'api/store/types/GetStores';
import { AdminStoresOrderBy, UserRole } from 'api/graphql-global-types';
import { GetMemorabiliaVariables } from 'api/memorabilia/types/GetMemorabilia';
// Components
import CreateEditMemorabilia from 'components/common/ManageProductsTable/components/CreateEditMemorabilia/CreateEditMemorabilia';
// Styles
import styles from './CreateMemorabilia.module.scss';

type CreateMemorabiliaProps = {
  getMemorabiliaInput: () => GetMemorabiliaVariables;
  onClose: () => void;
};

type AutoCompleteOption = {
  value: string;
  label: string;
};

const { Title, Text } = Typography;

const CreateMemorabilia = ({
  getMemorabiliaInput,
  onClose,
}: CreateMemorabiliaProps): JSX.Element => {
  const { data: storesData, refetch } = useQuery<GetStores, GetStoresVariables>(
    GET_STORES,
    {
      variables: {
        input: {
          orderBy: AdminStoresOrderBy.storeName,
        },
        storeRoles: [
          UserRole.Athlete,
          UserRole.Organization,
          UserRole.ContentCreator,
        ],
      },
    }
  );

  const [options, setOptions] = useState<AutoCompleteOption[]>([]);
  const [selectedOption, setSelectedOption] =
    useState<AutoCompleteOption | null>(null);

  useEffect(() => {
    if (storesData?.adminStores.entities) {
      setOptions(
        storesData.adminStores.entities.map((store) => ({
          value: store.id,
          label: store.storeDetails?.storeName
            ? store.storeDetails.storeName
            : `${store.firstName} ${store.lastName}`,
        }))
      );
    }
  }, [storesData]);

  const handleSelectStore = (
    value: string,
    option: AutoCompleteOption
  ): void => {
    setSelectedOption(option);
  };

  const handleOnClear = () => {
    setSelectedOption(null);
  };

  const handleOnSearch = useMemo(() => {
    const loadOptions = (storeName: string) => {
      refetch({
        storeRoles: [
          UserRole.Athlete,
          UserRole.Organization,
          UserRole.ContentCreator,
        ],
        input: { storeName, orderBy: AdminStoresOrderBy.storeName },
      });
    };

    return debounce(loadOptions, 400);
  }, [refetch]);

  return (
    <div>
      <Space className={styles.storeSection} direction="vertical">
        <Title>Store details</Title>

        <AutoComplete
          allowClear
          className={styles.autoComplete}
          placeholder="Search Account Name"
          options={options}
          value={selectedOption?.label}
          onSearch={handleOnSearch}
          onSelect={handleSelectStore}
          onClear={handleOnClear}
        />

        {selectedOption && <Text>Selected store: {selectedOption?.label}</Text>}
      </Space>

      <CreateEditMemorabilia
        currentAction="New"
        storeId={selectedOption?.value || ''}
        record={null}
        hideItemModal={onClose}
        getMemorabiliaInput={getMemorabiliaInput}
      />
    </div>
  );
};

export default CreateMemorabilia;
