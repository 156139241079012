import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Button, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// Api
import { DEACTIVATE_VIDEO_EDITOR_ACCOUNT } from 'api/videoLab/mutation';
import { GET_VIDEO_EDITORS } from 'api/videoLab/queries';
// Types
import {
  DeactivateVideoEditorAccount,
  DeactivateVideoEditorAccountVariables,
} from 'api/videoLab/types/DeactivateVideoEditorAccount';
import { VideoEditors_videoEditors_entities } from 'api/videoLab/types/VideoEditors';
import { GetAllEditorsInput } from '../index';
// Components
import CreateEditVideoEditorModal from './CreateEditVideoEditorModal';
// UI
import { errorNotification, successNotification } from 'ui/Notification';

const { confirm } = Modal;

type ActionsProps = {
  videoEditor: VideoEditors_videoEditors_entities;
  getAllEditorsInput: { input: GetAllEditorsInput };
};

const Actions: React.FC<ActionsProps> = ({
  videoEditor,
  getAllEditorsInput,
}) => {
  const [isVideoEditorEditing, toggleVideoEditorEditing] =
    useState<boolean>(false);

  const [
    deactivateVideoEditorAccount,
    { loading: deactivateVideoEditorAccountLoading },
  ] = useMutation<
    DeactivateVideoEditorAccount,
    DeactivateVideoEditorAccountVariables
  >(DEACTIVATE_VIDEO_EDITOR_ACCOUNT);

  const onDeactivateDesignerButtonClick = () => {
    confirm({
      title: `Are You sure You want to deactivate video editor ${
        videoEditor?.firstName || ''
      } ${videoEditor?.lastName || ''}?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Deactivate',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: handleDeactivateVideoEditor,
    });
  };

  const handleDeactivateVideoEditor = async () => {
    try {
      await deactivateVideoEditorAccount({
        variables: { id: videoEditor.id },
        refetchQueries: [
          {
            query: GET_VIDEO_EDITORS,
            variables: {
              ...getAllEditorsInput,
            },
          },
        ],
      });
      successNotification(
        `Video editor ${videoEditor?.firstName || ''} ${
          videoEditor?.lastName || ''
        } has been deactivated successfully`
      );
    } catch (error) {
      errorNotification((error as Error)?.message);
      console.error(error);
    }
  };

  if (videoEditor?.videoEditorStatus === 'Inactive') {
    return null;
  }

  return (
    <>
      <Space size="middle" direction="vertical">
        <Button
          type="primary"
          onClick={() => {
            toggleVideoEditorEditing(true);
          }}
        >
          Edit
        </Button>
        <Button
          type="primary"
          danger
          onClick={onDeactivateDesignerButtonClick}
          loading={deactivateVideoEditorAccountLoading}
        >
          Deactivate
        </Button>
      </Space>
      <CreateEditVideoEditorModal
        videoEditor={videoEditor}
        toggleVideoEditorModal={toggleVideoEditorEditing}
        isCreateEditEditorModalOpen={isVideoEditorEditing}
      />
    </>
  );
};

export default Actions;
