import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { List, Button, Modal } from 'antd';
import {
  CloseCircleOutlined,
  PlayCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { GET_ADMIN_WATCH_STREAMS } from 'api/streams/queries';
// Api
import { DELETE_STREAM_CLIP } from 'api/streams/mutations';
// Types
import { GetAdminWatchStreams_adminStreams_entities_streamRecords } from 'api/streams/types/GetAdminWatchStreams';
import {
  DeleteStreamClip,
  DeleteStreamClipVariables,
} from 'api/streams/types/DeleteStreamClip';
// Helpers
import { convertToMmSs } from 'pages/WatchStream/helpers';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import StreamClipPlayModal from './components/StreamClipPlayModal/StreamClipPlayModal';
// Styles
import styles from './StreamClip.module.scss';

type StreamClipProps = {
  streamRecord:
    | GetAdminWatchStreams_adminStreams_entities_streamRecords
    | undefined;
};

export type StreamClipType = {
  startTs: number;
  endTs: number;
  title: string | null;
  url: string | null;
};

const StreamClip: React.FC<StreamClipProps> = ({ streamRecord }) => {
  const [selectedClip, setSelectedClip] = useState<StreamClipType | null>(null);

  const [isPlayedModalVisible, setIsPlayedModalVisible] =
    useState<boolean>(false);
  const { confirm } = Modal;

  const { streamId: streamIdFromParams } = useParams<{
    streamId: string;
  }>();

  const [deleteStreamClip] = useMutation<
    DeleteStreamClip,
    DeleteStreamClipVariables
  >(DELETE_STREAM_CLIP);

  const handleDeleteClip = async (id: string) => {
    confirm({
      title: 'Are you sure you want to delete this clip?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await deleteStreamClip({
            variables: {
              input: {
                id,
              },
            },

            refetchQueries: [
              {
                query: GET_ADMIN_WATCH_STREAMS,
                variables: { input: { id: streamIdFromParams } },
              },
            ],
          });
          successNotification('Successfully removed');
        } catch (err) {
          errorNotification(
            'Looks like something went wrong. Please try again later.'
          );
        }
      },
    });
  };

  const handlePlayClip = (clip: StreamClipType) => {
    setIsPlayedModalVisible(true);
    setSelectedClip(clip);
  };

  const handleClosePlayClipModal = () => {
    setIsPlayedModalVisible(false);
    setSelectedClip(null);
  };

  return (
    <>
      {streamRecord?.clips && (
        <List
          itemLayout="horizontal"
          dataSource={streamRecord?.clips}
          renderItem={(clip) => {
            return (
              <List.Item
                actions={[
                  <Button
                    key={`${clip.id}-play`}
                    icon={<PlayCircleOutlined />}
                    disabled={!clip.url}
                    type="link"
                    onClick={() => clip && handlePlayClip(clip)}
                  />,
                  <Button
                    key={clip.id}
                    icon={<CloseCircleOutlined />}
                    type="link"
                    onClick={() => handleDeleteClip(clip.id)}
                    danger
                  />,
                ]}
              >
                <p className={styles.title}>{clip.title}</p>
                <p>
                  {`Start: ${convertToMmSs(
                    clip.startTs
                  )} - End: ${convertToMmSs(clip.endTs)}`}
                </p>
                <p>{clip.status}</p>
              </List.Item>
            );
          }}
        />
      )}
      {selectedClip && (
        <StreamClipPlayModal
          clip={selectedClip}
          onHide={handleClosePlayClipModal}
          isVisible={isPlayedModalVisible}
        />
      )}
    </>
  );
};

export default StreamClip;
