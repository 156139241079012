import React from 'react';
import { useMutation } from '@apollo/client';
import { Button } from 'antd';
// Api
import { ARCHIVE_DESIGN } from 'api/designLab/mutations';
// Types
import { ArchiveMerchDesign } from 'api/designLab/types/ArchiveMerchDesign';
import { ButtonType } from 'antd/lib/button';
// UI
import { errorNotification, successNotification } from 'ui/Notification';

type ArchiveDesignProps = {
  designRequestId: string;
  buttonTitle?: string;
  buttonType?: ButtonType;
};

const ArchiveDesignAction: React.FC<ArchiveDesignProps> = ({
  designRequestId,
  buttonTitle = 'Archive',
  buttonType = 'primary',
}) => {
  const [archiveDesign, { loading: archiveDesignLoading }] =
    useMutation<ArchiveMerchDesign>(ARCHIVE_DESIGN);

  const handleArchiveDesign = async () => {
    if (designRequestId) {
      try {
        await archiveDesign({
          variables: {
            input: {
              designRequestId,
            },
          },
        });
        successNotification(
          `Request #${designRequestId} has been archived successfully`
        );
      } catch (err) {
        errorNotification((err as Error)?.message);
        console.log('handleArchiveDesign error:', { ...(err as Error) });
      }
    }
  };

  return (
    <Button
      type={buttonType}
      loading={archiveDesignLoading}
      onClick={handleArchiveDesign}
    >
      {buttonTitle}
    </Button>
  );
};

export default ArchiveDesignAction;
