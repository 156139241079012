import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { Form, Select, Button, Typography, AutoComplete, Input } from 'antd';
// Helpers
import { getProperErrorMessage } from 'helpers/errors';
import { DeleteOutlined } from '@ant-design/icons';
// Api
import { GET_STORES } from 'api/store/queries';
import { SEND_MANUAL_INTERVIEW_INVITES } from 'api/interview/mutations';
import { GET_STORE_INTERVIEWERS } from 'api/interviewer/queries';
import { refetchQueries } from '../../InterviewsTable/InterviewsTable';
// Types
import {
  SendManualInterviewInvites,
  SendManualInterviewInvitesVariables,
} from 'api/interview/types/SendManualInterviewInvites';
import {
  GetStoreInterviewers,
  GetStoreInterviewersVariables,
} from 'api/interviewer/types/GetStoreInterviewers';
import { GetStores, GetStoresVariables } from 'api/store/types/GetStores';
import {
  AdminStoresOrderBy,
  InterviewSourceType,
  StoreStatus,
  UserRole,
} from 'api/graphql-global-types';
// Components
import SearchStreamsComponent from '../SearchStreams/SearchStreams';
import SearchMerchComponent from '../SearchMerch/SearchMerch';
import SearchProductsComponent from '../SearchProducts/SearchProducts';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
// Styles
import styles from './SendInviteModal.module.scss';

const { Text } = Typography;
const { Option } = Select;

export const interviewSourceTypeOptions = [
  { label: 'PPV Participant', value: InterviewSourceType.PPVParticipant },
  { label: 'Merch', value: InterviewSourceType.Merch },
  { label: 'Other', value: InterviewSourceType.Other },
  { label: 'Paid', value: InterviewSourceType.Paid },
  { label: 'Product', value: InterviewSourceType.Product },
  { label: 'Memorabilia', value: InterviewSourceType.Memorabilia },
  { label: 'QA Millions', value: InterviewSourceType.QAMillions },
  { label: 'Big Moment', value: InterviewSourceType.BigMoment },
];

export type AutoCompleteOption = {
  value: string | number;
  label: string;
};

type SendInviteModalProps = {
  onClose: () => void;
};

export const SendInviteModal = ({
  onClose,
}: SendInviteModalProps): JSX.Element => {
  const [autoCompleteValue, setAutoCompleteValue] = useState<string>('');
  const [storeOptions, setStoreOptions] = useState<AutoCompleteOption[]>([]);
  const [selectedStoreOptions, setSelectedStoreOptions] = useState<
    AutoCompleteOption[]
  >([]);

  const [selectedStreamOption, setSelectedStreamOption] =
    useState<AutoCompleteOption | null>(null);

  const [selectedMerchOption, setSelectedMerchOption] =
    useState<AutoCompleteOption | null>(null);

  const [selectedProductOption, setSelectedProductOption] =
    useState<AutoCompleteOption | null>(null);

  const [selectedInterviewType, setSelectedInterviewType] =
    useState<InterviewSourceType | null>();

  const [form] = Form.useForm();

  const handleResetFields = () => {
    form.resetFields();
    setSelectedStoreOptions([]);
    setSelectedStreamOption(null);
    setSelectedMerchOption(null);
    setSelectedProductOption(null);
  };

  const handleSelectStore = (
    value: string,
    option: AutoCompleteOption
  ): void => {
    setSelectedStoreOptions((prevOptions) => [...prevOptions, option]); // Add selected option to array

    setAutoCompleteValue('');
  };

  const handleOnClearStore = () => {
    setSelectedStoreOptions([]);
  };

  const handleDeselectStore = (value: string) => {
    setSelectedStoreOptions((prevOptions) =>
      prevOptions.filter((option) => option.value !== value)
    );
  };

  const [
    sendManualInterviewInvite,
    { loading: sendManualInterviewInviteLoading },
  ] = useMutation<
    SendManualInterviewInvites,
    SendManualInterviewInvitesVariables
  >(SEND_MANUAL_INTERVIEW_INVITES, { refetchQueries });

  const { data: interviewers } = useQuery<
    GetStoreInterviewers,
    GetStoreInterviewersVariables
  >(GET_STORE_INTERVIEWERS, {
    variables: {
      input: {
        limit: 100,
        offset: 0,
        status: [StoreStatus.Active],
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: storesData, refetch } = useQuery<GetStores, GetStoresVariables>(
    GET_STORES,
    {
      variables: {
        input: {
          orderBy: AdminStoresOrderBy.storeName,
        },
        storeRoles: [
          UserRole.Athlete,
          UserRole.Organization,
          UserRole.ContentCreator,
        ],
      },
    }
  );

  const handleOnSearchStores = useMemo(() => {
    const loadOptions = (storeName: string) => {
      refetch({
        storeRoles: [
          UserRole.Athlete,
          UserRole.Organization,
          UserRole.ContentCreator,
        ],
        input: { storeName, orderBy: AdminStoresOrderBy.storeName },
      });
    };

    return debounce(loadOptions, 400);
  }, [refetch]);

  useEffect(() => {
    if (storesData?.adminStores.entities) {
      setStoreOptions(
        storesData.adminStores.entities.map((store) => ({
          value: store.id,
          label: store.storeDetails?.storeName
            ? store.storeDetails.storeName
            : `${store.firstName} ${store.lastName}`,
        }))
      );
    }
  }, [storesData]);

  const handleFormFinish = async () => {
    const intervieweeIds = selectedStoreOptions.map(
      (option) => option.value as string
    );
    const values = form.getFieldsValue();

    if (intervieweeIds.length) {
      const sourceInput = {
        type: values.interviewType,
        ...(values.interviewType === InterviewSourceType.Merch && {
          merchId: Number(selectedMerchOption?.value),
        }),
        ...(values.interviewType === InterviewSourceType.Product && {
          productId: selectedMerchOption?.value as string,
        }),
        ...(values.interviewType === InterviewSourceType.Memorabilia && {
          productId: selectedMerchOption?.value as string,
        }),
        ...(values.interviewType === InterviewSourceType.PPVParticipant && {
          streamId: selectedStreamOption?.value as string,
        }),
      };

      try {
        await sendManualInterviewInvite({
          variables: {
            input: {
              intervieweeIds,
              source: sourceInput,
              adminNotes: values.adminNotes,
              interviewerId: values.interviewerId,
            },
          },
        });

        successNotification('Interview invitation sent successfully');
        handleResetFields();

        onClose();
      } catch (err) {
        errorNotification(getProperErrorMessage(err, 'Something went wrong'));
      }
    }
  };

  const isLoading = sendManualInterviewInviteLoading;

  const showSearchStream =
    selectedStoreOptions?.length > 0 &&
    selectedInterviewType === InterviewSourceType.PPVParticipant;
  const showSearchMerch =
    selectedStoreOptions?.length > 0 &&
    selectedInterviewType === InterviewSourceType.Merch;

  const isProductOrMemorabilia =
    selectedInterviewType === InterviewSourceType.Product ||
    selectedInterviewType === InterviewSourceType.Memorabilia;

  const showSearchProduct =
    selectedStoreOptions?.length > 0 && isProductOrMemorabilia;

  return (
    <Form
      form={form}
      layout="horizontal"
      name="sendInviteForm"
      autoComplete="off"
      onFinish={handleFormFinish}
    >
      <div>
        <Form.Item
          name="interviewType"
          label={<Text>Interview type</Text>}
          rules={[
            {
              required: true,
              message: 'Please select interview type',
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            placeholder="Interview type"
            onChange={(event) => {
              setSelectedInterviewType(event as InterviewSourceType);
              form.resetFields(['interviewerId']);
              setSelectedStoreOptions([]); // Reset to empty array
            }}
          >
            {interviewSourceTypeOptions?.map(({ value, label }) => {
              return (
                <Option key={value} value={value} name={label}>
                  {label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </div>

      <Form.Item name="interviewerId" label={<Text>Interviewer</Text>}>
        <Select
          showSearch
          allowClear
          placeholder="Select interviewer"
          filterOption={(input, option) =>
            option?.name
              ?.toLowerCase()
              .indexOf(input.replace(/\s\s+/g, ' ').trim().toLowerCase()) >= 0
          }
        >
          {interviewers?.getStoreInterviewers?.entities?.map(
            ({ firstName, lastName, id }) => {
              const name = [firstName, lastName].join(' ').trim();
              return (
                <Option key={id} value={id} name={name}>
                  {name}
                </Option>
              );
            }
          )}
        </Select>
      </Form.Item>

      <div className={styles.storeSection}>
        <Text className={styles.accountText}>Accounts:</Text>
        <AutoComplete
          allowClear
          id="store-search"
          className={styles.autoComplete}
          placeholder="Search Account Name"
          options={storeOptions}
          onSearch={handleOnSearchStores}
          onSelect={handleSelectStore}
          onClear={handleOnClearStore}
          value={autoCompleteValue}
          onChange={(value) => setAutoCompleteValue(value)} // Update the value prop based on user input
        />

        {selectedStoreOptions.length > 0 && (
          <>
            <Text className={styles.text}>Selected Accounts: </Text>
            <div className={styles.selectedStores}>
              {selectedStoreOptions.map((option) => (
                <span key={option.value} className={styles.selectedStoreItem}>
                  {option.label}{' '}
                  <Button
                    onClick={() => handleDeselectStore(option.value as string)}
                  >
                    <DeleteOutlined /> Remove selected account
                  </Button>
                </span>
              ))}
            </div>
          </>
        )}
      </div>

      {showSearchStream && (
        <SearchStreamsComponent
          storeIds={selectedStoreOptions?.map((item) => item.value as string)}
          selectedStreamOption={selectedStreamOption}
          setSelectedStreamOption={setSelectedStreamOption}
        />
      )}

      {showSearchMerch && (
        <SearchMerchComponent
          storeIds={selectedStoreOptions?.map((item) => item.value as string)}
          selectedMerchOption={selectedMerchOption}
          setSelectedMerchOption={setSelectedMerchOption}
        />
      )}

      {showSearchProduct && (
        <SearchProductsComponent
          storeIds={selectedStoreOptions?.map((item) => item.value as string)}
          selectedProductOption={selectedProductOption}
          setSelectedProductOption={setSelectedProductOption}
        />
      )}

      <Form.Item name="adminNotes" label={<Text>Admin Notes</Text>}>
        <Input />
      </Form.Item>

      <Form.Item className={styles.finishButtonContainer}>
        <Button
          className={styles.finishButton}
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          Send interview invitation
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SendInviteModal;
