import React, {
  forwardRef,
  MouseEventHandler,
  ComponentPropsWithRef,
  Children,
  cloneElement,
} from 'react';
import cn from 'classnames';
// Ui2
import Text from 'uiShared/Text/Text';
import Button, { ButtonColor } from 'uiShared/Button/Button';
// Styles
import styles from './Input.module.scss';

export type InputSize = 'large' | 'medium' | 'small';

export type InputProps = {
  name: string;
  label?: string;
  error?: string;
  isReady?: boolean;
  withPasswordToggle?: boolean;
  withDotsInPlaceholder?: boolean;
  fieldSize?: InputSize;
  theme?: 'black' | 'white';
  action?: {
    label: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    loading?: boolean;
    color?: ButtonColor;
  };
  prefix?: string;
  notice?: string;
  topNotice?: string;
  tooltipText?: string;
  customInput?: JSX.Element;
} & ComponentPropsWithRef<'input'>;
type Ref = HTMLInputElement;

// NOTE: copy from ui2 from fs-web
const Input = forwardRef<Ref, InputProps>(
  (
    {
      name,
      label,
      error,
      className,
      action,
      disabled = false,
      readOnly = false,
      id = name,
      type = 'text',
      isReady = false,
      withPasswordToggle = false,
      withDotsInPlaceholder = false,
      fieldSize = 'large',
      prefix,
      notice,
      topNotice,
      theme = 'white',
      customInput,
      ...inputProps
    },
    ref
  ) => {
    const isError = Boolean(error);
    const inputId = `${id}-text-field`;
    const errorId = `${id}-error-text`;
    const showEndAdornment = withPasswordToggle || isReady;
    const inputClassNames = cn(styles.input, {
      [styles[`size-${fieldSize}`]]: !!fieldSize,
      [styles.withPrefix]: prefix,
      [styles.withDotsInPlaceholder]: withDotsInPlaceholder,
    });

    return (
      <div className={cn(styles.inputContainerWrapper, className)}>
        <div
          className={cn(styles.inputContainer, {
            [styles[`size-${fieldSize}`]]: !!fieldSize,
            [styles.error]: isError,
            [styles[`theme-${theme}`]]: !!theme,
          })}
        >
          {label && (
            <label
              htmlFor={inputId}
              className={cn(styles.label, {
                [styles[`size-${fieldSize}`]]: !!fieldSize,
              })}
            >
              {label}
              {topNotice && (
                <Text
                  isEllipsis
                  xs="extra-small"
                  color="light-white"
                  className={styles.topNotice}
                >
                  {topNotice}
                </Text>
              )}
            </label>
          )}

          <div
            className={cn(styles.inputWrapper, {
              [styles.disabled]: disabled,
              [styles.withEndAdornment]: showEndAdornment || action,
              [styles[`size-${fieldSize}`]]: !!fieldSize,
            })}
          >
            {prefix && (
              <span
                className={cn(styles.inputPrefix, {
                  [styles['error']]: !!error,
                  [styles['disabled']]: disabled,
                })}
              >
                {prefix}
              </span>
            )}

            {customInput ? (
              Children.map(customInput, (child) => {
                return cloneElement(
                  child,
                  {
                    className: cn(customInput.props.className, inputClassNames),
                  },
                  null
                );
              })
            ) : (
              <input
                ref={ref}
                id={inputId}
                name={name}
                type={type}
                className={inputClassNames}
                aria-invalid={isError}
                aria-describedby={isError ? errorId : undefined}
                disabled={disabled}
                readOnly={readOnly}
                {...inputProps}
              />
            )}

            {action && (
              <div className={styles.actionWrapper}>
                <Button
                  className={styles.action}
                  onClick={action.onClick}
                  disabled={disabled || action.disabled}
                  loading={action.loading}
                  color={action.color || 'harvest-gold'}
                >
                  {action.label}
                </Button>
              </div>
            )}
          </div>
        </div>

        {notice && (
          <Text
            className={cn(styles.notice, {
              [styles[`size-${fieldSize}`]]: !!fieldSize,
            })}
            xs="extra-small"
            color="black"
          >
            {notice}
          </Text>
        )}

        {isError && (
          <Text
            id={errorId}
            color="maximum-red"
            xs="extra-small"
            className={cn(styles.errorHint, {
              [styles[`size-${fieldSize}`]]: !!fieldSize,
            })}
          >
            {error}
          </Text>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
