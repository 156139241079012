import React from 'react';
import { Typography } from 'antd';
// Helpers
import { formatCurrencyString, renderedCheckForBooleans } from 'helpers/utils';
// Types
import { AdminStoresOrderBy, StoreStatus } from 'api/graphql-global-types';

const { Link } = Typography;

// these columns are the same for athletes and gyms, changing this file will affect both tables

const HAVE_DONT_HAVE_FILTER = [
  {
    text: 'Have',
    value: 'true',
  },
  {
    text: "Don't have",
    value: 'false',
  },
];

export const ManagedByColumn = {
  title: 'Managed by',
  align: 'center' as const,
  width: 130,
  sorterType: 'text',
  withSearch: true,
  key: AdminStoresOrderBy.managedByName,
  dataIndex: 'storeManagementName',
};

export const NumberColumn = {
  title: 'Number',
  dataIndex: 'id',
  align: 'center' as const,
  fixed: 'left' as const,
  width: 90,
};

export const InitialAssessmentColumn = {
  title: 'Initial Assessment',
  dataIndex: 'initialAssessment',
  key: AdminStoresOrderBy.initialAssessment,
  width: 150,
  sorterType: 'number',
  withFloatRangeFilter: true,
  defaultNumericalValues: [0, 5],
};

export const LatestAssessmentColumn = {
  title: 'Latest Assessment',
  dataIndex: 'latestAssessment',
  key: AdminStoresOrderBy.latestAssessment,
  width: 150,
  sorterType: 'number',
  withFloatRangeFilter: true,
  defaultNumericalValues: [0, 5],
};

export const TiktokColumn = {
  title: 'Tiktok Link',
  dataIndex: 'tiktokLink',
  key: 'hasTiktok',
  align: 'left' as const,
  width: 220,
  withRadioFilters: true,
  filters: HAVE_DONT_HAVE_FILTER,
  filterMultiple: false,
  render: function SocialMediaLink(tiktokLink: string): JSX.Element {
    return (
      <Link href={tiktokLink} target="_blank">
        {tiktokLink}
      </Link>
    );
  },
};

export const InstagramColumn = {
  title: 'Instagram Link',
  dataIndex: 'instagramLink',
  key: 'hasInstagram',
  align: 'left' as const,
  width: 220,
  withRadioFilters: true,
  filters: HAVE_DONT_HAVE_FILTER,
  filterMultiple: false,
  render: function SocialMediaLink(instagramLink: string): JSX.Element {
    return (
      <Link href={instagramLink} target="_blank">
        {instagramLink}
      </Link>
    );
  },
};

export const TwitterColumn = {
  title: 'Twitter Link',
  dataIndex: 'twitterLink',
  key: 'hasTwitter',
  align: 'left' as const,
  width: 220,
  withRadioFilters: true,
  filters: HAVE_DONT_HAVE_FILTER,
  filterMultiple: false,
  render: function SocialMediaLink(twitterLink: string): JSX.Element {
    return (
      <Link href={twitterLink} target="_blank">
        {twitterLink}
      </Link>
    );
  },
};

export const WebsiteColumn = {
  title: 'Website Link',
  dataIndex: 'website',
  key: 'hasWebsite',
  align: 'left' as const,
  width: 220,
  withRadioFilters: true,
  filters: HAVE_DONT_HAVE_FILTER,
  filterMultiple: false,
  render: function SocialMediaLink(website: string): JSX.Element {
    return (
      <Link href={website} target="_blank">
        {website}
      </Link>
    );
  },
};

export const MillionsFollowersCountColumn = {
  title: 'MILLIONS Followers',
  dataIndex: 'followerCount',
  key: AdminStoresOrderBy.followerCount,
  align: 'center' as const,
  width: 180,
  withSearch: false,
  sorterType: 'number',
  withNumericalRangeFilter: true,
  defaultNumericalValues: [0, 100],
};

export const SocialFollowersCountColumn = {
  title: 'Social Followers',
  dataIndex: 'socialFollowerCount',
  key: AdminStoresOrderBy.socialFollowerCount,
  align: 'center' as const,
  width: 180,
  withSearch: false,
  sorterType: 'number',
  withNumericalRangeFilter: true,
  defaultNumericalValues: [0, 100],
};

export const FacebookColumn = {
  title: 'Facebook Link',
  dataIndex: 'facebookLink',
  key: 'hasFacebook',
  align: 'left' as const,
  width: 220,
  withRadioFilters: true,
  filters: HAVE_DONT_HAVE_FILTER,
  filterMultiple: false,
  render: function SocialMediaLink(facebookLink: string): JSX.Element {
    return (
      <Link href={facebookLink} target="_blank">
        {facebookLink}
      </Link>
    );
  },
};

export const YoutubeColumn = {
  title: 'Youtube Link',
  dataIndex: 'youtubeLink',
  key: 'hasYoutube',
  align: 'left' as const,
  width: 220,
  withRadioFilters: true,
  filters: HAVE_DONT_HAVE_FILTER,
  filterMultiple: false,
  render: function SocialMediaLink(youtubeLink: string): JSX.Element {
    return (
      <Link href={youtubeLink} target="_blank">
        {youtubeLink}
      </Link>
    );
  },
};

export const MerchColumn = {
  title: 'Merch',
  dataIndex: 'hasMerch',
  key: 'hasMerch',
  align: 'center' as const,
  withRadioFilters: true,
  filters: HAVE_DONT_HAVE_FILTER,
  filterMultiple: false,
  width: 100,
  render: renderedCheckForBooleans,
};

export const AMAColumn = {
  title: 'AMA',
  dataIndex: 'hasAma',
  key: 'hasAma',
  align: 'center' as const,
  withRadioFilters: true,
  filters: HAVE_DONT_HAVE_FILTER,
  filterMultiple: false,
  width: 100,
  render: renderedCheckForBooleans,
};

export const StreamColumn = {
  title: 'Stream',
  dataIndex: 'hasStreams',
  key: 'hasStreams',
  align: 'center' as const,
  withRadioFilters: true,
  filters: HAVE_DONT_HAVE_FILTER,
  filterMultiple: false,
  width: 130,
  render: renderedCheckForBooleans,
};

export const ProductsColumn = {
  title: 'Products',
  dataIndex: 'hasProducts',
  key: 'hasProducts',
  align: 'center' as const,
  withRadioFilters: true,
  filters: HAVE_DONT_HAVE_FILTER,
  filterMultiple: false,
  width: 130,
  render: renderedCheckForBooleans,
};

export const ExperiencesColumn = {
  title: 'Experiences',
  dataIndex: 'hasExperiences',
  key: 'hasExperiences',
  align: 'center' as const,
  withRadioFilters: true,
  filters: HAVE_DONT_HAVE_FILTER,
  filterMultiple: false,
  width: 130,
  render: renderedCheckForBooleans,
};

export const RevenueColumn = {
  title: 'Revenue',
  dataIndex: 'revenue',
  key: AdminStoresOrderBy.revenue,
  align: 'center' as const,
  width: 180,
  sorterType: 'number',
  render: (revenue: number): string => formatCurrencyString(revenue),
  withNumericalRangeFilter: true,
  defaultNumericalValues: [0, 1000],
};

export const StoreStatusColumn = {
  title: 'Store Status',
  dataIndex: 'status',
  key: AdminStoresOrderBy.status,
  align: 'center' as const,
  sorterType: 'text',
  withRadioFilters: true,
  filters: [
    {
      text: StoreStatus.Active,
      value: StoreStatus.Active,
    },
    {
      text: StoreStatus.Inactive,
      value: StoreStatus.Inactive,
    },
  ],
  filterMultiple: false,
  width: 160,
};

export const OpenDesignRequestColumn = {
  title: 'Open Design Request',
  dataIndex: 'hasOpenDesignRequest',
  key: 'hasOpenDesignRequest',
  align: 'center' as const,
  render: renderedCheckForBooleans,
  withRadioFilters: true,
  filters: HAVE_DONT_HAVE_FILTER,
  filterMultiple: false,
  width: 140,
};

export const CountryColumn = {
  title: 'Country',
  align: 'center' as const,
  width: 130,
  dataIndex: 'country',
};

export const StateColumn = {
  title: 'State',
  align: 'center' as const,
  width: 130,
  dataIndex: 'state',
};

export const CityColumn = {
  title: 'City',
  align: 'center' as const,
  width: 130,
  dataIndex: 'city',
};
