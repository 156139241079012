import React from 'react';
import { useApolloClient } from '@apollo/client';
// Api
import { GET_MERCH_PRODUCTS_FOR_HOME_PAGE_SETUP } from 'api/merch/queries';
// Types
import {
  GetMerchProductsForHomePageSetup,
  GetMerchProductsForHomePageSetupVariables,
} from 'api/merch/types/GetMerchProductsForHomePageSetup';
import { MerchProductOrderBy, SortDirection } from 'api/graphql-global-types';
// Components
import SectionItem, {
  LoadMore,
  OptionsItem,
} from 'pages/HomePageManagement/components/SectionItem';

type SetupMerchItemProps = {
  storeId: string;
  selectedMerch: OptionsItem;
  onMerchChange: (merch: OptionsItem) => void;
  clearCachedOptions: boolean;
  setClearCachedOptions: React.Dispatch<React.SetStateAction<boolean>>;
};

const PER_PAGE = 10;

const SetupMerchItem: React.FC<SetupMerchItemProps> = ({
  storeId,
  selectedMerch,
  onMerchChange,
  clearCachedOptions,
  setClearCachedOptions,
}) => {
  const client = useApolloClient();

  const loadMore: LoadMore = async (offset, search) => {
    let total = 0;
    const options: OptionsItem[] = [];

    if (storeId) {
      const { data } = await client.query<
        GetMerchProductsForHomePageSetup,
        GetMerchProductsForHomePageSetupVariables
      >({
        query: GET_MERCH_PRODUCTS_FOR_HOME_PAGE_SETUP,
        variables: {
          input: {
            storeId,
            ...(search
              ? {
                  title: search,
                }
              : {}),
            orderBy: MerchProductOrderBy.Price,
            direction: SortDirection.ASC,
            limit: PER_PAGE,
            offset,
          },
        },
        fetchPolicy: 'network-only',
      });

      total = data?.getMerchProducts?.total || 0;

      data?.getMerchProducts?.entities?.forEach((item) => {
        options.push({
          value: String(item.id),
          label: item.title,
        });
      });
    }

    return { total, options };
  };

  return (
    <SectionItem
      onItemChange={onMerchChange}
      item={selectedMerch}
      selectPlaceholder="Select merch"
      paginationSize={PER_PAGE}
      loadMore={loadMore}
      setClearCachedOptions={setClearCachedOptions}
      cacheUniqs={[storeId, clearCachedOptions]}
      isDisabled={!storeId}
    />
  );
};

export default SetupMerchItem;
