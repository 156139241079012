import { gql } from '@apollo/client';

export const AmaFields = gql`
  fragment AmaFields on Ama {
    id
    type
    requestedPrice
    daysToResponse
    videoURL
    status
    video {
      jobStatus
      videoFileUrl
    }
  }
`;

export const AmaRequestFields = gql`
  fragment AmaRequestFields on AmaRequest {
    id
    amaId
    instructions
    orderStatus
    amaVideoURL
    declineReason
    dateToResponse
    customerId
    requestType
    email
    to
    from
    price
    paymentMethodId
    stripeIntentClientSecret
    store {
      id
      lastName
      firstName
      email
    }
  }
`;
