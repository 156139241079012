import { gql } from '@apollo/client';
// Fragments
import { MemorabiliaFields, AuctionFields } from './fragments';

export const GET_MEMORABILIA = gql`
  query GetMemorabilia($input: MemorabiliaFilterInput!) {
    getMemorabilia(input: $input) {
      entities {
        ...MemorabiliaFields
      }
      limit
      offset
      total
    }
  }
  ${MemorabiliaFields}
`;

export const GET_SHIPMENT_CATEGORIES = gql`
  query GetShipmentCategories {
    getShipmentCategories {
      slug
      name
    }
  }
`;

export const GET_ADDRESSES = gql`
  query GetAddresses($input: GetAddressesInput!) {
    getAddresses(input: $input) {
      id
      addressLine1
      addressLine2
      city
      company
      country
      countryCode
      email
      firstName
      isDefault
      lastName
      phone
      state
      stateCode
      zipCode
    }
  }
`;

export const GET_ANY_PRODUCT_SALES = gql`
  query GetAnyProductSales($input: GetAnyProductSalesInput!) {
    getAnyProductSales(input: $input) {
      entities {
        order {
          id
          discountAmount
          createdAt
          paidAt
          customer {
            id
            firstName
            lastName
            email
          }
          paymentMethod {
            id
            cardholderName
            expMonth
            expYear
            isDefault
            last4
            methodId
            type
          }
          promoRevenue
          shipping
          stripeIntentClientSecret
          stripeIntentID
          subOrders {
            id
            status
            fulfillment
            addressLine1
            addressLine2
            city
            stateCode
            countryCode
            country
            zipCode
            name
            printfulShipment {
              trackingNumber
              trackingUrl
              labels {
                category
                format
                page_size
                required
                url
              }
            }
            merchOrderItems {
              id
              amount
              price
              merchProductVariant {
                id
                size
                color
                colorCode
                colorCode2
                price
                mockupImageURL
                customImages {
                  id
                  imageFileKey
                  isMainImage
                  customMerchImageURL
                }
              }
              merchProduct {
                id
                title
                gender
                productImageURL
                store {
                  id
                  storeDetails {
                    id
                    storeName
                  }
                }
                type
              }
              memorabilia {
                ...MemorabiliaFields
              }
            }
          }
          subtotal
          total
          valueAddedTax
        }
        totalStoreEarnings
        totalStoreRevenues
      }
      limit
      offset
      total
    }
  }
  ${MemorabiliaFields}
`;

export const ESTIMATE_PRODUCT_PRICE = gql`
  query EstimateProductPrice($input: ProductPriceEstimateInput!) {
    estimateProductPrice(input: $input) {
      platformFee
      price
      productFees {
        box
        inboundProcessingLoose
        inboundProcessingReceipt
        inboundShippingCost
        margin
        outboundOrderProcess
        packaging
        pickCost
        storage
      }
      totalFee
      warehouseFee
    }
  }
`;

export const GET_AUCTIONS = gql`
  query GetAuctions($input: AuctionFilterInput!) {
    getAuctions(input: $input) {
      entities {
        ...AuctionFields
      }
      limit
      offset
      total
    }
  }
  ${AuctionFields}
`;

export const GET_INAPPROPRIATE_PRODUCT_IMAGE_COUNT = gql`
  query GetInappropriateProductImageCount {
    getInappropriateProductImageCount
  }
`;
