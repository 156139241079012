import { gql } from '@apollo/client';

export const StoreUserBasicFields = gql`
  fragment StoreUserBasicFields on Store {
    id
    lastName
    firstName
    email
    slug
  }
`;

export const DesignerUserBasicFields = gql`
  fragment DesignerUserBasicFields on Designer {
    id
    lastName
    firstName
    email
  }
`;

export const Rejections = gql`
  fragment Rejections on DesignRejection {
    id
    createdAt
    designer {
      firstName
      lastName
      id
    }
    designImages {
      imageFileKey
      imageFileURL
      id
    }
    id
    reason
    rejectedBy {
      firstName
      lastName
      id
    }
    type
  }
`;

export const DesignRequestsFields = gql`
  fragment DesignRequestsFields on DesignRequest {
    id
    store {
      ...StoreUserBasicFields
      role
      status
      storeDetails {
        id
        storeName
      }
      storeManagementDetails {
        managedBy {
          id
          email
        }
        managedById
      }
    }
    designer {
      ...DesignerUserBasicFields
      designerRate
    }
    createdAt
    assignDate
    dueDate
    type
    auditLogs {
      id
      createdAt
      designRequestId
      designer {
        firstName
        lastName
      }
      manager {
        firstName
        lastName
      }
      performedBy {
        firstName
        lastName
      }
      senior {
        firstName
        lastName
      }
      seniorId
    }
    designCreationDetails {
      id
      rate
      pdfFile
      designName
      merchProductId
      designExtraInfo
      storeExampleImages {
        id
        key
        url
      }
      merchTypes
      merchItems {
        designRequestId
        merch {
          type
          storeId
          store {
            ...StoreUserBasicFields
            role
          }
          id
          minPrice
          merchProductVariants {
            id
            size
            color
            colorCode
            colorCode2
            price
            customImages {
              id
              customMerchImageURL
              imageFileKey
              isMainImage
            }
          }
          slug
          title
          description
          productImageURL
          mainImageUrl
          status
          createdBy {
            role
            storeDetails {
              id
            }
          }
          isCreatedByAdmin
          autoSocialMediaPackStatus
          autoSocialPackImages {
            id
            status
            url
          }
        }
        merchId
      }
      socialMediaURL
      designTypes {
        id
        description
        name
        posterImage
      }
      designSamples {
        designTypeId
        id
        name
        posterImage
      }
      designStyleSettings {
        id
        abstractLiteral
        classicModern
        economicalLuxurious
        feminineMasculine
        geometricOrganic
        matureYouthful
        playfulSophisticated
      }
      designColorPalettes {
        id
        name
        posterImage
      }
      designCustomColor
      storeExampleImages {
        id
        key
        url
      }
      designCreationDetailsImages {
        id
        imageFileKey
        imageFileURL
      }
      storeLogos {
        id
        key
        url
      }
    }
    rejections {
      ...Rejections
    }
    status
    priorityType
    socialMediaPackImages {
      imageFileKey
      imgURL
      label
      socialMediaPackImageUrl
    }
    escalatedTo {
      id
      email
    }
    escalatedToId
  }
  ${StoreUserBasicFields}
  ${DesignerUserBasicFields}
  ${Rejections}
`;

export const DesignersFields = gql`
  fragment DesignersFields on Designer {
    ...DesignerUserBasicFields
    designerRate
    designerDetails {
      id
      isIncludedInAutoAssignment
    }
    designerStatus
    designRequestCnt
  }
  ${DesignerUserBasicFields}
`;
