import { gql } from '@apollo/client';
// Fragments
import { ExperienceFields, ExperienceRequestFields } from './fragments';

export const GET_EXPERIENCES = gql`
  query GetExperiences($input: ExperienceFilterInput!) {
    getExperiences(input: $input) {
      entities {
        ...ExperienceFields
      }
      limit
      offset
      total
    }
  }
  ${ExperienceFields}
`;

export const ESTIMATE_EXPERIENCE_PRICE = gql`
  query EstimateExperiencePrice($input: ExperiencePriceEstimateInput!) {
    estimateExperiencePrice(input: $input) {
      platformFee
      price
      productFees {
        margin
      }
    }
  }
`;

export const GET_EXPERIENCE_SALES = gql`
  query GetExperienceSales($input: ExperienceSalesInput) {
    getExperienceSales(input: $input) {
      entities {
        ...ExperienceRequestFields
      }
      limit
      offset
      total
    }
  }
  ${ExperienceRequestFields}
`;
