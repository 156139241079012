import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Space, Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
// Helpers
import { renderComment } from './components/helpers';
import { formatPrice } from '../ManageMerch/helpers';
// Constants
import { commonTimeFormat } from 'constants/global';
// Api
import { GET_MEMORABILIA } from 'api/memorabilia/queries';
import {
  DeleteMemorabilia,
  DeleteMemorabiliaVariables,
} from 'api/memorabilia/types/DeleteMemorabilia';
import {
  DELETE_MEMORABILIA,
  RECOVER_MEMORABILIA,
} from 'api/memorabilia/mutations';
// Types
import {
  GetMemorabilia,
  GetMemorabiliaVariables,
  GetMemorabilia_getMemorabilia_entities,
  GetMemorabilia_getMemorabilia_entities_images,
} from 'api/memorabilia/types/GetMemorabilia';
import {
  RecoverMemorabilia,
  RecoverMemorabiliaVariables,
} from 'api/memorabilia/types/RecoverMemorabilia';
import {
  MemorabiliaOrderBy,
  MemorabiliaProductType,
  MemorabiliaStatus,
  SortDirection,
  MemorabiliaSaleMethod,
  MemorabiliaFulfillment,
  AuctionStatus,
} from 'api/graphql-global-types';
// Components
import CreateEditMemorabilia from './components/CreateEditMemorabilia/CreateEditMemorabilia';
import AuctionDetails from './components/AuctionDetails/AuctionDetails';
import SocialShareQR from '../SocialShareQR/SocialShareQR';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
import { SortedInfo, TableFilter } from 'ui/Table';
import Table from 'ui/Table';

const { confirm } = Modal;

const ManageAthleteProductsTable = (): JSX.Element => {
  const { storeId } = useParams<{ storeId: string | undefined }>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<MemorabiliaOrderBy>>({
    order: SortDirection.DESC,
    key: MemorabiliaOrderBy.createdAt,
  });

  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [showPromote, setShowPromote] = useState<boolean>(false);

  const getMemorabiliaInput: any = () => {
    const input: any = {
      storeIds: [storeId],
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      isOrphanPage: null,
      storeStatuses: null,
      auctionStatuses: [
        AuctionStatus.Scheduled,
        AuctionStatus.Live,
        AuctionStatus.Finished,
      ],
    };

    searchFilters?.forEach(({ key, value }) => {
      if (key === 'saleMethods') {
        return (input[key] = [value]);
      }
      if (key === 'isCreatedByAdmin') {
        if (value === 'true') {
          return (input['isCreatedByAdmin'] = true);
        } else {
          return (input['isCreatedByAdmin'] = false);
        }
      }

      input[key] = value;
    });

    return { input };
  };

  const { data, loading } = useQuery<GetMemorabilia, GetMemorabiliaVariables>(
    GET_MEMORABILIA,
    {
      variables: { ...getMemorabiliaInput() },
      fetchPolicy: 'cache-and-network',
    }
  );

  const [deleteMemorabilia] = useMutation<
    DeleteMemorabilia,
    DeleteMemorabiliaVariables
  >(DELETE_MEMORABILIA);

  const [relistMemorabilia] = useMutation<
    RecoverMemorabilia,
    RecoverMemorabiliaVariables
  >(RECOVER_MEMORABILIA);

  const handleRelistProduct = async (memorabiliaId: string) => {
    confirm({
      title: 'Are you sure you want to relist this item?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await relistMemorabilia({
            variables: {
              input: {
                id: memorabiliaId,
              },
            },

            refetchQueries: [
              {
                query: GET_MEMORABILIA,
                variables: { ...getMemorabiliaInput() },
              },
            ],
          });
          successNotification('Successfully relisted');
        } catch (err) {
          errorNotification(
            'Looks like something went wrong. Please try again later.'
          );
        }
      },
    });
  };

  const handleDeleteProduct = async (memorabiliaId: string) => {
    confirm({
      title: 'Are you sure you want to delete this item?',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          await deleteMemorabilia({
            variables: {
              input: {
                id: memorabiliaId,
              },
            },

            refetchQueries: [
              {
                query: GET_MEMORABILIA,
                variables: { ...getMemorabiliaInput() },
              },
            ],
          });
          successNotification('Successfully removed');
        } catch (err) {
          errorNotification(
            'Looks like something went wrong. Please try again later.'
          );
        }
      },
    });
  };

  const [selectedRecord, setSelectedRecord] =
    useState<GetMemorabilia_getMemorabilia_entities | null>(null);
  const [isDetailsModalVisible, setIsDetailsModalVisible] =
    useState<boolean>(false);
  const [isCreateModalVisible, setIsCreateModalVisible] =
    useState<boolean>(false);
  const [isEditModalVisible, setIsEditVisible] = useState<boolean>(false);
  const shareableLink = `/${selectedRecord?.store?.slug}/memorabilia/${selectedRecord?.slug}`;

  const handleShowAddProductModal = () => {
    setIsCreateModalVisible(true);
  };

  const showEditModal = (
    record: GetMemorabilia_getMemorabilia_entities | null
  ) => {
    setSelectedRecord(record);
    setIsEditVisible(true);
  };

  const hideCreateEditModal = () => {
    setIsEditVisible(false);
    setIsCreateModalVisible(false);
    setSelectedRecord(null);
  };

  const handlePromote = (
    record: GetMemorabilia_getMemorabilia_entities | null
  ) => {
    setSelectedRecord(record);
    setShowPromote(true);
  };

  const handlePromoteClose = () => {
    setSelectedRecord(null);
    setShowPromote(false);
  };

  const showDetailsModal = (
    record: GetMemorabilia_getMemorabilia_entities | null
  ) => {
    setSelectedRecord(record);
    setIsDetailsModalVisible(true);
  };

  const hideDetailsModal = () => {
    setIsDetailsModalVisible(false);
    setSelectedRecord(null);
  };

  const columns = [
    {
      title: 'Item',
      dataIndex: 'title',
      fixed: 'left' as const,
      align: 'center' as const,
      key: MemorabiliaOrderBy.title,
      withSearch: true,
      sorterType: 'text',
    },
    {
      title: 'Type',
      dataIndex: 'memorabiliaProductType',
      fixed: 'left' as const,
      key: 'productTypes',
      align: 'center' as const,
      withRadioFilters: true,
      filters: [
        {
          text: MemorabiliaProductType.Memorabilia,
          value: MemorabiliaProductType.Memorabilia,
        },
        {
          text: MemorabiliaProductType.Product,
          value: MemorabiliaProductType.Product,
        },
      ],
    },
    {
      title: 'Sale Type',
      dataIndex: 'saleType',
      key: 'saleMethods',
      fixed: 'left' as const,
      align: 'center' as const,
      render: function SellType(
        text: any,
        record: GetMemorabilia_getMemorabilia_entities
      ) {
        return (
          <span style={{ textTransform: 'capitalize' }}>
            {record.isForAuction
              ? MemorabiliaSaleMethod.auction
              : MemorabiliaSaleMethod.standard}
          </span>
        );
      },
      withRadioFilters: true,
      filters: [
        {
          text: MemorabiliaSaleMethod.standard,
          value: MemorabiliaSaleMethod.standard,
        },
        {
          text: MemorabiliaSaleMethod.auction,
          value: MemorabiliaSaleMethod.auction,
        },
      ],
    },
    {
      title: 'Images',
      dataIndex: 'images',
      align: 'center' as const,
      width: 80,
      render: function Image(
        images: GetMemorabilia_getMemorabilia_entities_images[]
      ) {
        return (
          <Space size="middle" direction="vertical">
            <span>{images?.length}</span>
          </Space>
        );
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: MemorabiliaOrderBy.price,
      align: 'center' as const,
      sorterType: 'number',
      width: 106,
      render: function Price(
        text: any,
        record: GetMemorabilia_getMemorabilia_entities
      ) {
        return <span>{formatPrice(record.price)}</span>;
      },
    },

    {
      title: 'Stock',
      dataIndex: 'numberOfUnits',
      align: 'center' as const,
      width: 106,
    },
    {
      title: 'Fulfillment',
      dataIndex: 'fulfillment',
      align: 'center' as const,
      width: 106,
      key: 'fulfillment',
      withRadioFilters: true,
      filters: [
        {
          text: MemorabiliaFulfillment.Athlete,
          value: MemorabiliaFulfillment.Athlete,
        },
        {
          text: MemorabiliaFulfillment.Millions,
          value: MemorabiliaFulfillment.Millions,
        },
      ],
      filterMultiple: false,
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      align: 'center' as const,
      width: 210,
      render: function Comment(
        text: any,
        record: GetMemorabilia_getMemorabilia_entities
      ) {
        return <span>{renderComment(record.status, record.isForAuction)}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'statuses',
      align: 'center' as const,
      width: 106,
      withRadioFilters: true,
      filters: [
        { text: MemorabiliaStatus.Active, value: MemorabiliaStatus.Active },
        { text: MemorabiliaStatus.Expired, value: MemorabiliaStatus.Expired },
        { text: MemorabiliaStatus.Removed, value: MemorabiliaStatus.Removed },
        { text: MemorabiliaStatus.Sold, value: MemorabiliaStatus.Sold },
      ],
    },
    {
      title: 'Auction Status',
      dataIndex: 'auctionStatus',
      key: 'auctionStatuses',
      align: 'center' as const,
      width: 106,
      withCheckboxFilters: true,
      filterMultiple: true,
      filters: [
        { text: AuctionStatus.Scheduled, value: AuctionStatus.Scheduled },
        { text: AuctionStatus.Live, value: AuctionStatus.Live },
        { text: AuctionStatus.Finished, value: AuctionStatus.Finished },
      ],
      render: function AuctionStatus(
        text: any,
        record: GetMemorabilia_getMemorabilia_entities
      ) {
        let auctionStatus: string | null = 'N/A';

        if (record.isForAuction && record.auctions.length) {
          auctionStatus = record.auctions[0].status;
        }

        return <span>{auctionStatus}</span>;
      },
    },
    {
      title: 'List date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center' as const,
      width: 106,
      render: (createdAt: string) => moment(createdAt).format(commonTimeFormat),
      sorterType: 'date',
    },
    {
      title: 'Created By',
      dataIndex: 'isCreatedByAdmin',
      key: 'isCreatedByAdmin',
      align: 'center' as const,
      width: 106,
      withRadioFilters: true,
      filters: [
        {
          text: 'Admin',
          value: 'true',
        },
        {
          text: 'Store',
          value: 'false',
        },
      ],
      filterMultiple: false,
      render: (isCreatedByAdmin: boolean) => {
        return <div>{isCreatedByAdmin ? 'Admin' : 'Store'}</div>;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 120,
      render: function Actions(
        text: string,
        record: GetMemorabilia_getMemorabilia_entities
      ) {
        return (
          <Space size="middle" direction="vertical">
            {record?.isForAuction && (
              <Button
                type="default"
                onClick={() => {
                  showDetailsModal(record);
                }}
              >
                Details
              </Button>
            )}
            <Button
              type="primary"
              onClick={() => {
                showEditModal(record);
              }}
            >
              Edit
            </Button>
            {record?.status === MemorabiliaStatus.Active &&
              record?.auctions[0]?.status !== 'Finished' && (
                <Button type="primary" onClick={() => handlePromote(record)}>
                  Promote
                </Button>
              )}

            {record?.status !== MemorabiliaStatus.Removed && (
              <Button
                type="default"
                onClick={() => handleDeleteProduct(record.id)}
              >
                Remove
              </Button>
            )}

            {record?.status === MemorabiliaStatus.Removed &&
              !record?.isForAuction && (
                <Button
                  type="default"
                  onClick={() => handleRelistProduct(record.id)}
                >
                  Relist
                </Button>
              )}
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Space
        direction="vertical"
        size="middle"
        align="end"
        className="streams-requests__cta-wrapper"
      >
        <Button type="primary" onClick={handleShowAddProductModal}>
          Add new item
        </Button>
      </Space>

      <Table<GetMemorabilia_getMemorabilia_entities, MemorabiliaOrderBy>
        columns={columns}
        data={data?.getMemorabilia?.entities}
        scroll={{ x: 800 }}
        loading={loading}
        total={data?.getMemorabilia?.total}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />

      <div className="createEditItemModal">
        <Modal
          title={selectedRecord?.id ? 'Edit item' : 'New item'}
          visible={
            isCreateModalVisible || (!!selectedRecord && isEditModalVisible)
          }
          onCancel={hideCreateEditModal}
          className="createEditItemModal"
          width={'90vw'}
          footer={null}
          destroyOnClose
        >
          <CreateEditMemorabilia
            currentAction={selectedRecord?.id ? 'Edit' : 'New'}
            storeId={storeId || ''}
            record={selectedRecord}
            hideItemModal={hideCreateEditModal}
            getMemorabiliaInput={getMemorabiliaInput}
          />
        </Modal>
      </div>

      <Modal
        title="Auction Details"
        visible={!!selectedRecord && isDetailsModalVisible}
        onCancel={hideDetailsModal}
        width={'90vw'}
        destroyOnClose
      >
        <AuctionDetails auctionId={selectedRecord?.auctions?.[0]?.id || ''} />
      </Modal>

      <Modal
        title="Promote"
        visible={showPromote}
        footer={false}
        onCancel={handlePromoteClose}
        width="90vw"
      >
        <SocialShareQR
          title={`Share ${selectedRecord?.memorabiliaProductType}`}
          subTitle={`Share this ${selectedRecord?.memorabiliaProductType} in any way convenient for you`}
          path={shareableLink}
          twitterText={`New ${selectedRecord?.memorabiliaProductType} alert! Check out my latest drop on @millionsdotco. Order now while inventory lasts! #MILLIONSco"`}
          itemTitle={selectedRecord?.title}
        />
      </Modal>
    </div>
  );
};

export default ManageAthleteProductsTable;
