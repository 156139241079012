import React, {
  useEffect,
  // , useState
} from 'react';
import { Tabs } from 'antd';
import {
  useHistory,
  // , useParams
} from 'react-router-dom';
// Components
// import UsersTable from './components/UsersTable/UsersTable';
// import SendoutsTable from './components/SendoutsTable/SendoutsTable';
// import TextMessages from './components/TextMessages/TextMessages';
import Notifications from './components/Notifications/Notifications';
// Constants
import { MANAGE_MESSAGES } from 'constants/routes';
// UI
import { DEFAULT_CURRENT_PAGE, DEFAULT_NUMBER_ITEMS_PER_PAGE } from 'ui/Table';

const { TabPane } = Tabs;

export enum ManageMessagesTabs {
  // TODO: uncomment options below after US number is available in production
  // Contacts = 'contacts',
  // Sendouts = 'sendouts',
  // TextMessages = 'textMessages',
  Notifications = 'notifications',
}

const ManageMessages: React.FC = () => {
  // TODO: uncomment code below after US number is available in production

  // const { manageMessagesTabs } = useParams<{
  //   manageMessagesTabs: ManageMessagesTabs | undefined;
  // }>();

  // const [activeTabKey, setActiveTabKey] = useState<string>(
  //   manageMessagesTabs || ManageMessagesTabs.Contacts
  // );
  // const [stopPolling, setStopPolling] = useState<boolean>(false);
  const history = useHistory();

  // useEffect(() => {
  //   if (activeTabKey !== manageMessagesTabs) {
  //     setActiveTabKey(manageMessagesTabs || ManageMessagesTabs.Contacts);
  //   }
  // }, [activeTabKey, manageMessagesTabs]);

  useEffect(() => {
    return () => {
      // Clear history or set record to null when TextMessages component is unmounted
      history.replace({ ...history.location, state: { record: null } });
    };
  }, [history]);

  const handleTabChange = (activeKey: string) => {
    history.push(
      `${MANAGE_MESSAGES}/${activeKey}/${DEFAULT_NUMBER_ITEMS_PER_PAGE}/${DEFAULT_CURRENT_PAGE}`
    );
    // setActiveTabKey(activeKey);
    // if (activeKey === ManageMessagesTabs.TextMessages) {
    //   setStopPolling(false);
    // } else {
    //   setStopPolling(true);
    // }
  };

  return (
    <Tabs
      activeKey={ManageMessagesTabs.Notifications}
      // activeKey={activeTabKey} TODO: this is the correct key, substitute for the above key after US number is approved
      onChange={handleTabChange}
    >
      {/* <TabPane tab="Athletes/Gyms" key="contacts">
        <UsersTable />
      </TabPane>
      <TabPane tab="Sendouts" key="sendouts">
        <SendoutsTable />
      </TabPane>
      <TabPane tab="Text Messages" key="textMessages">
        <TextMessages stopPolling={stopPolling} />
      </TabPane> */}
      <TabPane tab="Notifications" key="notifications">
        <Notifications />
      </TabPane>
    </Tabs>
  );
};

export default ManageMessages;
