import { notification } from 'antd';

export const successNotification = (
  description = '',
  message = 'Success'
): void => {
  notification['success']({
    message,
    description,
  });
};

export const warningNotification = (
  description?: string,
  message = 'Warning'
): void => {
  notification['warn']({
    message,
    description,
  });
};

export const errorNotification = (
  description = 'Error occurred, we are working on it',
  message = 'Error'
): void => {
  notification['error']({
    message,
    description,
  });
};
