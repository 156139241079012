import React from 'react';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
// Helpers
import { DateSpecificHours } from 'helpers/availability';
// Components
// Styles
import styles from './SpecificHoursList.module.scss';

type SpecificHoursListProps = {
  onRemove: (index: number) => void;
  list: DateSpecificHours[];
};

const SpecificHoursList = ({
  list,
  onRemove,
}: SpecificHoursListProps): JSX.Element => {
  return (
    <ul className={styles.root}>
      {list.map((item, index) => (
        <li key={index} className={styles.listItem}>
          <div className={styles.dateAndTime}>
            <span>{item.date?.format('YYYY-MM-DD')}</span> -
            {item.timeRanges.map((range, idx) => (
              <span key={idx}>
                {range.from?.format('HH:mm')} - {range.to?.format('HH:mm')}
              </span>
            ))}
          </div>

          <Button danger onClick={() => onRemove(index)}>
            <DeleteOutlined /> Delete
          </Button>
        </li>
      ))}
    </ul>
  );
};

export default SpecificHoursList;
