import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { DatePicker, Tabs, Typography } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
// Api
import { GET_ADMIN_STORES, GET_PAYOUT_ORDERS } from 'api/store/queries';
// Types
import {
  AmaType,
  UserRole,
  StoreStatus,
  SortDirection,
  AdminStoresOrderBy,
} from 'api/graphql-global-types';
import {
  GetPayoutOrders,
  GetPayoutOrdersVariables,
} from 'api/store/types/GetPayoutOrders';
import {
  GetAdminStores,
  GetAdminStoresVariables,
} from 'api/store/types/GetAdminStores';
import { SortedInfo, TableFilter } from 'ui/Table';
import {
  ApprovedStoresTableDataItem,
  RejectedStoresTableDataItem,
  UnApprovedStoresTableDataItem,
} from './components/StoresTable';
// Helpers
import { getPublicStreamDate } from 'helpers/utils';
// Constants
import { MANAGE_ATHLETES_STORES } from 'constants/routes';
import { DEFAULT_CURRENT_PAGE, DEFAULT_NUMBER_ITEMS_PER_PAGE } from 'ui/Table';
// Components
import StoresTable from './components/StoresTable';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Text } = Typography;

export enum ManageAthleteStoreStatus {
  Current = 'current',
  Pending = 'pending',
  Rejected = 'rejected',
}

const ManageStores = (): JSX.Element => {
  const history = useHistory();
  const defaultSortInfo: SortedInfo<AdminStoresOrderBy> = {
    order: SortDirection.DESC,
    key: AdminStoresOrderBy.createdAt,
  };

  const { itemsPerPage, pageNumber, storeStatus } = useParams<{
    itemsPerPage: string | undefined;
    pageNumber: string | undefined;
    storeStatus: ManageAthleteStoreStatus | undefined;
  }>();
  const [pageSize, setPageSize] = useState<number>(
    Number(itemsPerPage) || DEFAULT_NUMBER_ITEMS_PER_PAGE
  );
  const [currentPage, setCurrentPage] = useState<number>(
    Number(pageNumber) || DEFAULT_CURRENT_PAGE
  );
  const [sort, setSortInfo] =
    useState<SortedInfo<AdminStoresOrderBy>>(defaultSortInfo);
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [tabKey, setTabKey] = useState<ManageAthleteStoreStatus | string>(
    ManageAthleteStoreStatus.Current
  );

  const getStoreVariables: any = () => {
    const input: any = {
      status:
        tabKey === ManageAthleteStoreStatus.Pending
          ? [StoreStatus.Pending]
          : tabKey === ManageAthleteStoreStatus.Rejected
          ? [StoreStatus.Rejected]
          : [StoreStatus.Active, StoreStatus.Inactive],
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      if (key === 'initialAssessment') {
        return (input['initialAssessmentRange'] = {
          from: Array.isArray(value) ? value?.[0] : value,
          to: Array.isArray(value) ? value?.[1] : value,
        });
      }
      if (key === 'latestAssessment') {
        return (input['latestAssessmentRange'] = {
          from: Array.isArray(value) ? value?.[0] : value,
          to: Array.isArray(value) ? value?.[1] : value,
        });
      }
      if (key === 'createdAt') {
        return (input['signUpDateRange'] = {
          from: Array.isArray(value) ? value?.[0] : value,
          to: Array.isArray(value) ? value?.[1] : value,
        });
      }
      if (key === 'activatedAt') {
        return (input['activatedAtDateRange'] = {
          from: Array.isArray(value) ? value?.[0] : value,
          to: Array.isArray(value) ? value?.[1] : value,
        });
      }
      if (key === 'revenue') {
        return (input['revenueRange'] = {
          from: Array.isArray(value) ? Number(value?.[0]) : value,
          to: Array.isArray(value) ? Number(value?.[1]) : value,
        });
      }
      if (key === 'followerCount') {
        return (input['followersRange'] = {
          from: Array.isArray(value) ? Number(value?.[0]) : value,
          to: Array.isArray(value) ? Number(value?.[1]) : value,
        });
      }
      if (key === 'socialFollowerCount') {
        return (input['socialFollowersRange'] = {
          from: Array.isArray(value) ? Number(value?.[0]) : value,
          to: Array.isArray(value) ? Number(value?.[1]) : value,
        });
      }
      if (key === 'phoneNumber') {
        return (input['phoneNumberV2'] = value);
      }
      input[key] =
        key === 'hasAma' ||
        key === 'hasStreams' ||
        key === 'hasMerch' ||
        key === 'hasProducts' ||
        key === 'hasYoutube' ||
        key === 'hasExperiences' ||
        key === 'hasFacebook' ||
        key === 'hasTiktok' ||
        key === 'hasInstagram' ||
        key === 'hasTwitter' ||
        key === 'hasWebsite' ||
        key === 'hasOpenDesignRequest'
          ? value === 'true'
          : value;
    });

    return { input };
  };

  const {
    data,
    loading,
    refetch: refetchStores,
  } = useQuery<GetAdminStores, GetAdminStoresVariables>(GET_ADMIN_STORES, {
    variables: { storeRoles: [UserRole.Athlete], ...getStoreVariables() },
    fetchPolicy: 'cache-and-network',
  });

  // Payouts
  const defaultStartDate = moment()
    .subtract(1, 'months')
    .startOf('day')
    .utc(true);

  const defaultEndDate = moment().endOf('day').utc(true);
  const [startDate, setStartDate] = useState<Moment>(defaultStartDate);
  const [endDate, setEndDate] = useState<Moment>(defaultEndDate);
  const [datePickerStartDate, setDatePickerStartDate] = useState<Moment | null>(
    startDate
  );
  const [datePickerEndDate, setDatePickerEndDate] = useState<Moment | null>(
    endDate
  );
  const getMaxDate = (current: Moment) =>
    moment().diff(current, 'days', true) <= 0;

  const onDateRangeChange = (start: Moment, end: Moment) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleDateRangeChange: RangePickerProps['onChange'] = (dates) => {
    if (!dates) return null;

    const [start, end] = dates;

    setDatePickerStartDate(start);
    setDatePickerEndDate(end);

    if (start && end) {
      onDateRangeChange(
        moment.parseZone(start).utc(true).startOf('day'),
        moment.parseZone(end).utc(true).endOf('day')
      );
    }
  };

  const getFilterInput: any = () => {
    const input: any = {
      periodFrom: startDate.toISOString(),
      periodTo: endDate.toISOString(),
      limit: 1000,
      offset: 0,
    };

    searchFilters?.forEach(({ key, value }) => {
      if (key !== 'managedByName' && key !== 'hasYoutube') {
        input[key] = value;
      }
    });
    return { input };
  };

  const { data: payoutOrdersData } = useQuery<
    GetPayoutOrders,
    GetPayoutOrdersVariables
  >(GET_PAYOUT_ORDERS, {
    variables: { ...getFilterInput() },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (Number(itemsPerPage) !== pageSize) {
      setPageSize(Number(itemsPerPage));
    }
  }, [itemsPerPage, pageSize]);

  useEffect(() => {
    if (Number(pageNumber) !== currentPage) {
      setCurrentPage(Number(pageNumber));
    }
  }, [pageNumber, currentPage]);

  useEffect(() => {
    if (tabKey !== storeStatus) {
      setTabKey(storeStatus || ManageAthleteStoreStatus.Current);
    }
  }, [tabKey, storeStatus]);

  const storesList = data?.adminStores?.entities || [];
  const offset = data?.adminStores?.offset || 0;

  const getStoreName = (
    firstName: string | null,
    lastName: string | null,
    email: string | null
  ): string =>
    firstName || lastName ? `${firstName} ${lastName}` : email || '';

  const payoutsData: any[] =
    payoutOrdersData?.getPayoutOrders?.entities.map((item) => {
      const {
        id,
        store,
        revenue,
        profit,
        outstandingBalance,
        periodFrom,
        periodTo,
      } = item;

      return {
        id,
        name: `${store?.firstName} ${store?.lastName}`,
        revenue,
        profit,
        outstandingBalance,
        payoutPeriod: `${getPublicStreamDate(
          periodFrom
        )} - ${getPublicStreamDate(periodTo)}`,
      };
    }) || [];

  const storesData: ApprovedStoresTableDataItem[] =
    storesList?.map((store, index: number) => {
      const {
        id,
        firstName,
        lastName,
        createdAt,
        email,
        storeDetails,
        purchaseStats,
        status,
        hasAma,
        hasMerch,
        signUpSourceStream,
        hasStreams,
        hasProducts,
        hasExperiences,
        hasConnectedAccount,
        followerCount,
        sports,
        allAmas,
        verificationVideo,
        storeManagementDetails,
        hasOpenDesignRequest,
        activatedAt,
        interviewerDetails,
      } = store;

      const verificationAmaUrl = allAmas?.find(
        (ama) => ama.type === AmaType.Ama
      )?.videoURL;

      return {
        id: index + 1 + offset,
        storeId: id,
        name: getStoreName(firstName, lastName, email),
        email: email || '',
        phoneNumber: store?.phoneNumber || '',
        signUpSourceStream: signUpSourceStream?.name || null,
        status,
        revenue: purchaseStats?.storeRevenue || 0,
        socialMediaLink: storeDetails?.socialMediaLink || '',
        youtubeLink: storeDetails?.youtubeLink || '',
        facebookLink: storeDetails?.facebookLink || '',
        instagramLink: storeDetails?.instagramLink || '',
        tiktokLink: storeDetails?.tiktokLink || '',
        twitterLink: storeDetails?.twitterLink || '',
        website: storeDetails?.website || '',
        hasMerch: String(hasMerch),
        hasAma: String(hasAma),
        hasStreams: String(hasStreams),
        hasProducts: String(hasProducts),
        hasExperiences: String(hasExperiences),
        socialFollowerCount: storeDetails?.socialFollowing,
        country: storeDetails?.country || '',
        state: storeDetails?.state || '',
        city: storeDetails?.city || '',
        followerCount,
        sports,
        verificationVideo: verificationVideo
          ? verificationVideo.url
          : verificationAmaUrl || null,
        hasConnectedAccount,
        activatedAt,
        createdAt: String(createdAt),
        initialAssessment: storeManagementDetails?.initialAssessment,
        latestAssessment: storeManagementDetails?.latestAssessment,
        hasOpenDesignRequest,
        managedBy: storeManagementDetails?.managedBy,
        interviewerDetails,
        storeManagementName: `${
          storeManagementDetails?.managedBy?.firstName || ''
        } ${storeManagementDetails?.managedBy?.lastName || ''}`,
      };
    }) || [];

  const unapprovedStoresData: UnApprovedStoresTableDataItem[] =
    storesList.map((store, index: number) => {
      const {
        id,
        firstName,
        lastName,
        email,
        storeDetails,
        phoneNumber,
        hasAma,
        hasStreams,
        hasMerch,
        signUpSourceStream,
        hasProducts,
        hasExperiences,
        sports,
        verificationVideo,
        createdAt,
        allAmas,
        storeManagementDetails,
        interviewerDetails,
        isOnboarded,
      } = store;

      const verificationAmaUrl = allAmas?.find(
        (ama) => ama.type === AmaType.Ama
      )?.videoURL;

      return {
        id: index + 1 + offset,
        storeId: id,
        name: getStoreName(firstName, lastName, email),
        email: email || '',
        phoneNumber: phoneNumber || '',
        signUpSourceStream: signUpSourceStream?.name || null,
        socialMediaLink: storeDetails?.socialMediaLink || '',
        youtubeLink: storeDetails?.youtubeLink || '',
        facebookLink: storeDetails?.facebookLink || '',
        instagramLink: storeDetails?.instagramLink || '',
        tiktokLink: storeDetails?.tiktokLink || '',
        twitterLink: storeDetails?.twitterLink || '',
        website: storeDetails?.website || '',
        hasMerch: String(hasMerch),
        hasAma: String(hasAma),
        hasStreams: String(hasStreams),
        hasProducts: String(hasProducts),
        hasExperiences: String(hasExperiences),
        createdAt: String(createdAt),
        sports,
        country: storeDetails?.country || '',
        state: storeDetails?.state || '',
        city: storeDetails?.city || '',
        verificationVideo: verificationVideo
          ? verificationVideo.url
          : verificationAmaUrl || null,
        initialAssessment: storeManagementDetails?.initialAssessment,
        latestAssessment: storeManagementDetails?.latestAssessment,
        managedBy: storeManagementDetails?.managedBy,
        storeManagementName: `${
          storeManagementDetails?.managedBy?.firstName || ''
        } ${storeManagementDetails?.managedBy?.lastName || ''}`,
        socialFollowerCount: storeDetails?.socialFollowing,
        interviewerDetails,
        isOnboarded,
      };
    }) || [];

  const rejectedStoresData: RejectedStoresTableDataItem[] =
    storesList.map((store, index: number) => {
      const {
        id,
        firstName,
        lastName,
        email,
        storeDetails,
        phoneNumber,
        sports,
        allAmas,
        verificationVideo,
        createdAt,
        storeManagementDetails,
        interviewerDetails,
      } = store;

      const verificationAmaUrl = allAmas?.find(
        (ama) => ama.type === AmaType.Ama
      )?.videoURL;

      return {
        id: index + 1 + offset,
        storeId: id,
        name: getStoreName(firstName, lastName, email),
        email: email || '',
        phoneNumber: phoneNumber || '',
        socialMediaLink: storeDetails?.socialMediaLink || '',
        country: storeDetails?.country,
        state: storeDetails?.state,
        city: storeDetails?.city,
        facebookLink: storeDetails?.facebookLink || '',
        youtubeLink: storeDetails?.youtubeLink || '',
        instagramLink: storeDetails?.instagramLink || '',
        tiktokLink: storeDetails?.tiktokLink || '',
        twitterLink: storeDetails?.twitterLink || '',
        website: storeDetails?.website || '',
        createdAt: String(createdAt),
        sports,
        verificationVideo: verificationVideo
          ? verificationVideo.url
          : verificationAmaUrl || null,
        initialAssessment: storeManagementDetails?.initialAssessment,
        latestAssessment: storeManagementDetails?.latestAssessment,
        managedBy: storeManagementDetails?.managedBy,
        storeManagementName: `${
          storeManagementDetails?.managedBy?.firstName || ''
        } ${storeManagementDetails?.managedBy?.lastName || ''}`,
        socialFollowerCount: storeDetails?.socialFollowing,
        interviewerDetails,
      };
    }) || [];

  const handleTabChange = (activeKey: ManageAthleteStoreStatus | string) => {
    setSortInfo(defaultSortInfo);
    setSearchFilters([]);
    history.push(
      `${MANAGE_ATHLETES_STORES}/${activeKey}/${DEFAULT_NUMBER_ITEMS_PER_PAGE}/${DEFAULT_CURRENT_PAGE}`
    );
  };

  const handleSetPagination = (page: number, size: number): void => {
    history.push(`${MANAGE_ATHLETES_STORES}/${tabKey}/${size}/${page}`);
  };

  const renderTable = () => (
    <StoresTable
      data={
        tabKey === 'payouts'
          ? payoutsData
          : tabKey === ManageAthleteStoreStatus.Pending
          ? unapprovedStoresData
          : tabKey === ManageAthleteStoreStatus.Rejected
          ? rejectedStoresData
          : storesData
      }
      loading={loading}
      refetchStores={refetchStores}
      total={data?.adminStores?.total}
      sort={sort}
      setSortInfo={setSortInfo}
      pageSize={pageSize}
      currentPage={currentPage}
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      tabKey={tabKey}
      isPayoutsTab={tabKey === 'payouts'}
      setPageAndSize={handleSetPagination}
    />
  );

  return (
    <Tabs activeKey={tabKey} onChange={handleTabChange}>
      <TabPane tab="Stores" key={ManageAthleteStoreStatus.Current}>
        {renderTable()}
      </TabPane>
      <TabPane
        tab="Waiting for approval"
        key={ManageAthleteStoreStatus.Pending}
      >
        {renderTable()}
      </TabPane>
      <TabPane tab="Rejected sign ups" key={ManageAthleteStoreStatus.Rejected}>
        {renderTable()}
      </TabPane>
      <TabPane tab="Payouts/Transfers" key={'payouts'}>
        <div className="payoutsListDatePicker">
          <Text>Select date:</Text>
          <RangePicker
            defaultValue={[datePickerStartDate, datePickerEndDate]}
            disabledDate={getMaxDate}
            onChange={handleDateRangeChange}
          />
        </div>
        {renderTable()}
      </TabPane>
    </Tabs>
  );
};

export default ManageStores;
