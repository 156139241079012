import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { Typography, AutoComplete } from 'antd';
// Api
import { SEARCH_MERCH_PRODUCTS } from 'api/interview/queries';
// Types
import {
  SearchMerchProducts,
  SearchMerchProductsVariables,
} from 'api/interview/types/SearchMerchProducts';
// Components
import { AutoCompleteOption } from '../SendInviteModal/SendInviteModal';
// Styles
import styles from './SearchMerch.module.scss';

const { Text } = Typography;

type SearchMerchProps = {
  storeIds: string[];
  selectedMerchOption: AutoCompleteOption | null;
  setSelectedMerchOption: React.Dispatch<
    React.SetStateAction<AutoCompleteOption | null>
  >;
};

export const SearchMerchComponent = ({
  storeIds,
  selectedMerchOption,
  setSelectedMerchOption,
}: SearchMerchProps): JSX.Element => {
  const [searchMerch, { data }] = useLazyQuery<
    SearchMerchProducts,
    SearchMerchProductsVariables
  >(SEARCH_MERCH_PRODUCTS);

  const [merchOptions, setMerchOptions] = useState<AutoCompleteOption[]>([]);

  const handleSelect = (value: string, option: AutoCompleteOption): void => {
    setSelectedMerchOption(option);
  };

  const handleOnClear = () => {
    setSelectedMerchOption(null);
  };

  const handleOnSearch = useMemo(() => {
    const loadOptions = (searchTerm: string) => {
      searchMerch({
        variables: {
          input: {
            searchTerm: searchTerm,
            storeIds,
          },
        },
      });
    };

    return debounce(loadOptions, 400);
  }, [searchMerch, storeIds]);

  useEffect(() => {
    if (data?.getMerchProducts?.entities) {
      setMerchOptions(
        data?.getMerchProducts?.entities.map((merch) => ({
          value: merch.id,
          label: merch.title || '',
        }))
      );
    }
  }, [data]);

  return (
    <div className={styles.streamSection}>
      <Text>Merch:</Text>
      <AutoComplete
        id="merch-search"
        allowClear
        className={styles.autoComplete}
        placeholder="Search Merch Name"
        options={merchOptions}
        value={selectedMerchOption?.label}
        onSearch={handleOnSearch}
        onSelect={handleSelect}
        onClear={handleOnClear}
      />

      {selectedMerchOption && (
        <Text className={styles.text}>
          Selected merch: {selectedMerchOption?.label}
        </Text>
      )}
    </div>
  );
};

export default SearchMerchComponent;
