import { gql } from '@apollo/client';
import { AdminFields } from './fragments';

export const GET_ADMINS = gql`
  query GetAdmins($input: AdminFilterInput) {
    admins(input: $input) {
      entities {
        ...AdminFields
      }
      total
      offset
      limit
    }
  }
  ${AdminFields}
`;
