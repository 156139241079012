import { gql } from '@apollo/client';
import { HashtagFields } from 'api/hashtags/fragments';

export const ADD_HASHTAG = gql`
  mutation AddHashtag($input: AddHashtagInput!) {
    addHashtag(input: $input) {
      ...HashtagFields
    }
  }
  ${HashtagFields}
`;

export const EDIT_HASHTAG = gql`
  mutation EditHashtag($input: EditHashtagInput!) {
    editHashtag(input: $input) {
      ...HashtagFields
    }
  }
  ${HashtagFields}
`;

export const DELETE_HASHTAG = gql`
  mutation DeleteHashtag($hashtagId: Float!) {
    deleteHashtag(hashtagId: $hashtagId) {
      id
    }
  }
`;

export const DELETE_HASHTAG_FROM_STORE = gql`
  mutation DeleteHashtagFromStore($hashtagIds: [Float!]!, $storeId: String) {
    deleteHashtagsFromStore(hashtagIds: $hashtagIds, storeId: $storeId) {
      ...HashtagFields
    }
  }
  ${HashtagFields}
`;
