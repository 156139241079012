import { useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
// Constants
import {
  MANAGE_ATHLETES_STORES,
  MANAGE_CONTENT_CREATORS_STORES,
  MANAGE_ORGANIZATIONS_STORES,
} from 'constants/routes';
import { useGetUserTypeFromRoute } from './useGetUserTypeFromRoute';

export const useDefaultStoreRedirectOnEmptyId = (): void => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { isAthlete, isContentCreator, isOrganization } =
    useGetUserTypeFromRoute();
  const { storeId } = useParams<{ storeId: string | undefined }>();

  useEffect(() => {
    let redirectLink = url;

    if (!storeId || storeId === 'undefined') {
      if (isAthlete) {
        redirectLink = MANAGE_ATHLETES_STORES;
      }

      if (isOrganization) {
        redirectLink = MANAGE_ORGANIZATIONS_STORES;
      }

      if (isContentCreator) {
        redirectLink = MANAGE_CONTENT_CREATORS_STORES;
      }

      if (!isAthlete && !isContentCreator && !isOrganization) {
        redirectLink = '/';
      }
    }

    history.push(redirectLink);
  }, [storeId, isAthlete, isContentCreator, isOrganization, url, history]);
};
