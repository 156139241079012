import { gql } from '@apollo/client';
import { MerchProductBaseFields } from './fragments';

export const GET_ADMIN_MERCH_PRODUCTS = gql`
  query GetAdminMerchProducts($input: GetAdminMerchProductsInput!) {
    getAdminMerchProducts(input: $input) {
      ...MerchProductBaseFields
      limit
      offset
      total
    }
  }
  ${MerchProductBaseFields}
`;

export const GET_MERCH_PRODUCTS_FOR_HOME_PAGE_SETUP = gql`
  query GetMerchProductsForHomePageSetup($input: GetMerchProductsInput) {
    getMerchProducts(input: $input) {
      entities {
        id
        status
        title
        merchProductVariants {
          customImages {
            id
            customMerchImageURL
            imageFileKey
            isMainImage
          }
        }
        store {
          id
          role
          firstName
          lastName
          email
          storeDetails {
            id
            storeName
          }
        }
      }
      total
      offset
      limit
    }
  }
`;

export const GET_PR_PRODUCT_BY_TYPE_V2 = gql`
  query GetPRProductByTypeV2($type: GetPrProductsInput!) {
    getPRProductByTypeV2(input: $type) {
      age
      gender
      data {
        features
        printfiles {
          printfiles {
            printfile_id
            width
            height
            dpi
            fill_mode
            can_rotate
          }
        }
        product {
          variants {
            id
            price
            image
            color
            color_code
            color_code2
            in_stock
          }
          product {
            id
            options
            files {
              additional_price
              id
              title
              type
            }
          }
        }
        style
        templates {
          conflicting_placements {
            conflicts
            placement
          }
          version
          min_dpi
          templates {
            template_id
            background_color
            background_url
            image_url
            is_template_on_front
            orientation
            print_area_height
            print_area_left
            print_area_top
            print_area_width
            printfile_id
            template_height
            template_width
          }
          variant_mapping {
            variant_id
            templates {
              placement
              template_id
            }
          }
        }
      }
    }
  }
`;

export const GET_MERCH_PRODUCT_V2 = gql`
  query GetMerchProductV2($input: GetMerchProductInput!) {
    getMerchProduct(input: $input) {
      id
      requestedProfit
      title
      type
      genders
      hashtags {
        id
        name
      }
      merchProductVariants {
        id
        age
        gender
        stitches
        threads
        files {
          id
          merchProductPrintfilePlacements {
            id
            angle
            printImageId
            left
            top
            rotatedLeft
            rotatedTop
            scaleX
            scaleY
          }
          placementType
          imageFileKey
        }
        color
        colorCode
        colorCode2
        colorGroupId
        mockupImageURL
        printfulVariantId
        customImages {
          id
          imageFileKey
          isMainImage
          customMerchImageURL
        }
      }
      autoSocialPackImages {
        id
        status
        url
      }
    }
  }
`;

export const GET_PRINT_IMAGES = gql`
  query GetPrintImages($input: PrintImageFilterInput!) {
    getPrintImages(input: $input) {
      id
      imageURL
    }
  }
`;

export const GET_PRINT_IMAGE_BY_ID = gql`
  query GetPrintImageById($input: PrintImageInput!) {
    getPrintImageById(input: $input) {
      id
      imageKey
      deletedAt
      imageURL
    }
  }
`;

export const GET_MERCH_SALES_BY_STORE = gql`
  query GetMerchSales($input: MySalesInput) {
    getMerchSales(input: $input) {
      entities {
        id
        requestedProfit
        createdAt
        total
        printfulOrderId
        customer {
          id
          lastName
          firstName
          email
        }
        thankYouVideo {
          id
          thankYouVideoUrl
        }
        merchOrderItems {
          id
          merchProductVariant {
            id
            size
            color
          }
          merchProduct {
            id
            title
          }
          amount
        }
      }
      total
      offset
      limit
    }
  }
`;

export const GET_ATHLETE_MERCH_SALES_REPORT = gql`
  query GetAthletesMerchSalesReport($input: SalesReportInput!) {
    getAthletesMerchSalesReport(input: $input) {
      entities {
        storeId
        firstName
        lastName
        sports
        topSellingItem
        totalRevenue
      }
    }
  }
`;

export const GET_UNMERGED_MERCH = gql`
  query GetUnmergedMerch($input: GetUnmergedMerchInput!) {
    getUnmergedMerch(input: $input) {
      entities {
        id
        genders
        createdAt
        storeId
        store {
          id
          firstName
          lastName
          email
          slug
          storeDetails {
            id
            storeName
          }
        }
        title
        type
        slug
        productImageURL
        requestedProfit
      }
      limit
      offset
      total
    }
  }
`;

export const ESTIMATE_MERCH_PRICE = gql`
  query EstimateMerchPrice($input: EstimateMerchPriceInput!) {
    estimateMerchPrice(input: $input) {
      requestedProfit
      default {
        basePrice
        basePrintLocationPrice
        baseTotal
        finalPrice
        platformFee
      }
    }
  }
`;
