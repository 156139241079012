import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
// Types
import { GetSendouts_getSendouts_data } from 'api/notification/types/GetSendouts';
// Constants
import { commonTimeFormat } from 'constants/global';
// Styles
import styles from './NotificationDetailsModal.module.scss';

type NotificationDetailsModalProps = {
  record: GetSendouts_getSendouts_data | null;
};

const NotificationDetailsModal = ({
  record,
}: NotificationDetailsModalProps): JSX.Element => {
  const description = record?.description;
  const analyticsLabel = record?.analyticsLabel;
  const createdAt = moment(record?.createdAtIso).format(commonTimeFormat);
  const size = record?.size;
  const allTypes = [];

  const inAppPushTitle =
    record?.inAppConfig?.title || record?.pushConfig?.title;
  const inAppPushBody = record?.inAppConfig?.body || record?.pushConfig?.body;
  const action = record?.inAppConfig?.action || record?.pushConfig?.action;
  const inAppPushImageUrl =
    record?.inAppConfig?.imageUrl || record?.pushConfig?.imageUrl;
  const category =
    record?.inAppConfig?.category || record?.pushConfig?.category;

  const subject = record?.emailConfig?.subject;
  const paragraph = record?.emailConfig?.paragraph;
  const emailImageUrl = record?.emailConfig?.imageUrl;
  const buttonLabel = record?.emailConfig?.button?.label;
  const buttonLink = record?.emailConfig?.button?.url;

  // V1 only has inApp and Push options and both are selected on every sendout, but V2 will have more
  if (record?.inAppConfig?.title) {
    allTypes.push('inApp');
  }
  if (record?.pushConfig?.title) {
    allTypes.push('Push');
  }
  if (record?.emailConfig?.subject) {
    allTypes.push('Email');
  }
  const stringifiedTypes = allTypes.join(', ');

  return (
    <div className={styles.root}>
      <div className={styles.sendoutDataItem}>
        <div className={styles.label}> Sendout id:</div>
        <div> {record?.id}</div>
      </div>

      <div className={styles.sendoutDataItem}>
        <div className={styles.label}> Sendout title:</div>
        <div> {record?.title}</div>
      </div>

      {description && (
        <div className={styles.sendoutDataItem}>
          <div className={styles.label}> Sendout description:</div>
          <div> {description}</div>
        </div>
      )}

      <div className={styles.sendoutDataItem}>
        <div className={styles.label}>CSV file:</div>
        <a href={record?.csvUrl || ''}>
          <DownloadOutlined /> Download file
        </a>
      </div>

      {analyticsLabel && (
        <div className={styles.sendoutDataItem}>
          <div className={styles.label}> Analytics Label:</div>
          <div> {analyticsLabel}</div>
        </div>
      )}

      <div className={styles.sendoutDataItem}>
        <div className={styles.label}> Created at:</div>
        <div> {createdAt}</div>
      </div>

      <div className={styles.sendoutDataItem}>
        <div className={styles.label}> Status:</div>
        <div> {record?.status}</div>
      </div>

      {size && (
        <div className={styles.sendoutDataItem}>
          <div className={styles.label}> Size (number of users):</div>
          <div> {record?.size}</div>
        </div>
      )}

      <div className={styles.sendoutDataItem}>
        <div className={styles.label}> Sendout type:</div>
        <div> {stringifiedTypes}</div>
      </div>

      {inAppPushTitle && (
        <div className={styles.segment}>
          <p className={styles.segmentTitle}>inApp/Push configuration:</p>
          <div className={styles.sendoutDataItem}>
            <div className={styles.label}> Title:</div>
            <div> {inAppPushTitle}</div>
          </div>
          {inAppPushBody && (
            <div className={styles.sendoutDataItem}>
              <div className={styles.label}> Body:</div>
              <div className={styles.multilineBody}> {inAppPushBody}</div>
            </div>
          )}
          {action && (
            <div className={styles.sendoutDataItem}>
              <div className={styles.label}> Action:</div>
              <div> {action}</div>
            </div>
          )}
          {inAppPushImageUrl && (
            <div className={styles.sendoutDataItem}>
              <div className={styles.label}> Image:</div>
              <a
                href={inAppPushImageUrl || ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                <DownloadOutlined /> Download file
              </a>
            </div>
          )}
          {category && (
            <div className={styles.sendoutDataItem}>
              <div className={styles.label}> Category:</div>
              <div> {description}</div>
            </div>
          )}
        </div>
      )}

      {subject && (
        <div className={styles.segment}>
          <p className={styles.segmentTitle}>Email configuration:</p>
          <div className={styles.sendoutDataItem}>
            <div className={styles.label}> Subject:</div>
            <div> {subject}</div>
          </div>
          <div className={styles.sendoutDataItem}>
            <div className={styles.label}> Body:</div>
            <div className={styles.multilineBody}> {paragraph}</div>
          </div>
          {emailImageUrl && (
            <div className={styles.sendoutDataItem}>
              <div className={styles.label}>Image:</div>
              <a
                href={emailImageUrl || ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                <DownloadOutlined /> Download file
              </a>
            </div>
          )}
          {buttonLabel && (
            <div className={styles.sendoutDataItem}>
              <div className={styles.label}> Button label:</div>
              <div> {buttonLabel}</div>
            </div>
          )}
          {buttonLink && (
            <div className={styles.sendoutDataItem}>
              <div className={styles.label}> Button Link:</div>
              <div> {buttonLink}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationDetailsModal;
