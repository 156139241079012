import React from 'react';
// Components
import Component, { CreatePromoCodeModalProps } from './CreatePromoCodeModal';
// Context
import { StoresSelectedProductsProvider } from 'context/StoresSelectedProducts';

const CreatePromoCodeModal = (
  modalProps: CreatePromoCodeModalProps
): JSX.Element => (
  <StoresSelectedProductsProvider>
    <Component {...modalProps} />
  </StoresSelectedProductsProvider>
);

export default CreatePromoCodeModal;
