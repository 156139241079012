import React from 'react';
import moment, { Moment } from 'moment';
// Types
import { GetUpcomingStreams_adminStreams_entities } from 'api/streams/types/GetUpcomingStreams';
// Styles
import styles from './MonthCell.module.scss';

type MonthCellProps = {
  date: Moment;
  streams: GetUpcomingStreams_adminStreams_entities[];
};

const MonthCell = ({ date, streams }: MonthCellProps): JSX.Element | null => {
  const numberOfStreams = streams.filter(({ scheduleDate }) =>
    moment(scheduleDate).isSame(date, 'month')
  ).length;

  if (!numberOfStreams) {
    return null;
  }

  return (
    <div className={styles.root}>
      <span>{numberOfStreams}</span>
      <span>Stream(s)</span>
    </div>
  );
};

export default MonthCell;
