import { gql } from '@apollo/client';
import { AmaFields } from './fragments';

export const GET_AMA_REQUESTS_PAGINATED = gql`
  query AmaRequestsPaginated($input: AmaRequestFilterInput) {
    amaRequestsPaginated(input: $input) {
      entities {
        id
        ama {
          id
          type
        }
        orderStatus
        dateToResponse
        instructions
        from
        to
      }
      total
      offset
      limit
    }
  }
`;

export const GET_ALL_AMAS = gql`
  query GetAllAmas($input: AmaFilterInput) {
    amas(input: $input) {
      entities {
        status
        id
        store {
          id
          role
          firstName
          lastName
          email
          storeDetails {
            id
            storeName
          }
        }
      }
      total
      offset
      limit
    }
  }
`;

export const GET_STORES_AMA_REQUESTS = gql`
  query AllStoresAmaRequestsPaginated($input: AmaRequestFilterInput) {
    amaRequestsPaginated(input: $input) {
      entities {
        id
        ama {
          id
          type
        }
        orderStatus
        createdAt
        dateToResponse
        instructions
        store {
          id
          firstName
          lastName
        }
        customer {
          id
          firstName
          lastName
        }
      }
      total
      offset
      limit
    }
  }
`;

export const ADMIN_GET_AMAS = gql`
  query AdminGetAmas($storeId: String!) {
    adminGetAmas(storeId: $storeId) {
      ...AmaFields
    }
  }
  ${AmaFields}
`;

export const GET_AMA_SALES_BY_STORE = gql`
  query GetAmaSales($input: MySalesInput) {
    getAmaSales(input: $input) {
      entities {
        id
        customer {
          id
          lastName
          firstName
        }
        paidAt
        discountAmount
        requestedProfit
        ama {
          id
          type
        }
        thankYouVideo {
          id
          thankYouVideoUrl
        }
      }
      total
      offset
      limit
    }
  }
`;

export const GET_AMA_SALES_BY_STORE_V2 = gql`
  query GetAmaSalesV2($input: GetAmaSalesInput!) {
    getAmaSalesV2(input: $input) {
      entities {
        id
        customer {
          id
          lastName
          firstName
          email
        }
        paidAt
        discountAmount
        requestedProfit
        ama {
          id
          type
        }
        thankYouVideo {
          id
          thankYouVideoUrl
        }
      }
      total
      offset
      limit
    }
  }
`;

export const GET_AMA_SALES_REPORT = gql`
  query GetAmaSalesReport($input: SalesReportInput!) {
    getAmaSalesReport(input: $input) {
      entities {
        storeId
        firstName
        lastName
        sports
        totalRevenue
      }
    }
  }
`;

export const GET_USERS_AMA_PURCHASE = gql`
  query GetUsersAmaPurchase($input: AmaRequestQueryInput!) {
    getUsersAmaPurchase(input: $input) {
      entities {
        id
        orderStatus
        createdAt
        store {
          id
          firstName
          lastName
          email
        }
        instructions
        amaId
      }
      total
      offset
      limit
    }
  }
`;
