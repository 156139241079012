import moment, { Moment } from 'moment';
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsIsBetween from 'dayjs/plugin/isBetween';
// Types
import { Streams_adminStreams_entities } from 'api/streams/types/Streams';
import {
  GetMediaPost_getMediaPost_ImagePost,
  GetMediaPost_getMediaPost_VideoPost,
} from 'api/mediaPost/types/GetMediaPost';
// Constants
import { TIME_ZONE_OPTIONS, TimeZoneOption } from 'constants/timeZone';

dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsIsBetween);

export const getCurrentTimeZoneOffset = (): number => {
  const clientLocalDate = new Date();
  return -clientLocalDate.getTimezoneOffset();
};

export const getDateWithoutCurrentTimeZone = (
  date?: string | null
): Date | string =>
  date?.length
    ? dayjs(
        dayjs(date).subtract(getCurrentTimeZoneOffset(), 'minute').toISOString()
      ).toDate()
    : '';

export const getDateInOriginalTimeZone = (
  date: string | null,
  format?: string
): string => {
  if (date && dayjs(date).isValid()) {
    const dateInTimeZone = dayjs(date).subtract(
      getCurrentTimeZoneOffset(),
      'minute'
    );
    return dayjs(dateInTimeZone).format(format || 'YYYY-MM-DDThh:mm:ss.sssZ');
  }
  return '\u2014';
};

export const getDefaultTimeZone = (
  stream: Streams_adminStreams_entities | null | undefined
): string => {
  const streamTimeZone: TimeZoneOption | undefined = TIME_ZONE_OPTIONS.find(
    (i) => i.tzCode === stream?.timeZone?.tzCode
  );
  return streamTimeZone?.tzCode || TIME_ZONE_OPTIONS[0]?.tzCode;
};

export const getTimeZoneName = (tzCode: string | null | undefined): string => {
  const timeZone: TimeZoneOption | undefined = TIME_ZONE_OPTIONS.find(
    (i) => i.tzCode === tzCode
  );
  return timeZone?.tzName || TIME_ZONE_OPTIONS[0].tzName;
};

export const getTimeZoneLabel = (tzCode: string | null | undefined): string => {
  const timeZone: TimeZoneOption | undefined = TIME_ZONE_OPTIONS.find(
    (i) => i.tzCode === tzCode
  );
  return timeZone?.label || TIME_ZONE_OPTIONS[0].label;
};

export const getMediaPostTimeZoneObject = (
  mediaPost:
    | GetMediaPost_getMediaPost_ImagePost
    | GetMediaPost_getMediaPost_VideoPost
    | null
    | undefined
): string => {
  const mediaPostTimeZone: TimeZoneOption | undefined = TIME_ZONE_OPTIONS.find(
    (i) => i.tzCode === mediaPost?.scheduledAtTzCode
  );
  return mediaPostTimeZone?.tzCode || TIME_ZONE_OPTIONS[0].tzCode;
};

export const convertToUtcDate = (
  date: string | null | Date | undefined,
  timeZoneOffset: number
): string => {
  if (date && dayjs(date).isValid()) {
    const utcDate = dayjs(date).subtract(timeZoneOffset, 'minute');
    return utcDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  }
  return '\u2014';
};

export const convertFromUtc = (scheduledAt: string, offset: number): Moment => {
  // Parse the scheduled date as a moment object in UTC
  const scheduledMoment = moment.parseZone(scheduledAt);
  const totalOffsetMinutes = offset + scheduledMoment.utcOffset();
  return scheduledMoment.add(totalOffsetMinutes, 'minutes');
};
