import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Form, Spin, TreeSelect } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
// Api
import { GET_STORES_PROMO_CODE_PAYLOAD } from 'api/promocodes/queries';
// Types
import {
  GetStoresPromoCodePayload,
  GetStoresPromoCodePayloadVariables,
} from 'api/promocodes/types/GetStoresPromoCodePayload';
import { TreeDataEntity } from '../../data/tree-data.entity';
// Helper
import { storesPayloadMapper } from './store-data-select-helpers';
// Components
import FormItemLabel from '../FormItemLabel/FormItemLabel';

import './styles.scss';

export type StoreProductsProps = {
  storeId: string;
  onChange: (storeId: string, values: TreeDataEntity[]) => void;
  onRemove: (storeId: string) => void;
};

const StoreProducts: React.FC<StoreProductsProps> = ({
  storeId,
  onRemove,
  onChange,
}) => {
  const { data, loading, error } = useQuery<
    GetStoresPromoCodePayload,
    GetStoresPromoCodePayloadVariables
  >(GET_STORES_PROMO_CODE_PAYLOAD, {
    variables: { input: { storeIds: [storeId] } },
  });

  const treeData = useMemo(() => {
    if (!data || !data?.getStoresPromoCodePayload.length) {
      return [];
    } else {
      const [store] = data.getStoresPromoCodePayload;
      if (!store) {
        return [];
      }

      return storesPayloadMapper(store);
    }
  }, [data]);

  const handleOnChange = (values: TreeDataEntity[]): void => {
    onChange(storeId, values);
  };

  const handleOnClickRemove = (): void => {
    onRemove(storeId);
  };

  if (!data || error || loading) {
    return null;
  }

  const [store] = data.getStoresPromoCodePayload;

  if (!store) {
    return null;
  }

  const storeName = `${store?.store?.firstName || ''} ${
    store?.store?.lastName || ''
  } [${store?.store?.slug}]`;

  return (
    <Form.Item
      className="promo-code-form__label"
      required
      label={<FormItemLabel labelText={storeName} />}
    >
      <Form.Item noStyle>
        <TreeSelect
          multiple
          treeCheckable
          labelInValue
          placeholder="Please select"
          notFoundContent={loading ? <Spin size="small" /> : null}
          className="promo-code-form__input"
          onChange={handleOnChange}
          treeData={treeData}
        />
      </Form.Item>
      <MinusCircleOutlined
        className="promo-code-form__btn-delete"
        onClick={handleOnClickRemove}
      />
    </Form.Item>
  );
};

export default StoreProducts;
