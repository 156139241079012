import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Form, Input, Spin, Switch, Typography } from 'antd';
import { Controller, useForm } from 'react-hook-form';
// Api
import {
  CREATE_EXPERIENCE,
  CREATE_EXPERIENCE_PRESIGNED_URL,
  UPDATE_EXPERIENCE,
} from 'api/experience/mutations';
import {
  GET_EXPERIENCES,
  ESTIMATE_EXPERIENCE_PRICE,
} from 'api/experience/queries';
// Types
import {
  GetExperiencesVariables,
  GetExperiences_getExperiences_entities,
} from 'api/experience/types/GetExperiences';
import {
  EstimateExperiencePrice,
  EstimateExperiencePriceVariables,
} from 'api/experience/types/EstimateExperiencePrice';
import {
  CreateExperience,
  CreateExperienceVariables,
} from 'api/experience/types/CreateExperience';
import {
  UpdateExperience,
  UpdateExperienceVariables,
} from 'api/experience/types/UpdateExperience';
import {
  CreateExperienceImagePresignedUrl,
  CreateExperienceImagePresignedUrlVariables,
  CreateExperienceImagePresignedUrl_createExperiencePresignedUrl,
} from 'api/experience/types/CreateExperienceImagePresignedUrl';
// Helpers
import { formatPrice } from 'components/common/ManageMerch/helpers';
import { formatHashtagInput } from 'helpers/hashtags';
// Ui
import { successNotification, errorNotification } from 'ui/Notification';
import UploadMultipleImages, {
  UploadImage,
} from 'ui/UploadMultipleImages/UploadMultipleImages';
import TagsInput from 'uiShared/TagsInput/TagsInput';
// Styles
import styles from './CreateEditExperience.module.scss';

type FormValues = {
  numberOfUnits: number | null;
  isUnlimited: boolean;
  title: string;
  description: string;
  hashtags: string[];
  requestedPrice: number;
  images: UploadImage[];
};

type ImageToUpload = {
  fields: string;
  url: string;
  name: string;
  type: string;
  file: File | '';
};

type CreateEditExperienceProps = {
  record: GetExperiences_getExperiences_entities | null;
  hideItemModal: () => void;
  storeId: string;
  currentAction: string;
  getExperienceInput: () => GetExperiencesVariables;
};

const { Text, Title } = Typography;
const { TextArea } = Input;

const CreateEditExperience = ({
  record,
  hideItemModal,
  storeId,
  currentAction,
  getExperienceInput,
}: CreateEditExperienceProps): JSX.Element => {
  const initialFormValues: FormValues = {
    numberOfUnits: record?.numberOfUnits || null,
    isUnlimited: Boolean(!record?.numberOfUnits),
    title: record?.title || '',
    hashtags: record?.hashtags.map((tag) => tag.name) || [],
    description: record?.description || '',
    requestedPrice: record?.requestedPrice || 0,
    images:
      record?.images?.map((item) => ({
        data_url: item.experienceImageFileKey || undefined,
      })) || [],
  };

  const mainImageIndex: number =
    record?.images?.findIndex(function (image) {
      return image.isMainImage === true;
    }) || 0;

  const [isMainImage, setIsMainImage] = useState<number | null>(mainImageIndex);
  const [formFields, setFormFields] = useState<FormValues>(initialFormValues);

  const [form] = Form.useForm();
  const methods = useForm();
  const { control } = methods;

  const setFields = () => {
    const fields: FormValues = form.getFieldsValue();

    setFormFields({
      ...fields,
    });
  };

  const onCheckboxChange = (checked: boolean) => {
    if (checked) {
      setFormFields({
        ...formFields,
        numberOfUnits: null,
        isUnlimited: true,
      });
    } else {
      setFormFields({
        ...formFields,
        numberOfUnits: form.getFieldValue('numberOfUnits'),
        isUnlimited: false,
      });
    }
    form.validateFields(['numberOfUnits']);
  };

  const currentPrice = Number(form.getFieldValue('requestedPrice'));

  const { data: estimatedPrice } = useQuery<
    EstimateExperiencePrice,
    EstimateExperiencePriceVariables
  >(ESTIMATE_EXPERIENCE_PRICE, {
    variables: {
      input: {
        requestedPrice: currentPrice,
      },
    },
    skip: !currentPrice,
    fetchPolicy: 'cache-and-network',
  });

  const [createExperience, { loading }] = useMutation<
    CreateExperience,
    CreateExperienceVariables
  >(CREATE_EXPERIENCE, {
    refetchQueries: [
      {
        query: GET_EXPERIENCES,
        variables: {
          ...getExperienceInput(),
        },
      },
    ],
  });

  const [updateExperience, { loading: updateLoading }] = useMutation<
    UpdateExperience,
    UpdateExperienceVariables
  >(UPDATE_EXPERIENCE, {
    refetchQueries: [
      {
        query: GET_EXPERIENCES,
        variables: { ...getExperienceInput() },
      },
    ],
  });

  const [createPresignedUrl] = useMutation<
    CreateExperienceImagePresignedUrl,
    CreateExperienceImagePresignedUrlVariables
  >(CREATE_EXPERIENCE_PRESIGNED_URL);

  const getImagesToUpload = (
    preSignedUrls: CreateExperienceImagePresignedUrl_createExperiencePresignedUrl[],
    images: UploadImage[]
  ): ImageToUpload[] => {
    const res: ImageToUpload[] = [];

    images.forEach(({ file }, index) => {
      const preSignedUrl = preSignedUrls?.[index];

      const imageExtension = file
        ? file.name.split('.')[file.name.split('.').length - 1]
        : '';

      const imageName = `${preSignedUrl?.key || ''}.${imageExtension}`;

      res.push({
        fields: preSignedUrl?.fields || '',
        url: preSignedUrl?.url || '',
        name: imageName,
        type: file?.type || '',
        file: file || '',
      });
    });

    return res;
  };

  const uploadImages = async (
    preSignedUrls: CreateExperienceImagePresignedUrl_createExperiencePresignedUrl[],
    images: UploadImage[]
  ): Promise<string[]> => {
    const imagesToUpload = getImagesToUpload(preSignedUrls, images);

    for (const image of imagesToUpload) {
      const { fields, url, name, type, file } = image;
      const formData = new FormData();

      Object.entries(JSON.parse(fields)).forEach(([key, value]) => {
        formData.append(key, value as string);
      });

      formData.append('key', name);
      formData.append('Content-Type', type);
      formData.append('file', file);

      await fetch(url, {
        method: 'POST',
        body: formData,
      });
    }

    return imagesToUpload.map(({ name }) => name);
  };

  const handleCreateExperience = async () => {
    try {
      const { data } = await createPresignedUrl({
        variables: {
          input: {
            numberOfImages: formFields.images.length,
          },
        },
      });

      if (data) {
        try {
          const imagesUrls = await uploadImages(
            data.createExperiencePresignedUrl,
            formFields.images
          );

          if (imagesUrls) {
            const input = {
              numberOfUnits:
                formFields.numberOfUnits === null
                  ? null
                  : Number(formFields.numberOfUnits),
              title: formFields.title,
              description: formFields.description,
              hashtagInputs: formFields?.hashtags?.length
                ? formatHashtagInput(formFields?.hashtags)
                : null,
              images: imagesUrls.map((item) => ({
                isMainImage: imagesUrls.indexOf(item) === isMainImage,
                experienceImageFileKey: item,
              })),
              requestedPrice: Number(formFields.requestedPrice),
              storeId: storeId,
            };

            try {
              await createExperience({
                variables: {
                  input,
                },
              });
              successNotification(`New item created`);
              hideItemModal();
            } catch (error) {
              errorNotification((error as Error)?.message);
            }
          }
        } catch (error) {
          errorNotification((error as Error)?.message);
        }
      }
    } catch (error) {
      errorNotification((error as Error)?.message);
    }
  };

  const handleEditExperience = async () => {
    const newImages = formFields.images?.filter((item: any) => {
      return item.file;
    });

    const existingImages = formFields.images?.filter((item: any) => {
      return !item.file;
    });

    try {
      const { data } = await createPresignedUrl({
        variables: {
          input: {
            numberOfImages: newImages.length,
          },
        },
      });

      if (data) {
        try {
          const imagesUrls = await uploadImages(
            data.createExperiencePresignedUrl,
            newImages
          );

          if (imagesUrls) {
            // here we combine old images and new ones, uploading only new ones to s3
            const newAndExistingImages = [
              ...imagesUrls,
              ...existingImages.map((item: any) => item.data_url),
            ];
            try {
              await updateExperience({
                variables: {
                  input: {
                    numberOfUnits:
                      formFields.numberOfUnits === null
                        ? null
                        : Number(formFields.numberOfUnits),
                    id: record?.id || '',
                    title: formFields.title,
                    description: formFields.description,
                    hashtagInputs: formFields?.hashtags?.length
                      ? formatHashtagInput(formFields.hashtags)
                      : null,
                    requestedPrice: Number(formFields.requestedPrice),
                    images: newAndExistingImages.map((item) => ({
                      isMainImage:
                        newAndExistingImages.indexOf(item) === isMainImage,
                      experienceImageFileKey: item.includes('https://')
                        ? `experience-image${
                            item.split('experience-image')[1]
                          }  `
                        : item,
                    })),
                  },
                },
              });
              successNotification(`Item Updated`);
              hideItemModal();
            } catch (error) {
              errorNotification((error as Error)?.message);
            }
          }
        } catch (error) {
          errorNotification((error as Error)?.message);
        }
      }
    } catch (error) {
      errorNotification((error as Error)?.message);
    }
  };

  const handleFinish = async () => {
    if (currentAction === 'Edit') {
      await handleEditExperience();
    } else {
      await handleCreateExperience();
    }
  };

  const handleHashtagsChange = (hashtags: string[]) => {
    form.setFieldsValue({ hashtags: hashtags });
    setFields();
  };

  const finalPrice = estimatedPrice?.estimateExperiencePrice.price || 0;

  return (
    <>
      <Form
        form={form}
        layout="horizontal"
        name="setupStream"
        initialValues={initialFormValues}
        autoComplete="off"
        onValuesChange={setFields}
        onLoad={setFields}
        onFinish={handleFinish}
      >
        <div className={styles.formWrapper}>
          <div>
            <Title>Item details</Title>

            <Form.Item
              className={styles.customInput}
              name="title"
              label={<Text>Title</Text>}
              help="Name your item"
              rules={[
                {
                  required: true,
                  message: 'Please enter the title',
                },
              ]}
            >
              <Input placeholder="Example of title" />
            </Form.Item>

            <Form.Item
              className={styles.customInput}
              name="description"
              label={<Text>Description</Text>}
              rules={[
                {
                  required: true,
                  message: 'Please enter the description',
                },
              ]}
            >
              <TextArea placeholder="This should be a short description which reflects the uniqueness of the experience item." />
            </Form.Item>

            <Form.Item name="hashtags" label="Tagging">
              <Controller
                name="hashtags"
                defaultValue={formFields.hashtags}
                control={control}
                render={() => (
                  <TagsInput
                    hashtags={formFields.hashtags}
                    onChange={(val: any) => handleHashtagsChange(val)}
                    name="hashtags"
                  />
                )}
              />
            </Form.Item>

            <Form.Item
              name="isUnlimited"
              valuePropName="checked"
              label={<Text>Unlimited</Text>}
            >
              <Switch onChange={onCheckboxChange} />
            </Form.Item>
            <Form.Item
              className={styles.customInput}
              name="numberOfUnits"
              label="Number of Experiences"
              help="How many of these Experiences are you offering?"
              rules={[
                {
                  required: !formFields.isUnlimited,
                  message: 'Please provide number of units',
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Type here"
                disabled={formFields.isUnlimited}
              />
            </Form.Item>
            <div className={styles.priceWrapper}>
              <Form.Item
                className={styles.customInput}
                name="requestedPrice"
                label="Price"
                help="Max price is $799999"
                rules={[
                  {
                    required: true,
                    message: 'Price is required',
                  },
                ]}
              >
                <Input max={799999} type="number" placeholder="Type here" />
              </Form.Item>

              <Text>Final Price: {formatPrice(finalPrice)}</Text>
            </div>
          </div>

          <div className={styles.imageUploadWrapper}>
            <Title>Image upload</Title>
            <Form.Item
              name="images"
              rules={[
                {
                  required: true,
                  message: 'Please provide at least one image',
                },
              ]}
            >
              <UploadMultipleImages
                images={formFields.images}
                setImages={(images) => {
                  form.setFieldsValue({
                    images,
                  });
                  setFields();
                }}
                isMainImage={isMainImage}
                setIsMainImage={setIsMainImage}
              />
            </Form.Item>
          </div>
        </div>
        <div className={styles.modalFooter}>
          {loading || updateLoading ? (
            <Spin size="large" />
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              className="createEditFormSubmit"
            >
              Confirm
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default CreateEditExperience;
