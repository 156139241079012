import React from 'react';
// Hooks
import { useAppContext } from 'hooks';
// Components
import DesignRequestsTable from 'components/common/DesignRequestsTable/DesignRequestsTable';

const MyDesignRequests: React.FC = () => {
  const { authUser } = useAppContext();

  return <DesignRequestsTable designerId={authUser?.id || ''} />;
};

export default MyDesignRequests;
