import React from 'react';
// Types
import { YAxisProps } from 'recharts';

import styles from './CustomYAxisTick.module.scss';

interface CustomYAxisTickPayload {
  value: string | number;
}

type CustomYAxisTickProps = YAxisProps & {
  payload?: CustomYAxisTickPayload;
  currency?: string;
};

export const CustomYAxisTick: React.FC<CustomYAxisTickProps> = ({
  x,
  y,
  payload,
  currency,
}) => {
  const formatNumber = (number: number): string => {
    const formattedValue = number.toLocaleString('en-GB', {
      // info note: {notation: 'compact'} is not experimental feature anymore,
      //            but TypeScript doesn't support it yet. After update TS to
      //            higher version check if it is available there
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      notation: 'compact',
      compactDisplay: 'short',
    });

    return `${currency}${formattedValue}`;
  };

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={-28}
        y={0}
        dy={8}
        textAnchor="end"
        fill="#333333"
        className={styles.chartCustomAxisTick}
      >
        {formatNumber(Number(payload?.value))}
      </text>
    </g>
  );
};
