import { Dispatch, SetStateAction } from 'react';
import moment, { Moment } from 'moment';
import dayjs from 'dayjs';
// Constants
import { commonTimeFormat } from 'constants/global';
// Helpers
import {
  getCurrentTimeZoneOffset,
  getDateInOriginalTimeZone,
} from './timeZone';
// Types
import {
  GetHomePageData_getHomePageData_stores,
  GetHomePageData_getHomePageData_amas_store,
  GetHomePageData_getHomePageData_merch_store,
  GetHomePageData_getHomePageData_watchStreams_store,
} from 'api/homePageSetup/types/GetHomePageData';
import { Streams_adminStreams_entities_store } from 'api/streams/types/Streams';
import {
  DesignRequests_designRequests_entities_store,
  DesignRequests_designRequests_entities_designer,
} from 'api/designLab/types/DesignRequests';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import {
  AmaOrderStatus,
  StreamOrderStatus,
  StreamStatus,
  TimeZoneInput,
  UserRole,
} from 'api/graphql-global-types';
import { GetStores_adminStores_entities } from 'api/store/types/GetStores';
import { GetAllAmas_amas_entities_store } from 'api/ama/types/GetAllAmas';
import { GetUpcomingStreams_adminStreams_entities_store } from 'api/streams/types/GetUpcomingStreams';

export const formatNumber = (number: number): string =>
  new Intl.NumberFormat().format(number);

export const pictureSizeAndFormatValidation = (
  file: File,
  setPictureValidation: Dispatch<SetStateAction<string>>
): boolean => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  setPictureValidation('');

  if (!isJpgOrPng) {
    setPictureValidation('Please upload only JPG/PNG files');
    return false;
  }

  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    setPictureValidation('Picture should be smaller than 2MB');
    return false;
  }

  return true;
};

export const formatCurrencyString = (
  value: number | string,
  prefix = '$',
  float = 2
): string => {
  const numValue = Number(value);
  return !isNaN(numValue)
    ? `${numValue < 0 ? '-' : ''}${prefix}${Number(
        numValue < 0 ? -numValue : numValue
      ).toLocaleString('en-US', {
        minimumFractionDigits: float,
        maximumFractionDigits: float,
      })}`
    : '\u2014';
};

export const renderedCheckForBooleans = (
  text: string | 'true' | 'false' | boolean | null | undefined
): string =>
  (typeof text === 'boolean' && text) || text === 'true'
    ? '✓'
    : !text || text === 'false'
    ? ''
    : text;

export const getFullName = (
  user:
    | GetStores_adminStores_entities
    | GetAllAmas_amas_entities_store
    | Streams_adminStreams_entities_store
    | GetHomePageData_getHomePageData_stores
    | GetHomePageData_getHomePageData_amas_store
    | DesignRequests_designRequests_entities_store
    | GetUpcomingStreams_adminStreams_entities_store
    | DesignRequests_designRequests_entities_designer
    | undefined
    | null
): string => `${user?.firstName || ''} ${user?.lastName || ''}`;

export const getStoreName = (
  store:
    | GetStores_adminStores_entities
    | GetAllAmas_amas_entities_store
    | GetHomePageData_getHomePageData_stores
    | GetHomePageData_getHomePageData_merch_store
    | DesignRequests_designRequests_entities_store
    | GetUpcomingStreams_adminStreams_entities_store
    | GetHomePageData_getHomePageData_watchStreams_store
    | null
): string => {
  return (
    store?.storeDetails?.storeName ||
    (store?.role === UserRole.Organization ||
    store?.role === UserRole.ContentCreator
      ? store?.email || getFullName(store)
      : getFullName(store))
  );
};

export const getPublicStreamDate = (
  date: string | null | undefined,
  timeZone?: TimeZoneInput | null | undefined
): string => {
  if (date && dayjs(date).isValid()) {
    const dateString = getDateInOriginalTimeZone(date, commonTimeFormat);
    return `${dateString} ${timeZone?.tzCode || ''}`;
  }

  return '\u2014';
};

export const calculateRemainingDays = (value: string): string => {
  const daysLeft = moment(value).startOf('day').diff(moment(), 'day');
  return `${daysLeft > 0 ? daysLeft : 0}`;
};

export const getStartOfDayDate = (date: Moment): string =>
  moment(date)
    .startOf('day')
    .add(getCurrentTimeZoneOffset(), 'm')
    .toISOString();

export const getEndOfDayDate = (date: Moment): string =>
  moment(date).endOf('day').add(getCurrentTimeZoneOffset(), 'm').toISOString();

export const getUnique = <T extends string | number>(data: T[]): T[] =>
  Array.from(new Set(data));

export const getEvenNumber = (val: number | null | undefined): number =>
  val ? val - (val % 2) : 0;

export const customRequest = ({ onSuccess }: UploadRequestOption): void => {
  if (onSuccess) {
    onSuccess(null);
  }
};

export const getCustomerName = (
  firstName: string | null,
  lastName: string | null
): string => (firstName || lastName ? `${firstName} ${lastName}` : '');

export const orderStatus = [
  { value: AmaOrderStatus.Accepted, text: 'Accepted' },
  { value: AmaOrderStatus.Completed, text: 'Completed' },
  { value: AmaOrderStatus.Declined, text: 'Declined' },
  { value: AmaOrderStatus.Pending, text: 'Pending' },
  { value: AmaOrderStatus.Processing, text: 'Processing' },
];

export const statuses = [
  { value: StreamStatus.Active, text: 'Active' },
  { value: StreamStatus.Cancelled, text: 'Cancelled' },
  { value: StreamStatus.Ended, text: 'Ended' },
  { value: StreamStatus.Interrupted, text: 'Interrupted' },
  { value: StreamStatus.Paused, text: 'Paused' },
  { value: StreamStatus.Scheduled, text: 'Scheduled' },
];

export const paymentStatus = [
  { value: StreamOrderStatus.Completed, text: 'Completed' },
  { value: StreamOrderStatus.Cancelled, text: 'Cancelled' },
  { value: StreamOrderStatus.Pending, text: 'Pending' },
  { value: StreamOrderStatus.Processing, text: 'Processing' },
];
