export const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const parseToPlainText = (value: string | null | undefined): string => {
  if (value) {
    try {
      const isJson = isJsonString(value);
      let parsedValue = value;

      if (isJson) {
        parsedValue = JSON.parse(value);
      }

      return parsedValue.replace(/(<([^>]+)>)/gi, '');
    } catch (error) {
      return '';
    }
  }

  return '';
};

export const parseForInnerHTML = (value: string | null | undefined): string => {
  if (value) {
    const isJson = isJsonString(value);
    let parsedValue = value;

    if (isJson) {
      parsedValue = JSON.parse(value);
    }

    return parsedValue;
  }

  return '';
};

// same as the function above with the addition of applying empty lines inside the 'if'
export const parseForInnerHTMLWithBreaks = (
  value: string | null | undefined
): string => {
  if (value) {
    const isJson = isJsonString(value);
    let parsedValue = value;

    if (isJson) {
      parsedValue = JSON.parse(value);
    }

    // Replace newlines with <br> tags
    const textWithBreaks = parsedValue.replace(/\n/g, '<br>');
    // Replace multiple spaces with non-breaking spaces
    const textWithSpaces = textWithBreaks.replace(/  +/g, '&nbsp;&nbsp;');

    return textWithSpaces;
  }

  return '';
};
