import env from 'api/env';
import { getEnvLink } from './getEnvLink';

export const createPublicStreamLink = (
  storeSlug: string,
  streamSlug: string
): string =>
  `${getEnvLink(env.REACT_APP_ENV)}/${storeSlug}/streams/${streamSlug}`;

export const createPublicMediaPostLink = (
  storeSlug: string,
  mediaPostSlug: string
): string =>
  `${getEnvLink(
    env.REACT_APP_ENV
  )}/${storeSlug}/social-media-post/${mediaPostSlug}`;
