import React, { useState, ChangeEvent, FormEvent } from 'react';
import { CSVLink } from 'react-csv';
import ENV from 'api/env';
import cn from 'classnames';
import { useMutation } from '@apollo/client';
// Api
import { CREATE_BULK_UPLOAD_MUTATION } from 'api/bulkUpload/mutations';
// Types
import {
  createBulkUploadV2,
  createBulkUploadV2Variables,
} from 'api/bulkUpload/types/createBulkUploadV2';
import {
  CreateBulkImagePostAdminInput,
  CreateBulkVideoPostAdminInput,
} from 'api/graphql-global-types';
// Helpers
import { getAuthUserFromCookies } from 'helpers/cookies';
import { refreshUserToken } from 'helpers/auth';
// Ui
import { successNotification, errorNotification } from 'ui/Notification';
import Button from 'uiShared/Button/Button';
import TextArea from 'uiShared/Textarea/Textarea';
// Styles
import styles from './BulkUpload.module.scss';
const MAX_RETRY_COUNT = 1;

interface BulkUploadResponse {
  athleteName: string | null;
  title: string;
  mediaUrl: string;
  description?: string | null;
  __typename?: string;
}
const BulkUpload: React.FC = () => {
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [uploadedFileName, setUploadedFileName] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [csvData, setCsvData] = useState<BulkUploadResponse[]>([]);

  const fileInputRef = React.createRef<HTMLInputElement>();

  const [createBulkUpload, { loading: mutationLoading }] = useMutation<
    createBulkUploadV2,
    createBulkUploadV2Variables
  >(CREATE_BULK_UPLOAD_MUTATION);

  const handleSelectFile = () => {
    fileInputRef.current?.click();
  };
  const onCSVButtonClick = () => {
    setCsvData([]);
    successNotification('File downloading');
    setIsSubmitting(false);
  };
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      setCsvFile(null);
      setUploadedFileName('');
      errorNotification('No CSV selected');
      return;
    }
    setCsvFile(file);
    setUploadedFileName(file?.name || '');

    const isCsvFile = file.name.toLowerCase().endsWith('.csv');

    if (!isCsvFile) {
      errorNotification('Please select a valid CSV file');
      setCsvFile(null);
      setUploadedFileName('');
      return;
    }
  };

  const handleResponse = async (
    response: Response,
    retryCount: number,
    event: FormEvent<HTMLFormElement>
  ) => {
    if (!response.ok) {
      if (response.status === 502) {
        errorNotification('Internal Server Error');
        setIsSubmitting(false);
      } else if (response.status === 401 && retryCount < MAX_RETRY_COUNT) {
        await refreshUserToken();
        handleFormSubmit(event, retryCount + 1);
      } else {
        const responseData = await response.json();
        errorNotification(responseData.message || 'Internal Server Error');
        setIsSubmitting(false);
      }
    } else {
      try {
        const responseData = await response.json();
        const imageRows: CreateBulkImagePostAdminInput[] = responseData.images;
        const videoRows: CreateBulkVideoPostAdminInput[] = responseData.videos;
        const { data: uploadData } = await createBulkUpload({
          variables: {
            input: {
              images: imageRows,
              videos: videoRows,
            },
          },
        });
        const data = uploadData ? uploadData.createBulkUploadV2 : [];
        // done like this to avoid eslint rule disable (no-unused-vars) setCsvData(data?.map(({ __typename, ...rest }) => rest));
        const filteredData = data?.map((item) => {
          const rest = {} as BulkUploadResponse;
          for (const key in item) {
            if (key !== '__typename') {
              rest[key as keyof BulkUploadResponse] = item[
                key as keyof BulkUploadResponse
              ] as string;
            }
          }
          return rest;
        });

        setCsvData(filteredData);
        successNotification('Upload successful');
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
        errorNotification('Error uploading CSV file');
      }
    }
  };

  const handleFormSubmit = async (
    event: FormEvent<HTMLFormElement>,
    retryCount = 0
  ) => {
    event.preventDefault();
    const authUserFromCookies = getAuthUserFromCookies();
    const tokenFromCookies = authUserFromCookies?.accessToken || '';

    try {
      if (!csvFile) {
        errorNotification('No CSV selected');
        return;
      }
      setIsSubmitting(true);
      const fileReader = new FileReader();
      fileReader.onloadend = async () => {
        const csvContent = fileReader.result as string;
        const lambdaEndpoint = ENV.MEDIA_LAMBDA_URL;
        const response = await fetch(lambdaEndpoint, {
          method: 'POST',
          body: JSON.stringify({ csvContent }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenFromCookies}`,
          },
        });
        handleResponse(response, retryCount, event);
      };
      fileReader.readAsText(csvFile);
    } catch (error) {
      setIsSubmitting(false);
      const typedError = error as Error;
      errorNotification(typedError.message || 'Error uploading CSV file');
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <form
        onSubmit={handleFormSubmit}
        autoComplete="off"
        aria-label="bulk upload"
      >
        <input
          ref={fileInputRef}
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          className={styles.fileNameInput}
        />
        <Button
          className={styles.fileNameButton}
          type="button"
          s="default"
          color="harvest-gold"
          disabled={isSubmitting || mutationLoading}
          onClick={handleSelectFile}
        >
          Add CSV File
        </Button>

        <TextArea
          name="body"
          placeholder="File"
          value={uploadedFileName}
          className={cn(styles.textarea, styles.nameTextarea)}
          rows={6}
        />

        <div className={styles.buttonContainer}>
          <Button
            className={styles.submitButton}
            type="submit"
            s="default"
            color="harvest-gold"
            disabled={!csvFile || isSubmitting || mutationLoading}
          >
            Submit
          </Button>
        </div>
        {csvData.length > 0 && (
          <div className={styles.downloadButton}>
            <Button
              type="button"
              color="harvest-gold"
              className={styles.fileNameButton}
            >
              <CSVLink
                className={styles.fileNameButton}
                color="yellow"
                style={{ color: 'black' }}
                data={csvData}
                filename="data.csv"
                onClick={onCSVButtonClick}
              >
                Download CSV
              </CSVLink>
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};

export default BulkUpload;
