import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Button, Modal } from 'antd';
// Api
import { GET_EXPERIENCE_SALES } from 'api/experience/queries';
// Types
import { SortedInfo, TableFilter } from 'ui/Table';
import {
  GetExperienceSales,
  GetExperienceSalesVariables,
  GetExperienceSales_getExperienceSales_entities,
} from 'api/experience/types/GetExperienceSales';
import {
  SortDirection,
  ExperienceSalesOrderBy,
} from 'api/graphql-global-types';
// Helpers
import { formatCurrencyString, getCustomerName } from 'helpers/utils';
// Ui
import Table, {
  DEFAULT_NUMBER_ITEMS_PER_PAGE,
  DEFAULT_CURRENT_PAGE,
} from 'ui/Table';
// Components
import ProductSalesDetails from './components/ExperienceSalesDetails';
import { onlyDateTimeFormat } from 'constants/global';
import moment from 'moment';

export type ExperienceSalesItems = {
  id: string;
  storeName: string;
  customerName: string;
  email: string;
  quantity: number;
  price: string;
  createdAt: string;
  experienceItem: GetExperienceSales_getExperienceSales_entities;
};

const ExperienceSales = (): JSX.Element => {
  const [experienceDetailsModal, setExperienceDetailsModal] =
    useState<ExperienceSalesItems | null>();
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [pageSize, setPageSize] = useState<number>(
    DEFAULT_NUMBER_ITEMS_PER_PAGE
  );

  const { storeId } = useParams<{ storeId: string | undefined }>();

  const defaultSortInfo: SortedInfo<ExperienceSalesOrderBy> = {
    order: SortDirection.DESC,
    key: ExperienceSalesOrderBy.CreatedAt,
  };

  const [sort, setSortInfo] =
    useState<SortedInfo<ExperienceSalesOrderBy>>(defaultSortInfo);

  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const getExperienceRequestsInput: any = () => {
    const input: any = {
      storeId,
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      if (key === 'CreatedAt') {
        return (input['createdAtDateRange'] = {
          from: Array.isArray(value) ? value?.[0] : value,
          to: Array.isArray(value) ? value?.[1] : value,
        });
      }

      if (key === 'Id') {
        return (input['id'] = value);
      }

      if (key === 'CustomerFirstName') {
        return (input['customerName'] = value);
      }

      if (key === 'CustomerEmail') {
        return (input['customerEmail'] = value);
      }
      input[key] = value;
    });

    return { input };
  };

  const { data, loading } = useQuery<
    GetExperienceSales,
    GetExperienceSalesVariables
  >(GET_EXPERIENCE_SALES, {
    variables: {
      ...getExperienceRequestsInput(),
    },
    fetchPolicy: 'cache-and-network',
  });

  const salesData: ExperienceSalesItems[] =
    data?.getExperienceSales?.entities.map((experienceSalesEntity) => {
      const { id, createdAt, customer, quantity, price, store } =
        experienceSalesEntity;

      return {
        id,
        email: customer.email || '',
        createdAt: moment(createdAt).format(onlyDateTimeFormat),
        storeName: store?.storeDetails?.storeName || '',
        customerName: getCustomerName(customer.firstName, customer.lastName),
        quantity: quantity || 1,
        price: formatCurrencyString(price),
        experienceItem: experienceSalesEntity,
      };
    }) || [];
  const handleCloseModal = () => {
    setExperienceDetailsModal(null);
  };

  const columns = [
    {
      title: 'Experience Id',
      dataIndex: 'id',
      key: ExperienceSalesOrderBy.Id,
      fixed: 'left' as const,
      width: 140,
      sorterType: 'text',
      withSearch: true,
    },

    {
      title: 'Store`s Name',
      dataIndex: 'storeName',
      width: 100,
      withSearch: true,
      key: 'storeName',
    },
    {
      title: 'Customer`s Name',
      dataIndex: 'customerName',
      key: ExperienceSalesOrderBy.CustomerFirstName,
      width: 100,
      sorterType: 'text',
      withSearch: true,
    },
    {
      title: 'Customer Email',
      dataIndex: 'email',
      key: ExperienceSalesOrderBy.CustomerEmail,
      width: 160,
      sorterType: 'text',
      withSearch: true,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: ExperienceSalesOrderBy.CreatedAt,
      width: 70,
      sorterType: 'date',
      withDateRangeFilter: true,
    },
    {
      title: 'Units',
      dataIndex: 'quantity',
      align: 'right' as const,
      width: 60,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      align: 'right' as const,
      width: 70,
    },
    {
      title: 'Actions',
      dataIndex: '',
      align: 'center' as const,
      fixed: 'right' as const,
      width: 70,
      render: function ActionWrapper(record: ExperienceSalesItems) {
        return (
          <Button onClick={() => setExperienceDetailsModal(record)}>
            Details
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Table<ExperienceSalesItems, ExperienceSalesOrderBy>
        columns={columns}
        data={salesData}
        rowKey={(item) => item.id}
        scroll={{ x: 1200 }}
        loading={loading}
        total={data?.getExperienceSales?.total}
        defaultPageSize={pageSize}
        defaultCurrent={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />
      <Modal
        visible={!!experienceDetailsModal}
        onCancel={handleCloseModal}
        title="Details"
        footer={null}
        width="90vw"
      >
        {experienceDetailsModal && (
          <ProductSalesDetails experienceRequest={experienceDetailsModal} />
        )}
      </Modal>
    </>
  );
};

export default ExperienceSales;
