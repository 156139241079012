import React, { useEffect } from 'react';
import moment from 'moment';
import { Input, Form, DatePicker, Typography } from 'antd';
// Types
import { StoryOption } from 'pages/HomePageManagement';
import { UploadFile } from 'antd/es/upload/interface';
import { OptionsItem } from 'pages/HomePageManagement/components/SectionItem';
// Constants
import { onlyDateTimeFormat } from 'constants/global';
// UI
import UploadImageFile from 'ui/UploadImageFile/UploadImageFile';
// Components
import SetupStoreSectionItem from 'pages/HomePageManagement/components/SetupStoreSectionItem';

const { Text } = Typography;

export type SetupStoriesItemProps = {
  onStoryChange: (index: number, data: Partial<StoryOption>) => void;
  item: StoryOption;
  index: number;
};

type FormValues = {
  image: UploadFile[] | null;
  imageURL: string;
  title: string;
  description: string;
  link: string;
  publishedDate: moment.Moment | null;
};

const normFile = (e: any) => {
  if (e && Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const SetupStoryItem: React.FC<SetupStoriesItemProps> = ({
  onStoryChange,
  item,
  index,
}) => {
  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    if (item) {
      form.setFieldsValue({
        image: null,
        imageURL: item.imageURL || '',
        title: item.title || '',
        description: item.description || '',
        link: item.link || '',
        publishedDate: item.publishedDate,
      });
    }
  }, [item, form]);

  const handleStoreChange = (item: OptionsItem[]) => {
    onStoryChange(index, { store: item[0] });
  };

  const setFields = () => {
    const fields = form.getFieldsValue();

    onStoryChange(index, {
      ...fields,
      image: fields.image?.[0]?.originFileObj || null,
    });
  };

  const formItemStyle = { width: '100%', marginBottom: 14 };

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 10 }}
      onValuesChange={setFields}
      layout="vertical"
    >
      <Form.Item
        name="title"
        label="Title"
        style={formItemStyle}
        rules={[
          {
            required: true,
            message: 'Please, input title',
          },
        ]}
      >
        <Input
          name="title"
          value={item.title || ''}
          placeholder="Type  title"
        />
      </Form.Item>
      {index === 0 && (
        <Form.Item
          name="description"
          label="Description"
          style={formItemStyle}
          rules={[
            {
              required: true,
              message: 'Please, input description',
            },
          ]}
        >
          <Input
            name="description"
            value={item.description || ''}
            placeholder="Type description"
          />
        </Form.Item>
      )}
      <Form.Item
        name="link"
        label="Link"
        style={formItemStyle}
        rules={[
          {
            required: true,
            message: 'Please, input link',
          },
        ]}
      >
        <Input name="link" value={item.link || ''} placeholder="Type link" />
      </Form.Item>
      <Form.Item label="Store">
        <SetupStoreSectionItem
          item={item.store}
          itemIndex={0}
          onItemChange={handleStoreChange}
          selectedItems={[item.store]}
        />
      </Form.Item>
      <Form.Item
        name="publishedDate"
        style={formItemStyle}
        label={<Text>Set up the date</Text>}
        rules={[
          {
            required: true,
            message: 'Please enter the date',
          },
        ]}
      >
        <DatePicker
          name="publishedDate"
          format={onlyDateTimeFormat}
          style={{ width: 250 }}
          minuteStep={15}
        />
      </Form.Item>
      <Form.Item
        name="image"
        label="Image"
        style={formItemStyle}
        rules={[
          {
            required: true,
            message: 'Please upload the picture',
          },
        ]}
        getValueFromEvent={normFile}
        valuePropName="file"
      >
        <UploadImageFile
          buttonTitle="Upload photo"
          defaultFileUrl={item?.imageURL}
        />
      </Form.Item>
    </Form>
  );
};

export default SetupStoryItem;
