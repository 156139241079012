import React from 'react';
import { Tabs } from 'antd';
import { useParams } from 'react-router-dom';
// Hooks
import { useDefaultCustomerRedirectOnEmptyId } from 'hooks';
// Components
import AmaList from './components/AmaList';
import MerchList from './components/MerchList';
import StreamsList from './components/StreamsList';

const { TabPane } = Tabs;

const CustomerPurchases: React.FC = () => {
  useDefaultCustomerRedirectOnEmptyId();

  const { customerId } = useParams<{ customerId: string | undefined }>();
  return (
    <Tabs defaultActiveKey="merch">
      <TabPane tab="Orders" key="merch">
        <MerchList customerId={customerId?.toString() || ''} />
      </TabPane>
      <TabPane tab="AMA" key="AMA">
        <AmaList customerId={customerId?.toString() || ''} />
      </TabPane>
      <TabPane tab="Streams" key="streams">
        <StreamsList customerId={customerId?.toString() || ''} />
      </TabPane>
    </Tabs>
  );
};

export default CustomerPurchases;
