import React, { useState } from 'react';
import cn from 'classnames';
import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {
  useHistory,
  useParams,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
// Types
import { GetAdminMerchProducts_getAdminMerchProducts_entities } from 'api/merch/types/GetAdminMerchProducts';
import { MerchType } from 'api/graphql-global-types';
// Components
import PRPItem from '../MerchItem/MerchItem';
// Styles
import styles from './ToggledMerchSection.module.scss';

type ToggledMerchSectionProps = {
  title: string;
  products?: GetAdminMerchProducts_getAdminMerchProducts_entities[];
  productType: keyof typeof MerchType;
};

const ToggledMerchSection: React.FC<ToggledMerchSectionProps> = ({
  title,
  products,
  productType,
}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const { storeId } = useParams<{ storeId: string | undefined }>();
  const location = useLocation();
  const manageMerchRole = `/${location.pathname.split('/')[1]}`;

  const [open, setOpen] = useState<boolean>(true);

  const handleCreateButtonClick = () => {
    const path = !storeId
      ? match.path
      : `${manageMerchRole}/${productType}/${storeId}`;
    history.push(path);
  };

  const handleToggleButtonClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className={styles.root}>
      <div
        className={cn(styles.header, {
          [styles.open]: open && products?.length,
        })}
      >
        <div className={styles.titleWrapper}>
          <h1 className={styles.title}>
            {title}{' '}
            <span className={styles.count}>({products?.length || 0})</span>
          </h1>
          <Button onClick={handleCreateButtonClick} color="harvest-gold">
            Create
          </Button>
        </div>

        <button
          className={styles.toggleButton}
          onClick={handleToggleButtonClick}
        >
          <DownOutlined
            className={cn(styles.chevronIcon, {
              [styles.rotate]: open,
            })}
          />
        </button>
      </div>

      {open && (
        <div className={styles.merchList}>
          {products?.map((item) => (
            <PRPItem key={item.id} product={item} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ToggledMerchSection;
