import React, { useState } from 'react';
import { Modal } from 'antd';
import { useQuery } from '@apollo/client';
// Helpers
import { getPublicStreamDate } from 'helpers/utils';
// Api
import { GET_INTERVIEWS } from 'api/interview/queries';
// Types
import { InterviewsFormattedData } from 'pages/Interviews/Admin/InterviewsTable/InterviewsTable';
import {
  GetInterviews,
  GetInterviewsVariables,
  GetInterviews_getInterviews_entities,
} from 'api/interview/types/GetInterviews';
import {
  InterviewStatus,
  InterviewerTasksOrderBy,
  SortDirection,
} from 'api/graphql-global-types';
// UI
import Table, { Column, SortedInfo } from 'ui/Table';
// Columns
import {
  createdAtColumn,
  interviewTypeColumn,
  statusColumn,
} from 'pages/Interviews/common/Columns';

type TasksProps = {
  interviewerId: string;
  interviewerName: string;
  show: boolean;
  onClose: () => void;
};

const InterviewTasks: React.FC<TasksProps> = ({
  show,
  onClose,
  interviewerId,
  interviewerName,
}) => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<InterviewerTasksOrderBy>>({
    order: SortDirection.DESC,
    key: InterviewerTasksOrderBy.createdAt,
  });

  const { data, loading } = useQuery<GetInterviews, GetInterviewsVariables>(
    GET_INTERVIEWS,
    {
      variables: {
        input: {
          direction: sort.order,
          orderBy: sort.key,
          limit: pageSize,
          offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
          statuses: [InterviewStatus.Scheduled],
          interviewerId,
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const interviewIdColumn = {
    title: 'Task ID',
    dataIndex: 'interviewId',
    key: 'interviewId',
    align: 'center' as const,
    width: 130,
  };

  const intervieweeNameColumn = {
    title: 'Athlete',
    dataIndex: 'intervieweeName',
    key: 'intervieweeName',
    align: 'left' as const,
    width: 120,
    sorterType: 'text',
    withSearch: true,
  };

  const isAutoAssignColumn = {
    title: 'Auto Assign',
    dataIndex: 'isIncludedInAutoAssignment',
    key: 'isIncludedInAutoAssignment',
    align: 'center' as const,
    width: 60,
    render: function AutoAssignWrap(
      isIncludedInAutoAssignment: boolean | undefined
    ) {
      return <span>{isIncludedInAutoAssignment ? 'Yes' : 'No'}</span>;
    },
  };

  const scheduleDateColumn = {
    title: 'Schedule date',
    dataIndex: 'scheduleDate',
    key: 'scheduleDate',
    align: 'center' as const,
    width: 110,
    sorterType: 'date',
    render: (id: string, record: InterviewsFormattedData): JSX.Element => {
      return (
        <>
          {record?.stream?.scheduleDate
            ? getPublicStreamDate(
                record?.scheduleDate,
                record?.stream?.timeZone
              )
            : '-'}
        </>
      );
    },
  };

  const tasksColumns: Column<
    InterviewsFormattedData,
    InterviewerTasksOrderBy
  >[] = [
    interviewIdColumn,
    intervieweeNameColumn,
    createdAtColumn,
    scheduleDateColumn,
    interviewTypeColumn,
    statusColumn,
    isAutoAssignColumn,
  ];

  const getFormattedData = (
    data: GetInterviews | undefined
  ): InterviewsFormattedData[] => {
    const result: InterviewsFormattedData[] = [];

    data?.getInterviews?.entities?.forEach(
      (i: GetInterviews_getInterviews_entities) => {
        result.push({
          ...i,
          interviewId: i.id,
          intervieweeName: `${i?.interviewee?.firstName || ''} ${
            i?.interviewee?.lastName || ''
          }`,
          status: i.status || '',
          interviewDate: i.createdAt || '',
          interviewType: i.source.type || '',
          interviewerName: `${i?.interviewer?.firstName || ''} ${
            i?.interviewer?.lastName || ''
          }`,
          scheduleDate: i?.stream?.scheduleDate || '',
          timeZone: i?.stream?.timeZone || '',
        });
      }
    );

    return result;
  };

  return (
    <Modal
      title={
        <>
          <strong>{interviewerName}</strong> Interviews
        </>
      }
      visible={show}
      footer={null}
      onCancel={onClose}
      width="70vw"
    >
      <Table<InterviewsFormattedData, InterviewerTasksOrderBy>
        columns={tasksColumns}
        data={getFormattedData(data)}
        scroll={{ x: 1280 }}
        loading={loading}
        total={data?.getInterviews?.total}
        defaultPageSize={pageSize}
        defaultCurrent={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />
    </Modal>
  );
};

export default InterviewTasks;
