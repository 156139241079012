import { gql } from '@apollo/client';

export const CREATE_NOTIFICATION = gql`
  mutation CreateSendout($input: CreateSendoutInput!) {
    createSendout(input: $input) {
      id
    }
  }
`;

export const CREATE_PRESIGNED_CSV_FILE = gql`
  mutation CreatePresignedCsvUpload($input: CreatePresignedCsvUploadInput!) {
    createPresignedCsvUpload(input: $input) {
      key
      url
      fields
    }
  }
`;

export const CREATE_PRESIGNED_NOTIFICATION_IMAGE = gql`
  mutation CreatePresignedNotificationImageUpload(
    $input: CreatePresignedNotificationImageUploadInput!
  ) {
    createPresignedNotificationImageUpload(input: $input) {
      key
      url
      fields
    }
  }
`;

export const TEST_RENDER_TEMPLATE = gql`
  mutation TestRenderTemplate($input: TestRenderTemplateInput!) {
    testRenderTemplate(input: $input)
  }
`;
