import { gql } from '@apollo/client';

export const DASHBOARD_STATS = gql`
  query GetDashboardStats($input: DashboardFilter) {
    getDashboardStats(input: $input) {
      revenueStats
      revenueTotal
      profitStats
      profitTotal
      amountStats
      amountTotal
      popularItems {
        title
        type
        count
        revenue
        storeProfit
      }
    }
  }
`;
