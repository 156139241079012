// Types
import {
  DesignRequests,
  DesignRequests_designRequests_entities,
  DesignRequests_designRequests_entities_auditLogs,
  DesignRequests_designRequests_entities_designCreationDetails_designCreationDetailsImages,
  DesignRequests_designRequests_entities_designCreationDetails_merchItems,
  DesignRequests_designRequests_entities_rejections,
  DesignRequests_designRequests_entities_socialMediaPackImages,
} from 'api/designLab/types/DesignRequests';
import {
  DesignRequestStatus,
  DesignRequestType,
} from 'api/graphql-global-types';
// Helpers
import { getFullName, getStoreName } from './utils';
import moment from 'moment';

const SECONDS_IN_HOUR = 3600;

export type RequestImage = {
  link: string;
  name?: string;
  firstImageLink?: string;
  designRequestType: DesignRequestType;
  merchItems?:
    | DesignRequests_designRequests_entities_designCreationDetails_merchItems[]
    | null;
  designRequestId?: string;
  socialMediaPack?: DesignRequests_designRequests_entities_socialMediaPackImages[];
};

export type DesignRequestsFormattedData = Omit<
  DesignRequests_designRequests_entities,
  '__typename'
> & {
  designRequestId: string;
  designRequestType: DesignRequestType;
  storeName: string;
  designerName: string;
  requestDetails: RequestImage;
  requestResult: RequestImage;
  requestResults: RequestImage[];
  designRate: number;
  auditLogs: DesignRequests_designRequests_entities_auditLogs[];
  rejections: DesignRequests_designRequests_entities_rejections[];
};

export const getRequestFileName = (
  request: DesignRequests_designRequests_entities,
  postfix = ''
): string => {
  return `${request?.store?.firstName || ''}_${
    request?.store?.lastName || ''
  }_${request?.type || ''}_${postfix}_${request?.createdAt || ''}`;
};

const getDesignRequestResult = (
  entity: DesignRequests_designRequests_entities
) => {
  const result: RequestImage[] = [];

  if (entity?.designCreationDetails?.designCreationDetailsImages.length) {
    entity?.designCreationDetails?.designCreationDetailsImages.forEach(
      (
        image: DesignRequests_designRequests_entities_designCreationDetails_designCreationDetailsImages,
        index
      ) => {
        result.push({
          merchItems: entity.designCreationDetails?.merchItems || null,
          link: image.imageFileURL ? image.imageFileURL : '',
          name: getRequestFileName(entity) + index,
          designRequestType: entity.type,
          designRequestId: entity.id,
          socialMediaPack: entity.socialMediaPackImages
            ? entity.socialMediaPackImages
            : [],
        });
      }
    );
  } else if (entity?.designCreationDetails?.designCreationDetailsImages) {
    result.push({
      merchItems: entity.designCreationDetails?.merchItems || null,
      link:
        entity?.designCreationDetails?.designCreationDetailsImages[0]
          ?.imageFileURL || '',
      name: getRequestFileName(entity),
      designRequestType: entity.type,
      designRequestId: entity.id,
      socialMediaPack: entity.socialMediaPackImages
        ? entity.socialMediaPackImages
        : [],
    });
  } else {
    result.push({
      link: '',
      designRequestType: entity.type,
      merchItems: entity.designCreationDetails?.merchItems || null,
      designRequestId: entity.id,
      socialMediaPack: entity.socialMediaPackImages
        ? entity.socialMediaPackImages
        : [],
    });
  }

  return result;
};

export const getDesignRequestsFormattedData = (
  data: DesignRequests | undefined
): DesignRequestsFormattedData[] => {
  const result: DesignRequestsFormattedData[] = [];

  data?.designRequests?.entities?.forEach(
    (i: DesignRequests_designRequests_entities) => {
      result.push({
        ...i,
        designRequestId: i.id,
        designRequestType: i.type,
        storeName: getStoreName(i.store),
        designerName: getFullName(i.designer),
        requestDetails: {
          designRequestId: i.id,
          link:
            i.type === DesignRequestType.MerchCreation
              ? i.designCreationDetails?.pdfFile || ''
              : i.designCreationDetails?.storeLogos[0]?.url || '',
          name: getRequestFileName(i),
          designRequestType: i.type,
          firstImageLink:
            i.designCreationDetails?.designCreationDetailsImages[0]
              ?.imageFileURL || '',
        },
        requestResult: {
          designRequestId: i.id,
          merchItems: i.designCreationDetails?.merchItems || null,
          link:
            i.type === DesignRequestType.MerchCreation
              ? i.designCreationDetails?.designCreationDetailsImages[0]
                  ?.imageFileURL || ''
              : i.designCreationDetails?.storeLogos[0]?.url || '',
          name: getRequestFileName(i),
          designRequestType: i.type,
        },
        requestResults: getDesignRequestResult(i),
        designRate: Number(i.designCreationDetails?.rate || 0),
        rejections: i.rejections || [],
      });
    }
  );

  return result;
};

export const getDesignRequestDueDateCountdown = (
  entity: DesignRequestsFormattedData
): string => {
  if (
    entity.status === DesignRequestStatus.Accepted ||
    entity.status === DesignRequestStatus.AutoAccepted
  ) {
    const dateStoreAccepted = entity.auditLogs.find(
      (log) => log.__typename === 'AcceptedByStore'
    )?.createdAt;

    if (dateStoreAccepted) {
      return `Completed in ${Math.ceil(
        (moment(dateStoreAccepted).unix() - moment(entity.createdAt).unix()) /
          SECONDS_IN_HOUR
      )} hours`;
    }

    return `Completed in N/A hours`;
  }

  if (!entity.dueDate) {
    return 'N/A';
  }

  const isOverdue = moment(entity.dueDate).unix() - moment().unix() < 0;

  if (entity.status === DesignRequestStatus.InProgress && isOverdue) {
    return `Overdue by ${Math.round(
      (moment().unix() - moment(entity.createdAt).unix()) / SECONDS_IN_HOUR
    )} hours`;
  }

  if (entity.status === DesignRequestStatus.InProgress && !isOverdue) {
    return `Remaining ${Math.round(
      (moment(entity.dueDate).unix() - moment().unix()) / SECONDS_IN_HOUR
    )} hours`;
  }

  return moment(entity.dueDate).local().format('MM/DD/YYYY, h:mm a');
};
