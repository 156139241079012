import React, { useState } from 'react';
import { Button, DatePicker, Form, Modal, TimePicker } from 'antd';
import { DeleteOutlined, PlusSquareFilled } from '@ant-design/icons';
import { Moment } from 'moment';
// Constants
import { onlyDateTimeFormat } from 'constants/global';
// Helpers
import { DateSpecificHours, disabledDate } from 'helpers/availability';
// Styles
import styles from './SpecificHoursModal.module.scss';

type SpecificHoursModalProps = {
  isVisible: boolean;
  onClose: () => void;
  specificHoursList: DateSpecificHours[];
  setSpecificHoursList: React.Dispatch<
    React.SetStateAction<DateSpecificHours[]>
  >;
  handleAddToSpecificHoursList: (data: DateSpecificHours) => void;
};

const SpecificHoursModal = ({
  isVisible,
  onClose,
  handleAddToSpecificHoursList,
}: SpecificHoursModalProps): JSX.Element => {
  const [form] = Form.useForm();

  const [dateSpecificHours, setDateSpecificHours] = useState<DateSpecificHours>(
    {
      date: null,
      timeRanges: [{ from: null, to: null }],
    }
  );

  const handleTimeChange = (
    index: number,
    value: [Moment | null, Moment | null]
  ) => {
    const newTimeRanges = [...dateSpecificHours.timeRanges];
    newTimeRanges[index] = {
      from: value ? value[0] : null,
      to: value ? value[1] : null,
    };
    setDateSpecificHours({ ...dateSpecificHours, timeRanges: newTimeRanges });
  };

  const addTimeRange = () => {
    setDateSpecificHours({
      ...dateSpecificHours,
      timeRanges: [...dateSpecificHours.timeRanges, { from: null, to: null }],
    });
  };

  const removeTimeRange = (index: number) => {
    const newTimeRanges = dateSpecificHours.timeRanges.filter(
      (_, i) => i !== index
    );
    setDateSpecificHours({ ...dateSpecificHours, timeRanges: newTimeRanges });
  };

  const onFormSubmit = async () => {
    handleAddToSpecificHoursList(dateSpecificHours);
    onClose();
  };

  return (
    <Modal
      title="Select the date you want to assign specific hours"
      destroyOnClose
      visible={isVisible}
      okText="Save"
      cancelText="Cancel"
      onCancel={onClose}
      footer={null}
    >
      <Form
        form={form}
        layout="horizontal"
        name="hoursForm"
        autoComplete="off"
        className={styles.wrapper}
      >
        <div className={styles.specific}>
          <Form.Item name="specificDate" label="Date specific hours">
            <DatePicker
              disabledDate={disabledDate}
              format={onlyDateTimeFormat}
              onChange={(date: Moment | null) => {
                setDateSpecificHours({ ...dateSpecificHours, date });
              }}
            />
          </Form.Item>

          {dateSpecificHours.timeRanges.map((range, index) => (
            <div key={index} className={styles.timeRange}>
              <div className={styles.timeRangePicker}>
                <TimePicker.RangePicker
                  format="HH:mm"
                  minuteStep={15}
                  value={[range.from, range.to]}
                  onChange={(value) => handleTimeChange(index, value as any)}
                />
              </div>

              {index > 0 && (
                <Button
                  type="link"
                  danger
                  onClick={() => removeTimeRange(index)}
                >
                  <DeleteOutlined /> Delete
                </Button>
              )}
            </div>
          ))}

          <Button
            type="dashed"
            onClick={addTimeRange}
            className={styles.addTimeRangeBtn}
          >
            <PlusSquareFilled /> Add
          </Button>
        </div>

        <div>
          <Button type="primary" onClick={onFormSubmit}>
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SpecificHoursModal;
