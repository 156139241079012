import React from 'react';
import { useMutation } from '@apollo/client';
import { Space, Switch, Button } from 'antd';
// Api
import { GET_PROMO_CODES } from 'api/promocodes/queries';
import {
  ENABLE_PROMO_CODE,
  DISABLE_PROMO_CODE,
  DEACTIVATE_PROMO_CODE,
} from 'api/promocodes/mutations';
// Types
import {
  EnablePromocode,
  EnablePromocodeVariables,
} from 'api/promocodes/types/EnablePromocode';
import {
  DisablePromocode,
  DisablePromocodeVariables,
} from 'api/promocodes/types/DisablePromocode';
import {
  DeactivatePromocode,
  DeactivatePromocodeVariables,
} from 'api/promocodes/types/DeactivatePromocode';
import {
  GetPromocodesVariables,
  GetPromocodes_getPromocodes_entities,
} from 'api/promocodes/types/GetPromocodes';
// UI
import { errorNotification, successNotification } from 'ui/Notification';

type ActionsProps = {
  promoCode: GetPromocodes_getPromocodes_entities;
  getPromoCodesInput: GetPromocodesVariables;
};

const Actions: React.FC<ActionsProps> = ({ promoCode, getPromoCodesInput }) => {
  const [enablePromocode, { loading: enableIsLoading }] = useMutation<
    EnablePromocode,
    EnablePromocodeVariables
  >(ENABLE_PROMO_CODE);

  const [disablePromocode, { loading: disableIsLoading }] = useMutation<
    DisablePromocode,
    DisablePromocodeVariables
  >(DISABLE_PROMO_CODE);

  const [deactivatePromocode, { loading: deactivateIsLoading }] = useMutation<
    DeactivatePromocode,
    DeactivatePromocodeVariables
  >(DEACTIVATE_PROMO_CODE);

  const changePromoCodeRequestOptions = {
    variables: { id: promoCode.id },
    refetchQueries: [
      {
        query: GET_PROMO_CODES,
        variables: { ...getPromoCodesInput },
      },
    ],
  };

  const changePromoCodeStatus = async (
    request: typeof disablePromocode | typeof enablePromocode
  ) => {
    try {
      await request({ ...changePromoCodeRequestOptions });
      successNotification(
        `Promo code "${promoCode.name}" has been ${
          promoCode?.isActive ? 'disabled' : 'enabled'
        } successfully`
      );
    } catch (err) {
      errorNotification(err?.message);
      console.error(err);
    }
  };

  const onDeactivatePromoCode = async () => {
    try {
      await deactivatePromocode({ ...changePromoCodeRequestOptions });
      successNotification(
        `Promo code "${promoCode.name}" has been deactivated successfully`
      );
    } catch (err) {
      errorNotification(err?.message);
      console.error(err);
    }
  };

  const onSwitchChange = () =>
    promoCode?.isActive
      ? changePromoCodeStatus(disablePromocode)
      : changePromoCodeStatus(enablePromocode);

  return (
    <Space direction="vertical" size="middle" align="center">
      {!promoCode?.isDeactivated && (
        <Switch
          onChange={onSwitchChange}
          checkedChildren="Enabled"
          unCheckedChildren="Disabled"
          defaultChecked={promoCode?.isActive}
          loading={enableIsLoading || disableIsLoading}
        />
      )}

      {!promoCode?.isActive && !promoCode?.isDeactivated && (
        <Button
          onClick={onDeactivatePromoCode}
          loading={deactivateIsLoading}
          type="primary"
          danger
        >
          Deactivate
        </Button>
      )}
    </Space>
  );
};

export default Actions;
