import React from 'react';
import moment, { Moment } from 'moment';
import { Badge, Popover, Tabs, Space } from 'antd';
// Types
import { StreamStatus } from 'api/graphql-global-types';
import { GetUpcomingStreams_adminStreams_entities } from 'api/streams/types/GetUpcomingStreams';
// Components
import StreamsListByStatus from 'pages/StreamsCalendar/components/StreamsListByStatus/StreamsListByStatus';
// Styles
import styles from './DayCell.module.scss';

type DayCellProps = {
  date: Moment;
  streams: GetUpcomingStreams_adminStreams_entities[];
};

const { TabPane } = Tabs;

const NUMBER_OF_VISIBLE_STREAMS = 3;

const DayCell = ({ date, streams }: DayCellProps): JSX.Element | null => {
  const listData: GetUpcomingStreams_adminStreams_entities[] = streams.filter(
    ({ scheduleDate }) => moment(scheduleDate).isSame(date, 'day')
  );

  if (!listData.length) {
    return null;
  }

  const content = (
    <Tabs defaultActiveKey={StreamStatus.Scheduled}>
      <TabPane tab="Scheduled" key={StreamStatus.Scheduled}>
        <StreamsListByStatus
          listData={listData}
          statuses={[StreamStatus.Scheduled]}
        />
      </TabPane>
      <TabPane tab="Active" key={StreamStatus.Active}>
        <StreamsListByStatus
          listData={listData}
          statuses={[
            StreamStatus.Active,
            StreamStatus.Paused,
            StreamStatus.Interrupted,
          ]}
        />
      </TabPane>
      <TabPane tab="Cancelled" key={StreamStatus.Cancelled}>
        <StreamsListByStatus
          listData={listData}
          statuses={[StreamStatus.Cancelled]}
        />
      </TabPane>
      <TabPane tab="Ended" key={StreamStatus.Ended}>
        <StreamsListByStatus
          listData={listData}
          statuses={[StreamStatus.Ended]}
        />
      </TabPane>
    </Tabs>
  );

  return (
    <Popover content={content} title="Streams" trigger="click">
      <Space direction="vertical" size="small" className={styles.root}>
        {listData
          .slice(0, NUMBER_OF_VISIBLE_STREAMS)
          .map(({ id, name, streamStatus }) => (
            <Badge
              key={id}
              status={
                streamStatus === StreamStatus.Cancelled ? 'error' : 'success'
              }
              text={name}
              className={styles.item}
            />
          ))}

        {listData.length > NUMBER_OF_VISIBLE_STREAMS && (
          <span className={styles.cta}>
            +{listData.length - NUMBER_OF_VISIBLE_STREAMS}
          </span>
        )}
      </Space>
    </Popover>
  );
};

export default DayCell;
