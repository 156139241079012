import React from 'react';
import { useMutation } from '@apollo/client';
import { Button, Dropdown, Menu, Modal, Space } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// Api
import { UPDATE_ARTICLE } from 'api/articles/mutations';
import { GET_ARTICLES } from 'api/articles/queries';
// Types
import { GetArticles_getArticles_entities } from 'api/articles/types/GetArticles';
import {
  UpdateArticle,
  UpdateArticleVariables,
} from 'api/articles/types/UpdateArticle';
import { ArticleStatus } from 'api/graphql-global-types';
// UI
import { errorNotification, successNotification } from 'ui/Notification';

const { confirm } = Modal;

type ArticleActionsProps = {
  record: GetArticles_getArticles_entities;
  pageSize: number;
  onOpenEditSponsoredArticleModal: (
    record: GetArticles_getArticles_entities
  ) => void;
};

const ArticleActions = ({
  record,
  pageSize,
  onOpenEditSponsoredArticleModal,
}: ArticleActionsProps): JSX.Element | null => {
  const [updateArticle] = useMutation<UpdateArticle, UpdateArticleVariables>(
    UPDATE_ARTICLE,
    {
      // refetch the articles for the table with the correct page once we update the status
      refetchQueries: [
        {
          query: GET_ARTICLES,
          variables: {
            input: {
              limit: pageSize,
            },
          },
        },
      ],
    }
  );

  const updateSponsoredArticleStatus = async (
    id: string,
    status: ArticleStatus
  ) => {
    try {
      await updateArticle({
        variables: {
          input: {
            id,
            status,
          },
        },
      });
      const newStatus =
        status === ArticleStatus.Active ? 'Published' : 'Deactivated';
      successNotification(
        `Article status successfully changed to ${newStatus}`
      );
    } catch (error) {
      errorNotification((error as Error)?.message || 'Something went wrong');
    }
  };

  const editArticle = () => {
    onOpenEditSponsoredArticleModal(record);
  };

  const handlePublishArticle = () => {
    updateSponsoredArticleStatus(record.id, ArticleStatus.Active);
  };

  // trigger confirmation modal if publish button is clicked
  const onPublishArticle = () => {
    confirm({
      title: 'Are you sure you want to publish this article?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      onOk: handlePublishArticle,
    });
  };

  const handleDeactivateArticle = () => {
    updateSponsoredArticleStatus(record.id, ArticleStatus.Disabled);
  };

  // trigger confirmation modal if deactivate button is clicked
  const onDeactivateArticle = () => {
    confirm({
      title: 'Are you sure you want to deactivate this article?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: handleDeactivateArticle,
    });
  };

  const isPublished = record.status === ArticleStatus.Active;

  const menu = (
    <Menu>
      <Menu.Item key={record.id}>
        <Space direction="vertical" size="middle" align="center">
          <Button type="ghost" onClick={() => editArticle()}>
            Edit Article
          </Button>
          {isPublished ? (
            <Button type="primary" danger onClick={onDeactivateArticle}>
              Deactivate Article
            </Button>
          ) : (
            <Button type="primary" onClick={onPublishArticle}>
              Activate Article
            </Button>
          )}
        </Space>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown trigger={['click']} placement="bottomCenter" overlay={menu}>
      <Button
        aria-label="show the available actions"
        icon={<EllipsisOutlined />}
      />
    </Dropdown>
  );
};

export default ArticleActions;
