import React, { useEffect, useState } from 'react';
import { Spin, Button, Typography, Tooltip } from 'antd';
import { useMutation } from '@apollo/client';
// Api
import {
  SEND_MERCH_DESIGN_TO_REVIEW,
  CREATE_PRESIGNED_URL_FOR_SOCIAL_PACK_REQUEST,
} from 'api/designLab/mutations';
import { DESIGN_REQUESTS } from 'api/designLab/queries';
// Types
import { DesignRequestsFormattedData } from 'helpers/designRequests';
import {
  SendMerchDesignToReview,
  SendMerchDesignToReviewVariables,
} from 'api/designLab/types/SendMerchDesignToReview';
import {
  CreatePresignedUrlForSocialPack,
  CreatePresignedUrlForSocialPackVariables,
} from 'api/designLab/types/CreatePresignedUrlForSocialPack';
// Helpers
import { uploadToS3 } from 'helpers/createPresignedUrl';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import MerchItem from 'components/common/ManageMerch/AllMerch/MerchItem/MerchItem';
import SocialMediaPackItem, {
  SocialMediaPackItemType,
  FileWithUid,
  SocialMediaPackDataType,
  emptySocialMediaItem,
} from '../../../SocialMediaPackItem/SocialMediaPackItem';
// Styles
import styles from './MerchDesignModal.module.scss';

type MerchDesignModalProps = {
  request: DesignRequestsFormattedData | null;
  onClose: () => void;
};
const { Title } = Typography;

const MerchDesignModal = ({
  request,
  onClose,
}: MerchDesignModalProps): JSX.Element | null => {
  const [sendDesignToAdminReview, { loading: sendDesignLoading }] = useMutation<
    SendMerchDesignToReview,
    SendMerchDesignToReviewVariables
  >(SEND_MERCH_DESIGN_TO_REVIEW);

  const [createPresignedUrls, { loading: uploadingImages }] = useMutation<
    CreatePresignedUrlForSocialPack,
    CreatePresignedUrlForSocialPackVariables
  >(CREATE_PRESIGNED_URL_FOR_SOCIAL_PACK_REQUEST);

  const [socialMediaPackItems, setSocialMediaPackItems] = useState<
    SocialMediaPackItemType[]
  >([]);

  const socialMediaPackImages = request?.socialMediaPackImages;

  useEffect(() => {
    setSocialMediaPackItems(
      socialMediaPackImages?.map((item) => {
        return {
          isValid: true,
          label: item.label || '',
          image: undefined,
          imageFileKey: item.imageFileKey || '',
          imgURL: item.imgURL || item.socialMediaPackImageUrl || '',
        };
      }) || []
    );
  }, [socialMediaPackImages]);

  const [s3Loader, setS3Loader] = useState<boolean>(false);

  const allImagesForUpload: FileWithUid[] = []; // new images
  const socialMediaPackImagesIndex: number[] = []; // where new social media images should be added
  const socialMediaPackImageUrls: SocialMediaPackDataType[] = []; // array that will contain social media images (old and new)

  const addNewSocialMediaPack = () => {
    setSocialMediaPackItems([...socialMediaPackItems, emptySocialMediaItem]);
  };

  const clearSocialMediaPack = () => {
    setSocialMediaPackItems([]);
  };

  const updateSocialMediaPackItems = (
    value: SocialMediaPackItemType,
    valueIndex: number
  ) => {
    setSocialMediaPackItems((prevData) =>
      prevData.map((currentItem, index) => {
        if (index === valueIndex) {
          return value;
        }
        return currentItem;
      })
    );
  };

  const deleteSocialMediaItem = (index: number) => {
    const newSocialMediaPackItems = [...socialMediaPackItems];
    newSocialMediaPackItems.splice(index, 1);
    setSocialMediaPackItems(newSocialMediaPackItems);
  };

  const uploadDesign = async (uploadData: SocialMediaPackDataType[]) => {
    try {
      await sendDesignToAdminReview({
        variables: {
          input: {
            designRequestId: request?.id as string,
            socialMediaPackImages: uploadData,
          },
        },
        refetchQueries: [
          {
            query: DESIGN_REQUESTS,
            variables: { input: {} },
          },
        ],
      });
      successNotification(
        `Upload for request #${request?.id} has been finished successfully.`
      );
      onClose();
    } catch (error) {
      errorNotification((error as Error)?.message);
    }
  };

  const handleSubmitMerchDesign = async () => {
    for (let counter = 0; counter < socialMediaPackItems.length; counter++) {
      socialMediaPackImageUrls.push({
        imageFileKey: socialMediaPackItems[counter].imageFileKey || '',
        label: socialMediaPackItems[counter].label,
        imgURL: socialMediaPackItems[counter].imgURL || '',
      });
      const image = socialMediaPackItems[counter].image;
      if (image) {
        allImagesForUpload.push(image);
        socialMediaPackImagesIndex.push(counter);
      }
    }

    if (allImagesForUpload.length) {
      try {
        const { data } = await createPresignedUrls({
          variables: {
            input: {
              designRequestId: request?.id || '',
              numberOfImages: allImagesForUpload.length,
            },
          },
        });

        if (data?.createPresignedUrlForSocialPack.length) {
          try {
            setS3Loader(true);
            const uploadData = await uploadToS3(
              data.createPresignedUrlForSocialPack,
              allImagesForUpload,
              socialMediaPackImageUrls,
              socialMediaPackImagesIndex
            );
            setS3Loader(false);
            uploadDesign(uploadData);
          } catch (error) {
            errorNotification((error as Error)?.message);
            setS3Loader(false);
          }
        }
      } catch (error) {
        errorNotification((error as Error)?.message);
      }
    } else {
      uploadDesign(socialMediaPackImageUrls);
    }
  };

  const buttonLoading: boolean = uploadingImages || s3Loader;

  const merchItems = request?.designCreationDetails?.merchItems || [];
  const printfulImageIsReady = merchItems.every(
    ({ merch }) => merch?.productImageURL !== null
  );

  const store = request?.store;

  return (
    <div>
      <Title>Are you sure you want to submit this design?</Title>
      <div className={styles.designAndSocialWrapper}>
        <div className={styles.designWrapper}>
          <div className={styles.merchCardWrapper}>
            {merchItems.map(
              ({ merch }) =>
                merch && (
                  <MerchItem
                    key={merch?.id}
                    designRequestId={request?.id || ''}
                    product={merch}
                    isMerchDesignReview
                    store={store}
                  />
                )
            )}
          </div>

          <div className={styles.submitButtonWrapper}>
            {sendDesignLoading ? (
              <Spin size="large" />
            ) : (
              <Tooltip
                title={
                  !printfulImageIsReady
                    ? 'Please wait for images to upload'
                    : ''
                }
              >
                <Button
                  onClick={handleSubmitMerchDesign}
                  type="primary"
                  htmlType="submit"
                  className="createEditFormSubmit"
                  disabled={sendDesignLoading || !printfulImageIsReady}
                  loading={!printfulImageIsReady}
                >
                  Submit
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
        <div className={styles.socialMediaPackWrapper}>
          {socialMediaPackItems.map(
            (socialMediaItem: SocialMediaPackItemType, index: number) => {
              return (
                <SocialMediaPackItem
                  key={socialMediaItem.label + '_social_media_item_' + index}
                  index={index}
                  socialMediaItem={socialMediaItem}
                  setValue={updateSocialMediaPackItems}
                  deleteValue={deleteSocialMediaItem}
                />
              );
            }
          )}
          <Button onClick={addNewSocialMediaPack}>
            <span>Add New Social Media Image</span>
          </Button>

          {socialMediaPackItems.length ? (
            <Button onClick={clearSocialMediaPack} loading={buttonLoading}>
              <span>Clear All Social Media Images</span>
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MerchDesignModal;
