import React, { useEffect, useState, useRef } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import cx from 'classnames';
// Types
import { GetUnregisteredContact_getUnregisteredContact_entities } from 'api/mentions/types/GetUnregisteredContact';
import { ContactType, TargetType } from 'api/graphql-global-types';
import { GetStore_store } from 'api/store/types/GetStore';
import {
  GetMediaPost_getMediaPost_ImagePost_mentions,
  GetMediaPost_getMediaPost_VideoPost_mentions,
} from 'api/mediaPost/types/GetMediaPost';
// Components
import MillionsMentionsSelect from './components/MillionsMentionsSelect/MilionsMentionsSelect';
import OutsideMillionsAthletesSelect from './components/OutsideMillionsAthletesSelect/OutsideMillionsAthletesSelect';
import OutsideMillionsBrandsSelect from './components/OutsideMillionsBrandsSelect/OutsideMillionsBrandsSelect';
// Styles
import styles from './Mentions.module.scss';

export type MillionsMention = {
  label: string | null;
  value: string | null;
  avatarURL: string | null;
};

interface User {
  id: string;
  name: string;
  avatarUrl: string;
}

export type Mention = {
  id: string | null;
  targetType: TargetType | null;
  user: User | null;
  unregisteredContact: GetUnregisteredContact_getUnregisteredContact_entities | null;
};

export interface CustomMention {
  name: string;
  email: string;
  phoneNumberE164: string;
  type: ContactType;
  targetType: string;
  store?: GetStore_store | null;
  unregisteredContact?: GetUnregisteredContact_getUnregisteredContact_entities | null;
}

export type MentionValues =
  | GetMediaPost_getMediaPost_VideoPost_mentions
  | GetMediaPost_getMediaPost_ImagePost_mentions
  | CustomMention;

type MentionsProps = {
  mentions: any[];
  onChange: (value: Mention[]) => void;
  disabled?: boolean;
};

export const Mentions = ({
  mentions,
  disabled,
  onChange,
}: MentionsProps): JSX.Element => {
  const [millionsMentions, setMillionsMentions] = useState<Mention[]>([]);
  const [unregisteredAthletesMentions, setUnregisteredAthletesMentions] =
    useState<Mention[]>([]);
  const [unregisteredBrandsMention, setUnregisteredBrandsMention] = useState<
    Mention[]
  >([]);
  const prevMentionsRef = useRef<Mention[]>([]); // To track previous mentions value

  useEffect(() => {
    const registeredUsers =
      mentions?.filter(
        (mention) => mention.targetType === TargetType.REGISTERED
      ) || [];
    const unregisteredUsers =
      mentions?.filter(
        (mention) => mention.targetType === TargetType.UNREGISTERED
      ) || [];

    setMillionsMentions(registeredUsers);
    const unregisteredAthletes = unregisteredUsers?.filter(
      (mention) => mention?.unregisteredContact?.type === ContactType.ATHLETE
    );
    const unregisteredBrands = unregisteredUsers?.filter(
      (mention) => mention?.unregisteredContact?.type === ContactType.BRAND
    );
    setUnregisteredAthletesMentions(unregisteredAthletes);
    setUnregisteredBrandsMention(unregisteredBrands);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentMentions = [
      ...millionsMentions,
      ...unregisteredAthletesMentions,
      ...unregisteredBrandsMention,
    ];

    const prevMentions = prevMentionsRef.current;

    // Only trigger onChange if the new mentions differ from the previous mentions
    if (JSON.stringify(currentMentions) !== JSON.stringify(prevMentions)) {
      // Use setTimeout to defer the onChange call until after rendering
      setTimeout(() => {
        onChange(currentMentions);
        prevMentionsRef.current = currentMentions; // Update previous mentions to the latest value
      }, 0);
    }
  }, [
    millionsMentions,
    unregisteredAthletesMentions,
    unregisteredBrandsMention,
    onChange,
  ]);

  const handleMillionsMentionChange = (mention: Mention, index: number) => {
    setMillionsMentions((prevMentions) => {
      const updatedMentions = [...prevMentions];
      updatedMentions[index] = mention;
      return updatedMentions;
    });
  };

  const handleUnregisteredAthleteMentionChange = (mentions: Mention[]) =>
    setUnregisteredAthletesMentions(mentions);

  const handleUnregisteredBrandsMentionChange = (mentions: Mention[]) =>
    setUnregisteredBrandsMention(mentions);

  const addNewMillionsMention = () => {
    setMillionsMentions((prevMentions) => [
      ...prevMentions,
      {
        id: null,
        user: null,
        store: {
          storeDetails: {
            storeName: null,
            avatarURL: null,
          },
        },
        mentionType: 'MEDIA_POST',
        targetType: TargetType.REGISTERED,
        unregisteredContact: null,
      },
    ]);
  };

  const handleMillionsMentionRemove = (index: number) => {
    setMillionsMentions((prevMentions) => {
      const updatedMentions = [...prevMentions];
      updatedMentions.splice(index, 1);
      return updatedMentions;
    });
  };

  return (
    <div className={cx(styles.root, { [styles.disabled]: disabled })}>
      <div className={styles.millionsMentionsSection}>
        <label className={styles.millionsLabel}>{`MILLION'S Mention`}</label>

        {millionsMentions?.length === 0 ? (
          <MillionsMentionsSelect
            mention={null}
            onChange={handleMillionsMentionChange}
            index={0}
            handleMillionsMentionRemove={handleMillionsMentionRemove}
          />
        ) : (
          millionsMentions.map((item, index) => (
            <div key={index} className={styles.selectContainer}>
              <MillionsMentionsSelect
                mention={item}
                index={index}
                onChange={handleMillionsMentionChange}
                handleMillionsMentionRemove={handleMillionsMentionRemove}
              />
            </div>
          ))
        )}

        {millionsMentions.length < 5 && (
          <div className={styles.addNewMentionContainer}>
            <Button
              className={styles.addNewMentionButton}
              onClick={addNewMillionsMention}
            >
              <PlusCircleOutlined
                className={styles.addMentionIcon}
                title="Add new mention"
              />
              <p className={styles.addNewMentionTitle}>Add new mention</p>
            </Button>
          </div>
        )}
      </div>

      <OutsideMillionsAthletesSelect
        mentions={unregisteredAthletesMentions}
        onChange={handleUnregisteredAthleteMentionChange}
      />

      <OutsideMillionsBrandsSelect
        mentions={unregisteredBrandsMention}
        onChange={handleUnregisteredBrandsMentionChange}
      />
    </div>
  );
};

export default Mentions;
