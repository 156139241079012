import React from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';

import './styles.scss';

interface Props {
  title: string;
}

const Tip: React.FC<Props> = ({ title }) => (
  <sup className="tip-wrapper">
    <Tooltip title={title}>
      <QuestionCircleTwoTone />
    </Tooltip>
  </sup>
);

export default Tip;
