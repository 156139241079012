import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Modal, Input, Form, Switch, Select, Typography } from 'antd';
// Api
import { CREATE_ADDRESS } from 'api/users/mutations';
import { GET_COUNTRY_LIST } from 'api/users/queries';
import { GET_STORE_INFO } from 'api/store/queries';
// Types
import {
  CreateAddress,
  CreateAddressVariables,
} from 'api/users/types/CreateAddress';
import { GetStore, GetStoreVariables } from 'api/store/types/GetStore';
import {
  GetCountryList,
  GetCountryList_getCountryList,
} from 'api/users/types/GetCountryList';
import Tip from 'ui/Tip';
// UI
import { successNotification, errorNotification } from 'ui/Notification';
// Helpers
import { validationRules } from 'pages/ManageAccount/helpers';

const { Text } = Typography;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 12 },
};

type CreateAddressModalProps = {
  open: boolean;
  onClose: () => void;
  onComplete?: () => void;
  userId?: string;
};

const CreateAddressModal = ({
  open,
  onClose,
  onComplete,
  userId,
}: CreateAddressModalProps): JSX.Element => {
  // eslint-disable-next-line
  const [formFields, setFormFields] = useState<boolean>(false);
  const [countryState, setCountry] = useState<
    GetCountryList_getCountryList | null | undefined
  >(null);

  const { data: countriesList } = useQuery<GetCountryList>(GET_COUNTRY_LIST, {
    fetchPolicy: 'cache-and-network',
  });

  const { data } = useQuery<GetStore, GetStoreVariables>(GET_STORE_INFO, {
    variables: {
      input: {
        id: userId,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !userId,
  });

  const [createAddressRequest, { loading: isCreatingInProgress }] = useMutation<
    CreateAddress,
    CreateAddressVariables
  >(CREATE_ADDRESS);

  const currentStore = data?.store;

  const initialFormValues = {
    isDefault: true,
    company: 'millions',
    email: currentStore?.email || '',
    phone: currentStore?.phoneNumber || '',
  };

  const [form] = Form.useForm();

  const handleAddress = async () => {
    try {
      const [country, countryCode] = form.getFieldValue('country').split(',');
      const addressLine2 = form.getFieldValue('addressLine2') || '';

      const [state = null, stateCode = null] =
        form.getFieldValue('state')?.split(',') || [];

      const values = {
        ...form.getFieldsValue(),
        country,
        state,
        countryCode,
        stateCode,
        userId,
        addressLine2,
        // hardcoded value for now
        company: 'millions',
      };
      await createAddressRequest({ variables: { input: values } });

      successNotification('Address successfully created');
      onClose();

      if (onComplete) {
        onComplete();
      }
    } catch (e: any) {
      errorNotification(e?.message || 'Something went wrong');
    }
  };

  const getIfSubmitIsDisabled = (): boolean => {
    return Boolean(
      Object.values(form.getFieldsValue()).some(
        (field) => field === null || field === ''
      ) || form.getFieldsError()?.filter(({ errors }) => errors?.length)?.length
    );
  };

  const onFormValuesChange = () => {
    setFormFields({ ...form.getFieldsValue() });
  };

  const onCountryChange = (country: string) => {
    setCountry(
      countriesList?.getCountryList?.find(
        (i) => i.name === country?.split(',')[0]
      )
    );
  };

  return (
    <Modal
      title="Address creating"
      visible={open}
      onCancel={onClose}
      confirmLoading={isCreatingInProgress}
      onOk={handleAddress}
      okButtonProps={{ disabled: getIfSubmitIsDisabled() }}
    >
      <Form
        form={form}
        layout="horizontal"
        name="setupAddress"
        autoComplete="off"
        initialValues={initialFormValues}
        onValuesChange={onFormValuesChange}
        {...formItemLayout}
      >
        <Form.Item name="id" hidden>
          <Input type="text" />
        </Form.Item>

        <Form.Item
          name="email"
          label={<Text>Email</Text>}
          rules={validationRules.email}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="phone"
          label={<Text>Phone Number</Text>}
          rules={validationRules.phoneNumber}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="firstName"
          label={<Text>First Name</Text>}
          rules={validationRules.nameOrSurname}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastName"
          label={<Text>Last Name</Text>}
          rules={validationRules.nameOrSurname}
        >
          <Input />
        </Form.Item>

        <Form.Item name="company" label={<Text>Company</Text>}>
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="country"
          label={<Text>Country</Text>}
          rules={[
            {
              required: true,
              message: 'Please enter your Country',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a country"
            optionFilterProp="children"
            onChange={onCountryChange}
          >
            {countriesList?.getCountryList?.map(({ code, name }) => {
              return (
                <Option key={code} value={`${name},${code}`}>
                  {name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        {countryState?.states?.length && (
          <Form.Item
            name="state"
            label={<Text>State</Text>}
            rules={[
              {
                required: true,
                message: 'Please enter your State',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select your state"
              optionFilterProp="children"
            >
              {countryState?.states?.map(({ code, name }, index) => {
                return (
                  <Option key={code || index} value={`${name},${code}`}>
                    {name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name="city"
          label={<Text>City</Text>}
          rules={validationRules.city}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="zipCode"
          label={<Text>ZIP</Text>}
          rules={[
            {
              required: true,
              message: 'Please enter your ZIP',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="addressLine1"
          label={<Text>Address Line 1</Text>}
          rules={validationRules.addressLine}
        >
          <Input />
        </Form.Item>

        <Form.Item name="addressLine2" label={<Text>Address Line 2</Text>}>
          <Input />
        </Form.Item>

        <Form.Item
          name="isDefault"
          label={
            <Text>
              Default status
              <Tip title="Enable it to make the address to be default" />
            </Text>
          }
        >
          <Switch
            checkedChildren="Enabled"
            unCheckedChildren="Disabled"
            checked={form.getFieldValue('status')}
            defaultChecked
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateAddressModal;
