import {
  useQuery,
  QueryResult,
  QueryOptions,
  WatchQueryFetchPolicy,
  QueryFunctionOptions,
} from '@apollo/client';
// Api
import { GET_TEAMS_LIST } from 'api/store/queries';
// Types
import { GetTeamsList } from 'api/store/types/GetTeamsList';

type BasicQueryOptions = Omit<
  QueryOptions,
  'query' | 'variables' | 'fetchPolicy'
> & {
  fetchPolicy?: WatchQueryFetchPolicy;
} & QueryFunctionOptions;

type UseGetAthleteMerchProductsOptions = {
  options?: BasicQueryOptions;
};

export const useGetTeams = (
  options?: UseGetAthleteMerchProductsOptions
): QueryResult<GetTeamsList> => {
  const teams = useQuery<GetTeamsList>(GET_TEAMS_LIST, {
    ...options?.options,
  });

  return teams;
};
