import { gql } from '@apollo/client';

export const GET_HOME_PAGE_DATA = gql`
  query GetHomePageData {
    getHomePageData {
      stores {
        id
        orderIndex
        role
        firstName
        lastName
        email
        storeDetails {
          id
          storeName
        }
      }
      amas {
        id
        orderIndex
        store {
          id
          role
          firstName
          lastName
          email
          storeDetails {
            id
            storeName
          }
        }
      }
      merch {
        id
        orderIndex
        title
        store {
          id
          role
          firstName
          lastName
          email
          storeDetails {
            id
            storeName
          }
        }
      }
      watchStreams {
        id
        orderIndex
        name
        scheduleDate
        timeZone {
          tzCode
          offset
        }
        store {
          id
          role
          firstName
          lastName
          email
          storeDetails {
            id
            storeName
          }
        }
      }
      mainBanners {
        id
        image {
          id
          url
        }
        video {
          id
          url
        }
        title
        content
        link
        buttonContent
        shouldLinkRedirect
        orderIndex
      }
      ctaBanners {
        id
        image {
          id
          url
        }
        title
        content
        link
        buttonContent
        shouldLinkRedirect
        isBannerVisible
        linkContent
        buttonLink
        orderIndex
      }
      athleteStories {
        id
        title
        description
        imageURL
        link
        publishedDate
        orderIndex
        store {
          id
          slug
          firstName
          lastName
          storeDetails {
            id
            storeName
          }
        }
      }
    }
  }
`;

export const GET_ADMIN_GALLERY_IMAGES = gql`
  query AdminGalleryImages($input: AdminGetGalleryImagesInput!) {
    adminGalleryImages(input: $input) {
      entities {
        id
        key
        name
        url
      }
      total
      limit
      offset
    }
  }
`;

export const GET_ADMIN_GALLERY_VIDEOS = gql`
  query AdminGalleryVideos($input: AdminGetGalleryVideosInput!) {
    adminGalleryVideos(input: $input) {
      entities {
        id
        key
        name
        url
      }
      total
      limit
      offset
    }
  }
`;
