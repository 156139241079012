import { useLocation } from 'react-router-dom';
// Types
import { STORE_TYPE } from 'constants/global';

type UserTypeFromRoute = {
  isAthlete: boolean;
  isCustomer: boolean;
  isOrganization: boolean;
  isContentCreator: boolean;
};

export const useGetUserTypeFromRoute = (): UserTypeFromRoute => {
  const { pathname } = useLocation();

  const isAthlete = pathname.includes(`${STORE_TYPE.ATHLETE}`);
  const isCustomer = pathname.includes('customer');
  const isOrganization = pathname.includes(`${STORE_TYPE.ORGANIZATION}`);
  const isContentCreator = pathname.includes('content-creator');

  return { isAthlete, isCustomer, isOrganization, isContentCreator };
};
