import { useEffect } from 'react';
// Config
import ENV from 'api/env';
// Helpers
import { getAuthUserFromCookies } from 'helpers/cookies';
// Context
import { useAppContext } from 'hooks';
import { UserRole } from 'api/graphql-global-types';

const CookieTokenWatcher = (): null => {
  const { authUser, setAuthUser, clearAuthUser } = useAppContext();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const authUserFromCookies = getAuthUserFromCookies();

      if (
        authUser &&
        ![
          UserRole.Admin,
          UserRole.Moderator,
          UserRole.Designer,
          UserRole.VideoEditor,
        ].includes(authUser.role)
      ) {
        if (!authUser?.isInterviewer) {
          window.location.replace(ENV.REACT_APP_WEB_PORTAL_URL);
        }
      }

      if (!authUser && authUserFromCookies) {
        if (
          [
            UserRole.Admin,
            UserRole.Moderator,
            UserRole.Designer,
            UserRole.VideoEditor,
          ].includes(authUserFromCookies.role) ||
          authUserFromCookies.isInterviewer
        ) {
          setAuthUser(authUserFromCookies);
        } else {
          window.location.replace(ENV.REACT_APP_WEB_PORTAL_URL);
        }
      }

      if (!authUserFromCookies && authUser) {
        clearAuthUser();
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, [authUser, setAuthUser, clearAuthUser]);

  return null;
};

export default CookieTokenWatcher;
