import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Modal, Input, Form, Switch, Select, Typography } from 'antd';
// Api
import { GET_COUNTRY_LIST } from 'api/users/queries';
import { UPDATE_ADDRESS } from 'api/users/mutations';
// Types
import { ApolloQueryResult } from '@apollo/client';
import {
  GetCountryList,
  GetCountryList_getCountryList,
} from 'api/users/types/GetCountryList';
import { Users, UsersVariables } from 'api/users/types/Users';
import {
  UpdateAddress,
  UpdateAddressVariables,
} from 'api/users/types/UpdateAddress';
import { UserAddressRequestFields } from 'api/users/types/UserAddressRequestFields';
// UI
import { successNotification, errorNotification } from 'ui/Notification';
import Tip from 'ui/Tip';
// helpers
import { validationRules } from 'pages/ManageAccount/helpers';

const { Text } = Typography;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 12 },
};

type EditAddressModalProps = {
  addressToEdit: UserAddressRequestFields | undefined;
  isAddressEditing: boolean;
  toggleAddressEditing: React.Dispatch<React.SetStateAction<boolean>>;
  refetchUser: (
    variables?: Partial<UsersVariables> | undefined
  ) => Promise<ApolloQueryResult<Users>>;
};

const EditAddressModal: React.FC<EditAddressModalProps> = ({
  addressToEdit,
  isAddressEditing,
  toggleAddressEditing,
  refetchUser,
}) => {
  const [updateAddress, { loading: isUpdatingInProgress }] = useMutation<
    UpdateAddress,
    UpdateAddressVariables
  >(UPDATE_ADDRESS, {
    onCompleted: () => {
      refetchUser();
      toggleAddressEditing(false);
    },
  });
  const { data: countriesList } = useQuery<GetCountryList>(GET_COUNTRY_LIST, {
    fetchPolicy: 'cache-and-network',
  });

  const [countryState, setCountry] = useState<
    GetCountryList_getCountryList | null | undefined
  >(null);

  const getInitialValues = () => {
    const rawUserData = addressToEdit;
    return {
      ...rawUserData,
      country: `${rawUserData?.country},${rawUserData?.countryCode}`,
      state: rawUserData?.state
        ? `${rawUserData?.state},${rawUserData?.stateCode}`
        : null,
    };
  };
  const [form] = Form.useForm();

  const handleAddress = async () => {
    try {
      const [country, countryCode] = form.getFieldValue('country').split(',');
      const [state = null, stateCode = null] =
        form.getFieldValue('state')?.split(',') || [];
      const values = {
        ...form.getFieldsValue(),
        country,
        state,
        countryCode,
        stateCode,
      };
      await updateAddress({ variables: { input: values } });
      successNotification('The address is updated');
    } catch (e) {
      if (
        e?.graphQLErrors?.[0]?.extensions?.exception?.response?.message?.[0] ===
        'Invalid postcode specified for provided country'
      ) {
        errorNotification('Invalid postcode specified for provided country');
        console.log(
          'createAddress error - Invalid postcode specified for provided country'
        );
      } else {
        errorNotification();
        console.log('createAddress error - ', { ...e });
      }
    }
  };
  // eslint-disable-next-line
    const [formFields, setFormFields] = useState<boolean>(false);

  const getIfSubmitIsDisabled = (): boolean => {
    return Boolean(
      Object.values(form.getFieldsValue()).some(
        (field) => field === null || field === ''
      ) || form.getFieldsError()?.filter(({ errors }) => errors?.length)?.length
    );
  };

  return (
    <Modal
      title="Address editing"
      visible={isAddressEditing}
      onCancel={() => toggleAddressEditing(false)}
      onOk={handleAddress}
      okButtonProps={{ disabled: getIfSubmitIsDisabled() }}
      confirmLoading={isUpdatingInProgress}
    >
      <Form
        form={form}
        layout="horizontal"
        name="setupAddress"
        autoComplete="off"
        initialValues={getInitialValues()}
        onValuesChange={() => {
          const fields = form.getFieldsValue();
          setFormFields({
            ...fields,
          });
        }}
        {...formItemLayout}
      >
        <Form.Item name="id" hidden>
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="firstName"
          label={<Text>First Name</Text>}
          rules={validationRules.nameOrSurname}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label={<Text>Last Name</Text>}
          rules={validationRules.nameOrSurname}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="country"
          label={<Text>Country</Text>}
          rules={[
            {
              required: true,
              message: 'Please enter your Country',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a country"
            optionFilterProp="children"
            onChange={(value: string) => {
              setCountry(
                countriesList?.getCountryList?.find(
                  (country) => country.name === value?.split(',')[0]
                )
              );
            }}
          >
            {countriesList?.getCountryList?.map(({ code, name }) => {
              return (
                <Option key={code} value={`${name},${code}`}>
                  {name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        {countryState?.states?.length && (
          <Form.Item
            name="state"
            label={<Text>State</Text>}
            rules={[
              {
                message: 'Please enter your State',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select your state"
              optionFilterProp="children"
            >
              {countryState?.states?.map(({ code, name }, index) => {
                return (
                  <Option key={code || index} value={`${name},${code}`}>
                    {name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="city"
          label={<Text>City</Text>}
          rules={validationRules.city}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="zipCode"
          label={<Text>ZIP</Text>}
          rules={[
            {
              required: true,
              message: 'Please enter your ZIP',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="addressLine1"
          label={<Text>Address Line 1</Text>}
          rules={validationRules.addressLine}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="addressLine2"
          label={<Text>Address Line 2</Text>}
          rules={validationRules.addressLine}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="isDefault"
          label={
            <Text>
              Default status
              <Tip title="Enable it to make the address to be default" />
            </Text>
          }
        >
          <Switch
            checkedChildren="Enabled"
            unCheckedChildren="Disabled"
            checked={form.getFieldValue('status')}
            defaultChecked
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditAddressModal;
