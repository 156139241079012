import { gql } from '@apollo/client';

export const ADMIN_GET_REDIRECTS = gql`
  query AdminGetRedirects($input: GetRedirectsInput!) {
    adminGetRedirects(input: $input) {
      entities {
        id
        from
        to
      }
      total
      offset
      limit
    }
  }
`;
