import { gql } from '@apollo/client';

export const GET_STORES = gql`
  query GetStores($storeRoles: [UserRole!], $input: AdminStoresFilterInput!) {
    adminStores(storeRoles: $storeRoles, input: $input) {
      entities {
        id
        role
        firstName
        lastName
        email
        signUpSourceStream {
          id
          name
        }
        storeDetails {
          id
          storeName
          avatarURL
        }
        slug
      }
      total
      offset
      limit
    }
  }
`;

export const GET_ADMIN_STORES = gql`
  query GetAdminStores(
    $storeRoles: [UserRole!]
    $input: AdminStoresFilterInput!
  ) {
    adminStores(storeRoles: $storeRoles, input: $input) {
      entities {
        hasOpenDesignRequest
        activatedAt
        isOnboarded
        id
        firstName
        lastName
        createdAt
        scheduledInterviewCnt
        email
        followerCount
        phoneNumber
        isAptForSms
        isValidPhoneNumber
        hasAma
        hasStreams
        hasMerch
        hasProducts
        hasExperiences
        hasConnectedAccount
        interviewerDetails {
          id
          isIncludedInAutoAssignment
          sports {
            id
            name
          }
        }
        storeManagementDetails {
          id
          initialAssessment
          latestAssessment
          socialConnected
          designRequestsPerMonthLimit
          managedBy {
            firstName
            lastName
            email
          }
        }
        sports {
          id
          name
        }
        signUpSourceStream {
          id
          name
        }
        storeDetails {
          id
          youtubeLink
          facebookLink
          instagramLink
          tiktokLink
          twitterLink
          website
          storeName
          gymRole
          socialMediaLink
          socialFollowing
          country
          state
          city
          storeName
          avatarURL
        }
        purchaseStats {
          storeRevenue
          customerRevenue
          customerTotalCount
        }
        verificationVideo {
          id
          url
        }
        allAmas {
          id
          type
          videoURL
        }
        status
      }
      total
      offset
      limit
    }
  }
`;

export const GET_STORE_INFO = gql`
  query GetStore($input: StoreGetInput!) {
    store(input: $input) {
      id
      lastName
      firstName
      status
      email
      phoneNumber
      isAptForSms
      isValidPhoneNumber
      activatedAt
      isOrphanPage
      areasOfInfluence {
        id
        name
      }
      psychosocialKeywords {
        id
        name
      }
      storeManagementDetails {
        adConnected
        agencyName
        agencyWebsite
        agentEmail
        agentName
        id
        inbound
        initialAssessment
        latestAssessment
        designRequestsPerMonthLimit
        liveDate
        managedBy {
          email
        }
        managedById
        socialConnected
      }
      hashtags {
        id
        name
      }
      sports {
        id
        name
      }
      storeDetails {
        team {
          id
          name
          sport
        }
        league {
          id
          name
          sport
        }
        college {
          id
          name
        }
        storeId
        careerStatus
        hometown
        favoriteNumber
        birthDate
        isBirthMonthKnown
        isBirthDayKnown
        socialMediaLink
        facebookLink
        instagramLink
        tiktokLink
        twitterLink
        website
        youtubeLink
        facebookFollowing
        gender
        instagramFollowing
        tiktokFollowing
        twitterFollowing
        youtubeFollowing
        id
        bio
        avatarURL
        coverURL
        storeName
        description
        country
        state
        city
        gymRole
        website
        organizationType
      }
      redirectSlugs
    }
  }
`;

export const GET_STORE_BRAND_DEAL_INFO = gql`
  query GetStoreBrandDeal($input: StoreGetInput!) {
    store(input: $input) {
      brandDealSettings {
        id
        contactEmail
        contactPhone
        minFlatPrice
        industryCategories {
          isEnabled
          label
          value
        }
        isAcceptingFreeProducts
        isArticlesPrEnabled
        minBookMePrice
        minSocialPrice
        minSponsoredLivePrice
      }
    }
  }
`;

export const GET_STORE_INTERVIEWER = gql`
  query GetStoreInterviewer($input: StoreGetInput!) {
    store(input: $input) {
      id
      lastName
      firstName
      email
      interviewerDetails {
        id
        isIncludedInAutoAssignment
      }
    }
  }
`;

export const MANAGE_BIO_GET_STORE = gql`
  query GetManageBioStore(
    $storeRoles: [UserRole!]
    $input: AdminStoresFilterInput!
  ) {
    adminStores(storeRoles: $storeRoles, input: $input) {
      entities {
        id
        lastName
        firstName
        status
        email
        phoneNumber
        isAptForSms
        isValidPhoneNumber
        activatedAt
        isOrphanPage
        areasOfInfluence {
          id
          name
        }
        psychosocialKeywords {
          id
          name
        }
        storeManagementDetails {
          adConnected
          agencyName
          agencyWebsite
          agentEmail
          agentName
          id
          inbound
          initialAssessment
          latestAssessment
          designRequestsPerMonthLimit
          liveDate
          signing
          socialPost
          appearance
          pressConferenceOrAmplifiedInterview
          watchParty
          affinities
          currentSponsors
          conflictsOfInterestsOrNonInterests
          socialConnected
          managedBy {
            email
          }
          managedById
          socialConnected
        }
        hashtags {
          id
          name
        }
        sports {
          id
          name
        }
        storeDetails {
          team {
            id
            name
            sport
          }
          league {
            id
            name
            sport
          }
          college {
            id
            name
          }
          careerStatus
          hometown
          favoriteNumber
          birthDate
          isBirthMonthKnown
          isBirthDayKnown
          socialMediaLink
          facebookLink
          instagramLink
          tiktokLink
          twitterLink
          website
          youtubeLink
          facebookFollowing
          gender
          instagramFollowing
          tiktokFollowing
          twitterFollowing
          youtubeFollowing
          id
          bio
          avatarURL
          coverURL
          storeName
          description
          country
          state
          city
          gymRole
          website
          organizationType
        }
        redirectSlugs
      }
      total
      offset
      limit
    }
  }
`;

export const GET_STORE_NAME = gql`
  query GetStoreName($input: StoreGetInput!) {
    store(input: $input) {
      storeDetails {
        storeName
      }
    }
  }
`;

export const GET_MENTIONS_STORES = gql`
  query GetMentionsStores($storeRoles: [UserRole!], $input: StoresFilterInput) {
    stores(storeRoles: $storeRoles, input: $input) {
      entities {
        id
        storeDetails {
          id
          storeName
          storeId
          storeTitle
          avatarURL
        }
      }
      total
      offset
      limit
    }
  }
`;

export const GET_PAYOUT_ORDERS = gql`
  query GetPayoutOrders($input: PayoutOrdersFilterInput!) {
    getPayoutOrders(input: $input) {
      entities {
        amount
        currency
        id
        outstandingBalance
        periodFrom
        periodTo
        profit
        revenue
        store {
          firstName
          lastName
        }
      }
      limit
      offset
      total
    }
  }
`;

export const GET_LEAGUES_LIST = gql`
  query GetLeaguesList {
    getLeaguesList {
      id
      name
      sport
    }
  }
`;

export const GET_TEAMS_LIST = gql`
  query GetTeamsList {
    getTeamsList {
      id
      league
      name
      sport
    }
  }
`;

export const GET_COLLEGES_LIST = gql`
  query GetCollegesList {
    getCollegesList {
      id
      name
    }
  }
`;
