import React from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Form, Typography, Input } from 'antd';
// Api
import { ADMIN_GET_REDIRECTS } from 'api/redirects/queries';
import {
  ADMIN_CREATE_REDIRECT,
  ADMIN_EDIT_REDIRECT,
} from 'api/redirects/mutations';
// Types
import {
  AdminEditRedirect,
  AdminEditRedirectVariables,
} from 'api/redirects/types/AdminEditRedirect';
import {
  AdminCreateRedirect,
  AdminCreateRedirectVariables,
} from 'api/redirects/types/AdminCreateRedirect';
import {
  AdminGetRedirects_adminGetRedirects_entities,
  AdminGetRedirectsVariables,
} from 'api/redirects/types/AdminGetRedirects';
// UI
import { errorNotification, successNotification } from 'ui/Notification';

const { Text } = Typography;

type CreateEditRedirectModalProps = {
  isVisible: boolean;
  getRedirectsInput: () => AdminGetRedirectsVariables;
  onClose: () => void;
  redirect?: AdminGetRedirects_adminGetRedirects_entities | null;
};

type FormValues = {
  from: string;
  to: string;
};

const CreateEditRedirectModal: React.FC<CreateEditRedirectModalProps> = ({
  isVisible,
  onClose,
  redirect,
  getRedirectsInput,
}) => {
  const [adminCreateRedirect, { loading: editIsLoading }] = useMutation<
    AdminCreateRedirect,
    AdminCreateRedirectVariables
  >(ADMIN_CREATE_REDIRECT);

  const [adminEditRedirect, { loading: createIsLoading }] = useMutation<
    AdminEditRedirect,
    AdminEditRedirectVariables
  >(ADMIN_EDIT_REDIRECT);

  const [form] = Form.useForm();

  const initialFormValues: FormValues = {
    from: redirect?.from || '',
    to: redirect?.to || '',
  };

  const getFormValues = (): FormValues => {
    const formFields: FormValues = form.getFieldsValue();

    const isValidUrl = (string: string) => {
      let urlString;
      try {
        urlString = new URL(string);
      } catch {
        return false;
      }
      return urlString?.protocol?.includes('http');
    };

    const formatValue = (value: string) => {
      const res = value.trim().replaceAll(' ', '_');
      return res[0] !== '/' ? '/'.concat(res) : res;
    };

    return {
      from: formatValue(formFields.from),
      to: isValidUrl(formFields.to)
        ? formFields.to
        : formatValue(formFields.to),
    };
  };

  const onModalClose = () => {
    form.resetFields();
    onClose();
  };

  const handleCreateRedirect = async () => {
    const { from, to } = getFormValues();

    try {
      await adminCreateRedirect({
        variables: {
          input: {
            from,
            to,
          },
        },
        refetchQueries: [
          {
            query: ADMIN_GET_REDIRECTS,
            variables: { ...getRedirectsInput() },
          },
        ],
      });
      successNotification(
        `The redirect from "${from}" to "${to}" has been created successfully`
      );
      onModalClose();
    } catch (err) {
      errorNotification(err?.message);
      console.error('CreateRedirect', err);
    }
  };

  const handleEditRedirect = async () => {
    const { from, to } = getFormValues();

    try {
      await adminEditRedirect({
        variables: {
          input: {
            from,
            to,
            id: redirect?.id || '',
          },
        },
        refetchQueries: [
          {
            query: ADMIN_GET_REDIRECTS,
            variables: { ...getRedirectsInput() },
          },
        ],
      });
      successNotification(
        `The redirect from "${from}" to "${to}" has been saved successfully`
      );
      onModalClose();
    } catch (err) {
      errorNotification(err?.message);
      console.error(err);
    }
  };

  const onFormSubmit = async () => {
    try {
      await form.validateFields();
      return redirect ? handleEditRedirect() : handleCreateRedirect();
    } catch (err) {
      console.error('onFormSubmit', err);
    }
  };

  const fieldRules = [
    {
      validator: (_: any, value: string) => {
        const regexp = /^(\w|\.|-|_|\/|:|\?|&|=|,|\+|%)+$/;
        return regexp.test(value)
          ? Promise.resolve()
          : Promise.reject(
              new Error(
                'This field is required and accepts only letters, numbers and "-" or ""_" character.'
              )
            );
      },
      required: true,
    },
  ];

  return (
    <Modal
      title={`${redirect ? 'Edit' : 'Create new'} redirect`}
      destroyOnClose
      visible={isVisible}
      okText={redirect ? 'Save' : 'Create'}
      cancelText="Cancel"
      onOk={onFormSubmit}
      onCancel={onModalClose}
      confirmLoading={createIsLoading || editIsLoading}
    >
      <Form
        form={form}
        layout="vertical"
        name="CreateRedirectForm"
        initialValues={initialFormValues}
      >
        <Form.Item name="from" label={<Text>From</Text>} rules={fieldRules}>
          <Input disabled={Boolean(redirect)} />
        </Form.Item>

        <Form.Item name="to" label={<Text>To</Text>} rules={fieldRules}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateEditRedirectModal;
