import { gql } from '@apollo/client';

export const GET_SPORTS = gql`
  query Sports($input: GetSportsInput) {
    sports(input: $input) {
      id
      name
    }
  }
`;
