import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Form, Input, Space, Spin, Typography } from 'antd';
import Checkbox from 'antd/lib/checkbox';
// Api
import { SET_VIDEO_EDIT_REQUEST_SETTINGS } from 'api/videoLab/mutation';
import { GET_VIDEO_EDIT_REQUEST_SETTINGS } from 'api/videoLab/queries';
// Types
import {
  GetVideoEditRequestSettings,
  GetVideoEditRequestSettings_getVideoEditRequestSettings_MediaPostVideoEditRequestTrigger,
  GetVideoEditRequestSettings_getVideoEditRequestSettings_StreamVideoEditRequestTrigger,
} from 'api/videoLab/types/GetVideoEditRequestSettings';
import {
  SetVideoEditRequestSettings,
  SetVideoEditRequestSettingsVariables,
} from 'api/videoLab/types/SetVideoEditRequestSettings';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';

const { Title } = Typography;

type FormInput = {
  streamTrigger: boolean | null;
  streamDuration: number;
  interviewTrigger: boolean | null;
  mediaFeedTrigger: boolean | null;
  mediaPostDuration: number;
};

const VideoEditRequestSettings = (): JSX.Element => {
  const [isStreamDurationDisabled, setIsStreamDurationDisabled] =
    useState(true);
  const [isMediaFeedDurationDisabled, setIsMediaFeedDurationDisabled] =
    useState(true);

  const { data } = useQuery<GetVideoEditRequestSettings>(
    GET_VIDEO_EDIT_REQUEST_SETTINGS,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const [setVideoEditRequestSettings, { loading }] = useMutation<
    SetVideoEditRequestSettings,
    SetVideoEditRequestSettingsVariables
  >(SET_VIDEO_EDIT_REQUEST_SETTINGS);

  const streamTrigger = data?.getVideoEditRequestSettings.find(
    (item) => item.id === 'StreamTrigger'
  ) as GetVideoEditRequestSettings_getVideoEditRequestSettings_StreamVideoEditRequestTrigger;

  const interviewTrigger = data?.getVideoEditRequestSettings.find(
    (item) => item.id === 'InterviewTrigger'
  );

  const mediaFeedTrigger = data?.getVideoEditRequestSettings.find(
    (item) => item.id === 'MediaLabTrigger'
  ) as GetVideoEditRequestSettings_getVideoEditRequestSettings_MediaPostVideoEditRequestTrigger;

  const [form] = Form.useForm();

  const handleUpdateSettings = async () => {
    const values = form.getFieldsValue();
    try {
      await setVideoEditRequestSettings({
        variables: {
          input: {
            StreamTrigger: {
              isEnabled: values.streamTrigger,
              config: {
                duration: Number(values.streamDuration) * 60,
              },
            },
            InterviewTrigger: {
              isEnabled: values.interviewTrigger,
            },
            MediaLabTrigger: {
              isEnabled: values.mediaFeedTrigger,
              config: {
                duration: Number(values.mediaPostDuration) * 60,
              },
            },
          },
        },
      });
      successNotification('Settings updated');
    } catch (error) {
      errorNotification((error as Error)?.message);
    }
  };

  const handleFinish = async () => {
    handleUpdateSettings();
  };

  const onValuesChange = (changedValues: FormInput) => {
    if ('streamTrigger' in changedValues) {
      setIsStreamDurationDisabled(!changedValues.streamTrigger);
    }
    if ('mediaFeedTrigger' in changedValues) {
      setIsMediaFeedDurationDisabled(!changedValues.mediaFeedTrigger);
    }
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        streamTrigger: streamTrigger?.isEnabled || false,
        streamDuration: streamTrigger?.config.duration / 60 || 1,
        interviewTrigger: interviewTrigger?.isEnabled || false,
        mediaFeedTrigger: mediaFeedTrigger?.isEnabled || false,
        mediaPostDuration: mediaFeedTrigger?.config.duration / 60 || 1,
      });
      setIsStreamDurationDisabled(!streamTrigger.isEnabled);
      setIsMediaFeedDurationDisabled(!mediaFeedTrigger.isEnabled);
    }
  }, [data, form, streamTrigger, interviewTrigger, mediaFeedTrigger]);

  return (
    <>
      <div>
        <Space direction="vertical" size="middle" align="end">
          <Form
            form={form}
            layout="horizontal"
            name="videoEditRequestSettings"
            autoComplete="off"
            onFinish={handleFinish}
            onValuesChange={onValuesChange}
          >
            <div>
              <div>
                <Title>Video edit request settings</Title>

                <Form.Item
                  name="streamTrigger"
                  label="Stream"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>

                <Form.Item
                  name="streamDuration"
                  label="Stream trigger duration (in minutes):"
                >
                  <Input
                    type="number"
                    placeholder="Type here"
                    disabled={isStreamDurationDisabled}
                  />
                </Form.Item>

                <Form.Item
                  name="interviewTrigger"
                  label="Interview"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>

                <Form.Item
                  name="mediaFeedTrigger"
                  label="Media Post"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>

                <Form.Item
                  name="mediaPostDuration"
                  label="Media Post trigger duration (in minutes):"
                >
                  <Input
                    type="mediaPostNumber"
                    placeholder="Type here"
                    disabled={isMediaFeedDurationDisabled}
                  />
                </Form.Item>
              </div>
            </div>
            <div>
              {loading ? (
                <Spin size="large" />
              ) : (
                <Button type="primary" htmlType="submit">
                  Save Settings
                </Button>
              )}
            </div>
          </Form>
        </Space>
      </div>
    </>
  );
};

export default VideoEditRequestSettings;
