import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Space, Button, Typography } from 'antd';
import { DesktopOutlined, VideoCameraOutlined } from '@ant-design/icons';
// Config
import ENV from 'api/env';
// Hooks
import { useAppContext } from 'hooks';
// Api
import { GET_STREAMS } from 'api/streams/queries';
// Types
import {
  Streams,
  StreamsVariables,
  Streams_adminStreams_entities,
} from 'api/streams/types/Streams';
import {
  StreamStatus,
  StreamOrderBy,
  SortDirection,
} from 'api/graphql-global-types';
// Constants
import { WATCH_STREAM_GEN } from 'constants/routes';
// Helpers
import {
  getFullName,
  getPublicStreamDate,
  formatCurrencyString,
} from 'helpers/utils';
import { getEnvLink } from 'helpers/getEnvLink';
import { parseForInnerHTML } from 'helpers/textEditor';
// UI
import Table, { SortedInfo, TableFilter } from 'ui/Table';

type StreamsListItem = Streams_adminStreams_entities & { storeName: string };

const { Paragraph } = Typography;

const ListOfAssignedStreamsTable = (): JSX.Element => {
  const { authUser } = useAppContext();

  const history = useHistory();

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [sort, setSortInfo] = useState<SortedInfo<StreamOrderBy>>({
    order: SortDirection.DESC,
    key: StreamOrderBy.ScheduleDateStrict,
  });

  const getStreamsInput: any = () => {
    const input: any = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      moderatorId: authUser?.id,
    };

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;
    });

    return { input };
  };

  const { data, loading } = useQuery<Streams, StreamsVariables>(GET_STREAMS, {
    variables: { ...getStreamsInput() },
    fetchPolicy: 'cache-and-network',
  });

  const streamsList: StreamsListItem[] =
    data?.adminStreams?.entities?.map((stream) => ({
      ...stream,
      storeName: `${getFullName(stream?.store)} [${stream?.store?.slug}]`,
    })) || [];

  const columns = [
    {
      title: 'Image',
      dataIndex: 'imageURL',
      fixed: 'left' as const,
      align: 'center' as const,
      width: 127,
      render: function ImageStream(imageURL: string) {
        return <img width={127} src={imageURL} alt="stream" />;
      },
    },
    {
      title: 'Store name',
      dataIndex: 'storeName',
      key: 'storeName',
      fixed: 'left' as const,
      align: 'center' as const,
      width: 160,
      withSearch: true,
    },
    {
      title: 'Title',
      dataIndex: 'name',
      key: StreamOrderBy.Name,
      align: 'center' as const,
      width: 180,
      sorterType: 'text',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (description: string) => (
        <Paragraph ellipsis={{ rows: 10 }} title={description}>
          <div
            dangerouslySetInnerHTML={{ __html: parseForInnerHTML(description) }}
          />
        </Paragraph>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'scheduleDate',
      key: StreamOrderBy.ScheduleDateStrict,
      align: 'center' as const,
      width: 150,
      sorterType: 'date',
      render: (scheduleDate: string | null, record: StreamsListItem) =>
        getPublicStreamDate(scheduleDate, record?.timeZone),
    },
    {
      title: 'Requested Price',
      dataIndex: 'requestedPrice',
      key: StreamOrderBy.Price,
      align: 'center' as const,
      sorterType: 'number',
      width: 120,
      render: (requestedPrice: number | null, record: StreamsListItem) =>
        record?.isFree ? 'Free' : formatCurrencyString(requestedPrice || 0),
    },
    {
      title: 'Status',
      dataIndex: 'streamStatus',
      key: 'streamStatus',
      align: 'center' as const,
      withRadioFilters: true,
      render: (
        streamStatus: StreamStatus,
        record: Streams_adminStreams_entities
      ) => {
        return record?.deletedAt ? 'Deleted' : streamStatus;
      },
      filters: [
        {
          text: StreamStatus.Active,
          value: StreamStatus.Active,
        },
        {
          text: StreamStatus.Cancelled,
          value: StreamStatus.Cancelled,
        },
        {
          text: StreamStatus.Ended,
          value: StreamStatus.Ended,
        },
        {
          text: StreamStatus.Scheduled,
          value: StreamStatus.Scheduled,
        },
      ],
      filterMultiple: false,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 160,
      render: (id: string, record: StreamsListItem) => {
        const storeSlug = record?.store?.slug || '';
        const streamSlug = record?.slug || '';

        const handleWatchClick = () => {
          history.push(`${WATCH_STREAM_GEN}/${record.id}`);
        };

        return (
          <Space size="middle" direction="vertical">
            {record.streamStatus === StreamStatus.Ended && (
              <Button
                type="ghost"
                icon={<VideoCameraOutlined />}
                onClick={handleWatchClick}
              >
                Watch
              </Button>
            )}

            {storeSlug?.length > 0 && streamSlug?.length > 0 && (
              <Button
                href={`${getEnvLink(
                  ENV.REACT_APP_ENV
                )}/${storeSlug}/moderate-stream/${streamSlug}`}
                icon={<DesktopOutlined />}
                type="primary"
                target="_blank"
                block
              >
                Moderate
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <Table<StreamsListItem, StreamOrderBy>
      columns={columns}
      data={streamsList}
      scroll={{ x: 1024 }}
      loading={loading}
      total={data?.adminStreams?.total}
      setPageSize={setPageSize}
      setCurrentPage={setCurrentPage}
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      sortInfo={sort}
      setSortInfo={setSortInfo}
    />
  );
};

export default ListOfAssignedStreamsTable;
