import { CSSProperties } from 'react';

export const tagStyles: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '5px',
  padding: '5px',
  border: '1px solid #d9d9d9',
  background: '#d9d9d9',
};
