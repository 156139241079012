import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Space } from 'antd';
// Api
import { ADMIN_GET_REDIRECTS } from 'api/redirects/queries';
// Types
import {
  AdminGetRedirects,
  AdminGetRedirectsVariables,
  AdminGetRedirects_adminGetRedirects_entities,
} from 'api/redirects/types/AdminGetRedirects';
import { SortedInfo, TableFilter } from 'ui/Table';
import { SortDirection, GetRedirectsOrderBy } from 'api/graphql-global-types';
// UI
import Table from 'ui/Table';
// Components
import Actions from './components/Actions';
import CreateEditRedirectModal from './components/CreateEditRedirectModal';

import './styles.scss';

const Redirects: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<GetRedirectsOrderBy>>({
    order: SortDirection.DESC,
    key: GetRedirectsOrderBy.updatedAt,
  });
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [createRedirectModalVisible, setCreateRedirectModalVisible] =
    useState<boolean>(false);

  const getRedirectsInput: any = () => {
    const input: any = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;
    });

    return { input };
  };

  const { data: redirectsData, loading } = useQuery<
    AdminGetRedirects,
    AdminGetRedirectsVariables
  >(ADMIN_GET_REDIRECTS, {
    fetchPolicy: 'cache-and-network',
    variables: { ...getRedirectsInput() },
  });

  const closeCreateRedirectModal = (): void => {
    setCreateRedirectModalVisible(false);
  };

  const onAddRedirect = () => {
    setCreateRedirectModalVisible(true);
  };

  const columns = [
    {
      title: 'From',
      dataIndex: 'from',
      key: GetRedirectsOrderBy.from,
      fixed: 'left' as const,
      align: 'left' as const,
      width: 100,
      withSearch: true,
      sorterType: 'text',
    },
    {
      title: 'To',
      dataIndex: 'to',
      key: GetRedirectsOrderBy.to,
      align: 'left' as const,
      width: 100,
      withSearch: true,
      sorterType: 'text',
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 60,
      render: function ActionsWrap(
        id: string,
        record: AdminGetRedirects_adminGetRedirects_entities
      ) {
        return (
          <Actions redirect={record} getRedirectsInput={getRedirectsInput} />
        );
      },
    },
  ];

  return (
    <>
      <div className="redirects">
        <Space
          direction="vertical"
          size="middle"
          align="end"
          className="redirects__cta-wrapper"
        >
          <Button
            type="primary"
            onClick={onAddRedirect}
            className="redirects__header__cta"
          >
            Add new redirect
          </Button>
        </Space>

        <Table<
          AdminGetRedirects_adminGetRedirects_entities,
          GetRedirectsOrderBy
        >
          columns={columns}
          data={redirectsData?.adminGetRedirects.entities}
          scroll={{ x: 300 }}
          loading={loading}
          total={redirectsData?.adminGetRedirects.total}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
          sortInfo={sort}
          setSortInfo={setSortInfo}
        />
      </div>

      <CreateEditRedirectModal
        isVisible={createRedirectModalVisible}
        getRedirectsInput={getRedirectsInput}
        onClose={closeCreateRedirectModal}
      />
    </>
  );
};

export default Redirects;
