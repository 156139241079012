import React from 'react';
import { Modal } from 'antd';
import moment from 'moment';
// Types
import { VideoEditRequestSubmissionStatus } from 'api/graphql-global-types';
// UI
import Table from 'ui/Table';
// Const
import { commonTimeFormat } from 'constants/global';

export type VideoCommentModalData = {
  storeName: string;
  feedback: string | null;
  type: VideoEditRequestSubmissionStatus;
  assignedToStoreAt: any;
};

type VideoCommentsModalProps = {
  show: boolean;
  onClose: () => void;
  data: VideoCommentModalData[];
};

const VideoCommentsModal: React.FC<VideoCommentsModalProps> = ({
  show,
  onClose,
  data,
}) => {
  const columns = [
    {
      title: 'Store Name:',
      dataIndex: 'storeName',
      align: 'left' as const,
      width: 200,
    },
    {
      title: 'Date:',
      dataIndex: 'assignedToStoreAt',
      align: 'left' as const,
      width: 160,
      render: (assignedToStoreAt: any) =>
        moment(assignedToStoreAt).format(commonTimeFormat),
    },
    {
      title: 'Comment:',
      dataIndex: 'feedback',
      align: 'left' as const,
      width: 300,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      align: 'left' as const,
      width: 120,
    },
  ];

  return (
    <Modal
      title="Editing request comments"
      visible={show}
      footer={null}
      onCancel={onClose}
      width={780}
    >
      <Table<VideoCommentModalData>
        columns={columns}
        data={data}
        scroll={{ x: 430 }}
        total={data.length}
        defaultPageSize={5}
      />
    </Modal>
  );
};

export default VideoCommentsModal;
