import { gql } from '@apollo/client';
import { AdminFields } from './fragments';

export const CREATE_ADMIN = gql`
  mutation CreateAdmin($input: CreateAdminInput!) {
    createAdmin(input: $input) {
      ...AdminFields
    }
  }
  ${AdminFields}
`;

export const EDIT_ADMIN = gql`
  mutation EditAdmin($input: AdminEditInput!) {
    editAdmin(input: $input) {
      ...AdminFields
    }
  }
  ${AdminFields}
`;

export const DEACTIVATE_ADMIN = gql`
  mutation DeactivateAdmin($id: String!) {
    deactivateAdmin(id: $id) {
      ...AdminFields
    }
  }
  ${AdminFields}
`;

export const RECOVER_ADMIN = gql`
  mutation RecoverAdminAccount($id: String!) {
    recoverAdminAccount(id: $id) {
      ...AdminFields
    }
  }
  ${AdminFields}
`;
