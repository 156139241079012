import {
  Transforms,
  Node,
  Path,
  Range,
  Editor,
  Element,
  Descendant,
} from 'slate';
import { ReactEditor } from 'slate-react';
// Helpers
import { isBlockActive } from 'components/common/RichText/helpers/basic';

export type LinkElement = {
  type: 'link';
  href: string;
  children: Descendant[];
};

export enum LinkTarget {
  Blank = '_blank',
  Self = '_self',
  Parent = '_parent',
  Top = '_top',
}

export const createLinkNode = (
  href: string,
  target: LinkTarget,
  text: string
): Node & { type: string; href: string; target: LinkTarget } => ({
  type: 'link',
  href,
  target,
  children: [{ text }],
});

export const insertLink = (
  editor: ReactEditor,
  { href, target }: { href: string; target: LinkTarget }
): void => {
  if (!href) {
    return;
  }

  const { selection } = editor;
  const link = createLinkNode(href, target, '');

  if (selection) {
    const [parent, parentPath] = Editor.parent(editor, selection.focus.path);

    // eslint-disable-next-line
    // @ts-ignore
    if (parent.type === 'link') {
      removeLink(editor);
    }

    if (editor.isVoid(parent)) {
      Transforms.insertNodes(
        editor,
        {
          // eslint-disable-next-line
          // @ts-ignore
          type: 'paragraph',
          children: [link],
        },
        {
          at: Path.next(parentPath),
          select: true,
        }
      );
    } else if (Range.isCollapsed(selection)) {
      Transforms.insertNodes(editor, link, { select: true });
    } else {
      // eslint-disable-next-line
      // @ts-ignore
      Transforms.wrapNodes(editor, link, { split: true });
    }
  } else {
    Transforms.insertNodes(editor, {
      // eslint-disable-next-line
      // @ts-ignore
      type: 'paragraph',
      children: [link],
    });
  }
};

export const removeLink = (editor: ReactEditor): void => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      // eslint-disable-next-line
      // @ts-ignore
      !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
  });
};

export const unwrapLink = (editor: ReactEditor): void => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      // eslint-disable-next-line
      // @ts-ignore
      !Editor.isEditor(n) && Element.isElement(n) && n.type === 'link',
  });
};

export const wrapLink = (editor: ReactEditor, href: string): void => {
  // if (isLinkActive(editor)) {
  if (isBlockActive(editor, 'link')) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  // eslint-disable-next-line
  // @ts-ignore
  const isCollapsed = selection && Range?.isCollapsed?.(selection);
  const link: LinkElement = {
    type: 'link',
    href,
    children: isCollapsed ? [{ text: href }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};
