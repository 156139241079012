import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Modal, Button, Typography } from 'antd';
// Api
import { ASSIGN_INTERVIEW_ADMIN } from 'api/interview/mutations';
import { GET_STORE_INTERVIEWERS } from 'api/interviewer/queries';
// Types
import {
  GetStoreInterviewers,
  GetStoreInterviewersVariables,
  GetStoreInterviewers_getStoreInterviewers_entities,
} from 'api/interviewer/types/GetStoreInterviewers';
import { refetchQueries } from '../../InterviewsTable/InterviewsTable';
import {
  AssignInterviewAdmin,
  AssignInterviewAdminVariables,
} from 'api/interview/types/AssignInterviewAdmin';
import {
  StoreInterviewerFilterInput,
  StoreStatus,
} from 'api/graphql-global-types';
// UI
import Table, { TableFilter } from 'ui/Table';
import { errorNotification, successNotification } from 'ui/Notification';

const { Text } = Typography;

type AssignInterviewerModalProps = {
  interviewId?: string;
  isModalVisible?: boolean;
  setModalVisible: (isVisible: boolean) => void;
};

const AssignInterviewerModal: React.FC<AssignInterviewerModalProps> = ({
  interviewId,
  isModalVisible = false,
  setModalVisible,
}) => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const getInterviewersInput = (): GetStoreInterviewersVariables => {
    const input: StoreInterviewerFilterInput = {
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      status: [StoreStatus.Active, StoreStatus.Inactive, StoreStatus.Pending],
    };

    searchFilters.forEach(({ key, value }) => {
      input[key as keyof StoreInterviewerFilterInput] = value as any;
    });

    return { input };
  };

  const { data } = useQuery<
    GetStoreInterviewers,
    GetStoreInterviewersVariables
  >(GET_STORE_INTERVIEWERS, {
    variables: { ...getInterviewersInput() },
    fetchPolicy: 'cache-and-network',
  });

  const [assignInterviewAdmin, { loading }] = useMutation<
    AssignInterviewAdmin,
    AssignInterviewAdminVariables
  >(ASSIGN_INTERVIEW_ADMIN, {
    refetchQueries,
  });
  const [assignInterviewerId, setAssignInterviewerId] = useState<string | null>(
    null
  );

  const handleHideModal = () => {
    setModalVisible(false);
    setSearchFilters([]);
  };

  const handleAssignInterviewer = async (
    interviewerId: string,
    interviewerName: string
  ) => {
    if (interviewerId && interviewId) {
      setAssignInterviewerId(interviewerId);
      try {
        await assignInterviewAdmin({
          variables: {
            input: {
              id: interviewId,
              interviewerId,
            },
          },
        });

        setAssignInterviewerId(null);
        handleHideModal();
        successNotification(
          `Request #${interviewId} has been assigned to interviewer ${interviewerName} successfully`
        );
      } catch (err) {
        errorNotification((err as Error)?.message);

        console.log('handleAssignInterviewer error:', { ...(err as Error) });
      }
      setAssignInterviewerId(null);
    }
  };

  const columns = [
    {
      title: 'Interviewer name',
      dataIndex: 'interviewerName',
      align: 'left' as const,
      key: 'name',
      width: 200,
      withSearch: true,
      render: function RequestDetails(
        id: string,
        record: GetStoreInterviewers_getStoreInterviewers_entities
      ) {
        return (
          <Text>{`${record?.firstName || ''} ${record?.lastName || ''}`}</Text>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'center' as const,
      width: 200,
      withSearch: true,
      key: 'email',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      align: 'center' as const,
      width: 50,
      render: function ActionsWrap(
        id: string,
        record: GetStoreInterviewers_getStoreInterviewers_entities
      ) {
        return (
          <Button
            type="primary"
            onClick={() =>
              handleAssignInterviewer(
                id,
                `${record?.firstName || ''} ${record?.lastName || ''}`
              )
            }
            loading={assignInterviewerId === id && loading}
          >
            Assign
          </Button>
        );
      },
    },
  ];

  const dataList: GetStoreInterviewers_getStoreInterviewers_entities[] =
    data?.getStoreInterviewers?.entities?.map((item) => ({
      ...item,
      interviewerName: `${item?.firstName || ''} ${item?.lastName || ''} `,
    })) || [];

  return (
    <Modal
      title={
        <>
          Choose interviewer to assign interview{' '}
          <strong>{`#${interviewId || ''}`}</strong>
        </>
      }
      visible={isModalVisible}
      footer={null}
      onCancel={handleHideModal}
      width={970}
    >
      <Table<GetStoreInterviewers_getStoreInterviewers_entities>
        columns={columns}
        data={dataList}
        scroll={{ x: 450 }}
        loading={loading}
        total={data?.getStoreInterviewers?.total}
        defaultPageSize={pageSize}
        setSearchFilters={setSearchFilters}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
      />
    </Modal>
  );
};

export default AssignInterviewerModal;
