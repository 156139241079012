import React from 'react';
import { Tooltip, Typography } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

const { Text } = Typography;

export type FormItemLabelProps = {
  labelText: string;
  tooltipTitle?: string;
};

const FormItemLabel: React.FC<FormItemLabelProps> = ({
  labelText,
  tooltipTitle,
}) => {
  return (
    <Text>
      {labelText}
      {tooltipTitle ? (
        <Tooltip title={tooltipTitle}>
          <sup>
            {' '}
            <InfoCircleFilled />
          </sup>
        </Tooltip>
      ) : null}
    </Text>
  );
};

export default FormItemLabel;
