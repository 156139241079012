import React from 'react';
// Components
import MarkButton from 'components/common/RichText/components/MarkButton/MarkButton';
import LinkButton from 'components/common/RichText/components/LinkButton/LinkButton';
// Styles
import styles from './Toolbar.module.scss';

export enum ToolbarItem {
  Mark = 'Mark',
  Link = 'Link',
}

type ToolbarProps = {
  items?: ToolbarItem[];
};

const Toolbar = ({ items }: ToolbarProps): JSX.Element | null => {
  if (!items?.length) {
    return null;
  }

  return (
    <div className={styles.root}>
      {items.includes(ToolbarItem.Mark) && <MarkButton />}
      {items.includes(ToolbarItem.Link) && <LinkButton />}
    </div>
  );
};

export default Toolbar;
