import React, { ChangeEvent, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
// Api
import {
  ATTACH_THANK_YOU_VIDEO,
  ATTACH_THANK_YOU_VIDEO_TEMPLATE,
  ATTACH_THANK_YOU_VIDEO_STREAM,
  ATTACH_THANK_YOU_VIDEO_TEMPLATE_STREAM,
} from 'api/thankYouVideo/mutations';
import { GET_MERCH_SALES_BY_STORE } from 'api/merch/queries';
import { GET_STREAM_SALES_BY_STORE } from 'api/streams/queries';
import { GET_AMA_SALES_BY_STORE } from 'api/ama/queries';
// Types
import {
  AttachThankYouVideo,
  AttachThankYouVideoVariables,
} from 'api/thankYouVideo/types/AttachThankYouVideo';
import {
  AttachThankYouVideoStream,
  AttachThankYouVideoStreamVariables,
} from 'api/thankYouVideo/types/AttachThankYouVideoStream';
import {
  AttachThankYouVideoTemplate,
  AttachThankYouVideoTemplateVariables,
} from 'api/thankYouVideo/types/AttachThankYouVideoTemplate';
import {
  AttachThankYouVideoTemplateStream,
  AttachThankYouVideoTemplateStreamVariables,
} from 'api/thankYouVideo/types/AttachThankYouVideoTemplateStream';
import { ProductTypesType } from 'api/graphql-global-types';
import { GetMerchSales_getMerchSales_entities_thankYouVideo } from 'api/merch/types/GetMerchSales';
import { GetStreamSales_getStreamSales_entities_thankYouVideo } from 'api/streams/types/GetStreamSales';
// Hooks
import { useGetThankYouVideoTemplate } from 'hooks/useGetThankYouVideoTemplate';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
// Styles
import './styles.scss';

type ActionsProps = {
  orderId: string;
  printfulOrderId?: string | null;
  thankYouVideo:
    | GetMerchSales_getMerchSales_entities_thankYouVideo
    | GetStreamSales_getStreamSales_entities_thankYouVideo
    | null;
  onVideoView: (videoUrl: string) => void;
  productType: ProductTypesType;
};

enum Actions {
  Attach = 'Attach',
  Upload = 'Upload',
}

const StoreSalesActions: React.FC<ActionsProps> = ({
  orderId,
  printfulOrderId,
  thankYouVideo,
  onVideoView,
  productType,
}) => {
  const refetchQuery =
    productType === ProductTypesType.Merch
      ? GET_MERCH_SALES_BY_STORE
      : productType === ProductTypesType.Ama
      ? GET_AMA_SALES_BY_STORE
      : GET_STREAM_SALES_BY_STORE;

  const uploadInputRef = useRef<null | HTMLInputElement>(null);
  const { storeId } = useParams<{ storeId: string | undefined }>();
  const templateData = useGetThankYouVideoTemplate(productType);
  const thankYouVideoTemplate = templateData?.data?.getThankYouVideoTemplate;

  const isStream = productType === ProductTypesType.Stream;

  const [
    attachThankYouVideoTemplate,
    { loading: useThankYouVideoTemplateLoading },
  ] = useMutation<
    AttachThankYouVideoTemplate,
    AttachThankYouVideoTemplateVariables
  >(ATTACH_THANK_YOU_VIDEO_TEMPLATE, {
    refetchQueries: [
      {
        query: refetchQuery,
        variables: {
          input: {
            storeId,
          },
        },
      },
    ],
  });

  const [
    attachThankYouVideoTemplateStream,
    { loading: useThankYouVideoTemplateStreamLoading },
  ] = useMutation<
    AttachThankYouVideoTemplateStream,
    AttachThankYouVideoTemplateStreamVariables
  >(ATTACH_THANK_YOU_VIDEO_TEMPLATE_STREAM, {
    refetchQueries: [
      {
        query: refetchQuery,
        variables: {
          input: {
            storeId,
          },
        },
      },
    ],
  });

  const [attachThankYouVideo, { loading: useThankYouVideoLoading }] =
    useMutation<AttachThankYouVideo, AttachThankYouVideoVariables>(
      ATTACH_THANK_YOU_VIDEO,
      {
        refetchQueries: [
          {
            query: refetchQuery,
            variables: {
              input: {
                storeId,
              },
            },
          },
        ],
      }
    );

  const [
    attachThankYouVideoStream,
    { loading: useThankYouVideoStreamLoading },
  ] = useMutation<
    AttachThankYouVideoStream,
    AttachThankYouVideoStreamVariables
  >(ATTACH_THANK_YOU_VIDEO_STREAM, {
    refetchQueries: [
      {
        query: refetchQuery,
        variables: {
          input: {
            storeId,
          },
        },
      },
    ],
  });

  const handleAttachTemplateVideo = async (): Promise<void> => {
    try {
      if (isStream) {
        await attachThankYouVideoTemplateStream({
          variables: {
            input: {
              thankYouVideoId: thankYouVideoTemplate?.id || '',
              storeId,
              streamId: orderId,
            },
          },
        });
      } else {
        await attachThankYouVideoTemplate({
          variables: {
            input: {
              thankYouVideoId: thankYouVideoTemplate?.id || '',
              storeId,
              productType: productType,
              orderId,
            },
          },
        });
      }

      successNotification(
        `Your "Thank You" video template has been successfully sent to ${
          isStream ? 'stream' : 'order'
        } ${printfulOrderId || orderId}`
      );
    } catch (err) {
      console.error('Attach "Thank You" video template error:', err);
      errorNotification(
        'Looks like something went wrong. Please try again later.'
      );
    }
  };

  const handleAttachVideo = async (file: File | undefined): Promise<void> => {
    try {
      if (isStream) {
        await attachThankYouVideoStream({
          variables: {
            input: {
              video: file,
              storeId,
              streamId: orderId,
            },
          },
        });
      } else {
        await attachThankYouVideo({
          variables: {
            input: {
              video: file,
              productType: productType,
              storeId,
              orderId,
            },
          },
        });
      }
      successNotification(
        `Your "Thank You" video has been successfully sent to ${
          isStream ? 'stream' : 'order'
        } #${printfulOrderId || orderId}`
      );
    } catch (err) {
      console.error('Attach "Thank You" video error:', err);
      errorNotification(
        'Looks like something went wrong. Please try again later.'
      );
    }
  };

  const handleUploadVideoHandler = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    await handleAttachVideo(e?.currentTarget?.files?.[0]);
    e.currentTarget.value = '';
  };

  const handleFileUploadOpen = () => {
    uploadInputRef.current?.click();
  };

  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case Actions.Attach:
        handleAttachTemplateVideo();
        return;
      case Actions.Upload:
        handleFileUploadOpen();
        return;
      default:
        return;
    }
  };

  const handleViewVideoClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onVideoView(thankYouVideo?.thankYouVideoUrl || '');
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="file">
        <input
          name="video"
          type="file"
          accept="video/*"
          value=""
          hidden
          onChange={handleUploadVideoHandler}
          ref={uploadInputRef}
        />
      </Menu.Item>

      {!!thankYouVideoTemplate && (
        <Menu.Item key={Actions.Attach}>Use Template</Menu.Item>
      )}

      <Menu.Item key={Actions.Upload}>Upload Video</Menu.Item>
    </Menu>
  );

  const isMenuLoading =
    useThankYouVideoTemplateLoading ||
    useThankYouVideoLoading ||
    useThankYouVideoStreamLoading ||
    useThankYouVideoTemplateStreamLoading;

  return thankYouVideo?.thankYouVideoUrl ? (
    <Button onClick={handleViewVideoClick}>View video</Button>
  ) : (
    <Dropdown overlay={menu} trigger={['click']} disabled={isMenuLoading}>
      <div className="ant-dropdown-link">
        <Button icon={isMenuLoading && <LoadingOutlined />}>
          Actions <DownOutlined />
        </Button>
      </div>
    </Dropdown>
  );
};

export default StoreSalesActions;
