import React from 'react';
import { useApolloClient } from '@apollo/client';
// Api
import { GET_STORES } from 'api/store/queries';
// Types
import {
  StoreStatus,
  SortDirection,
  AdminStoresOrderBy,
  UserRole,
} from 'api/graphql-global-types';
import { GetStores, GetStoresVariables } from 'api/store/types/GetStores';
// Helpers
import { getStoreName } from 'helpers/utils';
// Components
import SectionItem, {
  LoadMore,
  OptionsItem,
} from 'pages/HomePageManagement/components/SectionItem';

type SetupMerchStoreProps = {
  selectedStore: OptionsItem;
  onStoreChange: (store: OptionsItem) => void;
};

const PER_PAGE = 10;

const SetupMerchStore: React.FC<SetupMerchStoreProps> = ({
  selectedStore,
  onStoreChange,
}) => {
  const client = useApolloClient();

  const loadMore: LoadMore = async (offset, search) => {
    const options: OptionsItem[] = [];

    const { data } = await client.query<GetStores, GetStoresVariables>({
      query: GET_STORES,
      variables: {
        input: {
          ...(search
            ? {
                storeName: search,
              }
            : {}),
          hasActiveMerch: true,
          status: [StoreStatus.Active],
          orderBy: AdminStoresOrderBy.storeName,
          direction: SortDirection.ASC,
          limit: PER_PAGE,
          offset,
        },
        storeRoles: [
          UserRole.Athlete,
          UserRole.Organization,
          UserRole.ContentCreator,
        ],
      },
      fetchPolicy: 'network-only',
    });

    const total = data?.adminStores?.total || 0;

    data?.adminStores?.entities?.forEach((item) => {
      options.push({
        value: item.id,
        label: getStoreName(item),
      });
    });

    return { total, options };
  };

  return (
    <SectionItem
      onItemChange={onStoreChange}
      item={selectedStore}
      selectPlaceholder="Select Store"
      paginationSize={PER_PAGE}
      loadMore={loadMore}
    />
  );
};

export default SetupMerchStore;
