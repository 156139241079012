import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useMutation } from '@apollo/client';
// Api
import {
  ADMIN_EDIT_MERCH_DESIGN,
  ADMIN_EDIT_MERCH_DESIGN_WITH_LOGO,
  CREATE_PRESIGNED_URL_FOR_SOCIAL_PACK_REQUEST,
} from 'api/designLab/mutations';
import { DesignRequestType } from 'api/graphql-global-types';
// Types
import { DesignRequests_designRequests_entities_socialMediaPackImages } from 'api/designLab/types/DesignRequests';
import {
  CreatePresignedUrlForSocialPack,
  CreatePresignedUrlForSocialPackVariables,
} from 'api/designLab/types/CreatePresignedUrlForSocialPack';
import {
  AdminEditMerchDesign,
  AdminEditMerchDesignVariables,
} from 'api/designLab/types/AdminEditMerchDesign';
import {
  AdminEditMerchDesignWithLogo,
  AdminEditMerchDesignWithLogoVariables,
} from 'api/designLab/types/AdminEditMerchDesignWithLogo';
import SocialMediaPackItem, {
  SocialMediaPackItemType,
  FileWithUid,
  SocialMediaPackDataType,
  emptySocialMediaItem,
} from '../../../SocialMediaPackItem/SocialMediaPackItem';
// Helpers
import { uploadToS3 } from 'helpers/createPresignedUrl';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import MerchItem from 'components/common/ManageMerch/AllMerch/MerchItem/MerchItem';
import { RequestImage } from 'helpers/designRequests';
// Styles
import styles from './MerchDesignPreviewModal.module.scss';

export type MerchDesignModalProps = {
  request?: RequestImage | null;
  socialMediaPack?: DesignRequests_designRequests_entities_socialMediaPackImages[];
  onClose?: () => void;
};

const MerchDesignPreviewModal = ({
  request,
  socialMediaPack,
  onClose,
}: MerchDesignModalProps): JSX.Element => {
  const [createPresignedUrls, { loading: uploadingImages }] = useMutation<
    CreatePresignedUrlForSocialPack,
    CreatePresignedUrlForSocialPackVariables
  >(CREATE_PRESIGNED_URL_FOR_SOCIAL_PACK_REQUEST);

  const [adminEditMerchDesign, { loading: adminEditMerchDesignLoader }] =
    useMutation<AdminEditMerchDesign, AdminEditMerchDesignVariables>(
      ADMIN_EDIT_MERCH_DESIGN
    );

  const [
    adminEditMerchDesignWithLogo,
    { loading: adminEditMerchDesignWithLogoLoader },
  ] = useMutation<
    AdminEditMerchDesignWithLogo,
    AdminEditMerchDesignWithLogoVariables
  >(ADMIN_EDIT_MERCH_DESIGN_WITH_LOGO);

  const [socialMediaPackItems, setSocialMediaPackItems] = useState<
    SocialMediaPackItemType[]
  >([]);

  const isMerchCreationType =
    request?.designRequestType === DesignRequestType.MerchCreation;
  const isMerchCreationWithLogoType =
    request?.designRequestType === DesignRequestType.MerchCreationWithLogo;

  useEffect(() => {
    setSocialMediaPackItems(
      (socialMediaPack &&
        socialMediaPack.map((item) => {
          return {
            isValid: true,
            label: item.label || '',
            image: undefined,
            imageFileKey: item.imageFileKey || '',
            imgURL: item.socialMediaPackImageUrl || '',
          };
        })) ||
        []
    );
  }, [socialMediaPack]);

  const [s3Loader, setS3Loader] = useState<boolean>(false);

  const allImagesForUpload: FileWithUid[] = []; // new images
  const socialMediaPackImagesIndex: number[] = []; // where new social media images should be added
  const socialMediaPackImageUrls: SocialMediaPackDataType[] = []; // array that will contain social media images (old and new)

  const addNewSocialMediaPack = () => {
    setSocialMediaPackItems([...socialMediaPackItems, emptySocialMediaItem]);
  };

  const clearSocialMediaPack = () => {
    setSocialMediaPackItems([]);
  };

  const updateSocialMediaPackItems = (
    value: SocialMediaPackItemType,
    valueIndex: number
  ) => {
    setSocialMediaPackItems((prevData) =>
      prevData.map((currentItem, index) => {
        if (index === valueIndex) {
          return value;
        }
        return currentItem;
      })
    );
  };

  const deleteSocialMediaItem = (index: number) => {
    const newSocialMediaPackItems = [...socialMediaPackItems];
    newSocialMediaPackItems.splice(index, 1);
    setSocialMediaPackItems(newSocialMediaPackItems);
  };

  const uploadDesign = async (uploadData: SocialMediaPackDataType[]) => {
    const types = isMerchCreationType || isMerchCreationWithLogoType;
    const apiCall =
      types === isMerchCreationType
        ? adminEditMerchDesign
        : adminEditMerchDesignWithLogo;
    try {
      await apiCall({
        variables: {
          input: {
            designRequestId: request?.designRequestId || '',
            socialMediaPackImages: uploadData,
            storeId: request?.merchItems?.[0]?.merch?.storeId || '',
          },
        },
      });
      successNotification(
        `Upload for request #${
          request?.designRequestId || ''
        } has been finished successfully.`
      );
      onClose && onClose();
    } catch (error) {
      errorNotification((error as Error)?.message);
    }
  };

  const handleFinish = async () => {
    for (let counter = 0; counter < socialMediaPackItems.length; counter++) {
      socialMediaPackImageUrls.push({
        imageFileKey: socialMediaPackItems[counter].imageFileKey || '',
        label: socialMediaPackItems[counter].label,
        imgURL: socialMediaPackItems[counter].imgURL || '',
      });
      const image = socialMediaPackItems[counter].image;
      if (image) {
        allImagesForUpload.push(image);
        socialMediaPackImagesIndex.push(counter);
      }
    }

    if (allImagesForUpload.length) {
      try {
        const { data } = await createPresignedUrls({
          variables: {
            input: {
              designRequestId: request?.designRequestId || '',
              numberOfImages: allImagesForUpload.length,
            },
          },
        });

        if (data?.createPresignedUrlForSocialPack.length) {
          try {
            setS3Loader(true);
            const uploadData = await uploadToS3(
              data.createPresignedUrlForSocialPack,
              allImagesForUpload,
              socialMediaPackImageUrls,
              socialMediaPackImagesIndex
            );
            setS3Loader(false);
            uploadDesign(uploadData);
          } catch (error) {
            errorNotification((error as Error)?.message);
            setS3Loader(false);
          }
        }
      } catch (error) {
        errorNotification((error as Error)?.message);
      }
    } else {
      uploadDesign(socialMediaPackImageUrls);
    }
  };

  const buttonLoading: boolean =
    uploadingImages ||
    s3Loader ||
    adminEditMerchDesignLoader ||
    adminEditMerchDesignWithLogoLoader;

  return (
    <div>
      {request?.merchItems?.map(
        ({ merch }) =>
          merch && (
            <MerchItem
              key={merch?.id}
              product={merch}
              isMerchDesignReview
              store={merch?.store}
              designRequestId={request?.designRequestId || ''}
            />
          )
      )}
      <div className={styles.socialAndUpdateWrapper}>
        <div className={styles.socialMediaPackWrapper}>
          {socialMediaPackItems.map(
            (socialMediaItem: SocialMediaPackItemType, index: number) => {
              return (
                <SocialMediaPackItem
                  key={socialMediaItem.label + '_social_media_item_' + index}
                  index={index}
                  socialMediaItem={socialMediaItem}
                  setValue={updateSocialMediaPackItems}
                  deleteValue={deleteSocialMediaItem}
                />
              );
            }
          )}
          <Button onClick={addNewSocialMediaPack} loading={buttonLoading}>
            <span>Add New Social Media Image</span>
          </Button>

          {socialMediaPackItems.length ? (
            <Button onClick={clearSocialMediaPack} loading={buttonLoading}>
              <span>Clear All Social Media Images</span>
            </Button>
          ) : null}
        </div>
        <div className={styles.updateWrapper}>
          <Button
            disabled={buttonLoading}
            loading={buttonLoading}
            type="primary"
            onClick={handleFinish}
          >
            <span>Update</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MerchDesignPreviewModal;
