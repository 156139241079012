import { gql } from '@apollo/client';

export const GET_SCHEDULE_EVENTS = gql`
  query GetScheduleEvents($input: GetScheduleEventsInput!) {
    getScheduleEvents(input: $input) {
      id
      startTs
      endTs
      status
    }
  }
`;

export const GET_MY_SCHEDULE_EVENTS = gql`
  query GetMyScheduleEvents($input: GetMyScheduleEventsInput!) {
    getMyScheduleEvents(input: $input) {
      id
      startTs
      endTs
      status
    }
  }
`;
