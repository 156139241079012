import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Typography } from 'antd';
// Api
import { GET_STREAMS } from 'api/streams/queries';
// Types
import {
  Streams,
  StreamsVariables,
  Streams_adminStreams_entities,
} from 'api/streams/types/Streams';
import {
  StreamOrderBy,
  SortDirection,
  StreamStatus,
} from 'api/graphql-global-types';
import { SortedInfo, TableFilter } from 'ui/Table';
// Helpers
import { formatCurrencyString, getPublicStreamDate } from 'helpers/utils';
import { parseForInnerHTML } from 'helpers/textEditor';
// UI
import Table from 'ui/Table';
// Components
import Actions from 'components/common/ScheduledStreamsTable/components/Actions';
import AttendeesColumn from 'components/common/AttendeesColumn/AttendeesColumn';
import StreamNumberOfOrdersButton from 'components/common/Stream/StreamNumberOfOrdersButton/StreamNumberOfOrdersButton';
import StreamViewsTooltip from 'components/common/Stream/StreamViewsTooltip/StreamViewsTooltip';
// Styles
import styles from './AllStreams.module.scss';

const { Paragraph } = Typography;

type StreamsListItem = Streams_adminStreams_entities & { storeName: string };

const AllStreams: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [sort, setSortInfo] = useState<SortedInfo<StreamOrderBy>>({
    order: SortDirection.DESC,
    key: StreamOrderBy.ScheduleDateStrict,
  });

  const getAllStreamsInput: any = () => {
    const input: any = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;
    });

    return { input };
  };

  const { data, loading } = useQuery<Streams, StreamsVariables>(GET_STREAMS, {
    variables: { ...getAllStreamsInput() },
    fetchPolicy: 'cache-and-network',
  });

  const streamsList: StreamsListItem[] =
    data?.adminStreams?.entities?.map((stream) => ({
      ...stream,
      storeName: `${stream?.store?.firstName || ''} ${
        stream?.store?.lastName || ''
      } [${stream?.store?.slug}]`,
    })) || [];

  const columns = [
    {
      title: 'Image',
      dataIndex: 'imageURL',
      fixed: 'left' as const,
      align: 'center' as const,
      width: 127,
      render: function ImageStream(imageURL: string) {
        return <img width={127} src={imageURL} alt="stream" />;
      },
    },
    {
      title: 'Store name',
      dataIndex: 'storeName',
      key: 'storeName',
      fixed: 'left' as const,
      align: 'center' as const,
      width: 160,
      withSearch: true,
    },
    {
      title: 'Title',
      dataIndex: 'name',
      key: 'name',
      align: 'center' as const,
      width: 180,
      withSearch: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (description: string) => (
        <Paragraph ellipsis={{ rows: 3 }} title={description}>
          <div
            dangerouslySetInnerHTML={{ __html: parseForInnerHTML(description) }}
          />
        </Paragraph>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'streamStatus',
      key: 'streamStatus',
      align: 'center' as const,
      withRadioFilters: true,
      filters: [
        {
          text: StreamStatus.Cancelled,
          value: StreamStatus.Cancelled,
        },
        {
          text: StreamStatus.Scheduled,
          value: StreamStatus.Scheduled,
        },
        {
          text: StreamStatus.Active,
          value: StreamStatus.Active,
        },
        {
          text: StreamStatus.Ended,
          value: StreamStatus.Ended,
        },
        {
          text: StreamStatus.Interrupted,
          value: StreamStatus.Interrupted,
        },
        {
          text: StreamStatus.Paused,
          value: StreamStatus.Paused,
        },
      ],
      render: (status: StreamStatus) => {
        return <p className={styles[`is${status}`]}>{status}</p>;
      },
    },
    {
      title: 'Tips',
      dataIndex: 'streamTips',
      render: (registerd: string, record: Streams_adminStreams_entities) => (
        <div className={styles.tipsCell}>
          {record.streamTips?.map((tip) => (
            <div key={tip.id} className={styles.tipCellWrapper}>
              <p>
                {tip.customer.firstName} {tip.customer.lastName}
              </p>
              <p>{formatCurrencyString(tip.amount)}</p>
            </div>
          ))}
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'scheduleDate',
      key: StreamOrderBy.ScheduleDateStrict,
      align: 'center' as const,
      width: 150,
      sorterType: 'date',
      render: (registered: number, record: Streams_adminStreams_entities) =>
        getPublicStreamDate(record?.scheduleDate, record?.timeZone),
    },
    {
      title: 'Requested Price',
      dataIndex: 'requestedPrice',
      key: StreamOrderBy.Price,
      align: 'center' as const,
      sorterType: 'number',
      width: 120,
      render: (registered: number, record: Streams_adminStreams_entities) =>
        record?.isFree
          ? 'Free'
          : formatCurrencyString(record?.requestedPrice || 0),
    },
    {
      title: 'Attendees',
      dataIndex: 'attendees',
      align: 'center' as const,
      width: 100,
      render: function AttendeesWrap(
        registered: number,
        record: Streams_adminStreams_entities
      ) {
        return <AttendeesColumn data={record} />;
      },
    },
    {
      title: 'Orders',
      dataIndex: 'numberOfOrders',
      align: 'center' as const,
      width: 100,
      render: function NumberOfOrdersWrapper(
        registered: number,
        record: Streams_adminStreams_entities
      ) {
        return (
          <StreamNumberOfOrdersButton
            numberOfOrders={record.numberOfOrders || 0}
            streamId={record.id}
            streamName={record.name}
            isFree={record.isFree}
          />
        );
      },
    },
    {
      title: 'Views',
      dataIndex: 'views',
      align: 'center' as const,
      width: 100,
      render: function AttendeesWrap(
        registered: number,
        record: Streams_adminStreams_entities
      ) {
        return <StreamViewsTooltip views={record.views} />;
      },
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 120,
      render: function ActionsWrap(text: any, record: any) {
        return <Actions stream={record} />;
      },
    },
  ];

  return (
    <>
      <Table<StreamsListItem, StreamOrderBy>
        columns={columns}
        data={streamsList}
        scroll={{ x: 1024 }}
        loading={loading}
        total={data?.adminStreams?.total}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />
    </>
  );
};

export default AllStreams;
