import { gql } from '@apollo/client';

export const DOWNLOAD_CAMPAIGN_OFFERS = gql`
  mutation DownloadCampaignOffersFull(
    $input: DownloadCampaignOffersFullInput!
  ) {
    downloadCampaignOffersFull(input: $input) {
      csvUrl
    }
  }
`;

export const APPROVE_CAMPAIGN = gql`
  mutation ApproveCampaign($input: ApproveCampaignInput!) {
    approveCampaign(input: $input) {
      id
      status
    }
  }
`;

export const DECLINE_CAMPAIGN = gql`
  mutation DeclineCampaign($input: DeclineCampaignInput!) {
    declineCampaign(input: $input) {
      id
      status
    }
  }
`;

export const CREATE_CAMPAIGN_EXAMPLE_FILES = gql`
  mutation CreateCampaignExampleFiles(
    $input: CreateCampaignExampleFilesInput!
  ) {
    createCampaignExampleFiles(input: $input) {
      fields
      key
      url
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      id
    }
  }
`;

export const UPDATE_BOOK_ME_CAMPAIGN = gql`
  mutation UpdateBookMeCampaign($input: UpdateBookMeCampaignInput!) {
    updateBookMeCampaign(input: $input) {
      id
    }
  }
`;

export const UPDATE_SPONSORED_APPEARANCE_CAMPAIGN = gql`
  mutation UpdateSponsoredAppearanceCampaign(
    $input: UpdateSponsoredAppearanceInput!
  ) {
    updateSponsoredAppearanceCampaign(input: $input) {
      id
    }
  }
`;

export const UPDATE_EVENT_MARKETING_CAMPAIGN = gql`
  mutation UpdateEventMarketingCampaign(
    $input: UpdateEventMarketingCampaignInput!
  ) {
    updateEventMarketingCampaign(input: $input) {
      id
      name
      activities {
        ... on EventMarketingActivity {
          id
          description
          estimatedViews
          participantCount
          shortFormVideoCount
          socialMediaLinks
          sport
          startDate
          type
        }
      }
    }
  }
`;

export const ADD_OFFLINE_PAYMENT = gql`
  mutation AddOfflineCampaignPayment($input: AddOfflineCampaignPaymentInput!) {
    addOfflineCampaignPayment(input: $input) {
      id
    }
  }
`;

export const COMPLETE_CAMPAIGN = gql`
  mutation CompleteCampaign($input: CompleteCampaignInput!) {
    completeCampaign(input: $input) {
      id
      status
      type
    }
  }
`;
