import React from 'react';
// Components
import Sales from 'components/common/Sales/ExperienceSales';

const ExperienceSales: React.FC = () => {
  // reusing component just without storeId
  return <Sales />;
};

export default ExperienceSales;
