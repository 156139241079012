import React, { useState } from 'react';
import { Modal, Tabs } from 'antd';
// Hooks
import { useDefaultStoreRedirectOnEmptyId } from 'hooks';
// Types
import { ProductTypesType } from 'api/graphql-global-types';
// Ui
import VideoPreview from 'ui/VideoPreview/VideoPreview';
// Components
import AmaSales from './AmaSales';
import StreamSales from './StreamSales';
import ProductSales from './ProductSales';
import ExperienceSales from './ExperienceSales';
// Styles
import './styles.scss';

const { TabPane } = Tabs;

const Sales: React.FC = () => {
  useDefaultStoreRedirectOnEmptyId();

  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [videoPreview, setVideoPreview] = useState<string | null>(null);

  const handleShowVideo = (videoUrl: string) => {
    setModalVisible(true);
    setVideoPreview(videoUrl);
  };

  const handleHideModal = () => {
    setModalVisible(false);
  };

  const [, setActiveKey] = useState<ProductTypesType>(ProductTypesType.Merch);

  const handleChangeProductType = (activeKey: string) => {
    setActiveKey(activeKey as ProductTypesType);
  };

  return (
    <div className="salesContainer">
      <Modal
        title="Video Preview"
        visible={isModalVisible}
        footer={null}
        onCancel={handleHideModal}
      >
        <div className="videoWrapper">
          <VideoPreview video={videoPreview} show={isModalVisible} />
        </div>
      </Modal>
      <Tabs
        defaultActiveKey={ProductTypesType.Product}
        onChange={handleChangeProductType}
      >
        <TabPane tab="Products" key={ProductTypesType.Product}>
          <ProductSales />
        </TabPane>

        <TabPane tab="AMA" key={ProductTypesType.Ama}>
          <AmaSales onVideoView={handleShowVideo} />
        </TabPane>

        <TabPane tab="Watchstream" key={ProductTypesType.Stream}>
          <StreamSales onVideoView={handleShowVideo} />
        </TabPane>

        <TabPane tab="Experiences" key={ProductTypesType.Experience}>
          <ExperienceSales />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Sales;
