import React, { useState } from 'react';
import { Modal, Typography } from 'antd';
import { useQuery } from '@apollo/client';
// Api
import { DESIGN_REQUESTS } from 'api/designLab/queries';
// Types
import {
  DesignRequests,
  DesignRequestsVariables,
} from 'api/designLab/types/DesignRequests';
import {
  DesignRequestStatus,
  DesignRequestsOrderBy,
  SortDirection,
  UserRole,
} from 'api/graphql-global-types';
import {
  DesignRequestsFormattedData,
  getDesignRequestsFormattedData,
} from 'helpers/designRequests';
// UI
import Table, { SortedInfo } from 'ui/Table';

const { Text, Link } = Typography;

type RequestsProps = {
  designerId: string;
  designerName: string;
  show: boolean;
  onClose: () => void;
};

const RequestStatusTitle = {
  [DesignRequestStatus.Accepted]: 'Accepted',
  [DesignRequestStatus.AutoAccepted]: 'Auto-Accepted',
  [DesignRequestStatus.Archived]: 'Archived',
  [DesignRequestStatus.AwaitingApproval]: 'Awaiting approval',
  [DesignRequestStatus.InProgress]: 'In progress',
  [DesignRequestStatus.OnReview]: 'On review',
  [DesignRequestStatus.Rejected]: 'Rejected',
  [DesignRequestStatus.Todo]: 'Todo',
};

const Requests: React.FC<RequestsProps> = ({
  show,
  onClose,
  designerId,
  designerName,
}) => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<DesignRequestsOrderBy>>({
    order: SortDirection.DESC,
    key: DesignRequestsOrderBy.createdAt,
  });

  const { data, loading } = useQuery<DesignRequests, DesignRequestsVariables>(
    DESIGN_REQUESTS,
    {
      variables: {
        input: {
          direction: sort.order,
          orderBy: sort.key,
          limit: pageSize,
          offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
          ...(designerId && {
            designerIds: [designerId],
          }),
        },
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const columns = [
    {
      title: 'Request id',
      dataIndex: 'id',
      align: 'left' as const,
      width: 120,
      render: function RequestIdWrap(id: string) {
        return (
          <Link
            href={`/design-request-details/${id}`}
            target="_blank"
            title={id}
          >
            {id}
          </Link>
        );
      },
    },
    {
      title: 'Store Name',
      dataIndex: 'storeName',
      align: 'left' as const,
      width: 180,
      render: function StoreNameWrap(
        storeName: string,
        record: DesignRequestsFormattedData
      ) {
        return (
          <Link
            href={
              record.store
                ? `${
                    record.store.role === UserRole.Organization
                      ? '/organization-dashboard'
                      : record.store.role === UserRole.ContentCreator
                      ? '/content-creator-dashboard'
                      : '/athlete-dashboard'
                  }/${record.store.id}`
                : '#'
            }
            target="_blank"
            title={`${storeName}`}
          >
            {storeName}
          </Link>
        );
      },
    },
    {
      title: 'Request status',
      dataIndex: 'status',
      align: 'left' as const,
      width: 180,
      render: function RequestsStatus(status: DesignRequestStatus) {
        return <Text>{RequestStatusTitle?.[status] || status}</Text>;
      },
    },
  ];

  return (
    <Modal
      title={
        <>
          <strong>{designerName}</strong> designer requests
        </>
      }
      visible={show}
      footer={null}
      onCancel={onClose}
      width="70vw"
    >
      <Table<DesignRequestsFormattedData, DesignRequestsOrderBy>
        columns={columns}
        data={getDesignRequestsFormattedData(data)}
        total={data?.designRequests?.total}
        defaultPageSize={pageSize}
        defaultCurrent={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        sortInfo={sort}
        setSortInfo={setSortInfo}
        loading={loading}
      />
    </Modal>
  );
};

export default Requests;
