import React, { useState } from 'react';
import { Spin, Pagination } from 'antd';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
// Hooks
import { useDefaultStoreRedirectOnEmptyId } from 'hooks';
// Api
import { GET_ADMIN_MERCH_PRODUCTS } from 'api/merch/queries';
// Types
import {
  GetAdminMerchProducts,
  GetAdminMerchProductsVariables,
} from 'api/merch/types/GetAdminMerchProducts';
// Helpers
import { computePRProducts } from '../helpers';
// Components
import ToggledMerchSection from './ToggledMerchSection/ToggledMerchSection';
// Styles
import styles from './AllMerch.module.scss';

const DEFAULT_NUMBER_ITEMS_PER_PAGE = 100;
const DEFAULT_CURRENT_PAGE = 1;

const AllMerch: React.FC = () => {
  useDefaultStoreRedirectOnEmptyId();
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [pageSize, setPageSize] = useState<number>(
    DEFAULT_NUMBER_ITEMS_PER_PAGE
  );

  const { storeId } = useParams<{ storeId: string | undefined }>();

  const { data, loading, refetch } = useQuery<
    GetAdminMerchProducts,
    GetAdminMerchProductsVariables
  >(GET_ADMIN_MERCH_PRODUCTS, {
    variables: {
      input: {
        storeId,
        limit: pageSize,
        offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !storeId,
  });

  const handleChangePage = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize || 0);

    refetch({
      input: {
        storeId,
        limit: pageSize,
        offset: (page > 1 ? page - 1 : 0) * pageSize,
      },
    });

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const products = data?.getAdminMerchProducts?.entities || [];

  const { TShirt, Hoodie, Hat, Joggers, RashGuard } =
    computePRProducts(products);

  return loading ? (
    <Spin size="large" />
  ) : (
    <>
      <h1 className={styles.title}>All Merch</h1>

      <ToggledMerchSection
        title="T-shirts"
        products={TShirt}
        productType="TShirt"
      />
      <ToggledMerchSection
        title="Hoodies"
        products={Hoodie}
        productType="Hoodie"
      />
      <ToggledMerchSection
        title="Joggers"
        products={Joggers}
        productType="Joggers"
      />
      <ToggledMerchSection title="Hats" products={Hat} productType="Hat" />
      <ToggledMerchSection
        title="Compression shirts"
        products={RashGuard}
        productType="RashGuard"
      />

      {data &&
        data?.getAdminMerchProducts.total > DEFAULT_NUMBER_ITEMS_PER_PAGE && (
          <Pagination
            onChange={handleChangePage}
            defaultCurrent={DEFAULT_CURRENT_PAGE}
            total={data?.getAdminMerchProducts.total}
            current={currentPage}
            pageSize={pageSize}
          />
        )}
    </>
  );
};

export default AllMerch;
