import React, { useState, useEffect } from 'react';
import { Card, Tabs } from 'antd';
// Types
import { BannerMediaType } from 'api/graphql-global-types';
import { BannersOption } from '../../index';
// Components
import SetupBannersItem from './components/SetupBannersItem';

const { TabPane } = Tabs;

export type SetupBannersProps = {
  selectedItems: BannersOption[];
  onInputChange: React.Dispatch<React.SetStateAction<BannersOption[]>>;
};

const SetupBanners: React.FC<SetupBannersProps> = ({
  selectedItems,
  onInputChange,
}) => {
  const [activeKey, setActiveKey] = useState<string>('0');
  const [kludgeLoading, setKludgeLoading] = useState<boolean>(false);

  useEffect(() => {
    setKludgeLoading(true);

    setTimeout(() => {
      setKludgeLoading(false);
    }, 1);
  }, [selectedItems.length]);

  const handleTabChange = (activeKey: string) => {
    setActiveKey(activeKey);
  };

  const onEdit = (
    targetKey:
      | string
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>,
    action: 'add' | 'remove'
  ) => {
    if (action === 'remove') {
      remove(targetKey);
    } else {
      add();
    }
  };

  const add = () => {
    const newBanners = [...selectedItems];
    newBanners.push({
      image: '',
      video: '',
      mediaId: '',
      title: '',
      description: '',
      buttonContent: '',
      shouldLinkRedirect: null,
      link: '',
      mediaType: BannerMediaType.image,
    });
    setActiveKey(String(selectedItems.length));
    onInputChange(newBanners);
  };

  const remove = (
    targetKey:
      | string
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
  ) => {
    const newBanners = selectedItems.filter(
      (_, index) => index !== Number(targetKey)
    );
    let newActiveKey = activeKey;

    if (newActiveKey === targetKey) {
      if (Number(targetKey) !== 0) {
        newActiveKey = String(Number(activeKey) - 1);
      } else {
        newActiveKey = '0';
      }
    } else if (
      newActiveKey > targetKey &&
      newActiveKey === String(newBanners.length)
    ) {
      newActiveKey = String(newBanners.length - 1);
    } else if (
      newActiveKey > targetKey &&
      newActiveKey < String(newBanners.length)
    ) {
      newActiveKey = String(Number(activeKey) - 1);
    }

    setActiveKey(String(newActiveKey));
    onInputChange(newBanners);
  };

  const handleBannerChange = (
    index: number,
    data: string | boolean,
    field: string,
    mediaId?: string
  ) => {
    const newBanners = [...selectedItems];
    if (mediaId && field === 'image' && typeof data !== 'boolean') {
      newBanners[index] = {
        ...newBanners[index],
        video: '',
        mediaType: BannerMediaType.image,
        image: data,
        mediaId,
      };
    } else if (mediaId && field === 'video' && typeof data !== 'boolean') {
      newBanners[index] = {
        ...newBanners[index],
        image: '',
        mediaType: BannerMediaType.video,
        video: data,
        mediaId,
      };
    } else {
      newBanners[index] = { ...newBanners[index], [field]: data };
    }

    onInputChange(newBanners);
  };

  return (
    <Card title="Setup Banners" style={{ width: '100%' }}>
      <Tabs
        type="editable-card"
        onChange={handleTabChange}
        onEdit={onEdit}
        activeKey={activeKey}
      >
        {selectedItems.map((item, index) => (
          <TabPane
            tab={`Banner ${index + 1}`}
            key={index}
            closable={selectedItems.length !== 1}
          >
            {kludgeLoading ? (
              <p>Update...</p>
            ) : (
              <SetupBannersItem
                index={index}
                onBannerChange={handleBannerChange}
                item={item}
              />
            )}
          </TabPane>
        ))}
      </Tabs>
    </Card>
  );
};

export default SetupBanners;
