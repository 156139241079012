import React, { ChangeEvent, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Space, Button, Image, Row, Col, Typography, Input } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// Api
import {
  REJECT_AND_REASSIGN_DESIGN,
  REJECT_AND_SEND_BACK_MERCH_DESIGN,
  APPROVE_BY_MANAGER,
  ACCEPT_MERCH_DESIGN,
} from 'api/designLab/mutations';
import { DESIGN_REQUESTS } from 'api/designLab/queries';
// Types
import {
  AcceptMerchDesign,
  AcceptMerchDesignVariables,
} from 'api/designLab/types/AcceptMerchDesign';
import {
  ApproveByManager,
  ApproveByManagerVariables,
} from 'api/designLab/types/ApproveByManager';
import {
  RejectAndSendBackMerchDesign,
  RejectAndSendBackMerchDesignVariables,
} from 'api/designLab/types/RejectAndSendBackMerchDesign';
import {
  RejectAndReassignDesign,
  RejectAndReassignDesignVariables,
} from 'api/designLab/types/RejectAndReassignDesign';
import { DesignRequestsFormattedData } from 'helpers/designRequests';
// Constants
import { FALLBACK_IMAGE } from 'constants/global';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import AssignDesigner from '../AssignDesigner/AssignDesigner';
import AssignDesignerModal from '../AssignDesigner/AssignDesignerModal';

const { confirm } = Modal;
const { TextArea } = Input;
const { Text } = Typography;

type ManageDesignRequestResultProps = {
  request: DesignRequestsFormattedData;
  isAwaitingApproval?: boolean;
};

const ManageDesignRequestResult: React.FC<ManageDesignRequestResultProps> = ({
  request,
  isAwaitingApproval = false,
}) => {
  const [approveByManager, { loading: reviewLoading }] = useMutation<
    ApproveByManager,
    ApproveByManagerVariables
  >(APPROVE_BY_MANAGER);
  const [acceptMerchDesign, { loading: approveLoading }] = useMutation<
    AcceptMerchDesign,
    AcceptMerchDesignVariables
  >(ACCEPT_MERCH_DESIGN);
  const [rejectAndReassignDesign, { loading: rejectAndReassignDesignLoading }] =
    useMutation<RejectAndReassignDesign, RejectAndReassignDesignVariables>(
      REJECT_AND_REASSIGN_DESIGN
    );
  const [
    rejectAndSendBackMerchDesign,
    { loading: rejectAndSendBackMerchDesignLoading },
  ] = useMutation<
    RejectAndSendBackMerchDesign,
    RejectAndSendBackMerchDesignVariables
  >(REJECT_AND_SEND_BACK_MERCH_DESIGN);

  const [isRejectModalVisible, setRejectModalVisible] =
    useState<boolean>(false);
  const [isRejectAndReassignModalVisible, setRejectAndReassignModalVisible] =
    useState<boolean>(false);
  const [isAssignModalVisible, setAssignModalVisible] =
    useState<boolean>(false);

  const [rejectReason, setRejectReason] = useState<string | undefined>(
    undefined
  );

  const [assignedDesigner, setAssignedDesigner] = useState<string | null>(null);

  const handleShowRejectModal = () => {
    setRejectModalVisible(true);
  };

  const handleShowRejectAndReassignModal = (designerId: string | null) => {
    console.log(designerId);
    setAssignedDesigner(designerId);
    setRejectAndReassignModalVisible(true);
  };

  const handleHideRejectModal = () => {
    setRejectModalVisible(false);
    setRejectReason(undefined);
  };

  const handleHideRejectAndReassigModal = () => {
    setRejectAndReassignModalVisible(false);
    setRejectReason(undefined);
  };

  const onRejectReasonChange = ({
    target: { value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setRejectReason(value);
  };

  const onApproveDesignButtonClick = () => {
    confirm({
      title: `Are You sure You want to approve design from ${
        request?.designer?.firstName || ''
      } ${request?.designer?.lastName || ''}?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Approve',
      okType: 'primary',
      cancelText: 'Cancel',
      onOk: handleApproveDesign,
    });
  };

  // this method rejects design and sends it back to designer
  const handleRejectAndSendBackMerchDesign = async () => {
    if (rejectReason?.length && request?.id) {
      try {
        await rejectAndSendBackMerchDesign({
          variables: {
            input: { designRequestId: request?.id, feedback: rejectReason },
          },
          refetchQueries: [
            {
              query: DESIGN_REQUESTS,
              variables: { input: {} },
            },
          ],
        });

        successNotification(
          `Design request #${request?.id} has been rejected successfully`
        );
        handleHideRejectModal();
      } catch (err) {
        errorNotification((err as Error)?.message);
        console.error(err);
      }
    }
  };
  // this method rejects design and reassigns it to another designer
  const handleRejectAndReassignDesign = async () => {
    setRejectAndReassignModalVisible(true);
    if (rejectReason?.length && request?.id) {
      if (assignedDesigner) {
        try {
          await rejectAndReassignDesign({
            variables: {
              input: {
                designRequestId: request?.id,
                feedback: rejectReason,
                newDesignerId: assignedDesigner,
              },
            },
            refetchQueries: [
              {
                query: DESIGN_REQUESTS,
                variables: { input: {} },
              },
            ],
          });

          successNotification(
            `Design request #${request?.id} has been rejected successfully`
          );
          handleHideRejectModal();
        } catch (err) {
          errorNotification((err as Error)?.message);
          console.error(err);
        }
      } else {
        errorNotification('Please choose designer to reassign design request');
      }
    }
  };

  const handleApproveDesign = async () => {
    if (request?.id) {
      try {
        if (!isAwaitingApproval) {
          await approveByManager({
            variables: { input: { designRequestId: request?.id } },
            refetchQueries: [
              {
                query: DESIGN_REQUESTS,
                variables: { input: {} },
              },
            ],
          });
        } else {
          await acceptMerchDesign({
            variables: { input: { designRequestId: request?.id } },
            refetchQueries: [
              {
                query: DESIGN_REQUESTS,
                variables: { input: { designRequestId: request?.id } },
              },
            ],
          });
        }

        successNotification(
          `Design request #${request?.id} by designer ${request?.designer?.firstName} ${request?.designer?.lastName} has been approved successfully`
        );
      } catch (err) {
        errorNotification((err as Error)?.message);
        console.error(err);
      }
    }
  };

  const handleSetAssignModalVisibility = (isVisible: boolean) => {
    setAssignModalVisible(isVisible);
  };

  return (
    <>
      <Space direction="vertical" size="middle" align="center">
        <Button type="primary" danger onClick={handleShowRejectModal}>
          Reject and send back
        </Button>

        <Button
          type="primary"
          danger
          onClick={() => setAssignModalVisible(true)}
        >
          Reject and reassign design
        </Button>

        <Button
          type="primary"
          onClick={onApproveDesignButtonClick}
          loading={reviewLoading || approveLoading}
        >
          Approve
        </Button>

        {!isAwaitingApproval && (
          <AssignDesigner
            designRequestId={request?.id || ''}
            buttonTitle="Re-assign"
            buttonType="default"
          />
        )}
      </Space>

      <Modal
        title="Reject design request"
        visible={isRejectModalVisible}
        okText="Reject"
        onOk={handleRejectAndSendBackMerchDesign}
        okButtonProps={{ disabled: !rejectReason }}
        onCancel={handleHideRejectModal}
        confirmLoading={rejectAndSendBackMerchDesignLoading}
      >
        <Row>
          <Col style={{ marginBottom: '16px' }}>
            <Text>
              Reject request result by designer{' '}
              <strong>
                {`${request?.designer?.firstName || ''} ${
                  request?.designer?.lastName || ''
                }`}
              </strong>{' '}
              for request <strong>{`#${request?.id}`}</strong>
            </Text>
          </Col>
          <Col style={{ display: 'flex', width: '100%' }}>
            <Image
              width={100}
              src={
                request.requestDetails.firstImageLink ||
                request.requestDetails.link
              }
              fallback={FALLBACK_IMAGE}
            />
            <TextArea
              style={{ width: '100%', marginLeft: '8px' }}
              value={rejectReason}
              onChange={onRejectReasonChange}
              placeholder="Type design reject reason"
              allowClear
            />
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Reject design request"
        visible={isRejectAndReassignModalVisible}
        okText="Reject"
        onOk={handleRejectAndReassignDesign}
        okButtonProps={{ disabled: !rejectReason }}
        onCancel={handleHideRejectAndReassigModal}
        confirmLoading={rejectAndReassignDesignLoading}
      >
        <Row>
          <Col style={{ marginBottom: '16px' }}>
            <Text>
              Reject request result by designer{' '}
              <strong>
                {`${request?.designer?.firstName || ''} ${
                  request?.designer?.lastName || ''
                }`}
              </strong>{' '}
              for request <strong>{`#${request?.id}`}</strong>
            </Text>
          </Col>
          <Col style={{ display: 'flex', width: '100%' }}>
            <Image
              width={100}
              src={
                request.requestDetails.firstImageLink ||
                request.requestDetails.link
              }
              fallback={FALLBACK_IMAGE}
            />
            <TextArea
              style={{ width: '100%', marginLeft: '8px' }}
              value={rejectReason}
              onChange={onRejectReasonChange}
              placeholder="Type design reject reason"
              allowClear
            />
          </Col>
        </Row>
      </Modal>

      <AssignDesignerModal
        isModalVisible={isAssignModalVisible}
        setModalVisible={handleSetAssignModalVisibility}
        handleAssignDesignerId={handleShowRejectAndReassignModal}
        isFromRejectAndReassignModal
      />
    </>
  );
};

export default ManageDesignRequestResult;
