import { gql } from '@apollo/client';

export const GET_ALL_PSYCHOSOCIAL_KEYWORDS = gql`
  query GetAllUserPsychosocialKeywords(
    $input: PsychosocialKeywordFilterInput!
  ) {
    getAllUserPsychosocialKeywords(input: $input) {
      entities {
        id
        name
        storeCount
      }
      limit
      offset
      total
    }
  }
`;
