import { gql } from '@apollo/client';
import { ThankYouVideoFields } from './fragments';

export const GET_THANK_YOU_VIDEO_TEMPLATE = gql`
  query GetThankYouVideoTemplate($input: GetThankYouVideoTemplateInput) {
    getThankYouVideoTemplate(input: $input) {
      ...ThankYouVideoFields
    }
  }
  ${ThankYouVideoFields}
`;
