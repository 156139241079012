import React from 'react';
import { Space, Typography, Button, Dropdown, Menu } from 'antd';
import { useMutation } from '@apollo/client';
// Api
import { ADMIN_SET_PRODUCT_SUBORDER_SHIPPED } from 'api/merch/mutations';
// Types
import {
  GetAnyProductSales_getAnyProductSales_entities_order,
  GetAnyProductSales_getAnyProductSales_entities_order_subOrders,
} from 'api/memorabilia/types/GetAnyProductSales';
import {
  AdminSetProductSuborderShipped,
  AdminSetProductSuborderShippedVariables,
} from 'api/merch/types/AdminSetProductSuborderShipped';
import {
  MemorabiliaFulfillment,
  MerchOrderStatus,
} from 'api/graphql-global-types';
// Helpers
import { formatPrice } from 'components/common/ManageMerch/helpers';
import { formatCurrencyString } from 'helpers/utils';
import {
  getCommonAddressString,
  getDropDownItemName,
} from 'components/common/Sales/ProductSales/helpers';

import styles from './ProductSalesDetails.module.scss';

const { Text, Title, Link } = Typography;

type ProductSalesDetailsProps = {
  data: GetAnyProductSales_getAnyProductSales_entities_order;
  onUpdateShipmentStatus?: () => void;
};

const ProductSalesDetails = ({
  data,
  onUpdateShipmentStatus,
}: ProductSalesDetailsProps): JSX.Element => {
  const suborders = data?.subOrders;

  const customer = data?.customer;

  const [adminSetProductSuborderShipped, { loading }] = useMutation<
    AdminSetProductSuborderShipped,
    AdminSetProductSuborderShippedVariables
  >(ADMIN_SET_PRODUCT_SUBORDER_SHIPPED);

  const handleOnConfirmShipmentClick = async (suborderId: string) => {
    await adminSetProductSuborderShipped({
      variables: {
        input: {
          suborderId,
        },
      },
    });

    if (onUpdateShipmentStatus) {
      onUpdateShipmentStatus();
    }
  };

  const getMenuOptions = (
    item: GetAnyProductSales_getAnyProductSales_entities_order_subOrders
  ) => {
    const menuOptions = (
      <Menu>
        {item?.printfulShipment?.labels?.map(({ category, url }) => {
          const dropdownItem = getDropDownItemName(category);
          return (
            <Menu.Item key={category}>
              <Link
                href={url}
                title="Tracking url"
                target="_blank"
                className={styles.menuLink}
              >
                {dropdownItem}
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
    );
    return menuOptions;
  };

  return (
    <div className={styles.root}>
      <div>
        {suborders?.map((item) => {
          return item.merchOrderItems.map((merchOrderItem) => {
            const mainImage = merchOrderItem.memorabilia?.images?.find(
              (img) => img.isMainImage
            )?.url;

            const mainCustomImage =
              merchOrderItem.merchProductVariant?.customImages?.find(
                (img) => img.isMainImage
              )?.customMerchImageURL;

            return (
              <div key={merchOrderItem.id}>
                <div className={styles.leftSide}>
                  <div>
                    {merchOrderItem.memorabilia ? (
                      <img
                        className={styles.image}
                        width={250}
                        src={mainImage || ''}
                        alt={merchOrderItem.memorabilia.title}
                      />
                    ) : (
                      <img
                        className={styles.image}
                        width={250}
                        src={
                          mainCustomImage ||
                          merchOrderItem.merchProductVariant?.customImages?.[0]
                            ?.customMerchImageURL ||
                          merchOrderItem.merchProductVariant?.mockupImageURL ||
                          ''
                        }
                        alt={merchOrderItem.merchProduct?.title || ''}
                      />
                    )}
                  </div>
                  <Space direction="vertical">
                    <Text>
                      {`Item type: ${
                        merchOrderItem.memorabilia
                          ? merchOrderItem.memorabilia.memorabiliaProductType
                          : 'Merch'
                      }`}
                    </Text>

                    <Text>
                      {merchOrderItem.memorabilia
                        ? merchOrderItem.memorabilia.title
                        : merchOrderItem.merchProduct?.title}
                    </Text>

                    {merchOrderItem.memorabilia && (
                      <Text>
                        Fulfillment: {merchOrderItem.memorabilia.fulfillment}
                      </Text>
                    )}

                    <Text>
                      Price: {formatCurrencyString(merchOrderItem.price)}
                    </Text>

                    <Text>{`Quantity: ${merchOrderItem.amount}`}</Text>

                    {item.printfulShipment?.trackingUrl && (
                      <Link
                        href={item.printfulShipment.trackingUrl}
                        title="Tracking url"
                        target="_blank"
                      >
                        Track my shipment
                      </Link>
                    )}

                    {item.merchOrderItems[0]?.memorabilia &&
                      item.printfulShipment?.labels?.length && (
                        <>
                          <Dropdown
                            trigger={['click']}
                            placement="bottomCenter"
                            overlay={getMenuOptions(item)}
                          >
                            <Button aria-label="show the available actions">
                              Select Shipping Document To Download
                            </Button>
                          </Dropdown>
                        </>
                      )}
                    <Text>{`Status: ${item.status}`}</Text>

                    {item.fulfillment === MemorabiliaFulfillment.Athlete &&
                      item.status === MerchOrderStatus.Pending && (
                        <div>
                          <Button
                            type="primary"
                            loading={loading}
                            onClick={() =>
                              handleOnConfirmShipmentClick(item.id)
                            }
                          >
                            Confirm Shipment
                          </Button>
                        </div>
                      )}
                  </Space>
                </div>
              </div>
            );
          });
        })}
      </div>
      <div>
        <Title>Address</Title>

        <Space direction="vertical">
          <Text>{`${customer?.firstName} ${customer?.lastName}` || ''}</Text>
          <Text>{getCommonAddressString(data?.subOrders[0])}</Text>
        </Space>

        <Title>Payment</Title>

        <Space direction="vertical">
          <Text>{`Payment Method Type: ${data?.paymentMethod?.type}`}</Text>
          <Text>{`Last 4: ${data?.paymentMethod?.last4}`}</Text>
        </Space>

        <Title>Summary</Title>

        <Space direction="vertical">
          <Text>{`Subtotal: ${formatPrice(data?.subtotal)}`}</Text>
          <Text>{`Total: ${formatPrice(data?.total)}`}</Text>
        </Space>
      </div>
    </div>
  );
};

export default ProductSalesDetails;
