import { CropArea } from 'components/common/CropVideo/CropVideo';
import { isFirefox, isChrome, isMobile, isSafari } from 'react-device-detect';

export const convertToFile = (val: Blob | File, title?: string): File =>
  new File([val], title || '');

export const getIfVideoSupportedByCropper = (videoType: string): boolean =>
  !(
    videoType === 'video/avi' ||
    videoType === 'video/x-ms-wmv' ||
    videoType === 'video/x-msvideo'
  );

export const getIfVideoSupportedByBrowser = (
  file: File | Blob | undefined | null
): boolean => {
  const videoType = file?.type || '';

  const chromeDesktopIsSupported =
    isChrome &&
    (videoType.includes('video/mp4') ||
      videoType.includes('video/x-m4v') ||
      videoType.includes('video/webm') ||
      videoType.includes('video/quicktime'));

  const chromeMobileIsSupported =
    isChrome &&
    (videoType.includes('video/mp4') ||
      videoType.includes('video/x-m4v') ||
      videoType.includes('video/quicktime'));

  const safariDesktopIsSupported =
    isSafari &&
    (videoType.includes('video/mp4') ||
      videoType.includes('video/x-m4v') ||
      videoType.includes('video/quicktime'));

  const safariMobileIsSupported = isSafari && false;

  const firefoxDesktopIsSupported =
    isFirefox &&
    (videoType.includes('video/mp4') ||
      videoType.includes('video/x-m4v') ||
      videoType.includes('video/webm'));

  const firefoxMobileIsSupported =
    isFirefox &&
    (videoType.includes('video/mp4') ||
      videoType.includes('video/x-m4v') ||
      videoType.includes('video/quicktime'));

  return Boolean(
    isMobile
      ? chromeMobileIsSupported ||
          safariMobileIsSupported ||
          firefoxMobileIsSupported
      : chromeDesktopIsSupported ||
          safariDesktopIsSupported ||
          firefoxDesktopIsSupported
  );
};

export const getCropArea = (
  originalWidth: number,
  originalHeight: number,
  cropAspect: number
): CropArea => {
  let x = 0;
  let y = 0;
  let width = originalWidth;
  let height = originalHeight;

  if (originalWidth < originalHeight) {
    width = originalHeight * cropAspect;
    x = (originalWidth - width) / 2;
  }

  if (originalWidth > originalHeight) {
    height = originalWidth * cropAspect;
    y = (originalHeight - height) / 2;
  }

  const getEvenNumber = (val: number): number => (val ? val - (val % 2) : 0);

  const cropArea: CropArea = {
    x: getEvenNumber(x),
    y: getEvenNumber(y),
    width: getEvenNumber(width),
    height: getEvenNumber(height),
    zoom: 1,
  };

  return cropArea;
};

export type VideoDimensions = {
  width: number;
  height: number;
};

export const getVideoDimensionsOf = (
  url: string
): Promise<VideoDimensions | null> => {
  return new Promise((resolve) => {
    const video = document.createElement('video');

    video.addEventListener(
      'loadedmetadata',
      function () {
        const height = this.videoHeight;
        const width = this.videoWidth;
        resolve(height && width ? { height, width } : null);
      },
      false
    );

    video.src = url;
  });
};
