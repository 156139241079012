import React, { useState } from 'react';
import moment from 'moment';
import { Modal, Button, Typography, Image } from 'antd';
// Types
import { DesignRequests_designRequests_entities_rejections } from 'api/designLab/types/DesignRequests';
import { Rejections_designImages } from 'api/designLab/types/Rejections';
// Constants
import { FALLBACK_IMAGE } from 'constants/global';
// UI
import Table from 'ui/Table';

const { Text } = Typography;

type RequestResultRejectsProps = {
  data: DesignRequests_designRequests_entities_rejections[];
};

const RequestResultRejects: React.FC<RequestResultRejectsProps> = ({
  data,
}) => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const handleShowModal = () => {
    setModalVisible(true);
  };

  const handleHideModal = () => {
    setModalVisible(false);
  };

  const columns = [
    {
      title: 'Design',
      dataIndex: 'designImages',
      align: 'center' as const,
      width: 50,
      render: function RequestDetails(designImages: Rejections_designImages[]) {
        return (
          <Image
            width={50}
            src={designImages[0].imageFileURL || ''}
            fallback={FALLBACK_IMAGE}
          />
        );
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      align: 'center' as const,
      width: 100,
      render: (createdAt: string) => moment(createdAt).format('ll'),
    },
    {
      title: 'Comment',
      dataIndex: 'reason',
      align: 'left' as const,
      width: 280,
    },
  ];

  if (!data || !data?.length) {
    return <Text>-</Text>;
  }

  return (
    <>
      <Button type="link" onClick={handleShowModal}>
        Show comments
      </Button>

      <Modal
        title="Design request comments"
        visible={isModalVisible}
        footer={null}
        onCancel={handleHideModal}
        width={570}
      >
        <Table<DesignRequests_designRequests_entities_rejections>
          columns={columns}
          data={data}
          scroll={{ x: 430 }}
          total={data.length}
          defaultPageSize={5}
        />
      </Modal>
    </>
  );
};

export default RequestResultRejects;
