import React, { useState } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Modal, Button } from 'antd';
// Constants
import { onlyDateTimeFormat } from 'constants/global';
// Api
import { GET_STREAM_SALES_BY_STORE } from 'api/streams/queries';
// Types
import {
  GetStreamSales,
  GetStreamSalesVariables,
  GetStreamSales_getStreamSales_entities_stream,
  GetStreamSales_getStreamSales_entities_thankYouVideo,
} from 'api/streams/types/GetStreamSales';
import {
  ProductTypesType,
  SortDirection,
  StreamSalesOrderBy,
  StreamStatus,
} from 'api/graphql-global-types';
// Helpers
import { formatCurrencyString } from 'helpers/utils';
// Ui
import Table, {
  DEFAULT_NUMBER_ITEMS_PER_PAGE,
  DEFAULT_CURRENT_PAGE,
  SortedInfo,
  TableFilter,
} from 'ui/Table';
// Components
import StoreSalesActions from '../components/StoreSalesActions';
import StreamSalesDetails from './components/StreamSalesDetails';

type StreamSalesProps = {
  onVideoView: (videoUrl: string) => void;
};

type StreamSalesItem = {
  name: string;
  scheduleDate: string;
  orderAmount: number;
  requestedProfit: string;
  id: string;
  stream: GetStreamSales_getStreamSales_entities_stream;
  thankYouVideo: GetStreamSales_getStreamSales_entities_thankYouVideo | null;
};

const StreamSales: React.FC<StreamSalesProps> = ({ onVideoView }) => {
  const [streamDetailsModal, setStreamDetailsModal] =
    useState<StreamSalesItem | null>();
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_CURRENT_PAGE);
  const [pageSize, setPageSize] = useState<number>(
    DEFAULT_NUMBER_ITEMS_PER_PAGE
  );

  const { storeId } = useParams<{ storeId: string | undefined }>();

  const defaultSortInfo: SortedInfo<StreamSalesOrderBy> = {
    order: SortDirection.DESC,
    key: StreamSalesOrderBy.ScheduleDate,
  };

  const [sort, setSortInfo] =
    useState<SortedInfo<StreamSalesOrderBy>>(defaultSortInfo);
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const getQueryVariables: any = () => {
    const input: any = {
      storeId,
      orderBy: sort.key,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      limit: pageSize,
      direction: sort.order,
    };

    searchFilters?.forEach(({ key, value }) => {
      if (key === 'ScheduleDate') {
        return (input['scheduledDateRange'] = {
          from: Array.isArray(value) ? value?.[0] : value,
          to: Array.isArray(value) ? value?.[1] : value,
        });
      }

      if (key === 'Name') {
        return (input['name'] = value);
      }

      if (key === 'type') {
        return (input['types'] = value);
      }
      input[key] = value;
    });

    return { input };
  };

  const { data, loading } = useQuery<GetStreamSales, GetStreamSalesVariables>(
    GET_STREAM_SALES_BY_STORE,
    {
      variables: { ...getQueryVariables() },
      fetchPolicy: 'cache-and-network',
    }
  );

  const handleCloseModal = () => {
    setStreamDetailsModal(null);
  };

  const tableData: StreamSalesItem[] =
    data?.getStreamSales?.entities?.map((streamItem) => {
      const { orderAmount, requestedProfit, stream, thankYouVideo } =
        streamItem;
      return {
        name: stream.name || '',
        scheduleDate: moment(stream.scheduleDate).format(onlyDateTimeFormat),
        orderAmount: orderAmount,
        requestedProfit: formatCurrencyString(requestedProfit),
        id: stream.id,
        stream: stream,
        thankYouVideo: thankYouVideo,
        streamItem: streamItem,
      };
    }) || [];

  const columns = [
    {
      title: 'Stream title',
      dataIndex: 'name',
      key: StreamSalesOrderBy.Name,
      fixed: 'left' as const,
      width: 140,
      sorterType: 'text',
      withSearch: true,
    },
    {
      title: 'Stream date',
      dataIndex: 'scheduleDate',
      key: StreamSalesOrderBy.ScheduleDate,
      width: 110,
      sorterType: 'date',
      withDateRangeFilter: true,
    },
    {
      title: 'Users',
      dataIndex: 'orderAmount',
      key: StreamSalesOrderBy.OrderCnt,
      width: 50,
      align: 'center' as const,
      sorterType: 'number',
    },
    {
      title: 'Earnings',
      dataIndex: 'requestedProfit',
      align: 'right' as const,
      key: StreamSalesOrderBy.Earnings,
      width: 60,
      sorterType: 'number',
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      align: 'center' as const,
      fixed: 'right' as const,
      width: 30,
      render: function Actions(rowId: string, rowData: StreamSalesItem) {
        return (
          <>
            <Button
              onClick={() => setStreamDetailsModal(rowData)}
              style={{
                marginBottom: '10px',
              }}
            >
              Details
            </Button>

            {rowData.stream.streamStatus === StreamStatus.Ended && (
              <StoreSalesActions
                orderId={rowId}
                thankYouVideo={rowData.thankYouVideo}
                onVideoView={onVideoView}
                productType={ProductTypesType.Stream}
              />
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table<StreamSalesItem, StreamSalesOrderBy>
        columns={columns}
        data={tableData}
        scroll={{ x: 1200 }}
        loading={loading}
        total={data?.getStreamSales?.total}
        defaultPageSize={pageSize}
        defaultCurrent={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        rowKey={(item) => item.stream.id}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />
      <Modal
        visible={!!streamDetailsModal}
        onCancel={handleCloseModal}
        title={streamDetailsModal?.stream.name}
        footer={null}
        width={'80vw'}
      >
        <StreamSalesDetails
          streamId={streamDetailsModal?.stream.id}
          storeId={storeId}
        />
      </Modal>
    </>
  );
};

export default StreamSales;
