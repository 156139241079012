import React from 'react';
// Types
import { LegendProps } from 'recharts';

import styles from './CustomLegend.module.scss';

interface LegendPayload {
  value: any;
  color?: string;
}

type CustomLegendProps = LegendProps<number | string, number | string> & {
  payload?: LegendPayload[];
};

export const CustomLegend: React.FC<CustomLegendProps> = ({ payload }) => {
  return (
    <ul className={styles.legendWrapper}>
      {payload?.map((entry) => (
        <li className={styles.legendItem} key={`item-${entry.value}`}>
          <i
            className={styles.legendItemIcon}
            style={{ backgroundColor: entry.color }}
          />
          {entry.value}
        </li>
      ))}
    </ul>
  );
};
