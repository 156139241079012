import React from 'react';
import { Space } from 'antd';
// Types
import { DesignTypes_designTypes } from 'api/designLab/types/DesignTypes';
import { DLStateValues } from 'components/common/ManageDesigns/components/DLConstructor/DLConstructor';
// Components
import CardItem from '../CardItem/CardItem';
// Styles
import styles from './TypesOfLogoForm.module.scss';

type TypesOfLogoFormProps = {
  onChange: (values: DLStateValues) => void;
  options: DesignTypes_designTypes[];
  selectedValues: number[];
};

const TypesOfLogoForm = ({
  options,
  selectedValues,
  onChange,
}: TypesOfLogoFormProps): JSX.Element => {
  const isCheckedMoreThanTree = selectedValues.length >= 3;

  const handleCheckboxChange = (id: number) => {
    const updated = selectedValues.some((item) => item === id)
      ? selectedValues.filter((item) => item !== id)
      : selectedValues.concat(id);

    onChange({
      typesOfLogo: updated,
    });
  };

  return (
    <Space className={styles.root} direction="horizontal" align="start" wrap>
      {options.map(({ id, name, posterImage, description }) => {
        const isSelected = selectedValues.some((val) => val === id);
        const isAvailable = !isCheckedMoreThanTree || isSelected;

        const handleChange = () => {
          if (isAvailable) {
            handleCheckboxChange(id);
          }
        };

        return (
          <CardItem
            key={`${name}-${id}`}
            title={name}
            isAvailable={isAvailable}
            isSelected={isSelected}
            onChange={handleChange}
            imageSrc={posterImage}
            imageAlt={description}
            description={description}
          />
        );
      })}
    </Space>
  );
};

export default TypesOfLogoForm;
