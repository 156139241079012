import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import moment, { Moment } from 'moment';
import { Typography, Button, Space, DatePicker } from 'antd';
import { CSVLink } from 'react-csv';
// Api
import { GET_STREAM_SALES_REPORT } from 'api/streams/queries';
// Types
import { RangePickerProps } from 'antd/es/date-picker';
import { StreamOrderBy } from 'api/graphql-global-types';
import {
  GetStreamSalesReport,
  GetStreamSalesReportVariables,
} from 'api/streams/types/GetStreamSalesReport';
import { GetStreamSalesReport_getStreamSalesReport_entities } from 'api/streams/types/GetStreamSalesReport';
// Helpers
import { formatCurrencyString } from 'helpers/utils';
// UI
import Table from 'ui/Table';
import { successNotification } from 'ui/Notification';
// Styles
import styles from './StreamSalesReportTable.module.scss';

type SalesReportItem = GetStreamSalesReport_getStreamSalesReport_entities & {
  storeName: string;
};

const { RangePicker } = DatePicker;
const { Text } = Typography;

const defaultStartDate = moment()
  .subtract(1, 'months')
  .startOf('day')
  .utc(true);

const defaultEndDate = moment().endOf('day').utc(true);

const StreamSalesReportTable = (): JSX.Element => {
  const [startDate, setStartDate] = useState<Moment>(defaultStartDate);
  const [endDate, setEndDate] = useState<Moment>(defaultEndDate);
  const [datePickerStartDate, setDatePickerStartDate] = useState<Moment | null>(
    startDate
  );
  const [datePickerEndDate, setDatePickerEndDate] = useState<Moment | null>(
    endDate
  );

  const { data, loading } = useQuery<
    GetStreamSalesReport,
    GetStreamSalesReportVariables
  >(GET_STREAM_SALES_REPORT, {
    variables: {
      input: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const onDateRangeChange = (start: Moment, end: Moment) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleDateRangeChange: RangePickerProps['onChange'] = (dates) => {
    if (!dates) return null;

    const [start, end] = dates;

    setDatePickerStartDate(start);
    setDatePickerEndDate(end);

    if (start && end) {
      onDateRangeChange(
        moment.parseZone(start).utc(true).startOf('day'),
        moment.parseZone(end).utc(true).endOf('day')
      );
    }
  };

  const onCSVButtonClick = () => {
    successNotification('File downloading');
  };

  const salesReportList: SalesReportItem[] =
    data?.getStreamSalesReport?.entities?.map((item) => ({
      ...item,
      storeName: `${item?.firstName || ''} ${item?.lastName || ''} `,
    })) || [];

  const columns = [
    {
      title: 'Store name',
      dataIndex: 'storeName',
      key: 'storeName',
      fixed: 'left' as const,
      align: 'center' as const,
      width: 160,
    },
    {
      title: 'Sport',
      dataIndex: 'sports',
      key: 'sports',
      fixed: 'left' as const,
      align: 'center' as const,
      width: 160,
      render: function Sports(sports: string[]) {
        return sports.join(', ');
      },
    },
    {
      title: 'Total Revenue',
      dataIndex: 'totalRevenue',
      key: 'totalRevenue',
      fixed: 'left' as const,
      align: 'center' as const,
      width: 160,
      render: (totalRevenue: number) => {
        return formatCurrencyString(totalRevenue);
      },
    },
  ];

  const filename = `Stream_sales_report_from_${moment(startDate).format(
    'YYYY-MMM-DD'
  )}_to${moment(endDate).format('YYYY-MMM-DD')}.csv`;

  const getMaxDate = (current: Moment) =>
    moment().diff(current, 'days', true) <= 0;

  return (
    <>
      <Space
        direction="horizontal"
        align="baseline"
        className={styles.spaceWrapper}
      >
        <div>
          <Text>Select date:</Text>
          <RangePicker
            defaultValue={[datePickerStartDate, datePickerEndDate]}
            disabledDate={getMaxDate}
            onChange={handleDateRangeChange}
          />
        </div>
        <Button type="primary">
          <CSVLink
            filename={filename}
            data={salesReportList}
            className="btn btn-primary"
            onClick={onCSVButtonClick}
          >
            Export to CSV
          </CSVLink>
        </Button>
      </Space>

      <Table<SalesReportItem, StreamOrderBy>
        columns={columns}
        data={salesReportList}
        rowKey={(item) => item.storeId}
        scroll={{ x: 1024 }}
        loading={loading}
        total={data?.getStreamSalesReport?.entities?.length}
      />
    </>
  );
};

export default StreamSalesReportTable;
