import React, { FC } from 'react';
import { Spin, Button } from 'antd';
// Types
import { MerchType } from 'api/graphql-global-types';
import { GetUnmergedMerch_getUnmergedMerch_entities } from 'api/merch/types/GetUnmergedMerch';
// Helpers
import {
  GetProductColors,
  computeMerchBackgroundColor,
} from 'components/common/ManageMerch/helpers';
import { listGenders } from 'helpers/mergeMerch';
import { getEnvLink } from 'helpers/getEnvLink';
// Config
import ENV from 'api/env';
// Styles
import styles from './MergeMerchChild.module.scss';

interface MergeMerchChildProp {
  childMerch: GetUnmergedMerch_getUnmergedMerch_entities;
  childMerchColors: GetProductColors;
  childMerchImages: any;
  loading: boolean;
}

const MergeMerchChild: FC<MergeMerchChildProp> = ({
  childMerch,
  childMerchColors,
  childMerchImages,
  loading,
}) => {
  const childGenders = childMerch.genders?.length
    ? listGenders(childMerch.genders, childMerch.type === MerchType.TShirt)
    : '';
  return (
    <>
      {loading ? (
        <Spin size="large" />
      ) : (
        <div className={styles.merchChildRoot}>
          <div className={styles.merchImages}>
            {(childMerchImages as string[]).map((item, index) => (
              <div key={index} className={styles.imageWrapper}>
                <img className={styles.photo} src={item || ''} alt="" />
              </div>
            ))}
          </div>
          <div className={styles.merchInformation}>
            <p>Merch title: {childMerch?.title || ''}</p>
            <p>Gender: {childGenders}</p>
            <div className={styles.colors}>
              Colors:
              {childMerchColors.map((item) => {
                return (
                  <span
                    key={item.color}
                    className={styles.colorItem}
                    style={computeMerchBackgroundColor(
                      item.colorCode,
                      item.colorCode2
                    )}
                  />
                );
              })}
            </div>
            <p>Request profit: {childMerch?.requestedProfit} $</p>
            <p>
              <a
                href={`${getEnvLink(ENV.REACT_APP_ENV)}/${
                  childMerch?.store?.slug
                }/merch/${childMerch?.slug}`}
              >
                View Merch
              </a>
            </p>
          </div>
          <Button
            type="primary"
            href="/manual-merch-merge"
            className={styles.backButton}
          >
            Back
          </Button>
        </div>
      )}
    </>
  );
};

export default MergeMerchChild;
