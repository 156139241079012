import { gql } from '@apollo/client';
import { UserListRequestFields } from './fragments';

export const GET_USERS = gql`
  query Users($input: UsersFilterInput) {
    users(input: $input) {
      ...UserListRequestFields
    }
  }
  ${UserListRequestFields}
`;

export const GET_USERS_LIST = gql`
  query GetUsersList($input: UsersFilterInput) {
    users(input: $input) {
      entities {
        id
        firstName
        lastName
        createdAt
        email
        phoneNumber
        hasAma
        hasStreams
        hasMerch
        sports {
          id
          name
        }
        storeDetails {
          id
          storeName
          gymRole
          socialMediaLink
        }
        purchaseStats {
          storeRevenue
          customerRevenue
          customerTotalCount
        }
        status
      }
      total
      offset
    }
  }
`;

export const GET_COUNTRY_LIST = gql`
  query GetCountryList {
    getCountryList {
      id
      name
      code
      states {
        name
        code
      }
    }
  }
`;
