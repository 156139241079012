/*"no-warning-comments": ["error", { "allowIfContainsDescription": "todo"}]*/
const DEVELOPMENT = 'development';
const STAGING = 'staging';
const PRODUCTION = 'production';

// TODO: refactor hotfix
const ENV = {
  [DEVELOPMENT]: {
    REACT_APP_ENV: process.env.REACT_APP_ENV || DEVELOPMENT,
    PUBLIC_API_HOST:
      process.env.REACT_APP_API_URL || 'https://api.stg1.millions.co/graphql',
    REACT_APP_URL: 'http://localhost:3030',
    MEDIA_LAMBDA_URL:
      'https://64fi4zqp2t3bxh6ovyla6ipfcm0jilgt.lambda-url.us-east-1.on.aws/',
    REACT_APP_WEB_PORTAL_URL: 'http://localhost:4000',
    MESSAGING_API_HOST: 'https://messaging.stg1.millions.co/graphql',
    CAMPAIGN_API_HOST: 'https://campaign-api.stg1.millions.co/graphql',
    CAMPAIGN_APP_URL: 'http://localhost:4200',
    NOTIFICATION_API_HOST: 'https://notification-api.stg1.millions.co/graphql',
  },
  [STAGING]: {
    REACT_APP_ENV: process.env.REACT_APP_ENV || STAGING,
    PUBLIC_API_HOST:
      process.env.REACT_APP_API_URL || 'https://api.stg1.millions.co/graphql',
    REACT_APP_URL: 'https://admin.stg1.millions.co/',
    MEDIA_LAMBDA_URL:
      'https://ek6f4wob6jikipd4mqpaltr7w40upgru.lambda-url.us-east-1.on.aws/',
    REACT_APP_WEB_PORTAL_URL: 'https://stg1.millions.co',
    MESSAGING_API_HOST: 'https://messaging.stg1.millions.co/graphql',
    CAMPAIGN_API_HOST: 'https://campaign-api.stg1.millions.co/graphql',
    CAMPAIGN_APP_URL: 'https://campaign.stg1.millions.co/',
    NOTIFICATION_API_HOST: 'https://notification-api.stg1.millions.co/graphql',
  },
  [PRODUCTION]: {
    REACT_APP_ENV: process.env.REACT_APP_ENV || PRODUCTION,
    PUBLIC_API_HOST:
      process.env.REACT_APP_API_URL || 'https://api.millions.co/graphql',
    REACT_APP_URL: 'https://admin.millions.co/',
    MEDIA_LAMBDA_URL:
      'https://hahdnmkglkeyscolnrtnprob4m0rjeyg.lambda-url.us-east-1.on.aws/',
    REACT_APP_WEB_PORTAL_URL: 'https://millions.co',
    MESSAGING_API_HOST: 'https://messaging.millions.co/graphql',
    CAMPAIGN_API_HOST: 'https://campaign-api.millions.co/graphql',
    CAMPAIGN_APP_URL: 'https://campaign.millions.co/',
    NOTIFICATION_API_HOST: 'https://notification-api.millions.co/graphql',
  },
};

const getEnvVariables = () => {
  if (process.env.REACT_APP_ENV === STAGING) {
    return ENV[STAGING];
  }

  if (process.env.REACT_APP_ENV === PRODUCTION) {
    return ENV[PRODUCTION];
  }

  return ENV[DEVELOPMENT];
};

export default getEnvVariables();
