import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Spin, Empty, Space, Button, Typography } from 'antd';
// Hooks
import {
  useGetUserTypeFromRoute,
  useDefaultStoreRedirectOnEmptyId,
} from 'hooks';
// Api
import { DESIGN_REQUESTS } from 'api/designLab/queries';
// Types
import { SortDirection, DesignRequestsOrderBy } from 'api/graphql-global-types';
import {
  DesignRequests,
  DesignRequestsVariables,
} from 'api/designLab/types/DesignRequests';
import { DesignRequestsFormattedData } from 'helpers/designRequests';
// Helpers
import { getDesignRequestsFormattedData } from 'helpers/designRequests';
// Components
import ManageDesignsCard from './components/ManageDesignsCard/ManageDesignsCard';
import MerchCreationModal from './components/MerchCreationModal/MerchCreationModal';
// Styles
import styles from './styles.module.scss';

const { Title } = Typography;

const ManageDesigns = (): JSX.Element => {
  useDefaultStoreRedirectOnEmptyId();

  const { isAthlete, isContentCreator, isOrganization } =
    useGetUserTypeFromRoute();

  const { storeId = '' } = useParams<{ storeId: string | undefined }>();

  const [isMerchCreationVisible, setIsMerchCreationVisible] =
    useState<boolean>(false);

  const { data, loading } = useQuery<DesignRequests, DesignRequestsVariables>(
    DESIGN_REQUESTS,
    {
      variables: {
        input: {
          storeIds: [storeId],
          orderBy: DesignRequestsOrderBy.createdAt,
          direction: SortDirection.DESC,
        },
      },
      fetchPolicy: 'cache-and-network',
      skip: !storeId.length,
    }
  );

  const handleHideMerchCreationModal = () => {
    setIsMerchCreationVisible(false);
  };

  const handleMerchCreationClick = () => {
    setIsMerchCreationVisible(true);
  };

  const formattedData: DesignRequestsFormattedData[] =
    getDesignRequestsFormattedData(data);

  if (loading) {
    return (
      <Spin spinning={loading} size="large">
        <div className="spin" />
      </Spin>
    );
  }

  const userType = isAthlete
    ? 'Athlete'
    : isOrganization
    ? 'Organization'
    : isContentCreator
    ? 'Content Creator'
    : 'User';

  return (
    <>
      <Space className={styles.header}>
        <Title level={3}>DesignLabs History</Title>
        <Button type="primary" onClick={handleMerchCreationClick}>
          Create New Merch Design
        </Button>
      </Space>

      {formattedData.length ? (
        <Space size={[16, 8]} wrap align="start" className={styles.listWrapper}>
          {formattedData.map((item) => (
            <ManageDesignsCard key={item.id} data={item} />
          ))}
        </Space>
      ) : (
        <Empty
          description={`${userType} doesn't have any design requests yet`}
        />
      )}

      <MerchCreationModal
        isVisible={isMerchCreationVisible}
        onHide={handleHideMerchCreationModal}
      />
    </>
  );
};

export default ManageDesigns;
