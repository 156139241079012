// Types
import { DesignRequestStatus } from 'api/graphql-global-types';

type ReturnType = {
  value: DesignRequestStatus;
  text: string;
};

export const getStatues = (tabKey: string): ReturnType[] => {
  let statuses = [];

  switch (tabKey) {
    case 'isUnassigned':
      statuses = [{ value: DesignRequestStatus.Todo, text: 'Todo' }];
      break;
    case 'isInProgress':
      statuses = [
        { value: DesignRequestStatus.InProgress, text: 'In Progress' },
        { value: DesignRequestStatus.OnReview, text: 'On Review' },
        {
          value: DesignRequestStatus.AwaitingApproval,
          text: 'Awaiting Approval',
        },
      ];
      break;
    case 'isOverdue':
      statuses = [
        { value: DesignRequestStatus.Todo, text: 'Todo' },
        { value: DesignRequestStatus.InProgress, text: 'In Progress' },
        { value: DesignRequestStatus.OnReview, text: 'On Review' },
        {
          value: DesignRequestStatus.AwaitingApproval,
          text: 'Awaiting Approval',
        },
        { value: DesignRequestStatus.Rejected, text: 'Rejected' },
      ];
      break;
    default:
      statuses = [
        { value: DesignRequestStatus.Archived, text: 'Archived' },
        { value: DesignRequestStatus.Todo, text: 'Todo' },
        { value: DesignRequestStatus.InProgress, text: 'In Progress' },
        { value: DesignRequestStatus.OnReview, text: 'On Review' },
        {
          value: DesignRequestStatus.AwaitingApproval,
          text: 'Awaiting Approval',
        },
        { value: DesignRequestStatus.Accepted, text: 'Accepted' },
        { value: DesignRequestStatus.AutoAccepted, text: 'Auto-Accepted' },
        { value: DesignRequestStatus.Rejected, text: 'Rejected' },
      ];
  }
  return statuses;
};
