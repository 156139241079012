import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Button, Typography, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// Api
import { DESIGNERS } from 'api/designLab/queries';
import { DEACTIVATE_DESIGNER } from 'api/designLab/mutations';
import { Designers_designers_entities } from 'api/designLab/types/Designers';
// Types
import {
  DeactivateDesigner,
  DeactivateDesignerVariables,
} from 'api/designLab/types/DeactivateDesigner';
import { DesignerStatus } from 'api/graphql-global-types';
// Components
import EditDesignerModal from './EditDesignerModal';
// UI
import { errorNotification, successNotification } from 'ui/Notification';

const { confirm } = Modal;
const { Text } = Typography;

type ActionsProps = {
  designer: Designers_designers_entities;
  getAllDesignersInput: any;
};

const Actions: React.FC<ActionsProps> = ({
  designer,
  getAllDesignersInput,
}) => {
  const [isDesignerEditing, toggleDesignerEditing] = useState<boolean>(false);

  const [deactivateDesigner, { loading: deactivateDesignerLoading }] =
    useMutation<DeactivateDesigner, DeactivateDesignerVariables>(
      DEACTIVATE_DESIGNER
    );

  const onDeactivateDesignerButtonClick = () => {
    confirm({
      title: `Are You sure You want to deactivate designer ${
        designer?.firstName || ''
      } ${designer?.lastName || ''}?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Deactivate',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: handleDeactivateDesigner,
    });
  };

  const handleDeactivateDesigner = async () => {
    try {
      await deactivateDesigner({
        variables: { id: designer.id },
        refetchQueries: [
          {
            query: DESIGNERS,
            variables: {
              ...getAllDesignersInput,
            },
          },
        ],
      });
      successNotification(
        `Designer ${designer?.firstName || ''} ${
          designer?.lastName || ''
        } has been deactivated successfully`
      );
    } catch (err) {
      errorNotification(err?.message);
      console.error(err);
    }
  };

  if (designer?.designerStatus === DesignerStatus.Inactive) {
    return <Text />;
  }

  return (
    <>
      <Space size="middle" direction="vertical">
        <Button
          type="primary"
          onClick={() => {
            toggleDesignerEditing(true);
          }}
        >
          Edit
        </Button>
        <Button
          type="primary"
          danger
          onClick={onDeactivateDesignerButtonClick}
          loading={deactivateDesignerLoading}
        >
          Deactivate
        </Button>
      </Space>
      <EditDesignerModal
        designer={designer}
        toggleDesignerEditing={toggleDesignerEditing}
        isDesignerEditing={isDesignerEditing}
      />
    </>
  );
};

export default Actions;
