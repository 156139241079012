import {
  useQuery,
  QueryResult,
  QueryOptions,
  WatchQueryFetchPolicy,
  QueryFunctionOptions,
} from '@apollo/client';
// Api
import { GET_COLLEGES_LIST } from 'api/store/queries';
// Types
import { GetCollegesList } from 'api/store/types/GetCollegesList';

type BasicQueryOptions = Omit<
  QueryOptions,
  'query' | 'variables' | 'fetchPolicy'
> & {
  fetchPolicy?: WatchQueryFetchPolicy;
} & QueryFunctionOptions;

type UseGetAthleteMerchProductsOptions = {
  options?: BasicQueryOptions;
};

export const useGetColleges = (
  options?: UseGetAthleteMerchProductsOptions
): QueryResult<GetCollegesList> => {
  const colleges = useQuery<GetCollegesList>(GET_COLLEGES_LIST, {
    ...options?.options,
  });

  return colleges;
};
