import React, { useState } from 'react';
import { Modal, Form, Typography, Input, Button, Checkbox, Select } from 'antd';
import { useMutation } from '@apollo/client';
import { notificationClient } from 'notificationClient';
import cn from 'classnames';
// Helpers
import { createPresignedUrlsAndUploadToS3 } from 'helpers/single-uploader';
// Api
import {
  CREATE_NOTIFICATION,
  CREATE_PRESIGNED_CSV_FILE,
  CREATE_PRESIGNED_NOTIFICATION_IMAGE,
  TEST_RENDER_TEMPLATE,
} from 'api/notification/mutations';
import { GET_NOTIFICATIONS } from 'api/notification/queries';
// Types
import {
  CreateSendout,
  CreateSendoutVariables,
} from 'api/notification/types/CreateSendout';
import {
  CreatePresignedCsvUpload,
  CreatePresignedCsvUploadVariables,
} from 'api/notification/types/CreatePresignedCsvUpload';
import {
  CreatePresignedNotificationImageUpload,
  CreatePresignedNotificationImageUploadVariables,
} from 'api/notification/types/CreatePresignedNotificationImageUpload';
import {
  TestRenderTemplate,
  TestRenderTemplateVariables,
} from 'api/notification/types/TestRenderTemplate';
import {
  CreateSendoutInput,
  NotificationActionEnum,
  SendoutEmailConfigInput,
} from 'api/graphql-notification-global-types';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
import UploadImageFile from 'ui/UploadImageFile/UploadImageFile';

// styles
import styles from './CreateNotificationModal.module.scss';

const { Text } = Typography;

const NOTIFICATION_TEMPLATES = [
  { label: 'First Name', value: 'firstName' },
  { label: 'Last Name', value: 'lastName' },
];

type CreateNotificationModalProps = {
  onClose: () => void;
  isVisible: boolean;
};

// could have used SendoutInAppConfigInput or SendoutPushConfigInput from 'api/graphql-notification-global-types'
// but made this type to use on two places
type InAppPushConfigProps = {
  title: string;
  body?: string | null;
  imageKey?: string | null;
  action?: NotificationActionEnum | null;
  category?: string | null;
};

type FormValues = {
  notificationTitle: string;
  description: string | null;
  analyticsLabel: string | null;
  title: string;
  body: string | null;
  action: NotificationActionEnum | null;
  category: string | null;
  subject: string;
  paragraph: string;
  buttonLabel: string | null;
  buttonLink: string | null;
};

type InputState = {
  title: string;
  body: string;
  subject: string;
  paragraph: string;
};

const CreateNotificationModal = ({
  onClose,
  isVisible,
}: CreateNotificationModalProps): JSX.Element => {
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isInAppPush, setIsInAppPush] = useState(false);
  const [isEmail, setIsEmail] = useState(false);

  const [notificationImageValidation, setNotificationImageValidation] =
    useState<string>('');
  const [emailImageValidation, setEmailImageValidation] = useState<string>('');

  const initialInputState = {
    title: '',
    body: '',
    subject: '',
    paragraph: '',
  };
  const [inputState, setInputState] = useState<InputState>(initialInputState);
  const [templateState, setTemplateState] =
    useState<InputState>(initialInputState);

  const [hasButton, setHasButton] = useState<boolean>(false);

  const notificationImage = form.getFieldValue('notificationImage');
  const emailImage = form.getFieldValue('emailImage');

  const initialFormValues: FormValues = {
    notificationTitle: '',
    description: null,
    analyticsLabel: null,
    title: '',
    body: null,
    action: null,
    category: null,
    subject: '',
    paragraph: '',
    buttonLabel: '',
    buttonLink: '',
  };

  const allowedActions = [
    'MerchAddAction',
    'ProductTemplateLatestAction',
    'ProductAddAction',
    'ChannelStartAction',
    'ShareLatestMemorabiliaAction',
  ];

  const [createPresignedCsv] = useMutation<
    CreatePresignedCsvUpload,
    CreatePresignedCsvUploadVariables
  >(CREATE_PRESIGNED_CSV_FILE, {
    client: notificationClient,
  });

  const [createPresignedNotificationImage] = useMutation<
    CreatePresignedNotificationImageUpload,
    CreatePresignedNotificationImageUploadVariables
  >(CREATE_PRESIGNED_NOTIFICATION_IMAGE, {
    client: notificationClient,
  });

  const [testRenderTemplate] = useMutation<
    TestRenderTemplate,
    TestRenderTemplateVariables
  >(TEST_RENDER_TEMPLATE, {
    client: notificationClient,
  });

  const [createNotification] = useMutation<
    CreateSendout,
    CreateSendoutVariables
  >(CREATE_NOTIFICATION, {
    refetchQueries: [
      {
        query: GET_NOTIFICATIONS,
        variables: {
          input: {
            limit: 10,
          },
        },
      },
    ],
    client: notificationClient,
  });

  const testTemplate = async (text: string) => {
    // send text to testRenderTemplate API and return the response (or an empty string)
    const template = await testRenderTemplate({
      variables: {
        input: {
          template: text,
        },
      },
    });
    return template.data?.testRenderTemplate || '';
  };

  const testTemplateProperty = async (
    text: string,
    property: keyof InputState
  ) => {
    // update the template state when testRenderTemplate returns a value
    setTemplateState({
      ...templateState,
      [property]: await testTemplate(text),
    });
  };

  const changeInput = async (text: string, property: keyof InputState) => {
    const isTitleBodyMaxed =
      (property === 'title' || property === 'body') && text.length > 700;
    const isSubjectMaxed = property === 'subject' && text.length > 1000;
    const isParagraphMaxed = property === 'paragraph' && text.length > 10000;

    // don't add text to title and body inputs if it's past 700 length, subject past 1000 and paragraph past 10k
    const shouldNotUpdate =
      isTitleBodyMaxed || isSubjectMaxed || isParagraphMaxed;
    if (!shouldNotUpdate) {
      // update the template data
      testTemplateProperty(text, property);
      // update the input data
      setInputState({
        ...inputState,
        [property]: text,
      });
      // update the form input value
      form.setFieldsValue({
        [property]: text,
      });
    }
  };

  const handleResetAndClose = () => {
    form.resetFields();
    setInputState(initialInputState);
    setTemplateState(initialInputState);
    setIsEmail(false);
    setIsInAppPush(false);
    onClose();
  };

  const handleOnSubmit = async () => {
    setIsLoading(true);
    const {
      notificationTitle,
      description,
      analyticsLabel,
      title,
      body,
      action,
      category,
      subject,
      paragraph,
      buttonLabel,
      buttonLink,
    } = form.getFieldsValue();
    try {
      const csvFile = form.getFieldValue('csvFile')[0].originFileObj;

      const presignedCsv = await createPresignedUrlsAndUploadToS3({
        files: [csvFile],
        getPresignedUrls: async () => {
          const { data } = await createPresignedCsv({
            variables: {
              input: {
                ext: 'csv',
                contentType: csvFile.type || '',
              },
            },
          });
          if (!data) {
            console.error('Error uploading csv file');
            setIsLoading(false);
            throw new Error(
              'Something went wrong with the upload of the csv file'
            );
          }

          return [data.createPresignedCsvUpload];
        },
      });

      const inAppPushConfig: InAppPushConfigProps = {
        title,
        ...(body && { body }),
        ...(action && { action }),
        ...(category && { category }),
      };

      const imageFile =
        form.getFieldValue('notificationImage')?.[0].originFileObj;
      if (imageFile && isInAppPush) {
        const presignedNotification = await createPresignedUrlsAndUploadToS3({
          files: [imageFile],
          getPresignedUrls: async () => {
            const { data } = await createPresignedNotificationImage({
              variables: {
                input: {
                  ext: imageFile.name.split('.').pop() || '',
                  contentType: imageFile.type || '',
                },
              },
            });

            if (!data) {
              console.error('Error uploading image file');
              setIsLoading(false);
              throw new Error(
                'Something went wrong with the upload of the inApp/Push image file'
              );
            }
            return [data.createPresignedNotificationImageUpload];
          },
        });
        if (presignedNotification) {
          inAppPushConfig.imageKey = presignedNotification[0].key;
        }
      }

      const emailConfig: SendoutEmailConfigInput = isEmail
        ? {
            subject,
            paragraph,
            ...(buttonLabel &&
              buttonLink && {
                button: { label: `${buttonLabel}`, url: `${buttonLink}` },
              }),
          }
        : null;

      const emailImageFile =
        form.getFieldValue('emailImage')?.[0].originFileObj;

      if (emailImageFile && isEmail) {
        const presignedNotification = await createPresignedUrlsAndUploadToS3({
          files: [emailImageFile],
          getPresignedUrls: async () => {
            const { data } = await createPresignedNotificationImage({
              variables: {
                input: {
                  ext: emailImageFile.name.split('.').pop() || '',
                  contentType: emailImageFile.type || '',
                },
              },
            });

            if (!data) {
              console.error('Error uploading image file');
              setIsLoading(false);
              throw new Error(
                'Something went wrong with the upload of the email image file'
              );
            }
            return [data.createPresignedNotificationImageUpload];
          },
        });
        if (presignedNotification) {
          emailConfig.imageKey = presignedNotification[0].key;
        }
      }

      const commonInput: CreateSendoutInput = {
        csvKey: presignedCsv[0].key,
        title: notificationTitle,
        ...(description && {
          description,
        }),
        ...(analyticsLabel && {
          analyticsLabel,
        }),
        ...(isInAppPush && {
          inAppConfig: {
            ...inAppPushConfig,
          },
          pushConfig: {
            ...inAppPushConfig,
          },
        }),
        ...(isEmail && {
          emailConfig,
        }),
      };

      await createNotification({
        variables: {
          input: { ...commonInput },
        },
      });
      successNotification(`Notification created successfully`);
      setIsLoading(false);

      handleResetAndClose();
    } catch (err) {
      setIsLoading(false);
      errorNotification((err as Error)?.message || 'Something went wrong');
    }
  };

  const getIfSubmitIsDisabled = () => {
    const hasErrors = Boolean(
      form.getFieldsError()?.filter(({ errors }) => errors?.length)?.length
    );

    // inApp/Push is valid if it's checkbox is checked and the title input has data
    const inAppPushValidity = isInAppPush
      ? Boolean(form.getFieldValue('title').length)
      : true;

    const buttonLabel = form.getFieldValue('buttonLabel');
    const buttonLink = form.getFieldValue('buttonLink');

    // email is valid if it's checkbox is checked and it has subject, paragraph
    // and either all button data-or no button data
    const emailValidity = isEmail
      ? Boolean(form.getFieldValue('subject').length) &&
        Boolean(form.getFieldValue('paragraph').length) &&
        // do we have both button label and link
        ((Boolean(buttonLabel.length) && Boolean(buttonLink.length)) ||
          // do we not have neither button label nor link
          (!buttonLabel.length && !buttonLink.length))
      : true;

    // form is submittable if we have notification title input and
    // atleast one of the two configurations (inApp/Push and/or email)
    const hasValues =
      Boolean(form.getFieldValue('notificationTitle').length) &&
      (isInAppPush || isEmail) &&
      inAppPushValidity &&
      emailValidity;

    setIsDisabled(Boolean(hasErrors || !hasValues));
  };

  const checkButtonLabel = (text: string) => {
    const buttonLabel = form.getFieldValue('buttonLabel');
    if (text === '' && buttonLabel === '') {
      setHasButton(false);
    } else {
      setHasButton(true);
    }
  };

  const checkButtonLink = (text: string) => {
    const buttonLink = form.getFieldValue('buttonLink');
    if (text === '' && buttonLink === '') {
      setHasButton(false);
    } else {
      setHasButton(true);
    }
  };

  const normFile = (e: any) => {
    if (e && Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const titleInput = inputState.title;
  const bodyInput = inputState.body;
  const subjectInput = inputState.subject;
  const paragraphInput = inputState.paragraph;

  return (
    <Modal
      title="Create sendout"
      destroyOnClose
      visible={isVisible}
      onCancel={onClose}
      width="80vw"
      footer={[
        <Button key="cancel" onClick={handleResetAndClose}>
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          loading={isLoading}
          onClick={handleOnSubmit}
          disabled={isDisabled}
        >
          Create
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="CreateSendout"
        initialValues={initialFormValues}
        onChange={getIfSubmitIsDisabled}
      >
        <Form.Item
          name="notificationTitle"
          label="Sendout Title"
          rules={[
            {
              required: true,
              message: 'Please set the sendout title',
            },
          ]}
        >
          <Input placeholder="Enter sendout title here" />
        </Form.Item>

        <Form.Item name="description" label="Sendout Description">
          <Input placeholder="Enter sendout description here" />
        </Form.Item>

        <Form.Item
          name="csvFile"
          label="Upload CSV file"
          valuePropName="csvFile"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: 'Please upload the CSV file',
            },
          ]}
        >
          <UploadImageFile
            buttonTitle="Upload CSV file"
            imageAlt="CSV file"
            accept="text/csv"
          />
        </Form.Item>

        <Form.Item
          name="analyticsLabel"
          label="Analytics label to track Push/inApp notifications (no spaces between words allowed)"
          rules={[
            {
              message:
                'Labels can include lower and upper case letters, numbers, and the following symbols: -, ~, %, limited to 50 characters',
              validator: (_: any, value: string) => {
                const regexp = /^[a-zA-Z0-9-_.~%]{1,50}$/;
                return regexp.test(value)
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        'Labels can include lower and upper case letters, numbers, and the following symbols: -, ~, %, limited to 50 characters'
                      )
                    );
              },
            },
          ]}
        >
          <Input placeholder="Enter label here" />
        </Form.Item>

        <Form.Item
          name="isInAppPush"
          label="InApp/Push settings"
          valuePropName="checked"
          initialValue={isInAppPush}
        >
          <Checkbox
            checked={isInAppPush}
            onClick={() => setIsInAppPush(!isInAppPush)}
          />
        </Form.Item>
        <div
          className={cn(styles.hidden, {
            [styles.visible]: isInAppPush,
          })}
        >
          <div className={styles.inlineInputAndTemplate}>
            <Form.Item
              className={styles.inlineInput}
              name="title"
              label="InApp/Push Title"
              rules={[
                {
                  required: isInAppPush,
                  message: 'Please set the title',
                },
                {
                  max: 700,
                  message: 'Please limit the title to 700 characters',
                },
              ]}
            >
              <Input
                placeholder="Hey {{firstName}}..."
                maxLength={700}
                onChange={(e) => changeInput(e.target.value, 'title')}
                value={titleInput}
              />
              <div className={styles.letterCounter}>
                {titleInput.length}/700
              </div>
              {!!templateState.title && (
                <>Preview title: {templateState.title}</>
              )}
            </Form.Item>

            <Form.Item
              name="titleTemplate"
              label={<Text>Add title templates</Text>}
            >
              {NOTIFICATION_TEMPLATES.map(({ label, value }) => (
                <Button
                  className={styles.templateButton}
                  key={value}
                  onClick={() =>
                    // update title input's value with the template, firstName or lastName
                    changeInput(`${titleInput} {{user.${value}}}`, 'title')
                  }
                >
                  {label}
                </Button>
              ))}
            </Form.Item>
          </div>
          <div className={styles.inlineInputAndTemplate}>
            <Form.Item
              className={styles.inlineInput}
              name="body"
              label="InApp/Push Body"
              rules={[
                {
                  max: 700,
                  message: 'Please limit the body to 700 characters',
                },
              ]}
            >
              <Input.TextArea
                placeholder="Templated body with vars {{firstName}}"
                maxLength={700}
                rows={5}
                onChange={(e) => changeInput(e.target.value, 'body')}
                value={bodyInput}
              />
              <div className={styles.letterCounter}>{bodyInput.length}/700</div>

              {!!templateState.body && (
                <div className={styles.bodyPreview}>
                  Preview body: {templateState.body}
                </div>
              )}
            </Form.Item>
            <Form.Item
              name="bodyTemplate"
              label={<Text>Add body templates</Text>}
            >
              {NOTIFICATION_TEMPLATES.map(({ label, value }) => (
                <Button
                  key={value}
                  className={styles.templateButton}
                  onClick={() =>
                    changeInput(`${bodyInput} {{user.${value}}}`, 'body')
                  }
                >
                  {label}
                </Button>
              ))}
            </Form.Item>
          </div>
          <Form.Item name="action" label="InApp/Push Action">
            <Select
              id="action"
              showSearch
              placeholder="Add new workflow"
              optionFilterProp="children"
            >
              {allowedActions.map((item) => {
                return (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="notificationImage"
            label="Upload InApp/Push image (recommended aspect ratio - 2:1)"
            valuePropName="notificationImage"
            getValueFromEvent={normFile}
            validateStatus={
              notificationImageValidation ||
              form.getFieldsError(['notificationImage'])[0].errors?.length ||
              !notificationImage
                ? 'error'
                : ''
            }
            help={notificationImageValidation || null}
          >
            <UploadImageFile
              name="notificationImage"
              buttonTitle="Upload InApp/Push image"
              imageAlt="notification image"
              maxFileSize={1}
              setPictureValidation={setNotificationImageValidation}
              maxWidth={2000}
              minWidth={300}
              minHeight={200}
            />
          </Form.Item>
          <Form.Item name="category" label="InApp/Push Category">
            <Input placeholder="" />
          </Form.Item>
        </div>
        <Form.Item
          name="isEmail"
          label="Email settings"
          valuePropName="checked"
          initialValue={isEmail}
        >
          <Checkbox checked={isEmail} onClick={() => setIsEmail(!isEmail)} />
        </Form.Item>
        <div
          className={cn(styles.hidden, {
            [styles.visible]: isEmail,
          })}
        >
          <div className={styles.inlineInputAndTemplate}>
            <Form.Item
              className={styles.inlineInput}
              name="subject"
              label="Email subject"
              rules={[
                {
                  required: isEmail,
                  message: "Please set the email's subject",
                },
                {
                  max: 1000,
                  message: 'Please limit the subject to 1000 characters',
                },
              ]}
            >
              <Input
                placeholder="Hey {{firstName}}..."
                maxLength={1000}
                onChange={(e) => changeInput(e.target.value, 'subject')}
                value={subjectInput}
              />
              <div className={styles.letterCounter}>
                {subjectInput.length}/1000
              </div>
              {!!templateState.subject && (
                <>Preview subject: {templateState.subject}</>
              )}
            </Form.Item>
            <Form.Item
              name="subjectTemplate"
              label={<Text>Add subject templates</Text>}
            >
              {NOTIFICATION_TEMPLATES.map(({ label, value }) => (
                <Button
                  key={value}
                  className={styles.templateButton}
                  onClick={() =>
                    changeInput(`${subjectInput} {{user.${value}}}`, 'subject')
                  }
                >
                  {label}
                </Button>
              ))}
            </Form.Item>
          </div>
          <div className={styles.inlineInputAndTemplate}>
            <Form.Item
              className={styles.inlineInput}
              name="paragraph"
              label="Email Body"
              rules={[
                { required: isEmail, message: "Please set the email's body" },
                {
                  max: 10000,
                  message: 'Please limit the body to 10000 characters',
                },
              ]}
            >
              <Input.TextArea
                placeholder="Templated body with vars {{firstName}}"
                maxLength={10000}
                rows={5}
                onChange={(e) => changeInput(e.target.value, 'paragraph')}
                value={paragraphInput}
              />
              <div className={styles.letterCounter}>
                {paragraphInput.length}/10000
              </div>
              {!!templateState.paragraph && (
                <div className={styles.bodyPreview}>
                  Preview body: {templateState.paragraph}
                </div>
              )}
            </Form.Item>
            <Form.Item
              name="subjectTemplate"
              label={<Text>Add body templates</Text>}
            >
              {NOTIFICATION_TEMPLATES.map(({ label, value }) => (
                <Button
                  key={value}
                  className={styles.templateButton}
                  onClick={() =>
                    changeInput(
                      `${paragraphInput} {{user.${value}}}`,
                      'paragraph'
                    )
                  }
                >
                  {label}
                </Button>
              ))}
            </Form.Item>
          </div>
          <Form.Item
            name="emailImage"
            label="Upload an image for the email"
            valuePropName="emailImage"
            getValueFromEvent={normFile}
            validateStatus={
              emailImageValidation ||
              form.getFieldsError(['emailImage'])[0].errors?.length ||
              !emailImage
                ? 'error'
                : ''
            }
            help={emailImageValidation || null}
          >
            <UploadImageFile
              name="emailImage"
              buttonTitle="Upload email image"
              imageAlt="email image"
              setPictureValidation={setEmailImageValidation}
            />
          </Form.Item>
          <Form.Item
            name="buttonLabel"
            label="Button Label"
            rules={[
              {
                required: hasButton,
                message:
                  "Please set the button's label or delete the link (field below)-button has to have both or neither",
              },
            ]}
          >
            <Input
              placeholder="Enter button's label here"
              onChange={(e) => checkButtonLink(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="buttonLink"
            label="Button Link"
            rules={[
              {
                required: hasButton,
                message:
                  "Please set the button's link or delete the label (field above)-button has to have both or neither",
              },
            ]}
          >
            <Input
              placeholder="Enter button's link here"
              onChange={(e) => checkButtonLabel(e.target.value)}
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateNotificationModal;
