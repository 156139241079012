import React from 'react';
import { Space, Empty } from 'antd';
// Types
import { StreamStatus } from 'api/graphql-global-types';
import { GetUpcomingStreams_adminStreams_entities } from 'api/streams/types/GetUpcomingStreams';
// Components
import StreamsListItem from '../StreamsListItem/StreamsListItem';

type StreamsListByStatusProps = {
  statuses: StreamStatus[];
  listData: GetUpcomingStreams_adminStreams_entities[];
};

const StreamsListByStatus = ({
  statuses,
  listData,
}: StreamsListByStatusProps): JSX.Element => {
  const filteredData = listData.filter((item) =>
    statuses.includes(item.streamStatus)
  );

  if (!filteredData.length) {
    return (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Streams" />
    );
  }

  return (
    <Space direction="vertical" size="small">
      {filteredData.map((stream) => (
        <StreamsListItem key={stream.id} stream={stream} />
      ))}
    </Space>
  );
};

export default StreamsListByStatus;
