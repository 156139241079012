import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import env from 'api/env';
// Helpers
import { parseForInnerHTML } from 'helpers/textEditor';
import { getEnvLink } from 'helpers/getEnvLink';
// Types
import { GetArticles_getArticles_entities } from 'api/articles/types/GetArticles';
import { ArticleStatus } from 'api/graphql-global-types';
// Constants
import { commonTimeFormat } from 'constants/global';
// Styles
import styles from './SponsoredArticlesDetails.module.scss';

type SponsoredArticlesDetailsProps = {
  record: GetArticles_getArticles_entities | null;
};

const SponsoredArticlesDetails = ({
  record,
}: SponsoredArticlesDetailsProps): JSX.Element => {
  const store = record?.store.storeDetails?.storeName;

  const createdAt = moment(record?.createdAt).format(commonTimeFormat);

  const tagsList = record?.hashtags.map((tag) => tag.name).join(', ');

  const imageLinkUrl = record?.articleUrl;

  const storeUrl = `${getEnvLink(env.REACT_APP_ENV)}/${record?.store?.slug}`;

  const isPublished = record?.status === ArticleStatus.Active;
  const articleUrl = `${storeUrl}/news/${record?.slug}`;

  return (
    <div className={styles.root}>
      <div className={styles.dataItem}>
        <div className={styles.label}> Article ID:</div>
        <div> {record?.id}</div>
      </div>

      <div className={styles.dataItem}>
        <div className={styles.label}>Profile selected:</div>
        <a href={storeUrl} target="_blank" rel="noopener noreferrer">
          {store}
        </a>
      </div>

      {isPublished && (
        <div className={styles.dataItem}>
          <div className={styles.label}>Article URL:</div>
          <a href={articleUrl} target="_blank" rel="noopener noreferrer">
            See Article
          </a>
        </div>
      )}

      <div className={styles.dataItem}>
        <div className={styles.label}>Articles type:</div>
        <div>Sponsored Article</div>
      </div>

      <div className={styles.dataItem}>
        <div className={styles.label}> Date created:</div>
        <div> {createdAt}</div>
      </div>

      <div className={styles.dataItem}>
        <div className={styles.label}> Status:</div>
        <div> {record?.status}</div>
      </div>

      <div className={styles.segment}>
        <p className={styles.segmentTitle}>Article Content</p>
        <div className={styles.dataItem}>
          <div className={styles.label}> Title:</div>
          <div> {record?.title}</div>
        </div>
        <div className={styles.dataItem}>
          <div className={styles.label}> Blog text:</div>
          <div
            className={styles.multilineBody}
            dangerouslySetInnerHTML={{
              __html: parseForInnerHTML(record?.content),
            }}
          ></div>
        </div>
        <div className={styles.dataItem}>
          <div className={styles.label}>Tags:</div>
          <div> {tagsList}</div>
        </div>
        <div className={styles.dataItem}>
          <div className={styles.label}>Cover image:</div>
          <a href={record?.url || ''} target="_blank" rel="noopener noreferrer">
            <DownloadOutlined /> Open image
          </a>
        </div>
        <div className={styles.dataItem}>
          <div className={styles.label}>Image label:</div>
          <div> {record?.imageLabel}</div>
        </div>
        {imageLinkUrl && (
          <div className={styles.dataItem}>
            <div className={styles.label}>Image url:</div>
            <a href={imageLinkUrl} target="_blank" rel="noopener noreferrer">
              go to image url
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default SponsoredArticlesDetails;
