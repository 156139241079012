import React from 'react';
import { Button } from 'antd';
import cn from 'classnames';
// Components
import UploadImageFile from 'ui/UploadImageFile/UploadImageFile';
// Styles
import styles from './SocialMediaPackItem.module.scss';

export type FileWithUid = File & {
  uid?: string;
};

export type SocialMediaPackDataType = {
  imageFileKey: string;
  imgURL: string;
  label?: string;
};

export type SocialMediaPackItemType = {
  isValid?: boolean;
  label?: string;
  image: FileWithUid | undefined;
  imageFileKey?: string;
  imgURL?: string;
};

export const emptySocialMediaItem: SocialMediaPackItemType = {
  isValid: false,
  label: '',
  image: undefined,
  imgURL: '',
  imageFileKey: '',
};

type SocialMediaPackProps = {
  socialMediaItem: SocialMediaPackItemType;
  index: number;
  setValue: (value: SocialMediaPackItemType, index: number) => void;
  deleteValue: (index: number) => void;
};

const SocialMediaPackItem = ({
  socialMediaItem,
  index,
  setValue,
  deleteValue,
}: SocialMediaPackProps): JSX.Element => {
  const { isValid, image, imgURL } = socialMediaItem;
  const displayUrl =
    image instanceof File ? URL.createObjectURL(image) : imgURL || '';

  const handleSetValue = (socialMediaPackItem: SocialMediaPackItemType) => {
    setValue(socialMediaPackItem, index);
  };

  const handleOnRemove = () => {
    handleSetValue({
      ...socialMediaItem,
      image: undefined,
      isValid: false,
    });
  };

  const handleDeleteSocialMediaPack = () => {
    deleteValue(index);
  };

  const beforePictureUploadCb = (file: File, fileList: File[]) => {
    const imagesList = [...fileList].filter((item) =>
      typeof item === 'object'
        ? (item.type === 'image/jpeg' ||
            item.type === 'image/jpg' ||
            item.type === 'image/png' ||
            item.type === 'image/gif') &&
          item.size / 1024 / 1024 < 2
        : true
    );
    handleSetValue({
      ...socialMediaItem,
      image: imagesList[0],
      isValid: true,
    });
  };

  return (
    <div
      className={cn(styles.imageItem, {
        [styles.invalidImageItem]: !isValid,
      })}
    >
      <UploadImageFile
        defaultFileUrl={displayUrl}
        buttonTitle={'Add photo'}
        beforePictureUploadCb={beforePictureUploadCb}
        imageAlt="Upload"
        onRemove={handleOnRemove}
      />

      <Button
        danger
        onClick={handleDeleteSocialMediaPack}
        className={styles.deleteButton}
      >
        Delete Social Media Pack
      </Button>
    </div>
  );
};

export default SocialMediaPackItem;
