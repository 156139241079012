export function base64ToBlob(base64Data: string): Blob {
  // Check if data is base64 encoded and starts with PNG signature

  // the string looks like this: 'data:' + type + ';base64,'
  // to extract the type, first eliminate the 'data:' part of the image url
  // and then take first part of that result, before the ';'
  const type = base64Data.split(':')[1].split(';')[0];

  if (!base64Data.startsWith('data:' + type + ';base64,')) {
    console.error('Invalid base64 data');
  }

  // Extract the content (remove header)
  const base64Content = base64Data.split(',')[1];

  // Convert it back to binary data
  const byteString = atob(base64Content);

  // Convert to byte array
  const byteNumbers = new Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob of type image/png
  return new Blob([byteArray], { type: type });
}

export const convertBlobToFile = (val: Blob | File, fileName: string): File =>
  new File([val], fileName || '', { type: val.type });
