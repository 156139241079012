import React, { useState } from 'react';
import cn from 'classnames';
import {
  ReactEditor,
  useFocused,
  useSelected,
  useSlateStatic,
} from 'slate-react';
import { Button, Popover, Space, Typography } from 'antd';
import { ExportOutlined, DisconnectOutlined } from '@ant-design/icons';
// Helpers
import { removeLink } from 'components/common/RichText/helpers/link';
// Styles
import styles from './Link.module.scss';
import { BaseElement } from 'slate';

const { Link: AntLink } = Typography;

export type LinkProps = BaseElement & {
  element: BaseElement & { href?: string };
};

const Link: React.FC<LinkProps> = ({ element, children }) => {
  const editor = useSlateStatic() as ReactEditor;
  const selected = useSelected();
  const focused = useFocused();

  const [openPopover, setOpenPopover] = useState<boolean>(false);

  const handlePopoverToggle = () => {
    setOpenPopover((prev) => !prev);
  };

  const handleRemoveLink = () => {
    removeLink(editor);
    handlePopoverToggle();
  };

  const { href } = element;
  const isActive = selected && focused;

  return (
    <Popover
      visible={openPopover}
      onVisibleChange={handlePopoverToggle}
      trigger="click"
      overlayClassName={styles.root}
      destroyTooltipOnHide
      content={
        <Space
          size="small"
          direction="horizontal"
          align="center"
          className={styles.content}
        >
          <AntLink
            href={href}
            title={`Visit ${href}`}
            target="_blank"
            ellipsis
            underline
          >
            {href}
          </AntLink>

          <Button
            type="link"
            href={href}
            target="_blank"
            icon={<ExportOutlined />}
            title={`Go to ${href}`}
          />

          <Button
            type="link"
            danger
            onClick={handleRemoveLink}
            icon={<DisconnectOutlined />}
            title="Remove link"
            htmlType="button"
          />
        </Space>
      }
    >
      <Button
        type="link"
        className={cn(styles.cta, { [styles.isActive]: isActive })}
        title={href}
        htmlType="button"
      >
        {children}
      </Button>
    </Popover>
  );
};

export default Link;
