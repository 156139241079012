import { ContactType, TargetType } from 'api/graphql-global-types';
// Ui
import { MentionValues } from 'uiShared/Mentions/Mentions';

export const formattedUnregisteredMention = (
  mention: {
    unregisteredContact: { name: any; representativeName: any; id: any };
    email: any;
    url: any;
  },
  type: ContactType
): {
  label: string;
  value: string;
  avatarURL: string;
  email: string;
  url: string;
} => ({
  label:
    type === ContactType.ATHLETE
      ? mention?.unregisteredContact?.name || ''
      : mention?.unregisteredContact?.representativeName || '',
  value: mention?.unregisteredContact?.id || '',
  avatarURL: '',
  email: mention?.email || '',
  url: mention?.url || '',
});

export const reformattedUnregisteredMention = (
  option: { value: any; label: any; email: any; url: any },
  type: ContactType
): {
  id: string;
  store: null;
  mentionType: string;
  targetType: TargetType;
  unregisteredContact: {
    id: string;
    representativeName: string | null;
    name: string | null;
    type: ContactType;
    email: string;
    url: string;
  };
} => ({
  id: option.value,
  store: null,
  mentionType: 'MEDIA_POST',
  targetType: TargetType.UNREGISTERED,
  unregisteredContact: {
    id: option.value,
    representativeName: type === ContactType.ATHLETE ? null : option.label,
    name: type === ContactType.BRAND ? null : option.label,
    type: type,
    email: option.email,
    url: option.url,
  },
});

export const formatMentionsInput = (
  mentions: MentionValues[]
):
  | Array<{
      storeId?: string;
    } | null>
  | Array<{
      unregisteredContactId?: string;
    }> => {
  return (
    mentions?.map((mention) => {
      if (mention.targetType === TargetType.REGISTERED) {
        return {
          storeId: mention?.store?.storeDetails?.storeId,
        };
      } else if (mention.targetType === TargetType.UNREGISTERED) {
        return {
          unregisteredContactId: mention?.unregisteredContact?.id,
        };
      }
      return null;
    }) ?? []
  );
};
