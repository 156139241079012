import React from 'react';
import { Space } from 'antd';
// Types
import { DesignTypes_designTypes_designSamples } from 'api/designLab/types/DesignTypes';
import { DLStateValues } from 'components/common/ManageDesigns/components/DLConstructor/DLConstructor';
// Components
import CardItem from '../CardItem/CardItem';
// Styles
import styles from './PreferredDesignsForm.module.scss';

type PreferredDesignsFormProps = {
  onChange: (values: DLStateValues) => void;
  options: DesignTypes_designTypes_designSamples[];
  selectedValues: number[];
};

const PreferredDesignsForm = ({
  onChange,
  options,
  selectedValues,
}: PreferredDesignsFormProps): JSX.Element => {
  const isCheckedMoreThanTwo = selectedValues.length >= 2;

  const handleCheckboxChange = (id: number) => {
    const updated = selectedValues.some((item) => item === id)
      ? selectedValues.filter((item) => item !== id)
      : selectedValues.concat(id);

    onChange({
      designs: updated,
    });
  };

  return (
    <Space className={styles.root} direction="horizontal" align="start" wrap>
      {options.map(({ id, name, posterImage }) => {
        const isSelected = selectedValues.some((val) => val === id);
        const isAvailable = !isCheckedMoreThanTwo || isSelected;

        const handleChange = () => {
          if (isAvailable) {
            handleCheckboxChange(id);
          }
        };

        return (
          <CardItem
            key={`${name}-${id}`}
            isAvailable={isAvailable}
            isSelected={isSelected}
            onChange={handleChange}
            imageSrc={posterImage}
            imageAlt={`Logo Example "${name}"`}
          />
        );
      })}
    </Space>
  );
};

export default PreferredDesignsForm;
