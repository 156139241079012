import { TreeDataEntity } from './tree-data.entity';

export class TreeSelectValue {
  id: string;
  type: string;
  storeId: string;
  value: string;
  label: string;

  public static fromObject({ value, title }: TreeDataEntity): TreeSelectValue {
    const [type, id, storeId] = value.split('|');

    const data = new TreeSelectValue();

    data.id = id;
    data.type = type;
    data.storeId = storeId;
    data.value = value;
    data.label = title;

    return data;
  }
}
