import React, { FC } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import cn from 'classnames';
// Styles
import './styles.scss';

interface PhoneNumberInputProps {
  name: string;
  register?: any;
  placeholder: string;
  disabled?: boolean;
  error?: string;
  label?: string;
  value?: string;
  classNames?: string;
  onBlur?: () => void;
  handlePhoneChange?: (value: string) => void;
}

const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  name,
  register,
  placeholder,
  disabled,
  label,
  error,
  classNames,
  onBlur,
  value,
  handlePhoneChange,
}) => {
  const preferredCountries = ['us', 'ca'];
  const containerClassName = `phoneNumberContainer ${
    disabled ? 'disabledContainer' : ''
  }`;
  const inputClassName = `phoneNumberInput ${error ? 'errorInput' : ''}`;
  const dropdownClass = `flagDropdown open`;

  const inputProps = {
    name: name,
    ref: register,
    placeholder: placeholder,
    disabled: disabled,
    className: inputClassName,
    onBlur: onBlur,
    style: {
      color: 'rgba(0, 0, 0, 0.85)',
      fontSize: '14px',
      fontVariant: 'tabular-nums',
      lineHeight: '1.5715',
      backgroundColor: '#fff',
      fontFeatureSettings: 'tnum',
    },
  };

  return (
    <div className={cn(containerClassName, classNames)} id={name}>
      <span className="label"> {label}</span>

      <PhoneInput
        value={value}
        onChange={handlePhoneChange}
        inputProps={inputProps}
        containerClass="phoneNumberInputContainer"
        buttonStyle={{
          display: 'block',
          padding: '8px',
          height: '100%',
          border: 'none',
          textAlign: 'left',
          backgroundColor: 'white',
        }}
        preferredCountries={preferredCountries}
        dropdownClass={dropdownClass}
        countryCodeEditable={false}
        country="us"
      />
      {error && <span className="error">{error}</span>}
    </div>
  );
};

export default PhoneNumberInput;
