import React, { useRef } from 'react';
import { Button, Divider, Input, Modal } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
// Components
import { StreamClipType } from '../../StreamClip';
// UI
import { successNotification } from 'ui/Notification';
import VideoPreview from 'ui/VideoPreview/VideoPreview';
// Styles
import styles from './StreamClipPlayModal.module.scss';

type StreamClipPlayModalProps = {
  onHide: () => void;
  isVisible?: boolean;
  clip: StreamClipType;
};

const StreamClipPlayModal = ({
  onHide,
  isVisible,
  clip,
}: StreamClipPlayModalProps): JSX.Element | null => {
  const urlRef = useRef<Input>(null);

  const handleCopyUrl = () => {
    if (urlRef.current) {
      navigator.clipboard
        .writeText(urlRef.current.input.value)
        .then(() => successNotification('Copied to clipboard'))
        .catch((error: Error) => console.error('Could not copy text: ', error));
    }
  };

  return (
    <Modal
      title={clip.title}
      visible={isVisible}
      onCancel={onHide}
      destroyOnClose
      footer={null}
      width="80%"
      centered
    >
      <div className={styles.root}>
        <VideoPreview video={clip.url} show />
        <Divider />
        <Input
          ref={urlRef}
          defaultValue={clip.url || ''}
          readOnly
          addonAfter={
            <Button
              type="text"
              icon={<CopyOutlined />}
              onClick={handleCopyUrl}
            />
          }
        />
      </div>
    </Modal>
  );
};

export default StreamClipPlayModal;
