import { gql } from '@apollo/client';
import { HashtagFields } from 'api/hashtags/fragments';

export const GET_HASHTAGS = gql`
  query GetHashtags($keyword: String!) {
    getHashtags(keyword: $keyword) {
      id
      name
    }
  }
`;

export const GET_ALL_HASHTAGS = gql`
  query GetAllHashtags($input: AllHashtagsFilterInput!) {
    getAllHashtags(input: $input) {
      entities {
        ...HashtagFields
      }
      total
      offset
      limit
    }
  }
  ${HashtagFields}
`;
