import { Gender } from 'api/graphql-global-types';

export const listGenders = (genders: Gender[], isShirt: boolean): string => {
  let listedGenders = '';
  genders.forEach((gender) => {
    if (isShirt && gender === Gender.Unisex) {
      listedGenders += 'Youth, ';
    } else {
      listedGenders += gender + ', ';
    }
  });
  return listedGenders.slice(0, listedGenders.length - 2);
};
