import { gql } from '@apollo/client';
// Fragments
import { ScheduleFields } from './fragments';

export const GET_SCHEDULE = gql`
  query GetSchedule($input: GetScheduleInput!) {
    getSchedule(input: $input) {
      ...ScheduleFields
    }
  }
  ${ScheduleFields}
`;

export const GET_MY_SCHEDULE = gql`
  query GetMySchedule($input: GetMyScheduleInput!) {
    getMySchedule(input: $input) {
      ...ScheduleFields
    }
  }
  ${ScheduleFields}
`;

export const GET_SCHEDULE_TIMEZONES = gql`
  query GetScheduleTimezones {
    getScheduleTimezones {
      alias
      iana
    }
  }
`;
