import { gql } from '@apollo/client';

export const PromocodeFields = gql`
  fragment PromocodeFields on Promocode {
    id
    name
    startDate
    endDate
    amount
    usageLimit
    perUserLimit
    chargeSubject
    isSiteWide
    isActive
    isDeactivated
  }
`;
