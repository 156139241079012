import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { notificationClient } from 'notificationClient';
import moment from 'moment';
// Api
import { GET_NOTIFICATIONS } from 'api/notification/queries';
// Types
import {
  GetSendouts,
  GetSendoutsVariables,
  GetSendouts_getSendouts_data,
} from 'api/notification/types/GetSendouts';
// UI
import Table from 'ui/Table';
import { Button, Modal, Spin } from 'antd';
// Constants
import { commonTimeFormat } from 'constants/global';
// Components
import CreateNotificationModal from './components/CreateNotificationModal/CreateNotificationModal';
import NotificationDetailsModal from './components/NotificationDetailsModal/NotificationDetailsModal';
// Styles
import styles from './Notifications.module.scss';

export interface NotificationsTableProps {
  id: string;
  name: string;
  createdAt: string;
  status: string;
  size: string;
  type: string;
}

const Notifications: React.FC = () => {
  const [itemModalData, setItemModalData] =
    useState<GetSendouts_getSendouts_data | null>(null);
  const [creationModalVisibility, setCreationModalVisibility] =
    useState<boolean>(false);

  const showItemModal = (record: GetSendouts_getSendouts_data | null) => {
    setItemModalData(record);
  };

  const hideItemModal = () => {
    setItemModalData(null);
  };

  const handleCreateSendout = () => {
    setCreationModalVisibility(true);
  };

  const hideCreationModal = () => {
    setCreationModalVisibility(false);
  };

  const { data, loading, refetch } = useQuery<
    GetSendouts,
    GetSendoutsVariables
  >(GET_NOTIFICATIONS, {
    variables: {
      input: {
        limit: 10,
      },
    },
    fetchPolicy: 'cache-and-network',
    client: notificationClient,
  });

  const handleOnClickLoadMore = async () => {
    if (data?.getSendouts?.nextToken) {
      await refetch({
        input: {
          limit: (data?.getSendouts.data?.length ?? 0) + 10,
        },
      });
    }
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      align: 'center' as const,
      width: 130,
      render: function ActionsWrap(
        id: string,
        record: GetSendouts_getSendouts_data
      ) {
        return (
          <span
            onKeyDown={() => showItemModal(record)}
            onClick={() => showItemModal(record)}
            className={styles.detailsModalLink}
            role="button"
            tabIndex={0}
          >
            {id}
          </span>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'title',
      align: 'center' as const,
      width: 120,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAtIso',
      align: 'center' as const,
      width: 120,
      render: (createdAt: string) => moment(createdAt).format(commonTimeFormat),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center' as const,
      width: 100,
    },
    {
      title: 'Size (number of users)',
      dataIndex: 'size',
      align: 'center' as const,
      width: 130,
    },
    {
      title: 'Type',
      dataIndex: 'id',
      align: 'center' as const,
      width: 120,
      render: function TypeColumn(
        id: string,
        record: GetSendouts_getSendouts_data
      ) {
        const types = [];
        // V1 only has inApp and Push options and both are selected on every sendout, but V2 will have more
        if (record.inAppConfig?.title) {
          types.push('inApp');
        }
        if (record.pushConfig?.title) {
          types.push('Push');
        }
        if (record.emailConfig?.subject) {
          types.push('Email');
        }
        return types.join(', ');
      },
    },
  ];

  if (loading) {
    return <Spin />;
  }

  return (
    <>
      <div className={styles.sendouts}>
        <Button
          className={styles.createBtn}
          type="primary"
          onClick={handleCreateSendout}
        >
          Create Notification
        </Button>

        <Table<GetSendouts_getSendouts_data>
          columns={columns}
          data={data?.getSendouts?.data}
          scroll={{ x: 300 }}
          pagination={false}
        />
      </div>
      <div className={styles.loadMoreBtn}>
        <Button
          type="primary"
          disabled={!data?.getSendouts?.nextToken}
          onClick={handleOnClickLoadMore}
        >
          Load More
        </Button>
      </div>
      <CreateNotificationModal
        isVisible={creationModalVisibility}
        onClose={hideCreationModal}
      />
      <Modal
        title="Sendout details"
        visible={!!itemModalData}
        footer={null}
        onCancel={hideItemModal}
        width="70vw"
      >
        <NotificationDetailsModal record={itemModalData} />
      </Modal>
    </>
  );
};

export default Notifications;
