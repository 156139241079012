import React, { useState } from 'react';
import { Button } from 'antd';
import { ButtonType } from 'antd/lib/button';
// Components
import AssignInterviewerModal from './AssignInterviewerModal';

type AssignInterviewerProps = {
  interviewId?: string;
  buttonTitle?: string;
  buttonType?: ButtonType;
};

const AssignInterviewer: React.FC<AssignInterviewerProps> = ({
  interviewId,
  buttonTitle = 'Assign',
  buttonType = 'primary',
}) => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const handleShowModal = () => {
    setModalVisible(true);
  };

  const handleSetModalVisibility = (isVisible: boolean) => {
    setModalVisible(isVisible);
  };

  return (
    <>
      <Button type={buttonType} onClick={handleShowModal}>
        {buttonTitle}
      </Button>

      <AssignInterviewerModal
        interviewId={interviewId}
        isModalVisible={isModalVisible}
        setModalVisible={handleSetModalVisibility}
      />
    </>
  );
};

export default AssignInterviewer;
