import React from 'react';
import cn from 'classnames';
import { Card, Typography, Checkbox, Image } from 'antd';
// Constants
import { FALLBACK_IMAGE } from 'constants/global';
// Components
import styles from './CardItem.module.scss';

type CardItemProps = {
  title?: string;
  isAvailable?: boolean;
  isSelected?: boolean;
  onChange?: () => void;
  withoutImage?: boolean;
  imageSrc?: string | null;
  imageAlt?: string | null;
  imageWithPreview?: boolean;
  description?: string | null;
  children?: JSX.Element;
};

const { Title, Text } = Typography;

const CardItem = ({
  title,
  isAvailable,
  isSelected,
  onChange,
  withoutImage,
  imageSrc,
  imageAlt,
  imageWithPreview = true,
  description,
  children,
}: CardItemProps): JSX.Element => {
  return (
    <Card
      className={cn(styles.card, { [styles.isSelected]: isSelected })}
      bodyStyle={{ padding: 0, lineHeight: 0 }}
      size="small"
      {...(title && {
        title: (
          <Title level={5} ellipsis={{ tooltip: title }}>
            {title}
          </Title>
        ),
      })}
      extra={
        <Checkbox
          disabled={!isAvailable}
          onClick={onChange}
          checked={isSelected}
        />
      }
    >
      {!withoutImage && (
        <Image
          src={imageSrc || ''}
          alt={imageAlt || ''}
          fallback={FALLBACK_IMAGE}
          preview={imageWithPreview}
        />
      )}

      {description && <Text className={styles.description}>{description}</Text>}

      {children}
    </Card>
  );
};

export default CardItem;
