import { gql } from '@apollo/client';

export const ACTIVATE_STORE_ACCOUNT = gql`
  mutation ActivateStoreAccount($input: StoreStatusInput!) {
    activateStoreAccount(input: $input) {
      id
    }
  }
`;

export const DEACTIVATE_STORE_ACCOUNT = gql`
  mutation DeactivateStoreAccount($input: StoreStatusInput!) {
    deactivateStoreAccount(input: $input) {
      id
    }
  }
`;

export const EDIT_STORE_ADMIN = gql`
  mutation EditStoreAdmin($input: StoreEditAdminInput!) {
    editStoreAdmin(input: $input) {
      store {
        id
      }
      errors
    }
  }
`;

export const ADD_CONNECTED_ACCOUNT_AND_GENERATE_LINK = gql`
  mutation AddConnectedAccountAndGenerateLink(
    $input: AddConnectedAccountWithLinkInput!
  ) {
    addConnectedAccountAndGenerateLink(input: $input) {
      created
      expires_at
      object
      url
    }
  }
`;

export const CREATE_PAYOUT_ORDER = gql`
  mutation CreatePayoutOrder($input: CreatePayoutOrderInput!) {
    createPayoutOrder(input: $input) {
      amount
      id
      payouts {
        stripePayoutId
        stripeTransferId
        amount
        status
        currency
      }
    }
  }
`;
