import { gql } from '@apollo/client';

export const ADMIN_CREATE_REDIRECT = gql`
  mutation AdminCreateRedirect($input: CreateRedirectInput!) {
    adminCreateRedirect(input: $input) {
      id
      from
      to
    }
  }
`;

export const ADMIN_EDIT_REDIRECT = gql`
  mutation AdminEditRedirect($input: EditRedirectInput!) {
    adminEditRedirect(input: $input) {
      id
      from
      to
    }
  }
`;

export const ADMIN_DELETE_REDIRECT = gql`
  mutation AdminDeleteRedirect($input: DeleteRedirectInput!) {
    adminDeleteRedirect(input: $input) {
      id
      from
      to
    }
  }
`;
