import { gql } from '@apollo/client';
// Fragments
import { SendoutFields } from './fragments';

export const GET_NOTIFICATIONS = gql`
  query GetSendouts($input: GetSendoutsInput!) {
    getSendouts(input: $input) {
      nextToken
      data {
        ...SendoutFields
      }
    }
  }
  ${SendoutFields}
`;
