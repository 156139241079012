import React from 'react';
import { Space, Input, Tooltip, Typography, Select } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
// Helpers
import { designMerchTypeOptions } from 'components/common/ManageMerch/helpers';
// Types
import { MerchType } from 'api/graphql-global-types';
import { UploadFile } from 'antd/es/upload/interface';
import { DLStateValues } from 'components/common/ManageDesigns/components/DLConstructor/DLConstructor';
// UI
import UploadImageFile from 'ui/UploadImageFile/UploadImageFile';
// Styles
import styles from './ExtraInformationForm.module.scss';

type ExtraInformationFormProps = {
  onChange: (values: DLStateValues) => void;
  selectedValues: DLStateValues['extra'];
  isMerchDesign?: boolean;
};

const { Title, Text } = Typography;

const ExtraInformationForm = ({
  onChange,
  selectedValues,
  isMerchDesign = false,
}: ExtraInformationFormProps): JSX.Element => {
  const selectedExamples: (string | FileWithPreview)[] | undefined =
    selectedValues?.examples;

  const handleInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      extra: {
        ...selectedValues,
        [target.name]: target.value,
      },
    });
  };

  const beforePictureUploadCb = (file: File, fileList: File[]) => {
    onChange({
      extra: {
        ...selectedValues,
        examples: [...(selectedExamples || []), ...fileList].filter((item) =>
          typeof item === 'object'
            ? (item.type === 'image/jpeg' || item.type === 'image/png') &&
              item.size / 1024 / 1024 < 2
            : true
        ),
      },
    });
  };

  const handleRemovePicture = (file: UploadFile<string>) => {
    const examples: (string | FileWithPreview)[] = (
      selectedExamples || []
    ).filter((example) =>
      typeof example === 'object'
        ? example.name !== file.name
        : example !== file.url
    );

    onChange({
      extra: {
        ...selectedValues,
        examples,
      },
    });
  };

  const maxImageExamples = 10;

  const imageExamplesList: UploadFile<string>[] | undefined =
    selectedExamples?.map((file, index) => ({
      uid: `${index}`,
      type: 'image/jpeg,image/png',
      size: 2000000,
      name:
        typeof file === 'object'
          ? file?.name || `Example #${index + 1}`
          : `Example #${index + 1}`,
      status: index >= maxImageExamples ? 'error' : 'done',
      response: 'Server Error 500',
      url: typeof file === 'object' ? URL.createObjectURL(file) : file,
    }));

  const handleSelect = (values: MerchType[]) => {
    onChange({
      extra: {
        ...selectedValues,
        merchTypes: values,
      },
    });
  };

  return (
    <Space
      className={styles.root}
      direction="vertical"
      align="center"
      size="large"
    >
      <div className={styles.form}>
        <>
          {isMerchDesign && (
            <div>
              <Title level={5}>What type of merch would you like?</Title>

              <Select
                style={{ width: '100%' }}
                placeholder="Select type of merch"
                optionFilterProp="children"
                onChange={handleSelect}
                value={selectedValues?.merchTypes}
                mode="multiple"
                options={designMerchTypeOptions}
              />
            </div>
          )}

          <Title level={5}>
            What would you like your logo or merchandise to say?
          </Title>

          <Input
            id="name"
            name="name"
            placeholder="Mike Tyson's Club"
            maxLength={256}
            value={selectedValues?.name}
            onChange={handleInputChange}
          />

          <Text className={styles.formItemDescription}>
            Ex. name, nickname, company name, event name and details, a
            catchphrase or common saying etc.
          </Text>
        </>

        <>
          <Title level={5}>
            Any other details you want to include for your designer?
          </Title>

          <Input
            id="extraInfo"
            name="extraInfo"
            placeholder=""
            maxLength={1000}
            value={selectedValues?.extraInfo}
            onChange={handleInputChange}
          />

          <Text className={styles.formItemDescription}>
            Feel free to comment anything additional you wish to have included
            in your logo that we didn&apos;t cover
          </Text>
        </>

        <>
          <Title level={5}>Please enter your social media:</Title>

          <Input
            id="socialMediaURL"
            name="socialMediaURL"
            placeholder=""
            maxLength={1000}
            value={selectedValues?.socialMediaURL}
            onChange={handleInputChange}
          />

          <Text className={styles.formItemDescription}>
            We will use this link to personalize your design
          </Text>
        </>

        <>
          <Title level={5}>
            Attach Example
            <Tooltip title={`Maximum ${maxImageExamples} images`}>
              <InfoCircleTwoTone className={styles.formItemTitleTipIcon} />
            </Tooltip>
          </Title>
          {(selectedExamples?.length || 0) > maxImageExamples && (
            <Text type="danger">
              You can not use more than {maxImageExamples} images.
            </Text>
          )}

          <UploadImageFile
            beforePictureUploadCb={beforePictureUploadCb}
            onRemove={handleRemovePicture}
            multiple
            maxCount={maxImageExamples}
            listType="picture-card"
            fileList={imageExamplesList}
            buttonIsDisabled={
              (selectedExamples?.length || 0) >= maxImageExamples
            }
            imageAlt="Example"
          />

          <Text className={styles.formItemDescription}>
            Screenshots of designs you like, your old logo, your signature, etc.
            Attach here for our designers to work off of
          </Text>
        </>
      </div>
    </Space>
  );
};

export default ExtraInformationForm;
