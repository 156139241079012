import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Space, Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// Api
import { ADMIN_GET_REDIRECTS } from 'api/redirects/queries';
import { ADMIN_DELETE_REDIRECT } from 'api/redirects/mutations';
// Types
import {
  AdminDeleteRedirect,
  AdminDeleteRedirectVariables,
} from 'api/redirects/types/AdminDeleteRedirect';
import {
  AdminGetRedirectsVariables,
  AdminGetRedirects_adminGetRedirects_entities,
} from 'api/redirects/types/AdminGetRedirects';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import CreateEditRedirectModal from './CreateEditRedirectModal';

const { confirm } = Modal;

type ActionsProps = {
  redirect: AdminGetRedirects_adminGetRedirects_entities;
  getRedirectsInput: () => AdminGetRedirectsVariables;
};

const Actions: React.FC<ActionsProps> = ({ redirect, getRedirectsInput }) => {
  const [editRedirectModalVisible, setEditRedirectModalVisible] =
    useState<boolean>(false);

  const [adminDeleteRedirect, { loading: deleteIsLoading }] = useMutation<
    AdminDeleteRedirect,
    AdminDeleteRedirectVariables
  >(ADMIN_DELETE_REDIRECT);

  const onEditRedirect = async () => {
    setEditRedirectModalVisible(true);
  };

  const closeEditRedirectModal = (): void => {
    setEditRedirectModalVisible(false);
  };

  const onDeleteRedirect = () => {
    confirm({
      title: 'Are you sure you want to delete this redirect?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: handleDeleteRedirect,
    });
  };

  const handleDeleteRedirect = async () => {
    try {
      await adminDeleteRedirect({
        variables: {
          input: {
            id: redirect.id,
          },
        },
        refetchQueries: [
          {
            query: ADMIN_GET_REDIRECTS,
            variables: { ...getRedirectsInput() },
          },
        ],
      });
      successNotification('Redirect has been deleted successfully');
    } catch (error) {
      errorNotification(error.message);
      console.error('DeleteRedirect', error);
    }
  };

  return (
    <>
      <Space direction="vertical" size="middle" align="center">
        <Button onClick={onEditRedirect} type="primary">
          Edit
        </Button>

        <Button
          onClick={onDeleteRedirect}
          loading={deleteIsLoading}
          type="primary"
          danger
        >
          Delete
        </Button>
      </Space>

      {editRedirectModalVisible && (
        <CreateEditRedirectModal
          isVisible={editRedirectModalVisible}
          redirect={redirect}
          onClose={closeEditRedirectModal}
          getRedirectsInput={getRedirectsInput}
        />
      )}
    </>
  );
};

export default Actions;
