import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Spin, Button, Typography, Input, Form, Modal } from 'antd';
import {
  EyeTwoTone,
  LoadingOutlined,
  EyeInvisibleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
// Api
import { GET_USERS } from 'api/users/queries';
import { ADMIN_CHANGE_PASSWORD, EDIT_USER } from 'api/users/mutations';
// Types
import {
  AdminChangePassword,
  AdminChangePasswordVariables,
} from 'api/users/types/AdminChangePassword';
import { Users } from 'api/users/types/Users';
import { StoreStatus } from 'api/graphql-global-types';
import { EditUser, EditUserVariables } from 'api/users/types/EditUser';
// Helpers
import { validationRules } from 'pages/ManageAccount/helpers';
// Hooks
import { useDefaultCustomerRedirectOnEmptyId } from 'hooks';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Styles
import './styles.scss';

const { Text, Title } = Typography;
const { confirm } = Modal;

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 3 },
};

const CustomerSettings: React.FC = () => {
  useDefaultCustomerRedirectOnEmptyId();
  const { customerId } = useParams<{ customerId: string | undefined }>();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
  const [isChangePasswordDisabled, setIsChangePasswordDisabled] =
    useState<boolean>(true);
  const [formEditUser] = Form.useForm();
  const [formChangePassword] = Form.useForm();
  const { data, loading: userLoading } = useQuery<Users>(GET_USERS, {
    variables: {
      input: { id: customerId, status: [StoreStatus.Active] },
    },
    fetchPolicy: 'cache-and-network',
  });
  const [editUser, { loading }] = useMutation<EditUser, EditUserVariables>(
    EDIT_USER
  );
  const [changePassword] = useMutation<
    AdminChangePassword,
    AdminChangePasswordVariables
  >(ADMIN_CHANGE_PASSWORD);

  const customerData = data?.users?.entities?.[0];

  const initialValues = {
    email: customerData?.email,
    firstName: customerData?.firstName,
    lastName: customerData?.lastName,
    phoneNumber: customerData?.phoneNumber,
  };

  const editUserHandler = () => {
    try {
      const values = formEditUser.getFieldsValue();
      const valuesToSend = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumberE164: values.phoneNumber || null,
        id: customerData?.id,
      };
      editUser({ variables: { input: valuesToSend } });
      successNotification('The data is updated');
    } catch (e) {
      errorNotification();
      console.log('Error occured', { ...e });
    }
  };

  const changePasswordHandler = async () => {
    confirm({
      title: `Are you sure you want to change a password for this user?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await changePassword({
            variables: {
              input: {
                id: customerId || '',
                newPassword: formChangePassword.getFieldValue('password'),
              },
            },
          });
          successNotification('Password is changed');
        } catch (e) {
          errorNotification();
          console.log('adminChangePassword error:', { ...e });
        }
      },
    });
  };

  const checkValues = (): void => {
    const values = {
      ...formEditUser.getFieldsValue(),
      id: customerId,
    };
    setIsSubmitDisabled(
      Object.values(values).some((x) => x === null || x === '') ||
        formEditUser.getFieldsError()?.some(({ errors }) => errors?.length > 0)
    );
  };

  const isPasswordMatching = () =>
    formChangePassword.getFieldValue('password') ===
    formChangePassword.getFieldValue('confirmPassword');

  const checkPasswordValues = (): void => {
    setIsChangePasswordDisabled(
      Object.values(formChangePassword.getFieldsValue()).some(
        (x) => x === null || x === ''
      ) ||
        formEditUser
          .getFieldsError()
          ?.some(({ errors }) => errors?.length > 0) ||
        !isPasswordMatching() ||
        formChangePassword
          .getFieldsError()
          ?.some(({ errors }) => errors?.length > 0)
    );
  };

  return (
    <div>
      {!userLoading ? (
        <div className="settings-form">
          <Form
            form={formEditUser}
            layout="horizontal"
            name="setupAddress"
            autoComplete="off"
            initialValues={initialValues}
            onValuesChange={checkValues}
            {...formItemLayout}
          >
            <Title>Profile</Title>
            <Form.Item
              name="firstName"
              label={<Text>First Name</Text>}
              rules={validationRules.nameOrSurname}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="lastName"
              label={<Text>Last Name</Text>}
              rules={validationRules.nameOrSurname}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label={<Text>Email</Text>}
              rules={validationRules.email}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label={<Text>Phone Number</Text>}
              rules={validationRules.phoneNumber}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                onClick={editUserHandler}
                loading={loading}
                type="primary"
                disabled={isSubmitDisabled}
              >
                Confirm
              </Button>
            </Form.Item>
          </Form>
          <Form
            form={formChangePassword}
            layout="horizontal"
            name="setupAddress"
            autoComplete="off"
            onValuesChange={checkPasswordValues}
            {...formItemLayout}
          >
            <Title>Password</Title>
            <Form.Item
              name="password"
              label={<Text>New Password</Text>}
              rules={[
                {
                  required: true,
                  message: 'Please enter your new password',
                },
                {
                  min: 6,
                  max: 256,
                  required: true,
                  message:
                    "Password's length is minimum 6 and maximum 256 characters.",
                },
                {
                  pattern: /^(?=.*?[a-z])(?=.*?[A-Z]).*$/gm,
                  required: true,
                  message:
                    'Password should contain 1 uppercase and 1 lowercase characters.',
                },
              ]}
            >
              <Input.Password
                placeholder="input password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              validateStatus={
                !isPasswordMatching() ||
                formChangePassword.getFieldsError()[1]?.errors[0]
                  ? 'error'
                  : ''
              }
              help={!isPasswordMatching() ? 'Password does not match' : null}
              label={<Text>Confirm Password</Text>}
              rules={[
                {
                  required: true,
                  message: 'Please confirm your Password',
                },
              ]}
            >
              <Input.Password
                placeholder="input password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item>
              <Button
                onClick={changePasswordHandler}
                type="primary"
                disabled={isChangePasswordDisabled}
              >
                Change Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <div className="spin-wrapper">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 56 }} spin />} />
        </div>
      )}
    </div>
  );
};

export default CustomerSettings;
