import React, { useRef } from 'react';
import cn from 'classnames';
import { Space, Button } from 'antd';
// Types
import { DesignColorPalettes_designColorPalettes } from 'api/designLab/types/DesignColorPalettes';
import { DLStateValues } from 'components/common/ManageDesigns/components/DLConstructor/DLConstructor';
// Components
import CardItem from '../CardItem/CardItem';
// Styles
import styles from './PreferredColorsForm.module.scss';

type PreferredColorsFormProps = {
  onChange: (values: DLStateValues) => void;
  options: DesignColorPalettes_designColorPalettes[];
  selectedValues: number[];
  designCustomColor?: string;
};

const PreferredColorsForm = ({
  onChange,
  options,
  selectedValues,
  designCustomColor,
}: PreferredColorsFormProps): JSX.Element => {
  const customColorRef = useRef<HTMLInputElement | null>(null);

  const checkedColorsCount = selectedValues.length;
  const isCheckedMoreThanTwo =
    Number(Boolean(designCustomColor)) + checkedColorsCount >= 2;

  const handleColorCheckboxChange = (id: number) => {
    const updated = selectedValues.some((item) => item === id)
      ? selectedValues.filter((item) => item !== id)
      : selectedValues.concat(id);

    onChange({
      colors: updated,
    });
  };

  const handleCustomColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      designCustomColor: e.target.value,
    });
  };

  const handleCustomColorCheckboxChange = () => {
    if (!designCustomColor) {
      onChange({
        designCustomColor: customColorRef.current?.value || 'white',
      });

      customColorRef.current?.click();
    } else {
      onChange({
        designCustomColor: '',
      });
    }
  };

  const isAvailableCustomColor = !isCheckedMoreThanTwo || !!designCustomColor;

  return (
    <Space className={styles.root} direction="horizontal" align="start" wrap>
      {options.map(({ id, name, posterImage }) => {
        const isSelected = selectedValues.some((val) => val === id);
        const isAvailable = !isCheckedMoreThanTwo || isSelected;

        const handleChange = () => {
          if (isAvailable) {
            handleColorCheckboxChange(id);
          }
        };

        return (
          <CardItem
            key={`${name}-${id}`}
            title={name}
            isAvailable={isAvailable}
            isSelected={isSelected}
            onChange={handleChange}
            imageSrc={posterImage}
            imageAlt={`"${name}" color palette Example`}
            imageWithPreview={false}
          />
        );
      })}

      <CardItem
        title="Customize your own color"
        isAvailable={isAvailableCustomColor}
        isSelected={!!designCustomColor}
        onChange={handleCustomColorCheckboxChange}
        withoutImage
      >
        <>
          {!designCustomColor && (
            <Button
              className={styles.customColorButton}
              onClick={handleCustomColorCheckboxChange}
              type="dashed"
              size="large"
            >
              Click here to choose the color
            </Button>
          )}
          <input
            type="color"
            data-testid="customColor"
            className={cn(styles.colorPickerInput, {
              [styles.hidden]: !designCustomColor || !isAvailableCustomColor,
            })}
            style={{ backgroundColor: designCustomColor || 'unset' }}
            ref={customColorRef}
            value={designCustomColor}
            onChange={handleCustomColorChange}
          />
        </>
      </CardItem>
    </Space>
  );
};

export default PreferredColorsForm;
