import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
// Api
import { GET_ADMIN_STORES } from 'api/store/queries';
// Types
import {
  AmaType,
  UserRole,
  StoreStatus,
  SortDirection,
  AdminStoresOrderBy,
} from 'api/graphql-global-types';
import { SortedInfo, TableFilter } from 'ui/Table';
import {
  GetAdminStores,
  GetAdminStoresVariables,
} from 'api/store/types/GetAdminStores';
import {
  ApprovedStoresTableDataItem,
  RejectedStoresTableDataItem,
  UnApprovedStoresTableDataItem,
} from './components/StoresTable';
// Constants
import { MANAGE_ORGANIZATIONS_STORES } from 'constants/routes';
import { DEFAULT_CURRENT_PAGE, DEFAULT_NUMBER_ITEMS_PER_PAGE } from 'ui/Table';
// Components
import StoresTable from './components/StoresTable';

const { TabPane } = Tabs;

export enum ManageOrganizationStoreStatus {
  Current = 'current',
  Pending = 'pending',
  Rejected = 'rejected',
}

const ManageStores = (): JSX.Element => {
  const history = useHistory();
  const defaultSortInfo: SortedInfo<AdminStoresOrderBy> = {
    order: SortDirection.DESC,
    key: AdminStoresOrderBy.createdAt,
  };

  const { itemsPerPage, pageNumber, storeStatus } = useParams<{
    itemsPerPage: string | undefined;
    pageNumber: string | undefined;
    storeStatus: ManageOrganizationStoreStatus | undefined;
  }>();
  const [pageSize, setPageSize] = useState<number>(
    Number(itemsPerPage) || DEFAULT_NUMBER_ITEMS_PER_PAGE
  );
  const [currentPage, setCurrentPage] = useState<number>(
    Number(pageNumber) || DEFAULT_CURRENT_PAGE
  );
  const [sort, setSortInfo] =
    useState<SortedInfo<AdminStoresOrderBy>>(defaultSortInfo);
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [tabKey, setTabKey] = useState<ManageOrganizationStoreStatus>(
    ManageOrganizationStoreStatus.Current
  );

  const getStoreVariables: any = () => {
    const input: any = {
      status:
        tabKey === ManageOrganizationStoreStatus.Pending
          ? [StoreStatus.Pending]
          : tabKey === ManageOrganizationStoreStatus.Rejected
          ? [StoreStatus.Rejected]
          : [StoreStatus.Active, StoreStatus.Inactive],
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      if (key === 'initialAssessment') {
        return (input['initialAssessmentRange'] = {
          from: Array.isArray(value) ? value?.[0] : value,
          to: Array.isArray(value) ? value?.[1] : value,
        });
      }
      if (key === 'latestAssessment') {
        return (input['latestAssessmentRange'] = {
          from: Array.isArray(value) ? value?.[0] : value,
          to: Array.isArray(value) ? value?.[1] : value,
        });
      }
      if (key === 'createdAt') {
        return (input['signUpDateRange'] = {
          from: Array.isArray(value) ? value?.[0] : value,
          to: Array.isArray(value) ? value?.[1] : value,
        });
      }
      if (key === 'activatedAt') {
        return (input['activatedAtDateRange'] = {
          from: Array.isArray(value) ? value?.[0] : value,
          to: Array.isArray(value) ? value?.[1] : value,
        });
      }
      if (key === 'revenue') {
        return (input['revenueRange'] = {
          from: Array.isArray(value) ? Number(value?.[0]) : value,
          to: Array.isArray(value) ? Number(value?.[1]) : value,
        });
      }
      if (key === 'followerCount') {
        return (input['followersRange'] = {
          from: Array.isArray(value) ? Number(value?.[0]) : value,
          to: Array.isArray(value) ? Number(value?.[1]) : value,
        });
      }
      if (key === 'socialFollowerCount') {
        return (input['socialFollowersRange'] = {
          from: Array.isArray(value) ? Number(value?.[0]) : value,
          to: Array.isArray(value) ? Number(value?.[1]) : value,
        });
      }
      if (key === 'phoneNumber') {
        return (input['phoneNumberV2'] = value);
      }
      input[key] =
        key === 'hasAma' ||
        key === 'hasStreams' ||
        key === 'hasMerch' ||
        key === 'hasYoutube' ||
        key === 'hasProducts' ||
        key === 'hasExperiences' ||
        key === 'hasFacebook' ||
        key === 'hasTiktok' ||
        key === 'hasInstagram' ||
        key === 'hasTwitter' ||
        key === 'hasWebsite' ||
        key === 'hasOpenDesignRequest'
          ? value === 'true'
          : value;
    });
    return { input };
  };

  const {
    data,
    loading,
    refetch: refetchStores,
  } = useQuery<GetAdminStores, GetAdminStoresVariables>(GET_ADMIN_STORES, {
    variables: { storeRoles: [UserRole.Organization], ...getStoreVariables() },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (Number(itemsPerPage) !== pageSize) {
      setPageSize(Number(itemsPerPage));
    }
  }, [itemsPerPage, pageSize]);

  useEffect(() => {
    if (Number(pageNumber) !== currentPage) {
      setCurrentPage(Number(pageNumber));
    }
  }, [pageNumber, currentPage]);

  useEffect(() => {
    if (tabKey !== storeStatus) {
      setTabKey(storeStatus || ManageOrganizationStoreStatus.Current);
    }
  }, [tabKey, storeStatus]);

  const storesList = data?.adminStores?.entities || [];
  const offset = data?.adminStores?.offset || 0;

  const getStoreName = (
    orgName: string | null | undefined,
    email: string | null
  ): string => (orgName ? orgName : email || '');

  const getContactsName = (
    firstName: string | null,
    lastName: string | null,
    email: string | null
  ): string =>
    firstName || lastName ? `${firstName} ${lastName}` : email || '';

  const storesData: ApprovedStoresTableDataItem[] =
    storesList?.map((store, index) => {
      const {
        id,
        firstName,
        createdAt,
        lastName,
        email,
        storeDetails,
        purchaseStats,
        status,
        hasAma,
        hasMerch,
        hasStreams,
        hasProducts,
        hasExperiences,
        followerCount,
        sports,
        allAmas,
        verificationVideo,
        storeManagementDetails,
        hasOpenDesignRequest,
        activatedAt,
        interviewerDetails,
      } = store;

      const verificationAmaUrl = allAmas?.find(
        (ama) => ama.type === AmaType.Ama
      )?.videoURL;

      return {
        id: index + 1 + offset,
        storeId: id,
        storeName: getStoreName(storeDetails?.storeName, email),
        contactsName: getContactsName(firstName, lastName, email),
        contactsRole: storeDetails?.gymRole || '-',
        contactsEmail: email || '-',
        phoneNumber: store.phoneNumber || '',
        revenue: purchaseStats?.storeRevenue || 0,
        status,
        gymSocialLink: storeDetails?.socialMediaLink || '',
        youtubeLink: storeDetails?.youtubeLink || '',
        facebookLink: storeDetails?.facebookLink || '',
        instagramLink: storeDetails?.instagramLink || '',
        country: storeDetails?.country || '',
        state: storeDetails?.state || '',
        city: storeDetails?.city || '',
        tiktokLink: storeDetails?.tiktokLink || '',
        twitterLink: storeDetails?.twitterLink || '',
        website: storeDetails?.website || '',
        hasMerch: String(hasMerch),
        hasAma: String(hasAma),
        hasStreams: String(hasStreams),
        hasProducts: String(hasProducts),
        hasExperiences: String(hasExperiences),
        socialFollowerCount: storeDetails?.socialFollowing,
        followerCount,
        sports,
        verificationVideo: verificationVideo
          ? verificationVideo.url
          : verificationAmaUrl || null,
        activatedAt,
        createdAt: String(createdAt),
        initialAssessment: storeManagementDetails?.initialAssessment,
        latestAssessment: storeManagementDetails?.latestAssessment,
        hasOpenDesignRequest,
        managedBy: storeManagementDetails?.managedBy,
        storeManagementName: `${
          storeManagementDetails?.managedBy?.firstName || ''
        } ${storeManagementDetails?.managedBy?.lastName || ''}`,
        interviewerDetails,
      };
    }) || [];

  const unapprovedStoresData: UnApprovedStoresTableDataItem[] =
    storesList.map((store, index) => {
      const {
        id,
        storeDetails,
        firstName,
        createdAt,
        lastName,
        email,
        phoneNumber,
        purchaseStats,
        status,
        hasAma,
        hasStreams,
        hasMerch,
        hasExperiences,
        hasProducts,
        sports,
        verificationVideo,
        allAmas,
        storeManagementDetails,
        isOnboarded,
        interviewerDetails,
      } = store;

      const verificationAmaUrl = allAmas?.find(
        (ama) => ama.type === AmaType.Ama
      )?.videoURL;

      return {
        id: index + 1 + offset,
        storeId: id,
        storeName: getStoreName(storeDetails?.storeName, email),
        contactsName: getContactsName(firstName, lastName, email),
        contactsRole: storeDetails?.gymRole || '-',
        contactsEmail: email || '-',
        phoneNumber: phoneNumber || '-',
        gymSocialLink: storeDetails?.socialMediaLink || '',
        country: storeDetails?.country || '',
        state: storeDetails?.state || '',
        city: storeDetails?.city || '',
        youtubeLink: storeDetails?.youtubeLink || '',
        facebookLink: storeDetails?.facebookLink || '',
        instagramLink: storeDetails?.instagramLink || '',
        tiktokLink: storeDetails?.tiktokLink || '',
        twitterLink: storeDetails?.twitterLink || '',
        website: storeDetails?.website || '',
        hasMerch: String(hasMerch),
        hasAma: String(hasAma),
        hasStreams: String(hasStreams),
        hasProducts: String(hasProducts),
        hasExperiences: String(hasExperiences),
        createdAt: String(createdAt),
        sports,
        verificationVideo: verificationVideo
          ? verificationVideo.url
          : verificationAmaUrl || null,
        initialAssessment: storeManagementDetails?.initialAssessment,
        latestAssessment: storeManagementDetails?.latestAssessment,
        managedBy: storeManagementDetails?.managedBy,
        storeManagementName: `${
          storeManagementDetails?.managedBy?.firstName || ''
        } ${storeManagementDetails?.managedBy?.lastName || ''}`,
        socialFollowerCount: storeDetails?.socialFollowing,
        revenue: purchaseStats?.storeRevenue || 0,
        status,
        isOnboarded,
        interviewerDetails,
      };
    }) || [];

  const rejectedStoresData: RejectedStoresTableDataItem[] =
    storesList.map((store, index: number) => {
      const {
        id,
        firstName,
        lastName,
        email,
        storeDetails,
        phoneNumber,
        sports,
        allAmas,
        verificationVideo,
        createdAt,
        storeManagementDetails,
        interviewerDetails,
      } = store;

      const verificationAmaUrl = allAmas?.find(
        (ama) => ama.type === AmaType.Ama
      )?.videoURL;

      return {
        id: index + 1 + offset,
        storeId: id,
        storeName: getStoreName(storeDetails?.storeName, email),
        contactsName: getContactsName(firstName, lastName, email),
        contactsEmail: email || '-',
        phoneNumber: phoneNumber || '-',
        socialMediaLink: storeDetails?.socialMediaLink || '',
        country: storeDetails?.country || '',
        state: storeDetails?.state || '',
        city: storeDetails?.city || '',
        facebookLink: storeDetails?.facebookLink || '',
        youtubeLink: storeDetails?.youtubeLink || '',
        instagramLink: storeDetails?.instagramLink || '',
        tiktokLink: storeDetails?.tiktokLink || '',
        twitterLink: storeDetails?.twitterLink || '',
        website: storeDetails?.website || '',
        createdAt: String(createdAt),
        sports,
        verificationVideo: verificationVideo
          ? verificationVideo.url
          : verificationAmaUrl || null,
        initialAssessment: storeManagementDetails?.initialAssessment,
        latestAssessment: storeManagementDetails?.latestAssessment,
        managedBy: storeManagementDetails?.managedBy,
        storeManagementName: `${
          storeManagementDetails?.managedBy?.firstName || ''
        } ${storeManagementDetails?.managedBy?.lastName || ''}`,
        socialFollowerCount: storeDetails?.socialFollowing,
        interviewerDetails,
      };
    }) || [];

  const handleTabChange = (
    activeKey: ManageOrganizationStoreStatus | string
  ) => {
    setSortInfo(defaultSortInfo);
    setSearchFilters([]);
    history.push(
      `${MANAGE_ORGANIZATIONS_STORES}/${activeKey}/${DEFAULT_NUMBER_ITEMS_PER_PAGE}/${DEFAULT_CURRENT_PAGE}`
    );
  };

  const handleSetPagination = (page: number, size: number): void => {
    history.push(`${MANAGE_ORGANIZATIONS_STORES}/${tabKey}/${size}/${page}`);
  };

  const renderTable = () => (
    <StoresTable
      data={
        tabKey === ManageOrganizationStoreStatus.Pending
          ? unapprovedStoresData
          : tabKey === ManageOrganizationStoreStatus.Rejected
          ? rejectedStoresData
          : storesData
      }
      loading={loading}
      refetchStores={refetchStores}
      total={data?.adminStores?.total}
      sort={sort}
      setSortInfo={setSortInfo}
      pageSize={pageSize}
      currentPage={currentPage}
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      tabKey={tabKey}
      setPageAndSize={handleSetPagination}
    />
  );

  return (
    <Tabs activeKey={tabKey} onChange={handleTabChange}>
      <TabPane tab="Stores" key={ManageOrganizationStoreStatus.Current}>
        {renderTable()}
      </TabPane>
      <TabPane
        tab="Waiting for approval"
        key={ManageOrganizationStoreStatus.Pending}
      >
        {renderTable()}
      </TabPane>
      <TabPane
        tab="Rejected sign ups"
        key={ManageOrganizationStoreStatus.Rejected}
      >
        {renderTable()}
      </TabPane>
    </Tabs>
  );
};

export default ManageStores;
