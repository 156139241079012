import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Space, Button, Modal, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// Api
import { DEACTIVATE_ADMIN, RECOVER_ADMIN } from 'api/admin/mutations';
// Types
import {
  DeactivateAdmin,
  DeactivateAdminVariables,
} from 'api/admin/types/DeactivateAdmin';
import {
  RecoverAdminAccount,
  RecoverAdminAccountVariables,
} from 'api/admin/types/RecoverAdminAccount';
import { StoreStatus } from 'api/graphql-global-types';
import { GetAdmins_admins_entities } from 'api/admin/types/GetAdmins';
import { AdminFields } from 'api/admin/fragments';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import CreateEditAdminModal from 'pages/Admins/components/CreateEditAdminModal';

const { confirm } = Modal;

type ActionsProps = {
  admin: GetAdmins_admins_entities;
};

const Actions = ({ admin }: ActionsProps): JSX.Element => {
  const [editAdminModalVisible, setEditAdminModalVisible] =
    useState<boolean>(false);

  const handleEditAdminModalToggle = () => {
    setEditAdminModalVisible((prev) => !prev);
  };

  const [recoverAdminAccount, { loading: recoverAdminLoading }] = useMutation<
    RecoverAdminAccount,
    RecoverAdminAccountVariables
  >(RECOVER_ADMIN, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          admins(existingAdmins = []) {
            const newAdminRef = cache.writeFragment({
              data: data?.recoverAdminAccount,
              fragment: AdminFields,
            });

            return [...existingAdmins.entities, newAdminRef];
          },
        },
        broadcast: false,
      });
    },
  });

  const [deactivateAdmin, { loading }] = useMutation<
    DeactivateAdmin,
    DeactivateAdminVariables
  >(DEACTIVATE_ADMIN, {
    update(cache, { data }) {
      cache.modify({
        fields: {
          admins(existingAdmins = []) {
            const newAdminRef = cache.writeFragment({
              data: data?.deactivateAdmin,
              fragment: AdminFields,
            });

            return [...existingAdmins.entities, newAdminRef];
          },
        },
        broadcast: false,
      });
    },
  });

  const handleRecoverAdmin = async () => {
    try {
      await recoverAdminAccount({
        variables: {
          id: admin.id,
        },
      });

      successNotification(
        `${admin.firstName || ''} ${admin.lastName || ''} has been reactivated`
      );
    } catch (error) {
      errorNotification((error as Error)?.message || 'Something went wrong');
    }
  };

  const onRecoverAdmin = () => {
    confirm({
      title: 'Are you sure you want to reactivate this admin?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: handleRecoverAdmin,
    });
  };

  const handleDeactivateAdmin = async () => {
    try {
      await deactivateAdmin({
        variables: {
          id: admin.id,
        },
      });

      successNotification(
        `${admin.firstName || ''} ${admin.lastName || ''} has been deactivated`
      );
    } catch (error) {
      errorNotification((error as Error)?.message || 'Something went wrong');
    }
  };

  const onDeactivateAdmin = () => {
    confirm({
      title: 'Are you sure you want to deactivate this admin?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: handleDeactivateAdmin,
    });
  };

  return (
    <>
      <Spin spinning={loading || recoverAdminLoading}>
        <Space direction="vertical" size="middle" align="center">
          <Button onClick={handleEditAdminModalToggle} type="primary">
            Edit
          </Button>

          <Button
            onClick={onDeactivateAdmin}
            type="primary"
            danger
            disabled={admin.status === StoreStatus.Inactive}
          >
            Deactivate
          </Button>

          <Button
            onClick={onRecoverAdmin}
            type="primary"
            danger
            disabled={admin.status === StoreStatus.Active}
          >
            Reactivate
          </Button>
        </Space>
      </Spin>

      <CreateEditAdminModal
        isVisible={editAdminModalVisible}
        admin={admin}
        onClose={handleEditAdminModalToggle}
      />
    </>
  );
};

export default Actions;
