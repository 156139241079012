import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Modal, Typography } from 'antd';
// Config
import ENV from 'api/env';
// Api
import { GET_ALL_HASHTAGS } from 'api/hashtags/queries';
// Types
import {
  SortDirection,
  HashtagsFilterOrderBy,
  AllHashtagsFilterInput,
} from 'api/graphql-global-types';
import {
  GetAllHashtags,
  GetAllHashtagsVariables,
  GetAllHashtags_getAllHashtags_entities,
  GetAllHashtags_getAllHashtags_entities_sports,
} from 'api/hashtags/types/GetAllHashtags';
import { SortedInfo, TableFilter } from 'ui/Table';
// Helpers
import { getEnvLink } from 'helpers/getEnvLink';
// Components
import Actions from './components/Actions';
import HashtagStores from './components/HashtagStores';
import CreateHashtag from './components/CreateHashtag/CreateHashtag';
// UI
import Table from 'ui/Table';

import './styles.scss';

const { Text, Link } = Typography;

const ManageHashtags: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<HashtagsFilterOrderBy>>({
    order: SortDirection.DESC,
    key: HashtagsFilterOrderBy.name,
  });
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const [selectedRecord, setSelectedRecord] =
    useState<GetAllHashtags_getAllHashtags_entities | null>(null);

  const [isStoresModalVisible, setIsStoresModalVisible] =
    useState<boolean>(false);

  const showStoresModal = (
    record: GetAllHashtags_getAllHashtags_entities | null
  ) => {
    setSelectedRecord(record);
    setIsStoresModalVisible(true);
  };

  const hideStoresModal = () => {
    setIsStoresModalVisible(false);
    setSelectedRecord(null);
  };

  const getAllHashtagsInput: any = () => {
    const input: AllHashtagsFilterInput = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      if (key === HashtagsFilterOrderBy.name) {
        input.keyword = value as string;
      }
    });

    return { input };
  };

  const { data, loading } = useQuery<GetAllHashtags, GetAllHashtagsVariables>(
    GET_ALL_HASHTAGS,
    {
      variables: { ...getAllHashtagsInput() },
      fetchPolicy: 'cache-and-network',
    }
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: HashtagsFilterOrderBy.name,
      fixed: 'left' as const,
      align: 'left' as const,
      width: 50,
      withSearch: true,
      sorterType: 'text',
      render: function HashtagName(name: string) {
        return <Text>{`#${name}`}</Text>;
      },
    },
    {
      title: 'Slug',
      dataIndex: 'name',
      align: 'center' as const,
      width: 50,
      render: function Slug(name: string) {
        const slug = name.toLowerCase();

        return (
          <Link
            href={`${getEnvLink(ENV.REACT_APP_ENV)}/tag/${slug}`}
            title={`See all stores with tag #${name}`}
            target="_blank"
          >
            {slug}
          </Link>
        );
      },
    },
    {
      title: 'Sports',
      dataIndex: 'sports',
      align: 'center' as const,
      width: 150,
      render: function Sports(
        sports: GetAllHashtags_getAllHashtags_entities_sports[]
      ) {
        return sports?.map((item) => item.name).join(', ') || '';
      },
    },
    {
      title: 'Stores',
      dataIndex: 'storeCount',
      align: 'center' as const,
      width: 150,
      render: function StoresNumber(
        text: string,
        record: GetAllHashtags_getAllHashtags_entities
      ) {
        return (
          <Button
            disabled={record?.storeCount === 0}
            type="link"
            onClick={() => showStoresModal(record)}
            title={`Manage stores with internal tag: ${record?.name}`}
          >
            {record?.storeCount || '-'}
          </Button>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 50,
      render: function ActionsWrap(
        id: string,
        record: GetAllHashtags_getAllHashtags_entities
      ) {
        return (
          <Actions
            hashtag={record}
            getAllHashtagsInput={getAllHashtagsInput()}
          />
        );
      },
    },
  ];

  return (
    <div className="manage-hashtags">
      <CreateHashtag getAllHashtagsInput={getAllHashtagsInput()} />

      <Modal
        title={`Store with hashtag #${selectedRecord?.name || ''}`}
        visible={isStoresModalVisible}
        onCancel={hideStoresModal}
        footer={null}
        destroyOnClose
        width="50vw"
      >
        {selectedRecord && (
          <HashtagStores
            hashtag={selectedRecord}
            getAllHashtagsInput={getAllHashtagsInput}
            onFinish={hideStoresModal}
          />
        )}
      </Modal>

      <Table<GetAllHashtags_getAllHashtags_entities, HashtagsFilterOrderBy>
        columns={columns}
        data={data?.getAllHashtags?.entities}
        scroll={{ x: 400 }}
        loading={loading}
        total={data?.getAllHashtags?.total}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />
    </div>
  );
};

export default ManageHashtags;
