export const LOGIN = '/';
export const ACCOUNT = '/account';

// Customers
export const CUSTOMERS = '/customers';
export const CUSTOMERS_WITH_PAGINATION = `${CUSTOMERS}/:itemsPerPage?/:pageNumber?`;

// Home Page management
export const HOME_PAGE_MANAGEMENT = '/home-page-management';

// Dashboard
export const DASHBOARD = '/dashboard';
export const ATHLETE_DASHBOARD_GEN = '/athlete-dashboard';
export const ATHLETE_DASHBOARD = `${ATHLETE_DASHBOARD_GEN}/:storeId`;

export const ORGANIZATION_DASHBOARD_GEN = '/organization-dashboard';
export const ORGANIZATION_DASHBOARD = `${ORGANIZATION_DASHBOARD_GEN}/:storeId`;
export const CONTENT_CREATOR_DASHBOARD_GEN = '/content-creator-dashboard';
export const CONTENT_CREATOR_DASHBOARD = `${CONTENT_CREATOR_DASHBOARD_GEN}/:storeId`;

// Manage all tags
export const TAGS = '/tags';

// Manage campaigns
export const CAMPAIGNS = '/campaigns';

// Manage Store
export const MANAGE_ATHLETES_STORES = `/manage-athletes-stores`;
export const MANAGE_ATHLETES_STORES_WITH_PAGINATION = `${MANAGE_ATHLETES_STORES}/:storeStatus?/:itemsPerPage?/:pageNumber?`;
export const MANAGE_ORGANIZATIONS_STORES = `/manage-organizations-stores`;
export const MANAGE_ORGANIZATIONS_STORES_WITH_PAGINATION = `${MANAGE_ORGANIZATIONS_STORES}/:storeStatus?/:itemsPerPage?/:pageNumber?`;
export const MANAGE_CONTENT_CREATORS_STORES = `/manage-content-creators-stores`;
export const MANAGE_CONTENT_CREATORS_STORES_WITH_PAGINATION = `${MANAGE_CONTENT_CREATORS_STORES}/:storeStatus?/:itemsPerPage?/:pageNumber?`;

// Manage Messages
export const MANAGE_MESSAGES = `/manage-messages`;
export const MANAGE_MESSAGES_WITH_PAGINATION = `${MANAGE_MESSAGES}/:manageMessagesTabs?/:itemsPerPage?/:pageNumber?`;

// Manage Bio
export const MANAGE_ATHLETE_BIO_GEN = '/manage-athlete-bio';
export const MANAGE_ATHLETE_BIO = `${MANAGE_ATHLETE_BIO_GEN}/:storeId`;
export const MANAGE_ORGANIZATION_BIO_GEN = '/manage-organization-bio';
export const MANAGE_ORGANIZATION_BIO = `${MANAGE_ORGANIZATION_BIO_GEN}/:storeId`;
export const MANAGE_CONTENT_CREATOR_BIO_GEN = '/manage-content-creator-bio';
export const MANAGE_CONTENT_CREATOR_BIO = `${MANAGE_CONTENT_CREATOR_BIO_GEN}/:storeId`;

// Scheduled Events
export const ATHLETE_SCHEDULED_EVENTS_GEN = '/athlete-scheduled-events';
export const ATHLETE_SCHEDULED_EVENTS = `${ATHLETE_SCHEDULED_EVENTS_GEN}/:storeId`;
export const ORGANIZATION_SCHEDULED_EVENTS_GEN =
  '/organization-scheduled-events';
export const ORGANIZATION_SCHEDULED_EVENTS = `${ORGANIZATION_SCHEDULED_EVENTS_GEN}/:storeId`;
export const CONTENT_CREATOR_SCHEDULED_EVENTS_GEN =
  '/content-creator-scheduled-events';
export const CONTENT_CREATOR_SCHEDULED_EVENTS = `${CONTENT_CREATOR_SCHEDULED_EVENTS_GEN}/:storeId`;

// Media Library
export const MEDIA_FEED_ATHLETE_GEN = '/media-feed-athlete';
export const MEDIA_FEED_ATHLETE = `${MEDIA_FEED_ATHLETE_GEN}/:storeId`;
export const MEDIA_POST = '/media-post';
export const MEDIA_FEED_ORGANIZATION_GEN = '/media-feed-organization';
export const MEDIA_FEED_ORGANIZATION = `${MEDIA_FEED_ORGANIZATION_GEN}/:storeId`;
export const MEDIA_FEED_CONTENT_CREATOR_GEN = '/media-feed-content-creator';
export const MEDIA_FEED_CONTENT_CREATOR = `${MEDIA_FEED_CONTENT_CREATOR_GEN}/:storeId`;

// Sales
export const ATHLETE_STORE_SALES_GEN = '/athlete-sales';
export const ATHLETE_STORE_SALES = `${ATHLETE_STORE_SALES_GEN}/:storeId`;
export const ORGANIZATION_STORE_SALES_GEN = '/organization-sales';
export const ORGANIZATION_STORE_SALES = `${ORGANIZATION_STORE_SALES_GEN}/:storeId`;
export const CONTENT_CREATOR_STORE_SALES_GEN = '/content-creator-sales';
export const CONTENT_CREATOR_STORE_SALES = `${CONTENT_CREATOR_STORE_SALES_GEN}/:storeId`;

// All Products and Memorabilia
export const ALL_PRODUCT_MEMORABILIA = '/all-product-memorabilia';

// AMA
export const MANAGE_ATHLETE_AMA_REQUESTS_GEN = '/manage-athlete-ama-requests';
export const MANAGE_ATHLETE_AMA_REQUESTS = `${MANAGE_ATHLETE_AMA_REQUESTS_GEN}/:storeId`;
export const SETUP_ATHLETE_AMA_GEN = '/setup-athlete-ama';
export const SETUP_ATHLETE_AMA = `${SETUP_ATHLETE_AMA_GEN}/:storeId`;
export const ALL_AMA_REQUESTS = '/all-ama-requests';
export const MANAGE_ORGANIZATION_AMA_REQUESTS_GEN =
  '/manage-organization-ama-requests';
export const MANAGE_ORGANIZATION_AMA_REQUESTS = `${MANAGE_ORGANIZATION_AMA_REQUESTS_GEN}/:storeId`;
export const SETUP_ORGANIZATION_AMA_GEN = '/setup-organization-ama';
export const SETUP_ORGANIZATION_AMA = `${SETUP_ORGANIZATION_AMA_GEN}/:storeId`;
export const MANAGE_CONTENT_CREATOR_AMA_REQUESTS_GEN =
  '/manage-content-creator-ama-requests';
export const MANAGE_CONTENT_CREATOR_AMA_REQUESTS = `${MANAGE_CONTENT_CREATOR_AMA_REQUESTS_GEN}/:storeId`;
export const SETUP_CONTENT_CREATOR_AMA_GEN = '/setup-content-creator-ama';
export const SETUP_CONTENT_CREATOR_AMA = `${SETUP_CONTENT_CREATOR_AMA_GEN}/:storeId`;

// Brand deals
export const ATHLETE_DEALS_SETUP_GEN = '/athlete-deals-setup';
export const ATHLETE_DEALS_SETUP = `${ATHLETE_DEALS_SETUP_GEN}/:storeId`;
export const CONTENT_CREATOR_DEALS_SETUP_GEN = '/content-creator-deals-setup';
export const CONTENT_CREATOR_DEALS_SETUP = `${CONTENT_CREATOR_DEALS_SETUP_GEN}/:storeId`;

// Moderators
export const LIST_OF_ASSIGNED_STREAMS = '/list-of-assigned-streams';
export const MANAGE_WATCH_STREAM_MODERATORS_GEN =
  '/manage-watch-stream-moderators';

// Streams
export const MANAGE_ATHLETE_SCHEDULED_STREAMS_GEN = `/manage-athlete-scheduled-streams`;
export const MANAGE_ATHLETE_SCHEDULED_STREAMS = `${MANAGE_ATHLETE_SCHEDULED_STREAMS_GEN}/:storeId`;
export const SETUP_ATHLETE_STREAM_GEN = `/setup-athlete-stream`;
export const SETUP_ATHLETE_STREAM = `${SETUP_ATHLETE_STREAM_GEN}/:storeId`;
export const MANAGE_ORGANIZATION_SCHEDULED_STREAMS_GEN = `/manage-organization-scheduled-streams`;
export const MANAGE_ORGANIZATION_SCHEDULED_STREAMS = `${MANAGE_ORGANIZATION_SCHEDULED_STREAMS_GEN}/:storeId`;
export const SETUP_ORGANIZATION_STREAM_GEN = `/setup-organization-stream`;
export const SETUP_ORGANIZATION_STREAM = `${SETUP_ORGANIZATION_STREAM_GEN}/:storeId`;
export const MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS_GEN = `/manage-content-creator-scheduled-streams`;
export const MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS = `${MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS_GEN}/:storeId`;
export const SETUP_CONTENT_CREATOR_STREAM_GEN = `/setup-content-creator-stream`;
export const SETUP_CONTENT_CREATOR_STREAM = `${SETUP_CONTENT_CREATOR_STREAM_GEN}/:storeId`;
export const STREAMS_CALENDAR = '/streams-calendar';
export const ALL_STREAMS_TABLE = '/all-streams';
export const WATCH_STREAM_GEN = `/watch-stream`;
export const WATCH_STREAM = `${WATCH_STREAM_GEN}/:streamId`;
export const PROMO_CODES = '/promo-codes';
export const ADMINS = '/admins';

// Merch
const MANAGE_MERCH_ROLE_PARAM =
  '/:manageMerchRole(manage-athlete-merch|manage-organization-merch|manage-content-creator-merch)';
// export const MANAGE_MERCH_GEN = `/manage-merch`;
// export const MANAGE_MERCH = `${MANAGE_MERCH_GEN}/:storeId`;
export const MANAGE_MERCH_TSHIRT_GEN = `/TShirt`;
export const MANAGE_MERCH_HOODIE_GEN = `/Hoodie`;
export const MANAGE_MERCH_HAT_GEN = `/Hat`;
export const MANAGE_MERCH_JOGGERS_GEN = `/Joggers`;
export const MANAGE_MERCH_RASH_GUARD_GEN = `/RashGuard`;
export const MANAGE_MERCH_TSHIRT = `${MANAGE_MERCH_ROLE_PARAM}${MANAGE_MERCH_TSHIRT_GEN}/:storeId/:productId?`;
export const MANAGE_MERCH_HOODIE = `${MANAGE_MERCH_ROLE_PARAM}${MANAGE_MERCH_HOODIE_GEN}/:storeId/:productId?`;
export const MANAGE_MERCH_HAT = `${MANAGE_MERCH_ROLE_PARAM}${MANAGE_MERCH_HAT_GEN}/:storeId/:productId?`;
export const MANAGE_MERCH_JOGGERS = `${MANAGE_MERCH_ROLE_PARAM}${MANAGE_MERCH_JOGGERS_GEN}/:storeId/:productId?`;
export const MANAGE_MERCH_RASH_GUARD = `${MANAGE_MERCH_ROLE_PARAM}${MANAGE_MERCH_RASH_GUARD_GEN}/:storeId/:productId?`;
export const MANAGE_ATHLETE_MERCH_GEN = `/manage-athlete-merch`;
export const MANAGE_ATHLETE_MERCH = `${MANAGE_ATHLETE_MERCH_GEN}/:storeId`;
export const MANAGE_ORGANIZATION_MERCH_GEN = `/manage-organization-merch`;
export const MANAGE_ORGANIZATION_MERCH = `${MANAGE_ORGANIZATION_MERCH_GEN}/:storeId`;
export const MANAGE_CONTENT_CREATOR_MERCH_GEN = `/manage-content-creator-merch`;
export const MANAGE_CONTENT_CREATOR_MERCH = `${MANAGE_CONTENT_CREATOR_MERCH_GEN}/:storeId`;

// Products
export const MANAGE_ATHLETE_PRODUCTS_GEN = `/manage-athlete-products`;
export const MANAGE_ATHLETE_PRODUCTS = `${MANAGE_ATHLETE_PRODUCTS_GEN}/:storeId`;
export const MANAGE_ORGANIZATION_PRODUCTS_GEN = `/manage-organization-products`;
export const MANAGE_ORGANIZATION_PRODUCTS = `${MANAGE_ORGANIZATION_PRODUCTS_GEN}/:storeId`;
export const MANAGE_CONTENT_CREATOR_PRODUCTS_GEN = `/manage-content-creator-products`;
export const MANAGE_CONTENT_CREATOR_PRODUCTS = `${MANAGE_CONTENT_CREATOR_PRODUCTS_GEN}/:storeId`;

// Experience
export const MANAGE_ATHLETE_EXPERIENCE_GEN = `/manage-athlete-experience`;
export const MANAGE_ATHLETE_EXPERIENCE = `${MANAGE_ATHLETE_EXPERIENCE_GEN}/:storeId`;
export const MANAGE_ORGANIZATION_EXPERIENCE_GEN = `/manage-organization-experience`;
export const MANAGE_ORGANIZATION_EXPERIENCE = `${MANAGE_ORGANIZATION_EXPERIENCE_GEN}/:storeId`;
export const MANAGE_CONTENT_CREATOR_EXPERIENCE_GEN = `/manage-content-creator-experience`;
export const MANAGE_CONTENT_CREATOR_EXPERIENCE = `${MANAGE_CONTENT_CREATOR_EXPERIENCE_GEN}/:storeId`;

// Customer Settings
export const MANAGE_ACCOUNT = `/manage-account`;
export const CUSTOMER_PURCHASES_GEN = `/customer-purchases`;
export const CUSTOMER_PURCHASES = `${CUSTOMER_PURCHASES_GEN}/:customerId`;
export const CUSTOMER_SETTINGS_GEN = `/customer-settings`;
export const CUSTOMER_SETTINGS = `${CUSTOMER_SETTINGS_GEN}/:customerId`;
export const CUSTOMER_ADDRESSES_GEN = `/customer-addresses`;
export const CUSTOMER_ADDRESSES = `${CUSTOMER_ADDRESSES_GEN}/:customerId`;
export const CUSTOMER_PAYMENT_GEN = `/customer-payment`;
export const CUSTOMER_PAYMENT = `${CUSTOMER_PAYMENT_GEN}/:customerId`;

// Interview
export const MY_INTERVIEWS = `/my-interviews`;
export const INTERVIEWS = '/interviews';
export const INTERVIEWERS = '/interviewers';
export const INTERVIEW_SETTINGS = '/interview-settings';
export const MY_SCHEDULED_EVENTS = '/my-scheduled-events';
export const MY_AVAILABILITY = '/my-availability';

// Design lab
export const DESIGN_REQUESTS = `/design-requests`;
export const DESIGNERS = `/designers`;
export const MY_DESIGN_REQUESTS = `/my-design-requests`;
export const MANAGE_ATHLETE_DESIGNS_GEN = `/manage-athlete-designs`;
export const MANAGE_ATHLETE_DESIGNS = `${MANAGE_ATHLETE_DESIGNS_GEN}/:storeId`;
export const MANAGE_ORGANIZATION_DESIGNS_GEN = `/manage-organization-designs`;
export const MANAGE_ORGANIZATION_DESIGNS = `${MANAGE_ORGANIZATION_DESIGNS_GEN}/:storeId`;
export const MANAGE_CONTENT_CREATOR_DESIGNS_GEN = `/manage-content-creator-designs`;
export const MANAGE_CONTENT_CREATOR_DESIGNS = `${MANAGE_CONTENT_CREATOR_DESIGNS_GEN}/:storeId`;

export const DESIGN_REQUEST_DETAILS_GEN = `/design-request-details`;
export const DESIGN_REQUEST_DETAILS = `${DESIGN_REQUEST_DETAILS_GEN}/:requestId`;

export const DESIGN_REQUEST_RESULT_DETAILS_GEN = `/design-request-results`;
export const DESIGN_REQUEST_RESULT_DETAILS = `${DESIGN_REQUEST_RESULT_DETAILS_GEN}/:requestId`;

// Video lab
export const EDITING_REQUESTS = '/editing-requests';
export const MY_EDITING_REQUESTS = '/my-editing-requests';
export const VIDEO_EDITORS = `/video-editors`;
export const VIDEO_EDITOR_PROFILE = '/video-editor-profile';
export const VIDEO_EDIT_REQUEST_SETTINGS = '/video-edit-request-settings';

// Redirects
export const REDIRECTS = `/redirects`;

// Bulk Upload
export const BULK_UPLOAD = `/bulk-upload`;

// Sales reports
export const MERCH_SALES_REPORT = `/merch-sales-report`;
export const AMA_SALES_REPORT = `/ama-sales-report`;
export const STREAM_SALES_REPORT = `/stream-sales-report`;

// General sales
export const GENERAL_SALES = `/general-sales`;

// Experience sales
export const EXPERIENCE_SALES = `/experience-sales`;

// Manual merch merge
export const MANUAL_MERCH_MERGE = `/manual-merch-merge`;

// Articles
export const ARTICLES = `/articles`;
export const ARTICLES_WITH_PAGINATION = `${ARTICLES}/:articlesType?/:itemsPerPage?/:pageNumber?`;
